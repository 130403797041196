
import React from 'react';
import { useState, useEffect } from "react";
import { Editor, EditorState, ContentState } from 'draft-js';
import FeatureButton from '../ReusableComponents/Button';
import Button from '@mui/material/Button';
import InputBoxMui from '../ReusableComponents/InputBoxMui';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { getParaphrasePrompt, updateParaphrasePrompt } from '../../services/promptsGeneratorServices';
import Loader from '../../utils/loader';
import { toast, ToastContainer } from "react-toastify";
 
 
 
 
const PromptsGenerator_Paraphrase = () => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [customText, setCustomText] = useState("Custom")
  const [isCustomSuggestion, setIsCustomSuggestion] = useState(true)
  const [isCustomOpen, setIsCustomOpen] = useState(false)
  const [customTextValue, setCustomTextValue] = useState("")
  const [suggestionText, setSuggestionText] = useState("Custom")
 
 
 
  const [featureName, setFeatureName] = useState('improve-phrasing');
  const [promptInformation, setPromptInformation] = useState('');
  const [tone, setTone] = useState('');
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectCustomTone, setSelectCustomTone] = useState('Custom');

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
 
  // Update windowWidth on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
 
    window.addEventListener('resize', handleResize);
 
    return () => window.removeEventListener('resize', handleResize);
  }, []);
 
  const mobileView = windowWidth < 770
 
 
//   const handleParaphrasePrompt = async (type, customTone ) => {
 
//     try {
//       const data = {
//         featureName: featureName,
//         customTone : customTone   ,
//         tone: type,
//       };
// setTone(data.tone)
// //console.log("tone  ", data.tone)
 
 
//       setLoading(true)
//       const result = await getParaphrasePrompt(data);
//       if (result.prompt_information !== "") {
//       //  console.log("Responce from ParaPrompt API  ", result)
//       //  console.log("Responce from ParaPrompt API  ", result.data)
//         const contentState = ContentState.createFromText(result.prompt_information);
//         const newEditorState = EditorState.createWithContent(contentState);
//         setEditorState(newEditorState);
//       }
 
 
//     } catch (error) {
//       console.error('Error calling paraphrasePrompt:', error);
//     }
//     setIsCustomOpen(false)
 
//     setLoading(false)
//   };

const handleParaphrasePrompt = async (type, customTone) => {
setTone(type)
  try {
    setSelectCustomTone(customTone)
    const data = {
      featureName: featureName,
      tone: type,
      customTone : customTone
    };
 
console.log("featureName ",data.featureName ,"tone  ", data.tone, "customTone  ", data.customTone)
 
    setLoading(true)
    const result = await getParaphrasePrompt(data);
    if (result.prompt_information !== "") {
      console.log("Responce from ParaPrompt API  ", result)
      console.log("Responce from ParaPrompt API  ", result.data)
      const contentState = ContentState.createFromText(result.prompt_information);
      const newEditorState = EditorState.createWithContent(contentState);
      setEditorState(newEditorState);
    }
 
 
  } catch (error) {
    console.error('Error calling paraphrasePrompt:', error);
  }
  setIsCustomOpen(false)
 
  setLoading(false)
};    
 
 
 
 
  const updatePraphrasePrompts = async () => {
 
   const text = editorState.getCurrentContent().getPlainText()
if(text === ""){
  toast.warn("Nothing to save");
  return;
}
 
 
    try {
      const data = {
        promptInformation : text,
        featureName: featureName,
        tone: tone,
        customTone: selectCustomTone
      };
 
      console.log("promptsInformation   ", data.promptInformation);
      console.log("featureName   ",    data.featureName)
      console.log("Tone", data.tone)

      setLoading(true);
      const result = await updateParaphrasePrompt(data);
      if (result.prompt_information !== "") {
        console.log("Response from ParaPrompt API", result);
        console.log("Response from ParaPrompt API", result.data);

 
        // const contentState = ContentState.createFromText(result.prompt_information);
        // const newEditorState = EditorState.createWithContent(contentState);
        // setEditorState(newEditorState);
        toast.success("Data saved successfully")
      }
    } catch (error) {
      console.error('Error calling paraphrasePrompt:', error);
    }
 
   
    setLoading(false);
  };
 
// const updatePraphrasePrompts = ()=>{
//   editorState.getCurrentContent().getPlainText()
// }

  const handleChange = (newEditorState) => {
    setEditorState(newEditorState)
  }
  const handleClose = () => {
    setIsCustomOpen(false)
    setCustomText(customText)
  }
  const handleSuggestionChange = (e) => {
   // console.log("Suggestion Text", e.target.value)
    let value = e.target.value
    //  value = value.replace(/[0-9]' `@#$%^&*()_+!:"}{|<>,.}]/g,'')
   // console.log("value_", value)con
    setCustomTextValue(value)
 
  }
 
  const handleCustomClick = () => {
    console.log("customText_", customTextValue)
       setIsCustomSuggestion(false)
    setSuggestionText(customTextValue)
    // let splitCustomText = customTextValue.split(' ')
    // GrammerCheckForParaphrase(splitCustomText[2])
    //setIsCustomOpen(false)
  }
 
 
 
  const handleFutureButton = (type) => {
    // if (editorState.getCurrentContent().getPlainText('').split(/\s+/).length > 1500) {
    //   toast.warn("words limit exceeded")
    //   return;
    // }
    //console.log("featureName  ", type)
    if (type == "shorten") {
     // console.log("compression")
      // checkWordsCompression()
      setCustomText("Custom")
    } else if (type == "expand") {
    //  console.log("checkWordsExpansion")
      // checkWordsExpansion()
      setCustomText("Custom")
    } else if (type == "custom") {
  //    console.log("checkCustom")
      setIsCustomOpen(true)
    }
    else {
     //
      setCustomText("Custom")
    }
   // setTone(type);
  //  handleParaphrasePrompt()
  }
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
  return (
    <div>
 
      <Loader loading={loading} />
 
 
      <Modal
        isOpen={isCustomOpen}
        // toggle={() => setIsCustomOpen(true)}
        style={{
          // position: "absolute",
          // left: "43%",
          top: "33%",
        }}
      >
        <ModalHeader toggle={handleClose}>
          <div className="d-flex px-2 justify-content-between">
            <div
              style={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: 600,
                color: "#303030",
                fontSize: "1rem",
              }}
            >Make my text sound...</div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="d-flex justify-content-between">
            <p
              style={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: 600,
                color: "#303030",
                fontSize: "1rem",
              }}
              className="col-md-10"
            >
              {/* <InputBoxMui
                fullWidth
                placeholder={"E.g., “like a News”"}
                value={customTextValue}
                onChange={e => handleSuggestionChange(e)}
 
              /> */}
            </p>
            <div className="ml-2">
              {/* <button
                className="btn btn-primary"
                onClick={handleCustomClick}
                disabled={customTextValue !== "" ? false : true}
              >
                Done
              </button> */}
            </div>
          </div>
          <div className="px-2">
            <div
              style={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: 600,
                color: "#525252",
                fontSize: "14px",
              }}
            >Suggestions:</div>
            <div className="d-flex justify-content-evenly mt-2">
              <FeatureButton text={"Like a Story"} style={{ width: "120px" }} size={"md"} onClick={() => handleParaphrasePrompt("custom","Like a story")} />
              <FeatureButton text={"Like a Poem"} divClassName={"ml-3"} style={{ width: "120px" }} size={"md"} onClick={() => handleParaphrasePrompt("custom","Like a poem")} />
            </div>
            <div className="d-flex justify-content-evenly mt-2">
              <FeatureButton text={"Like a Ceo"} style={{ width: "120px" }} size={"md"} onClick={() => handleParaphrasePrompt("custom","Like a CEO")} />
              <FeatureButton text={"Like a Doctor"} divClassName={"ml-3"} style={{ width: "120px" }} size={"md"} onClick={() => handleParaphrasePrompt("custom","Like a Doctor")} />
            </div>
            <div className="d-flex justify-content-evenly mt-2">
              <FeatureButton text={"Like a News"} style={{ width: "120px" }} size={"md"} onClick={() => handleParaphrasePrompt("custom","Like a news")} />
              <FeatureButton text={"Like a Sales Executive"} divClassName={"ml-3"} style={{ width: "160px" }} size={"md"} onClick={() => handleParaphrasePrompt("custom","Like a sales executive")} />
            </div>
            <div className="d-flex justify-content-evenly mt-2">
              <FeatureButton text={"Like a Letter"} style={{ width: "120px" }} size={"md"} onClick={() => handleParaphrasePrompt("custom","Like a letter")} />
              {/* //<FeatureButton text={suggestionText} disable={isCustomSuggestion} divClassName={"ml-3"} style={{ width: "120px" }} size={"md"}  /> */}
              {/* <FeatureButton text={suggestionText} disable={isCustomSuggestion} divClassName={"ml-3"} style={{ width: "120px" }} size={"md"} onClick={() => handleParaphrasePrompt("custom",suggestionText)} /> */}
            </div>
          </div>
        </ModalBody>
      </Modal>
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
      <div style={{ backgroundColor: "white", minHeight:"9vh", width: "100%", display: "flex", alignItems: "center", marginTop: "20px", boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', borderRadius: '8px', padding: '5px 0px' }}>
        <Button style={{ height: "60%", marginLeft: "10px" }} variant="contained" onClick={() => handleParaphrasePrompt("defaultPrompt")} >Generate</Button>
 
        <div style={{ height: "100%", width: "92%", marginLeft: "5%", display: "flex", flexDirection: "row", flexWrap:'wrap', alignItems: "center" }}>
          <FeatureButton divClassName={"px-1"} text={"Standard"} style={{ margin: '3px' ,width: mobileView ? '60px' : "75px", fontSize: mobileView ? '10px' : '' }} size={"sm"} onClick={() => handleParaphrasePrompt("standard") }/>
          <FeatureButton divClassName={"px-1"} text={"Formal"} style={{ margin: '3px' ,width: mobileView ? '60px' : "75px", fontSize: mobileView ? '10px' : '' }} size={"sm"}  onClick={() => handleParaphrasePrompt("formal") } />
          <FeatureButton divClassName={"px-1"} text={"Casual"} style={{ margin: '3px' ,width: mobileView ? '60px' : "75px", fontSize: mobileView ? '10px' : '' }} size={"sm"}  onClick={() => handleParaphrasePrompt("casual") } />
          <FeatureButton divClassName={"px-1"} text={"Positive"} style={{ margin: '3px' ,width: mobileView ? '60px' : "75px", fontSize: mobileView ? '10px' : '' }} size={"sm"}  onClick={() => handleParaphrasePrompt("positive") } />
          <FeatureButton divClassName={"px-1"} text={"Confident"} style={{ margin: '3px' ,width: mobileView ? '60px' : "75px", fontSize: mobileView ? '10px' : '' }} size={"sm"}  onClick={() => handleParaphrasePrompt("confident") } />
          <FeatureButton divClassName={"px-1"} text={"Friendly"} style={{ margin: '3px' ,width: mobileView ? '60px' : "75px", fontSize: mobileView ? '10px' : '' }} size={"sm"}  onClick={() => handleParaphrasePrompt("friendly") } />
          <FeatureButton divClassName={"px-1"} text={"Simple"} style={{ margin: '3px' ,width: mobileView ? '60px' : "75px", fontSize: mobileView ? '10px' : '' }} size={"sm"}  onClick={() => handleParaphrasePrompt("simple") } />
          <FeatureButton divClassName={"px-1"} text={selectCustomTone ? selectCustomTone : "Custom"} style={{ margin: '3px' ,width: mobileView ? '60px' : "75px", fontSize: mobileView ? '10px' : '' }} size={"sm"} onClick={() => handleFutureButton("custom")} />
          <FeatureButton divClassName={"px-1"} text={"Shorten"} style={{ margin: '3px' ,width: mobileView ? '60px' : "75px", fontSize: mobileView ? '10px' : '' }} size={"sm"} onClick={() => handleParaphrasePrompt("shorten") }  />
          <FeatureButton divClassName={"px-1"} text={"Expand"} style={{ margin: '3px' ,width: mobileView ? '60px' : "75px", fontSize: mobileView ? '10px' : '' }} size={"sm"}  onClick={() => handleParaphrasePrompt("expand") } />
 
        </div>
 
      </div>
 
 
      <div style={{ backgroundColor: "white", display: "flex", flexDirection: "column", height: "50vh", width: "100%", padding: "20px", boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', borderRadius: '8px', marginTop: "20px" }}>
        <div style={{ flex: "100%", display: "flex", flexDirection: "column", width: "100%", overflowY: "auto" }}>
          <Editor
            editorState={editorState}
         
            onChange={handleChange}
            style={{ flex: 1 }}
          />
        </div>
        <div style={{ flex: "15%", width: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
          <Button variant="contained" onClick={updatePraphrasePrompts}>Save</Button>
        </div>
      </div>
 
 
 
 
 
    </div>
  );
}

export default PromptsGenerator_Paraphrase;