const initialState = {
    leftSubadminNavbarOpen: true,
};
 
const handleSubAdminSideBarReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'OPEN_LEFT_NAVBAR':
            return {
                ...state,
                leftSubadminNavbarOpen: true
            };
        case 'CLOSE_LEFT_NAVBAR':
            return {
                ...state,
                leftSubadminNavbarOpen: false
            };
        default:
            return state;
    }
};
 
export default handleSubAdminSideBarReducer;