
export const setBlogClick = () => {
    console.log("inside blogggg")
    return {
        type: 'SET_BLOG'
    };
};

export const setDocumentClick = () => {
    console.log("inside doccc")
    return {
        type: 'SET_DOCUMENT'
    };
};
