import React, { useState, useEffect } from "react";
import BreadCrumb from "./Cards/BreadCrumbs";
import HeadingB from "./Cards/HeadingB";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import { InputGroup, FormControl, Button } from "react-bootstrap";
import Loader from "../utils/loader";
import { toast, ToastContainer } from "react-toastify";
import teamService from "../services/teamService";
import UserCard from "./Cards/UserCard";
import userService from "../services/userService";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { withRouter } from "react-router-dom";
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import SwapHorizRoundedIcon from '@mui/icons-material/SwapHorizRounded';
import team from "../static/noteams.png"
import DataGridMui from "./ReusableComponents/DataGridMui";
import adminServices from "../services/adminServices";

const SubAdminManageUser = ({ currentUser }) => {
    const [inviteEmails, setInviteEmails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [teamMembers, setTeamMembers] = useState([]);
    const [inviteLimit, setInviteLimit] = useState(currentUser.userCount);
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [adminModalShow, setAdminModalShow] = useState(false);
    const [currentRow, setCurrentRow] = useState(null)
    const [filterTeam, setFilterTeam] = useState([]);
    const [adminChanged, setAdminChanged] = useState(false)
    const history = useHistory()

    useEffect(() => {
        if (adminChanged) {
          history.push({
            pathname: `/`,
          });
        }
      }, [adminChanged]);

    useEffect(() => {
        // getTeam()
        getOrganizationUsers()
    }, []);

    const getOrganizationUsers = async () => {
        let currentUserEmail = sessionStorage.getItem("currentUserEmail")
        try {
            const response = await adminServices.getAllOrganizationUsers(currentUserEmail);
            // const response2 = await adminServices.getAllUsers2()
            console.log("organizationUsers", response)
            if (response.status === 200) {
                setTeamMembers(response.data);
                setFilterTeam(response.data);
                setLoading(false);
            } else if (response.status === 404) {
                setLoading(false);
                setTeamMembers(response.data);
                setFilterTeam(response.data);
            } else {
                setLoading(false);
            }
        } catch (error) {
            console.error("Error fetching team:", error);
            setLoading(false);
        }
    }

    //  Get All Team Members
    const getTeam = async () => {
        try {
            const teamMembers = await userService.getTeam();
            if (teamMembers.status === 200) {
                setTeamMembers(teamMembers.data.user);
                setFilterTeam(teamMembers.data.user);
                setLoading(false);
            } else if (teamMembers.status === 404) {
                setLoading(false);
                setTeamMembers(teamMembers.data.user);
                setFilterTeam(teamMembers.data.user);
            } else {
                setLoading(false);
            }
        } catch (error) {
            console.error("Error fetching team:", error);
            setLoading(false);
        }
    };

    const handleCheckFn = async (data) => {
        // console.dir(data);
    };



    // Delete Member
    const handleDelete = async (user) => {
        setLoading(true);

        try {
            const team = await userService.deleteTeam({ id: user.id });
            if (team.status !== 200) {
                toast.error(team.message);
            } else {
                toast.success(team.message);
                getTeam();
            }
        } catch (error) {
            console.error("Error deleting user:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleSearch = (e) => {
        if (e.target.value === "") {
            setFilterTeam(teamMembers);
        } else {
            const value = e.target.value.toLowerCase();

            const result = teamMembers.filter((member) =>
                member.name.toLowerCase().includes(value)
            );

            setFilterTeam(result);
        }
    };

    const columns = [
        // {
        //     field: 'id', headerName: 'ID',
        //     width: 90,
        //     flex: 0.2,
        // },
        {
            field: 'username',
            headerName: 'User Name',
            // width: 150,
            flex: 0.3,
            editable: false,
        },
        {
            field: 'email',
            headerName: 'Email',
            type: 'text',
            flex: 0.3,

        },
        {
            field: 'phone',
            headerName: 'Phone Number',
            type: 'number',
            // width: 110,
            flex: 0.3,

        },
        {
            field: 'userRole',
            headerName: 'User Role',
            flex: 0.2,
        },
        {
            field: 'planName',
            headerName: 'User Plan',
            flex: 0.2,
        },
        {
            field: 'delete',
            headerName: 'Delete',
            // flex: 0.1,
            renderCell: (params) => {
                // console.log("params", params)
                return (
                    <button
                        className="btn btn-md"
                        onClick={() => deleteUser(params)}
                    // disabled={params.row.isApproved == true ? true : false}
                    >
                        {<DeleteRoundedIcon fontSize="small" style={{color: "red"}} />}
                    </button>
                )
            }
        },
        {
            field: 'changeAdmin',
            headerName: 'Change Admin',
            // flex: 0.1,
            renderCell: (params) => {
                // console.log("params", params)
                return (
                    <button
                        className="btn btn-md"
                        onClick={() => changeAdmin(params)}
                    // disabled={params.row.isApproved == true ? true : false}
                    >
                        {<SwapHorizRoundedIcon fontSize="small" style={{color: "dodgerblue"}} />}
                    </button>
                )
            }
        },


        // {
        //     field: 'age',
        //     headerName: 'Subscription Plan',
        //     type: 'number',
        //     // width: 110,
        //     flex: 0.2,

        // },
        // {
        //     field: 'fullName',
        //     headerName: 'Full name',
        //     description: 'This column has a value getter and is not sortable.',
        //     sortable: false,
        //     // width: 160,
        //     flex: 0.6,
        //     valueGetter: (params) =>
        //         `${params.row.firstName || ''} ${params.row.lastName || ''}`,
        // },
    ];

    const deleteUser = (params) => {
        console.log("params", params)
        setCurrentRow(params.row)
        setDeleteModalShow(!deleteModalShow)
    }

    const deleteUserConfirm = async () => {
        let sendData = {
            email: currentRow.email,
            isDelete: true
        }
        setLoading(true)
        try {
            let response = await adminServices.subAdminDeleteUserDirectly(sendData)
            console.log("response", response)
            if (response.status == 200) {
                toast.success(response.message)
            }
            else{
                toast.info(response.message)
            }
        }
        catch (e) {
            console.log("error", e)
        }
        getOrganizationUsers()
        setDeleteModalShow(false)
        setLoading(false)
    }

    const changeAdmin = (params) => {
        console.log("params", params)
        setCurrentRow(params.row)
        setAdminModalShow(!adminModalShow)
    }

    const changeAdminConfirm = async () => {
        let sendData = {
            email: currentRow.email,
            isTransfer: true
        }
        setLoading(true)
        try {
            let response = await adminServices.subAdminTransferAdminRole(sendData)
            console.log("response", response)
            if (response.status == 200) {
                toast.success(response.message)
                setAdminChanged(true)
            }
            else{
                toast.info(response.message)
            }
        }
        catch (e) {
            console.log("error", e)
        }
        setAdminModalShow(false)
        // getOrganizationUsers()
        setLoading(false)
    }

    return (
        <div>
            <Loader loading={loading} />
            <ToastContainer />

            <div className="container-fluid" style={{ background: "#f0f0f7" }}>
                {/* <BreadCrumb l1="SubAdmin" l2="" dashboardlink="/dashboard" /> */}
                <div className="row justify-content-end w-100">
                    {/* Title */}


                    {/* Search Bar */}
                    {/* <div className="col-md-8">
                        <input
                            className="form-control searchBar"
                            name="searchQuery"
                            onChange={handleSearch}
                            type="text"
                            placeholder="&#xF002; Search group or Team Member"
                            aria-label="Search"
                            style={{
                                fontFamily: "FontAwesome",
                                border: "none",
                                //   width: "60%",
                                //   float: "left",
                            }}
                        />
                    </div> */}

                    {/* Send Invite Button */}
                    {/* <div className="col-md-2">
                        <OverlayTrigger
                            trigger="click"
                            placement="bottom"
                            // overlay={popover}
                            rootClose={true}
                        >
                            <button
                                className="btn btn-outline-primary inviteMember"
                                style={{ width: "90%" }}
                                disabled={true}
                            >
                                Invite
                            </button>
                        </OverlayTrigger>
                    </div> */}
                    {/* <div className="col-md-2 justify-content-end">
                        <OverlayTrigger
                            trigger="click"
                            placement="bottom"
                            // overlay={popover}
                            rootClose={true}
                        >
                            <button
                                className="btn btn-primary inviteMember"
                                style={{ width: "90%" }}
                                disabled={true}
                            >
                                Export
                            </button>
                        </OverlayTrigger>
                    </div> */}
                </div>

                <div className="row mt-2 w-100">
                    {/* {filterTeam.length === 0 ? ( */}
                    {filterTeam.length === 0 ? (
                        <div className="col-md-12 text-center">
                            <img src={team} alt="No Teams" />
                            <h3>No User Records found!</h3>
                        </div>
                    ) : (
                        <>
                            <div className="col-md-12">
                                <DataGridMui
                                    height="60vh"
                                    getRowId={(data) => data._id}
                                    columns={columns}
                                    rows={filterTeam}
                                    checkboxSelection={false}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>

            {/* <div className="container">
                <div className="row mt-5">
                    {filterTeam.length === 0 ? (
                        <div className="col-md-12 text-center">
                            <img src={team} alt="No Teams" />
                            <h3>No teams found!</h3>
                        </div>
                    ) : (
                        <>
                            <div className="col-md-12 p-2">
                                <DataGridMui
                                    getRowId={(data) => data.id}
                                    columns={columns}
                                    rows={sampleData}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div> */}

            {/* Delete User Modal */}
            <Modal
                isOpen={deleteModalShow}
                toggle={() => setDeleteModalShow(!deleteModalShow)}
                style={{
                    position: "absolute",
                    left: "43%",
                    top: "35%",
                }}
            >
                <ModalHeader
                    toggle={() => setDeleteModalShow(!deleteModalShow)}
                >

                    Delete User
                </ModalHeader>
                <ModalBody>
                    <div style={{ textAlign: "center" }}>
                        <p>
                            Are You Sure ? <br />
                            You want to delete <strong>{currentRow?.username}</strong>
                        </p>

                        <div>
                            <div className="col-md-12">
                                <button
                                    className="btn btn-primary hvr-float-shadow buysub"
                                    onClick={deleteUserConfirm}
                                >
                                    Confirm
                                </button>
                            </div>

                        </div>
                    </div>
                </ModalBody>
            </Modal>

            <Modal
                isOpen={adminModalShow}
                toggle={() => setAdminModalShow(!adminModalShow)}
                style={{
                    position: "absolute",
                    left: "43%",
                    top: "35%",
                }}
            >
                <ModalHeader
                    toggle={() => setAdminModalShow(!adminModalShow)}
                >

                    Change Admin
                </ModalHeader>
                <ModalBody>
                    <div style={{ textAlign: "center" }}>
                        <p>
                            Are You Sure ? <br />
                            You want to change <strong>{currentRow?.username}</strong> as Admin
                        </p>

                        <div>
                            <div className="col-md-12">
                                <button
                                    className="btn btn-primary hvr-float-shadow buysub"
                                    onClick={changeAdminConfirm}
                                >
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
}

const mapStateToProps = (state) => ({
    currentUser: state.user.currentUser,
});
const mapDispatchToProps = (dispatch) => ({});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(SubAdminManageUser));
