import React, { useState, useEffect } from 'react';
import DashboardServices, { getDocsStatusBarChart } from "../services/dashboardServices";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../utils/loader";
import NumberWidget from "./NumberWidget";
import Apex from "./ApexChartsUserFn";
// import DashboardCard from "../components/Cards/DasboardCard";
import DashboardCard from "../components/Cards/DasboardCard2";
import { Dropdown } from "react-bootstrap";
import Doc from ".././static/Doc.png";
import Group from ".././static/Group.png";
import Member from ".././static/Member.png";
import Rate from ".././static/Rate.png";
import Total from ".././static/Total.png";
import ReactTour from "./ReactTour";
import ImageCard from './ReusableComponents/ImageCard';
import documents from ".././static/admin_dashboard/totaldocs.png"
import pending_for_approval from ".././static/admin_dashboard/pendingforapproval.png"
import whiteboard from ".././static/admin_dashboard/whitebrd.png"
import chat from ".././static/admin_dashboard/chatcon.png"
import ApexChartsComp from './ReusableComponents/ApexChartsComp';
import PieChartComp from './ReusableComponents/PieChartComp';
import RemainderComp from './ReusableComponents/RemainderComp';
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getnotifications } from '../services/notficationService';
import StatusApexChartsComp from './ReusableComponents/StatusApexChartsComp';
import documentService from '../services/document';

const tourSteps = [
    {
        selector: ".reactour-1",
        content: () => (
            <div>
                <b>Widget Bar</b>
                <p>Select Different widgets to visualize various metrics.</p>
            </div>
        ),
        position: "bottom"
    },
    {
        selector: ".reactour-2",
        content: "Select year to visualize the performance metrics of that year.",
        position: "left"
    },
    {
        selector: ".reactour-3",
        content: "Graphical representation of metrics.",
        position: "top"
    }
];

const DashboardPage = () => {
    const [loading, setLoading] = useState(false);
    const [reactTour, setReactTour] = useState(false);
    const [selectYear, setSelectYear] = useState(new Date().getFullYear());
    const [selectMonth, setSelectMonth] = useState(new Date().getMonth() + 1);
    const [years, setYears] = useState([...Array(Number(new Date().getFullYear()) - 2018)].map((year, i) => 2019 + i));
    const [selected, setSelected] = useState(new Date().getFullYear());
    const [totalMyDocs, setTotalMyDocs] = useState(0);
    const [totalSharedDocs, setTotalSharedDocs] = useState(0)
    const [Total_Reviews, setTotalReviews] = useState("");
    const [Total_Teams, setTotalTeams] = useState("");
    const [Month_Wise_Document, setMonthWiseDocument] = useState([]);
    const [Month_Wise_Ratings, setMonthWiseRatings] = useState([]);
    const [MonthWise, setMonthWise] = useState([]);
    const [RatingWise, setRatingWise] = useState([]);
    const [Year, setYear] = useState("");
    const [isSelected, setIsSelected] = useState(false);
    const [borderColor, setBorderColor] = useState("");
    const [dashboardCardSetails, setDashboardCardDetails] = useState([]);
    const [cardmargin, setCardMargin] = useState(1);
    const [monthWiseAverageRatings, setMonthWiseAverageRatings] = useState([]);
    const [monthWiseTotalNoOfRatings, setMonthWiseTotalNoOfRatings] = useState([]);
    const [monthWiseTotalRatings, setMonthWiseTotalRatings] = useState([]);
    const [cardSelected, setCardSelected] = useState("");
    const [lineData, setLineData] = useState([]);
    const [bar1Data, setBar1Data] = useState([]);
    const [bar2Data, setBar2Data] = useState([]);
    const [lineColor, setLineColor] = useState("#ff3a29");
    const [linen, setLinen] = useState("");
    const [monthWiseTeams, setMonthWiseTeams] = useState([]);
    const [monthWiseRatedDocuments, setMonthWiseRatedDocuments] = useState([]);
    const userDetails = useSelector((user) => user)
    const userID = userDetails.user.currentUser._id
    const userRole = sessionStorage.getItem("Role")
    const [yearList, setYearList] = useState([])
    const [teamList, setTeamList] = useState([])
    const [barChartYear, setBarChartYear] = useState(new Date().getFullYear())
    const [selectedMember, setSelectedMember] = useState()
    const [isBarChartDropdown, setIsBarChartDropdown] = useState(false)
    const [isPieChartDropdown, setIsPieChartDropdown] = useState(false)
    const [imageCardData, setImageCardData] = useState({
        totalDocuments: "0",
        pendingForApproval: "0",
        unreadMsgCount: "0"
    })
    const [pieChartData, setPieChartData] = useState([])
    const [barChartData, setBarChartData] = useState({
        bar1Count: 0,
        bar2Count: 0,
        chartHeading: "Documents & Reviews",
        bar1Name: `Documents(0)`,
        bar2Name: `Reviews(0)`,
        xAxis: [
            "Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"
        ],
        // bar1Data: [0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 12],
        // xAxis: [],
        bar1Datas: [],
        bar2Data: [],
    })
    const [barDocsStatusChartData, setBarDocsStatusChartData] = useState({
        chartHeading: "Documents & Reviews Status",
        bar1Name: "Documents",
        bar2Name: "Reviews",
        xAxis: [
            "Pending", "Progress", "Completed", "Rejected", "Published"
        ],
        // bar1Data: [0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 12],
        // xAxis: [],
        counts: [],
        // bar2Data: [],
})
    const [notifications, setNotifications] = useState([]);

    // cmp1={barChartData.bar1Name}
    // cmp2={barChartData.bar2Name}
    // bar1={barChartData.bar1Data}
    // bar2={barChartData.bar2Data}
    // monthList={barChartData.xAxis}

    const { leftNavbarOpen } = useSelector(state => state.handleModelAndSideBavBar);


    const data2 = [
        { title: "In-progress", value: 20, color: "blue" },
        { title: "Rejected", value: 60, color: "skyblue" },
        { title: "Accepted", value: 20, color: "green" }
    ]

    // useEffect(() => {
    //     const year = new Date().getFullYear();
    //     getMOnthwiseRatings(year);
    //     getWidgetDetails(year);
    //     getmonthwiseTeams(year);
    // }, []);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
 
    // Update windowWidth on resize
    useEffect(() => {
      const handleResize = () => setWindowWidth(window.innerWidth);
   
      window.addEventListener('resize', handleResize);
      // if(window.innerWidth <768){
      //   setSidebarOpen(!isSidebarOpen)
      // }
   
      return () => window.removeEventListener('resize', handleResize);
    }, []);


    const convertForPieChart = (arr) => {
        let finalArr = []
        arr.map((obj) => {
            if (obj.value > 0) {
                finalArr.push({
                    title: obj.title,
                    value: Number(obj.value),
                    color: obj.color
                })
            }
        })
        console.log("finalarr", finalArr)
        return finalArr
    }

    useEffect(() => {
        console.log("userRole", userRole)
        if (userRole == "Individual") {
            getUserPieChartDetails(null)
        }
        else if (userRole == "Organization") {
            fetchTeamList()
        }
    }, [])

    useEffect(() => {
        fetchYearList()
        getUserDashCardDetails()
        getUserBarChartDetails(barChartYear)
    }, [])

    const fetchYearList = async () => {
        setLoading(true)
        try {
            let response = await DashboardServices.getYearList()
            console.log("fetchYearList", response)
            if (response.status == 200) {
                let finalArr = []
                response.yearList.map((obj) => finalArr.push({ name: obj, value: obj }))
                setYearList(finalArr)
                setIsBarChartDropdown(true)
            }
        }
        catch (error) {
            console.log("error", error)
        }
        setLoading(false)
    }



    const fetchTeamList = async () => {
        setLoading(true)
        try {
            let response = await DashboardServices.getTeamMembersDetails(userID)
            console.log("fetchTeamList", response)
            let finalArr = []
            response.memberNames.map((obj) => finalArr.push({ name: obj.name, value: obj.id }))
            setTeamList(finalArr)
            setIsPieChartDropdown(true)

        }
        catch (error) {
            console.log("error", error)
        }
        setLoading(false)
    }

    const getDocs = async () => {
        try {
            const response = await documentService.getAllMyDocuments();
            if (response.status === 200) {
                setTotalMyDocs(response.data.length);
            } else {
                console.error("Error fetching data:", response.message);
            }
        } catch (error) {
            console.error("Error calling backend API:", error.message);
        }
    };

    const getSharedDocs = async () => {
        try {
            const response = await documentService.getSharedDocuments();
            if (response.status === 200) {
                setTotalSharedDocs(response.data.length);
    
            } else {
                console.error("Error fetching data:", response.message);
            }
        } catch (error) {
            console.error("Error calling backend API:", error.message);
        }
    };

    useEffect(()=>{
        getDocs()
        getSharedDocs()

    },[])

    const getUserDashCardDetails = async () => {
        setLoading(true)
        try {
            let response = await DashboardServices.getIndividualDashCards(userID)
            console.log("getUserDashCardDetails", response)
            let { totalDocuments, pendingForApproval, unreadMsgCount } = response
            setImageCardData({
                "totalDocuments": totalDocuments, "pendingForApproval": pendingForApproval,"unreadMsgCount": unreadMsgCount
            }) 
        }
        catch (error) {
            console.log("error", error)
        }
        setLoading(false)
    }

    const getUserPieChartDetails = async (teamID) => {
        setLoading(true)
        try {
            let response = await DashboardServices.getIndividualUserPiechart(userID, teamID)
            console.log("getUserPieChartDetails", response)
            let finalData = convertForPieChart(response.data)
            setPieChartData(finalData)
        }
        catch (error) {
            console.log("error", error)
        }
        setLoading(false)
    }

    const getUserBarChartDetails = async (year) => {
        setLoading(true)
        try {
            let response = await DashboardServices.getIndividualBarChart(userID, year)
            console.log("getUserBarChartDetails", response)
            let { bar1, bar2, bar1Count, bar2Count } = response
            setBarChartData(prevState => ({
                ...prevState,
                bar1Datas: bar1,
                bar2Data: bar2,
                bar1Count: bar1Count,
                bar2Count: bar2Count,
                bar1Name: `Documents(${bar1Count})`,
                bar2Name: `Reviews(${bar2Count})`
            }));
            let isBar1 = bar1.some((obj) => obj > 0)
            let isBar2 = bar2.some((obj) => obj > 0)
            if (!isBar1 && !isBar2) {
                //toast.warn("No records were found")
            }
            console.log("getUserBarChartDetails", bar1, "bar2", bar2, "bar1Count", bar1Count, "bar2Count",bar2Count )
        }
        catch (error) {
            console.log("error", error)
        }
        setLoading(false)
    }
    // const getDocsStatusBarChartDetails = async () => {
    //     setLoading(true)
    //     try {
    //         let response = await DashboardServices.getDocsStatusBarChart(userID)
    //         console.log("getDocsStatusBarChart", response)
    //         let { count } = response
    //         setBarDocsStatusChartData({ ...barDocsStatusChartData, counts: count })
    //         let isBar1 = count.some((obj) => obj > 0)
    //         if (!isBar1) {
    //             toast.warn("No records were found")
    //         }
    //     }
    //     catch (error) {
    //         console.log("error", error)
    //     }
    //     setLoading(false)
    // }

    const handleFetchStatus = async () => {
        // setLoading(true)
       try {
        let response = await DashboardServices.getDocsStatusBarChart(userID);
        console.log("statusdata", response)
        let bar1 = response.counts
        console.log("bar1:", bar1)
        setBarDocsStatusChartData({ ...barDocsStatusChartData, counts: bar1 })
        console.log("bar1status2", bar1)
        let isBar1 = bar1.some((obj) => obj > 0)
        if (!isBar1) {
            //toast.warn("No records were found")
        }
        }
        catch (error) {
            console.log("error", error)
        }
        // setLoading(false)
    }
    useEffect(() => {
        handleFetchStatus();
    }, [])

    // const handleChangeBarChart = (e) => {
    //     console.log("handleChangeBarChart", e, e.target.value)
    //     let value = e.target.value
    //     setBarChartYear(value)
    //     getUserBarChartDetails(value)
    // }

    const handlePieChart = (e) => {
        console.log("handlePieChart", e, e.target.value)
        let value = e.target.value
        setSelectedMember(value)
        getUserPieChartDetails(value)
    }

    const handleBarChartYear = (year) =>{
        console.log("handleBarChartYear", year)
        let currentYear = new Date().getFullYear()
        if(year > currentYear){
            toast.warn("Please enter current or past year")
            return
        }
        getUserBarChartDetails(year)
    }
    // const handleDocsStatusBarChartYear = (year) =>{
    //     console.log("handleDocsStatusBarChartYear", year)
    //     let currentYear = new Date().getFullYear()
    //     if(year > currentYear){
    //         toast.warn("Please enter current or past year")
    //         return
    //     }
    //     handleFetchStatus()
    // }

    const getWidgetDetails = async (year) => {
        try {
            let widgdetails = await DashboardServices.getWidgetDetails(year);
            console.log(widgdetails);
            let widgData = [
                {
                    title: "Total Documentsss",
                    count: widgdetails.noOfDocuments,
                    colors: "#ff3a29",
                    img: Doc,
                    sparkData: widgdetails.monthWiseDocuments,
                },
                {
                    title: "Document's Rated",
                    count: widgdetails.noOfDocumentsRated,
                    colors: "#ffb200",
                    img: Rate,
                    sparkData: widgdetails.monthWiseRatings,
                },
                {
                    title: "Total Ratings",
                    count: widgdetails.totalRatings,
                    colors: "#34b53a",
                    img: Group,
                    sparkData: widgdetails.monthWiseTotalNoOfRatings,
                },
                {
                    title: "Average Ratings",
                    count: widgdetails.AverageRatings,
                    colors: "#4339f2",
                    img: Total,
                    sparkData: widgdetails.monthWiseAverageRatings,
                },
                {
                    title: "Total Members",
                    count: widgdetails.noOfTeams,
                    colors: "#af39f2",
                    img: Member,
                    sparkData: widgdetails.monthWiseTeams,
                },
            ];
            setSelected(year);
            setDashboardCardDetails(widgData);
            setLinen("reviews");
            setBar1Data(widgdetails.monthWiseDocuments);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching widget details:", error);
        }
    };


    //   const increment = () => {
    //     const newYear = selectYear + 1;
    //     console.log(newYear);
    //     setSelectYear(newYear);
    //     getdetails(newYear);
    //   };

    //   const decrement = () => {
    //     const newYear = selectYear - 1;
    //     console.log(newYear);
    //     setSelectYear(newYear);
    //     getdetails(newYear);
    //   };

    const getMOnthwiseRatings = async (year) => {
        try {
            let data = await DashboardServices.getMonthWiseRatings(year);
            console.log("getMOnthwiseRatings", data)
            setMonthWiseAverageRatings(data.monthWiseAverageRatings);
            setMonthWiseTotalNoOfRatings(data.monthWiseTotalNoOfRatings);
            setMonthWiseTotalRatings(data.monthWiseTotalRatings);
            setLineData(data.monthWiseTotalRatings);
            setBar1Data([]);
            setBar2Data([]);
        } catch (error) {
            console.error("Error fetching month-wise ratings:", error);
        }
    };

    const getmonthwiseTeams = async (year) => {
        try {
            let admindata = await DashboardServices.getMonthWiseTeamDetails(year);
            setMonthWiseTeams(admindata.monthWiseTeams);
        } catch (error) {
            console.error("Error fetching month-wise teams:", error);
        }
    };

    const selecctCard = async (details) => {
        setCardSelected(details.title);
        let year = selected; // assuming selected is another state variable

        if (details.title === "Average Ratings") {
            setLineData(monthWiseAverageRatings);
            setBar1Data([]);
            setBar2Data([]);
            setLineColor(details.colors);
            setLinen(details.title);
            setLoading(false);
        } else if (details.title === "Total Ratings") {
            setBar1Data([]);
            setBar2Data(monthWiseTotalNoOfRatings);
            setLineColor(details.colors);
            setLinen(details.title);
        } else if (details.title === "Total Documents") {
            try {
                let data = await DashboardServices.getWidgetDetails(year);
                setMonthWiseDocument(data.monthWiseDocuments);
                setLineData(data.monthWiseDocuments);
                setBar1Data(data.monthWiseDocuments);
                setBar2Data([]);
                setLineColor(details.colors);
                setLinen(details.title);
            } catch (error) {
                console.error("Error fetching widget details:", error);
            }
        } else if (details.title === "Total Members") {
            try {
                let data = await DashboardServices.getMonthWiseTeamDetails(year);
                setMonthWiseTeams(data.monthWiseTeams);
                setLineData(data.monthWiseTeams);
                setBar1Data([]);
                setBar2Data([]);
                setLineColor(details.colors);
                setLinen(details.title);
            } catch (error) {
                console.error("Error fetching month-wise teams:", error);
            }
        } else if (details.title === "Document's Rated") {
            try {
                let data = await DashboardServices.getRatedDocumentMonthwise(year);
                setMonthWiseRatedDocuments(data.monthWiseRatedDocuments);
                setLineData(data.monthWiseRatedDocuments);
                setBar1Data([]);
                setBar2Data([]);
                setLineColor(details.colors);
                setLinen(details.title);
            } catch (error) {
                console.error("Error fetching rated document month-wise:", error);
            }
        }
    };

    const getDocumentRatedMonthwise = async (year) => {
        try {
            let admindata = await DashboardServices.getRatedDocumentMonthwise(year);
            setMonthWiseRatedDocuments(admindata.monthWiseRatedDocuments);
            setSelected(year);
        } catch (error) {
            console.error("Error fetching rated document month-wise:", error);
        }
    };

    const _changeYear = async (year) => {
        setSelected(year);
        setCardSelected("");
        setLineColor("#ff3a29");

        try {
            await Promise.all([
                getMOnthwiseRatings(year),
                getDocumentRatedMonthwise(year),
                getWidgetDetails(year),
                getmonthwiseTeams(year),
            ]);
        } catch (error) {
            console.error("Error updating data for the new year:", error);
        }
    };

    const getNotifications = async () => {
        let notifications = await getnotifications();
        console.log("notifications", notifications)
        // setNotifications(notifications.notifications);
        // console.log(notifications);
    };

    useEffect(() => {
        getNotifications()
    }, [])

    return (
        <div style={{width:"100%",marginLeft:windowWidth < 768 ? "" : "-20px"}}>
            <ToastContainer />
            <ReactTour
                steps={tourSteps}
                isOpen={reactTour}
                closeTour={() => setReactTour(false)}
            />
            <Loader loading={loading} />
            <div
               
                style={{
                    display: "flex",
                    flexDirection:"column",
                    width:"100%"
                }}
            >
                <div
                   
                    style={{
                        display:"flex",
                        flexWrap:windowWidth < 768 ? "wrap" : "",
                        flexDirection:"row",
                        justifyContent:"space-between",
                        borderRadius:'12px',
                        width:'100%',
                        height:windowWidth < 768 ? "" : "10rem",
                        boxShadow: 'rgb(0, 0, 0) 2px 2px 5px -2px',
                        backgroundColor:'white',
                        alignItems:"center",
                        alignContent:"center"

                    }}
                >
                    <div  style={{width:windowWidth < 768 ? "50%" : "25%",borderRight:"2px solid #ccc", display:windowWidth < 768 ? "flex" : "flex",justifyContent:windowWidth < 768 ? "space-between" : "center",alignItems:windowWidth < 768 ? "center" : "center",alignContent:windowWidth < 768 ? "center" : "center",paddingTop:windowWidth < 768 ? "10px" : "",paddingBottom:windowWidth < 768 ? "10px" : ""}}>
                        <Link to="/mydocuments">
                            <ImageCard
                                // bgColor="#4676A5"
                                txtColor="#4676A5"
                                bgColor="white"
                                altImg="total documents"
                                imgSrc={documents}
                                cardName="Total Documents"
                                number={(totalMyDocs + totalSharedDocs) || "0"}
                            />
                        </Link>
                    </div>
                    <div style={{width:windowWidth < 768 ? "50%" : "25%",borderRight:windowWidth < 768 ? "" : "2px solid #ccc", display:windowWidth < 768 ? "flex" : "flex",justifyContent:windowWidth < 768 ? "space-between" : "center",alignItems:windowWidth < 768 ? "center" : "center",alignContent:windowWidth < 768 ? "center" : "center",paddingTop:windowWidth < 768 ? "10px" : "",paddingBottom:windowWidth < 768 ? "10px" : ""}}>
                        <Link to="/mydocuments">
                            <ImageCard
                                // bgColor="#DBBC00"
                                txtColor="#DBBC00"
                                bgColor="white"
                                altImg="pending approval"
                                imgSrc={pending_for_approval}
                                cardName="Pending for approval"
                                number={imageCardData.pendingForApproval || "0"}
                            />
                        </Link>
                    </div>
                    <div style={{width:windowWidth < 768 ? "50%" : "25%",borderRight:"2px solid #ccc",borderTop:windowWidth < 768 ? "2px solid #ccc" : "", display:windowWidth < 768 ? "flex" : "flex",justifyContent:windowWidth < 768 ? "space-between" : "center",alignItems:windowWidth < 768 ? "center" : "center",alignContent:windowWidth < 768 ? "center" : "center",paddingTop:windowWidth < 768 ? "10px" : "",paddingBottom:windowWidth < 768 ? "10px" : ""}}>
                        <Link>
                        <ImageCard
    txtColor="#7A4587"
    bgColor="white"
    altImg="white board"
    imgSrc={whiteboard}
    cardName={
        <>
           <span style={{color:"white"}}>nk</span> White Board
        </>
    }
    number="0"
/>

                        </Link>
                     
                    </div>
                    <div style={{width:windowWidth < 768 ? "50%" : "25%", display:windowWidth < 768 ? "flex" : "flex",justifyContent:windowWidth < 768 ? "space-between" : "center",alignItems:windowWidth < 768 ? "center" : "center",alignContent:windowWidth < 768 ? "center" : "center",borderTop:windowWidth < 768 ? "2px solid #ccc" : "",paddingTop:windowWidth < 768 ? "10px" : "",paddingBottom:windowWidth < 768 ? "10px" : ""}}>
                        <Link to="/chat">
                        <ImageCard
                            // bgColor="#18A350"
                            txtColor='#18A350'
                            bgColor="white"
                            altImg="chats"
                            imgSrc={chat}
                            cardName="Organization Chats "
                            number={imageCardData.unreadMsgCount || "0"}
                        />
                        </Link>
                    </div>
                </div>
                <div
                    style={{
                        width:"100%",
                        marginTop:"20px"
                    }}
                >
<div style={{
    width:"100%",
    display:"flex",
    flexDirection:windowWidth < 768 ? "column" : "row",
    justifyContent:"space-between"
}}>
<div 
                     style={{
                        width:windowWidth < 768 ? "100%" : "36%",boxShadow: "2px 2px 5px -2px #000000"
                    }}
                    >
                        {userRole == "Individual" && pieChartData.length > 0 ?
                            <PieChartComp
                                // pieChartData={data2}
                                pieChartData={pieChartData}
                                chartHeading="Document Corrections"
                            />
                            : isPieChartDropdown ?
                                <PieChartComp
                                    pieChartData={pieChartData}
                                    chartHeading="Document Corrections"
                                    isSelectDropdownShown={isPieChartDropdown}
                                    pieChartSelectOptions={teamList}
                                    pieChartValue={undefined == selectedMember ? "" : selectedMember}
                                    pieChartSelectLabel="Member"
                                    pieChartHandleChange={handlePieChart}

                                />
                                :
                                <div
                                    // className='align-items-center'
                                    style={{ fontWeight: "bold" }}
                                >No Pie Chart data available</div>
                        }

                        {/* {userRole == "Organization" && isPieChartDropdown ?
                            <PieChartComp
                                // pieChartData={data2}
                                pieChartData={pieChartData}
                                chartHeading="Document Corrections"
                                isSelectDropdownShown={isPieChartDropdown}
                                pieChartSelectOptions={teamList}
                                pieChartValue={selectedMember}
                                pieChartSelectLabel="member"
                                pieChartHandleChange={handlePieChart}

                            />
                            :
                            <div
                                // className='align-items-center'
                                style={{ fontWeight: "bold" }}
                            >No Pie Chart data available</div>
                        } */}
                    </div>
                    <div  
                     style={{
                        width:windowWidth < 768 ? "100%" : "63%",
                        marginTop:windowWidth < 768 ? "10px" : "",
                        backgroundColor:"white",
                boxShadow: "2px 2px 5px -2px #000000",
                    }}
                    >
                        {/* {Object.keys(barChartData).length > 0 ? */}
                        {/* {isBarChartDropdown ? */}
                            <StatusApexChartsComp
                                chartHeading={barDocsStatusChartData.chartHeading}
                                cmp1={barChartData.bar1Name}
                                cmp2={barChartData.bar2Name}
                                bar1={barDocsStatusChartData.counts}
                                xAxisList={barDocsStatusChartData.xAxis}
                                isSelectDropdownShown={isBarChartDropdown}
                                barChartSelectOptions={yearList}
                                // barChartHandleChange={handleChangeBarChart}
                                barChartValue={barChartYear}
                                barChartSelectLabel="Year"
                            />
                            {/* :
                            <div
                                // className='align-items-center'
                                style={{ fontWeight: "bold" }}
                            >No Bar Chart data available</div>
                        } */}
                    </div>
</div>
                  
                    <div 
                     style={{
                        width:"100%",
                        marginTop:windowWidth < 768 ? "15px" : '30px',
                        backgroundColor:"white",
                boxShadow: "2px 2px 5px -2px #000000",
                    }}
                    >
                        {/* {Object.keys(barChartData).length > 0 ? */}
                        {isBarChartDropdown ?
                            <ApexChartsComp
                                chartHeading={barChartData.chartHeading}
                                cmp1={barChartData.bar1Name}
                                cmp2={barChartData.bar2Name}
                                bar1Count={barChartData.bar1Count}
                                bar2Count={barChartData.bar2Count}
                                bar1={barChartData.bar1Datas}
                                bar2={barChartData.bar2Data}
                                xAxisList={barChartData.xAxis}
                                isSelectDropdownShown={isBarChartDropdown}
                                barChartSelectOptions={yearList}
                                // barChartHandleChange={handleChangeBarChart}
                                barChartValue={barChartYear}
                                barChartSelectLabel="Year"
                                onChangeBarChartYear={handleBarChartYear}
                            />
                            :
                            <div
                                // className='align-items-center'
                                style={{ fontWeight: "bold" }}
                            >No Bar Chart data available</div>
                        }
                    </div>
                </div>
                {notifications.length > 0 &&
                    <div
                        className="userRemainder"
                        style={{
                            gridColumn: 2,
                            // gridRow: "1 / span 2"
                            gridRow: "1 / span 1"
                        }}
                    >
                        <RemainderComp data={notifications} />
                    </div>}
            </div>
        </div>
    );
}
export default DashboardPage;
