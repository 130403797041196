import React, { useEffect, useState } from "react";
import ProgressBarMui from "./ProgressBarMui";
import ProgressBarComp from "./ProgressBarComp";
import ForumRoundedIcon from '@mui/icons-material/ForumRounded';

const GrammarCheckAssistant = ({mobileView,setShowAssistant,handleGoals, handleAIassistant, handleAIReview,grammarFluency, trial}) => {
    const [isOpen, setIsOpen] = useState(true)
    const [correctnessPercent,setCorrectnessPercent] = useState(0);
    const [clarityPercent,setClarityPercent] = useState(0);
    const [engagePercent,setEngagePercent] = useState(0);
    const [deliveryPercent,setDeliveryPercent] = useState(0);
    const [overAllScore,setOverAllScore] = useState(0);

    useEffect(()=>{
        console.log("grammarFluency_",grammarFluency);
        if(grammarFluency){
            setCorrectnessPercent(grammarFluency.correctness)
            setClarityPercent(grammarFluency.fluency)
            setEngagePercent(grammarFluency.engagement)
            setDeliveryPercent(grammarFluency.delivery)
            setOverAllScore(grammarFluency.OverallScore)
        }
    },[grammarFluency])

    const toggle = () => {
        mobileView ? setShowAssistant(false) :
        setIsOpen(!isOpen)
    }

    return (
        <div
            style={{
                backgroundColor: "#ffffff",
                borderRadius: "8px",
                boxShadow: "2px 2px 5px -2px #000000",
                padding: "9px",
                overflow: "hidden",
                height: mobileView ? '100%' : (isOpen ? "auto" : "3rem"),
                transition: "height 0.3s"
            }}
        >
            <div
                style={{
                    color: "#393939",
                    fontFamily: 'Poppins, sans-serif',
                    fontWeight: 600,
                    fontSize: "0.8rem",
                    cursor: "pointer"
                }}
                onClick={toggle}
            >
                {mobileView ? "Hide Assistant >>" : (isOpen ? "Hide Assistant >>" : "Show Assistant <<")}
            </div>
            <div
                style={{
                    height: "74vh",
                    // overflowY: "auto",
                    padding: "3px",
                    margin: "0 auto"
                }}
            >
                <div
                    className="mt-4"
                    style={{
                        border: "1px solid #B2B2B2",
                        borderRadius: "8px",
                        padding: "4px"
                    }}
                >
                    <span
                        style={{
                            textAlign: "left", fontSize: mobileView ? '13px' : ''
                        }}
                    >Overall Score</span>
                    <span
                        style={{
                            float: "right", fontSize: mobileView ? '13px' : ''
                        }}
                    >{overAllScore}</span>
                </div>
                {/* <div
                    className="mt-3"
                    onClick={handleGoals}
                    style={{
                        border: "1px solid #B2B2B2",
                        borderRadius: "8px",
                        padding: "4px",
                        cursor: "pointer"
                    }}
                >
                    <span
                        style={{
                            textAlign: "left"
                        }}
                    >Goals</span>
                    
                </div>
                <div
                onClick={handleAIassistant}
                    className="mt-3"
                    style={{
                        border: "1px solid #B2B2B2",
                        borderRadius: "8px",
                        padding: "4px",
                        cursor: "pointer"
                    }}
                >
                    <span
                        style={{
                            textAlign: "left"
                        }}
                    >AI Assistant</span>
                    
                </div> */}
                <hr style={{ borderTop: "1px solid #A0A0A0" }} />
                {/* <ProgressBarMui /> */}
                <div className="mt-4">
                    <ProgressBarComp
                        mobileView = {mobileView}
                        progressLabel="Correctness"
                        checkCircleColor="#EA1537"
                        bgColor="#40E100"
                        completed={correctnessPercent}
                    />
                </div>

                <div className="mt-4">
                    <ProgressBarComp
                    mobileView = {mobileView}
                        progressLabel="Clarity"
                        checkCircleColor="#4A6EE0"
                        bgColor="red"
                        completed={clarityPercent}
                    />
                </div>
                <div className="mt-4">
                    <ProgressBarComp
                    mobileView = {mobileView}
                        progressLabel="Engagement"
                        checkCircleColor="#4AE09E"
                        bgColor="#EAD700"
                        completed={engagePercent}
                    />
                </div>

                <div className="mt-4">
                    <ProgressBarComp
                    mobileView = {mobileView}
                        progressLabel="Delivery"
                        checkCircleColor="#9D15EA"
                        bgColor="#9D15EA"
                        completed={deliveryPercent}
                    />
                </div>

                <hr style={{ borderTop: "1px solid #A0A0A0" }} />

                {/* <div
                    className="mt-2"
                    style={{
                        border: "1px solid #767676",
                        borderRadius: "8px",
                        padding: "4px",
                        margin: "0px auto",
                        textAlign: "center"
                    }}
                >

                    <ForumRoundedIcon style={{ color: "dodgerblue" }} />
                    <span
                    // style={{
                    //     textAlign: "left"
                    // }}
                    >Comments</span>
                </div>

                <hr style={{ borderTop: "1px solid #A0A0A0" }} /> */}


              
                <div
                    className="mt-2"
                    onClick={handleAIReview}
                    style={{
                        border: "1px solid #3295F7",
                        borderRadius: "8px",
                        padding: "4px",
                        margin: "0 auto",
                        cursor: "pointer"
                    }}
                >
                    <span
                   style={{fontSize: mobileView ? '13px' : ''}}
                    >AI Review</span>
                </div>

                {/* <ul
                    style={{
                        listStyle: "none"
                    }}
                >
                    {
                        list.map((obj) => {
                            return <li>
                                <div
                                    className="mt-2"
                                    style={{
                                        backgroundColor: "#ffffff",
                                        borderRadius: "8px",
                                        boxShadow: "2px 2px 5px -2px #000000",
                                        padding: "7px"
                                    }}
                                >
                                    <p>{obj}</p>
                                    <p>Sept 17 | 10:00 to 11:00 AM </p>
                                    <div
                                    className="justify-content-end"
                                    >
                                    <button className="btn btn-primary">Join now</button>
                                    </div>
                                </div>
                            </li>
                        })
                    }
                </ul> */}
            </div>
        </div>
    )
}

export default GrammarCheckAssistant