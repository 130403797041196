import React, { useState, useEffect } from "react";
import BreadCrumb from "./Cards/BreadCrumbs";
import HeadingB from "./Cards/HeadingB";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import { InputGroup, FormControl, Button } from "react-bootstrap";
import Loader from "../utils/loader";
import { toast, ToastContainer } from "react-toastify";
import teamService from "../services/teamService";
import UserCard from "./Cards/UserCard";
import userService from "../services/userService";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import team from "../static/noteams.png"
import { FaEdit } from 'react-icons/fa';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import DataGridMui from "./ReusableComponents/DataGridMui";
import adminServices from "../services/adminServices";
import InputBoxMui from "./ReusableComponents/InputBoxMui";

const SubAdminUserRequest = ({ currentUser }) => {
    const [inviteEmails, setInviteEmails] = useState([]);
    const [loading, setLoading] = useState(false);
    const [teamMembers, setTeamMembers] = useState([]);
    const [inviteLimit, setInviteLimit] = useState(currentUser.userCount);
    const [modalShow, setModalShow] = useState(false);
    const [filterTeam, setFilterTeam] = useState([]);
    const [msg, setMsg] = useState("")
    const [userDetails, setUserDetails] = useState("")
    const [currentRow, setCurrentRow] = useState(null)
    const [isRejectModal, setIsRejectModal] = useState(false)
    const [comment, setComment] = useState("")
    const [popupMsg, setPopupMsg] = useState({
        "Sign Up": "New User has signed up. Pending Approval",
        "Delete": "User has requested to delete the Account",
        // "OTP": `User has asked for ${currentRow !== null && currentRow["typeOfRequest"]} request`,
        "OTP": `User has asked for ${currentRow !== null && currentRow["OTPRequest"]} request`,
        otpMobileDisable: "User has requested to disable two-factor Authentication",
        otpMobileEnable: "User has requested to enable two-factor Authentication",
        otpEmailEnable: "User has requested to enable Email OTP",
        otpEmailDisable: "User has requested to disable Email OTP",
    })

    useEffect(() => {
        // getTeam()
        getAllUserRequests()
    }, []);

    const handleApprove = async () => {
        console.log("handleApprove", currentRow)
        try {
            let sendData = {
                requestId: currentRow._id,
                email: currentRow.email,
                typeOfRequest: currentRow.typeOfRequest == "Delete Account" ? "Delete" : currentRow.typeOfRequest,
                isApproved: true,
            }
            setLoading(true)
            let response = await adminServices.subAdminApproveRequest(sendData)
            setLoading(false)
            console.log("handleApproveResponse", response)
            if (response.status == 200) {
                toast.success(response.message)
            }
            else {
                toast.error(response.message)
            }
        }
        catch (error) {
            console.log("error", error)
        }
        // if (currentRow.typeOfRequest == "Delete Request") {
        //     handleApproveDelete({email: currentRow.email})
        // }
        setModalShow(false)
        getAllUserRequests()
    }

    const showRejectModal = () => {
        setModalShow(false)
        setIsRejectModal(!isRejectModal)
    }

    const handleComment = (e) => {
        let value = e.target.value
        console.log("handleComment", value)
        setComment(value)
    }

    const handleReject = async () => {
        console.log("handleReject", currentRow)
        try {
            let sendData = {
                requestId: currentRow._id,
                email: currentRow.email,
                typeOfRequest: currentRow.typeOfRequest == "Delete Account" ? "Delete" : currentRow.typeOfRequest,
                isApproved: false,
                comment: comment,
            }
            setLoading(true)
            let response = await adminServices.subAdminApproveRequest(sendData)
            setLoading(false)
            console.log("handleRejectResponse", response)
            if (response.status == 200) {
                toast.success(response.message)
            }
            else {
                toast.error(response.message)
            }
        }
        catch (error) {
            console.log("error", error)
        }
        // setModalShow(false)
        setIsRejectModal(false)
        getAllUserRequests()
    }

    const getAllUserRequests = async () => {
        let currentUserEmail = sessionStorage.getItem("currentUserEmail")
        setLoading(true)
        try {
            const response = await adminServices.getAllRequests(currentUserEmail);
            // const response = await adminServices.getAllOrganizationUsers(currentUserEmail);
            console.log("userRequests", response)
            if (response.status === 200) {
                setTeamMembers(response.data);

                let newData = response.data
                let finalArr = []
                newData.map((obj) => {
                    finalArr.push({
                        ...obj,
                        // "status": obj.isApproved == true ? "Approved" : "Pending"
                        // "typeOfRequest": "Delete Request"
                    })
                })
                console.log("finalArr", finalArr)
                setFilterTeam(finalArr);
                // setFilterTeam(response.data);
                setLoading(false);
            } else if (response.status === 404) {
                setLoading(false);
                setTeamMembers(response.data);
                setFilterTeam(response.data);
            } else {
                setLoading(false);
            }
        } catch (error) {
            console.error("Error fetching team:", error);
            setLoading(false);
        }
        setLoading(false)
    }

    //  Get All Team Members
    const getTeam = async () => {
        try {
            const teamMembers = await userService.getTeam();
            if (teamMembers.status === 200) {
                setTeamMembers(teamMembers.data.user);
                setFilterTeam(teamMembers.data.user);
                setLoading(false);
            } else if (teamMembers.status === 404) {
                setLoading(false);
                setTeamMembers(teamMembers.data.user);
                setFilterTeam(teamMembers.data.user);
            } else {
                setLoading(false);
            }
        } catch (error) {
            console.error("Error fetching team:", error);
            setLoading(false);
        }
    };

    const handleCheckFn = async (data) => {
        // console.dir(data);
    };

    // Send Invite
    const sendInvite = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (inviteLimit < inviteEmails.length + teamMembers.length) {
            setLoading(false);
            setModalShow(true);
            setInviteEmails([]);
            return;
        }

        if (inviteEmails.length < 1) {
            setLoading(false);
            toast.error("Please enter valid email!");
        } else {
            try {
                const team = await teamService.inviteMember(inviteEmails);
                if (team.status !== 200) {
                    toast.error(team.message);
                } else {
                    toast.success(team.message);
                    getTeam();
                }
            } catch (error) {
                console.error("Error sending invite:", error);
            } finally {
                setLoading(false);
                setInviteEmails([]);
            }
        }
    };

    // Delete Member
    const handleDelete = async (user) => {
        setLoading(true);

        try {
            const team = await userService.deleteTeam({ id: user.id });
            if (team.status !== 200) {
                toast.error(team.message);
            } else {
                toast.success(team.message);
                getTeam();
            }
        } catch (error) {
            console.error("Error deleting user:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleSearch = (e) => {
        if (e.target.value === "") {
            setFilterTeam(teamMembers);
        } else {
            const value = e.target.value.toLowerCase();

            const result = teamMembers.filter((member) =>
                member.name.toLowerCase().includes(value)
            );

            setFilterTeam(result);
        }
    };

    const columns = [
        // {
        //     field: 'id', headerName: 'ID',
        //     width: 90,
        //     flex: 0.2,
        // },
        // {
        //     field: 'username',
        //     headerName: 'User Name',
        //     // width: 150,
        //     flex: 0.1,
        //     editable: false,
        // },
        {
            field: 'email',
            headerName: 'Email',
            type: 'text',
            flex: 0.2,

        },
        // {
        //     field: 'phone',
        //     headerName: 'Phone Number',
        //     type: 'number',
        //     // width: 110,
        //     // flex: 0.2,
        // },
        {
            field: 'userRole',
            headerName: 'User Role',
            // flex: 0.2,
        },
        {
            field: 'typeOfRequest',
            headerName: 'Type of Request',
            flex: 0.1,
        },
        {
            field: 'userStatus',
            headerName: 'Status',
            flex: 0.1,
        },
        {
            field: 'Comment',
            headerName: 'Comment',
            flex: 0.1,
        },

        {
            field: '',
            headerName: 'Edit',
            // flex: 0.1,
            renderCell: (params) => {
                console.log("params", params)
                return (
                    <button
                        className="btn btn-md"
                        onClick={() => handleEdit(params)}
                        // disabled={params.row.isApproved == true ? true : false}
                        disabled={(params.row.userStatus == "pending") || (params.row.userStatus == "Rejected") ? false : true}
                    >
                        {<BorderColorRoundedIcon fontSize="small" style={{color: "dodgerblue"}} />}
                        {/* {<FaEdit />} */}
                    </button>
                )
            }
        },
    ];

    const handleEdit = (params) => {
        console.log("params", params)
        setCurrentRow(params.row)
        let { email, typeOfRequest, OTPRequest } = params.row
        console.log("typeOfRequest", typeOfRequest)
        setUserDetails(email)
        if (typeOfRequest.includes("OTP") == true) {
            setMsg(`User has asked for ${OTPRequest} request`)
        } else {
            setMsg(popupMsg[typeOfRequest])
        }

        if(params.row.userStatus == "Rejected"){
            setIsRejectModal(true)
            return
        }
        setModalShow(!modalShow)
    }

    const handleApproveDelete = async (email) => {
        setLoading(true)
        try {
            let response = await adminServices.deleteOrganizationUser(email)
            console.log("handleApproveDelete", response)
            if (response.status == 200) {
                toast.success(response.message)
            }
            else {
                toast.error(response.message)
            }
        }
        catch (error) {
            console.log(error)
        }
        setLoading(false)
    }

    return (
        <div>
            <Loader loading={loading} />
            <ToastContainer />

            <div className="container-fluid" style={{ background: "#f0f0f7" }}>
                {/* <BreadCrumb l1="SubAdmin" l2="" dashboardlink="/dashboard" /> */}
                <div className="row justify-content-end w-100">
                    {/* Title */}
                    {/* Search Bar */}
                    {/* <div className="col-md-8">
                        <input
                            className="form-control searchBar"
                            name="searchQuery"
                            onChange={handleSearch}
                            type="text"
                            placeholder="&#xF002; Search group or Team Member"
                            aria-label="Search"
                            style={{
                                fontFamily: "FontAwesome",
                                border: "none",
                                //   width: "60%",
                                //   float: "left",
                            }}
                        />
                    </div> */}

                    {/* Send Invite Button */}
                    {/* <div className="col-md-2">
                        <OverlayTrigger
                            trigger="click"
                            placement="bottom"
                            // overlay={popover}
                            rootClose={true}
                        >
                            <button
                                className="btn btn-outline-primary inviteMember"
                                style={{ width: "90%" }}
                                disabled={true}
                            >
                                Invite
                            </button>
                        </OverlayTrigger>
                    </div> */}
                    {/* <div className="col-md-2 justify-content-end">
                        <OverlayTrigger
                            trigger="click"
                            placement="bottom"
                            // overlay={popover}
                            rootClose={true}
                        >
                            <button
                                className="btn btn-primary inviteMember"
                                style={{ width: "90%" }}
                                disabled={true}
                            >
                                Export
                            </button>
                        </OverlayTrigger>
                    </div> */}
                </div>

                <div className="row mt-2 w-100">
                    {/* {filterTeam.length === 0 ? ( */}
                    {filterTeam.length === 0 ? (
                        <div className="col-md-12 text-center">
                            <img src={team} alt="No Teams" />
                            <h3>No User Request found!</h3>
                        </div>
                    ) : (
                        <>
                            <div className="col-md-12">
                                <DataGridMui
                                    height="60vh"
                                    getRowId={(data) => data._id}
                                    columns={columns}
                                    rows={filterTeam}
                                    checkboxSelection={false}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>

            {/* <div className="container">
                <div className="row mt-5">
                    {filterTeam.length === 0 ? (
                        <div className="col-md-12 text-center">
                            <img src={team} alt="No Teams" />
                            <h3>No teams found!</h3>
                        </div>
                    ) : (
                        <>
                            <div className="col-md-12 p-2">
                                <DataGridMui
                                    getRowId={(data) => data.id}
                                    columns={columns}
                                    rows={sampleData}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div> */}

            {/* Modal */}
            <Modal
                isOpen={modalShow}
                toggle={() => setModalShow(!modalShow)}
                style={{
                    position: "absolute",
                    left: "43%",
                    top: "35%",
                }}
            >
                <ModalHeader
                    toggle={() => setModalShow(!modalShow)}
                    className="text-center"
                >
                    User Request
                </ModalHeader>
                <ModalBody>
                    <div style={{ textAlign: "center" }}>
                        <p>
                            {msg !== "" ? msg : ""} <br />
                            <strong>{userDetails}</strong>
                        </p>
                        <div className="row">
                            <div className="col-md-6">
                                <button className="btn btn-primary"
                                    onClick={handleApprove}
                                >
                                    Approve
                                </button>
                            </div>
                            <div className="col-md-6">
                                <button className="btn btn-secondary"
                                    onClick={showRejectModal}
                                >
                                    Reject
                                </button>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>


            {/* comment modal */}
            <Modal
                isOpen={isRejectModal}
                toggle={() => setIsRejectModal(!isRejectModal)}
                style={{
                    position: "absolute",
                    left: "43%",
                    top: "35%",
                }}
            >
                <ModalHeader
                    toggle={() => setIsRejectModal(!isRejectModal)}
                    className="text-center"
                >
                    Reason for Rejection
                </ModalHeader>
                <ModalBody>
                    <div style={{ textAlign: "center" }}>
                        <div>
                            <InputBoxMui
                                label="Comment"
                                multiline={true}
                                rows={2}
                                onChange={handleComment}
                            />
                            <p
                                style={{
                                    fontSize: "0.7rem",
                                    color: "red"
                                }}
                            >{comment.length > 30 ? "maximum characters exceeded" : ""} </p>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-12">
                                <button className="btn btn-primary"
                                    onClick={handleReject}
                                    disabled={comment.length > 0 ? false : true}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
}

const mapStateToProps = (state) => ({
    currentUser: state.user.currentUser,
});
const mapDispatchToProps = (dispatch) => ({});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(SubAdminUserRequest));
