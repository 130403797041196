import React from "react";
import AdminSideNavBar from "../AdminSideNavBar";
import NetworkDetector from "../HigherOrderComponents/NetworkDetector";
// import NumberWidget from "../NumberWidget";
// import Invoice from "../Invoice";
// import AdminDash from "../AdminDashboard";
import AdminDash from "../AdminDashboard2";
import queryString from "query-string";

const AdminDashBoard = (props) => {
  if (props.location.search) {
    let values = queryString.parse(props.location.search);
    sessionStorage.setItem("token", values.token);
  }
  return (
    <AdminSideNavBar>
      <AdminDash />
    </AdminSideNavBar>
  );
};

export default NetworkDetector(AdminDashBoard);
