import React, { useState, useRef,useEffect } from "react";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import { useSelector } from "react-redux";

const Tags = ({ tags, removeTags, onFocus,content, onKeyDown,modalOpen ,istagsEnter,setIsTagsEnter, viewOnlyAccess}) => {
  const [inputValue, setInputValue] = useState("");
  const tagInputRef = useRef(null);

  const { leftNavbarOpen } = useSelector(state => state.handleModelAndSideBavBar);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
 
  // Update windowWidth on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
 
    window.addEventListener('resize', handleResize);
    // if(window.innerWidth <768){
    //   setSidebarOpen(!isSidebarOpen)
    // }
 
    return () => window.removeEventListener('resize', handleResize);
  }, []);



  useEffect(()=>{
    if(istagsEnter){
      setInputValue("")
      setIsTagsEnter(false)
    }
  },[istagsEnter])

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleRemoveTag = (index) => {
    removeTags(index);
  };

  const handleInputFocus = () => {
    onFocus();
  };

  const handleInputKeyDown = (e) => {
    onKeyDown(e);
  };

  return (
    <div className="input-tag" style={{height:windowWidth < 768 ? "70px" : "", width:windowWidth < 768 ? "100%" : "55%", marginTop: "10px",overflowY:"auto"}}>
      <ul className="input-tag__tags">
        {tags?.map((tag, i) => (
          <li key={tag}>
            {tag}
            <button type="button" onClick={() => handleRemoveTag(i)}>
              +
            </button>
          </li>
        ))}
        <li className="input-tag__tags__input">
          <input
            id="tags"
            type="text"
            placeholder="# Add tags"
            value={inputValue}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            onKeyDown={handleInputKeyDown}
            // ref={tagInputRef}
            disabled={((content === '') || viewOnlyAccess) ? true:false}
          />
          <LocalOfferIcon />
        </li>
      </ul>
    </div>
  );
};

export default Tags;
