import React from 'react';
import {useState, useEffect} from "react";
import { Editor, EditorState, ContentState } from 'draft-js';
import FeatureButton from '../ReusableComponents/Button';
import Button from '@mui/material/Button';
import { getPlagiarismPrompt, updatePlagiarismPrompt } from '../../services/promptsGeneratorServices';
import Loader from '../../utils/loader';
import { toast } from 'react-toastify';





const PromptsGenerator_Plagiarism = () => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
   const [featureName, setFeatureName] = useState("Plagiarismdetails");
   const [loading, setLoading] = useState(false);

    
const handleChange= (newEditorState) =>{
    setEditorState(newEditorState)
}


const handlePlagiarismPrompt = async (type) => {
  
    try {
      const data = {
        featureName: featureName
      
    
      };
      

  
      setLoading(true)
      const result = await getPlagiarismPrompt(data);
      if (result.prompt_information !== "") {
 
        const contentState = ContentState.createFromText(result.prompt_information);
        const newEditorState = EditorState.createWithContent(contentState);
        setEditorState(newEditorState);
      }


    } catch (error) {
      console.error('Error calling paraphrasePrompt:', error);
    }


    setLoading(false)
  };




  const updatePlagiarismPrompts = async () => {

    const text = editorState.getCurrentContent().getPlainText()
 if(text === ""){
   toast.warn("Nothing to save");
   return;
 }
 
 
     try {
       const data = {
        prompt_information : text,
         featureName: featureName, 
       
       };
   
       setLoading(true);
       const result = await updatePlagiarismPrompt(data);
       if (result.prompt_information !== "") {
         console.log("Response from ParaPrompt API", result);
         console.log("Response from ParaPrompt API", result.data);
 
        
         toast.success("Data saved successfully")
       }
     } catch (error) {
       console.error('Error calling paraphrasePrompt:', error);
     }
 
    
     setLoading(false);
   };
 
 







  return (
    <div>
              <Loader loading={loading} />

             <div style={{ backgroundColor: "white", height: "9vh", width: "100%", display: "flex", alignItems: "center", marginTop: "20px", boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', borderRadius: '8px', }}>
                    <Button style={{ height: "60%", marginLeft: "10px" }} variant="contained" onClick={handlePlagiarismPrompt}>Generate</Button>

                    {/* <div style={{ height: "100%", width: "92%", marginLeft: "10%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <FeatureButton divClassName={"px-1"} text={"Check Plagiarism"} style={{ width: "150px" }} size={"sm"} />
                    </div> */}

                </div>


                <div style={{ backgroundColor: "white", display: "flex",flexDirection:"column", height: "50vh", width: "100%", padding: "20px", boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', borderRadius: '8px', marginTop: "20px" }}>
    <div style={{flex: "100%", display: "flex", flexDirection: "column", width: "100%" , overflowY:"auto"}}>
        <Editor
            editorState={editorState}
          //  placeholder="Plagiarism"
            onChange={handleChange}
            style={{ flex: 1 }}
        />
    </div>
    <div style={{ flex: "15%", width: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
        <Button variant="contained" onClick={updatePlagiarismPrompts}>Save</Button>
    </div>
</div>





    </div>
  );
}

export default PromptsGenerator_Plagiarism;
