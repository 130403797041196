import React, { useEffect, useState } from 'react';
import ShareFile from '../shareFileModal';
import { Modal, Button } from 'react-bootstrap';

import { toast } from 'react-toastify';
import { stateToHTML } from "draft-js-export-html";
import { convertFromRaw } from "draft-js";
import { asBlob } from 'html-docx-js-typescript'
import { saveAs } from "file-saver";
import document from '../../services/document';



const CoAuthorShareModal = ({ show, onHide, docId, isSaving}) => {
   
    const [savedDocData, setSavedDocData] = useState(null)
    const [ allDocumentData, setAllDocumentData] = useState([])

  
    const getDocsById = async () => {
        try {
            const response = await document.getDocumentbyId(docId);
            if (response.status === 200) {
                
                setSavedDocData(response.data.document);
  
            } else {
                console.error("Error fetching data:", response.message);
            }
        } catch (error) {
            console.error("Error calling backend API:", error.message);
        }
    };
  
    const getDocs = async () => {
        try {
            const response = await document.getAllDocuments();
            if (response.status === 200) {
                setAllDocumentData(response.data);
              
            } else {
            console.error("Error fetching data:", response.message);
            }
        } catch (error) {
            console.error("Error calling backend API:", error.message);
        }
    };
    
  
    useEffect(() => {
        console.log("shareModal_",isSaving,docId);
        
            getDocsById();
            getDocs();
        
        
    }, [isSaving]);

  
  
    return (
        <>
            <Modal show={show} onHide={onHide} centered id="shareOptionModal">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Share Options
                    </Modal.Title>
                </Modal.Header>
  
                <Modal.Body>
                    <div className="container-fluid">
                        {/* Access ShareFile component here */}
                       {savedDocData !== null &&  <ShareFile
                            
                            shareDocumentId={savedDocData}
                            getDocuments={getDocs}
                            closeModal={onHide}
                        />}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
  }

  export default CoAuthorShareModal

//DO NOT DELETE
// const CoAuthorShareModal = ({ show, onHide, docContent, docName, docId }) => {
    

   
   

//     const [documentData, setDocumentData] = useState({});
//     const [savedDocData, setSavedDocData] = useState(null)
//     const [ allDocumentData, setAllDocumentData] = useState([])


//     useEffect(()=>{

//         const docTitle = docContent.split(" ").slice(0, 2).join(" ")

//         setDocumentData({
//         activeDoc: true,
//         characterCount: 0,
//         comments:[],
//         date:"2024-01-24T10:44:17.241Z",
//         documentAudit:[],
//         documentName: `${docName}`|| `${docTitle}`,
//         documentType: "document",
//         drafts: [],
//         editor: "65b0e20363d01a044cbb468a",
//         imageUrl: [],
//         isAccepted: "pending",
//         isLatestDocument: true,
//         isUpdated: false,
//         mergeDocuments: [],
//         mergeStatus: false,
//         month: 1,
//         onlyDocumentContent: `{\"blocks\":[{\"key\":\"2rlmr\",\"text\":\"${docContent}\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}}],\"entityMap\":{}}`,
//         ownerDP: "",
//         ownerName: "",
//         pendingDocuments: [],
//         ratings: 0,
//         readOnly: false,
//         reviewDate: "",
//         reviewStatus: null,
//         sharedWith:[],
//         status: "original",
//         tags: [],
//         totalRatings: 0,
//         version: [],
//         wordsCount: 0,
//         year: 2024,
//         __v: 0,
//         _id: "65b0e9ea63d01a044cbb472b"
        
//         })
//     },[docContent,docName])
  
//     const getDocsById = async () => {
//         try {
//             const response = await document.getDocumentbyId(docId);
//             if (response.status === 200) {
                
//                 setSavedDocData(response.data);
  
//             } else {
//                 console.error("Error fetching data:", response.message);
//             }
//         } catch (error) {
//             console.error("Error calling backend API:", error.message);
//         }
//     };
  
//     const getDocs = async () => {
//         try {
//             const response = await document.getAllDocuments();
//             if (response.status === 200) {
//                 setAllDocumentData(response.data);
              
//             } else {
//             console.error("Error fetching data:", response.message);
//             }
//         } catch (error) {
//             console.error("Error calling backend API:", error.message);
//         }
//     };
    
  
//     useEffect(() => {
//         if (docId) {
//             getDocsById(docId);
//         }
//         getDocs();
//     }, [docId]);

  
  
//     return (
//         <>
//             <Modal show={show} onHide={onHide} centered id="shareOptionModal">
//                 <Modal.Header closeButton>
//                     <Modal.Title id="contained-modal-title-vcenter">
//                         Share Options
//                     </Modal.Title>
//                 </Modal.Header>
  
//                 <Modal.Body>
//                     <div className="container-fluid">
//                         {/* Access ShareFile component here */}
//                         <ShareFile
                            
//                             shareDocumentId={docId ? savedDocData : documentData}
//                             getDocuments={getDocs}
//                             closeModal={onHide}
//                         />
//                     </div>
//                 </Modal.Body>
//             </Modal>
//         </>
//     );
//   }

//   export default CoAuthorShareModal









///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// import CoAuthorShareModal from './CoAuthorShareModal';

// const [isShareModalOpen, setIsShareModalOpen] = useState(false);


//   const openShareModal = () => {
//     setIsShareModalOpen(true)
//   }

//   const closeShareModal = () => {
//     setIsShareModalOpen(false)
//   }

// { docId &&
//     <CustomTooltip title="Share" >
//       <IconButton  style={{ fontSize: '10px', marginLeft: '2px', color: '#00CED1' }} onClick={openShareModal}><SendIcon /></IconButton>
//     </CustomTooltip>
//     }
    
//     <CoAuthorShareModal show={isShareModalOpen} onHide={closeShareModal} docContent={docContent} docName={docName} docId={docId}/>


//     const handleInsertClick = (intext, id) => {
//         // Get the current editor state
//         const currentEditorState = editorState;
    
//         let intextCitation = ` (${intext})`
      
//         // Get the selection state
//         const selection = currentEditorState.getSelection();
      
//         // Create a ContentState with the inserted text
//         const contentStateWithInsertion = Modifier.insertText(
//           currentEditorState.getCurrentContent(),
//           selection,
//           intextCitation
//         );
      
//         // Create a new EditorState with the updated ContentState
//         const newEditorState = EditorState.push(
//           currentEditorState,
//           contentStateWithInsertion,
//           'insert-characters'
//         );
      
//         // Set the new EditorState
//         setEditorState(newEditorState);
//       };