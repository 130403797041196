


import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import { withRouter } from "react-router-dom";
import { capitalCase } from "change-case";

// Define the useStyles hook outside of the component
const useStyles = makeStyles({
  card: {
    width: (props) => (props.windowWidth && props.windowWidth < 576 ? '100%' : '430%'),
  },
  smallAvatar: {
    width: 30,
    height: 30,
    backgroundColor: "#1976d2 ",
  }
});

const TagsCard = ({ data, abc, windowWidth }) => {
  const classes = useStyles({ windowWidth });

  return (
    <div className="col-sm-3 p-2 TagsCardHover">
      <Card className={classes.card}>
        <CardContent>
          <div className="row">
            <div className="col-2">
              <Avatar className={classes.smallAvatar}>{data.characters}</Avatar>
            </div>
            <div className="col-10">
              {data.Tags.map((tag, index) => (
                <div className="find p-2" key={index}>
                  <h6
                    component={"span"}
                    gutterBottom
                    style={{
                      fontWeight: "900",
                      overflowWrap: "break-word",
                      cursor: "pointer",
                      margin: 0,
                    }}
                    onClick={() => abc(tag)}
                  >
                    {capitalCase(tag)} 
                  </h6>
                </div>
              ))}
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default withRouter(TagsCard);

