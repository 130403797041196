import React, { useState, useEffect } from 'react';
import 'draft-js/dist/Draft.css';
import CloseIcon from '@mui/icons-material/Close';
import TranslateIcon from '@mui/icons-material/Translate';
import { Button, IconButton, Typography } from '@material-ui/core';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ArticleIcon from '@mui/icons-material/Article';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Editor, EditorState, ContentState, RichUtils } from 'draft-js';
import { checkPhrasing } from '../services/englishCheckers';
import { toast, ToastContainer } from "react-toastify";
import SelectDropdownMui from './ReusableComponents/SelectDropdownMui';
import FeatureButton from './ReusableComponents/Button';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import InputBoxMui from './ReusableComponents/InputBoxMui';
import "./sideModel_summariser.css";
//import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {Slider} from '@material-ui/core';
import { getCheckSummariser } from '../services/summariserService';
import Loader from '../utils/loader';
import { useSelector } from "react-redux";


const MyDocument_SideModel = ({ contentEditorState, showSideBar, setShowSideBar, showParaphraser,
  setShowParaphrase, showSummariser, setShowSummariser, handleCloseSideModel, fun_call, setfun_Call, fun_SummariseCall }) => {




  const [secondaryeditorState, setSecondaryEditorState] = useState(EditorState.createEmpty());
  const userDetails = useSelector((user) => user)
const userID = userDetails.user.currentUser._id
  const [loading, setLoading] = useState(false);
  //const [previousState, setPreviousState] = useState(props.callGrammerCheck);
  const [toneList, setToneList] = useState([
    {
      "value": "standard",
      "name": "Standard"
    },
    {
      "value": "formal",
      "name": "Formal"
    },
    {
      "value": "casual",
      "name": "Casual"
    },
    {
      "value": "positive",
      "name": "Positive"
    },
    {
      "value": "confident",
      "name": "Confident"
    },
    {
      "value": "friendly",
      "name": "Friendly"
    },
    {
      "value": "simple",
      "name": "Simple"
    },
    {
      "value": "shorten",
      "name": "Shorten"

    },
    {
      "value": "expand",
      "name": "Expand"

    },

  ]);
  const [selectedTone, setSelectedTone] = useState("")
  const [suggestionText, setSuggestionText] = useState("Custom")
  const [block, setBlock] = useState(null)
  const [isCustomSuggestion, setIsCustomSuggestion] = useState(true)
  const [customTextValue, setCustomTextValue] = useState("")
  const [isCustomOpen, setIsCustomOpen] = useState(false)
  const [customText, setCustomText] = useState("Custom")
  const [editorState2, setEditorState2] = useState(() => EditorState.createEmpty());
  const [righteditorContent, setRightEditorContent] = useState(EditorState.createEmpty())
  const [totalWords, setTotalWords] = useState(0);
  const [sliderValue, setSliderValue] = useState(20);
  const [sliderText, setSliderText] = useState("Shorten")
  const [maxCountDisplayed, setMaxCountDisplayed] = useState(false)
  const [customPrompt, setCustomPrompt] = useState("")
  const [paragraphState, setParagraphState] = useState()
  const [editorStatePara, setEditorStatePara] = useState("");
  const [isKeyword, setIsKeyword] = useState(false)
  const [APIData, setAPIData] = useState(false);
  const [isBulletPoints, setIsBulletPoints] = useState(false)
  const [isParagraph, setIsParagraph] = useState(false)
  const [isCustom, setIsCustom] = useState(false)
  const [activeLink, setActiveLink] = useState(null);
  const [keywords, setKeywords] = useState([])
  const [isVisible, setIsVisible] = useState(false);


  ///// summariser functions
useEffect(()=>{
  grammarCheckSummarizer()
 
}, [fun_SummariseCall])


const handleLinkClick = (linkType) => {
    console.log("Inside handlelink", linkType);
    setActiveLink(linkType);
    if (linkType === "bulletPoints") {
      console.log("bullet selected");
      setAPIData(true)
      setIsBulletPoints(true)
      setIsParagraph(false)
    }
    else if (linkType === "paragraph") {
      setAPIData(false)
      setIsBulletPoints(false)
      setIsParagraph(true)
      setIsCustom(false)
      setIsKeyword(true)
      handleParagraph()
    }
    else if (linkType == "custom") {
      setIsBulletPoints(false)
      setIsParagraph(false)
      setIsCustom(true)
      setIsKeyword(false)
    }
  };




const handleOutputChangeSummarizer = (newEditorState) => {
  console.log("opChange_", newEditorState)
  // let value = e.target.value
  // setHistory([...history.slice(0, currentIndex + 1), value]);
  // setCurrentIndex(currentIndex + 1);
  setRightEditorContent(newEditorState)
}


  const grammarCheckSummarizer = async (type) => {
    console.log("editorState_", contentEditorState, contentEditorState.getCurrentContent().getPlainText());
    let content = contentEditorState.getCurrentContent();
    let plainText = content.getPlainText();
    let data = {
      // text: `Please improve paraphrasing for the provided text ${type !== undefined ? `like a ${type} tone` : ""} ${editorState.getCurrentContent().getPlainText()}`,
      text: plainText,
      content: "",
      prompts: "one",
      userId: userID,
      isCoAuthor: false
    }
    setLoading(true)
    try {
      if (contentEditorState.getCurrentContent().getPlainText() == "") {
        return
      } else {
        let summarizerResponse = await getCheckSummariser(data)
        console.log("summarizerResponse", summarizerResponse)
        if (summarizerResponse.data !== "") {
          let responseOutput = summarizerResponse?.data?.output
          // let wordLength = responseOutput.split(" ")
          console.log("summarizerResponse_", responseOutput[0]);
          const contentState = ContentState.createFromText(responseOutput[0]);
          const newEditorState = EditorState.createWithContent(contentState);
          setRightEditorContent(newEditorState);
        }
      }
    }
    catch (e) {
      console.log("e", e)
    }
    setLoading(false)
  }






  // useEffect(() => {
  //   setRightEditorContent(props.righteditorContent);
  // }, [props.righteditorContent]);


  // const deleteFunctionEditor = () => {

  //   setRightEditorContent(EditorState.createEmpty());
  //   props.handleDeleteinSummarizer(true)

  // };
  // const handleParagraph = () => {
  //   const contentState = ContentState.createFromText(editorStatePara);
  //   console.log("Paragraph text:", contentState.getPlainText());
  //   // Update the state or perform any other necessary actions with the content
  //   setRightEditorContent(EditorState.createWithContent(contentState));
  // }; // working

  const handleParagraph = () => {
    let hasText = righteditorContent.getCurrentContent().hasText();
    if (!hasText) {
      toast.info('No content to convert to paragraph');
      return;
    }
    let text = righteditorContent.getCurrentContent().getPlainText();
    let cleanedText = text.replace(/\s+/g, ' ').trim(); // Replace multiple spaces with a single space
    let newContent = ContentState.createFromText('\n' + cleanedText);
    let newEditorState = EditorState.createWithContent(newContent);
    setRightEditorContent(newEditorState);
  };


  const handleSummeriseClick = async (type) => {
    console.log(contentEditorState, "edit stateee");
    if (contentEditorState.getCurrentContent().getPlainText().split(/\s+/).length > 1500) {
      toast.warn("Words limit exceeded")
      return;
    }
    setLoading(true);
    try {
      if (contentEditorState.getCurrentContent().getPlainText().split(/\s+/).length - 1 == 0) {
        toast.warn("Please enter text")
        return;
      } else {
        const contentState = contentEditorState.getCurrentContent();
        const plainText = contentState.getPlainText();
        console.log(plainText, "plaintext");
        const data = {
          // text: `Please improve paraphrasing for the provided text ${type !== undefined ? `like a ${type} tone` : ""} ${editorState.getCurrentContent().getPlainText()}`,
          // text: `Please improve paraphrasing for the provided text like a one tone The advent of 5G technology has ushered in a new era in communication, promising unprecedented speed, reliability, and connectivity. This fifth-generation wireless technology represents a significant leap forward from its predecessor, 4G. With data transfer rates up to 100 times faster than 4G networks, 5G has the potential to revolutionize how we connect and communicate. Its low latency ensures minimal delays in data transmission, opening doors to applications such as augmented reality, virtual reality, and the Internet of Things.`,
          text: plainText,
          prompts: "one",
          content: "",
          userId: userID,
      isCoAuthor: false
        };

        //   if (customPrompt !== "") {
        //     data = {
        //         text: customPrompt,
        //         content: `Please improve paraphrasing for the provided text like a one tone The advent of 5G technology has ushered in a new era in communication, promising unprecedented speed, reliability, and connectivity. This fifth-generation wireless technology represents a significant leap forward from its predecessor, 4G. With data transfer rates up to 100 times faster than 4G networks, 5G has the potential to revolutionize how we connect and communicate. Its low latency ensures minimal delays in data transmission, opening doors to applications such as augmented reality, virtual reality, and the Internet of Things.`,
        //         prompts: "six"
        //     }
        // }


        const summarizerResponse = await getCheckSummariser(data);
        console.log("summarizerResponse", summarizerResponse);
        if (summarizerResponse.data !== "") {
          let responseOutput = summarizerResponse?.data?.output
          let wordLength = responseOutput.split(" ")
          console.log("summarizerResponse_", responseOutput, wordLength.length);
          const contentState = ContentState.createFromText(responseOutput);
          const newEditorState = EditorState.createWithContent(contentState);
          setRightEditorContent(newEditorState);
          setEditorStatePara(newEditorState.getCurrentContent().getPlainText())
          console.log(newEditorState, "new editor state");

          // Please generate the keywords based on the provided content
          let sendData2 = {
            // text: `Please improve paraphrasing for the provided text like a one tone The advent of 5G technology has ushered in a new era in communication, promising unprecedented speed, reliability, and connectivity. This fifth-generation wireless technology represents a significant leap forward from its predecessor, 4G. With data transfer rates up to 100 times faster than 4G networks, 5G has the potential to revolutionize how we connect and communicate. Its low latency ensures minimal delays in data transmission, opening doors to applications such as augmented reality, virtual reality, and the Internet of Things.`,
            text: plainText,
            prompts: "two",
            content: "",
            userId: userID,
      isCoAuthor: false
          }

          let generateKeywords = await getCheckSummariser(sendData2)
          console.log("generateKeywords", generateKeywords)
          if (generateKeywords.data !== "") {
            const text = generateKeywords.data.output;

            // Check if there is content before attempting to add bullets
            if (text.trim().length > 0) {
              // Extract sentences after dots
              const lines = text.split('\n');
              const filteredLines = lines.filter(line => !line.match(/^\s*\n\d+\.\s*/));

              const extractedSentences = filteredLines
                .map(line => line.split(/\.\s*/))
                .flat()
                .filter(Boolean);

              const cleanedArray = extractedSentences.filter((item, index) => index % 2 !== 0);

              const finalArray = cleanedArray.map(obj => ({ name: obj, value: obj }));

              setKeywords(finalArray);
              setIsVisible(!isCustom);
              setIsKeyword(true);
            } else {
              console.error('No content to add bullets');
            }
          }
        }
      }
    } catch (error) {
      console.error('handleSummarizeClick', error);
    }
    setLoading(false);

  };




  // shorten/long




  const handleBeforeInput = (chars, editorState) => {
    console.log(editorState, "handelbeforInput function data")
    const contentState = editorState.getCurrentContent();
    const currentText = contentState.getPlainText();
    const wordCount = currentText.trim().split(/\s+/).length;

    if (wordCount >= 1500) {
      return 'handled';
    }
    return 'not-handled';
  };
  // ----- delete ------
  // ----- delete ------
  const handleDeleteClick = () => {
    console.log("inside delete");
    setRightEditorContent(EditorState.createEmpty());
    //deleteFunctionEditor()
    setTotalWords(0);
    setSliderValue(0);
    setCustomPrompt("");
    setEditorStatePara("");
    // resetTranscript();
  };

  // ---- download ----
  const handleDownload = () => {
    console.log("inside download");
    console.log(editorState2, "edit state");
    console.log(editorState2.getCurrentContent(), "get current content");
    console.log(righteditorContent.getCurrentContent().getPlainText(), "plain text");

    if (righteditorContent.getCurrentContent().getPlainText() == "") {
      toast.warn(`You don't have text, to download`);
      return;
    }
    const blob = new Blob([righteditorContent.getCurrentContent().getPlainText()], { type: 'text/plain' });
    console.log(blob, "blob");
    const anchor = document.createElement('a');
    anchor.href = URL.createObjectURL(blob);
    anchor.download = 'text_content.doc';
    anchor.click();
    URL.revokeObjectURL(anchor.href);
  };
  //  ----- copy to clipboard ---

  const handleCopyEditor2 = () => {
    let sentence = righteditorContent.getCurrentContent().getPlainText()
    if (sentence == "") {
      toast.warn(`You don't have text, to copy`);
      return;
    }
    navigator.clipboard.writeText(sentence).then(
      function () {
        console.log("Async: Copying to clipboard was successful!");
        if (sentence) toast.info("Your text Copied!");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  }


  const handleApplyBullets = () => {
    // setAPIData(true)
    // setAPIData(false)
    let hasText = righteditorContent.getCurrentContent().hasText();
    if (!hasText) {
      toast.info("No content to add bullets")
      return
    }
    setIsBulletPoints(true)
    setIsParagraph(false)
    setIsCustom(false)
    setIsKeyword(true)

    // let text = editorState2.getCurrentContent().getPlainText();
    // let sentences = text.split('. ');
    // let newText = sentences.join('.\n');
    // let newContentState = ContentState.createFromText(newText);
    // let newEditorState = EditorState.createWithContent(newContentState);
    // setEditorState2(newEditorState)

    // Get the current text from the editor
    let text = righteditorContent.getCurrentContent().getPlainText();

    // Remove all empty lines
    let lines = text.split('\n').filter(line => line.trim() !== '');
    let textWithoutEmptyLines = lines.join('\n');

    // Split the text at every full stop

    const sentenceSplitPattern =/(?<!\b(?:[A-Z][a-z]*\.?\s?)+)\.\s+/;
    let sentences = textWithoutEmptyLines.split(sentenceSplitPattern).filter(sentence => sentence.trim() !== '').map(sentence => sentence.replace(/\n/g, ''));

    // Add a newline after every sentence and join them back together
    let formattedText = sentences.join('.\n');

    // Create a new content state with the formatted text
    let newContent = ContentState.createFromText(formattedText);

    // Set the new content state in the editor
    // let newEditorState = EditorState.push(editorState2, newContent);
    let newEditorState = EditorState.createWithContent(newContent);
    let newEditorStateExt = newEditorState.getCurrentContent()
    console.log("newEditorStateExt", newEditorStateExt.getPlainText())
    let firstBlock = newEditorStateExt.getFirstBlock()
    let lastBlock = newEditorStateExt.getLastBlock()
    let firstBlockKey = firstBlock.getKey();
    let lastBlockKey = lastBlock.getKey();
    let firstBlockLength = firstBlock.getLength();
    let lastBlockLength = lastBlock.getLength();
    let selection = newEditorState.getSelection().merge({
      anchorKey: firstBlockKey,
      anchorOffset: 0,
      focusKey: lastBlockKey,
      focusOffset: lastBlockLength,
    })
    let selectedState = EditorState.forceSelection(newEditorState, selection)
    setParagraphState(selectedState)

    let selectionState = selectedState.getSelection();
    let contentState = selectedState.getCurrentContent();
    let currentBlock = contentState.getBlockForKey(selectionState.getStartKey());
    // Check if the selection is not collapsed and spans the entire block
    // if (!selectionState.isCollapsed() && selectionState.hasFocus &&
    //     selectionState.getStartOffset() === 0 && selectionState.getEndOffset() === currentBlock.getLength()) {

    // Toggle the block type to unordered-list-item
    let newEditorState2 = RichUtils.toggleBlockType(selectedState, 'unordered-list-item');
    setRightEditorContent(newEditorState2);
    // }

  }

 
  const handleSliderChange = (event, newValue) => {
    // Calculate the percentage
    const percentage = ((newValue - 0) / (100 - 0)) * 100;
    // Update the state
    setSliderValue(newValue);
    setSliderText(newValue >= 60 ? "Expand" : "Shorten")
    summarizeByPercentage(newValue)
    // Use the percentage as needed
    console.log('Percentage:', percentage);
  };




  const summarizeByPercentage = async (value) => {
 
    //summarize by percentage
    // four, five
    let promptValue = "four"
    let newValue
    if (value == 0) {
      newValue = 0
      promptValue = "four"
    }
    else if (value < 60) {
      newValue = 100 - value
      promptValue = "four"
    }
    else if (value >= 60) {
      newValue = value
      promptValue = "five"
    }

    console.log("newValue", newValue)
    let sendData = {
      text: newValue.toString() + "%",
      content: contentEditorState.getCurrentContent().getPlainText(),
      prompts: promptValue,
      userId: userID,
      isCoAuthor: false
    }
    console.log("sendData", sendData)
    setLoading(true)
    try {
      let response = await getCheckSummariser(sendData)
      console.log("summarizeByPercentage", response)
      let responseOutput = response?.data?.output
      const contentState = ContentState.createFromText(responseOutput[0]);
      const newEditorState = EditorState.createWithContent(contentState);
      setRightEditorContent(newEditorState);
      setEditorStatePara(newEditorState.getCurrentContent().getPlainText())
      console.log(newEditorState, "new editor state");
    }
    catch (e) {
      console.log("summarizeByPercentageerror", e)
    }
    setLoading(false)
  }




























  //   paraphrase functions


  useEffect(() => {
    grammarCheckForParaphrase()
   
  }, [fun_call])


  function handleParaphrase() {

    setShowSummariser(false)
    setShowParaphrase(true)

  }

  function handleSummarise() {


    setShowSummariser(true)
    setShowParaphrase(false)

  }



  const handleTone = (e) => {
    console.log("selectedTone", selectedTone)
    let value = e.target.value
    setSelectedTone(value)
    console.log("value", value, e)
    setCustomText("Custom")
  }



  const handleCustomSelection = (type) => {
    let splitCustomText = type.split(' ');
    let suggestionType = splitCustomText[2];


    setCustomText(type);
    console.log(type, "type");
    setIsCustomOpen(false);
    setIsCustomSuggestion(true);
    setSuggestionText("Custom");
    setCustomTextValue("");

    Rephrase_grammarCheckForParaphrase(suggestionType);
  };



  const handleClose = () => {
    setIsCustomOpen(false)
    // setCustomText(customText)
  }

  const handleSuggestionChange = (e) => {
    console.log("Suggestion Text", e.target.value)
    let value = e.target.value
    //  value = value.replace(/[0-9]' `@#$%^&*()_+!:"}{|<>,.}]/g,'')
    console.log("value_", value)
    setCustomTextValue(value)

  }

  const handleCustomClick = () => {
    console.log("customText_", customTextValue)
    setIsCustomSuggestion(false)
    setSuggestionText(customTextValue)

  }


  const handleToneSelection = (type) => {
    //  setActiveEditor('paraphrase');
    Rephrase_grammarCheckForParaphrase(type)
  }


  useEffect(() => {
    if (selectedTone !== "" && selectedTone !== "custom") {
      handleToneSelection(selectedTone)
    }

  }, [selectedTone])




  useEffect(() => {
    console.log("selectedTone", selectedTone)
  }, [handleTone])






  const handleOutputChange = (newEditorState) => {
    console.log("opChange_", newEditorState)
    // let value = e.target.value
    // setHistory([...history.slice(0, currentIndex + 1), value]);
    // setCurrentIndex(currentIndex + 1);
    setSecondaryEditorState(newEditorState)
  }


  const grammarCheckForParaphrase = async (type) => {


    console.log("editorState_", contentEditorState, contentEditorState.getCurrentContent().getPlainText());
if(contentEditorState.getCurrentContent().getPlainText() == ""){

  return

}


    let data = {
      text: `Please improve paraphrasing for the provided text ${type !== undefined ? `like a ${type} tone` : ""} ${contentEditorState.getCurrentContent().getPlainText()}`,
      userId: userID,
        isCoAuthor: false
    }
    setLoading(true)
    try {
      let paraphraseResponse = await checkPhrasing(data)
      console.log("paraphraseResponse", paraphraseResponse)
      if (paraphraseResponse.data !== "") {
        let responseOutput = paraphraseResponse?.data?.output
        // let wordLength = responseOutput.split(" ")
        console.log("paraphraseResponse_Pankaj", responseOutput);
        const contentState = ContentState.createFromText(responseOutput[0]);
        const newEditorState = EditorState.createWithContent(contentState);
        console.log("newEditorStateeeeeeee ", newEditorState)
        setSecondaryEditorState(newEditorState);
      }
    }
    catch (e) {
      console.log("e", e)
    }
    setLoading(false)
  }



  const Rephrase_grammarCheckForParaphrase = async (type) => {

    console.log("Secondary__editorState_", secondaryeditorState, secondaryeditorState.getCurrentContent().getPlainText());
    if (secondaryeditorState.getCurrentContent().getPlainText() == "") {
      toast.warn("Nothing to rephrase");
      return;
    }

    let data = {
      text: `Please improve paraphrasing for the provided text ${type !== undefined ? `like a ${type} tone` : ""} ${secondaryeditorState.getCurrentContent().getPlainText()}`,
      userId: userID,
        isCoAuthor: false
    }
    setLoading(true)
    try {
      let paraphraseResponse = await checkPhrasing(data)
      console.log("paraphraseResponse", paraphraseResponse)
      if (paraphraseResponse.data !== "") {
        let responseOutput = paraphraseResponse?.data?.output
        // let wordLength = responseOutput.split(" ")
        console.log("paraphraseResponse_Pankaj", responseOutput);
        const contentState = ContentState.createFromText(responseOutput[0]);
        const newEditorState = EditorState.createWithContent(contentState);
        console.log("newEditorStateeeeeeee ", newEditorState)
        setSecondaryEditorState(newEditorState);
      }
    }
    catch (e) {
      console.log("e", e)
    }
    setLoading(false)
  }







  function handleDeletePara() {
    setSecondaryEditorState(EditorState.createEmpty());


  }

  const handleDownloadForParaphrase = () => {

    if (!secondaryeditorState.getCurrentContent().getPlainText()) {
      toast.warn(`You don't have text, to download`);
      return;
    }
    const blob = new Blob([secondaryeditorState.getCurrentContent().getPlainText()], { type: 'text/plain' });
    console.log(blob, "blob");
    const anchor = document.createElement('a');
    anchor.href = URL.createObjectURL(blob);
    anchor.download = 'text_content.doc';
    anchor.click();
    URL.revokeObjectURL(anchor.href);
  };



  const handleCopyEditorForParaphrase = () => {
    let sentence = secondaryeditorState.getCurrentContent().getPlainText()
    if (sentence == "") {
      toast.warn(`You don't have text, to copy`);
      return;
    }
    navigator.clipboard.writeText(sentence).then(
      function () {
        console.log("Async: Copying to clipboard was successful!");
        if (sentence) toast.info("Your text Copied!");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  }





  return (
    <div>
      {/* <Loader loading={loading} /> */}


      {showSideBar && (
        <div className="Serive_div"
          style={{ width: '360px', zIndex: '1000', height: '100vh', position: "fixed", top: "65px", right: "0px", border: '1px solid  grey', backgroundColor: "white" }}
        >




          <div style={{ display: 'flex', width: '100%', height: "8%", justifyContent: "space-between", alignItems: "center", border: "1px solid grey ", position: "absolute", top: "0px" }}>
            <div style={{ display: 'flex' }}>
              <div style={{ border: "1px solid grey", borderLeft: 'none', borderTop: 'none', borderBottom: 'none', borderRight: 'none', alignItems: "center", color: 'black' }}>
                <IconButton title='Paraphrase' style={{ outline: 'none', color: showParaphraser ? '#3d83fa' : 'blue' }} onClick={() => handleParaphrase()}><MenuBookIcon />
                  <div style={{ color: showParaphraser ? '#3d83fa' : 'grey' }}></div>
                  {showParaphraser &&
                    <div><h6 style={{ color: '#3d83fa', marginTop: "7px", marginLeft: '2px' }}>Paraphrase</h6></div>
                  }

                </IconButton>
              </div>
              <div style={{ border: "1px solid grey", borderTop: 'none', borderRight: 'none', borderBottom: 'none' }}>
                <IconButton title='Summarize' style={{ outline: 'none', color: showSummariser ? '#3d83fa' : 'green' }} onClick={() => handleSummarise()}><ArticleIcon />
                  <div style={{ color: showSummariser ? '#3d83fa' : 'grey' }}></div>
                  {showSummariser &&
                    <div>
                      <h6 style={{ color: '#3d83fa', marginTop: "7px", marginLeft: '2px' }}>Summarize</h6>
                    </div>
                  }
                </IconButton>
              </div>
            </div>
            <div onClick={handleCloseSideModel}>
              <CloseIcon />
            </div>
          </div>



          {showParaphraser && (
            <div style={{ height: "300px", width: "90%", marginTop: "100px", marginLeft: "20px" }}>
              <Loader loading={loading} />

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ marginLeft: '10px', marginBottom: "10px", width: "38%" }}>
                  <SelectDropdownMui
                    optionsList={toneList}
                    selectLabel={"Tone"}
                    onChange={handleTone}
                    value={selectedTone}
                  />
                </div>
                {/* <Button onChange={handleTone} value={"custom"} onClick={ setIsCustomOpen(true)}>Custom</Button> */}
                <Button variant="contained"
                  style={{
                    color: isCustomSuggestion ? "white" : "black",
                    backgroundColor: isCustomSuggestion ? '#3d83fa' : 'transparent',
                  }}
                  onClick={() => setIsCustomOpen(true)}>Custom</Button>
              </div>


              {customText !== "Custom" && <>
                <div>{customText}</div>
              </>}
              <Modal
                isOpen={isCustomOpen}
                // toggle={() => setIsCustomOpen(true)}
                style={{
                  // position: "absolute",
                  // left: "43%",
                  top: "33%",
                }}
              >



                <ModalHeader toggle={handleClose}>
                  <div className="d-flex px-2 justify-content-between">
                    <div
                      style={{
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: 600,
                        color: "#303030",
                        fontSize: "1rem",
                      }}
                    >Make my text sound...</div>
                  </div>
                </ModalHeader>
                <ModalBody>
                  <div className="d-flex justify-content-between">
                    <p
                      style={{
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: 600,
                        color: "#303030",
                        fontSize: "1rem",
                      }}
                      className="col-md-10"
                    >
                      <InputBoxMui
                        fullWidth
                        placeholder={"E.g., “like a News”"}
                        value={customTextValue}
                        onChange={e => handleSuggestionChange(e)}

                      />
                    </p>
                    <div className="ml-2">
                      <button
                        className="btn btn-primary"
                        onClick={handleCustomClick}
                        disabled={customTextValue !== "" ? false : true}
                      >
                        Done
                      </button>
                    </div>
                  </div>
                  <div className="px-2">
                    <div
                      style={{
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: 600,
                        color: "#525252",
                        fontSize: "14px",
                      }}
                    >Suggestions:</div>
                    <div className="d-flex justify-content-evenly mt-2">
                      <FeatureButton text={"Like a Story"} style={{ width: "120px" }} size={"md"} onClick={() => handleCustomSelection("Like a Story")} />
                      <FeatureButton text={"Like a Poem"} divClassName={"ml-3"} style={{ width: "120px" }} size={"md"} onClick={() => handleCustomSelection("Like a Poem")} />
                    </div>
                    <div className="d-flex justify-content-evenly mt-2">
                      <FeatureButton text={"Like a Ceo"} style={{ width: "120px" }} size={"md"} onClick={() => handleCustomSelection("Like a Ceo")} />
                      <FeatureButton text={"Like a Doctor"} divClassName={"ml-3"} style={{ width: "120px" }} size={"md"} onClick={() => handleCustomSelection("Like a Doctor")} />
                    </div>
                    <div className="d-flex justify-content-evenly mt-2">
                      <FeatureButton text={"Like a News"} style={{ width: "120px" }} size={"md"} onClick={() => handleCustomSelection("Like a News")} />
                      <FeatureButton text={"Like a Sales Executive"} divClassName={"ml-3"} style={{ width: "160px" }} size={"md"} onClick={() => handleCustomSelection("Like a Sales Executive")} />
                    </div>
                    <div className="d-flex justify-content-evenly mt-2">
                      <FeatureButton text={"Like a Letter"} style={{ width: "120px" }} size={"md"} onClick={() => handleCustomSelection("Like a Letter")} />
                      <FeatureButton text={suggestionText} disable={isCustomSuggestion} divClassName={"ml-3"} style={{ width: "120px" }} size={"md"} onClick={() => handleCustomSelection(suggestionText)} />
                    </div>
                  </div>
                </ModalBody>
              </Modal>


              <div style={{ minWidth: '100%', maxWidth: '100%', boxShadow: " 0 0 10px rgba(0, 0, 0, 0.1)", padding: '20px 5px', borderRadius: '8px 8px 0px 0px', minHeight: '90%', maxHeight: '90%', overflowY: 'auto' }}>
                <Editor
                  editorState={secondaryeditorState}
                  onChange={handleOutputChange}
                  readOnly={true}
                />

              </div>

              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: '5px', minWidth: '100%', maxWidth: '100%', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', padding: '20px', borderRadius: '0px 0px 8px 8px', background: 'white', minHeight: '70px', maxHeight: '70px' }}>
                <div >
                  <IconButton title={"Delete"} onClick={handleDeletePara} style={{ width: '50px', height: '50px', borderRadius: '5px', marginRight: '5px', border: '1px solid black', color: 'red', '&:hover': { color: 'black' }, }}><DeleteIcon /></IconButton>
                  <IconButton title={"Download"} onClick={handleDownloadForParaphrase} style={{ width: '50px', height: '50px', borderRadius: '5px', marginRight: '5px', border: '1px solid black', color: 'green' }}><DownloadIcon /></IconButton>
                  <IconButton title={"Copy"} onClick={handleCopyEditorForParaphrase} style={{ width: '50px', height: '50px', borderRadius: '5px', marginRight: '5px', border: '1px solid black', color: 'blue' }}><ContentCopyIcon /></IconButton>

                </div>
                <div>
                  <Button variant="contained" style={{ color: "white", backgroundColor: '#3d83fa' }} onClick={Rephrase_grammarCheckForParaphrase}>Rephrase</Button>
                </div>
              </div>

            </div>


          )}

          {showSummariser && (
            <>
             <Loader loading={loading} />
              <div className='Maindiv1'>
                <div className='headtag1'>
                  Summary Length
                </div>
                <div className='headtag_flex1'>
                  <div className='headtag_inner_div1'>Short</div>
                  <Slider
                    defaultValue={20}
                    step={20}
                    marks
                    min={0}
                    max={60}
                    style={{ width: "150px" }}
                    valueLabelDisplay="off"
                    onChange={handleSliderChange}
                  />
                  <div className='headtag_inner_div1'>Long</div>
                </div>
              </div>

              <div className='top_button_style1'>
                <Button
                  variant="Text"
                  style={{ fontSize: "10px", marginRight: "15px" }}
                  onClick={handleApplyBullets}
                >
                  Bullet Points
                </Button>

                <Button
                  variant="text"
                  style={{ fontSize: "10px" }}
                  onClick={() => handleLinkClick("paragraph")}
                >
                  Paragraph
                </Button>
              </div>

              <div className='editorDiv1' >
                <Editor
                  editorState={righteditorContent}
                  onChange={handleOutputChangeSummarizer}
                  handleBeforeInput={handleBeforeInput}
                  placeholder="Please enter your text."
                  //readOnly={true}
                />
              </div>

              <div className='bottomButtons1'>
                <div>
                  <IconButton
                    title={"Delete"}
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "5px",
                      marginRight: "5px",
                      border: "1px solid black",
                      color: "grey",
                    }}
                    onClick={handleDeleteClick}
                  >
                    <DeleteIcon />
                  </IconButton>
                  <IconButton
                    title={"Download"}
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "5px",
                      marginRight: "5px",
                      border: "1px solid black",
                    }}
                    onClick={handleDownload}
                  >
                    <DownloadIcon />
                  </IconButton>
                  <IconButton
                    title={"Copy"}
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "5px",
                      marginRight: "5px",
                      border: "1px solid black",
                    }}
                    onClick={handleCopyEditor2}
                  >
                    <ContentCopyIcon />
                  </IconButton>
                </div>
              </div>

            </>
          )}

        </div>
      )}
    </div>
  );
}

export default MyDocument_SideModel;
