import React from "react";
import ContactUs from "../ContactUs";
import SideNavBar from "../SideNavBar";
const ContactUsPages = props => {
 
  return (
    <SideNavBar>
      <ContactUs />
    </SideNavBar>
  );
};
export default ContactUsPages;