import React, { useState, useRef, useEffect } from 'react';
import { Editor, EditorState, ContentState, Modifier, SelectionState, RichUtils } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { Button, IconButton, TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import RedoIcon from '@mui/icons-material/Redo';
import UndoIcon from '@mui/icons-material/Undo';
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FeedbackIcon from '@mui/icons-material/Feedback';
import { Menu, MenuItem } from '@material-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CircularProgress from "@material-ui/core/CircularProgress";
import { toast } from 'react-toastify';

import { Popover, List, ListItem, ListItemText, Switch, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import StrikethroughSIcon from '@mui/icons-material/StrikethroughS';
import SuperscriptIcon from '@mui/icons-material/Superscript';
import SubscriptIcon from '@mui/icons-material/Subscript';
import CodeIcon from '@mui/icons-material/Code';
import FormatClearIcon from '@mui/icons-material/FormatClear';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import ShareIcon from '@mui/icons-material/Share';
import { Tooltip } from '@mui/material';
import Immutable from 'immutable';
// import { Modal  , Form } from "react-bootstrap";
import Modal from '@mui/material/Modal';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
// import {handleresarchContente, handcitationContente} from './CoauthorModal';

import { deleteDocuments } from '../../services/document';
import { useSelector } from 'react-redux';
import CircleIcon from '@mui/icons-material/Circle';
import { DownloadOptionModal } from '../MyDocumentsCardsModal';

const CoAuthorTopMenubar = (props) => {
  const { clickState } = useSelector(state => state.handleClick);
  // File, Edit, Format, Help
  const [fileMenuAnchor, setFileMenuAnchor] = useState(null);
  const [editMenuAnchor, setEditMenuAnchor] = useState(null);
  const [formatMenuAnchor, setFormatMenuAnchor] = useState(null);
  const [helpMenuAnchor, setHelpMenuAnchor] = useState(null);
  const [copyMessage, setCopyMessage] = useState(null);
  const [copyTooltip, setCopyTooltip] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [projectName, setProjectName] = useState()

  const [Finalprojectname, setFinalprojectname] = useState(clickState === "blog" ? 'Untitled Blog' : "Untitled Document")
  const [RenameModal, setRenameModal] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false);


  const [isEditing, setEditing] = useState(false);
  const [editedState, setEditedState] = useState(Finalprojectname);
  const { lowerModelOpen, rightModalOpen } = useSelector(state => state.handleModelAndSideBavBar);
 
  console.log(clickState, "+++++++ Top menubar");
  useEffect(() => {
    console.log(clickState, "-------clickState from top menubar");
  }, [clickState]);

  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);

  const handleEditClick = () => {
    setEditing(true);
  };

  const handleSaveClick = () => {
    if (clickState === "blog") {
      setFinalprojectname(Finalprojectname || "Untitled Blog");
      setEditing(false);
      props.setDocName(Finalprojectname || "Untitled Blog")
    } else {
      setFinalprojectname(Finalprojectname || "Untitled Document");
      setEditing(false);
      props.setDocName(Finalprojectname || "Untitled Document")
    }

  };

  const closeDownloadModal = () => {
    setIsDownloadModalOpen(false);
};

  const openDownloadModal = () => {
    setIsDownloadModalOpen(true)
  }



  const handleChange = (e) => {
    setFinalprojectname(e.target.value);
    props.setDocName(e.target.value)
  };


  const handleOpenModal = () => {
    setIsModalOpen(true);
    // alert('kk')
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };


  useEffect(() => {
    console.log("insidetopmenubar", props.docName, Finalprojectname)

    if (props.docName == "") {
      if (clickState === "blog") {
        if (props.editorState?.trim()?.split(/\s+/).length > 2 && Finalprojectname == "Untitled Blog") {
          let proName = props.editorState?.trim()?.split(/\s+/)?.slice(0, 2)?.join(' ')
          setFinalprojectname(proName)
          props.setDocName(proName)
          console.log("getting called")
        }
      } else {
        // if (props.editorState.getCurrentContent().getPlainText().trim().split(/\s+/).length > 2 && Finalprojectname == "Untitled Project") {
        if (props.editorState?.trim()?.split(/\s+/).length > 2 && Finalprojectname == "Untitled Document") {
          let proName = props.editorState?.trim()?.split(/\s+/)?.slice(0, 2)?.join(' ')
          setFinalprojectname(proName)
          props.setDocName(proName)
          console.log("getting called")
        }
      }

    } else if (props.editorState == "" || props.editorState == undefined) {
      if(clickState === "blog"){
        setFinalprojectname("Untitled Blog")
      }else{
        setFinalprojectname("Untitled Document")
      }
      
    }
    else {
      let proName = props.editorState?.split(/\s+/).slice(0, 2).join(' ')
      setFinalprojectname(proName)
    }
  }, [props.editorState])


  const handleMenuClick = (event, menuType) => {
    switch (menuType) {
      case 'file':
        setFileMenuAnchor(event.currentTarget);
        break;
      case 'edit':
        setEditMenuAnchor(event.currentTarget);
        break;
      case 'format':
        setFormatMenuAnchor(event.currentTarget);
        break;
      case 'help':
        setHelpMenuAnchor(event.currentTarget);
        break;
      default:
        break;
    }
  };


  // for rename popup hide and show
  const handleCloserename = () => {
    setRenameModal(true)
    handleOpenModal()
    handleCloseMenu()
  }






  const handleCloseMenu = () => {
    setFileMenuAnchor(null);
    setEditMenuAnchor(null);
    setFormatMenuAnchor(null);
    setHelpMenuAnchor(null);
  };

  const history = useHistory();

  const handleCloseProject = () => {
    history.goBack();
  };


  const handleOpenProject = () => {
    history.goBack();
  }

  const handleDelete = () => {
    setDeleteConfirmationOpen(true);
  };

  const handleConfirmDelete = async () => {
    const res = await deleteDocuments(props.docId || props.newDocId);

    if (res.status === 200) {
      toast.success("Document deleted!");
    } else {
      toast.error("Oops..Something went wrong!!");
    }

    setDeleteConfirmationOpen(false);
    history.goBack();
  };

  const handleCancelDelete = () => {
    setDeleteConfirmationOpen(false);
  };


  const handlePrint = () => {
    const printWindow = window.open('', '_blank');
    const content = props.editorHtmlContent

    printWindow.document.write('<html><head></head><body>');
    printWindow.document.write(content);
    printWindow.document.write('</body></html>');

    printWindow.document.close();
    printWindow.print();
    printWindow.onafterprint = () => {
      printWindow.close();
    };

    handleCloseMenu();
  };

  const handleExport = () => {
    openDownloadModal()
    handleCloseMenu()
  };

  const editorRef = useRef();

  const handleUndo = () => {
    props.onChange(EditorState.undo(props.editorState));
  };

  const handleRedo = () => {
    props.onChange(EditorState.redo(props.editorState));
  };

  const handleSelectAll = () => {
    const contentState = props.editorState.getCurrentContent();
    const selection = SelectionState.createEmpty(contentState.getFirstBlock().getKey())
      .merge({
        anchorOffset: 0,
        focusKey: contentState.getLastBlock().getKey(),
        focusOffset: contentState.getLastBlock().getText().length,
      });

    const newEditorState = EditorState.forceSelection(props.editorState, selection);
    props.onChange(newEditorState);
    handleCloseMenu()
  };

  // const handleSelectAll = () => {
  //   const contentState = props.editorState.getCurrentContent();
  //   const firstBlock = contentState.getFirstBlock();
  //   const lastBlock = contentState.getLastBlock();
  //   const firstBlockKey = firstBlock.getKey();
  //   const lastBlockKey = lastBlock.getKey();
  //   const firstBlockLength = firstBlock.getLength();
  //   const lastBlockLength = lastBlock.getLength();

  //   // Create a selection spanning from the start of the first block to the end of the last block

  //   const selection = new SelectionState({
  //     anchorKey: firstBlockKey,
  //     anchorOffset: 0,
  //     focusKey: lastBlockKey,
  //     focusOffset: lastBlockLength,
  //   });

  //   // Apply an inline style to the selected text to visually highlight it
  //   const contentStateWithHighlight = Modifier.applyInlineStyle(
  //     contentState,
  //     selection,
  //     'HIGHLIGHT'
  //   );

  //   // Update the editor state with the new content containing highlighted text
  //   const newEditorState = EditorState.push(
  //     props.editorState,
  //     contentStateWithHighlight,
  //     'change-inline-style'
  //   );

  //   // Update the editor state with the new selection
  //   const newEditorStateWithSelection = EditorState.forceSelection(
  //     newEditorState,
  //     selection
  //   );

  //   // Update the editor state
  //   props.onChange(newEditorStateWithSelection);

  //   handleCloseMenu()
  // };

  // useEffect(()=>{

  //   if(props.removeHighlight){

  //   const contentState = props.editorState.getCurrentContent();
  //   const firstBlock = contentState.getFirstBlock();
  //   const lastBlock = contentState.getLastBlock();
  //   const firstBlockKey = firstBlock.getKey();
  //   const lastBlockKey = lastBlock.getKey();
  //   const firstBlockLength = firstBlock.getLength();
  //   const lastBlockLength = lastBlock.getLength();

  //   // Create a selection spanning from the start of the first block to the end of the last block
  //   const selection = new SelectionState({
  //     anchorKey: firstBlockKey,
  //     anchorOffset: 0,
  //     focusKey: lastBlockKey,
  //     focusOffset: lastBlockLength,
  //   });

  //     const contentStateWithHighlight = Modifier.removeInlineStyle(
  //     contentState,
  //     selection,
  //     'HIGHLIGHT'
  //   );

  //   // Update the editor state with the new content containing highlighted text
  //   const newEditorState = EditorState.push(
  //     props.editorState,
  //     contentStateWithHighlight,
  //     'change-inline-style'
  //   );

  //   // Update the editor state with the new selection
  //   const newEditorStateWithSelection = EditorState.forceSelection(
  //     newEditorState,
  //     selection
  //   );

  //   // Update the editor state
  //   props.onChange(newEditorStateWithSelection);
  //   props.setRemoveHighlight(false)


  //   }


  // },[props.removeHighlight])





  const handleCopy = () => {
    const editorState = props.editorState;
    const selection = editorState.getSelection();

    if (selection.isCollapsed()) {
      // No text is selected
      return;
    }

    const start = selection.getStartOffset();
    const end = selection.getEndOffset();
    const selectedText = selection.getStartKey() === selection.getEndKey()
      ? editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getText().slice(start, end)
      : editorState.getCurrentContent().getText().slice(start, end);

    navigator.clipboard.writeText(selectedText).then(
      function () {
        setCopyTooltip(true);
        setTimeout(() => setCopyTooltip(false), 1000);
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  };



  const handleCut = () => {
    const editorState = props.editorState;
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      const contentState = editorState.getCurrentContent();
      const contentStateWithCut = Modifier.removeRange(contentState, selection, 'backward');
      const newEditorState = EditorState.push(editorState, contentStateWithCut, 'remove-range');
      props.onChange(newEditorState);
    }
    const start = selection.getStartOffset();
    const end = selection.getEndOffset();
    const selectedText = selection.getStartKey() === selection.getEndKey()
      ? editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getText().slice(start, end)
      : editorState.getCurrentContent().getText().slice(start, end);

    navigator.clipboard.writeText(selectedText).then(
      function () {
        console.log("text copied");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );

    handleCloseMenu();
  };



  const handlePaste = () => {
    navigator.clipboard.readText()
      .then((clipboardText) => {
        const editorState = props.editorState;
        const currentContent = editorState.getCurrentContent();
        const selection = editorState.getSelection();
        const newContentState = Modifier.replaceText(
          currentContent,
          selection,
          clipboardText
        );
        const newEditorState = EditorState.push(editorState, newContentState, 'insert-characters');
        props.onChange(newEditorState);
      })
      .catch((error) => {
        console.error("Unable to paste text: ", error);
      });
  };



  const renderMenuOptions = (options) => {
    const iconMapping = {
      'New Project': <AddIcon />,
      'Open Project': <FolderOpenIcon />,
      'Import File': <ImportExportIcon />,
      // 'Rename': <EditIcon />,
      'Delete': <DeleteIcon />,
      'Export': <SaveIcon />,
      'Print': <PrintIcon />,
      'Close Project': <CloseIcon />,
      'Undo': <UndoIcon />,
      'Redo': <RedoIcon />,
      'Cut': <ContentCutIcon />,
      'Copy': <ContentCopyIcon />,
      'Paste': <ContentPasteIcon />,
      'Select All': <SelectAllIcon />,
      'Bold': <FormatBoldIcon />,
      'Italic': <FormatItalicIcon />,
      'Underline': <FormatUnderlinedIcon />,
      'Strikethrough': <StrikethroughSIcon />,
      'Superscript': <SuperscriptIcon />,
      'Subscript': <SubscriptIcon />,
      'Code': <CodeIcon />,
      'Clear Formatting': <FormatClearIcon />,
      'Tutorials': <EmojiObjectsIcon />,
      'FAQs': <HelpOutlineIcon />,
      'Feedback': <FeedbackIcon />,
      "What's New": <NewReleasesIcon />
    };

    const actionHandlers = {
      'Undo': handleUndo,
      'Redo': handleRedo,
      'Cut': handleCut,
      'Copy': handleCopy,
      'Paste': handlePaste,
      'Select All': handleSelectAll,
      'Bold': handleBold,
      'Italic': handleItalic,
      'Underline': handleUnderline,
      'Strikethrough': handleStrikeThrough,
      'Superscript': handleSuperscript,
      'Subscript': handleSubscript,
      'Code': handleCode,
      'Clear Formatting': handleClearFormatting,
      'Close Project': handleCloseProject,
      'Print': handlePrint,
      'Export': handleExport,
      'Delete': handleDelete,
      // 'Rename': handleCloserename,
      'New Project': handleNewProject,
      'Open Project': handleOpenProject,
      'Import File': handleImport
    };

    return options.map((option) => (
      <MenuItem key={option} onClick={() => actionHandlers[option]()}>
        {iconMapping[option]}
        <Typography style={{ marginLeft: '8px' }}>{option}</Typography>
      </MenuItem>
    ));
  };

  const handleBold = () => {
    applyInlineStyle('BOLD');
  };
  const handleItalic = () => {
    applyInlineStyle('ITALIC');
  };
  const handleUnderline = () => {
    applyInlineStyle('UNDERLINE');
  };
  const handleStrikeThrough = () => {
    applyInlineStyle('STRIKETHROUGH');
  };
  const handleSuperscript = () => {
    applyInlineStyle('SUPERSCRIPT');
  };
  const handleSubscript = () => {
    applyInlineStyle('SUBSCRIPT');
  };
  const handleCode = () => {
    applyInlineStyle('CODE');
  };
  const handleClearFormatting = () => {
    const editorState = props.editorState;
    const contentState = editorState.getCurrentContent();
    const plainText = contentState.getPlainText();
    const clearedContentState = ContentState.createFromText(plainText);
    const newEditorState = EditorState.push(
      editorState,
      clearedContentState,
      'remove-range'
    );
    props.onChange(newEditorState);
    handleCloseMenu()
  };

  const applyInlineStyle = (style) => {
    const editorState = props.editorState;
    const selection = editorState.getSelection();
    const currentStyle = editorState.getCurrentInlineStyle();

    const newContentState = currentStyle.has(style)
      ? Modifier.removeInlineStyle(
        editorState.getCurrentContent(),
        selection,
        style
      )
      : Modifier.applyInlineStyle(
        editorState.getCurrentContent(),
        selection,
        style
      );

    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      'change-inline-style'
    );

    props.onChange(newEditorState);
  };

  const handleProjectName = (e) => {
    setProjectName(e.target.value)
  }

  const handleSavefunction = () => {
    if (projectName) {
      setFinalprojectname(projectName)
    }
    setRenameModal(false)
  }



  useEffect(() => {
    props.setSharedState(Finalprojectname);
  }, [Finalprojectname])



  //  For New Project
  const handleNewProject = () => {
    window.location.reload(true);
    handleCloseMenu();

    // props.newProject(true)
    // setEditedState('Untitled Project')
    // setFinalprojectname("Untitled Project")
  }



  const convertTextToHtml = (text) => {
    // Split the text into paragraphs based on line breaks
    const paragraphs = text.split('\n');

    // Map each paragraph to wrap it with <p> tags and handle line breaks
    const htmlContent = paragraphs.map((paragraph) => {
      // Replace consecutive line breaks with <br> tags within the paragraph
      const paragraphWithBreaks = paragraph.replace(/\n{2,}/g, '<br><br>');
      // Wrap the paragraph with <p> tags
      return `<p>${paragraphWithBreaks}</p>`;
    });

    // Join the paragraphs into a single HTML string
    return htmlContent.join('');
  };

  const handleImport = () => {
    // Create a hidden file input element
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.style.display = 'none';

    // Trigger click event on the file input
    fileInput.addEventListener('change', (event) => {
      const fileList = event.target.files;
      if (fileList.length > 0) {
        const file = fileList[0];
        // Do something with the selected file, for example, you can read its content
        const reader = new FileReader();
        reader.onload = (e) => {
          const fileContent = e.target.result;
          const fileName = file.name;
          let contentToSend;

          const text = fileContent.toString();
          console.log(text, fileName, "shaka")

          if (file.name.endsWith('.txt')) {
            // Convert text content to HTML format
            const htmlContent = convertTextToHtml(text);
            contentToSend = `<br>${htmlContent}`; // Start from a new line with extra space
            // Append the new content to the existing content in the editor
            props.setEditorContentUpload(props.editorHtmlContent + contentToSend);
        } else if (file.name.endsWith('.docx')) {
            const bodyContentRegex = /<body[^>]*>([\s\S]*?)<\/body>/i;
            const match = bodyContentRegex.exec(text);
            contentToSend = match ? `<br>${match[1]}` : ''; // Start from a new line with extra space
            // Append the new content to the existing content in the editor
            props.setEditorContentUpload(props.editorHtmlContent + contentToSend);
        } else {
            toast.warning("Please upload .txt or .docx file")
            return
          }

        };
        reader.readAsText(file);
      }
    });

    // Click on the file input programmatically
    document.body.appendChild(fileInput);
    fileInput.click();
    // Clean up after file selection
    document.body.removeChild(fileInput);
    handleCloseMenu()
  };




  return (
    <>

      <div style={{ marginTop: '15px' }}>
        <div style={{ display: "flex", justifyContent: 'space-between', backgroundColor: '#1976d2', height: '60px', padding: '0px 15px' }}>

          <div style={{ display: 'flex', alignItems: "center", color: 'white', marginLeft: '15px' }}>
            {isEditing ? (
              <div>
                <input type="text" value={props.docName} onChange={handleChange} style={{width: props.mobileView ? '90%' : ''}} />
                <button onClick={handleSaveClick} style={{marginLeft:'10px', fontSize: props.mobileView ? '11px' : ''}}>Save</button>
              </div>
            ) : (
              <div>
                <h3 onClick={handleEditClick} style={{fontSize: props.mobileView ? '12px' : ''}}>{ clickState === "blog" ?  props.docName || "Untitled Blog" : props.docName || "Untitled Document"}</h3>
              </div>
            )}

            {/* <div><CloudDoneIcon style={{ marginLeft: '15px' }} /></div> */}
          </div>




          <div className="d-flex align-items-center justify-content-end ml-5">
            {(props.circularLoader && props.docName) ?
              <><CircleIcon style={{ color: '#3FDD01' }} /><span className="mx-2" style={{ color: "white", fontSize: props.mobileView ? '12px' : '' }}>Document Saved</span></>
              :
              Finalprojectname !== "Untitled Document" || Finalprojectname !== "Untitled Blog"  && ''
            }
          </div>
        </div>

        {!props.trial &&
        <div style={{backgroundColor:'white'}}>
        <div className='Left-side-coauthor' style={{ display: "flex", alignItems: "center", justifyContent: "space-between", backgroundColor:'white', width:props.mobileView ? '73%' : '82%', height: props.mobileView ? '57px' : '' }}>

          <div style={{ marginTop: props.mobileView ? '0%' : '1%', padding: props.mobileView ? '0px' : '5px' }} >
            {copyMessage && <span>{copyMessage}</span>}
            <Button onClick={(e) => handleMenuClick(e, 'file')} style={{ textTransform: 'capitalize', fontSize: props.mobileView ? '11px' : '15px', width: '5px' }}>File</Button>
            <Popover
              open={Boolean(fileMenuAnchor)}
              anchorEl={fileMenuAnchor}
              onClose={handleCloseMenu}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >


              {props.docId ? renderMenuOptions(['New Project', 'Open Project', 'Import File', 'Delete', 'Export', 'Print', 'Close Project']) : renderMenuOptions(['New Project', 'Open Project', 'Import File', 'Delete', 'Print', 'Close Project'])}
              {/* {renderMenuOptions([ 'Delete', 'Print', 'Close Project'])} */}
            </Popover>
            


            {/* Edit Menu */}
            {/* <Button onClick={(e) => handleMenuClick(e, 'edit')} style={{ textTransform: 'capitalize', fontSize: '15px', color: 'black' }}>Edit</Button> */}
            {/* <Popover
              open={Boolean(editMenuAnchor)}
              anchorEl={editMenuAnchor}
              onClose={handleCloseMenu}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              {renderMenuOptions(['Undo', 'Redo', 'Cut', 'Copy', 'Paste', 'Select All'])}
            </Popover>
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                color: 'white',
                padding: '8px',
                borderRadius: '5px',
                display: copyTooltip ? 'block' : 'none',
              }}
            >
              Copied!
            </div> */}
            {/* Format Menu */}
            {/* <Button onClick={(e) => handleMenuClick(e, 'format')} style={{ textTransform: 'capitalize', fontSize: '15px', color: 'black' }}>Format</Button> */}
            {/* <Popover
              open={Boolean(formatMenuAnchor)}
              anchorEl={formatMenuAnchor}
              onClose={handleCloseMenu}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              {renderMenuOptions(['Bold', 'Italic', 'Underline', 'Strikethrough', 'Superscript', 'Subscript', 'Code', 'Clear Formatting'])}
            </Popover> */}
            {/* <Button onClick={(e) => props.handleMenuItemClick("Editor View")} style={{ textTransform: 'capitalize', fontSize: '15px', color: 'white' }}>Edit</Button>
            <Button onClick={(e) => props.handleMenuItemClick("Viewing")} style={{ textTransform: 'capitalize', fontSize: '15px', color: 'white' }}>View</Button> */}

            {/* Help Menu */}
            <Button onClick={(e) => handleMenuClick(e, 'help')} style={{ textTransform: 'capitalize', fontSize: props.mobileView ? '11px' : '15px' }}>Help</Button>
            <Popover
              open={Boolean(helpMenuAnchor)}
              anchorEl={helpMenuAnchor}
              onClose={handleCloseMenu}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              {renderMenuOptions(['Tutorials', 'FAQs', 'Feedback', "What's New"])}
            </Popover>
            {/* <Button onClick={() => props.grammarCheck()} style={{ textTransform: 'capitalize', fontSize: '15px' }}>Check Grammar</Button> */}
            <DownloadOptionModal show={isDownloadModalOpen} onHide={closeDownloadModal} id={props.docId} />

            

            {/* <Button style={{ textTransform: 'capitalize', fontSize: '13px', color: 'white', marginLeft: '10px', backgroundColor: '#1976D2', borderRadius: '10px', boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)'}}>
        <ShareIcon style={{ marginRight: '5px', tabSize: '5px'}} />
        Share
        </Button>
         */}
          </div>
          <div>
          <Button onClick={() => props.grammarCheck()} style={{ textTransform: 'capitalize', fontSize: props.mobileView ? '10px' : '15px' }}>Check Grammar</Button>
          </div>
          

        </div>
        </div>

}

        {props.trial &&
        <div className='Left-side-coauthor' style={{ display: "flex", alignItems: "center", justifyContent: "space-between", backgroundColor:'white' }}>
        <div style={{ marginTop: '1%', padding: '5px' }}>
          <Button onClick={() => props.grammarCheck()} style={{ textTransform: 'capitalize', fontSize: props.mobileView ? '10px' : '15px' }}>Check Grammar</Button>
        </div>
        </div>
}


      </div>
      <Dialog open={deleteConfirmationOpen} onClose={handleCancelDelete}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          Are you sure you want to delete the content? This action cannot be undone.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            No
          </Button>
          <Button onClick={handleConfirmDelete} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>


      {RenameModal &&
        <>
          {/* <Button onClick={handleOpenModal}>Open modal</Button> */}
          {/* <Modal>
       <div  style={{display:"flex",alignItems:"center"  , backgroundColor:"white",height:"fit-content",border:"1px solid black",borderRadius:"3px"}}>
       <TextField style={{margin:"2px"}} onChange={(e) => { handleProjectName(e) }} id="outlined-basic" placeholder='project name' variant="outlined" />
        <Button style={{backgroundColor:"#3f51b5", padding:"12px",margin:"3px",color:"white"}} onClick={handleSavefunction}>Save</Button>
       </div>
      </Modal> */}

          <Modal open={isModalOpen} onClose={handleCloseModal}>
            <div style={{ position: "absolute", top: "45%", left: "45%", display: "flex", alignItems: "center", width: "fit-content", height: "fit-content", justifyContent: "cenetr", border: "1px solid black", borderRadius: "3px", backgroundColor: "white" }}>
              <TextField style={{ margin: "2px" }} onChange={(e) => { handleProjectName(e) }} id="outlined-basic" placeholder='project name' variant="outlined" />
              <Button style={{ backgroundColor: "#3f51b5", padding: "12px", margin: "3px", color: "white" }} onClick={handleSavefunction}>Save</Button>
            </div>
          </Modal>
        </>
      }

    </>
  );
};

export default CoAuthorTopMenubar;
