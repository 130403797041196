import React, { useEffect, useState } from "react";
import { Card, CardContent, Checkbox, IconButton, Typography, Menu, MenuItem, Button } from '@material-ui/core';
import { Modal, Button as B_Button } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import { toast } from 'react-toastify';
import { Editor, EditorState, ContentState, convertToRaw } from 'draft-js';
import 'draft-js/dist/Draft.css';
import styled from "styled-components";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export const SuggestionCards = () => {
    return(
        <>
            <div>
                <Card style={{width:'100%',height:'130px',backgroundColor:'white', marginBottom:'15px', borderRadius: '10px'}}>
                    <CardContent style={{display:'flex', flexDirection:'column', justifyContent:'space-between'}}>
                        <div style={{display:'flex', justifyContent:'space-between'}}>
                            <div><Typography variant="body2">title</Typography></div>
                            <div><Typography variant='caption' style={{color:'grey'}}>Error message</Typography></div>
                        </div>
                        <div style={{ overflowY:'auto'}}>
                            <Typography variant="caption" ><p>Lorem Ipsum is simply dummy 
                                text of the printing and typesetting industry.Lorem Ipsum is simply dummy 
                                text of the printing and typesetting industry.</p>
                            </Typography>
                        </div>
                        <div style={{paddingTop:'15px'}}>
                            <Button variant="contained" color="primary">Accept</Button>
                            <Button variant="text" style={{marginLeft:'10px'}}>Ignore</Button>
                        </div>
                    </CardContent>
                </Card>
            </div>
        </>
    )
}

export const RewriteCard = ({handleCloseRewriteCard, rewrittenText, setContentEditorState, contentEditorState}) =>{

  const handleOnClick = () => {
    try {
      // Create a new EditorState with the updated content
      const newContentState = ContentState.createFromText(rewrittenText);
      const newEditorState = EditorState.push(
        contentEditorState,
        newContentState,
        'insert-characters'
      );

      // Update the editor state
      setContentEditorState(newEditorState);

      handleCloseRewriteCard();
    } catch (error) {
      toast.error("Error in rewrite");
    }
  };


    return(
        <>
        <div style={{position:'absolute', right:'10%', top:'40%', zIndex:'999', minWidth:'300px', maxWidth:'500px'}}>
        <Card style={{position:'relative' , padding:'10px 20px', borderRadius:'10px'}}>
            <div style={{display:'flex', justifyContent:'right'}}>
            <IconButton
                onClick={handleCloseRewriteCard}
            >
                <CloseIcon />
            </IconButton>
            </div>
            <div style={{marginBottom:'10px'}}>
            <Typography variant="body1" >
                {rewrittenText}
            </Typography>
            </div>
            <div style={{display:'flex', justifyContent:'right'}}>
                <Button variant="outlined" color="primary" onClick={()=>{handleOnClick()}}>Rewrite</Button>
            </div>
        </Card>
        </div>
        </>
    )
}


export const StatisticalFactCard = ({handleCloseStatisticalFactCard, statisticalData , setContentEditorState, contentEditorState}) =>{

  const handleOnClick = () => {
    try {
      // Get the current content state
      const currentContentState = contentEditorState.getCurrentContent();
  
      // Create a new content state by appending the new text to the existing text
      const newContentState = ContentState.createFromText(
        currentContentState.getPlainText() + '\n' + statisticalData
      );
  
      // Create a new EditorState with the updated content
      const newEditorState = EditorState.push(
        contentEditorState,
        newContentState,
        'insert-characters'
      );
  
      // Update the editor state
      setContentEditorState(newEditorState);
  
      handleCloseStatisticalFactCard();
    } catch (error) {
      toast.error("Error in append");
    }
  };

    return(
        <>
        <div style={{position:'absolute', right:'10%', top:'40%', zIndex:'999', minWidth:'300px', maxWidth:'500px'}}>
        <Card style={{position:'relative' , padding:'10px 20px', borderRadius:'10px'}}>
            <div style={{display:'flex', justifyContent:'center'}}>
                <Typography variant="body1">
                    <strong>Add Statistical Data</strong>
                </Typography>
            </div>
            <div style={{margin:'15px 0px'}}>
            <Typography variant="body1" >
                {statisticalData}
            </Typography>
            </div>
            <div style={{display:'flex', justifyContent:'space-between'}}>
                <Button variant="contained"  onClick={handleCloseStatisticalFactCard}>Ignore</Button>
                <Button variant="contained" color="primary" onClick={()=>{handleOnClick()}}>Accept</Button>
            </div>
        </Card>
        </div>
        </>
    )
}

export const ExplainCard = ({handleCloseExplainCard, explanationText, setContentEditorState, contentEditorState}) =>{

  
  const handleOnClick = () => {
    try {
      // Get the current content state
      const currentContentState = contentEditorState.getCurrentContent();
  
      // Create a new content state by appending the new text to the existing text
      const newContentState = ContentState.createFromText(
        currentContentState.getPlainText() + '\n' + explanationText
      );
  
      // Create a new EditorState with the updated content
      const newEditorState = EditorState.push(
        contentEditorState,
        newContentState,
        'insert-characters'
      );
  
      // Update the editor state
      setContentEditorState(newEditorState);
  
      handleCloseExplainCard();
    } catch (error) {
      toast.error("Error in append");
    }
  };

    return(
        <>
        <div style={{position:'absolute', right:'10%', top:'40%', zIndex:'999',minWidth:'300px', maxWidth:'500px'}}>
        <Card style={{position:'relative' , padding:'10px 20px', borderRadius:'10px'}}>
            <div style={{display:'flex', justifyContent:'center'}}>
                <Typography variant="body1">
                    <strong>Explain</strong>
                </Typography>
            </div>
            <div style={{margin:'15px 0px'}}>
            <Typography variant="body1" >
                {explanationText}
            </Typography>
            </div>
            <div style={{display:'flex', justifyContent:'space-between'}}>
                <Button variant="contained"  onClick={handleCloseExplainCard}>Ignore</Button>
                <Button variant="contained" color="primary" onClick={()=>{handleOnClick()}}>Accept</Button>
            </div>
        </Card>
        </div>
        </>
    )
}

export const PreviewDocModal = ({ isOpen, handleClose,docName,editorData }) => {

  const [editorState ,setEditorState] =useState()
  useEffect(() => {
    console.log("editorData_", editorData);
    if (editorData) {
      const currentContent = editorData.getCurrentContent();
      if (currentContent) {
          const plainText = currentContent.getPlainText();
          console.log("Plain text:", plainText);
          setEditorState(plainText)
      } else {
          console.log("Editor data does not have current content.");
      }
  } else {
      console.log("Editor data is null or undefined.");
  }
}, [editorData]);

function handleDownloadFormain() {

  if (!editorData.getCurrentContent().getPlainText()) {
    toast.warn(`You don't have text, to download`);
    return;
  }
  const blob = new Blob([editorData.getCurrentContent().getPlainText()], { type: 'text/plain' });
  console.log(blob, "blob");
  const anchor = document.createElement('a');
  anchor.href = URL.createObjectURL(blob);
  anchor.download = `${docName}.doc`;
  anchor.click();
  URL.revokeObjectURL(anchor.href);
  handleClose()
}
    return (
        <Modal show={isOpen} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{docName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container d-flex justify-content-center align-items-center">
        <div>
        {editorState ? (
          <p>{editorState}</p>
        ) : (
          <p>Please enter some text to preview</p>
        )}
      </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
          <B_Button variant="secondary" onClick={handleDownloadFormain}>
            Download <DownloadIcon/>
          </B_Button>
        </Modal.Footer>
      </Modal>
    );
  };


  export const UploadDocModal = ({ isOpen, handleClose,handleUploadFile,key }) => {

   
    const history = useHistory()
    function handleCreateNewDocument(){
      history.push('/mynewdocuments');
    }
    return (
      <Modal show={isOpen} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add New Version</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '10px',
            }}
          >
                      <Button
                        title={"Upload"} style={{ textDecoration: 'underline' , height:'50px'}}
                        component="label" htmlFor="coAutherFileInput"
                      >
                        <UploadIcon />
                        <VisuallyHiddenInput
                          id="coAutherFileInput"
                          type="file"
                          key={key}
                          onChange={handleUploadFile} // Directly call the function here
                        />
                        UPLOAD DOCUMENT
                      </Button>
            <Button onClick={handleCreateNewDocument} variant="outline-secondary" style={{ textDecoration: 'underline' , height:'50px'}}>
              <CreateNewFolderIcon /> Create New Document
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

