import React, { useState, useEffect } from 'react';
import ShareFile from './shareFileModal';
import { Modal, Button } from 'react-bootstrap';
import documentService from '../services/document';
import { toast } from 'react-toastify';
import { stateToHTML } from "draft-js-export-html";
import { convertFromRaw } from "draft-js";
import { asBlob } from 'html-docx-js-typescript'
import { saveAs } from "file-saver";
import adminServices from '../services/adminServices';
import Loader from '../utils/loader';
import html2pdf from 'html2pdf.js';
import { get } from 'lodash';
import { getBlogById, getAllUsersBlogs } from '../services/blogServices';


////         Share Functionality   ////////////////////////////

export const ShareOptionModal = ({ show, onHide, id, setMyDocs }) => {
    const [documentData, setDocumentData] = useState(null);
    const [allDocumentData, setAllDocumentData] = useState([])
    // const [shareFileKey, setShareFileKey] = useState(0);


    const getDocsById = async () => {
        try {
            const response = await documentService.getDocumentbyId(id);
            if (response.status === 200) {
                // Store the data in the state variable
                console.log("setDocumentData:",response.data.document)
                setDocumentData(response.data.document);

            } else {
                console.error("Error fetching data:", response.message);
            }
        } catch (error) {
            console.error("Error calling backend API:", error.message);
        }
    };

    const getDocs = async () => {
        try {
            const response = await documentService.getAllDocuments();

            if (response.status === 200) {
                setAllDocumentData(response.data);
                //setDocuments(response.data);
                setMyDocs(response.data);
            } else {
                console.error("Error fetching data:", response.message);
            }
        } catch (error) {
            console.error("Error calling backend API:", error.message);
        }
    };


    useEffect(() => {
        // Fetch data when the component mounts or 'id' changes
console.log("id__", id)
        if (id) {
            getDocsById();
            getDocs()
        }
    }, [id]);

    return (
        <>
            <Modal show={show} onHide={onHide} centered id="shareOptionModal" style={{ marginTop: '30px' }}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Share Options
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="container-fluid">
                        {/* Access ShareFile component here */}
                        {documentData !== null && <ShareFile
                            // key={shareFileKey}
                            shareDocumentId={documentData}
                            getDocuments={getDocs}
                            closeModal={onHide}
                        />}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export const ShareOptionModal2 = ({ show, onHide, id}) => {
    const [documentData, setDocumentData] = useState(null);
    const [allDocumentData, setAllDocumentData] = useState([])
    // const [shareFileKey, setShareFileKey] = useState(0);


    const getDocsById = async () => {
        try {
            const response = await getBlogById(id);
            if (response.status === 200) {
                // Store the data in the state variable
                console.log("setDocumentData:",response.data)
                setDocumentData(response.data);

            } else {
                console.error("Error fetching data:", response.message);
            }
        } catch (error) {
            console.error("Error calling backend API:", error.message);
        }
    };

    const getDocs = async () => {
        try {
            const response = await getAllUsersBlogs(5,0,"all");

            if (response.status === 200) {
                setAllDocumentData(response.data);
                //setDocuments(response.data);
                //setMyDocs(response.data);
            } else {
                console.error("Error fetching data:", response.message);
            }
        } catch (error) {
            console.error("Error calling backend API:", error.message);
        }
    };


    useEffect(() => {
        // Fetch data when the component mounts or 'id' changes
console.log("id__", id)
        if (id) {
            getDocsById();
            getDocs()
        }
    }, [id]);

    return (
        <>
            <Modal show={show} onHide={onHide} centered id="shareOptionModal" style={{ marginTop: '30px' }}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Share Options
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="container-fluid">
                        {/* Access ShareFile component here */}
                        {documentData !== null && <ShareFile
                            // key={shareFileKey}
                            shareDocumentId={documentData}
                            getDocuments={getDocs}
                            closeModal={onHide}
                        />}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

///////////////   Delete Document Functionality    /////////////////////////////////////////

export const DeleteOptionModal = ({ show, onHide, id, getAllDocuments }) => {
    const [documentData, setDocumentData] = useState(null);

    const getDocsById = async () => {
        try {
            const response = await documentService.getDocumentbyId(id);
            if (response.status === 200) {
                setDocumentData(response.data);
            } else {
                console.error("Error fetching data:", response.message);
            }
        } catch (error) {
            console.error("Error calling backend API:", error.message);
        }
    };

    useEffect(() => {
        // Fetch data when the component mounts or 'id' changes
        if (id) {
            getDocsById();
        }
    }, [id]);

    const handleDelete = async () => {
        try {

            const data = await documentService.getDocumentbyId(documentData.document._id);

            if (data.status === 200) {

                if (data.data.document.activeDoc === true) {

                    if (documentData.document.status === "draft") {

                        if (documentData.document.reviewStatus === true) {

                            const og = await documentService.getDocumentbyId(documentData.document.orginalDocumentID);
                            if (og.status === 200) {
                                if (og.data.pendingDocuments.includes(documentData.document._id)) {
                                    toast.warn("Document is in Merging process");
                                    return;
                                }
                            }
                        }
                    }


                    const res = await documentService.deleteDocuments(documentData.document._id);

                    if (res.status === 200) {
                        toast.success("Document deleted!");
                        onHide(); // Close the modal after deletion
                        getAllDocuments()
                    } else {
                        toast.error("Oops..Something went wrong!!");
                    }
                } else {
                    toast.error("Error deleting document:");
                    onHide(); // Close the modal
                }
            } else {
                toast.error("Error fetching document information");
            }
        } catch (error) {
            console.error("Error deleting document:", error.message);
            toast.error("Oops..Something went wrong!!");
        }
    };

    return (
        <Modal show={show} onHide={onHide} centered id="deleteOptionModal">
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Delete Document
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to delete the document?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={handleDelete}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    );
};


///////////////   Delete Folder Functionality    /////////////////////////////////////////

export const DeleteFolderOptionModal = ({ show, onHide, id, getAllDocuments }) => {
    const [documentData, setDocumentData] = useState(null);

    const getFolderById = async () => {
        try {
            const response = await documentService.getFolderbyId(id);
            if (response.status === 200) {
                setDocumentData(response.data);
            } else {
                console.error("Error fetching data:", response.message);
            }
        } catch (error) {
            console.error("Error calling backend API:", error.message);
        }
    };

    useEffect(() => {
        // Fetch data when the component mounts or 'id' changes
        if (id) {
            getFolderById();
        }
    }, [id]);

    const handleDelete = async () => {
        try {
            const res = await documentService.deleteFolder(documentData._id);
    
            if (res.status === 200) {
                toast.success("Folder deleted!");
                onHide(); // Close the modal after deletion
                getAllDocuments();
            } else {
                toast.error("Oops.. Something went wrong!!");
            }
        } catch (error) {
            console.error("Error deleting folder:", error.message);
            toast.error("Oops.. Something went wrong!!");
        }
    };

    return (
        <Modal show={show} onHide={onHide} centered id="deleteOptionModal">
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Delete Folder
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to delete this folder?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={handleDelete}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    );
};


//////////////     Restore Document Functionality           /////////////////////////////////////

export const RestoreOptionModal = ({ show, onHide, id, getAllDocuments }) => {
    const [documentData, setDocumentData] = useState(null);

    const getDocsById = async () => {
        try {
            const response = await documentService.getDocumentbyId(id);
            if (response.status === 200) {
                setDocumentData(response.data.document);
            } else {
                console.error("Error fetching data:", response.message);
            }
        } catch (error) {
            console.error("Error calling backend API:", error.message);
        }
    };

    useEffect(() => {
        // Fetch data when the component mounts or 'id' changes
        if (id) {
            getDocsById();
        }
    }, [id]);

    const handleRestore = async () => {
        try {
            const data = await documentService.getDocumentbyId(documentData._id);

            if (data.status === 200) {
                if (data.data.document.activeDoc === false) {
                    const res = await documentService.restoreDocuments(documentData._id);

                    if (res.status === 200) {
                        toast.success("Document restored!");
                        onHide(); // Close the modal after restoration
                        getAllDocuments();
                    } else {
                        toast.error("Oops..Something went wrong!!");
                    }
                } else {
                    alert("Document is not deleted");
                    onHide(); // Close the modal
                }
            } else {
                alert("Error fetching document information");
            }
        } catch (error) {
            console.error("Error restoring document:", error.message);
            toast.error("Oops..Something went wrong!!");
        }
    };

    return (
        <Modal show={show} onHide={onHide} centered id="restoreOptionModal">
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Restore Document
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to restore the document?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Cancel
                </Button>
                <Button variant="success" onClick={handleRestore}>
                    Restore
                </Button>
            </Modal.Footer>
        </Modal>
    );
};


//////////////     Restore Folder Functionality           /////////////////////////////////////

export const RestoreFolderOptionModal = ({ show, onHide, id, getAllDocuments }) => {
    const [documentData, setDocumentData] = useState(null);

    const getFolderById = async () => {
        try {
            const response = await documentService.getFolderbyId(id);
            if (response.status === 200) {
                setDocumentData(response.data);
            } else {
                console.error("Error fetching data:", response.message);
            }
        } catch (error) {
            console.error("Error calling backend API:", error.message);
        }
    };

    useEffect(() => {
        // Fetch data when the component mounts or 'id' changes
        if (id) {
            getFolderById();
        }
    }, [id]);

    const handleRestore = async () => {
        try {
            const data = await documentService.getFolderbyId(documentData._id);

            console.log(data,"restore data folder")

            if (data.status === 200) {
                if (data.data.activeFolder === false) {
                    const res = await documentService.restoreFolder(documentData._id);

                    if (res.status === 200) {
                        toast.success("Folder restored!");
                        onHide(); // Close the modal after restoration
                        getAllDocuments();
                    } else {
                        toast.error("Oops..Something went wrong!!");
                    }
                } else {
                     toast.error("Folder is not deleted");
                    onHide(); // Close the modal
                }
            } else {
                 toast.error("Error fetching document information");
            }
        } catch (error) {
            console.error("Error restoring document:", error.message);
            toast.error("Oops..Something went wrong!!");
        }
    };

    return (
        <Modal show={show} onHide={onHide} centered id="restoreOptionModal">
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Restore Folder
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to restore this folder?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Cancel
                </Button>
                <Button variant="success" onClick={handleRestore}>
                    Restore
                </Button>
            </Modal.Footer>
        </Modal>
    );
};



/////////////////////  Individual Document - Delete Permanently  Functionality         ///////////////////////////////////

export const PermanentDeleteOptionModal = ({ show, onHide, id, getAllDocuments }) => {
    const [documentData, setDocumentData] = useState(null);

    const getDocsById = async () => {
        try {
            const response = await documentService.getDocumentbyId(id);
            if (response.status === 200) {
                setDocumentData(response.data.document);
            } else {
                console.error("Error fetching data:", response.message);
            }
        } catch (error) {
            console.error("Error calling backend API:", error.message);
        }
    };

    useEffect(() => {
        // Fetch data when the component mounts or 'id' changes
        if (id) {
            getDocsById();
        }
    }, [id]);

    const handlePermanentDelete = async () => {
        try {

            const data = await documentService.getDocumentbyId(documentData._id);

            if (data.status === 200) {

                if (data.data.document.activeDoc === false) {

                    const res = await documentService.permanentDelete({
                        _id: documentData._id,
                        drafts: documentData.drafts,
                        status: documentData.status,
                        orginalDocumentID: documentData.orginalDocumentID,
                        editor: documentData.editor,
                        pendingDocuments: documentData.pendingDocuments,
                        mergeDocuments: documentData.mergeDocuments,
                    });


                    if (res.status === 200) {
                        toast.success("Document deleted permanently!");
                        onHide(); // Close the modal after permanent deletion
                        getAllDocuments();
                    } else {
                        toast.error("Oops..Something went wrong!!");
                    }
                } else {
                    alert("Document is not deleted");
                    onHide(); // Close the modal
                }
            } else {
                alert("Error fetching document information");
            }
        } catch (error) {
            console.error("Error permanently deleting document:", error.message);
            toast.error("Oops..Something went wrong!!");
        }
    };


    return (
        <Modal show={show} onHide={onHide} centered id="permanentDeleteOptionModal">
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Delete Document
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to permanently delete the document?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={handlePermanentDelete}>
                    Delete Permanently
                </Button>
            </Modal.Footer>
        </Modal>
    );
};


/////////////////////  Individual Folder - Delete Permanently  Functionality         ///////////////////////////////////


export const PermanentDeleteFolderOptionModal = ({ show, onHide, id, getAllDocuments }) => {
    const [documentData, setDocumentData] = useState(null);

    const getFolderById = async () => {
        try {
            const response = await documentService.getFolderbyId(id);
            if (response.status === 200) {
                setDocumentData(response.data);
            } else {
                console.error("Error fetching data:", response.message);
            }
        } catch (error) {
            console.error("Error calling backend API:", error.message);
        }
    };

    useEffect(() => {
        // Fetch data when the component mounts or 'id' changes
        if (id) {
            getFolderById();
        }
    }, [id]);

    const handlePermanentDelete = async () => {
        try {

            const data = await documentService.getFolderbyId(documentData._id);

            if (data.status === 200) {

                if (data.data.activeFolder === false) {

                    const res = await documentService.permanentDeleteFolder(documentData._id);


                    if (res.status === 200) {
                        toast.success("Folder deleted permanently!");
                        onHide(); // Close the modal after permanent deletion
                        getAllDocuments();
                    } else {
                        toast.error("Oops..Something went wrong!!");
                    }
                } else {
                    toast.error("Folder is not deleted");
                    onHide(); // Close the modal
                }
            } else {
                toast.error("Error fetching document information");
            }
        } catch (error) {
            console.error("Error permanently deleting document:", error.message);
            toast.error("Oops..Something went wrong!!");
        }
    };


    return (
        <Modal show={show} onHide={onHide} centered id="permanentDeleteOptionModal">
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Delete Folder
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to permanently delete this folder?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={handlePermanentDelete}>
                    Delete Permanently
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

/////////////////      Download Functionality         /////////////////////////////////////

export const DownloadOptionModal = ({ show, onHide, id }) => {
    const [documentData, setDocumentData] = useState(null);
    const [loading, setLoading] = useState(false);

    const getDocsById = async () => {
        try {
                const response = await documentService.getDocumentbyId(id);
                if (response.status === 200) {
                setDocumentData(response.data);
            } else {
                console.error("Error fetching data:", response.message);
            }
        } catch (error) {
            console.error("Error calling backend API:", error.message);
        }
    };

    useEffect(() => {
      // Fetch data when the component mounts or 'id' changes
      if (id) {
        getDocsById();
      }
    }, [id]);

    const handleDocxDownload = async () => {
        // const contentState = convertFromRaw(JSON.parse(documentData.onlyDocumentContent));
        // const contentHTML = stateToHTML(contentState);

        const htmlString = `<!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <title>${documentData.document.documentName}</title>
            <style>
        table, th, td {
            border: 1px solid black;
            border-collapse: collapse;
        }
        th, td {
            padding: 5px;
            text-align: left;
        }
    </style>
        </head>
        <body>
            <h3>${documentData.document.documentName}</h3>
            <hr/>
            ${documentData.documentBody}
        </body>
        </html>`;

        console.log(htmlString,"heyhey")

        asBlob(htmlString).then(docx => {
            saveAs(docx, `${documentData.document.documentName}.docx`);
            onHide(); // Close the modal after the download
        });
    };


    const downloadSinglePDF = () => {



        // const parsedJson = documentData.onlyDocumentContent ? JSON.parse(documentData.onlyDocumentContent) : { blocks: [{ text: '' }] };
        // const description = parsedJson.blocks[0].text;

        const pdfContent = `
        <style>
        table, th, td {
            border: 1px solid black;
            border-collapse: collapse;
        }
        th, td {
            padding: 5px;
            text-align: left;
        }
    </style>
    <div><h5>${documentData.document.documentName}</h5><p>${documentData.documentBody}</p></div>`;

        console.log(pdfContent,"hoyhoy")



        const element = document.createElement('div');
        element.innerHTML = pdfContent;

        html2pdf(element, {
            margin: 10,
            filename: `${documentData.document.documentName}.pdf`, // Set the filename to the document name
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        }).then((pdf) => {
            saveAs(pdf, `${documentData.document.documentName}.pdf`);
        });
        onHide();
    };



    return (
        <>
    <Loader loading={loading} />
      <Modal show={show} onHide={onHide} centered id="downloadOptionModal">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Download Options
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="text-center">
            <span>Download as...</span>
            <br /> <br />
            <Button
              variant="primary"
              className="inviteMember mr-3 pl-5 pr-5"
              onClick={downloadSinglePDF}
            >
              PDF
            </Button>
            <Button
              variant="primary"
              className="inviteMember pl-5 pr-5"
              onClick={handleDocxDownload}
            >
              Word
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      </>

    );
};


export const DownloadBlogOptionModal = ({ show, onHide, id }) => {
    const [documentData, setDocumentData] = useState(null);
    const [loading, setLoading] = useState(false);

    const getDocsById = async () => {
        try {
                const response = await getBlogById(id);
                if (response.status === 200) {
                setDocumentData(response.data);
            } else {
                console.error("Error fetching data:", response.message);
            }
        } catch (error) {
            console.error("Error calling backend API:", error.message);
        }
    };

    useEffect(() => {
      // Fetch data when the component mounts or 'id' changes
      if (id) {
        getDocsById();
      }
    }, [id]);

    const handleDocxDownload = async () => {
        // const contentState = convertFromRaw(JSON.parse(documentData.onlyDocumentContent));
        // const contentHTML = stateToHTML(contentState);

        const htmlString = `<!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <title>${documentData.documentName}</title>
            <style>
        table, th, td {
            border: 1px solid black;
            border-collapse: collapse;
        }
        th, td {
            padding: 5px;
            text-align: left;
        }
    </style>
        </head>
        <body>
            <h3>${documentData.documentName}</h3>
            <hr/>
            ${documentData.onlyDocumentContent}
        </body>
        </html>`;

        console.log(htmlString,"heyhey")

        asBlob(htmlString).then(docx => {
            saveAs(docx, `${documentData.documentName}.docx`);
            onHide(); // Close the modal after the download
        });
    };


    const downloadSinglePDF = () => {



        // const parsedJson = documentData.onlyDocumentContent ? JSON.parse(documentData.onlyDocumentContent) : { blocks: [{ text: '' }] };
        // const description = parsedJson.blocks[0].text;

        const pdfContent = `
        <style>
        table, th, td {
            border: 1px solid black;
            border-collapse: collapse;
        }
        th, td {
            padding: 5px;
            text-align: left;
        }
    </style>
    <div><h3>${documentData.documentName}</h3><hr/><p>${documentData.onlyDocumentContent}</p></div>`;

        console.log(pdfContent,"hoyhoy")



        const element = document.createElement('div');
        element.innerHTML = pdfContent;

        html2pdf(element, {
            margin: 10,
            filename: `${documentData.documentName}.pdf`, // Set the filename to the document name
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        }).then((pdf) => {
            saveAs(pdf, `${documentData.documentName}.pdf`);
        });
        onHide();
    };



    return (
        <>
    <Loader loading={loading} />
      <Modal show={show} onHide={onHide} centered id="downloadOptionModal">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Download Options
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="text-center">
            <span>Download as...</span>
            <br /> <br />
            <Button
              variant="primary"
              className="inviteMember mr-3 pl-5 pr-5"
              onClick={downloadSinglePDF}
            >
              PDF
            </Button>
            <Button
              variant="primary"
              className="inviteMember pl-5 pr-5"
              onClick={handleDocxDownload}
            >
              Word
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      </>

    );
};




/////////////////      Permanent Delete All Documents         /////////////////////////////////////

export const PermanentDeleteAllModal = ({ show, onHide, ids, getAllDocuments }) => {




    const handlePermanentDelete = async () => {
        try {

            // Call the service function
            const response = await documentService.permanentDeleteAllDocuments(ids);

            // Check if the request was successful
            if (response.status === 200) {
                toast.success('Documents deleted successfully');
                onHide(); // Close the modal after permanent deletion
                getAllDocuments();
            } else {
                toast.error("Oops..Something went wrong!!");
                onHide();
            }
        } catch (error) {
            toast.error("Oops..Something went wrong!!");
            onHide();
        }
    };


    return (
        <Modal show={show} onHide={onHide} centered id="permanentDeleteAllModal">
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Delete Documents
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to permanently delete all selected documents/folders ?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={handlePermanentDelete}>
                    Delete Permanently
                </Button>
            </Modal.Footer>
        </Modal>
    );
};


/////////////////      Delete All 'My Documents'         /////////////////////////////////////

export const DeleteAllMyDocsModal = ({ show, onHide, ids, getAllDocuments }) => {
    const [deleting, setDeleting] = useState(false);

    const handleDelete = async () => {
        setDeleting(true);
        let successCount = 0;
        try {
            for (const id of ids) {
                // Check if the ID belongs to a document
                const docResponse = await documentService.getDocumentbyId(id);
                if (docResponse.status === 200) {
                    const documentData = docResponse.data;
                    if (documentData.document.activeDoc === true) {
                        // Delete the document
                        if (documentData.document.status === "draft" && documentData.document.reviewStatus === true) {
                            const og = await documentService.getDocumentbyId(documentData.document.orginalDocumentID);

                            if (og.status === 200) {
                                if (og.data.pendingDocuments.includes(documentData.document._id)) {
                                    toast.warn("Document is in merging process. Skipping...");
                                    continue;
                                }
                            }
                        }
                        const docDeleteResponse = await documentService.deleteDocuments(documentData.document._id);
                        if (docDeleteResponse.status === 200) {
                            successCount++;
                        } else {
                            toast.error("Failed to delete document");
                        }
                    } else {
                        toast.warn("Error deleting documents");
                    }
                } else {
                    // If the ID doesn't belong to a document, check if it belongs to a folder
                    const folderResponse = await documentService.getFolderbyId(id);
                    if (folderResponse.status === 200) {
                        const folderData = folderResponse.data;
                        if (folderData.activeFolder === true) {
                            // Delete the folder
                            const folderDeleteResponse = await documentService.deleteFolder(id);
                            if (folderDeleteResponse.status === 200) {
                                successCount++;
                            } else {
                                toast.error("Failed to delete folder");
                            }
                        } else {
                            toast.warn("Error deleting folder");
                        }
                    } else {
                        toast.error("Error fetching document or folder");
                    }
                }
            }
            if (successCount > 0) {
                toast.success("Documents deleted!");
            }
            onHide();
            getAllDocuments();
        } catch (error) {
            console.error("Error deleting documents and folders:", error.message);
            toast.error("Oops..Something went wrong!!");
        } finally {
            setDeleting(false);
        }
    };

    return (
        <Modal show={show} onHide={onHide} centered id="deleteMultipleDocumentsModal">
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Delete Documents
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to delete all selected documents/folders ?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide} disabled={deleting}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={handleDelete} disabled={deleting}>
                    {deleting ? 'Deleting...' : 'Delete'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
