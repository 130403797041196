import React, { Component } from "react";
import Chart from "react-apexcharts";

export class Apex extends Component {
  render() {
    let options = {
      chart: {
        height: 350,
        type: "line",
        stacked: false,
      },
      // title: {
      //   text: this.props.title,
      //    align:"",
      //   style: {
      //     fontSize:  '20px',
      //     color:  "#1976d2",
      //   },
      // },
      fill: {
        colors: ["#678ffc", "#34eb8f", "#ffb200"],
      },
      colors: ["#678ffc", "#34eb8f", "#ffb200"],
      xaxis: {
        categories: this.props.monthList,
      },
      yaxis: [
        {
          // axisTicks: {
          //   show: true,
          // },
          axisBorder: {
            show: true,
            color: "#008FFB",
          },

          // tooltip: {
          //   enabled: true,
          // },
        },
      ],
      tooltip: {
        fixed: {
          enabled: true,
          position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
          offsetY: 30,
          offsetX: 60,
        },
      },
    };

    let series = [
      {
        name: this.props.cmp1,
        type: "column",
        data: this.props.bar1,
      },
      {
        name: this.props.cmp2,
        type: "column",
        data: this.props.bar2,
      },
      {
        name: this.props.cmp3,
        type: "line",
        data: [0, 0, 60, 50, 80, 75, 82, 90, 110, 140, 100, 70],
        data: this.props.linedata,
      },
    ];

    return (
      <div className="app">
        <div className="row">
          <div className="col-12 apexCharts">
            {/* <div className="mixed-chart "> */}

            <Chart
              options={options}
              series={series}
              type="line"
              id="charts"
              height={400}
              style={{ backgroundColor: "white" }}
            />
            {/* </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default Apex;
