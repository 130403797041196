import React from "react";
// import DashBoard from "../DashBoard";
import DashBoard from "../DashBoard2";
import NetworkDetector from "../HigherOrderComponents/NetworkDetector";
import queryString from "query-string";
import SideNavBar from "../SideNavBar";
const DashBoardPage = props => {
  if (props.location.search) {
    let values = queryString.parse(props.location.search);
    sessionStorage.setItem("token", values.token);
  }
  return (
    <SideNavBar>
      <DashBoard />
    </SideNavBar>
  );
};
export default NetworkDetector(DashBoardPage);