import React, { useEffect, useState } from "react";
import { createContext  } from "react";
import { connect } from "react-redux";
import ChatServices from "../services/ChatServices";
import { setCurrentUser } from "../redux/user/user.actions";
export const ChatContext = createContext();

const ChatContextProvider = ({ children }) => {

  const [groups, setGroups] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [selectedChat, setSelectedContactChat] = useState(null);
  const [selectedInboxId, setSelectedInboxId] = useState(null);
  const userId  = sessionStorage.getItem('userId');
  const [colorIndex , setColorIndex] = useState('');
  const [audioURL, setAudioURL] = useState("");
  const [showFilePage , setShowFilePage] = useState(false);
  const [unreadMessagesCount , setUnreadMessagesCount] = useState(0);
  const [DeleteGroupId , setDeleteGroupId] = useState(null);
  const [track, setTrack] = useState('');
  const [audios, setAudios] = useState([]);

  const fetchUsersChat = async () => {
    try {
      const response = await ChatServices.fetchMessages(userId);
      if (response.status === 200) {
        setContacts(response?.chatData?.contacts)
        setGroups(response?.chatData?.groups);
      } else {
        console.error("Error Opening Chat:", response.message);
      }
    } catch (error) {
      console.error("Error calling backend API:", error.message);
    }
  }

  useEffect(() => {
    fetchUsersChat();
  }, []);


  return (
    <ChatContext.Provider
      value={{ groups, audios, setAudios, track, setTrack, DeleteGroupId, setDeleteGroupId,  contacts, colorIndex, unreadMessagesCount, setUnreadMessagesCount , showFilePage, setShowFilePage, audioURL, setAudioURL, selectedGroupId, setSelectedGroupId,  setColorIndex,  fetchUsersChat, userId , selectedInboxId, setSelectedInboxId, selectedContactId, setSelectedContactId, selectedChat, setSelectedContactChat }}
    >
      {children}
    </ChatContext.Provider>
  ); 
};

export default ChatContextProvider;