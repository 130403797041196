import React from 'react';
import { useState, useEffect } from "react";
import { Editor, EditorState, ContentState } from 'draft-js';
import FeatureButton from '../ReusableComponents/Button';
import Button from '@mui/material/Button';
import Loader from "../../utils/loader";
import { toast, ToastContainer } from "react-toastify";
import { getGrammerCheckPrompt,updatePromptGrammarCheck } from '../../services/promptsGeneratorServices';
 
const PromptsGenerator_GrammerCheck = () => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [loading, setLoading] = useState(false);
 
 
 
    const handleChange = (newEditorState) => {
        setEditorState(newEditorState)
    }
 
    const getGrammarCheckPrompt = async () => {
        try {
            const data = {
                featureName: "correct-grammar",
            };
            setLoading(true)
            const result = await getGrammerCheckPrompt(data);
            if (result.prompt_information !== "") {
                console.log("Responce from ParaPrompt API  ", result)
                const contentState = ContentState.createFromText(result.prompt_information);
                const newEditorState = EditorState.createWithContent(contentState);
                setEditorState(newEditorState);
            }
        } catch (error) {
            console.error('Error calling paraphrasePrompt:', error);
        }
        setLoading(false)
    };
 
    const savePrompt = async () => {
 
        try {
          const text = editorState.getCurrentContent().getPlainText()
          if (text === "") {
            toast.warn("Nothing to save");
            return;
          }
          let data = {
            featureName: "correct-grammar",
            prompt_information: text
          }
          setLoading(true)
          const response = await updatePromptGrammarCheck(data);
   
          if (response.status == 200) {
            console.log('Data from API:', response);
            toast.success('Prompt saved successfully.');
          } else {
            console.error('Unexpected response format:', response);
            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
          console.error('Error fetching data from API:', error);
        }
        setLoading(false);
   
      }
   
 
 
 
    return (
        <div>
            <div style={{ backgroundColor: "white", height: "9vh", width: "100%", display: "flex", alignItems: "center", marginTop: "20px", boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', borderRadius: '8px', }}>
            <Loader loading={loading} />
                <Button style={{ height: "60%", marginLeft: "10px" }} variant="contained"  onClick={() => getGrammarCheckPrompt()}>Generate</Button>
 
                {/* <div style={{ height: "100%", width: "92%", marginLeft: "10%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <FeatureButton divClassName={"px-1"} text={"Grammar"} style={{ width: "75px" }} size={"sm"} />
                    <FeatureButton divClassName={"px-1"} text={"Punctuation"} style={{ width: "95px" }} size={"sm"} onClick={() => handleGrammarCheckPrompt("punctuation")} />
                    <FeatureButton divClassName={"px-1"} text={"Word Confusion"} style={{ width: "120px" }} size={"sm"} onClick={() => handleGrammarCheckPrompt("word-confusion")} />
                    <FeatureButton divClassName={"px-1"} text={"Captalization"} style={{ width: "95px" }} size={"sm"} onClick={() => handleGrammarCheckPrompt("correct-capitalization")} />
                    <FeatureButton divClassName={"px-1"} text={"Repetition"} style={{ width: "75px" }} size={"sm"} onClick={() => handleGrammarCheckPrompt("repetitive")} />
                    <FeatureButton divClassName={"px-1"} text={"Compression"} style={{ width: "95px" }} size={"sm"} onClick={() => handleGrammarCheckPrompt("Compression")} />
                    <FeatureButton divClassName={"px-1"} text={"Expansion"} style={{ width: "75px" }} size={"sm"} onClick={() => handleGrammarCheckPrompt("Expansion")} />
                    <FeatureButton divClassName={"px-1"} text={"Word Choice"} style={{ width: "110px" }} size={"sm"} onClick={() => handleGrammarCheckPrompt("Word_Choice")} />
                </div> */}
 
            </div>
 
 
            <div style={{ backgroundColor: "white", display: "flex", flexDirection: "column", height: "50vh", width: "100%", padding: "20px", boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', borderRadius: '8px', marginTop: "20px" }}>
                <div style={{ flex: "100%", display: "flex", flexDirection: "column", width: "100%", overflowY: "auto" }}>
                    <Editor
                        editorState={editorState}
                        // placeholder="grammer check"
                        onChange={handleChange}
                        style={{ flex: 1 }}
                    />
                </div>
                <div style={{ flex: "15%", width: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                    <Button variant="contained" onClick={() => savePrompt()}>Save</Button>
                </div>
            </div>
 
 
 
 
 
        </div>
    );
}
 
export default PromptsGenerator_GrammerCheck;