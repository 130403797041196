import React from 'react';
import { useState, useEffect } from "react";
import { Editor, EditorState, ContentState } from 'draft-js';
import FeatureButton from '../ReusableComponents/Button';
import Button from '@mui/material/Button';
import { getCitationPrompt, updateCitationPrompt } from '../../services/promptsGeneratorServices';
import Loader from '../../utils/loader';
import { toast } from 'react-toastify';




const PromptsGenerator_Citation = () => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [loading, setLoading] = useState(false);
  const [featureName, setFeatureName] = useState("Citation")
  const [ selectedType, setSelectedType] = useState('');

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
 
  // Update windowWidth on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
 
    window.addEventListener('resize', handleResize);
 
    return () => window.removeEventListener('resize', handleResize);
  }, []);
 
  const mobileView = windowWidth < 770
  const handleChange = (newEditorState) => {
    setEditorState(newEditorState)
  }


  const handleCitationPrompt = async (type) => {
 setSelectedType(type)
    try {
      const data = {
        featureName: featureName,
        type: type

      };
      console.log(data,"data")


      setLoading(true)
      const result = await getCitationPrompt(data);
      if (result.prompt_information !== "") {

        const contentState = ContentState.createFromText(result.prompt_information);
        const newEditorState = EditorState.createWithContent(contentState);
        setEditorState(newEditorState);
      }


    } catch (error) {
      console.error('Error calling paraphrasePrompt:', error);
    }


    setLoading(false)
  };




  const updateCitationPrompts = async () => {

    const text = editorState.getCurrentContent().getPlainText()
    if (text === "") {
      toast.warn("Nothing to save");
      return;
    }


    try {
      const data = {
        promptInformation: text,
        featureName: featureName,
        type: selectedType

      };
      console.log("Citation ", data)
      setLoading(true);
      const result = await updateCitationPrompt(data);
      if (result.prompt_information !== "") {
        console.log("Response from ParaPrompt API", result);
        console.log("Response from ParaPrompt API", result.data);


        toast.success("Data saved successfully")
      }
    } catch (error) {
      console.error('Error calling paraphrasePrompt:', error);
    }


    setLoading(false);
  };















  return (
    <div>
      <Loader loading={loading} />

      <div style={{ backgroundColor: "white", minHeight: "9vh", width: "100%", display: "flex", alignItems: "center", marginTop: "20px", boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', borderRadius: '8px', }}>
        <Button style={{ height: "60%", marginLeft: "10px" }} variant="contained" onClick={()=>handleCitationPrompt("defaultPrompt")}>Generate</Button>

        <div style={{ height: "100%", width: "92%", marginLeft: "5%", display: "flex", flexDirection: "row",flexWrap:'wrap', alignItems: "center" }}>
          <FeatureButton divClassName={"px-1"} text={"Webpage"} style={{ width: "75px", margin:'5px', fontSize: mobileView ? '10px' : ''  }} size={"sm"} onClick={() => handleCitationPrompt("Webpage") }/>
          <FeatureButton divClassName={"px-1"} text={"Journal Article"} style={{ width: "130px", margin:'5px', fontSize: mobileView ? '10px' : ''  }} size={"sm"}  onClick={() => handleCitationPrompt("JournalArticle") } />
          <FeatureButton divClassName={"px-1"} text={"Book"} style={{ width: "75px", margin:'5px', fontSize: mobileView ? '10px' : ''  }} size={"sm"}  onClick={() => handleCitationPrompt("Book") } />
          <FeatureButton divClassName={"px-1"} text={"Citation Style Change"} style={{ width: "140px", margin:'5px', fontSize: mobileView ? '10px' : ''  }} size={"sm"}  onClick={() => handleCitationPrompt("ChangeCitationStyle") } />
 
        </div>



        {/* <div style={{ height: "100%", width: "92%", marginLeft: "10%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <FeatureButton divClassName={"px-1"} text={"Cite"} style={{ width: "75px" }} size={"sm"} />
                    </div> */}

      </div>


      <div style={{ backgroundColor: "white", display: "flex", flexDirection: "column", height: "50vh", width: "100%", padding: "20px", boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', borderRadius: '8px', marginTop: "20px" }}>
        <div style={{ flex: "100%", display: "flex", flexDirection: "column", width: "100%", overflowY: "auto" }}>
          <Editor
            editorState={editorState}
            // placeholder="Citation"
            onChange={handleChange}
            style={{ flex: 1 }}
          />
        </div>
        <div style={{ flex: "15%", width: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
          <Button variant="contained" onClick={updateCitationPrompts}>Save</Button>
        </div>
      </div>





    </div>
  );
}

export default PromptsGenerator_Citation;
