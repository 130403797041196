import React from "react";
import Subadminsidebar from "../SubAdminSideBar"
import NetworkDetector from "../HigherOrderComponents/NetworkDetector";
import queryString from "query-string";
import SubAdminBlogs from "../SubAdminBlogs";

const SubAdminBlogPage= (props) => {
  if (props.location.search) {
    let values = queryString.parse(props.location.search);
    sessionStorage.setItem("token", values.token);
  }
  return (
    <Subadminsidebar>
      <SubAdminBlogs />
    </Subadminsidebar>
  );
};

export default NetworkDetector(SubAdminBlogPage);
