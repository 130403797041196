import React, { useEffect, useState } from "react";
import "./Main.css";
import Chat from "../Chat/Chat";
import { useContext } from "react";
import { ChatContext } from "../../../contexts/ChatContext";

const Main = () => {

  const { selectedInboxId } = useContext(ChatContext);

  // setSelectedContactId(InboxId ? InboxId : null);
  // setSelectedInboxId(InboxId ? InboxId : GroupInboxId ? GroupInboxId : null);
  // setSelectedGroupId(GroupInboxId ? GroupInboxId : null);

  return selectedInboxId === null ? (
    <div className="main">
      <h1>Welcome</h1>
      <p>Click on an inbox to start chatting!</p>
    </div>
  ) : (
    <Chat />
  );
};

export default Main;
