import React, { Component } from "react";
import AdminSideNavBar from "../AdminSideNavBar";
import NetworkDetector from "../HigherOrderComponents/NetworkDetector";
import ManageOrganization from "../ManageOrganization";

class ManageOrganizationPage extends Component {
  state = {};
  componentDidMount() {
    console.log(this.props.location.aboutProps);
  }
  render() {
    return (
      <AdminSideNavBar>
        <ManageOrganization />
      </AdminSideNavBar>
    );
  }
}

export default NetworkDetector(ManageOrganizationPage);
