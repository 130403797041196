import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: '#646464',
                    fontFamily: 'Montserrat, sans-serif',
                    fontWeight: '500',
                    // fontSize: '14px',
                    fontSize: '0.8rem',
                }
            }
        }
    }
})

export default function CheckboxMui({ id, defaultChecked,label, isChecked, onChange,disabled  }) {
    //   const [checked, setChecked] = useState(true);
    //   const handleChange = (event) => {
    //     setChecked(event.target.checked);
    //   };
    return (
        <ThemeProvider theme={theme}>
            <FormGroup>
                <FormControlLabel control={<Checkbox
                    id={id}
                    defaultChecked={defaultChecked}
                    checked={isChecked}
                    onChange={(e) => onChange(e)}
                    disabled={disabled}
                    inputProps={{ 'aria-label': 'controlled' }}
                />}
                    size="small"
                    label={label}
                />
            </FormGroup>
        </ThemeProvider>
    );
}
