import React from 'react';
import { useState, useEffect } from "react";
import { Editor, EditorState, ContentState } from 'draft-js';
import FeatureButton from '../ReusableComponents/Button';
import Button from '@mui/material/Button';
import { getHintPrompt, updateHintPrompt } from '../../services/promptsGeneratorServices';
import Loader from '../../utils/loader';
import { toast } from 'react-toastify';




const PromptsGenerator_Hint = () => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [loading, setLoading] = useState(false);
    const [featureName, setFeatureName] = useState("improve-phrasing")
    const [selectedType, setSelectedType] = useState('');

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
 
  // Update windowWidth on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
 
    window.addEventListener('resize', handleResize);
 
    return () => window.removeEventListener('resize', handleResize);
  }, []);
 
  const mobileView = windowWidth < 770
    
    const handleChange = (newEditorState) => {
        setEditorState(newEditorState)
    }


    const handleHintPrompt = async (type) => {
        setSelectedType(type)
        try {
            const data = {
                featureName: featureName,
                promptName: type

            };
            console.log(data, "data")
            setLoading(true)
            const result = await getHintPrompt(data);
            if (result.prompt_information !== "") {

                const contentState = ContentState.createFromText(result.prompt_information);
                const newEditorState = EditorState.createWithContent(contentState);
                setEditorState(newEditorState);
            }

        } catch (error) {
            console.error('Error calling hint prompt:', error);
        }
        setLoading(false)
    };


    const updateHintPrompts = async () => {
        const text = editorState.getCurrentContent().getPlainText()
        if (text === "") {
            toast.warn("Nothing to save");
            return;
        }
        try {
            const data = {
                promptInformation: text,
                featureName: featureName,
                promptName: selectedType

            };
            console.log("hint ", data)
            setLoading(true);
            const result = await updateHintPrompt(data);
            if (result.prompt_information !== "") {
                console.log("Response from ParaPrompt API", result);
                console.log("Response from ParaPrompt API", result.data);
                toast.success("Data saved successfully")
            }
        } catch (error) {
            console.error('Error calling hint prompt:', error);
        }
        setLoading(false);
    };

    return (
        <div>
            <Loader loading={loading} />

            <div style={{ backgroundColor: "white", minHeight: "9vh", width: "100%", display: "flex", alignItems: "center", marginTop: "20px", boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', borderRadius: '8px', }}>
                <Button style={{ height: "60%", marginLeft: "10px" }} variant="contained" onClick={() => handleHintPrompt("defaultPrompt")}>Generate</Button>

                <div style={{ height: "100%", width: "92%", marginLeft: "10%", display: "flex", flexWrap:'wrap', flexDirection: "row", alignItems: "center" }}>
                    <FeatureButton divClassName={"px-1"} text={"Rewrite"} style={{ width: "75px", margin:'5px' }} size={"sm"} onClick={() => handleHintPrompt("rewriteText")} />
                    <FeatureButton divClassName={"px-1"} text={"Statistical Data"} style={{ width: "130px", margin:'5px' }} size={"sm"} onClick={() => handleHintPrompt("statisticalData")} />
                    <FeatureButton divClassName={"px-1"} text={"Explanation"} style={{ width: "84px", margin:'5px' }} size={"sm"} onClick={() => handleHintPrompt("explanation")} />
                </div>
            </div>

            <div style={{ backgroundColor: "white", display: "flex", flexDirection: "column", height: "50vh", width: "100%", padding: "20px", boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', borderRadius: '8px', marginTop: "20px" }}>
                <div style={{ flex: "100%", display: "flex", flexDirection: "column", width: "100%", overflowY: "auto" }}>
                    <Editor
                        editorState={editorState}
                        onChange={handleChange}
                        style={{ flex: 1 }}
                    />
                </div>
                <div style={{ flex: "15%", width: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                    <Button variant="contained" onClick={updateHintPrompts}>Save</Button>
                </div>
            </div>
        </div>
    );
}

export default PromptsGenerator_Hint;
