import React from "react";
import { Modal, Box, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState, useEffect, useRef } from 'react';


const VideoModel = ({ open, handleClose, videoUrl }) => {
  // const url = `..${videoUrl}`
  console.log(videoUrl,"video")
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: windowWidth < 768 ? '90%' : '60%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    padding: windowWidth < 768 ? '16px' : '32px',
  };

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography id="modal-title" variant="h6" component="h2" style={{color: "#1976d2"}}>
            Video Demo
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon style={{color : "red"}} />
          </IconButton>
        </Box>
        <Box sx={{ mt: 2 }}>
          {/* <iframe
            width="100%"
            height="400px"
            src={videoUrl}
            title="Video Demo"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe> */}
           <video controls width="100%">
          <source src={videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        </Box>
      </Box>
    </Modal>
  );
};

export default VideoModel;
