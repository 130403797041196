import {  SelectionState, Modifier, convertToRaw, } from "draft-js";

export const getTextFromAnchorKey = (editorState, anchorKey) =>{
    let currentContent = editorState.getCurrentContent();
     let currentContentBlock = currentContent.getBlockForKey(anchorKey);
  let text = currentContentBlock && currentContentBlock.getText();
   return text
 }

export const updatedSelectionState = (anchorKey, start, end) =>{
    const blockSelection = SelectionState.createEmpty(anchorKey).merge({
        focusKey: anchorKey,
        anchorOffset: start,
        focusOffset: end
        });
    return blockSelection;
}

export const getSelectedText = (editorState) =>{
   const selectionState = editorState.getSelection();
   const anchorKey = selectionState.getAnchorKey();
   const currentContent = editorState.getCurrentContent();
   const currentContentBlock = currentContent.getBlockForKey(anchorKey);
   const start = selectionState.getStartOffset();
   const end = selectionState.getEndOffset();
   const selectedText = currentContentBlock.getText().slice(start, end);
   return selectedText;
}

 export const getAlltext = (editorState) =>{
    const current_content = editorState.getCurrentContent();
    const text = current_content.getPlainText();
    return text;
 }

 export const getCurrentAnchorKey = (editorState) =>{
    const selectionState = editorState.getSelection();
    const anchorKey = selectionState.getAnchorKey();
    return anchorKey;
 }

 export const currentTextandAnchorKey = (editorState) => {
    const selectionState = editorState.getSelection();
    const anchorKey = selectionState.getAnchorKey();
    const currentContent = editorState.getCurrentContent();
    const currentContentBlock = currentContent.getBlockForKey(anchorKey);
    const text = currentContentBlock.getText();
    return { text, anchorKey };
  };

export const getAnchorKeys = (editorState) =>{
   const current_content = editorState.getCurrentContent();
   const convertRaw = convertToRaw(current_content);
   const anchorKeys = (convertRaw.blocks || []).map((obj) => obj.key);
   return anchorKeys;
}

export const insertText = (currentState, text, style) =>{
      const current_content = currentState.getCurrentContent();
      const selection = currentState.getSelection();
      return Modifier.replaceText( current_content, selection, text, style );
}

 export default {
    getTextFromAnchorKey,
    updatedSelectionState,
    getAlltext,
    getCurrentAnchorKey,
    currentTextandAnchorKey,
    getSelectedText,
    getAnchorKeys,
    insertText
 }