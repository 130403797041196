

const initialState = {
    templateName: '',
    uploadDocument: '',
    sharedDocumentStatus: '',
    shareFolderId: '',
};

const templateReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_TEMPLATE_NAME':
            return {
                ...state,
                templateName: action.payload,
            };
        case 'RESET_TEMPLATE_NAME':
            return {
                ...state,
                templateName: action.payload,
            };
        case 'UPLOAD_COAUTHOR_DOCUMENT':
            return {
                ...state,
                uploadDocument: action.payload,
            };
        case 'SHARED_DOCUMENT_STATUS':
            return {
                ...state,
                sharedDocumentStatus: action.payload,
            };
        case 'SHARE_FOLDER_ID':
            return {
                ...state,
                shareFolderId: action.payload,
            };
        
        default:
            return state;
    }
};

export default templateReducer;
