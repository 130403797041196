import React, { useState, useEffect, useRef } from "react";
import { MenuItem, Select } from "@material-ui/core";
import { Button, Dropdown, FormControl } from "react-bootstrap";
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import FormatClearIcon from "@mui/icons-material/FormatClear";
import { indexOf } from "lodash";
import { RichUtils } from "draft-js";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'blue',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);



function SelectItem({ list, onBlockChange, currentBlockType, placeholder }) {
  const [selected, setSelected] = useState("");

  const onChangeFn = (style) => {
    setSelected(style);
    onBlockChange(style);
  };

  useEffect(() => {
    // console.log("currentBlockType", currentBlockType)
    if (currentBlockType === "unstyled") setSelected(placeholder);
    else setSelected(currentBlockType);
  }, [currentBlockType]);

  return (
    <Select
      value={selected}
      className="mr-3"
      onChange={(e) => onChangeFn(e.target.value)}
    >
      {list.map((item) => (
        <MenuItem value={item.style}>{item.label}</MenuItem>
      ))}
    </Select>
  );
}

function InlineSelect({ list, placeholder, currentStyle, onToggle, tooltipText }) {
  const [selected, setSelected] = useState("");
  const [showfontFamily, setFontFamily] = useState('roboto_regular');

  useEffect(() => {
    let getInlineStyle = null;

    list.forEach((obj) => {
      if (currentStyle.has(obj.style)) getInlineStyle = obj.style;
    });

    if (!getInlineStyle) {
      setSelected(placeholder);
      setFontFamily(placeholder);
    } else {
      setSelected(getInlineStyle);
      setFontFamily(getInlineStyle);
    }
  }, [currentStyle, placeholder, list]);

  const onChangeFn = (e, style) => {
    e.preventDefault();
    setSelected(style);
    setFontFamily(style);
    onToggle(style);
  };

  const handleFontFamily = (text) => {
    const inputStyle = text.target.value.toLowerCase();
    setFontFamily(inputStyle);
    const matchingStyle = list.find((style) => style.style === inputStyle);
    if (matchingStyle) {
      setSelected(inputStyle);
      onToggle(inputStyle);
    } else {
      console.warn(`Style ${inputStyle} is not in the list.`);
    }
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <input
          value={showfontFamily}
          type="text"
          style={{ width: "100px", fontSize: "11px", padding: "3px", border: "1px solid grey", outline: "none", }}
          onChange={(e) => {
            handleFontFamily(e);
          }}
        />
        <Dropdown

          style={{ border: "1px solid none" }}
          onMouseDown={(e) => {
            e.preventDefault();
          }}
        >
          <LightTooltip title={tooltipText}>
          <Dropdown.Toggle
            className="mydrop"
            variant="white"
            id="dropdown-basic"
            style={{ color: "#1976D2", fontSize: "12px", width: "fit-content" }}
          ></Dropdown.Toggle>
          </LightTooltip>

          <Dropdown.Menu className="setShadow">
            {list.map((item) => (
              <Dropdown.Item key={item.style} onMouseDown={(e) => onChangeFn(e, item.style)}>
                {item.label}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>

    </>
  );
}

function InlineSize({ list, placeholder, currentStyle, onToggle, tooltipText }) {
  const [selected, setSelected] = useState("");

  const onChangeFn = (e, style, label) => {
    e.preventDefault();
    console.log("style", style);
    setSelected(label);
    onToggle(style);
  };

  useEffect(() => {
    // console.log("working")
    console.log(list, "headingList");
    console.log(currentStyle, "current style size");
    let getInlineStyle = null;
    list.forEach((obj) => {
      if (currentStyle.has(obj.style)) getInlineStyle = obj.label;
    });
    if (!getInlineStyle) setSelected(placeholder);
    else setSelected(getInlineStyle);
    //   console.log("getInlineStyle", getInlineStyle)
  }, [currentStyle]);

  return (
    <Dropdown
      onMouseDown={(e) => {
        e.preventDefault();
      }}
    >
       <LightTooltip title={tooltipText}>
      <Dropdown.Toggle
        className="mydrop"
        variant="green"
        id="dropdown-basic"
        style={{ color: "#1976D2", fontSize: "15px", marginLeft: "5px" }}
      >
        {" "}
        {selected}{" "}
      </Dropdown.Toggle>
      </LightTooltip>

      <Dropdown.Menu className="setShadow">
        {list.map((item) => (
          <Dropdown.Item
            onMouseDown={(e) => onChangeFn(e, item.style, item.label)}
          >
            {item.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export function InlineAlign({ list, placeholder, currentStyle, onToggle }) {
  const [selected, setSelected] = useState("");

  const onChangeFn = (e, style, label) => {
    e.preventDefault();
    console.log("style", style);
    setSelected(label);
    onToggle(style);
  };

  useEffect(() => {
    // console.log("working")
    console.log(list, "headingList");
    console.log(currentStyle, "current style size");
    let getInlineStyle = null;
    list.forEach((obj) => {
      if (currentStyle.has(obj.style)) getInlineStyle = obj.label;
    });
    if (!getInlineStyle) setSelected(placeholder);
    else setSelected(getInlineStyle);
    //   console.log("getInlineStyle", getInlineStyle)
  }, [currentStyle]);

  return (
    <Dropdown
      onMouseDown={(e) => {
        e.preventDefault();
      }}
    >
      <Dropdown.Toggle
        className="mydrop"
        variant="green"
        id="dropdown-basic"
        style={{padding:"0px"}}
        
      >
        {" "}
        {selected}{" "}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {list.map((item) => (
          <Dropdown.Item
            onMouseDown={(e) => onChangeFn(e, item.style, item.label)}
          >
            {item.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

function InlineFontSize({ list, placeholder, currentStyle, onToggle }) {
  const [inputValue, setInputValue] = useState(14);
  const [lastStyle, setLastStyle] = useState(null);
  console.log(currentStyle, "currentStyle")
  useEffect(() => {
    let getInlineStyle = null;
    list.forEach((obj) => {
      if (currentStyle.has(obj.style)) getInlineStyle = obj.label;
    });

    // Check if the currentStyle has changed since the last update
    if (getInlineStyle !== lastStyle) {
      setLastStyle(getInlineStyle);
      if (!getInlineStyle) setInputValue(placeholder);
      else setInputValue(getInlineStyle);
    }
  }, [currentStyle, placeholder, list, lastStyle]);



  const handleToggle = (newSize) => {
    if (newSize >= 1 && newSize <= 99) {
      const matchingItem = list.find(
        (item) => item.label === newSize.toString()
      );
      if (matchingItem) {
        const style = matchingItem.style;
        onToggle(style);
      }
    }
  };

  const handleInputChange = (e) => {
    const newValue = e.target.value;

    // Check if the entered value is a two-digit number
    if (/^\d{0,2}$/.test(newValue)) {
      setInputValue(newValue);

      // If it's a two-digit number, trigger the handleToggle function
      if (newValue.length === 2) {
        handleToggle(newValue);
      }
    }
  };

  const handleIncrease = () => {
    setInputValue((prevValue) => {
      let newValue = parseInt(prevValue, 10) + 1;
      newValue = newValue > 99 ? 99 : newValue; // Ensure it doesn't exceed 99
      handleToggle(newValue.toString());
      return newValue < 10 ? `0${newValue}` : newValue.toString();
    });
  };

  const handleDecrease = () => {
    setInputValue((prevValue) => {
      let newValue = parseInt(prevValue, 10) - 1;
      newValue = newValue < 1 ? 1 : newValue; // Ensure it doesn't go below 1
      handleToggle(newValue.toString());
      return newValue < 10 ? `0${newValue}` : newValue.toString();
    });
  };

  return (
    <div
      style={{
        width: "120px",
        marginLeft: "20px",
        display: "flex",
        alignItems: "center",
        marginLeft: "15px",
        marginRight: "15px",
        fontSize: "18px"
      }}
    >
      <div
        onClick={handleDecrease}
        style={{
          fontSize: "20px",
          cursor: "pointer",
          width: "19px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        -
      </div>
      <LightTooltip title="Font Size">
      <input
        value={inputValue}
        onChange={handleInputChange}
        style={{
          width: "19px",
          padding: "2px",
          textAlign: "center",
          appearance: "none",
          outline: "none",
          color: "#1976D2",
          backgroundColor: "none",
          fontSize: "12px",
          border: "1px solid #1976D2",
          borderRadius: "2px",
        }}
      />
      </LightTooltip>
      <div
        onClick={handleIncrease}
        style={{
          cursor: "pointer",
          width: "19px",
          fontSize: "18px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        +
      </div>
    </div>
  );
}







function InlineParagraph({ list, placeholder, currentStyle, onToggle }) {
  const [selected, setSelected] = useState("");
  const onChangeFn = (e, style, label) => {
    // console.log(style,label,"----")
    e.preventDefault();
    setSelected(label);
    onToggle(style);
    //   console.log(selected,"style")
  };

  useEffect(() => {
    //   console.log(list,"headingList")
    //   console.log(currentStyle,"current style")
    let getInlineStyle = null;
    list.forEach((obj) => {
      if (currentStyle.has(obj.style)) getInlineStyle = obj.label;
    });
    if (!getInlineStyle) {
      setSelected(placeholder);
      // console.log(selected,"not")
    } else {
      setSelected(getInlineStyle);
      console.log(getInlineStyle, ".....");
      console.log(selected, "yes");
    }
  }, [currentStyle]);

  return (
    <Dropdown onMouseDown={(e) => e.preventDefault()}>
      <Dropdown.Toggle className="mydrop" variant="green" id="dropdown-basic">
        {selected}
      </Dropdown.Toggle>

      <Dropdown.Menu className="setShadow">
        {list.map((item, index) => (
          <Dropdown.Item
            key={index}
            onMouseDown={(e) => onChangeFn(e, item?.style, item?.label)}
          >
            {item?.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

function InlineSymbols({ placeholder, list, currentStyle, onToggle }) {
  const [selected, setSelected] = useState("");

  const onChangeFn = (e, label) => {
    e.preventDefault();
    console.log("style", label);
    setSelected(label);
    onToggle(label);
  };

  useEffect(() => {
    // console.log("working")
    console.log(list, "symbolList");
    console.log(currentStyle, "current style size");
    let getInlineStyle = null;
    list.forEach((obj) => {
      if (currentStyle.has(obj.style)) getInlineStyle = obj.label;
    });
    if (!getInlineStyle) setSelected(placeholder);
    else setSelected(getInlineStyle);
    //   console.log("getInlineStyle", getInlineStyle)
  }, [currentStyle]);

  return (
    <Dropdown
      onMouseDown={(e) => {
        e.preventDefault();
      }}
    >
      <LightTooltip title="Special Symbols">
        <Dropdown.Toggle
          className="mydrop"
          variant="green"
          id="dropdown-basic"
          style={{ color: "#1976D2", fontSize: "18px" }}
        >
          Ω
        </Dropdown.Toggle>
      </LightTooltip>
      <Dropdown.Menu
        className="setShadow"
        style={{
          height: "400px",
          overflowY: "auto",
          display: "grid",
          gridTemplateColumns: "repeat(8, 1fr)",
          gap: "10px",
          padding: "5px",
        }}
      >
        {list.map((item) => (
          <Dropdown.Item
            key={item.label}
            onMouseDown={(e) => onChangeFn(e, item.label)}
            style={{
              cursor: "pointer",
              padding: "8px",
              borderRadius: "4px",
              border: "1px solid transparent", // Initial border
              transition: "border 0.3s ease", // Add transition for a smooth effect
            }}
            onMouseOver={(e) => (e.target.style.border = "1px solid #000")} // Change border on hover
            onMouseOut={(e) =>
              (e.target.style.border = "1px solid transparent")
            } // Reset border on mouse out
          >
            {item.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

function TableMaker({ list, placeholder, currentStyle, onToggle }) {
  // console.log(list,"tablelist")

  const [Rows, setRows] = useState(0);
  const [Cols, setCols] = useState(0);
  const [selected, setSelected] = useState("");

  const onChangeFn = (e, rows, columns) => {
    e.preventDefault();
    // console.log(rows,columns,"table");
    // setSelected(style);
    onToggle(rows, columns);
  };

  useEffect(() => {
    let getInlineStyle = null;
    list.forEach((obj) => {
      if (currentStyle.has(obj.style)) getInlineStyle = obj.style;
    });
    if (!getInlineStyle) setSelected(placeholder);
    else setSelected(getInlineStyle);
    // console.log("getInlineStyle", getInlineStyle)
  }, [currentStyle]);

  return (
    <>
      <Dropdown
        onMouseDown={(e) => {
          e.preventDefault();
        }}
      >
        <Dropdown.Toggle
          className="mydrop"
          variant="green"
          id="dropdown-basic"
          style={{ color: "#1976D2" }}
        >
          {selected}
        </Dropdown.Toggle>

        <Dropdown.Menu
          className="setShadow"
          style={{
            maxHeight: "200px",
            overflowY: "auto",
            zIndex: "2000",
          }}
        >
          {list.map((item) => (
            <Dropdown.Item
              key={`${item.rows}-${item.columns}`}
              onMouseDown={(e) => onChangeFn(e, item.rows, item.columns)}
            >
              {item.rows} x {item.columns}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

function InlineClearFormating({ placeholder, list, currentStyle, onToggle }) {
  const [selected, setSelected] = useState("");

  const onChangeFn = (e) => {
    e.preventDefault();
    setSelected("clear");
    onToggle("clearStyle");
  };

  useEffect(() => {
    let getInlineStyle = null;
    list.forEach((obj) => {
      if (currentStyle.has(obj.style)) getInlineStyle = obj.label;
    });
    if (!getInlineStyle) setSelected(placeholder);
    else setSelected(getInlineStyle);
  }, [currentStyle]);

  return (
    <div style={{ backgroundColor: "transparent", width: "25px", height: "17px", display: 'flex', justifyContent: "center", outline: "none" }} onClick={(e) => onChangeFn(e)}>
      <FormatClearIcon style={{ color: "#1976D2", fontSize: "18px", outline: "none" }} />
    </div>
  );
}

function InlineColor({ list, placeholder, currentStyle, onToggle }) {
  const [selected, setSelected] = useState("");

  const onChangeFn = (e, style) => {
    e.preventDefault();
    // console.log("style", style);
    setSelected(style);
    onToggle(style);
  };

  useEffect(() => {
    let getInlineStyle = null;
    list.forEach((obj) => {
      if (currentStyle.has(obj.style)) getInlineStyle = obj.style;
    });
    if (!getInlineStyle) setSelected(placeholder);
    else setSelected(getInlineStyle);
    // console.log("getInlineStyle", getInlineStyle)
  }, [currentStyle]);

  return (
    <Dropdown
      onMouseDown={(e) => {
        e.preventDefault();
      }}
    >
      <LightTooltip title="Font Color">
        <Dropdown.Toggle
          variant="green"
          id="dropdown-basic"
          className="mydrop"
          style={{ color: "#1976D2", fontSize: "16px" }}
        >
          {selected}
        </Dropdown.Toggle>
      </LightTooltip>

      <Dropdown.Menu className="setShadow">
        {list.map((item) => (
          <Dropdown.Item
            className="drop_set"
            style={{ backgroundColor: item.style }}
            onMouseDown={(e) => onChangeFn(e, item.style)}
          >
            {item.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

function InlineTextAlign({ list, placeholder, currentStyle, onToggle }) {
  const [selected, setSelected] = useState("");

  const onChangeFn = (e, style, label) => {
    e.preventDefault();
    console.log("style", style);
    setSelected(label);
    onToggle(style);
  };

  useEffect(() => {
    // console.log("working")
    console.log(list, "headingList");
    console.log(currentStyle, "current style");
    console.log(onToggle, "gdfguygxyg");
    let getInlineStyle = null;
    list.forEach((obj) => {
      if (currentStyle.has(obj.style)) getInlineStyle = obj.label;
    });
    if (!getInlineStyle) setSelected(placeholder);
    else setSelected(getInlineStyle);
    console.log("gettextalignStyle", getInlineStyle);
  }, [currentStyle]);

  return (
    <Dropdown
      onMouseDown={(e) => {
        e.preventDefault();
      }}
    >
      <Dropdown.Toggle className="mydrop" variant="green" id="dropdown-basic">
        {" "}
        {selected}{" "}
      </Dropdown.Toggle>

      <Dropdown.Menu className="setShadow">
        {list.map((item) => (
          <Dropdown.Item
            onMouseDown={(e) => onChangeFn(e, item.style, item.label)}
          >
            {item.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

function AddfunctionControl({ list, placeholder, currentStyle, onToggle }) {
  const [selected, setSelected] = useState("");

  const onChangeFn = (e, style) => {
    e.preventDefault();
    // console.log("style", style);
    setSelected(style);
    onToggle(style);
  };

  useEffect(() => {
    let getInlineStyle = null;
    list.forEach((obj) => {
      if (currentStyle.has(obj.style)) getInlineStyle = obj.style;
    });
    if (!getInlineStyle) setSelected(placeholder);
    else setSelected(getInlineStyle);
    // console.log("getInlineStyle", getInlineStyle)
  }, [currentStyle]);

  return (
    <Dropdown
      onMouseDown={(e) => {
        e.preventDefault();
      }}
    >
      <LightTooltip title="Insert">
        <Dropdown.Toggle
          variant="green"
          id="dropdown-basic"
          className="mydrop"
          style={{ color: "#1976D2", fontSize: "18px" }}
        >
          +
        </Dropdown.Toggle>
      </LightTooltip>

      <Dropdown.Menu
        className="setShadow"
        style={{ display: "flex", alignItems: "center", color: "black" }}
      >

        <Dropdown.Item
          // title={"Monospace"}
          className="drop_set"
          style={{ backgroundColor: "white", color: "black" }}
          onMouseDown={(e) => onChangeFn(e, "CODE")}

        >
          <LightTooltip title="Monospace">
            <span style={{ color: "black" }}><i className="fa fa-code" aria-hidden="true"></i></span>
          </LightTooltip>
        </Dropdown.Item>




        <Dropdown.Item
          // title={"Code block"}
          className="drop_set"
          style={{ backgroundColor: "white", color: "black" }}
          onMouseDown={(e) => onChangeFn(e, "CODEBLOCK")}

        >
          <LightTooltip title="Code block">
            <span style={{ color: "black" }}> <IntegrationInstructionsIcon /></span>
          </LightTooltip>
        </Dropdown.Item>



        <Dropdown.Item
          // title={"Horizontal Line"}
          className="drop_set"
          style={{ backgroundColor: "white", color: "black" }}
          onMouseDown={(e) => onChangeFn(e, "HORIZONTALLINE")}

        >
          <LightTooltip title="Horizontal Line">
            <span style={{ color: "black" }}><HorizontalRuleIcon /></span>
          </LightTooltip>
        </Dropdown.Item>



        <Dropdown.Item
          // title={"Block Quote"}
          className="drop_set"
          style={{ backgroundColor: "white", color: "black" }}
          onMouseDown={(e) => onChangeFn(e, "BLOCKQUOTE")}

        >
          <LightTooltip title="Block Quote">
            <span style={{ color: "black" }}><FormatQuoteIcon /></span>
          </LightTooltip>
        </Dropdown.Item>

      </Dropdown.Menu>
    </Dropdown>
  );
}

export {
  SelectItem,
  InlineSelect,
  InlineColor,
  InlineFontSize,
  InlineSize,
  InlineParagraph,
  InlineTextAlign,
  InlineSymbols,
  InlineClearFormating,
  TableMaker,
  AddfunctionControl,
};
