import React, { useState, useEffect } from 'react';
import '../FormComponents/CoAuthor_M.css'
import Switch from '@mui/material/Switch';
import { Container, Row, Col, Dropdown, Button as B_Button } from 'react-bootstrap';
import CachedIcon from '@mui/icons-material/Cached';
import { Button, IconButton,Typography,Modal,Accordion, AccordionSummary, AccordionDetails,} from '@material-ui/core';
import { Card, CardContent,Menu , Checkbox} from '@material-ui/core';
import { useSelector } from "react-redux";
import { Modal as B_Modal, Form } from "react-bootstrap";
import LanguageIcon from '@mui/icons-material/Language';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import AirplayIcon from '@mui/icons-material/Airplay';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import InputLabel from '@mui/material/InputLabel';
import FeedbackIcon from '@mui/icons-material/Feedback';
import MenuItem from '@mui/material/MenuItem';
import {  TextField} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import SelectDropdownMui from "../../ReusableComponents/SelectDropdownMui";
// import SelectDropdownMui from "./ReusableComponents/SelectDropdownMui";
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import MicIcon from '@mui/icons-material/Mic';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';
import SearchIcon from '@mui/icons-material/Search';    
import citationService from '../../../services/citationService';
import { v4 as uuidv4 } from 'uuid';
import { CoAuthor_CitationCard } from '../CitationCard';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import Loader from '../../../utils/loader';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';



const CustomTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'blue',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);


export const Coauthor_Citationcomponent =  (props)=>{


  let suggestionArray = [];

    const [citations, setCitations] = useState([]);  
    const [composeState, setComposestate] = useState(false)
    const [createCitationState, setCreateCitationState] = useState(true)
    const [creatClassState, setCreateClassstate] = useState(false)
    const [sourceitem, setSourceitem] = useState('Webpage')
    const [anchorEl, setAnchorEl] = useState(null);
    const [searchInput, setSearchInput] = useState("");
    const [websiteData, setWebsiteData] = useState(null);
    const [isCopied, setIsCopied] = useState(false);
    const [checkEdit, setCheckEdit] = useState(false)
    const [citationID, setCitationID] = useState('')
    const [publicationYearError, setPublicationYearError] = useState('');
    const [accessYearError, setAccessYearError] = useState('');
    const [publicationDayError, setPublicationDayError] = useState('');
    const [accessDayError, setAccessDayError] = useState('');
    const [selectedHeading, setSelectedHeading] = useState('Webpage')
    const [loading, setLoading] = useState(false);


    const [publicationYear, setPublicationYear] = useState('');
    const [publicationMonth, setPublicationMonth] = useState('');
    const [publicationDay, setPublicationDay] = useState('');

    const [accessYear, setAccessYear] = useState('');
    const [accessMonth, setAccessMonth] = useState('');
    const [accessDay, setAccessDay] = useState('');
    const userDetails = useSelector((user) => user)
    const userID = props.trial ? props.userId : userDetails.user.currentUser._id

    
    const [sourceList, setsourceList] = useState([
      {
        "value": "Webpage",
        "name": "Webpage"
      },
      {
        "value": "Journal Article",
        "name": "Journal Article"
      },
      {
        "value": "Book",
        "name": "Book"
      }
    ]);
  
    const [professionList, setProfessionList] = useState([
  
      {
        "value": "AMA 10th edition",
        "name": "AMA 10th edition"
      },
      {
        "value": "American Chemical Society",
        "name": "American Chemical Society"
      },
      {
        "value": "APA 11th edition",
        "name": "APA 11th edition"
      },
      {
        "value": "APA 6th edition",
        "name": "APA 6th edition"
      },
      {
        "value": "APA 7th edition",
        "name": "APA 7th edition"
      },
      {
        "value": "Chicago author",
        "name": "Chicago author"
      }
    ]);

    const [formData, setFormData] = useState({
        heading: "",
        title: "",
        description: "",
        websiteName: "",
        contributors: [],
        url: "",
        doi: "",
        journalName: "",
        edition: "",
        publisher: "",
        articleNumber: "",
        volumeNumber: "",
        issueNumber: "",
        page: "",
        libraryDatabase: "",
        ISBN: "",
        ISSN: "",
        publicationDate: {
          year: "",
          month: "",
          day: "",
        },
        accessDate: {
          year: "",
          month: "",
          day: "",
        },
        // sourceType: ''
      });
    
      const [contributors, setContributors] = useState([
        { id: uuidv4(), position: '', firstName: '', middleName: '', lastName: '', suffix: '' },
      ]);
    
      const [createcitation , setCreatecitation] = useState(false)
      
      const headings = ["Webpage", "Journal Article", "Book", "Book Chapter", "Video", "Online Dictionary Entry", "Online News Article", "Image", "Report", "Website"];
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
    
  
    const [citationStyle, setCitationStyle] = useState("")
    // const [homeCitationstate , sethomeCitationstate]=useState(false)
    const handleSource = (e) => {
      setSourceitem(e)
    }

    useEffect(()=>{
      setSearchInput('')
      setWebsiteData(null)
    },[sourceitem])
  
    const handleCreateCitation = () => {
      setCreateClassstate(true)
      setCreateCitationState(false)
    }
  
    const handleCompose = () => {
      setCreateClassstate(false)
      setComposestate(true)
      setCheckEdit(false)
  
    }

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleCloseAccordion = () => {
      setWebsiteData(null);
    };

    const handleCopyClick = () => {
      setIsCopied(true);
      toast.success("Text copied")
      setWebsiteData(null)
    };



    const currentYear = new Date().getFullYear();

    const calculateMaxDay = (month, year) => {
      switch (month) {
        case "January":
        case "March":
        case "May":
        case "July":
        case "August":
        case "October":
        case "December":
          return 31;
        case "April":
        case "June":
        case "September":
        case "November":
          return 30;
        case "February":
          // Check for leap year
          return (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)) ? 29 : 28;
        default:
          return 31; // Default to 31 if month is not recognized (for safety)
      }
    };

    const handleInputChange = (field, value) => {
      setFormData((prevData) => ({
        ...prevData,
        [field]: value,
      }));
      console.log(formData, "formdata");
    };

    const handlePublicationYearChange = (e) => {
      const inputYear = e.target.value;
    
      // Check if the input is not empty and is a valid 4-digit year with only numeric characters
      if (inputYear === '' || (parseInt(inputYear, 10) > 0 && /^\d{0,4}$/.test(inputYear) && parseInt(inputYear, 10) <= currentYear)) {
        setPublicationYearError('');
        setPublicationYear(inputYear);
    
        // Additional check for day validity based on the new year and month
        const inputMonth = publicationMonth;
        const inputDay = publicationDay;
        const maxDay = calculateMaxDay(inputMonth, inputYear);
    
        if (inputDay !== "" && (inputDay < 1 || inputDay > maxDay)) {
          setPublicationDayError('Invalid day');
        } else {
          setPublicationDayError('');
          handleInputChange('publicationDate', { year: inputYear, month: inputMonth, day: inputDay });
        }
      } else {
        setPublicationYearError('Invalid year');
      }
    };

    const handlePublicationMonthChange = (e) => {
      const inputMonth = e.target.value;
      setPublicationMonth(inputMonth);
    
      // Validate the day based on the new month and year
      const inputYear = publicationYear;
      const inputDay = publicationDay;
      const maxDay = calculateMaxDay(inputMonth, inputYear);
    
      if (inputDay !== "" && (inputDay < 1 || inputDay > maxDay)) {
        setPublicationDayError('Invalid day');
      } else {
        setPublicationDayError('');
        handleInputChange('publicationDate', { year: inputYear, month: inputMonth, day: inputDay });
      }
    };

    
    const handlePublicationDayChange = (e) => {
      const inputDay = parseInt(e.target.value, 10);
      const inputMonth = publicationMonth;
      const inputYear = publicationYear;
      
      let maxDay = 31; // Default maximum days
      
      // Update maxDay based on the month
      if (inputMonth === "April" || inputMonth === "June" || inputMonth === "September" || inputMonth === "November") {
        maxDay = 30;
      } else if (inputMonth === "February") {
        // Check for leap year
        maxDay = (inputYear % 4 === 0 && (inputYear % 100 !== 0 || inputYear % 400 === 0)) ? 29 : 28;
      }
      
      // Check if the input is not empty and within the valid range
      if (inputDay === "" || inputDay < 1 || inputDay > maxDay) {
        setPublicationDayError('Invalid day');
      } else {
        setPublicationDayError('');
        setPublicationDay(inputDay);
        handleInputChange('publicationDate', { year: inputYear, month: inputMonth, day: inputDay });
      }
    };
    
    
    const handleAccessYearChange = (e) => {
      const inputYear = e.target.value;
    
      // Check if the input is not empty and is a valid 4-digit year with only numeric characters
      if (inputYear === '' || (parseInt(inputYear, 10) > 0 && /^\d{0,4}$/.test(inputYear) && parseInt(inputYear, 10) <= currentYear)) {
        setAccessYearError('');
        setAccessYear(inputYear);
    
        // Additional check for day validity based on the new year and month
        const inputMonth = accessMonth;
        const inputDay = accessDay;
        const maxDay = calculateMaxDay(inputMonth, inputYear);
    
        if (inputDay !== "" && (inputDay < 1 || inputDay > maxDay)) {
          setAccessDayError('Invalid day');
        } else {
          setAccessDayError('');
          handleInputChange('accessDate', { year: inputYear, month: inputMonth, day: inputDay });
        }
      } else {
        setAccessYearError('Invalid year');
      }
    };


    const handleAccessMonthChange = (e) => {
      const inputMonth = e.target.value;
      setAccessMonth(inputMonth);
    
      // Validate the day based on the new month and year
      const inputYear = accessYear;
      const inputDay = accessDay;
      const maxDay = calculateMaxDay(inputMonth, inputYear);
    
      if (inputDay !== "" && (inputDay < 1 || inputDay > maxDay)) {
        setAccessDayError('Invalid day');
      } else {
        setAccessDayError('');
        handleInputChange('accessDate', { year: inputYear, month: inputMonth, day: inputDay });
      }
    };


    const handleAccessDayChange = (e) => {
      const inputDay = parseInt(e.target.value, 10);
      const inputMonth = accessMonth;
      const inputYear = accessYear;
  
      let maxDay = 31;
  
      if (inputMonth === "April" || inputMonth === "June" || inputMonth === "September" || inputMonth === "November") {
        maxDay = 30;
      } else if (inputMonth === "February") {
        maxDay = (inputYear % 4 === 0 && (inputYear % 100 !== 0 || inputYear % 400 === 0)) ? 29 : 28;
      }
  
      if (inputDay === "" || inputDay < 1 || inputDay > maxDay) {
        setAccessDayError('Invalid day');
      } else {
        setAccessDayError('');
        setAccessDay(inputDay);
        handleInputChange('accessDate', { year: inputYear, month: inputMonth, day: inputDay });
      }
    };

    // const isValidURL = (url) => {
    //   try {
    //     // Create a new URL object
    //     const parsedUrl = new URL(url);
    
    //     // Extract the hostname
    //     const hostname = parsedUrl.hostname;
    
    //     // Check if the hostname ends with a valid top-level domain
    //     const validUrlPattern = new RegExp('\\.(com|io|org|net|gov|edu|co)$', 'i');
    //     return validUrlPattern.test(hostname);
    //   } catch (e) {
    //     // If URL constructor throws an error, it's an invalid URL
    //     return false;
    //   }
    // };

    const isValidURL = (url) => {
      try {
        // If the URL doesn't start with a protocol, add 'https://'
        if (!/^https?:\/\//i.test(url)) {
          url = 'https://' + url;
        }
    
        // Create a new URL object
        const parsedUrl = new URL(url);
    
        // Extract the hostname
        const hostname = parsedUrl.hostname;
    
        // Check if the hostname ends with a valid top-level domain
        const validUrlPattern = new RegExp('\\.(com|io|org|net|gov|edu|co)$', 'i');
        return validUrlPattern.test(hostname);
      } catch (e) {
        // If URL constructor throws an error, it's an invalid URL
        return false;
      }
    };
   
    const validateInput = (sourceitem, searchInput) => {
      if (sourceitem === "Webpage" && !isValidURL(searchInput)) {
        toast.warning("Please enter a valid URL");
        return false;
      }
   
      // Add more validation checks for other tabs if needed
   
      return true;
    };

    

    const handleCiteButtonClick = async () => {
      if (!searchInput.trim()) {
        toast.warn("Search bar is empty");
        return;
      }
      if (validateInput(sourceitem, searchInput)) {
        setLoading(true)
        try {
          let response;
          let userId = userID
          let isCoAuthor = true
          if (sourceitem === 'Webpage') {
            response = await citationService.getCheckCitationWebpage(searchInput, citationStyle, sourceitem,userId,isCoAuthor);
            
            if (response.status === 200) {
            setLoading(false)
            console.log("Backend Response:", response);
            let responseOutput = response?.data?.output;
            const parsedData = JSON.parse(responseOutput[0]);
            suggestionArray = parsedData.replacements?.corrections;
            console.log("changedJSon_", suggestionArray, parsedData);
            setWebsiteData(suggestionArray)
            }
          } 
          else if (sourceitem === 'Journal Article') {
            response = await citationService.getCheckCitationJournalArticle(searchInput, citationStyle, sourceitem,userId,isCoAuthor);
            
            if (response.status === 200) {
            setLoading(false)
            console.log("Backend Response:", response);
            let responseOutput = response?.data?.output;
            const parsedData = JSON.parse(responseOutput[0]);
            suggestionArray = parsedData.replacements?.corrections;
            console.log("changedJSon_", suggestionArray, parsedData);
            setWebsiteData(suggestionArray)
            }
          } 
          else {
            response = await citationService.getCheckCitationBook(searchInput, citationStyle, sourceitem,userId,isCoAuthor);

            if (response.status === 200) {
              setLoading(false)
              console.log("Backend Response:", response);
              let responseOutput = response?.data?.output;
              const parsedData = JSON.parse(responseOutput[0]);
              suggestionArray = parsedData.replacements?.corrections;
              console.log("changedJSon_", suggestionArray, parsedData);
              setWebsiteData(suggestionArray)
              }
          }
          
        } catch (error) {
          setLoading(false)
          toast.warn("Error fetching citation details");
          console.error("Error calling backend API:", error.message);
        }
    
        // setWebsiteData(suggestionArray)

      }
      
    };


    const handleSaveCitations2 = async () => {
      // Handle save logic here
      const email = sessionStorage.getItem("currentUserEmail")
      try {
        const response = await citationService.getAddCitation2(websiteData[0], email);
        console.log("Backend Response:", response);
        if (response.status == 200) {
          toast.success("Citation saved successfully")
          fetchCitationDetails()
          setWebsiteData(null)
        }
      } catch (error) {
        console.error("Error calling backend API:", error.message);
      }
  
      handleClose();
  
    };

    


  
    const handleCitationdStyle = (e) => {
      let value = e.target.value
      console.log("handleCitationdStyle", e, value)
      setCitationStyle(value)
    }

    const fetchCitationDetails = async () => {
      try {
        const email = sessionStorage.getItem("currentUserEmail");
        let data={email}
        const response = await citationService.getCitationDetails(data);
        if (response.status === 200) {
          const reversedCitations = response.citationDetails.reverse();
          setCitations(reversedCitations);
         
        } else {
          console.error("Error fetching citation details:", response.message);
        }
      } catch (error) {
        console.error("Error calling backend API:", error.message);
      }
    };
  
    useEffect(() => {
      fetchCitationDetails();
    }, []);

  
 
    
        const handleAddMember = () => {
        setContributors((prevContributors) => [
          ...prevContributors,
          { id: uuidv4(), position: '', firstName: '', middleName: '', lastName: '', suffix: '' },
        ]);
      };
    
      const handleContributorChange = (id, field, value) => {
        setContributors((prevContributors) =>
          prevContributors.map((contributor) =>
            contributor.id === id ? { ...contributor, [field]: value } : contributor
          )
        );
      };
    
      const handleDeleteMember = (id) => {
        setContributors((prevContributors) => prevContributors.filter((contributor) => contributor.id !== id));
      };
    
    
      const handleSaveCitations = async () => {

        if (publicationDayError === "Invalid day" || accessDayError === "Invalid day"){
          toast.error("Invalid date. Please provide correct date")
          return
        }

        const email = sessionStorage.getItem("currentUserEmail")
        // Include contributors data in the formData state
        const updatedFormData = {
          ...formData,
          email:email,
          contributors: contributors,
        };

        if (updatedFormData.title===""){
          toast.error("Please provide citation title.")
          return
        }
    
       
    
    
        // Handle save logic here using updatedFormData
        try {
          let sourceType = ""
          if(selectedHeading === "Webpage"){sourceType=0}
          else if (selectedHeading === "Journal Article"){sourceType=1}
          else if (selectedHeading === "Book"){sourceType=2}
          else{sourceType = selectedHeading}
          const response = await citationService.getAddCitation(updatedFormData, citationStyle, sourceType);
          console.log("Backend Response:", response);
    
          if (response.status == 200) {
            toast.success("Citation saved successfully")
            fetchCitationDetails()
            setFormData({
              heading: "",
              title: "",
              description: "",
              websiteName: "",
              contributors: [],
              doi: "",
              journalName: "",
              url: "",
              edition: "",
              publisher: "",
              articleNumber: "",
              volumeNumber: "",
              issueNumber: "",
              page: "",
              libraryDatabase: "",
              ISBN: "",
              ISSN: "",
              publicationDate: {
                year: "",
                month: "",
                day: "",
              },
              accessDate: {
                year: "",
                month: "",
                day: "",
              },
            })
            setContributors([
              { id: uuidv4(), position: '', firstName: '', middleName: '', lastName: '', suffix: '' },
            ])
            setPublicationYearError('')
            setAccessYearError('')
            setPublicationDayError('')
            setAccessDayError('')
            setSelectedHeading('Webpage');
            setComposestate(false)
            setCreateClassstate(true)
            setPublicationDay('')
            setPublicationMonth('')
            setPublicationYear('')
            setAccessDay('')
            setAccessMonth('')
            setAccessYear('')
          }
        } catch (error) {
          console.error("Error calling backend API:", error.message);
        }
      };


      const handleUpdateCitation = async () => {

        if (publicationDayError === "Invalid day" || accessDayError === "Invalid day"){
          toast.error("Invalid date. Please provide correct date")
          return
        }

        const updatedFormData = {
          ...formData,
          contributors : contributors
        }

        if (updatedFormData.title===""){
          toast.error("Please provide citation title.")
          return
        }
        try {
          // Call the service function to update the citation details
          const response = await citationService.updateCitationDetails(citationID, updatedFormData);
    
          // Check if the update was successful
          if (response.status === 200) {
            // Show a success toast
            toast.success('Citation details updated successfully');
            
            // Optionally, you can reset the form or perform other actions
            //setFormData(initialFormData); // Assuming you have an initialFormData state
    
            // Fetch updated citation details
            fetchCitationDetails();
    
            // Close the edit mode and show the citation list
            setFormData({
              heading: "",
              title: "",
              description: "",
              websiteName: "",
              contributors: [],
              doi: "",
              journalName: "",
              url: "",
              edition: "",
              publisher: "",
              articleNumber: "",
              volumeNumber: "",
              issueNumber: "",
              page: "",
              libraryDatabase: "",
              ISBN: "",
              ISSN: "",
              publicationDate: {
                year: "",
                month: "",
                day: "",
              },
              accessDate: {
                year: "",
                month: "",
                day: "",
              },
            })
            setContributors([
              { id: uuidv4(), position: '', firstName: '', middleName: '', lastName: '', suffix: '' },
            ])
            setSelectedHeading('Webpage');
            setCreateClassstate(true);
            setComposestate(false);
            setCheckEdit(false);
            setPublicationYearError('')
            setAccessYearError('')
            setPublicationDayError('')
            setAccessDayError('')
            setPublicationDay('')
            setPublicationMonth('')
            setPublicationYear('')
            setAccessDay('')
            setAccessMonth('')
            setAccessYear('')
          } else {
            // Show an error toast if the update fails
            toast.error('Failed to update citation details');
          }
        } catch (error) {
          // Handle errors, e.g., log to console or show an error toast
          console.error('Error updating citation details:', error.message);
          toast.error('Internal server error. Please try again.');
        }
      }
     
      const backeventhanndlrC = ()=>{
            setSelectedHeading('Webpage')
            setComposestate(false)
            setCreateClassstate(true)
            setCreatecitation(false)
            setFormData({
              heading: "",
              title: "",
              description: "",
              websiteName: "",
              contributors: [],
              doi: "",
              journalName: "",
              url: "",
              edition: "",
              publisher: "",
              articleNumber: "",
              volumeNumber: "",
              issueNumber: "",
              page: "",
              libraryDatabase: "",
              ISBN: "",
              ISSN: "",
              publicationDate: {
                year: "",
                month: "",
                day: "",
              },
              accessDate: {
                year: "",
                month: "",
                day: "",
              },
            })
            setContributors([
              { id: uuidv4(), position: '', firstName: '', middleName: '', lastName: '', suffix: '' },
            ])
            setPublicationYearError('')
            setAccessYearError('')
            setPublicationDayError('')
            setAccessDayError('')
            setPublicationDay('')
            setPublicationMonth('')
            setPublicationYear('')
            setAccessDay('')
            setAccessMonth('')
            setAccessYear('')
          }
          
    const backButton = () => {
        setCreateClassstate(false)
        setCreateCitationState(true)
        setSelectedHeading('Webpage')
        setPublicationYearError('')
        setAccessYearError('')
        setPublicationDayError('')
        setAccessDayError('')
        setPublicationDay('')
        setPublicationMonth('')
        setPublicationYear('')
        setAccessDay('')
        setAccessMonth('')
        setAccessYear('')


      }
  
  console.log(websiteData,"websiteData");
  
    return (
      <>

        <Loader loading={loading} />
        {/* 
          {homeCitationstate && 
          <>
          <CitationclassComponent/>
          </>
          } */}
  
        {createCitationState &&
  
          <div>
            {/* <div> <p style={{colour:'blue',marginLeft:"12px"}}  onClick={Backtohomecitation}>Back</p></div> */}
            {/* <Row
              style={{
                width: '438px',
                zIndex: 1000,
                height: '64px',
                borderRadius: '29px',
                border: '1px solid #9F9F9F',
                backgroundColor: '#FFFFFF',
                // paddingTop: '3px',
                // paddingLeft: '3px',
                // paddingRight: '3px',
                boxShadow: '3px 1px 8px 0px rgba(0,0,0,0.25)',
                marginLeft: 'auto',
                marginTop: '10px',
                marginRight: 'auto',
                position: "relative"
  
              }}
              className="citation-accordion"
            > */}
              {/* <Col xs={4}> */}
  
                <div className='mt-3 px-2' 
                >
                  <SelectDropdownMui
                    optionsList={professionList}
                    selectLabel={"Citation Style"}
                    onChange={(e) => handleCitationdStyle(e)}
                    value={citationStyle}
                  />
                </div>
              {/* </Col> */}
            {/* </Row> */}
  
            <div style={{ marginTop: "100px" }}>
              <div 
              style={{ borderRadius: "10px", backgroundColor: "#FCFCFC", color: "#1976D2", width: "134px", display: 'flex', justifyContent: "center", padding: "5px", margin: "auto", border: "1px solid #1976D2", cursor:"pointer" }}
                onClick={handleCreateCitation}>Create Citation</div>
            </div>
          </div>
        }
  
  
  
  {creatClassState &&
        <div>
          <div className='p-2'>
            <Button variant="contained" color="primary"
              onClick={backButton} startIcon={<KeyboardBackspaceIcon />} style={{ marginRight: '7px', whiteSpace: 'nowrap' }}>
              Back
            </Button>
          </div>
          {/* <div> <p style={{colour:'blue',marginLeft:"12px"}}  onClick={Backtohomecitation}>Back</p></div> */}
          <Row
            style={{
              width: '100%',
              zIndex: 1000,
              height: '64px',
              borderRadius: '5px',
              border: '1px solid #9F9F9F',
              backgroundColor: '#FFFFFF',
              // paddingTop: '3px',
              // paddingLeft: '3px',
              // paddingRight: '3px',
              boxShadow: '3px 1px 8px 0px rgba(0,0,0,0.25)',
              marginLeft: 'auto',
              marginTop: '10px',
              marginRight: 'auto',
              position: "relative",
              // border:"5px solid red"

            }}
            className="citation-accordion"
          >
            
            

              <div className='' style={{ width: "35%", fontSize: '12px' }}>
                <label style={{ marginLeft: '5px', fontSize : props.mobileView ? '8px' : '' }}>Citation Source</label>
                <SelectDropdownMui
                  optionsList={sourceList}
                  onChange={(e) => handleSource(e.target.value)}
                  value={sourceitem}
                  sx={{margin: '0px 10px', width: '80%'}}
                />
              </div>
          

            
              <div style={{ width: '1%', height: '100%', backgroundColor: "#A0A0A0", }}></div>
          

            <div style={{width:'45%'}}>
              <FormControl style = {{width:'80%'}}>
                <TextField
                  id="standard-search"
                  label={sourceitem==="Webpage" ? "Enter URL":"Enter Title"}
                  type="search"
                  variant="standard"
                  value={searchInput}
                  onChange={(e) => {
                    setSearchInput(e.target.value)
                    if (!e.target.value.trim()) {
                      handleCloseAccordion()
                    }
                  }}
                  sx={{ margin:'0px 10px', width: '80%',fontSize : props.mobileView ? '8px' : ''}}
                />
                <span></span>
              </FormControl>
              </div>
           

            <div style={{width:'15%',fontSize : props.mobileView ? '8px' : '',marginLeft:"-8%"}}>
              <B_Button  onClick={handleCiteButtonClick} style={{ marginTop: '10px', color: 'white', marginRight: '10px', width:props.mobileView ? "50px" : "60px", height:props.mobileView ? "35px" : '44.84px', borderRadius: "15px", backgroundColor: '#3295f7' }}  >
                Cite
              </B_Button>
              </div>

            

            {websiteData && (
              <Accordion style={{ width: '97%', zIndex: 1001 }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography><b>{websiteData[0].title}</b></Typography>

                </AccordionSummary>
                <AccordionDetails style={{ position: 'relative' }}>

                  <Typography><p>{websiteData[0].sourceType} | {websiteData[0].publicationDate.year}</p></Typography>


                  {/* Save Icon */}
                  <div style={{ display: 'flex' }}>
                  <CustomTooltip title="Save Citation">
                    <IconButton
                      style={{ position: 'absolute', bottom: '10px', right: '90px', color: "green" }}
                      onClick={handleSaveCitations2}
                      title={"Save Citation"}
                    >
                      <AssignmentTurnedInIcon />
                    </IconButton>
                    </CustomTooltip>

                    <CopyToClipboard
                    sx={{fontSize:props.mobileView ? '8px' : ''}}
                      // text={`${websiteData[0].title} - ${websiteData[0].sourceType==="Journal Article" ? websiteData[0].journalName : websiteData[0].description}\n${websiteData[0].sourceType} | ${websiteData[0].publicationDate.year}`}
                      text={`${websiteData[0].title} - ${websiteData[0].sourceType==="Journal Article" ? websiteData[0].journalName : websiteData[0].sourceType==="Webpage" ? websiteData[0].description : ""} \n ${websiteData[0].sourceType === "Book" ? `Edition - ${websiteData[0].edition}\n` : ""} ${websiteData[0].sourceType === "Book" ? `Publisher: ${websiteData[0].publisher} \n` : ""} ${websiteData[0].sourceType} | ${websiteData[0].publicationDate.year}\n ${(websiteData[0].sourceType === "Book" || (websiteData[0].sourceType === "Journal Article" && websiteData[0].doi !== "")) ? `DOI - ${websiteData[0].doi} \n` : ""}${websiteData[0].url}`}
                      onCopy={handleCopyClick}
                    >
                      <CustomTooltip title="Copy">
                      <IconButton
                        style={{ position: 'absolute', bottom: '10px', right: '50px',color: 'blue', }}
                        title={"Copy"}
                      >
                        <ContentCopyIcon  />

                      </IconButton>
                      </CustomTooltip>
                    </CopyToClipboard>
                    <CustomTooltip title="Close">
                    <IconButton
                      style={{ position: 'absolute', bottom: '10px', right: '10px', color: "red" }}
                      onClick={handleCloseAccordion}
                      title={"Close"}
                    >
                      <CloseIcon  />
                    </IconButton>
                    </CustomTooltip>
                  </div>

                </AccordionDetails>
              </Accordion>
            )}
          </Row>

          <div style={{ marginTop: "100px" }}>
            <div 
            style={{ borderRadius: "10px", backgroundColor: "#FCFCFC", color: "#1976D2", width: "134px", display: 'flex', justifyContent: "center", padding: "5px", margin: "auto", border: "1px solid #1976D2", cursor: "pointer"}} 
            onClick={() => { handleCompose() }}> + Compose</div>
            <div><p style={{ width:props.mobileView ? "200px" : "310px", margin: "auto", textAlign: "center" }}>Cite a Wide range of source and export them in your favorite styles!</p></div>
          </div>

        


       
          <Container style={{ marginTop: "35px" }}>
              {citations.map((citation, index) => (
                <CoAuthor_CitationCard 
                  citation={citation} 
                  index={index} 
                  fetchCitationDetails={fetchCitationDetails} 
                  setComposestate={setComposestate} 
                  setCreateClassstate={setCreateClassstate} 
                  setFormData={setFormData} 
                  setCheckEdit={setCheckEdit}
                  setContributors={setContributors}
                  setCitationID = {setCitationID}
                  handleInsertClick = {props.handleInsertClick}
                  setSelectedHeading = {setSelectedHeading}
                /> 
              ))}
            </Container>

          

        </div>

      }




    {composeState &&   
            <>
           <div>
      <div className='p-2'>
        <Button variant="contained" color="primary" onClick={backeventhanndlrC} startIcon={<KeyboardBackspaceIcon />} style={{ marginRight: '7px', whiteSpace: 'nowrap' }}>
        Back
      </Button>
      </div>

            {checkEdit ?
            <div style={{display:'flex',justifyContent:'center',marginTop:"10px"}}>
              <h4>{formData.heading}</h4>
            </div>
              :
            <div style={{display:'flex',justifyContent:'center',marginTop:"10px"}}>
            <Dropdown >
                          <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
                            {formData.heading  || selectedHeading}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {headings.map((item) => (
                              <Dropdown.Item
                                key={item}
                                onClick={() => {
                                  setSelectedHeading(item)
                                  handleInputChange("heading", item)
                                }}
                              >
                                {item}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                </Dropdown>
            </div>
          }
    <Form>
            {/* Text Fields */}
            {(selectedHeading === 'Book Chapter' || selectedHeading === "Webpage" || selectedHeading === 'Image' || selectedHeading === 'Video' || selectedHeading === "Journal Article" || selectedHeading === "Book" || selectedHeading === 'Online Dictionary Entry' || selectedHeading === 'Online News Article' || selectedHeading === 'Report' || selectedHeading === 'Website') && (
            <Form.Group controlId="formTitle" className="row justify-content-between align-items-center mt-4 custom-form-group-width">
              <Form.Label className="col-md-3 pt-2 ">Title</Form.Label>
              <Form.Control
                className='col-md-9'
                type="text"
                value={formData.title}
                onChange={(e) => handleInputChange("title", e.target.value)}
              />
            </Form.Group>
            )} 



            {(selectedHeading === "Journal Article") && (
            <Form.Group controlId="formJournalName" className="row justify-content-between align-items-center mt-2 custom-form-group-width">
              <Form.Label className="col-md-3 pt-2 ">Journal Name</Form.Label>
              <Form.Control
                className='col-md-9'
                type="text"
                value={formData.journalName}
                onChange={(e) => handleInputChange("journalName", e.target.value)}
              />
            </Form.Group>
            )} 


          {(selectedHeading === "Webpage" || selectedHeading === "Image" ) && (
            <Form.Group controlId="formDescription" className="row justify-content-between align-items-center mt-2 custom-form-group-width">
              <Form.Label className="col-md-3 pt-2">Description</Form.Label>
              <Form.Control
                className='col-md-9'
                type="text"
                // placeholder="Description"
                value={formData.description}
                onChange={(e) =>
                  handleInputChange("description", e.target.value)
                }
              />
            </Form.Group>
              )} 


          {(selectedHeading === "Webpage" || selectedHeading === 'Image' || selectedHeading === 'Video' || selectedHeading === 'Online Dictionary Entry' || selectedHeading === 'Online News Article' || selectedHeading === 'Report') && (
            <Form.Group controlId="formWebsiteName" className="row justify-content-between align-items-center mt-2 custom-form-group-width">
              <Form.Label className="col-md-3 pt-2">Website Name</Form.Label>
              <Form.Control
                className='col-md-9'
                type="text"
                // placeholder="Website Name"
                value={formData.websiteName}
                onChange={(e) =>
                  handleInputChange("websiteName", e.target.value)
                }
              />
            </Form.Group>
          )}

            {/* Contributors Dropdown */}

            {(selectedHeading === "Webpage" || selectedHeading === 'Image' || selectedHeading === 'Video' || selectedHeading === "Journal Article" || selectedHeading === "Book" || selectedHeading === 'Book Chapter' || selectedHeading === 'Online Dictionary Entry' || selectedHeading === 'Online News Article' || selectedHeading === 'Report') && (
            <Form.Group controlId="formContributors">
              <Accordion style={{ width: '84%', margin:"auto" , marginTop:"15px"}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Contributors</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ flexDirection: 'column' }}>
                  {contributors.map((contributor, index) => (
                    <div key={index} style={{ width: '100%', marginBottom: '10px' }}>
                      <ContributorForm
                        contributor={contributor}
                        handleDelete={() => handleDeleteMember(contributor.id)}
                        handleInputChange={(field, value) =>
                          handleContributorChange(contributor.id, field, value)
                        }
                      />
                    </div>
                  ))}
                  <div style={{ width: '100%', marginBottom: '10px' }}>
                    <B_Button variant="primary" onClick={handleAddMember}>Add Member +</B_Button>
                  </div>
                </AccordionDetails>
              </Accordion>

            </Form.Group>
            )}

            {(selectedHeading === "Journal Article" || selectedHeading === "Book" || selectedHeading === 'Book Chapter') && (
            <Form.Group controlId="formVolumeNumber" className="row justify-content-between align-items-center mt-2 custom-form-group-width">
              <Form.Label className="col-md-3 pt-2 ">Volume Number</Form.Label>
              <Form.Control
                className='col-md-9'
                type="text"
                value={formData.volumeNumber}
                onChange={(e) => handleInputChange("volumeNumber", e.target.value)}
              />
            </Form.Group>
            )} 


          {(selectedHeading === "Journal Article") && (
            <Form.Group controlId="formIssueNumber" className="row justify-content-between align-items-center mt-2 custom-form-group-width">
              <Form.Label className="col-md-3 pt-2 ">Issue Number</Form.Label>
              <Form.Control
                className='col-md-9'
                type="text"
                value={formData.issueNumber}
                onChange={(e) => handleInputChange("issueNumber", e.target.value)}
              />
            </Form.Group>
            )} 


             


            {/* Publication Date */}
            {(selectedHeading === "Webpage" || selectedHeading === 'Image' || selectedHeading === 'Video' || selectedHeading
              === "Journal Article" || selectedHeading === "Book" || selectedHeading === 'Book Chapter' || selectedHeading === 'Online Dictionary Entry' || selectedHeading === 'Online News Article' || selectedHeading === 'Report' || selectedHeading === 'Website') && (
            <Form.Group controlId="formPublicationDate" className="row justify-content-evenly align-items-center mt-2 custom-form-group-width-date">
              <Form.Label className="col-md-3 pt-2">Publication Date</Form.Label>
              
              <div className="col-md-3">
                <Form.Control
                  type="text"
                  placeholder="Year"
                  
                  value={publicationYear || formData.publicationDate.year}
                  onChange={handlePublicationYearChange}
                />
                {publicationYearError && <div style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{publicationYearError}</div>}
              </div>

              <Form.Control
                className="col-md-3"
                as="select"
                placeholder="Month"
                
                value={publicationMonth || formData.publicationDate.month}
                onChange={handlePublicationMonthChange}
              >
                <option value="" disabled>
                  Month
                </option>
                {months.map((month) => (
                  <option key={month} value={month}>
                    {month}
                  </option>
                ))}
              </Form.Control>
              <div className="col-md-3">
                <Form.Control
                  type="number"
                  placeholder="Day"
                  value={publicationDay || formData.publicationDate.day}
                  onChange={handlePublicationDayChange}
                />
                {publicationDayError && <div style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{publicationDayError}</div>}
              </div>
            </Form.Group>
            )}
          

            {/* Access Date */}
            {(selectedHeading === "Webpage" || selectedHeading === 'Video' || selectedHeading === 'Online Dictionary Entry' || selectedHeading === 'Online News Article' || selectedHeading === 'Report' || selectedHeading === 'Website') && (
            <Form.Group controlId="formAccessDate" className="row justify-content-evenly align-items-center mt-2 custom-form-group-width-date">
              <Form.Label className="col-md-3 pt-2">Access Date</Form.Label>
              {/* Similar structure as Publication Date */}
              
              <div className="col-md-3">
                <Form.Control
                  type="text"
                  placeholder="Year"
                  
                  value={accessYear || formData.accessDate.year}
                  onChange={handleAccessYearChange}
                />
                {accessYearError && <div style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{accessYearError}</div>}
              </div>
              <Form.Control

                className="col-md-3"
                as="select"
                placeholder="Month"
                
                value={accessMonth || formData.accessDate.month}
                onChange={handleAccessMonthChange}
              >
                <option value="" disabled>
                  Month
                </option>
                {months.map((month) => (
                  <option key={month} value={month}>
                    {month}
                  </option>
                ))}
              </Form.Control>
              <div className="col-md-3">
                <Form.Control
                  type="number"
                  placeholder="Day"
                  value={accessDay || formData.accessDate.day}
                  onChange={handleAccessDayChange}
                />
                {accessDayError && <div style={{ color: 'red', fontSize: '10px', marginTop: '5px' }}>{accessDayError}</div>}
              </div>
            </Form.Group>
            )}


            {(selectedHeading === "Journal Article") && (
            <Form.Group controlId="formLibraryDatabase" className="row justify-content-between align-items-center mt-2 custom-form-group-width">
              <Form.Label className="col-md-3 pt-2 ">Library Database</Form.Label>
              <Form.Control
                className='col-md-9'
                type="text"
                value={formData.libraryDatabase}
                onChange={(e) => handleInputChange("libraryDatabase", e.target.value)}
              />
            </Form.Group>
            )} 

            {(selectedHeading === "Journal Article" || selectedHeading === "Book Chapter") && (
            <Form.Group controlId="formPage" className="row justify-content-between align-items-center mt-2 custom-form-group-width">
              <Form.Label className="col-md-3 pt-2 ">Page</Form.Label>
              <Form.Control
                className='col-md-9'
                type="text"
                value={formData.page}
                onChange={(e) => handleInputChange("page", e.target.value)}
              />
            </Form.Group>
            )} 


            {(selectedHeading === "Book" || selectedHeading === 'Book Chapter') && (
            <Form.Group controlId="formEdition" className="row justify-content-between align-items-center mt-2 custom-form-group-width">
              <Form.Label className="col-md-3 pt-2 ">Edition</Form.Label>
              <Form.Control
                className='col-md-9'
                type="text"
                value={formData.edition}
                onChange={(e) => handleInputChange("edition", e.target.value)}
              />
            </Form.Group>
            )} 

            {(selectedHeading === "Book" || selectedHeading === 'Book Chapter' || selectedHeading === 'Online News Article' || selectedHeading === 'Report' || selectedHeading === 'Website') && (
            <Form.Group controlId="formPublisher" className="row justify-content-between align-items-center mt-2 custom-form-group-width">
              <Form.Label className="col-md-3 pt-2 ">Publisher</Form.Label>
              <Form.Control
                className='col-md-9'
                type="text"
                value={formData.publisher}
                onChange={(e) => handleInputChange("publisher", e.target.value)}
              />
            </Form.Group>
            )} 

            {(selectedHeading === "Journal Article" || selectedHeading === "Book" || selectedHeading === "Book Chapter") && (
            <Form.Group controlId="formDOI" className="row justify-content-between align-items-center mt-2 custom-form-group-width">
              <Form.Label className="col-md-3 pt-2 ">DOI</Form.Label>
              <Form.Control
                className='col-md-9'
                type="text"
                value={formData.doi}
                onChange={(e) => handleInputChange("doi", e.target.value)}
              />
            </Form.Group>
            )} 

            {/* URL */}
            {(selectedHeading === "Webpage" || selectedHeading === 'Image' || selectedHeading === 'Video' || selectedHeading === "Journal Article" || selectedHeading === "Book" || selectedHeading === 'Book Chapter' || selectedHeading === 'Online Dictionary Entry' || selectedHeading === 'Online News Article' || selectedHeading === 'Report' || selectedHeading === 'Website') && (
            <Form.Group controlId="formURL" className="row justify-content-between align-items-center mt-2 custom-form-group-width">
              <Form.Label className="col-md-3 pt-2">URL</Form.Label>
              <Form.Control
                className='col-md-9'
                type="text"
                value={formData.url}
                onChange={(e) => handleInputChange("url", e.target.value)}
              />
            </Form.Group>
            )}

          {(selectedHeading === "Journal Article") && (
            <Form.Group controlId="formArticleNumber" className="row justify-content-between align-items-center mt-2 custom-form-group-width">
              <Form.Label className="col-md-3 pt-2 ">Article number or eLocater</Form.Label>
              <Form.Control
                className='col-md-9'
                type="text"
                value={formData.articleNumber}
                onChange={(e) => handleInputChange("articleNumber", e.target.value)}
              />
            </Form.Group>
            )} 

          {(selectedHeading === "Book" || selectedHeading === 'Book Chapter') && (
            <Form.Group controlId="formISBN" className="row justify-content-between align-items-center mt-2 custom-form-group-width">
              <Form.Label className="col-md-3 pt-2 ">ISBN</Form.Label>
              <Form.Control
                className='col-md-9'
                type="text"
                value={formData.ISBN}
                onChange={(e) => handleInputChange("ISBN", e.target.value)}
              />
            </Form.Group>
            )} 

          {(selectedHeading === "Book" || selectedHeading === 'Book Chapter') && (
            <Form.Group controlId="formISSN" className="row justify-content-between align-items-center mt-2 custom-form-group-width">
              <Form.Label className="col-md-3 pt-2 ">ISSN</Form.Label>
              <Form.Control
                className='col-md-9'
                type="text"
                value={formData.ISSN}
                onChange={(e) => handleInputChange("ISSN", e.target.value)}
              />
            </Form.Group>
            )} 


          </Form>

         <div style={{ marginTop:"35px", marginBottom:'35px', display:'flex',justifyContent:'center'}}>
          {checkEdit?
            <B_Button onClick = {handleUpdateCitation} variant="primary">
                Update Citations
            </B_Button>:
            <B_Button variant="primary" onClick={handleSaveCitations}>
                Save Citations
            </B_Button>
          }
         
         </div>
     </div>

            </>
            }
        </>
      )
    }

    const ContributorForm = ({ contributor, handleDelete, handleInputChange }) => {
    return (
      <>
        <Form>
          <Row>
            {/* Position */}
            <Col md={4}>
              <Form.Group controlId={`position_${contributor.id}`}>
                <Form.Label style={{ fontSize: '1rem' }}>Position</Form.Label>
                <Form.Control
                  type="text"
                  value={contributor.position}
                  onChange={(e) => handleInputChange('position', e.target.value)}
                />
              </Form.Group>
            </Col>
  
            {/* First Name */}
            <Col md={4}>
              <Form.Group controlId={`firstName_${contributor.id}`}>
                <Form.Label style={{ fontSize: '1rem' }}>First Name</Form.Label>
                <Form.Control
                  type="text"
                  value={contributor.firstName}
                  onChange={(e) => handleInputChange('firstName', e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
  
            <Col md={4}>
              <Form.Group controlId={`middleName_${contributor.id}`}>
                <Form.Label style={{ fontSize: '1rem' }}>Middle </Form.Label>
                <Form.Control
                  type="text"
                  value={contributor.middleName}
                  onChange={(e) => handleInputChange('middleName', e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
  
            <Col md={4}>
              <Form.Group controlId={`lastName_${contributor.id}`}>
                <Form.Label style={{ fontSize: '1rem' }}>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  value={contributor.lastName}
                  onChange={(e) => handleInputChange('lastName', e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
  
            <Col md={4}>
              <Form.Group controlId={`suffix_${contributor.id}`}>
                <Form.Label style={{ fontSize: '1rem' }}>Suffix</Form.Label>
                <Form.Control
                  type="text"
                  value={contributor.suffix}
                  onChange={(e) => handleInputChange('suffix', e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
  
            {/* Middle Name */}
            {/* ... (similar structure for other fields) */}
  
            {/* Delete Icon */}
            <Col md={1}>
              <IconButton onClick={handleDelete} style={{ marginTop: '15px' }}>
                <DeleteIcon style={{ marginTop: '7px' }} />
              </IconButton>
            </Col>
          </Row>
        </Form>
      </>
    );
  }