import React from 'react';
import { Button } from '@mui/material';
//import not_found_Img from "../FormComponents/"
const CoAuther_PlagiarismChecker_Blank = (props) => {



    const handleCloseModal = () => {
        props.close(); // Call the function passed from the parent to close the modal
      };
    




    return (
        <div className="blank_container"
            style={{
                margin: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center", /* Center vertically */
                height: "70%",
                flexDirection: "column",
                position: "relative"
            }}
        >
            <div className="head_blank"
                style={{
                    textAlign: "center",
                    position: "absolute",
                    top: "10%",
                    width: "100%"
                }} >
                <h2>Project is blank</h2>
            </div>

            <div className='blank_para' style={{
                textAlign: "center",
                color: "grey",
                position: "absolute",
                top: "20%",
                width: "100%",
            }}><p>Write something and then check for plagiarism</p></div>




            <div className='blank_btn'
                style={{
                    display: "flex",
                    justifyContent: "center",
                     position: "absolute",
                     top:"30%",
                     right: "40px"
            }}>
            <Button onClick={handleCloseModal} variant="contained" aria-label="outlined primary button group">
                Got It
                </Button>
        </div>
        </div >












    );
}

export default CoAuther_PlagiarismChecker_Blank;
