import React from "react";
import AdminSideNavBar from "../AdminSideNavBar";
import NetworkDetector from "../HigherOrderComponents/NetworkDetector";
// import NumberWidget from "../NumberWidget";
// import Invoice from "../Invoice";
import queryString from "query-string";
import PromptsGenerator from "../PromptsGenerator/PromptsGenerator";
const PromptsGeneratorPage= (props) => {
  if (props.location.search) {
    let values = queryString.parse(props.location.search);
    sessionStorage.setItem("token", values.token);
  }
  return (
    <AdminSideNavBar>
      {/* <PromptsGenerator /> */}
      <PromptsGenerator/>
    </AdminSideNavBar>
  );
};

export default NetworkDetector(PromptsGeneratorPage);
