import React from "react";
import SideNavBar from "../SideNavBar";
import Myteam from "../teamMembers";
import ChatContextProvider from "../../contexts/ChatContext";

const TeamMembersPage = () => {
  return (
    <SideNavBar>
      <ChatContextProvider>
      <Myteam />
      </ChatContextProvider>
    </SideNavBar>
  );
};

export default TeamMembersPage;
