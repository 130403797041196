import React, { useEffect, useState } from "react";
import BlogCard from "./Cards/BlogCard";
import AdminPostTabs from "./AdminPostTabs";
import Loader from "../utils/loader";
import { withRouter } from "react-router-dom";
import HeadingB from "./Cards/HeadingB";
import {
  deletePermanentlyBlog
} from "../services/adminServices";
import { getCurrentUser } from "../services/authService";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import swal from "sweetalert";
import { toast } from "react-toastify";
import PaginatioN from "react-js-pagination";
import SEOBlogCard from "./Cards/SEOBlogCard";
import { acceptPendingBlogBySubAdmin, getAllOrgBlogsSubAdmin, getBlogByIdSubAdmin, getPendingOrgBlogs, publishBlogSubAdmin, rejectPendingBlogBySubAdmin, searchBlogSubAdmin, searchPendingBlogsSubAdmin, unpublishBlogSubAdmin } from "../services/subAdminServices";
import { useSelector } from "react-redux";

function SubAdminBlogs({ history }) {
  const [loading, setLoading] = useState(false);
  const [tabValue, setSwitchTabValue] = useState(0);
  const [pendingBlogs, setPendingBlogs] = React.useState([]);
  const [filterPending, setFilterPending] = React.useState([]);
  const [isSubAdmin, setIsSubAdmin] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [myblog, setmyBlog] = useState(false);
  const [rejectedblog, setRejectedblog] = useState({});
  const [reason, setReason] = useState("");
  const [blogValue, setblogValue] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [pageItems] = useState(10);
  const [search, setSearch] = useState("");
  const [hidePagination, setHidePagination] = useState(false);
  const userDetails = useSelector((user) => user)
  console.log("userDetails", userDetails)
  const userId = userDetails.user.currentUser._id
  const orgId = userDetails.user.currentUser.organizationId

  useEffect(() => {
    setLoading(true);
    initialCall();
    if (tabValue === 0) {
      setmyBlog(false);
      setblogValue("all");
      fetchPendingBlogs();
    } else {
      setmyBlog(true);
      setblogValue("my");
      myBlogs();
    }
  }, [tabValue, currentPage]);

  const initialCall = async () => {
    const user = await getCurrentUser();
    if (user.isSubAdmin) {
      setIsSubAdmin(true);
    }
  };

  const fetchPendingBlogs = async () => {
    let sendData = {
      userId: userId,
      orgId: orgId
    }
    console.log("sendData", sendData)
    let data = await getPendingOrgBlogs(sendData);
    setPendingBlogs(data.data);
    setFilterPending(data.data);
    setTotalItems(data.total);
    setLoading(false);
  };

  const myBlogs = async () => {
    let data = await getAllOrgBlogsSubAdmin(userId, orgId);
    console.log('myBlogs===>', data);
    setPendingBlogs(data.data);
    setFilterPending(data.data);
    setTotalItems(data.total);
    setLoading(false);
  };

  const viewBlog = (blog) => {
    history.push({
      pathname: `/blogpreview/${blog._id}`,
      state: { detail: blog, blogType: blogValue },
      data: { documentType: "blog" },
    });
  };

  const openTextEditor = () => {
    history.push({
      pathname: `/blogeditor`,
      // data: { documentType: "blog" },
    });
  };

  const approveBlog = async (data) => {
    swal({
      title: "Approve Blog",
      text: "Are you sure ? You want to approve this blog",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willApprove) => {
      if (willApprove) {
        console.log(data);
        setLoading(true);
        let blog = {
          BlogID: data._id,
          BlogName: data.documentName,
          userID: data.editor._id,
          subadminId: userId
        };
        let response = await acceptPendingBlogBySubAdmin(blog);
        if (response.status === 200) {
          fetchPendingBlogs();
          toast.success("Blog approved successfully.");
        } else {
          toast.error(response.message);
        }
      }
    });
  };

  const rejectBlog = async () => {
    if (!reason) {
      toast.warn("Please Enter Valid Reason");
    } else {
      setLoading(true);
      let blog = {
        BlogID: rejectedblog._id,
        BlogName: rejectedblog.documentName,
        userID: rejectedblog.editor._id,
        userEmail: rejectedblog.editor.email,
        Message: reason,
        subadminId: userId
      };
      let response = await rejectPendingBlogBySubAdmin(blog);
      if (response.status === 200) {
        fetchPendingBlogs();
        toast.success("Blog rejected successfully.");
      } else {
        toast.error(response.message);
      }
    }
    setOpen(false);
    setReason("");
  };

  // handle publish
  const handlePublish = async (data) => {
    swal({
      title: "Publish Blog",
      text: "Are you sure ? You want to publish this blog",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willPublish) => {
      if (willPublish) {
        let blog = await getBlogByIdSubAdmin(data._id, userId, orgId);
        console.log(blog);
        if (blog.status === 200) {
          if (blog.data.activeDoc === true) {
            let responseBlog = await publishBlogSubAdmin(data._id, userId, orgId);
            toast.success("Blog published!");
            console.log(responseBlog);
          }
          myBlogs();
        }
      }
    });
  };

  // handle unpublish
  const handleUnpublish = async (data) => {
    swal({
      title: "Unpublish Blog",
      text: "Are you sure ? You want to unpublish this blog",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willunPublish) => {
      if (willunPublish) {
        let blog = await getBlogByIdSubAdmin(data._id, userId, orgId);
        console.log(data);

        if (blog.status === 200) {
          if (blog.data.activeDoc === true) {
            let unpublishBlog = await unpublishBlogSubAdmin(data._id, userId, orgId);
            toast.success("Blog unpublished!");
            console.log(unpublishBlog);
          }
          myBlogs();
        } else {
          toast.error("Sommething went wrong");
        }
      }
    });
  };

  const switchTab = (value) => {
    setSwitchTabValue(value);
    setCurrentPage(1);
  };

  const switchDialog = (value, data) => {
    setOpen(value);
    setRejectedblog(data);
  };

  const onChangeSearch = async (e) => {
    setSearch(e.target.value);
    if (e.target.value === "") {
      setFilterPending(pendingBlogs);
      setHidePagination(false);
    }
  };

  const searchBlog = async () => {
    let word = search;
    word = word.trim();
    setSearch(word);
    if (word === "") {
      console.log("No Search");
      return;
    } else {
      setHidePagination(true);
      if (tabValue === 0) {
        let data = await searchPendingBlogsSubAdmin(userId, orgId, word);
        setFilterPending(data.data);
      } else {
        let data = await searchBlogSubAdmin(userId, orgId, word);
        setFilterPending(data.data);
      }
    }
  };


  const permanentDeleteBlog = async (blog) => {
    swal({
      title: "Permanent Deletion",
      text: "Do you want to permanently delete this blog ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (permanentDelete) => {
      if (permanentDelete) {
        setLoading(true);
        let data = {
          _id: blog._id,
        };
        let res = await deletePermanentlyBlog(data);
        if (res.status !== 200) {
          setLoading(false);
          toast.error("Blog Deletion Failed !");
        }
        else {
          toast.success("Blog deleted permanently!");
          setLoading(false)
          initialCall();
          myBlogs();
        }

      } else {
        swal("Your Blog is safe!");
      }
    });
  };

  const viewSEOBlog = (blog) => {
    history.push({
      pathname: `/blogeditor`,
      seo: { detail: blog, },
    });
  }

  return (
    <div>
      <Loader loading={loading} />
      <AdminPostTabs handleChangeTab={switchTab} openEditor={openTextEditor} search={search} onChangeSearch={onChangeSearch} searchBlog={searchBlog}  />
      <div className="row mt-3">
        <div className="col-md-4 input-group  ml-3">
          {/* {tabValue === 0 ? (
            <HeadingB className="alertHead" text="Manage Blogs" />
          ) : (
              <HeadingB className="alertHead" text="My Blogs" />
            )} */}
          <HeadingB className="alertHead" text="Organization Blogs" />
        </div>
        {/* <div className="col-md-5 input-group mb-3">
          <div style={{ float: "left", width: "80%" }}>
            <input
              type="text"
              name="searchQuery"
              className="form-control"
              placeholder="search blog"
              aria-label="User's Blog"
              aria-describedby="basic-addon2"
              value={search}
              onChange={onChangeSearch}
            />
          </div>
          <div className="input-group-append">
            <button className="btn btn-primary" type="button" onClick={searchBlog}>
              <i className="fa fa-search"></i> Search
            </button>
          </div>
        </div> */}
      </div>
      <hr />
      {/* Pending Blogs */}
      {filterPending.length > 0 ? (
        <>
          {filterPending.map((blog) => (
            tabValue == 0 ? (<BlogCard
              data={blog}
              mypost={myblog}
              isAdmin={isSubAdmin}
              dialogBox={switchDialog}
              approveBlog={approveBlog}
              showBlog={() => viewBlog(blog)}
              blogunpub={() => handleUnpublish(blog)}
              blogPub={() => handlePublish(blog)}
              permanentDelete={() => permanentDeleteBlog(blog)}
            // search={searchBlog}
            />) : (<SEOBlogCard
              data={blog}
              permanentDelete={() => permanentDeleteBlog(blog)}
              showBlog={() => viewBlog(blog)}
            />)
          ))}
          {totalItems > pageItems && hidePagination === false ? (
            <div className="mt-5" style={{ float: "right" }}>
              <PaginatioN
                activePage={currentPage}
                itemsCountPerPage={pageItems}
                totalItemsCount={totalItems}
                pageRangeDisplayed={3}
                onChange={(page) => setCurrentPage(page)}
                itemClass="page-item"
                linkClass="page-link"
              />
            </div>
          ) : (
            ""
          )}
        </>
      ) : (
        <h1>No Blogs to Display</h1>
      )}
      {/* Pending Blogs */}

      <div>
        <Dialog
          open={open}
          onClose={() => {
            setOpen(false);
            setReason("");
          }}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Reason For Rejection of a Blog
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please enter the reason for blog rejection
            </DialogContentText>
            <TextField
              id="filled-multiline-static"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              label="Reason"
              multiline
              rows={5}
              variant="outlined"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpen(false);
                setReason("");
              }}
              color="primary"
            >
              Cancel
            </Button>
            <Button onClick={rejectBlog} color="primary">
              Send
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
export default withRouter(SubAdminBlogs);
