import http from "./httpService";
import { apiUrl, herokuUrl } from "./config.json";
let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = `${apiUrl}/admin`;
} else {
  apiEndpoint = `${herokuUrl}/admin`;
}

export async function getUserInvoices() {
  const data = await http.get(`${apiEndpoint}/listPaymentByUser`);
 
  return data;
}
export async function getAllpaymentsForAdmin() {
  const data = await http.get(`${apiEndpoint}/listPayments`);

  return data;
}
export default {
  getUserInvoices,
  getAllpaymentsForAdmin 
};
