import React, { useState, useRef, useEffect, useMemo } from "react";
import JoditEditor, { Jodit } from "jodit-react";
import './joditStyle.css';

import imgage from '../../static/Group 745.png';

export default function JoditEditorComp({ editorHeight, editorContent, editor, className, onChange, isEdit = false, isMain = true, setEditorContentUpload}) {
    const [content, setContent] = useState(editorContent);
    const [selectedFile, setSelectedFile] = useState(null);

    const config = { 
        readonly: !isEdit ? true : false,
        branding: false,
        statusbar: false, // Set to false to hide the status bar
        height: editorHeight,
        toolbar: isEdit ? true : false,
        askBeforePasteHTML: false,
        toolbarButtonSize: "small",
        buttons: [
            
            'undo', 'redo', 'bold', 'italic', 'underline', 'strikethrough',
            '|',
            'superscript', 'subscript',
            '|',
            'ul', 'ol',
            '|',
            'indent', 'outdent',
            '|',
            'font', 'fontsize', 'brush', 'paragraph',
            '|',
            'image', 'table', 'link',
            '|',
            'align', 'lineHeight', 'hr', 'eraser', 'find', 'spellcheck',
            '|',
            'cut', 'selectall', 'copy', 'paste',
        ],
        toolbarAdaptive: false,
        uploader: {
            insertImageAsBase64URI: true, // Enable Base64 encoding
        },
        events: {
            afterInit(instance) {
                editor.current = instance;
                console.log('jodit instance', instance.selection, editor.current);
            },
        },
        controls: {
            paragraph: {
                list: Jodit.atom({
                    p: 'Paragraph',
                    h1: 'Heading 1',
                    h2: 'Heading 2',
                    h3: 'Heading 3',
                    h4: 'Heading 4',
                    blockquote: 'Quote',
                })
            },
            'spellcheck': {
                exec: (editor1) => {
                    const editorContent = editor.current.editor;
                    const allElements = Array.from(editorContent.children);
                    const fragment = document.createDocumentFragment();
                    allElements.forEach((element) => {
                    if (!element.id.startsWith('jodit-selection_marker')) {
                        fragment.appendChild(element.cloneNode(true)); // Clone to avoid modifying the original DOM
                    }
                    });

                    editorContent.innerHTML = ""; 
                    editorContent.appendChild(fragment); 

                    setEditorContentUpload(editorContent.innerHTML);

                },
                tooltip: 'Clear Search Highlights'
            }
        }
    }

    const handleFontChange = (editor, font) => {
        console.log("font_", editor, font);
        editor.selection.applyCSS({ 'font-family': font });
    };

    useEffect(() => {
        console.log("content___", editorContent);
    }, [editorContent])

    const jodit = useMemo(() => {
        return (
            <div>
                <JoditEditor
                    ref={editor}
                    value={editorContent}
                    config={config}
                    className={"custom-editor"}
                    onChange={onChange}
                />
            </div>
        )
    }, [isEdit])

    return (
        <div>
            {isMain ? jodit :
                <div>
                    <JoditEditor
                        ref={editor}
                        value={editorContent}
                        config={config}
                        className={"custom-editor"}
                        onBlur={onChange}
                    />
                </div>

            }
        </div>
    )
}