import React from "react";
import SideNavBar from "../SideNavBar";
import ChatContextProvider from "../../contexts/ChatContext";
import Contacts from "../ChatBox/Contacts/Contacts";
import Main from "../ChatBox/Main/Main";
import { Box , Button} from "@mui/material";
import { QueryClientProvider, QueryClient } from "react-query";
import VideoState from "../../contexts/VideoState";
import CallModal from "../ChatBox/options/CallModal";
import VideoChat from "../ChatBox/Calling/VideoChat";
import { withRouter } from 'react-router-dom';

const queryClient = new QueryClient();

const ChatBoxPage = () => {

  // const { InboxId = '', GroupInboxId = '' } = (history.location.state || {});

  return (
    <SideNavBar>
         <ChatContextProvider>
        <QueryClientProvider client={queryClient}>
          <Box display='flex' maxHeight='80vh'>
          <CallModal />
          <VideoChat />
          <Contacts />
            <Main />
          </Box>
        </QueryClientProvider>
      </ChatContextProvider>
    </SideNavBar>
  );
};

export default ChatBoxPage;
