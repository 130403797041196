import React, { useState, useEffect, useRef }  from "react";

import { withRouter } from "react-router-dom";

import CoAuthorNewProject from "../Forms/CoAuthorNewProject";
import userService from "../../services/userService";
import { setManuallyClick } from "../../redux/manageLoginClick/manageLoginClick.action";
import { useDispatch } from "react-redux";
import authService from "../../services/authService";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { toast } from "react-toastify";


const GrammarCheckTrialPage = (props) => { 

    const [complete, setComplete] = useState(false)
    const [userID, setUserID] = useState('')
    const dispatch = useDispatch();
    const history = useHistory();


    const generateRandomString = (length) => {
        const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
          result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
      };
      
      const generateRandomEmail = () => {
        const randomString = generateRandomString(10); 
        return `${randomString}@gmail.com`;
      };
      
      const generateRandomPassword = () => {
        return generateRandomString(12); 
      };

    const email = generateRandomEmail()
    const password = generateRandomPassword()


    const deleteAccount = async () => {
        const res = await userService.deleteTempAccount()
        sessionStorage.clear();
        localStorage.clear();
        history.push('/');
    }

    const handleManuallyClick = () => {
        dispatch(setManuallyClick());
      };

      async function handleLogin() {
        handleManuallyClick()
        
        let userEntry = {
            email: email.toLowerCase(),
            password: password,
        };
        let response = await authService.login(userEntry);
        console.log("handleLoginResponse", response)
        if (response.status !== 200) {
           
        }
        else {
            const { user, features } = response;

            setUserID(user._id)
            
            localStorage.setItem("organizationId", user.organizationId)
            localStorage.setItem("userId", user._id)
            localStorage.setItem("accountType", user.accountType)
            localStorage.setItem("Role", user.Role)
            localStorage.setItem("token", response.token);
            localStorage.setItem("currentUserEmail", userEntry.email)
            
            
            if (localStorage.getItem("rememberMe")) {
                localStorage.setItem("token", response.token);
            }
            sessionStorage.setItem("organizationId", user.organizationId)
            sessionStorage.setItem("userId", user._id)
            sessionStorage.setItem("accountType", user.accountType)
            sessionStorage.setItem("Role", user.Role)
            sessionStorage.setItem("token", response.token);
            sessionStorage.setItem("currentUserEmail", userEntry.email)
            
            
            if (sessionStorage.getItem("rememberMe")) {
                sessionStorage.setItem("token", response.token);
            }
            setComplete(true)
            setTimeout(() => {
                toast.error("Session expired.")
                deleteAccount()
              }, 300000); 
            
            
        }
    }

    

    const handleRegisterSubmit = async () => {
        
            let user = {
                username: "temporaryTrialUser",
                email: email,
                
                password: password,
                phone: "123123123",
                
                referedEmail: "",
                Role: "Individual",
                organizationName: "",
                organizationId: "",
                userRole: "",
                country: "USA",
            };
            console.log("user_", user);
            
            const result = await userService.register22(user);
            console.log("result hohoho", result)
            
            
        
            if (result.status !== 200) {
                
            } else {
                
                localStorage.removeItem("referedEmail");
                localStorage.removeItem("accountType");
                handleLogin()
                
            }  
    }

  
    useEffect(() => {
        handleRegisterSubmit();
      }, []);
  
    
    return (

     
        complete && <CoAuthorNewProject isGrammarCheck={false} isCoAuthor={true} trial = {true} userId ={userID}/>

    )
};

export default withRouter(GrammarCheckTrialPage);
