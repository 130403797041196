import React, { useEffect, useState } from "react";
import { DropdownButton, Dropdown } from "react-bootstrap";
import Loader from "../utils/loader";
import { toast } from "react-toastify";
import DropDown from "./Cards/DropDown";
import UserCard from "./Cards/UserCard";
import { Popover, OverlayTrigger } from "react-bootstrap";
import {
  getAllManageUsers,
  searchUser,
  planFilter,
  getAllUsers2,
} from "../services/adminServices";
import { withRouter } from "react-router-dom";
import "./ManageUser.css";
import BreadCrumb from "./Cards/BreadCrumbs";
import team from "../static/noteams.png"
import SearchBox from "./SearchBox";
import { Typography } from "@material-ui/core";
import DataGridMui from "./ReusableComponents/DataGridMui";

const ManageUser = ({ history }) => {
  let [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [usersBackup, setusersBackup] = useState([]);
  let [chk, setChk] = useState([]);
  const [drop, setDrop] = useState("");
  const [page, setPage] = useState(0);
  const [value, setvalue] = useState("");
  const [filter, setfilter] = useState([]);
  const [dashLink] = ["/admindashboard"];
  const [LastPage, setLastPage] = useState(0);
  const [planFilterDropDown, setPlanFilterDropDown] = useState("all");

  // const [result, setresult] = useState([]);  ***db.users.find({name: /^${string}/i})
  const showCard = 15;

  //   const getAllUsers = async () => {
  //     setLoading(true);
  //     const res = await getAllManageUsers(showCard, page);
  //     if (res.status === 200) {
  //       setUsers(res.data);
  //       setusersBackup(res.data);
  //       setLastPage(Math.floor(res.pages - 1));
  //       setfilter(res.data);
  //       setLoading(false);
  //     } else {
  //       setLoading(false);
  //       console.log("Oops something went worng");
  //     }
  //   };

  const getAllUsers = async () => {
    setLoading(true);
    const res = await getAllUsers2();
    console.log("getAllUsers", res)
    if (res.status === 200) {
      setUsers(res.data);
      setusersBackup(res.data);
      setfilter(res.data);
      setLoading(false);
    } else {
      setLoading(false);
      console.log("Oops something went worng");
    }
  };

  const handleCheck = (getObj) => {
    if (!drop) return;
    if (chk.some((obj) => obj._id === getObj._id)) {
      chk = chk.filter((obj) => obj._id !== getObj._id);
    } else {
      chk.push(getObj);
    }

    setChk([...chk]);
    console.log(chk);
  };

  useEffect(() => {
    getAllUsers();
  }, [page]);

  const handleSubmit = () => {
    if (chk.length < 1) {
      if (!drop) {
        toast.error("Please select dropdown");
        return;
      }
      toast.error("Please select any one of the User");
      return;
    }
    const sendData = chk;
    console.log(sendData, drop);
    history.push({
      pathname: `/Newsletter`,
      state: { sendData, type: drop },
    });
  };

  const goToSetting = (obj) => {
    // console.log(obj);
    history.push({
      pathname: `/accountsettings`,
      state: { userData: obj },
    });
  };

  const goToDocument = (obj) => {
    history.push({
      pathname: `/mydocuments`,
      state: { userData: obj },
    });
  };

  // const searchDoc = search => {
  //   console.log(search);
  // };
  const filterOnChange = async (e) => {
    if (e.target.value === "") {
      // console.log(e.target.value);
      setPage(0);
      setfilter(users);
    }
    setvalue(e.target.value);
  };

  const searchUsername = async () => {
    let result = await searchUser(value);
    setfilter(result);
  };

  const setDropDowm = (selection) => {
    console.log(selection);
    setDrop(selection);
    if (selection === "newsletter") {
      let filterData = usersBackup.filter((obj) => obj.subscription === true);
      setUsers(filterData);
    } else setUsers(usersBackup);
  };

  const FilterPlan = async (value) => {
    setLoading(true);
    setPlanFilterDropDown(value)
    if (value === "all") {
      setfilter(users);
    } else {
      let data = await planFilter(value);
      setfilter(data.data);
    }
    setLoading(false);
  };

  const columns = [
    // {
    //     field: 'id', headerName: 'ID',
    //     width: 90,
    //     flex: 0.2,
    // },
    {
      field: 'username',
      headerName: 'User Name',
      // width: 150,
      flex: 0.5,
      editable: false,
    },
    {
      field: 'email',
      headerName: 'Email',
      type: 'text',
      flex: 0.4,

    },
    {
      field: 'country',
      headerName: 'Country',
      flex: 0.3,

    },
    {
      field: 'phone',
      headerName: 'Phone Number',
      // type: 'number',
      // width: 110,
      flex: 0.4,
    },
    {
      field: 'Role',
      headerName: 'User Role',
      flex: 0.3,
    },
    {
      field: 'planName',
      headerName: 'User Plan',
      flex: 0.4,
    },
  ];

  const sampleData = [
    { id: 1, username: 'Lavanya', email: 'lavanya.g@gmail.com', phoneNumber: 9751266890 },
    { id: 2, username: 'Ajith Kumar', email: 'ajith@gmail.com', phoneNumber: 8765675452 },
    { id: 3, username: 'Vijay', email: 'vijay@gmail.com', phoneNumber: 9751266890 },
    { id: 4, username: 'Balaji', email: 'balaji@gmail.com', phoneNumber: 9361724780 },
    { id: 5, username: 'Shalini', email: 'shalini@gmail.com', phoneNumber: 12341234 },
  ];

  return (
    <div>
      <Loader loading={loading} />
      {/* <BreadCrumb l1="Dashboard" l2="Manage Users" dashboardlink={dashLink} /> */}

      <div className="container-fluid" style={{ background: "#f0f0f7" }}>
        {/* <BreadCrumb l1="SubAdmin" l2="" dashboardlink="/dashboard" /> */}
        {/* <div className="row justify-content-end w-100">
          <div className="col-md-2 justify-content-end">
            <OverlayTrigger
              trigger="click"
              placement="bottom"
              // overlay={popover}
              rootClose={true}
            >
              <button
                className="btn btn-primary inviteMember"
                style={{ width: "90%" }}
                disabled={true}
              >
                Export
              </button>
            </OverlayTrigger>
          </div>
        </div> */}

        <div className="row mt-2 w-100">
          {/* {filterTeam.length === 0 ? ( */}
          {users.length === 0 ? (
            <div className="col-md-12 text-center">
              <img src={team} alt="No Teams" />
              <h3>No teams found!</h3>
            </div>
          ) : (
            <>
              <div className="col-md-12">
                <DataGridMui
                  height="60vh"
                  getRowId={(data) => data._id}
                  columns={columns}
                  rows={users}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(ManageUser);
