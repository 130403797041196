import React, { Component } from 'react'
import NetworkDetector from "../HigherOrderComponents/NetworkDetector";
import Subadminsidebar from "../SubAdminSideBar"
import queryString from "query-string";
import SubAdminDocuments from '../SubAdminDocuments';

const SubAdminDocumentsPage = (props) => {
  if (props.location.search) {
    let values = queryString.parse(props.location.search);
    sessionStorage.setItem("token", values.token);
  }
    return (
      <Subadminsidebar>
        <SubAdminDocuments />
      </Subadminsidebar>
    );
  };
  export default NetworkDetector(SubAdminDocumentsPage);
