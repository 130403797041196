
const SET_TEMPLATE_NAME = 'SET_TEMPLATE_NAME';
const RESET_TEMPLATE_NAME = 'RESET_TEMPLATE_NAME';
const UPLOAD_COAUTHOR_DOCUMENT = 'UPLOAD_COAUTHOR_DOCUMENT'
const SHARED_DOCUMENT_STATUS = 'SHARED_DOCUMENT_STATUS'
const SHARE_FOLDER_ID = 'SHARE_FOLDER_ID'
export const setTemplateName = (templateName) => (
    {

        type: SET_TEMPLATE_NAME,
        payload: templateName,
    }
);

export const resetTemplateName = (templateName) => (
    {

        type: RESET_TEMPLATE_NAME,
        payload: templateName,
    }
);

export const uploadDocument = (uploadDocument) => (
    {

        type: UPLOAD_COAUTHOR_DOCUMENT,
        payload: uploadDocument,
    }
);

export const sharedDocumentStatus = (sharedDocumentStatus) => (
    {

        type: SHARED_DOCUMENT_STATUS,
        payload: sharedDocumentStatus,
    }
);

export const shareFolderId = (shareFolderId) => (
    {

        type: SHARE_FOLDER_ID,
        payload: shareFolderId,
    }
);