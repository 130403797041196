import React, { useEffect, useRef, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import InputBoxMui from "./InputBoxMui";
import SelectDropdownMui from "./SelectDropdownMui";
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import DataGridMui from "./DataGridMui";
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';

export const StageComp = ({ onStatusChange, onStageCreated }) => {
    const statusRef = useRef(null)
    const dmmuyList = [
        {
            "value": "In-progress",
            "name": "In-progress"
        },
        {
            "value": "Complete",
            "name": "Complete"
        }]

    const [stageName, setStageName] = useState("")
    const [status, setStatus] = useState([
        {
            statusName: "",

        },
    ])
    const [createdStage, setCreatedStage] = useState([])

    const columns = [
        {
            field: 'stageName',
            headerName: 'Stage Name',
            // type: 'text',
            flex: 0.1,
        },
        {
            field: 'edit',
            headerName: 'Edit',
            // flex: 0.1,
            renderCell: (params) => {
                // console.log("params", params)
                return (
                    <button
                        className="btn btn-md"
                        onClick={() => handleEdit(params)}
                    // disabled={params.row.isApproved == true ? true : false}
                    >
                        {<BorderColorRoundedIcon fontSize="small" />}
                    </button>
                )
            }
        },
        {
            field: 'delete',
            headerName: 'Delete',
            // flex: 0.1,
            renderCell: (params) => {
                // console.log("params", params)
                return (
                    <button
                        className="btn btn-md"
                        onClick={() => handleDelete(params)}
                    // disabled={params.row.isApproved == true ? true : false}
                    >
                        {<DeleteRoundedIcon fontSize="small" />}
                    </button>
                )
            }
        },
    ];

    const handleEdit = (params) => {
        console.log("handleEdit", params)
    }

    const handleDelete = (params) => {
        console.log("handleDelete", params)
        setCreatedStage((preValue)=>preValue.filter((obj)=>obj.stageId !== params.id))
    }

    useEffect(() => {

        return () => {
            setStageName("")
            setStatus([{ statusName: "" }])
            setCreatedStage([])
        }
    }, [])

    useEffect(() => {
        onStatusChange(status)
        scrollToBottom()
    }, [status])

    useEffect(() => {
        onStageCreated(createdStage)
    }, [createdStage])

    const scrollToBottom = () => {
        let element = statusRef.current
        if (element) {
            element.scrollTop = element.scrollHeight
        }
    }

    const addStatus = () => {

        let isEmptyStatus = status.some((obj) => obj.statusName.trim() == "")
        console.log("addStatus", status, isEmptyStatus)
        if (isEmptyStatus) {
            toast.warn("Please enter a status")
            return
        }
        setStatus((prevStatus) => [...prevStatus, { statusName: "" }])
    }

    const handleStageName = (e) => {
        console.log("handleStageName", e)
        let { value } = e.target
        setStageName(value)
    }

    const handleStatus = (e, inx) => {
        let { id, value, name } = e.target
        console.log("handleStatus", e, id, name, value, inx, status)
        setStatus((preStatus) =>
            preStatus.map((obj, i) =>
                inx == i ? { ...obj, ["statusName"]: value } : obj
            )
        )
    }

    const handleRemoveStatus = (id) => {
        console.log("handleRemoveStatus", id)
        setStatus((preStatus) => preStatus.filter((_, index) => index !== id))
    }

    const handleAddStage = () => {
        console.log("handleAddStage", createdStage)
        let stageId = createdStage.length == 0 ? 0 : createdStage[createdStage.length - 1].stageId + 1
        let newObj = {
            stageId: stageId,
            stageName: stageName,
            status: status
        }
        if (newObj.status.length == 0) {
            toast.warn("Please add status")
            return
        }
        else if (newObj.stageName.trim() == "") {
            toast.warn("Please enter stage name")
            return
        }
        else if (newObj.status.length > 0) {
            let newStatus = newObj.status
            if (newStatus.some((obj) => obj.statusName.trim() == "")) {
                toast.warn("Please enter status")
                return
            }
        }
        setCreatedStage([...createdStage, newObj])
    }

    const addStage = () => {

    }

    return (
        <>
            <ToastContainer />
            <div className="p-2">
                <InputBoxMui
                    fullWidth
                    label="Workflow Name "
                />
            </div>

            <div className="p-2">
                <InputBoxMui
                    fullWidth
                    label="Description"
                />
            </div>

            {/* <div className="p-2">
                    <button className="btn btn-secondary"
                        onClick={addStage}
                    >
                        Add Stage
                    </button>
                </div> */}

            <div className="mt-2 p-2"
                style={{ border: "1px solid rgba(255,0,0,0.3)", borderRadius: "3px" }}
            >
                <div className="row p-2">
                    <div className="col-md-6">
                        <InputBoxMui
                            fullWidth
                            label="Stage Name"
                            value={stageName}
                            onChange={handleStageName}
                        />
                    </div>
                    <div className="col-md-3">
                        <button
                            className="btn btn-primary"
                            onClick={addStatus}
                        >
                            Add Status
                        </button>
                    </div>

                    <div className="col-md-3">
                        <button
                            className="btn btn-primary"
                            onClick={handleAddStage}
                        >
                            Add Stage
                        </button>
                    </div>

                </div>

                <div
                    ref={statusRef}
                    style={{ height: "25vh", overflowX: "hidden", overflowY: "auto" }}
                >
                    {status.length > 0 ? (
                        status.map((obj, inx) => (
                            <div className="row p-2" key={inx}>
                                <div className="col-md-6">
                                    {/* <SelectDropdownMui
                                    id={inx}
                                    optionsList={dmmuyList}
                                    selectLabel={"Status"}
                                    onChange={(e) => handleStatus(e, inx)}
                                    value={obj.statusName}
                                /> */}

                                    <InputBoxMui
                                        fullWidth
                                        id={inx}
                                        label="Status"
                                        value={obj.statusName}
                                        onChange={(e) => handleStatus(e, inx)}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <button

                                        id={inx}
                                        className="btn btn-danger"
                                        onClick={() => handleRemoveStatus(inx)}
                                    >
                                        <RemoveCircleOutlineRoundedIcon />
                                    </button>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div></div>
                    )}
                </div>
            </div>

            {/* <div className="row p-2">
                <div className="col-md-3 d-flex justify-content-end">
                    <button
                        className="btn btn-primary"
                        onClick={handleAddStage}
                    >
                        Add Stage
                    </button>
                </div>
            </div> */}
            <div className="p-2">
                <p>{createdStage.length > 0 ? "Created Stages" : ""}</p>
                {/* {
                    createdStage.length > 0 && createdStage.map((obj, index) => (
                        <div key={index} className="row" style={{ marginBottom: '10px' }}>
                            <div className="col-md-6"><p>{obj.stageName}</p></div>
                            <div className="col-md-3">Edit</div>
                            <div className="col-md-3">Delete</div>
                        </div>
                    ))
                } */}

                {
                    createdStage.length > 0 &&
                    <DataGridMui
                        height="35vh"
                        // getRowId={(data) => data.workFlowID}
                        getRowId={(data) => data.stageId}
                        columns={columns}
                        rows={createdStage}
                        checkboxSelection={false}
                    />
                }
            </div>
        </>
    )
}