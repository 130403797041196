import React, { useState,useEffect } from "react";
import { withRouter } from "react-router-dom";
import PriceTag from "./PricePlans/PriceTag";
import ThreeBtn from "./PricePlans/ThreeBtn";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

function Subscription({
  price_detail,
  planName,
  planList,
  btn_list,
  plan_mode,
  hide,
  currentUser,
  plan_id,
}) {
  const [planPeriod, setplanPeriod] = useState(null);

  useEffect(() => {
    if (planPeriod) {
      console.log(planPeriod);
    }
  }, [planPeriod]);

  return (
    <div className="col-md-4 text-center res-col mt-4">
      <div
        className={
          currentUser
            ? currentUser.planName !== planName
              ? "card hover-card"
              : currentUser.isAdmin
              ? "card hover-card"
              : hide !== true
              ? "card hover-card currentPlan"
              : "card hover-card"
            : "card hover-card"
        }
        // className={
        //   currentUser
        //     ? currentUser.planName === planName
        //       ? "card hover-card currentPlan"
        //       : "card hover-card"
        //     : "card hover-card"
        // }
        id="sCard"
        style={{ width: "100%",borderRadius:"25px" }}
      >
        <div className="card-body point ">
          <br />
          <h5 className="card-title m-0">{planName} </h5>
          <br />

          <div style={{ height: "20px" }}>
            <ThreeBtn
              btn_list={btn_list}
              planPeriod={(period) => setplanPeriod(period)}
              pricebox={true}
            />
          </div>

          <PriceTag
            price_detail={price_detail}
            planPeriod={planPeriod}
            planName={planName}
            planList={planList}
            plan_mode={plan_mode}
            bg_color={"bg-white"}
            price_size={"50px"}
            hide={hide}
            plan_id={plan_id}
          />

          <br />
          {hide === true ? (
            <div>
              <Link to={{ pathname: "/homepricedetails" }}>
              <button
                      type="button"
                      class="btn px-4  btn-outline-primary w-75"
                      
                    >
                      Learn more
                    </button>
                    </Link>
            </div>
          ) : (
            <div>
              <Link to={{ pathname: "/pricedetails" }}>
              <button
                      type="button"
                      class="btn px-4  btn-outline-primary w-75"
                      
                    >
                      Learn more
                    </button>
                    </Link>
            </div>
          )}

          <br />
          <br />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(withRouter(Subscription));
