import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    }
}));

const colors = [
    "#bff603", "yellow", "#80d4ff", "orange",
    "#ff8b94", "#d3ffce", "#9bb7d4", "#ffdb58",
    "#f1a7fe", "#ff6961", "#ffb347", "#add8e6",
    "#a0bfe6", "#89cff0", "#4f86f7", "#67e6ec",
    "#7fffd4", "#77dd77", "#b19cd9", "#ff9999"
];

const letterToColorIndex = (letter) => {
    // Convert letter to lowercase to ensure consistency
    const lowercaseLetter = letter.toLowerCase();
    // Get ASCII code of the lowercase letter
    const charCode = lowercaseLetter.charCodeAt(0) - 97; // ASCII code of 'a' is 97
    // Wrap the charCode to ensure it stays within the bounds of the colors array
    return charCode % colors.length;
};

export default function BasicAvatar({ name, isTeam }) {
    console.log(isTeam,"isteam")
    const classes = useStyles();
    const colorIndex = letterToColorIndex(name.charAt(0));

    return (
        <Avatar
            style={{
                backgroundColor: `${colors[colorIndex]}`,
                color: "black",
                width: isTeam === "team" ? 54 : null, 
                height: isTeam === "team" ? 54 : null ,
                border: "4px solid white",
                boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.2)"
            }}
        >
            <h5
                style={{ marginTop: "4px" }}
                data-toggle="tooltip"
                title={name}
            >
                {name.charAt(0).toUpperCase()}
            </h5>
        </Avatar>
    );
}
