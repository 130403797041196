import React, { useState, useEffect } from "react";
import BreadCrumb from "../Cards/BreadCrumbs";
import { EditorState, Modifier, CompositeDecorator, convertToRaw, convertFromRaw, KeyBindingUtil, ContentState, ContentBlock } from "draft-js";
import Editor, { createEditorStateWithText, composeDecorators } from "draft-js-plugins-editor";
import DraftFn from '../Editor/DraftFn';
import Swal from "sweetalert2";
import countSyllables from "syllable";
import readability from "readability-meter";
import { toast, ToastContainer } from "react-toastify";
import style from "./paraphrase.module.css";
import Highlighter from "react-highlight-words";
import TextareaAutosize from "react-textarea-autosize";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { PetAssistanceBtn, SideBtn, RightBottomBtn, Statistics, Feedback, } from "./ParaphraseComponent";
import { paraphrase } from "../../services/englishCheckers";
import { loading } from "../../static/loading.png";
import { noText } from "../../static/noText.png";
import { Loader } from "../../utils/loader";
import { connect } from "react-redux";
import HeadingB from "../Cards/HeadingB";
import Skeletoncomp from "../Skeleton";
import { Typography } from "@material-ui/core";
import { MdDelete } from "react-icons/md";
import { Button, Card } from "react-bootstrap";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import ReactTour from "../ReactTour";
import DraftParaPhase from "./DraftParaPhase";
import { ParaphraseDocs } from "./ParaphraseDocs";
import adminServices from "../../services/adminServices";
const { hasCommandModifier } = KeyBindingUtil;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Paraphrase({ features }) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [characters, setCharacters] = useState({ max: 1500, now: 0 });
  const [sentenceSuggestion, setSentenceSuggestion] = useState([]);
  const [leftEditorText, setLeftEditorText] = useState('');
  const [rightEditorText, setRightEditorText] = useState({ text: '', status: '', isClear: false });
  const [statistics, setStatistics] = useState({});
  let [keyCode, setKeyCode] = useState(null)
  const [currentNavId, setCurrentNavId] = useState(0);
  const [preText, setPreText] = useState('');
  const [updatedTextResponse, setUpdatedTextResponse] = useState({
    suggestWords: null,
    text: null,
    leftEditorText: null,
  });
  const [loading, setLoading] = useState(false);
  const [column, setColumn] = useState({
    column: "col-md-6",
    feedback: false,
    statistics: false,
  });
  const [plagarizedContent, setPlagarizedContent] = useState("");
  const [tabValue, setTabValue] = React.useState(0);
  const [reactTour, setReactTour] = React.useState(false);
  const [dontShow, setDontShow] = useState(sessionStorage.getItem('dontShow') || false);
  const [tourSteps, setTourSteps] = React.useState([
    {
      selector: '.reactour-1',
      content: 'Write your content here',
      position: 'right',
    },
    {
      selector: '.reactour-2',
      content: 'On left side it displays the character limit of the content & on right a button to paraphrase the content',
      position: 'right',
    },
    {
      selector: '.reactour-3',
      content: 'Paraphrased content will displayed on right editor',
      position: 'left',
    },
    {
      selector: '.reactour-4',
      content: 'Utility bar displaying the sentence navigation, plagiarism checker, copy response content to clipboard and statistics bar',
      position: 'left',
    },
    {
      selector: '.reactour-5',
      content: 'Click on this tab to check paraphrased content for your existing document',
      position: 'bottom',
    }
  ]);
  const [currentParaphraseText, setCurrentParaphraseText] = useState();
  const [isParaphrase, setIsParaphrase] = useState(false);




  const getParaphraseLimit = async () => {
    let data = await adminServices.getParaphraseLimit();
    setCharacters({ max: data.paraphrase_limit, now: 0 })
    console.log(characters)
  };

  const handleSpan = props => {
    // console.log("handleSpan==>", props);
    const { start, end } = props;
    console.log('props', props);
    let className = 'select-highlight';
    return (<span {...props} className={className}> {props.children} </span>);
  };

  const handleStrategy = (contentBlock, callback, contentState) => {
    console.log('currentNavId', currentNavId)
    sentenceSuggestion.forEach((obj) => {
      const id = obj.id;
      if (id === currentNavId) {
        console.log('obj start end', currentNavId, obj.start, obj.end)
        callback(obj.start, obj.end)
      }
    });
  }

  const generateDecorator = () => {
    console.info('generateDecorator_called');
    return new CompositeDecorator([
      {
        strategy: handleStrategy,
        component: handleSpan
      }
    ]);
  };

  const decoratorInit = () => {
    const editorStateWithDec = EditorState.set(editorState, { decorator: generateDecorator() });
    setEditorState(editorStateWithDec);
  }

  const handleChangeText = (text) => {
    if (!text) return
    console.log("before text", text);
    text = text.replace(/['"]+/g, '');
    console.log("after text", text);
    const editorState = EditorState.createEmpty();
    const newContentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      text
    );
    const updatedEditor = EditorState.push(editorState, newContentState, 'insert-characters')
    setEditorState(EditorState.set(updatedEditor, { decorator: generateDecorator() }));
  }

  const onHandleClear = () => {
    console.log('init onHandleClear');

    setEditorState(EditorState.createEmpty());
    setRightEditorText({ ...rightEditorText, isClear: true });
    leftSuggestion([]);
    setCurrentNavId(0);
    setIsParaphrase(false);
    if (plagarizedContent) {

      colChange("statistics");
    }
    setPlagarizedContent("");

  }

  const handleTabChange = (event, newValue) => {
    console.log('handleTabChange');
    setTabValue(newValue)
  }

  const onLeftHandleNavigation = (currentId, text) => {
    console.log('init onLeftHandleNavigation', currentId);
    handleChangeText(text);
    setCurrentNavId(currentId)
  }

  useEffect(() => {
    getParaphraseLimit();
  }, []);

  useEffect(() => {
    decoratorInit();

  }, [currentNavId]);


  /** set the text when the text enter in the left **/
  const onLeftEditorChange = (event) => {
    // console.log('keyCode', keyCode)
    if (keyCode === 86) {
      keyCode = null;
      removeString(event);
    }
    else {
      // removeString(event);
      let previous_Content = editorState.getCurrentContent().getPlainText();
      let current_Content = event.getCurrentContent().getPlainText();

      if(previous_Content !== current_Content){
        setIsParaphrase(false);
        setRightEditorText({ ...rightEditorText, isClear: true });
        leftSuggestion([]);
        setCurrentNavId(0);
      }

      setEditorState(event);
      let length = current_Content.length;
      if (characters.max < length)
        length = characters.max
      setCharacters({ ...characters, now: length });
    }
  }

  const myKeyBindingFn = (e) => {
    // if you paste thecommand this if conditon will invoke
    if (e.keyCode === 86 /* `V` key */ && hasCommandModifier(e)) {
      keyCode = e.keyCode;
    }
  }

  const removeString = (event) => {
    console.log('Remove String fuction called');
    let maxLength = characters.max - 1;
    const contentState = event.getCurrentContent();
    let convertRaw = convertToRaw(contentState);
    console.log('convertRaw', convertRaw);
    const blocks = convertRaw.blocks;

    let end = 0;
    const updatedBlock = blocks.map((block) => {
      const blockText = block.text;
      const blockTextLen = blockText.length;

      if (maxLength < 0) {
        toast(`2.You are reached maximum character limit ${characters.max}`);
        end = 0;
      }
      else {
        if (blockTextLen < maxLength) {
          maxLength = maxLength - blockTextLen;
          console.log('maxLength', maxLength, 'blockTextLen', blockTextLen);
          end = blockTextLen;
        }
        else {
          end = Math.abs(maxLength);
          maxLength = -1;
          toast(`3.You are reached maximum character limit ${characters.max}`);
        }
      }
      console.log('endLen', end)
      const text = blockText.slice(0, end);
      block.text = text;
      return block;
    });

    convertRaw.blocks = updatedBlock;
    console.log('updatedRaw', convertRaw);
    let newBLocks = convertRaw.blocks;
    let slicedString = ``;
    newBLocks.map(block => {
      slicedString += block.text;
      return block;
    });
    slicedString = slicedString.slice(0, characters.max);
    handleChangeText(slicedString);
    // const updatedContentState = convertFromRaw(convertRaw);
    // const updatedEditorState = EditorState.createWithContent(updatedContentState);
    // setEditorState(updatedEditorState)
  }

  const _handleBeforeInput = () => {
    const MAX_LENGTH = characters.max;
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length
    console.log('currentContentLength', currentContentLength)
    if (currentContentLength > MAX_LENGTH - 1) {
      console.log('you can type max ten characters');
      toast(`1.You are reached maximum character limit ${characters.max}`);
      return 'handled';
    }
  }


  const onPetAssistant = () => {
    if (features.ParaPhrase !== true) {
      toast("Please Upgrade your plan to enable Paraphrase Feature");
      return;
    }
    const current_content = editorState.getCurrentContent();
    const text = current_content.getPlainText();
    console.log("input_text===>", text);
    if (text.length < 10) {
      toast('Please Enter at least one sentence');
      return;
    }
    let isTextChanged = false;
    let isPara = true;
    if (preText !== text) {
      isTextChanged = true;
      setPreText(text);
    }
    setIsParaphrase(isPara);
    setRightEditorText({ text, status: !rightEditorText.status, isTextChanged, isClear: false });

    // console.log('leftEditorText', leftEditorText);
  }

  const statisticsFn = async () => {
    console.log("init statisticsFn");
    const editorRight = currentParaphraseText;
    const editorLeft = rightEditorText.text;

    if (!editorRight || !editorLeft) {
      let statistic = {
        sentance: 0,
        words: 0,
        avgWords: 0,
        percentChange: 0,
        syllables: 0,
        readability: 0,
      };
      console.log("statistic", statistic);
      setStatistics(statistic);
      return;
    }

    const sentance = editorRight.split(/\.+/);
    if (!sentance[sentance.length - 1]) {
      sentance.pop();
    }
    const words = editorRight.split(/\s+/);
    const avgWords = ((sentance.length / words.length) * 100).toFixed(2);
    const count = words.reduce((count, word, i) => {
      word = word.replace(/[^a-zA-Z ]/g, "");
      const pattern = `\\b(${word})\\b`;
      const regex = new RegExp(pattern, 'g');;
      if (!regex.test(editorLeft)) count++;
      return count;
    }, 0);
    const percentChange = ((count / words.length) * 100).toFixed(2);
    let syllables = countSyllables(editorRight);
    const grade = await readability.ease(editorRight);
    const score = grade.score.toFixed(2);

    let statistic = {
      sentance: sentance.length,
      words: words.length,
      avgWords,
      percentChange,
      syllables,
      readability: score,
    };
    console.log("statistic", statistic);
    setStatistics(statistic);
  };

  const colChange = (status) => {
    console.log(status); // feedback, statistics
    let feedback = false;
    let statistics = false;
    let mycol = "col-md-4";
    if (status === "feedback") {
      feedback = !column.feedback;
      if (!feedback) mycol = "col-md-6";
    } else if (status === "statistics") {
      statistics = !column.statistics;
      if (!statistics) mycol = "col-md-6";
      else statisticsFn();
    }
    setColumn({ column: mycol, feedback, statistics });
  };

  const assignplagarizedContent = (htmlContent) => {
    setPlagarizedContent(htmlContent);
  }

  const leftSuggestion = (suggestion) => {
    console.log('suggestion', suggestion);
    setSentenceSuggestion(suggestion);
  }
  const ParaContent = (data) => {
    let text = data.replace(
      /^\s*[\r\n]/gm,
      ""
    );
    const length = text.length;
    console.log(data)
    // const max = characters.max;
    // characterLimit(max, length);
    setLeftEditorText(text.replace(/^\s*[\r\n]/gm, ''));
    sessionStorage.setItem("paraphraseText", text);
    setCharacters({ ...characters, now: length });
    setRightEditorText({ text: "", status: "", isClear: false });
    if (length > characters.max) {
      text = text.slice(0, characters.max);
      handleChangeText(text);
    } else {
      handleChangeText(text);
    }
    // if (max >= length) {
    //   setLeftEditorText(text);
    //   sessionStorage.setItem("paraphraseText", text);
    //   setCharacters({ ...characters, now: length });
    // } else {
    //   toast.error("Character limit exceeded.");
    // }
    setTabValue(0);
  };

  const handlePastedText = (text) => {
    // const {editorState} = this.state;
    console.log("before text",text);
    text = text.replace(/['"]+/g, '');
    console.log("after text",text);
    const blockMap = ContentState.createFromText(text.trim()).blockMap;
    const newState = Modifier.replaceWithFragment(editorState.getCurrentContent(), editorState.getSelection(), blockMap);
    setEditorState(EditorState.push(editorState, newState, 'insert-fragment'));
    return true;
  }




  const handleDontShow = (e) => {
    const dontShow = e.target.checked;
    if (dontShow) {
      localStorage.setItem("dontShow", true);
      sessionStorage.setItem("dontShow", true);
    } else {
      localStorage.setItem("dontShow", false);
      sessionStorage.setItem("dontShow", false);
    }
    setDontShow(dontShow);
  };


  const onHandleDelete = (e) => {
    console.log(e.target.value);
    const value = e.target.value
    if (value === "true") {
      setEditorState(EditorState.createEmpty());
      setRightEditorText({ ...rightEditorText, isClear: true });
      leftSuggestion([]);
      setCurrentNavId(0);
      setIsParaphrase(false);
      if (plagarizedContent) {
        colChange("statistics");
      }
      setPlagarizedContent("");
    }
  }

  return (
    <div>
      <Loader loading={loading} />
      <ToastContainer />
      <ReactTour
        steps={tourSteps}
        isOpen={reactTour}
        closeTour={() => setReactTour(false)}
      />
      <BreadCrumb l1="Home" l2="Paraphraser" dashboardlink="dashboard" />
      <div className="row">
        <div className="col-10">
          <HeadingB text="Paraphraser"></HeadingB>
        </div>
        <div className="col-2">
          {tabValue === 0 ? (
            <div style={{ float: "right" }}>
              <i
                className="fa fa-question-circle mr-5"
                aria-hidden="true"
                data-toggle="tooltip"
                title="Need Help ?"
                style={{
                  cursor: "pointer",
                  color: "dodgerblue",
                  fontSize: "15px"
                }}
                onClick={() => setReactTour(true)}
              ></i>
            </div>
          ) : ("")}
        </div>
      </div>

      <hr className={style.hrdash} />

      <AppBar position="static">
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="simple tabs example"
        >
          <Tab label="Paraphraser" {...a11yProps(0)} />
          <Tab className="reactour-5" label="My Documents" {...a11yProps(1)} />
        </Tabs>
      </AppBar>

      <TabPanel value={tabValue} index={0}>
        <div className="row">
          <div className={column.column}>
            {characters.now > 0 ? (<>
              {dontShow ? (<button
                className={style.trash}
                onClick={onHandleClear}
                style={{ position: "absolute", top: "12px", right: "24px" }}
              >
                <MdDelete
                   data-placement="top"
                  title="Clear editor"
                  size={"24px"}
                />
              </button>) : (<button
                className={style.trash}
                style={{ position: "absolute", top: "12px", right: "24px" }}
              >
                <MdDelete
                  data-toggle="modal"
                  data-target="#myModal"
                  data-placement="top"
                  title="Clear editor"
                  size={"24px"}
                />
              </button>)}
            </>) : ("")}

            <div className="reactour-1">
              {/* <TextareaAutosize
                onChange={onLeftEditorChange}
                id="editorLeft"
                className='editable'
                className={style.editable}
                value={leftEditorText}
                spellCheck={false}
                placeholder="PET paraphraser will rewrite your text. Start by writing or pasting something here and then press praphraser It."
              /> */}

              <div className="editor ft_md w-100" style={{
                paddingRight: "8%",
                minHeight: "70vh",
                maxHeight: "70vh",
                overflowY: "auto"
              }}
              >
                <Editor
                  onChange={(e) => onLeftEditorChange(e)}
                  handleBeforeInput={_handleBeforeInput}
                  keyBindingFn={myKeyBindingFn}
                  editorState={editorState}
                  autoCapitalize="sentences"
                  handlePastedText={() => handlePastedText}
                />
              </div>
            </div>
            {/* <div id='editorLeft' 
                onInput={onLeftEditorChange}
                className={style.editable} 
                contentEditable ></div> */}

            <div className="reactour-2">
              <PetAssistanceBtn
                onPetAssistant={onPetAssistant}
                onHandleClear={onHandleClear}
                characters={characters}
                // sentenceSuggestion_={sentenceSuggestion_}
                isPara={isParaphrase}
              // limitChara={buttonDisable}
              />
            </div>
          </div>
          <div className={[column.column].join(' ')}>
            <DraftParaPhase
              _colChange={colChange}
              onLeftHandleNavigation={onLeftHandleNavigation}
              leftSuggestion={leftSuggestion}
              rightEditorText={rightEditorText}
              setLoading={setLoading}
              setPlagarizedContent={assignplagarizedContent}
              setCurrentParaphraseText={setCurrentParaphraseText}
            />

          </div>
          {/* <div className={[column.column, "reactour-3"].join(' ')}>
          <SideBtn
            onHandleLike={onHandleLike}
            onSelectedTextCopy={onSelectedTextCopy}
            isLocked={isLocked}
            isLike={isLike}
            onHandleLock={onHandleLock}
            showHistory={showHistory}
          />
          <div
            id="editorRight"
            className={style.editable}
            contentEditable={false}
          >
            {rightEditorText ? (
              <Highlighter
                highlightClassName="selectedText"
                searchWords={selectedText}
                autoEscape={true}
                highlightTag={"span"}
                textToHighlight={rightEditorText}
              />
            ) : (
                <Typography>
                  <Skeletoncomp width={430} />
                </Typography>
              )}
          </div>
          
          <div className="reactour-4">
          <RightBottomBtn
            sentanceNav={sentanceNav}
            onHandleNavigation={onHandleNavigation}
            colChange={colChange}
            plagarizedContent={assignplagarizedContent}
            setLoading={(value)=>setLoading(value)}
          />
          </div>
        </div> */}

          <div className="col-md-4">
            <ReactCSSTransitionGroup
              transitionName="example"
              transitionAppear={true}
              transitionEnter={true}
              transitionLeave={true}
            >
              {column.statistics && (
                <Statistics statistics={statistics} colChange={colChange} plagarizedContent={assignplagarizedContent} />
              )}
              {column.feedback && <Feedback colChange={colChange} />}
            </ReactCSSTransitionGroup>
          </div>
        </div>

        <div className="row">
          {plagarizedContent ? (
            <div className="container mt-5">
              <Card className="text-center" style={{ boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.4)" }}>
                <Card.Header><h3>Plagiarized Content</h3></Card.Header>
                <Card.Body>
                  <Card.Text>
                    {plagarizedContent}
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>

          ) : null}
        </div>
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        <ParaphraseDocs isPara={true} ParaContent={ParaContent} />
      </TabPanel>


      {/*  clear editor modal */}
      <div >


        <div class="modal fade" id="myModal" role="dialog">
          <div class="modal-dialog modal-dialog-centered ">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
              </div>
              <div class="modal-body">
                <h4 style={{ textAlign: "center" }}>Can we clear you editor</h4>
              </div>
              <div class="modal-footer">
                <input type="checkbox" id="defaultUnchecked" checked={dontShow} onChange={(e) => handleDontShow(e)} />
                <label style={{ marginRight: "45%", marginTop: "1%" }} for="defaultUnchecked"> Don't ask me again</label><br />
                <button type="button" class="btn btn-success" value="true" data-dismiss="modal" onClick={(e) => onHandleDelete(e)}>Continue</button>
                {/* <button type="button" class="btn btn-danger" value="false" data-dismiss="modal" onClick={(e)=>onHandleDelete(e)}>No</button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  features: state.features.features,
});

export default connect(mapStateToProps)(Paraphrase);
