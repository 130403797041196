import React, { useEffect, useState, useRef } from 'react';

import { Editor, EditorState, ContentState, RichUtils, Modifier, convertToRaw, convertFromRaw, convertFromHTML, SelectionState } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { Button, IconButton } from '@material-ui/core';
import Button1 from '@mui/material/Button';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Link } from 'react-router-dom';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import UploadIcon from '@mui/icons-material/Upload';
import MicIcon from '@mui/icons-material/Mic';
import AirplayIcon from '@mui/icons-material/Airplay';
import { Menu, MenuItem } from '@material-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import DownloadIcon from '@mui/icons-material/Download';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { checkGrammar, checkGrammarFluency, checkPhrasing, checkSummarizer } from '../../services/englishCheckers';
import { getCheckSummariser } from '../../services/summariserService';
import { Loader } from "../../utils/loader";
import EditIcon from '@mui/icons-material/Edit';
import { toast, ToastContainer } from "react-toastify";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CheckIcon from '@mui/icons-material/Check';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import styled from "styled-components";
import CoAutherTopMenubar from '../pages/CoAutherTopMenubar'
import CoauthorModal from './FormComponents/CoAuthor_Model';
import createUndoPlugin from 'draft-js-undo-plugin';
import { Popover, List, ListItem, ListItemText, Switch, Box } from '@mui/material';
import TranslateIcon from '@mui/icons-material/Translate';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SelectDropdownMui from '../ReusableComponents/SelectDropdownMui';
import { checkTranslator } from '../../services/translatorServices';
import CoAuthorLowerModel from './FormComponents/CoAuthorLowerModel';
import { strCount } from '../Editor/utilize';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import documentService from '../../services/document';
import { useDispatch, useSelector } from 'react-redux';
import { resetTemplateName } from '../../redux/coauthor/coauthor.action';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { closeLeftNavbar, closeLowerModalAndOpenLeftNavbar, openLeftNavbar, openLowerModalAndCloseLeftNavbar, closeLowerModal } from '../../redux/handleSideNavBarAndModel/handleStatus.action';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import CoAuthorShareModal from './CoAuthorShareModal';
import { useLocation } from 'react-router-dom';
import htmlToDraft from 'html-to-draftjs';
import swal from "sweetalert";
import { reviewDocument } from "../../services/teamCollbarationServices";
import myDocumentsNewProjectServices from "../../services/myDocumentsNewProjectServices";
import blogService from '../../services/blogServices';
import { DownloadOptionModal } from '../MyDocumentsCardsModal';
import aireviewicon from "../../static/AI_Review_color.png"
import paraphrase_logo from "../../static/Paraphrase 1.png"

import HeadingB from '../Cards/HeadingB';

import {
  SuggestionCards,
  RewriteCard,
  StatisticalFactCard,
  ExplainCard,
} from "../MyDocumentsNewProjectCards";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import JoditEditor from 'jodit-react';
import JoditEditorComp from '../ReusableComponents/JoditEditorComp';
import SuggestionComp from '../ReusableComponents/SuggestionComp';
import GrammarCheckAssistant from '../ReusableComponents/GrammarCheckAssistant';
import Tags from '../Tags';
import TagButtonComp from '../ReusableComponents/TagButtonComp';
import CheckboxMui from '../ReusableComponents/CheckBoxMui';
import { FaBookOpen } from "react-icons/fa";
import { asBlob } from 'html-docx-js-typescript'
import { saveAs } from "file-saver";
import { CoAuthor_Aireviewcomponent } from './FormComponents/CoAuthor_Aireview';
import CloseIcon from '@mui/icons-material/Close';
import { closeRightModalAndOpenLeftNavbar, openRightModalAndCloseLeftNavbar, closeRightModal } from '../../redux/handleSideNavBarAndModel/handleStatus.action';


const CustomTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'blue',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const CoAuthorNewProject = ({ docId, isCoAuthor = true, isGrammarCheck, trial = false, userId }) => {
  console.log(isGrammarCheck, "props of couthor");
  
  const [isToolbarVisible, setisToolbarVisible] = useState(true)
  const [loading, setLoading] = useState(false);
  const [showRightSide, setShowRightSide] = useState(false);
  const [showSummarizer, setShowSummarizer] = useState(true);
  const [showParaphrase, setShowParaphrase] = useState(false)
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const maxWords = 2500;
  const [secondaryeditorState, setSecondaryEditorState] = useState(EditorState.createEmpty());
  const [secondaryeditorStateSummarizer, setSecondaryEditorStateSummarizer] = useState(EditorState.createEmpty());
  const [secondaryeditorStateTranslator, setSecondaryEditorStateTranslator] = useState(EditorState.createEmpty());
  const [showTranslationEditor, setShowTranslationEditor] = useState(false);
  const [leftEditorText, setLeftEditorText] = useState('');
  const [rightEditorText, setRightEditorText] = useState({ text: '', status: '', isClear: false });
  const [trackIsModelOpen, setTrackIsModalOpen] = useState(false)
  const [editorState2, setEditorState2] = useState(() => EditorState.createEmpty());
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);

  const [editorHtmlContent, setEditorHtmlContent] = useState('')
  const [acceptAllClicked, setAcceptAllClicked] = useState(false);
  const [ignoreAllClicked, setIgnoreAllClicked] = useState(false)
  const [imgInEditor, setImgInEditor] = useState(0)

  const [isTyping, setIsTyping] = useState(false)
  const [docApiCalled, setDocApiCalled] = useState(false);
  const [blogApiCalled, setBlogApiCalled] = useState(false);
  const [newDocId, setNewDocId] = useState('');
  const [docData, setDocData] = useState('')
  const [docWords, setDocWords] = useState('')
  const [docCharacters, setDocCharacters] = useState('')
  const [docSaved, setDocSaved] = useState(false)
  const [blogSaved, setBlogSaved] = useState(false)
  const [docContent, setDocContent] = useState('')
  const [isSaving, setIsSaving] = useState(false);
  const [circularLoader, setCircularLoader] = useState(false)
  const [viewOnlyAccess, setViewOnlyAccess] = useState(false)
  const [currentEditorValue, setCurrentEditorValue] = useState('')
  const [showAssistant, setShowAssistant] = useState(false)


  const [totalWords, setTotalWords] = useState(0);
  const [translatedText, setTranslatedText] = useState('');
  //  -------
  const [inputKey, setInputKey] = useState()
  const [sliderValue, setSliderValue] = useState(0);
  const [sliderText, setSliderText] = useState("Shorten")
  const [maxCountDisplayed, setMaxCountDisplayed] = useState(false)
  const [customPrompt, setCustomPrompt] = useState("")
  const [paragraphState, setParagraphState] = useState()
  const [editorStatePara, setEditorStatePara] = useState("");
  const [isKeyword, setIsKeyword] = useState(false)
  const [APIData, setAPIData] = useState(false);
  const [keywords, setKeywords] = useState([])
  const [isBulletPoints, setIsBulletPoints] = useState(false)
  const [isParagraph, setIsParagraph] = useState(false)
  const [isCustom, setIsCustom] = useState(false)
  const [activeLink, setActiveLink] = useState(null);
  // const [transcript, resetTranscript] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [editDropdown, setEditDropdown] = React.useState(null);
  const [selectedEditOption, setSelectedEditOption] = useState(true);
  const [selectedViewoption, setSelectedViewOption] = useState(false)
  const isSuggestingDisabled = true;
  //const editorRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [shortcutPopoverAnchor, setShortcutPopoverAnchor] = useState(null);
  const [grammarPopoverAnchor, setGrammarPopoverAnchor] = useState(null);
  const [isArrowClickedGrammar, setIsArrowClickedGrammar] = useState(false);
  const [isArrowClickedShort, setIsArrowClickedShort] = useState(false);
  const { transcript, resetTranscript } = useSpeechRecognition();
  const [showTranslator1, setShowTranslator1] = useState(false);
  const [showSummariser1, setShowSummariser1] = useState(false);
  const [showParaphraser1, setShowParaphraser1] = useState(false);
  const [callGrammerCheck, setCallGrammerCheck] = useState(false);
  const [parentState, setParentState] = useState(EditorState.createEmpty());
  const [firstTime, setFirstTime] = useState(0);
  const [callSummarise, setCallSummarise] = useState(false);
  // const [templateName,setTemplateName] = useState("cold-calling")
  const [showTranslateModel, setShowTranslateModel] = useState(false);
  const [sharedState, setSharedState] = useState('');
  const [docName, setDocName] = useState("")
  const [mountedFromUpload, setMountedFromUpload] = useState(false);
  const [uploadSize, setUploadSize] = useState(true)
  const [sharedDocument, setSharedDocument] = useState(false)
  const [removeHighlight, setRemoveHighlight] = useState(false)

  const [clickSubmitForReview, setClickSubmitForReview] = useState(false)
  const userDetails = useSelector((user) => user)
  const userID = userId ? userId : userDetails.user.currentUser._id 
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [showHintIcon, setShowHintIcon] = useState(false);
  const [anchorElForoHint, setAnchorElForHint] = useState(null);
  const [rewrittenText, setRewrittentext] = useState("");
  const [statisticalData, setStatisticalData] = useState("");
  const [explanationText, setExplanationText] = useState("");
  const [statisticalFactCard, setStatisticalFactCard] = useState(false);
  const [explainCard, setExplainCard] = useState(false);
  const [rewriteCard, setRewriteCard] = useState(false);
  const [contentValue, setContentValue] = useState("");
  const [folderId, setFolderId] = useState('')

  const [convertedHtml, setConvertedHtml] = useState('');
  const [conversionMessages, setConversionMessages] = useState([]);


  const [selectedOption, setSelectedOption] = useState(null);
  const uploadDocumentValue = useSelector((state) => (state.templateName.uploadDocument))

  const editor = useRef(null);
  const [editorContent, setEditorContent] = useState("")

  // for grammar check 
  const [sentenceResult, setSentenceResult] = useState([])
  const [isSuggestion, setIsSuggestion] = useState(true)
  const [isType, setIsType] = useState(true)

  const [grammarFluency, setGrammarFluency] = useState();
  const [isGoalsOpen, setIsGoalsOpen] = useState(false)
  const [isAIOpen, setIsAIOpen] = useState(false)

  const [audience, setAudience] = useState("")
  const [goalsAudienceButton, setGoalsAudienceButton] = useState([
    "General", "Knowledgeable", "Expert"
  ])
  const [domain, setDomain] = useState("")
  const [goalsDomainButton, setGoalsDomainButton] = useState([
    "Academic", "Business", "General", "Email", "Casual", "Creative"
  ])

  const [showGoalsCheck, setShowGoalsCheck] = useState(true)

  const [formality, setFormality] = useState("")
  const [tone, setTone] = useState("")

  const [professionList, setProfessionList] = useState([
    {
      name: "Content Writer",
      value: "Content Writer"
    },
    {
      name: "Copywriter",
      value: "Copywriter"
    },
    {
      name: "Technical writer",
      value: "Technical writer"
    },
    {
      name: "Research Analyst",
      value: "Research Analyst"
    },
  ])
  const [profession, setProfession] = useState("")

  const [tags, setTags] = useState([]);
  const [istagsEnter, setIsTagsEnter] = useState(false);

  const [showAireviewContent, setshowAireviewContent] = useState(false)
  const [showGoals, setShowGoals] = useState(false);
  const [ignoredSuggestions, setIgnoredSuggestions] = useState([])

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Update windowWidth on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const mobileView = windowWidth < 770

  const checkIgnoredSentence = async () => {
    try {
      const userId = sessionStorage.getItem("userId");
      let id = userId
      console.log(id, "dataaa");
      const response = await documentService.getIgnoredSuggestions(id);
      console.log(response.getPhrases, 'res from get ignored suggestion')
      if (response.getPhrases.length > 0) {
        setIgnoredSuggestions(response.getPhrases)
      }

    } catch (error) {
      console.log("Error in checking ignored suggestion.");
    }
  }

  useEffect(() => {
    checkIgnoredSentence()
  }, [])
  console.log("ignoredSuggestions", ignoredSuggestions)


  const sharedFolderId = useSelector((state) => (state.templateName.shareFolderId))
  let grammarCheckArray = []
  let finalArray = []

  const { clickState } = useSelector(state => state.handleClick);
  console.log(clickState, "+++++++");
  useEffect(() => {
    console.log(clickState, "-------clickState");
  }, [clickState]);

  useEffect(() => {

    console.log("SSHHHHHHHHHHHHHHHHHH", sharedFolderId)
    setFolderId(sharedFolderId)
  }, [sharedFolderId])



  useEffect(() => {

    console.log("CAAAAAAAAAAAAA    uploaddocumentvalue")
    setMountedFromUpload(uploadDocumentValue)
  }, [uploadDocumentValue])

  const sharedDocumentValue = useSelector((state) => (state.templateName.sharedDocumentStatus))
  console.log(sharedDocumentValue, "sharedDocumentValue");
  useEffect(() => {
    console.log("CAAAAAAAAAAAAA    shareddocumentvalue")
    setSharedDocument(sharedDocumentValue)
  }, [sharedDocumentValue])


  const openShareModal = () => {
    setIsShareModalOpen(true)
  }

  const closeShareModal = () => {
    setIsShareModalOpen(false)
  }

  const closeDownloadModal = () => {
    setIsDownloadModalOpen(false);
  };

  const openDownloadModal = () => {
    setIsDownloadModalOpen(true)
  }


  const history = useHistory()
  const location = useLocation();


  useEffect(() => {
    // Retrieve blog content from props.location.state if available
    if (location.state && location.state.blogContent) {
      const blogContent = location.state.blogContent
      setEditorContentUpload(blogContent)
      setDocName(location.state.blogName)
      setTags(location.state.blogTags)
    }
  }, [location.state]);


  useEffect(() => {
    console.log("CAAAAAAAAAAAAA    documentId");
    if (docId) {
      return
    }
    if (location.state && !mountedFromUpload) {
      const { fileContent, fileName } = location.state;
      console.log("fileContent_", fileContent, fileName)
      // const newEditorState = EditorState.createWithContent(convertFromRaw({ blocks: [{ text: fileContent }], entityMap: {} }))
      // handleLefEditorChange(newEditorState);
      setEditorContentUpload(fileContent)

    }
  }, [newDocId, mountedFromUpload]);

  const setEditorContentUpload = (textContent) => {
    if (editor.current) {
      const setContent = editor.current;
      setContent.setEditorValue(textContent);
    }
  };


  useEffect(() => {
    console.log("CAAAAAAAAAAAAA    MOUNT")
    if (docId) {
      return
    }
    if (location.state && mountedFromUpload) {
      const { fileContent, fileName } = location.state;
      // const newEditorState = EditorState.createWithContent(convertFromRaw({ blocks: [{ text: fileContent }], entityMap: {} }))
      setDocName(fileName);
      // setEditorState(newEditorState);
      setEditorContent(fileContent)
      setMountedFromUpload(false)
    }
  }, [mountedFromUpload]);


  const selectedTemplateName = useSelector((state) => state.templateName.templateName);
  const { rightModalOpen, leftNavbarOpen, lowerModelOpen } = useSelector(state => state.handleModelAndSideBavBar);
  const dispatch = useDispatch();
  // const {templateName} = useParams()

  // const [dummyMail, setDummyMail] =useState  ({
  // coldEmail
  // :
  // `Subject: Exploring Potential Collaboration Opportunities Dear [Recipient's Name], I hope this email finds you well. My name is [Your Name], and I am reaching out to explore potential collaboration opportunities between our organizations. [Company Name] has caught our attention due to its impressive [specific achievement or feature]. We believe that there could be mutual benefits in exploring a partnership or collaboration that leverages the strengths of both our organizations. I would appreciate the opportunity to connect with you and discuss how we can create value together. Are you available for a brief call or meeting in the coming week? Please let me know a time that suits you, and I'll make sure to accommodate. Thank you for considering this, and I look forward to the possibility of working together. Best regards, [Your Full Name] [Your Position] [Your Company] [Your Contact Information]`
  // });

  const [dummyMail, setDummyMail] = useState({
    Appendix: `Subject: Exploring Potential Collaboration Opportunities\nDear [Recipient's Name],\n\nI hope this email finds you well. My name is [Your Name], and I am reaching out to explore potential collaboration opportunities between our organizations.\n\n[Company Name] has caught our attention due to its impressive [specific achievement or feature]. We believe that there could be mutual benefits in exploring a partnership or collaboration that leverages the strengths of both our organizations.\n\nI would appreciate the opportunity to connect with you and discuss how we can create value together. Are you available for a brief call or meeting in the coming week? Please let me know a time that suits you, and I'll make sure to accommodate.\n\nThank you for considering this, and I look forward to the possibility of working together.\n\nBest regards,\n[Your Full Name]\n[Your Position]\n[Your Company]\n[Your Contact Information]`
  });



  const templateData = [
    {
      name: "Cold-Email",
      data: `<div >

      <p><h3>Subject Line:</h3> Compelling Subject That Grabs Attention</p>

      <p><h3>Greeting:</h3> Hi [Recipient's Name],</p>

      <p><h3>Introduction:</h3> I hope this email finds you well. My name is [Your Name], and I am reaching out to you. [Briefly introduce yourself and mention a common interest or connection.]</p>

      <p><h3>Value Proposition:</h3> I want to share with you the exciting value [Your Product/Service/Proposition] brings. [Explain how it can benefit the recipient and address a pain point.]</p>

      <p><h3>Relevance:</h3> Considering [Recipient's Company/Needs/Challenges], I believe our [Product/Service/Proposition] is particularly relevant. [Customize and explain why it aligns with their specific situation.]</p>

      <p><h3>Call to Action:</h3> I would love to discuss this further with you. Could we schedule a brief call at your earliest convenience? [Provide options for scheduling or another clear CTA.]</p>

      <p><h3>Social Proof (Optional):</h3> We've had success working with companies like [Client Name] and achieved [mention any notable achievements]. [Optional: Include a brief testimonial or success story.]</p>

      <p><h3>Closing:</h3> Thank you for considering [Your Product/Service/Proposition]. I look forward to the possibility of working together.</p>

      <p><h3>Best regards:</h3> <br> [Your Full Name] <br> [Your Position] <br> [Your Company]</p>

      <p><h3>Contact Information:</h3> <br> [Your Email Address] <br> [Your Phone Number]</p>


      </div>
      `    },
    {
      name: "Resume",
      data: `  <div style={{margin:"10px"}}>
      <span><strong>Your Name :</strong> Lorem ipsum dolor sit amet, consectetuer adipiscing elit </span> <br />

    <span>  <strong>Address :</strong> 123 Your Street <br />
      Your City, ST 12345 <br />
      (123) 456-7890 <br />
      no_reply@example.com
    </span>

    <div class="section" style="margin-top: 20px">
      <h3>EXPERIENCE</h3>
      <p>
        <strong>Company, Location — Job Title</strong><br />
        MONTH 20XX - PRESENT<br />
        Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh.
      </p>

      <p>
        <strong>Company, Location — Job Title</strong><br />
        MONTH 20XX - MONTH 20XX<br />
        Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh.
      </p>

      <p>
        <strong>Company, Location — Job Title</strong><br />
        MONTH 20XX - MONTH 20XX<br />
        Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh.
      </p>
    </div>

    <div class="section" style="margin-top: 20px">
      <h3>EDUCATION</h3>
      <p>
        <strong>School Name, Location — Degree</strong><br />
        MONTH 20XX - MONTH 20XX<br />
        Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore.
      </p>

      <p>
        <strong>School Name, Location — Degree</strong><br />
        MONTH 20XX - MONTH 20XX<br />
        Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh.
      </p>
    </div>

    <div class="section" style="margin-top: 20px">
      <h3>PROJECTS</h3>
      <p>
        <strong>Project Name — Detail</strong><br />
        Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
      </p>
    </div>

    <div class="section" style="margin-top: 20px">
      <h3>SKILLS</h3>
      <ul>
        <li>Lorem ipsum dolor sit amet.</li>
        <li>Consectetuer adipiscing elit.</li>
        <li>Sed diam nonummy nibh euismod tincidunt.</li>
        <li>L​​​‌​aoreet dolore magna aliquam erat volutpat.</li>
      </ul>
    </div>

    <div class="section" style="margin-top: 20px">
      <h3>AWARDS</h3>
      <p>
        <span>Lorem ipsum dolor sit amet Consectetuer adipiscing elit, Sed diam nonummy Nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.</span>
      </p>

      <p>
        <span>Lorem ipsum dolor sit amet Consectetuer adipiscing elit, Sed diam nonummy Nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.</span>
      </p>
    </div>

    <div class="section" style="margin-top: 20px">
      <h3>LANGUAGES</h3>
      <p>Lorem ipsum, Dolor sit amet, Consectetuer</p>
    </div>  <div>`
    },
    {
      name: "Essay",
      data: `<div >



      <h3>Introduction</h3>

      <p>
        Your essay begins with your introduction. It is important to capture your reader’s interest, so make sure you have an attention-grabbing hook. Your introduction is a paragraph that provides your reader with the relevant general background information on your topic. Using this context to ease your reader into the subject, you should then state your thesis statement. This is the main focus of your essay. A thesis statement is not a fact but something that can be discussed and analyzed. After stating your thesis, you should include an organizational statement. This functions as a roadmap that introduces the points you will make to support your thesis in the order in which they will appear in your body paragraphs.
      </p>

      <br />

      <h3>Body Paragraphs:</h3>

      <p>
        Body paragraphs are the bulk of your essay that provides substance to your argument with core analysis. These paragraphs are composed of points to support your thesis statement. It is helpful to separate your main claims into different paragraphs, making each claim a topic sentence. A topic sentence begins a body paragraph, introducing what your paragraph will be about. You must provide evidence (such as researched quotes or statistics) to back up and explore your topic sentence. You must provide the appropriate references for any evidence you cite. The evidence provided must be analyzed to explain how this idea proves or backs up your thesis. It is important that everything in your essay should be relevant to your thesis. This link is made at the end of the paragraph, tying back to the thesis statement and leading into the next point as a transition sentence. It is important to cite and use transition words in your essay to help create a smooth flow of your ideas. You may have as many body paragraphs as you’d like, but each body paragraph will follow the same format (beginning with a topic sentence, providing evidence and analysis to back up this claim, before linking your idea with your thesis statement).
      </p>

      <br />

      <h3>Conclusion</h3>

      <p>
        Your conclusion is the wrap-up of your essay. Here you must summarize the main points of your essay. You are to review your topic sentences and main argument, but you must not introduce any new ideas to your essay at this stage. Instead, re-emphasize your thesis statement and how your evidence and analysis support your points. In your concluding thoughts, you could appeal to the readers to see that you have come to a logical conclusion. You could also show why your argument matters, or simply end your essay with a memorable final statement.
      </p>

      <br />

      <h3>References:</h3>

      <p>
        If you have included evidence via in-text citations in your essay, you must cite it fully in a References section at the end of your essay. It is recommended to use either APA or MLA citations.
      </p>

      <br />
      <br />

      <h3>Structure:</h3>

      <p>An essay can be broken down into three main sections.</p>

      <br />

      <h3>Introduction</h3>

      <ul>
        <li>Background information</li>
        <li>Thesis statement</li>
        <li>Organizational statement/roadmap</li>
      </ul>

      <br />

      <h3>Body Paragraphs (repeat as many times as needed)</h3>

      <ul>
        <li>Topic Sentence</li>
        <li>Evidence</li>
        <li>Analysis</li>
        <li>Link to the thesis statement</li>
      </ul>

      <br />

      <h3>Conclusion</h3>

      <ul>
        <li>To sum up the main points,</li>
        <li>Then tie it back to the thesis statement.</li>
        <li>Final thoughts</li>
      </ul>

      <br />

      <p>
        If needed, include a references section at the end for any works cited.
      </p>



      </div>`
    },
    {
      name: "Business Letter",
      data: `<div style={{margin:"10px"}}>

      <h3>Your Company's Letterhead</h3>
      <p>
        <span>If applicable, include your company's letterhead at the top of the letter, which typically features the company's logo, name, address, and contact information.</span>
      </p>

      <h3>Date</h3>
      <p>
        <span>Below the letterhead or your address, add the current date. Format it as Month Day, Year (e.g., August 15, 2023).</span>
      </p>

      <h3>Recipient's Address</h3>
      <p>
        <span>Leave a few lines and then include the recipient's name, title, company name, street address, city, and postal code.</span>
      </p>

      <h3>Salutation</h3>
      <p>
        <span>Start with a formal salutation, addressing the recipient with their appropriate title and last name (e.g., 'Dear Mr. Smith,').</span>
      </p>

      <h3>Body of the Letter</h3>
      <p>
        <span>In the body of the letter, maintain a professional and formal tone. Address the purpose of the letter, provide details, and communicate your message clearly.</span>
      </p>

      <h3>Closing</h3>
      <p>
        <span>Close the letter with a formal closing (e.g., 'Sincerely,' 'Yours faithfully,') followed by your full name and title.</span>
      </p>

      <h3>Signature (If Applicable)</h3>
      <p>
        <span>Leave space for your physical signature above your typed name. If the letter is being sent digitally, you can skip this step.</span>
      </p>

      <h3>Enclosures (If Applicable)</h3>
      <p>
        <span>If you're enclosing additional documents or materials, list them below your signature or closing.</span>
      </p>


      </div>
      `

    },
    {
      name: "Meeting-Notes",
      data: `


      <div  >
      <!-- Meeting Details -->
<h3>Meeting Date:</h3>
<p>[Insert Meeting Date]</p>

<h3>Time:</h3>
<p>[Insert Meeting Time]</p>

<h3>Location:</h3>
<p>[Insert Meeting Location, if applicable]</p>

<!-- List of Attendees -->
<h3>List of Attendees:</h3>
<p>
  Attendant's name<br />
  Attendant's name<br />
  Attendant's name
</p>

<!-- Agenda -->
<h3>Agenda:</h3>
<p>
  <em>Topic 1:</em> [Insert Topic 1]<br />
  <em>Summary:</em> [Insert Summary]<br />
  <em>Discussion:</em> [Insert Discussion]<br />
  <em>Decisions:</em> [Insert Decisions]<br />

  <em>Topic 2:</em> [Insert Topic 2]<br />
  <em>Summary:</em> [Insert Summary]<br />
  <em>Discussion:</em> [Insert Discussion]<br />
  <em>Decisions:</em> [Insert Decisions]<br />

  <em>Topic 3:</em> [Insert Topic 3]<br />
  <em>Summary:</em> [Insert Summary]<br />
  <em>Discussion:</em> [Insert Discussion]<br />
  <em>Decisions:</em> [Insert Decisions]
</p>

<!-- Discussion and Decisions -->
<h3>Discussion and Decisions:</h3>
<p>
  Provide a summary of the discussions for each agenda item. Include key points, viewpoints, and decisions made.
</p>

<!-- Action Items -->
<h3>Action Items:</h3>
<p>
  - [Action Item 1: Description, Assigned to, Due Date]<br />
  - [Action Item 2: Description, Assigned to, Due Date]<br />
  - [Action Item 3: Description, Assigned to, Due Date]
</p>

<!-- Next Steps -->
<h3>Next Steps:</h3>
<p>
  Outline the next steps to be taken after the meeting, including tasks, responsibilities, and timelines.
</p>

<!-- Open Issues -->
<h3>Open Issues:</h3>
<p>
  Note any issues or topics that require further discussion or resolution. Specify who will address these issues and when.
</p>

<!-- Additional Notes -->
<h3>Additional Notes:</h3>
<p>
  Include any additional notes, comments, or observations relevant to the meeting.
</p>

<!-- Upcoming Meetings -->
<h3>Upcoming Meetings:</h3>
<p>
  Mention the dates and times of upcoming meetings related to the discussed topics, if applicable.
</p>

      </div>

      `
    },
    {
      name: "Article",
      data: `

      <div style={{margin:"10px"}}>
      <!-- Introduction -->
<h3>Introduction</h3>
<p>
  The introduction should engage the reader and provide an overview of the article's main topic. Start with a hook, present the key issue, and state the purpose or thesis of the article.
</p>

<!-- Main Content -->
<h3>Main Content</h3>
<p>
  This section is the heart of your article. Organize the content based on the type of article you're writing:
</p>

<!-- News/Feature Article -->
<h3>News/Feature Article:</h3>
<p>
  Provide relevant facts, details, quotes, and anecdotes to present the story or topic.
</p>

<!-- Opinion/Editorial Article -->
<h3>Opinion/Editorial Article:</h3>
<p>
  Present your opinion, supported by logical reasoning, evidence, and examples.
</p>

<!-- Review Article -->
<h3>Review Article:</h3>
<p>
  Evaluate the subject of the review objectively, discussing its strengths, weaknesses, and overall quality.
</p>

<!-- Research/How-To Article -->
<h3>Research/How-To Article:</h3>
<p>
  Share research findings, instructions, tips, or information on the chosen topic.
</p>

<!-- Interview/Profile Article -->
<h3>Interview/Profile Article:</h3>
<p>
  Share insights from the interviewee or profiled individual, focusing on their experiences, expertise, and perspectives.
</p>

<!-- Conclusion -->
<h3>Conclusion</h3>
<p>
  Summarize the main points of the article and reiterate its significance. Conclude with a thought-provoking statement, a call to action, or a reflection on the topic discussed.
</p>

<!-- Additional Sections (Optional) -->
<h3>Additional Sections (Optional)</h3>
<p>
  Depending on the article type, you might include additional sections such as:
</p>

<!-- References/Citations -->
<h3>References/Citations:</h3>
<p>
  List sources cited or referenced in the article.
</p>

<!-- Author Bio -->
<h3>Author Bio:</h3>
<p>
  Provide a brief biography of the author, including relevant credentials.
</p>

<!-- Visuals -->
<h3>Visuals:</h3>
<p>
  Include images, graphs, charts, or infographics to enhance the content.
</p>

      </div>
      `
    },
    {
      name: "Blog",
      data: `

      <div style={{margin:"10px"}}>

      <h3>The Blog Post</h3>
      <p>
        This is a classic blog post template, often known as the 'Listicle'. It usually comprises a list of anywhere between 10–30 items that have a reason to be listed (such as tips, techniques, myths, strategies, etc. This type of list is usually used for displaying non-chronological information.
      </p>

      <!-- To use this template -->
      <h3>To use this template:</h3>
      <p>
        Your title should be numbered for the number of items listed in the article. There must be a specific benefit or purpose for your article to make it compelling. Your title should be attention-grabbing. Possible examples:
      </p>

      <!-- Title Examples -->
      <ul>
        <li>'X Important Tips to Achieve [Desired Outcome]'</li>
        <li>
          'X Reasons Why [Problem] Still Persists Despite All Your Attempts'
        </li>
        <li>'X Myths You Need to Stop Believing'</li>
      </ul>

      <!-- Introduction -->
      <h3>Introduction</h3>
      <p>
        Your article should begin by establishing trust with the reader by introducing the problem the reader has and proving the benefit the reader will get from reading your article. Your introduction should be as short as possible. It is advised to include a linked table of contents with jump links so readers can skip right to the tip that interests them if they would like to.
      </p>

      <!-- Subheadings -->
      <h3>Subheadings</h3>
      <p>
        These are important to give readers an overview of what that specific section is about. They also break up your content into manageable chunks. Subheadings should be descriptive and reveal a clear benefit. This will boost the perceived value of each section to help grab readers’ attention to read the section. It is up to you to choose whether or not to number your subheadings. Subheadings should be in head level 2 format.
      </p>

      <!-- Action Items -->
      <h3>Action Items</h3>
      <p>
        Action items are sometimes overlooked in how important they can be. These are the steps a reader will have to take to complete or achieve each item on your list. Including action items is simple: just include the step-by-step information to help readers follow your steps. Often it is helpful to include photos or video visuals.
      </p>

      <!-- Conclusion -->
      <h3>Conclusion</h3>
      <p>
        It is important to wrap up your blog post. You can decide how you want to do this, but most lists end by listing a few more final tips. Your conclusion should leave readers wanting to actually use the information they have received from your blog. You can also encourage them to interact with your post, such as liking, sharing, and commenting.
      </p>

      </div>
      `
    },
    {
      name: "Appendix",
      data: `
      <div style={{ margin: "50px", backgroundColor: "violet" }}>


      <!-- Appendix A: Protocol -->
      <h3>Appendix A: Protocol</h3>
      <p>
        This example shows how you could format a protocol for your research paper, thesis, or dissertation.
      </p>

      <!-- Materials -->
      <h3>Materials</h3>
      <p>
        The materials for this study can be subdivided into five different types:
      </p>
      <ol>
        <li>Sustained vowel [a:] by COPD speakers during exacerbation;</li>
        <li>Sustained vowel [a:] by COPD speakers during stable COPD;</li>
        <li>Storytelling of ‘The King’ by COPD speakers during exacerbation;</li>
        <li>Storytelling of ‘The King’ by COPD speakers in stable condition;</li>
        <li>Storytelling of ‘Mary and Adam’ by healthy speakers.</li>
      </ol>

      <!-- Type 1: Sustained vowels -->
      <h3>Type 1: Sustained vowels</h3>
      <p>
        The sustained vowels were recorded shortly before or after reading aloud. The King. The vowels were isolated and saved as a separate recording, but this type of speech did not require further annotation before analysis.</p>
        <h3>Type 1-4: Storytelling of COPD speakers and healthy speakers</h3>
      <p>
        Speech types 1-4 were prepared and annotated following the same steps:
      </p>
      <ul>
        <li>
          Each file was clipped to remove the instructions preceding the reading task and the commentary after the reading task.
        </li>
        <li>
          The files were then converted using Praat, changing the sample size from 44 kHz to 16 kHz to prepare the files for forced alignment.
        </li>
        <li>
          The transcript of each file was added in the first interval tier ‘transcript’ to prepare the files for forced alignment.
        </li>
        <li>
          Each file was processed using the forced aligner according to its manual (Xue, De Reus, & Merkus, 2019; see Appendix D), creating two additional interval tiers ‘wordsegmentation’ and ‘phoneticsegmentation’ containing the segmentations of the transcripts on word and phoneme level (in SAMPA).
        </li>
        <li>
          These segmentations on both levels were then manually checked and corrected.
        </li>
        <li>
          After finalizing the segmentation process, three more tiers were manually added to each file for the annotation process. The first (interval) tier ‘respiratory’ was added for respiratory-related annotations, such as ‘inhalation’, ‘exhalation’, ‘pause’. This way, information about the respiratory pattern could be obtained.
        </li>
        <li>
          The second (point) tier ‘speakernoise’ was added for voice quality-related annotations, such as ‘cough’, ‘creaky’, ‘slimy’, to obtain information regarding the voice quality of the speaker. A point tier was preferred over an interval tier because the study focused on the number of occurrences.
        </li>
        <li>
          The third (interval) tier ‘commentary’ was added to clarify the speaker noises or to keep a record of additional observations. The respiratory tier and the speakernoise tier were annotated separately.
        </li>
        <li>
          The first tier to be annotated was the respiratory tier. Each silence interval was studied to determine if the speaker was inhaling (in), exhaling (ex), or if there was an audible pause (p). This way, the transcript could be subdivided into different ‘breathing groups’. The commentary tier was used to specify the annotations.
        </li>
        <li>
The second tier to be annotated was the speakernoise tier. The fragment was replayed to obtain information about the condition of the voice. Audible disturbances, such as hoarseness, creakiness, and sliminess of the voice, were addressed using the speakernoise tier to indicate the occurrence, and the commentary tier to describe the nature of the disturbance.
        </li>
        <li>
          The fragment was then replayed a final time to check the annotations.
        </li>
      </ul>

      <!-- Appendix B: Survey Questions -->
      <h2>Appendix B: Survey Questions</h2>
      <p>
        This example shows how you could format survey questions for your research paper, thesis, or dissertation.
      </p>

      <!-- General questions -->
      <h3>General questions</h3>
      <ol>
        <li>What is your age?</li>
        <li>
          What is the gender you identify with?
          <ul>
          <li></li>
            <li>a) Female</li>
            <li>b) Male</li>
            <li>c) Non-binary</li>
            <li>d) Other</li>
            <li>e) Don’t want to say</li>
          </ul>
        </li>
        <li>
          What is the highest degree or level of school you have completed? If currently enrolled, the highest degree received.
          <ul>
          <li></li>
            <li>a) ...</li>
            <li>b) ....</li>
            <li>c) ...</li>
          </ul>
        </li>
      </ol>



      <!-- Appendix C: Interview Transcript 1 -->
      <h2>Appendix C: Interview Transcript 1</h2>
      <p>
        Interviewer: Thank you very much for your time today and for agreeing to participate in this interview. May I have your consent to video-record?
      </p>
      <p>Participant 1: Yes.</p>
      <p>Interviewer: Great, thank you. I’ll begin the recording now.</p>
      <p>Participant 1: Sounds good.</p>
      <p>
        Interviewer: Okay, we’re ready to get started. My first question is about what you would say your motivation was when you first decided to volunteer for the Army.
      </p>
      <p>
        Participant 1: I wouldn’t say I had motivation, I was against the war. I was drafted, which meant that I had to go regardless of if I wanted to. I had recently gotten married to my first wife and was enjoying helping my dad out at the family hardware store, but when my number got called up I had to go.
      </p>

      <!-- Appendix C: Interview Transcript 2 -->
      <h2>Appendix C: Interview Transcript 2</h2>
      <p>
        Interviewer: Thank you very much for your time today and for agreeing to participate in this interview. May I have your consent to video-record?
      </p>
      <p>Participant 2: I would prefer that you only audio-recorded.</p>
      <p>
        Interviewer: Okay, no problem, look, you can see here I am just pressing audio, not video.
      </p>
      <p>Participant 2: I appreciate that.</p>
      <p>
        Interviewer: Okay, so let’s get started. My first question is about what you would say your motivation was when you first decided to join the Army.
      </p>
      <p>
        Participant 2: Well, the war in Vietnam had just started, and my twin brother and I were freshmen in college at the time. We decided to join on our own terms, rather than wait to be drafted. He ended up in the Airborne Division, while I ended up in the Cavalry Division.
      </p>


      </div>
      `
    }

  ]


  useEffect(() => {
    console.log("CAAAAAAAAAAAAA    SELECETD TEMP NAME")
    console.log("selectedTemplateName_", selectedTemplateName);
    if (selectedTemplateName !== undefined && selectedTemplateName !== "") {
      const templateValue = templateData.find((data) => data.name === selectedTemplateName);
      console.log("TemplateValue_", templateValue)
      editor.current.selection.insertHTML(templateValue.data);
      // if (templateValue) {
      //   const blocksFromHTML = convertFromHTML(templateValue.data);
      //   // const contentState = ContentState.createFromBlockArray(
      //   //   blocksFromHTML.contentBlocks,
      //   //   blocksFromHTML.entityMap
      //   // );
      //   // const newEditorState = EditorState.createWithContent(contentState);
      //   // setEditorState(newEditorState);
      //   // setEditorContent(blocksFromHTML)
      // }
    }
  }, [selectedTemplateName]);




  // useEffect(() => {
  //   console.log("selectedTemplateName_", selectedTemplateName);

  //   if (selectedTemplateName !== undefined && selectedTemplateName !== "") {
  //     const templateValue = templateData.find((data) => data.name === selectedTemplateName);

  //     if (templateValue) {
  //       const blocksFromHTML = convertFromHTML(templateValue.data);

  //       // Apply the 'CENTER' style to all blocks
  //       const contentStateWithCenterStyle = blocksFromHTML.contentBlocks.reduce(
  //         (contentState, block) => {
  //           const contentStateWithStyle = Modifier.applyInlineStyle(
  //             contentState,
  //             block.range,
  //             'CENTER'
  //           );
  //           return contentStateWithStyle;
  //         },
  //         ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap)
  //       );

  //       const newEditorState = EditorState.createWithContent(contentStateWithCenterStyle);
  //       setEditorState(newEditorState);
  //     }
  //   }
  // }, [selectedTemplateName, templateData]);


  const handleTranslateClick = () => {
    if (editorContent !== "") {
      setShowTranslateModel(true);
      setShowTranslator1(true);
      setTrackIsModalOpen(true);
      dispatch(openLowerModalAndCloseLeftNavbar())
    } else {
      toast.warn("Please enter some text")
    }

    setShowSummariser1(false);
    setShowParaphraser1(false);

  };

  const handleCloseTranslate = () => {
    setShowTranslateModel(false);
    setTrackIsModalOpen(false);
    dispatch(mobileView ? closeLowerModal() : closeLowerModalAndOpenLeftNavbar())
  };

  useEffect(() => {

    console.log("CAAAAAAAAAAAAA    DOC ID")
    console.log("docID_", docId)
    if (docId !== "") {
      getDocById(docId); // Pass docId to the function
    }
  }, [docId]);

  useEffect(() => {
    dispatch(closeLowerModalAndOpenLeftNavbar())
  }, [])



  useEffect(() => {
    console.log("CAAAAAAAAAAAAA  VIEW ONLY ACCESS  ")
    if (viewOnlyAccess) {
      handleMenuItemClick("Viewing")
    } else {
      handleMenuItemClick("Editor View")
    }

  }, [viewOnlyAccess]);


  const getDocById = async (docId) => {
    try {
      //console.log(docId,"docidddddd")
      const response = await documentService.getDocumentbyId(docId);
      console.log("content_", response)
      if (response.status === 200) {
        console.log(response.data, "haaaaaaaaaaaaaaaaaaaaaaaa")
        const { documentName, onlyDocumentContent, readOnly, status, reviewStatus, tags } = response.data.document;
        console.log(readOnly, onlyDocumentContent, "haaaaaaaaa")
        console.log(reviewStatus, "reviewStatus")
        setTags(tags)
        setDocName(documentName)
        setViewOnlyAccess(readOnly)
        if (readOnly) {
          setSelectedEditOption(false)
        } else {
          setSelectedEditOption(true)
        }
        if (reviewStatus == true) {
          setClickSubmitForReview(true)
        }
        console.log(tags, "tags in get doc");
        if (documentName) {
          // const contentData = JSON.parse(onlyDocumentContent);
          // const textContent = contentData.blocks[0].text;
          const textContent = response.data.documentBody
          console.log("textContent_", textContent)
          // setEditorContent(textContent)


          if (editor.current) {
            // Access the editor instance using the current property of the ref
            const setContent = editor.current;

            // Clear the content using the setContent method of the editor instance
            setContent.setEditorValue(textContent);
            setCurrentEditorValue(textContent)
          }
          setIsTyping(false)
          // Set contentEditorState with only the text content
          // setEditorState(
          //   EditorState.createWithContent(convertFromRaw({ blocks: [{ text: textContent }], entityMap: {} }))
          // );
        }

        else {
          console.error("Error: Document name or content is missing in the response data", response.data);
        }

        console.log("content", editorState)
      } else {
        console.error("Error fetching data:", response.message);
      }
    } catch (error) {
      console.error("Error calling backend API:", error.message);
    }
  };



  const handleSummariserClick = () => {
    // grammarCheckSummarizer()
    // if (editorState.getCurrentContent().getPlainText() !== "") {
    if (editorContent !== "") {
      setShowTranslateModel(true);
      setShowSummariser1(true);
      setTrackIsModalOpen(true);
      dispatch(openLowerModalAndCloseLeftNavbar())
      if (callSummarise === false) {
        setCallSummarise(true)
      } else {
        setCallSummarise(false);
      }
    } else {
      toast.warn("Please enter text")

    }
    setShowTranslator1(false);
    setShowParaphraser1(false);
    console.log("summeriseeeeerrr callledddd")
  };


  const handleParaphraseClick = () => {
    // setFirstTime(1)
    // if (editorState.getCurrentContent().getPlainText() !== "") {
    if (editorContent !== "") {
      setShowTranslateModel(true);
      setShowParaphraser1(true);
      setTrackIsModalOpen(true);
      dispatch(openLowerModalAndCloseLeftNavbar())
      if (callGrammerCheck === false) {
        setCallGrammerCheck(true);
      } else {
        setCallGrammerCheck(false);
      }
    } else {
      toast.warn("Please enter text")
    }
    setShowTranslator1(false);
    setShowSummariser1(false);
  };







  const [switchStates, setSwitchStates] = useState({
    autoCorrect: false,
    inlineSuggestions: false,
    doubleClickThesaurus: false,
    suggestText: false,
    flares: false,
    grammaticalErrors: false,
    sentenceRewrites: false,
  });
  const [activeEditor, setActiveEditor] = useState(null);
  // const handleTranslateClick = () => {
  //   setShowRightSide(true);
  //   setActiveEditor('translator');
  // };
  // const handleParaphraseClick = () => {
  //   setShowRightSide(true);
  //   setActiveEditor('paraphrase');
  //   setSelectedTone("")
  //   grammarCheckForParaphrase()
  // };
  // const handleSummarizerClick = () => {
  //   setShowRightSide(true);
  //   setActiveEditor('summarize')
  //   grammarCheckSummarizer()
  // };

  const [dropdownValue, setDropdownValue] = useState('');

  const [selectedSentence, setSelectedSentence] = useState("")
  const [selectedWordCount, setSelectedWordCount] = useState(0)

  const [languageList, setLanguageList] = useState([
    {
      value: '',
      name: 'Select'
    },
    {
      "value": "English",
      "name": "English"
    },
    {
      "value": "Spanish",
      "name": "Spanish"
    },
    {
      "value": "German",
      "name": "German"
    },
    {
      "value": "Chinese",
      "name": "Chinese"
    },
    {
      "value": "Japanese",
      "name": "Japanese"
    },
    {
      "value": "Russian",
      "name": "Russian"
    },
    {
      "value": "Arabic",
      "name": "Arabic"
    },
    {
      "value": "French",
      "name": "French"
    },
    {
      "value": "Portuguese",
      "name": "Portuguese"
    }

  ]);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const handleLanguage = (e) => {
    console.log("eData_", e)
    let value = e?.target?.value
    console.log("eData_", e, value)

    setSelectedLanguage(value !== undefined ? value : e)
    setSecondaryEditorStateTranslator(EditorState.createEmpty())

  }




  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleShortcutClick = (event) => {
    setShortcutPopoverAnchor(event.currentTarget);
    setIsArrowClickedShort(!isArrowClickedShort);
  };

  const handleGrammarClick = (event) => {
    setGrammarPopoverAnchor(event.currentTarget);
    setIsArrowClickedGrammar(!isArrowClickedGrammar);
  };

  const handleClose1 = () => {
    setAnchorEl(null);


  };
  const handleClose = () => {
    setShortcutPopoverAnchor(null);
    setIsArrowClickedShort(false);
    setGrammarPopoverAnchor(null);
    setIsArrowClickedGrammar(false);

  };

  const open = Boolean(anchorEl);
  const shortcutPopoverOpen = Boolean(shortcutPopoverAnchor);
  const grammarPopoverOpen = Boolean(grammarPopoverAnchor);
  const id = open ? 'settings-popover' : undefined;
  const popoverWidth = 250;
  const shortcutPopoverId = shortcutPopoverOpen ? 'shortcut-popover' : undefined;
  const grammarPopoverId = grammarPopoverOpen ? 'grammar-popover' : undefined;


  // ------settingEnd------//

  // Undo redo Functionality
  const undoRedoPlugin = createUndoPlugin();
  const { UndoButton, RedoButton } = undoRedoPlugin;
  const undoredoPlugin = [undoRedoPlugin];


  const handleEditDropdownOpen = (event) => {
    setEditDropdown(event.currentTarget);
  };
  const handleEditDropdownClose = () => {
    setEditDropdown(null);
  };
  const handleMenuItemClick = (option) => {
    handleEditDropdownClose();
    if (option === 'Viewing') {
      setSelectedEditOption(false);
      setSelectedViewOption(true);
      dispatch(closeLeftNavbar())
    }
    else {
      setSelectedEditOption(true);
      dispatch(openLeftNavbar())
      setSelectedViewOption(false);
    }
  };
  // const handleEditItemClick = () => {
  //   setSelectedEditOption(true);
  //   setSelectedViewOption(false);
  // };

  // const handleViewItemClick = ()=>{
  //   setSelectedEditOption(false);
  //   setSelectedViewOption(true);
  // }

  const handleCustomPrompt = (e) => {
    console.log("handleCustomPrompt", e.target.value)
    let value = e.target.value
    setCustomPrompt(value)
  }
  // -----

  ////////////mainEditor icons

  function handleDeleteFormain() {
    // setEditorState(EditorState.createEmpty());
    console.log("delete clicl")
    if (editor.current) {
      // Access the editor instance using the current property of the ref
      const cleareditor = editor.current;

      // Clear the content using the setContent method of the editor instance
      cleareditor.setEditorValue('');
    }
    setEditorContent("");
    resetTranscript();

    dispatch(resetTemplateName(""))
  }


  const handleDownloadFormain = () => {
    // const contentState = convertFromRaw(JSON.parse(documentData.onlyDocumentContent));
    // const contentHTML = stateToHTML(contentState);

    const htmlString = `<!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
    </head>
    <body>
        <h3>${docName}</h3>
        <hr/>
        ${editorHtmlContent}
    </body>
    </html>`;

    asBlob(htmlString).then(docx => {
      saveAs(docx, `${docName}.docx`);
    });
  };

  function handleCopyFormain() {
    // let sentence = editorState.getCurrentContent().getPlainText()
    let sentence = editorContent
    if (sentence == "") {
      toast.warn(`You don't have text, to copy`);
      return;
    }
    navigator.clipboard.writeText(sentence).then(
      function () {
        console.log("Async: Copying to clipboard was successful!");
        if (sentence) toast.info("Your text Copied!");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  }


  ////// Paraphraser icons


  ///////////////////



  const handleOutputChange = (newEditorState) => {
    console.log("opChange_", newEditorState)
    // let value = e.target.value
    // setHistory([...history.slice(0, currentIndex + 1), value]);
    // setCurrentIndex(currentIndex + 1);
    setSecondaryEditorState(newEditorState)
  }
  // const handleTranslateIconClick = () => {
  //   // Set the state to show only the translation editor
  //   setShowSummarizer(false);
  //   setShowTranslationEditor(true);
  // };

  const handleTranslate = async () => {

    setLoading(true)
    try {
      // let input = editorState.getCurrentContent().getPlainText()
      let input = editorContent
      if (input === '') {
        toast.warn('Please enter text to translate');
        setLoading(false)
        return;
      }
      if (selectedLanguage == '') {
        toast.warn('Please Select the Language to translate');
        setLoading(false)
        return;
      }

      // console.log("SelectedLang_", selectedLanguage, selectedIndustry, selectedProfession)
      let data
      let userId = userID
      let isCoAuthor = true

      data = {
        text: `Please%20translate%20the%20following%20text%20to%20${selectedLanguage}%20-${input}`,
        userId: userID,
        isCoAuthor: true
      }

      const response = await checkTranslator(data)
      const translatedText = response?.data?.output;


      if (response.status == 400) {
        toast.warn(response.message)
        return
      }
      let textWithoutLeadingNewlines = translatedText.replace(/^\n+/, '');
      let wordLength = translatedText.split(/[ -]+/)
      // console.log("paraphraseResponse_", response, wordLength.length);

      // setOpWordsCount(wordLength.length)
      const contentState = ContentState.createFromText(textWithoutLeadingNewlines);
      const newEditorState = EditorState.createWithContent(contentState);
      setTranslatedText(translatedText);
      setSecondaryEditorStateTranslator(newEditorState)
      setLoading(false)
    } catch (error) {
      console.error('Error calling backend API:', error.message);
      toast.warn(error.message)
      setLoading(false)
    }
  };

  const handleOutputChangeSummarizer = (newEditorState) => {
    console.log("opChange_", newEditorState)
    // let value = e.target.value
    // setHistory([...history.slice(0, currentIndex + 1), value]);
    // setCurrentIndex(currentIndex + 1);
    setSecondaryEditorStateSummarizer(newEditorState)
  }
  const handleOutputChangeTranslator = (newEditorState) => {
    console.log("opChange_", newEditorState)
    // let value = e.target.value
    // setHistory([...history.slice(0, currentIndex + 1), value]);
    // setCurrentIndex(currentIndex + 1);
    setSecondaryEditorStateTranslator(newEditorState)
  }

  const handleEditorChange2 = (newEditorState) => {
    const contentState = newEditorState.getCurrentContent();
    const currentText = contentState.getPlainText();
    const wordCount = currentText.trim().split(/\s+/).length;

    if (wordCount <= maxWords) {
      setTotalWords(wordCount);
      setEditorState2(newEditorState);
    }
  };

  const handleBeforeInput = (chars, editorState) => {
    console.log(editorState, "handelbeforInput function data")
    const contentState = editorState.getCurrentContent();
    const currentText = contentState.getPlainText();
    const wordCount = currentText.trim().split(/\s+/).length;

    if (wordCount >= maxWords) {
      return 'handled';
    }
    return 'not-handled';
  };



  // Delete function for summarizer  editor
  const handleDeleteInSummarizer = ((data) => {
    // alert(data)
    if (data === true) {
      setSecondaryEditorStateSummarizer(EditorState.createEmpty())
    }
  })





  // const grammarCheckSummarizer = async (type) => {
  //   console.log("editorState_", editorState, editorState.getCurrentContent().getPlainText());
  //   let content = editorState.getCurrentContent();
  //   let plainText = content.getPlainText();
  //   let data = {
  //     // text: `Please improve paraphrasing for the provided text ${type !== undefined ? `like a ${type} tone` : ""} ${editorState.getCurrentContent().getPlainText()}`,
  //     text: plainText,
  //     content: "",
  //     prompts: "one",
  //   }
  //   setLoading(true)
  //   try {
  //     if (!editorState.getCurrentContent().getPlainText().trim()) {
  //       toast.warn("Please enter text")
  //     } else {
  //       let summarizerResponse = await getCheckSummariser(data)
  //       console.log("summarizerResponse", summarizerResponse)
  //       if (summarizerResponse.data !== "") {
  //         let responseOutput = summarizerResponse?.data?.output
  //         // let wordLength = responseOutput.split(" ")
  //         console.log("summarizerResponse_", responseOutput[0]);
  //         const contentState = ContentState.createFromText(responseOutput[0]);
  //         const newEditorState = EditorState.createWithContent(contentState);
  //         setSecondaryEditorStateSummarizer(newEditorState);
  //       }
  //     }
  //   }
  //   catch (e) {
  //     console.log("e", e)
  //   }
  //   setLoading(false)
  // }
  // ------------------------------------------
  // summarizer
  const handleSummeriseClick = async (type) => {
    console.log(editorState, "edit stateee");
    // if (editorState.getCurrentContent().getPlainText().split(/\s+/).length > 1500) {
    if (editorContent.split(/\s+/).length > 1500) {
      toast.warn("Words limit exceeded")
      return;
    }
    setLoading(true);
    try {
      // if (editorState.getCurrentContent().getPlainText().split(/\s+/).length - 1 == 0) {
      if (editorContent.split(/\s+/).length - 1 == 0) {
        toast.warn("Please enter text")
        return;
      }
      // else {
      //   // const contentState = editorState.getCurrentContent();
      //   // const plainText = contentState.getPlainText();
      //   const plainText = editorContent
      //   console.log(plainText, "plaintext");
      //   const data = {
      //     // text: `Please improve paraphrasing for the provided text ${type !== undefined ? `like a ${type} tone` : ""} ${editorState.getCurrentContent().getPlainText()}`,
      //     // text: `Please improve paraphrasing for the provided text like a one tone The advent of 5G technology has ushered in a new era in communication, promising unprecedented speed, reliability, and connectivity. This fifth-generation wireless technology represents a significant leap forward from its predecessor, 4G. With data transfer rates up to 100 times faster than 4G networks, 5G has the potential to revolutionize how we connect and communicate. Its low latency ensures minimal delays in data transmission, opening doors to applications such as augmented reality, virtual reality, and the Internet of Things.`,
      //     text: plainText,
      //     prompts: "one",
      //     content: "",
      //     userId: userID,
      //     isCoAuthor: true
      //   };

      //   //   if (customPrompt !== "") {
      //   //     data = {
      //   //         text: customPrompt,
      //   //         content: `Please improve paraphrasing for the provided text like a one tone The advent of 5G technology has ushered in a new era in communication, promising unprecedented speed, reliability, and connectivity. This fifth-generation wireless technology represents a significant leap forward from its predecessor, 4G. With data transfer rates up to 100 times faster than 4G networks, 5G has the potential to revolutionize how we connect and communicate. Its low latency ensures minimal delays in data transmission, opening doors to applications such as augmented reality, virtual reality, and the Internet of Things.`,
      //   //         prompts: "six"
      //   //     }
      //   // }


      //   const summarizerResponse = await getCheckSummariser(data);
      //   console.log("summarizerResponse", summarizerResponse);
      //   if (summarizerResponse.data !== "") {
      //     let responseOutput = summarizerResponse?.data?.output
      //     let wordLength = responseOutput.split(" ")
      //     console.log("summarizerResponse_", responseOutput, wordLength.length);
      //     const contentState = ContentState.createFromText(responseOutput);
      //     const newEditorState = EditorState.createWithContent(contentState);
      //     setSecondaryEditorStateSummarizer(newEditorState);
      //     setEditorStatePara(newEditorState.getCurrentContent().getPlainText())
      //     console.log(newEditorState, "new editor state");

      //     // Please generate the keywords based on the provided content
      //     let sendData2 = {
      //       // text: `Please improve paraphrasing for the provided text like a one tone The advent of 5G technology has ushered in a new era in communication, promising unprecedented speed, reliability, and connectivity. This fifth-generation wireless technology represents a significant leap forward from its predecessor, 4G. With data transfer rates up to 100 times faster than 4G networks, 5G has the potential to revolutionize how we connect and communicate. Its low latency ensures minimal delays in data transmission, opening doors to applications such as augmented reality, virtual reality, and the Internet of Things.`,
      //       text: plainText,
      //       prompts: "two",
      //       content: "",
      //       userId: userID,
      //       isCoAuthor: true
      //     }

      //     let generateKeywords = await getCheckSummariser(sendData2)
      //     console.log("generateKeywords", generateKeywords)
      //     if (generateKeywords.data !== "") {
      //       const text = generateKeywords.data.output;

      //       // Check if there is content before attempting to add bullets
      //       if (text.trim().length > 0) {
      //         // Extract sentences after dots
      //         const lines = text.split('\n');
      //         const filteredLines = lines.filter(line => !line.match(/^\s*\n\d+\.\s*/));

      //         const extractedSentences = filteredLines
      //           .map(line => line.split(/\.\s*/))
      //           .flat()
      //           .filter(Boolean);

      //         const cleanedArray = extractedSentences.filter((item, index) => index % 2 !== 0);

      //         const finalArray = cleanedArray.map(obj => ({ name: obj, value: obj }));

      //         setKeywords(finalArray);
      //         setIsVisible(!isCustom);
      //         setIsKeyword(true);
      //       } else {
      //         console.error('No content to add bullets');
      //       }
      //     }
      //   }
      // }
    } catch (error) {
      console.error('handleSummarizeClick', error);
    }
    setLoading(false);

  };

  // shorten/long
  const summarizeByPercentage = async (value) => {

    //summarize by percentage
    // four, five
    let promptValue = "four"
    let newValue
    if (value == 0) {
      // newValue = 0
      // promptValue = "four"
      handleSummeriseClick()
      return
    }
    else if (value < 60) {
      newValue = 100 - value
      promptValue = "four"
    }
    else if (value >= 60) {
      newValue = value
      promptValue = "five"
    }

    console.log("newValue", newValue)
    let sendData = {
      text: newValue.toString() + "%",
      // content: editorState.getCurrentContent().getPlainText(),
      content: editorContent,
      prompts: promptValue,
      userId: userID,
      isCoAuthor: true
    }
    console.log("sendData", sendData)
    setLoading(true)
    try {
      let response = await getCheckSummariser(sendData)
      console.log("summarizeByPercentage", response)
      let responseOutput = response?.data?.output
      const contentState = ContentState.createFromText(responseOutput);
      const newEditorState = EditorState.createWithContent(contentState);
      setSecondaryEditorStateSummarizer(newEditorState);
      setEditorStatePara(newEditorState.getCurrentContent().getPlainText())
      console.log(newEditorState, "new editor state");
    }
    catch (e) {
      console.log("summarizeByPercentageerror", e)
    }
    setLoading(false)
  }

  const handleSliderChange = (event, newValue) => {
    // Calculate the percentage
    const percentage = ((newValue - 0) / (100 - 0)) * 100;
    // Update the state
    setSliderValue(newValue);
    setSliderText(newValue >= 60 ? "Expand" : "Shorten")
    summarizeByPercentage(newValue)
    // Use the percentage as needed
    console.log('Percentage:', percentage);
  };


  // ----- delete ------
  const handleDeleteClick = () => {
    console.log("inside delete");
    setEditorState2(EditorState.createEmpty());
    setSecondaryEditorState(EditorState.createEmpty());
    setSecondaryEditorStateSummarizer(EditorState.createEmpty());
    setTotalWords(0);
    setSliderValue(0);
    setCustomPrompt("");
    setEditorStatePara("");
    // resetTranscript();
  };

  // ---- upload file ----

  const handleWordCountExceed = () => {
    if (!maxCountDisplayed) {
      toast.warn("Word count exceeded than 2500");
      setMaxCountDisplayed(true);
    }
  }

  const customBlockFn = (nodeName, node) => {
    if (nodeName === 'img' && node instanceof HTMLImageElement) {
      const entityConfig = {
        src: node.src,
        type: 'IMAGE',
        mutability: 'MUTABLE',
      };
      return {
        type: 'IMAGE',
        mutability: 'MUTABLE',
        data: {
          src: node.src,
          entityConfig: entityConfig,
        },
      };
    }
  };

  const blockRenderer = (block) => {
    if (block.getType() === 'atomic') {
      return {
        component: Media,
        editable: false
      }
    }
    return null;
  }

  const Media = (props) => {
    const entity = props.contentState.getEntity(props.block.getEntityAt(0));
    const { src } = entity.getData();
    const type = entity.getType();
    console.log("source____", src)
    console.log("type______", type)

    let media;
    if (type === 'IMAGE') {
      media = <img src={src} style={{ width: '100%', height: '500px' }}
        alt='Example' />;
    }
    console.log("media_____", media);
    return media;
  };

  useEffect(() => {
    console.log("convertedHtml_", convertedHtml)
    if (convertedHtml) {

      try {
        const blocksFromHTML = htmlToDraft(convertedHtml, customBlockFn);
        console.log("blocksFromHTML_", blocksFromHTML);
        // const newEditorState = EditorState.createWithContent(customContentStateConverter(
        //   ContentState.createFromBlockArray(
        //     blocksFromHTML.contentBlocks,
        //     blocksFromHTML.entityMap
        //   )
        // ))
        const { contentBlocks, entityMap } = blocksFromHTML;
        console.log("contentBlocks_", contentBlocks, entityMap);
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        console.log("contentState_", contentState);
        const newEditorState = EditorState.createWithContent(contentState);
        console.log("newEditorState_", newEditorState);
        setEditorState(newEditorState);
      } catch (error) {
        console.error("Error converting HTML:", error);
      }
    }
  }, [convertedHtml]);

  // const handleFileChange = async (e) => {
  //   console.log("inside upload",e.target.files[0]);

  //   const file = e.target.files[0];
  //   // if (file) {
  //   //   e.preventDefault();

  //   //   // Send the selected file to your server here
  //   //   const formData = new FormData();
  //   //   formData.append('file', file);

  //   //   const html = await mammoth.convertToHtml({ path: filePath.path });
  //   //   editor.current.selection.insertHTML(data.value);

  //   //   // fetch('http://localhost:3006/upload-text', {
  //   //   //   method: 'POST',
  //   //   //   body: formData,
  //   //   // })
  //   //   //   .then(response => response.json())
  //   //   //   .then(data => {
  //   //   //     console.log('Upload successful:', data);
  //   //   //     editor.current.selection.insertHTML(data.value);

  //   //   //     // Handle server response (e.g., display success message)
  //   //   //   })
  //   //   //   .catch(error => {
  //   //   //     console.error('Upload failed:', error);
  //   //   //     // Handle upload error
  //   //   //   });
  //   // }


  //   if (file) {
  //     mammoth.convertToHtml
  //       ({
  //         arrayBuffer: file
  //       }).then
  //       (
  //         (result) => {
  //           editor.current.selection.insertHTML(result.value);
  //           setConvertedHtml(result.value);
  //           setConversionMessages(result.messages);
  //         }).
  //       catch
  //       (
  //         (error) => {
  //           console.error('Error converting document:', error);
  //           setConversionMessages([{
  //             type: 'error',
  //             message: 'Error converting document'
  //           }]);
  //         });
  //   }
  // };

  const convertTextToHtml = (text) => {
    // Split the text into paragraphs based on line breaks
    const paragraphs = text.split('\n');

    // Map each paragraph to wrap it with <p> tags and handle line breaks
    const htmlContent = paragraphs.map((paragraph) => {
      // Replace consecutive line breaks with <br> tags within the paragraph
      const paragraphWithBreaks = paragraph.replace(/\n{2,}/g, '<br><br>');
      // Wrap the paragraph with <p> tags
      return `<p>${paragraphWithBreaks}</p>`;
    });

    // Join the paragraphs into a single HTML string
    return htmlContent.join('');
  };

  const handleFileChange = () => {
    // Create a hidden file input element
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.style.display = 'none';

    // Trigger click event on the file input
    fileInput.addEventListener('change', (event) => {
      const fileList = event.target.files;
      if (fileList.length > 0) {
        const file = fileList[0];
        if (file.type === "application/pdf") {
          // If the file is a PDF, show a toast message and return
          toast.warn("PDF format is not supported.");
          return;
        }
        // Do something with the selected file, for example, you can read its content
        const reader = new FileReader();
        reader.onload = (e) => {
          const fileContent = e.target.result;
          const fileName = file.name;
          let contentToSend;

          const text = fileContent.toString();
          console.log(text, fileName, "shaka")

          if (file.name.endsWith('.txt')) {
            // Convert text content to HTML format
            const htmlContent = convertTextToHtml(text);
            contentToSend = `<br>${htmlContent}`; // Start from a new line with extra space
            // Append the new content to the existing content in the editor
            setEditorContentUpload(editorHtmlContent + contentToSend);
        } else if (file.name.endsWith('.docx')) {
            const bodyContentRegex = /<body[^>]*>([\s\S]*?)<\/body>/i;
            const match = bodyContentRegex.exec(text);
            contentToSend = match ? `<br>${match[1]}` : ''; // Start from a new line with extra space
            // Append the new content to the existing content in the editor
           setEditorContentUpload(editorHtmlContent + contentToSend);
        } else {
            toast.warning("Please upload .txt or .docx file")
            return
          }

        };
        reader.readAsText(file);
      }
    });

    // Click on the file input programmatically
    document.body.appendChild(fileInput);
    fileInput.click();
    // Clean up after file selection
    document.body.removeChild(fileInput);
    handleCloseMenu()
  }


  // ---- download ----
  const handleDownload = () => {
    console.log("inside download");
    console.log(editorState2, "edit state");
    console.log(editorState2.getCurrentContent(), "get current content");
    console.log(secondaryeditorStateSummarizer.getCurrentContent().getPlainText(), "plain text");

    if (!secondaryeditorStateSummarizer.getCurrentContent().getPlainText()) {
      toast.warn(`You don't have text, to download`);
      return;
    }
    const blob = new Blob([secondaryeditorStateSummarizer.getCurrentContent().getPlainText()], { type: 'text/plain' });
    console.log(blob, "blob");
    const anchor = document.createElement('a');
    anchor.href = URL.createObjectURL(blob);
    anchor.download = 'text_content.doc';
    anchor.click();
    URL.revokeObjectURL(anchor.href);
  };

  //  ----- copy to clipboard ---

  const handleCopyEditor2 = () => {
    let sentence = secondaryeditorStateSummarizer.getCurrentContent().getPlainText()
    if (sentence == "") {
      toast.warn(`You don't have text, to copy`);
      return;
    }
    navigator.clipboard.writeText(sentence).then(
      function () {
        console.log("Async: Copying to clipboard was successful!");
        if (sentence) toast.info("Your text Copied!");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  }
  const handleCopyEditorTranslator = () => {
    let sentence = secondaryeditorStateTranslator.getCurrentContent().getPlainText()
    if (sentence == "") {
      toast.warn(`You don't have text, to copy`);
      return;
    }
    navigator.clipboard.writeText(sentence).then(
      function () {
        console.log("Async: Copying to clipboard was successful!");
        if (sentence) toast.info("Your text Copied!");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  }
  // -----  bullet ----

  const handleParagraph = () => {
    const contentState = ContentState.createFromText(editorStatePara);
    console.log("Paragraph text:", contentState.getPlainText());
    // Update the state or perform any other necessary actions with the content
    setSecondaryEditorStateSummarizer(EditorState.createWithContent(contentState));
  }; // working



  const handleLinkClick = (linkType) => {
    console.log("Inside handlelink", linkType);
    setActiveLink(linkType);
    if (linkType === "bulletPoints") {
      console.log("bullet selected");
      setAPIData(true)
      setIsBulletPoints(true)
      setIsParagraph(false)
    }
    else if (linkType === "paragraph") {
      setAPIData(false)
      setIsBulletPoints(false)
      setIsParagraph(true)
      setIsCustom(false)
      setIsKeyword(true)
      handleParagraph()
    }
    else if (linkType == "custom") {
      setIsBulletPoints(false)
      setIsParagraph(false)
      setIsCustom(true)
      setIsKeyword(false)
    }
  };

  const handleApplyBullets = () => {
    // setAPIData(true)
    // setAPIData(false)
    let hasText = editorState.getCurrentContent().hasText();
    if (!hasText) {
      toast.info("No content to add bullets")
      return
    }
    setIsBulletPoints(true)
    setIsParagraph(false)
    setIsCustom(false)
    setIsKeyword(true)

    // let text = editorState2.getCurrentContent().getPlainText();
    // let sentences = text.split('. ');
    // let newText = sentences.join('.\n');
    // let newContentState = ContentState.createFromText(newText);
    // let newEditorState = EditorState.createWithContent(newContentState);
    // setEditorState2(newEditorState)

    // Get the current text from the editor
    // let text = editorState.getCurrentContent().getPlainText();
    let text = editorContent

    // Remove all empty lines
    let lines = text.split('\n').filter(line => line.trim() !== '');
    let textWithoutEmptyLines = lines.join('\n');

    // Split the text at every full stop
    let sentences = textWithoutEmptyLines.split('.').filter(sentence => sentence.trim() !== '').map(sentence => sentence.replace(/\n/g, ''));

    // Add a newline after every sentence and join them back together
    let formattedText = sentences.join('.\n');

    // Create a new content state with the formatted text
    let newContent = ContentState.createFromText(formattedText);

    // Set the new content state in the editor
    // let newEditorState = EditorState.push(editorState2, newContent);
    let newEditorState = EditorState.createWithContent(newContent);
    let newEditorStateExt = newEditorState.getCurrentContent()
    console.log("newEditorStateExt", newEditorStateExt.getPlainText())
    let firstBlock = newEditorStateExt.getFirstBlock()
    let lastBlock = newEditorStateExt.getLastBlock()
    let firstBlockKey = firstBlock.getKey();
    let lastBlockKey = lastBlock.getKey();
    let firstBlockLength = firstBlock.getLength();
    let lastBlockLength = lastBlock.getLength();
    let selection = newEditorState.getSelection().merge({
      anchorKey: firstBlockKey,
      anchorOffset: 0,
      focusKey: lastBlockKey,
      focusOffset: lastBlockLength,
    })
    let selectedState = EditorState.forceSelection(newEditorState, selection)
    setParagraphState(selectedState)

    let selectionState = selectedState.getSelection();
    let contentState = selectedState.getCurrentContent();
    let currentBlock = contentState.getBlockForKey(selectionState.getStartKey());
    // Check if the selection is not collapsed and spans the entire block
    // if (!selectionState.isCollapsed() && selectionState.hasFocus &&
    //     selectionState.getStartOffset() === 0 && selectionState.getEndOffset() === currentBlock.getLength()) {

    // Toggle the block type to unordered-list-item
    let newEditorState2 = RichUtils.toggleBlockType(selectedState, 'unordered-list-item');
    setSecondaryEditorStateSummarizer(newEditorState2);
    // }

  }

  ////////////////    MIC FUNCTIONALITY   /////////////////////////////////////


  const handleMicClick = () => {
    if (SpeechRecognition.browserSupportsSpeechRecognition()) {
      if (isRecording) {
        SpeechRecognition.stopListening();
        // handleSpeechInput(transcript);
        resetTranscript()
        console.log('mictesting', transcript)
      } else {
        SpeechRecognition.startListening({ continuous: true });
      }
      setIsRecording(!isRecording);
    } else {
      toast.error("Speech recognition is not supported by your browser.");
    }
  };

  useEffect(() => {
    // Clean up speech recognition when component unmounts or when navigating away
    return () => {
      if (isRecording) {
        SpeechRecognition.stopListening();
        resetTranscript()
      }
    };
  }, [isRecording]);

  const handleTranscriptChange = () => {
    // This function will be called whenever the transcript changes
    if (transcript) {
      handleSpeechInput(transcript);
    }
  };

  const handleSpeechInput = (input) => {

    if (input.trim() !== '') {
      if (editor.current) {
        const presentContent = editor.current.value
        const processedHtmlContent = presentContent.replace(/<br\s*\/?>/gi, '\n').replace(/<div\s*\/?>/gi, '\n');
        const tempElement = document.createElement('div');
        tempElement.innerHTML = processedHtmlContent;
        const textContent = tempElement.innerText.trim();
        const content = `<p>${textContent} ${input}</p>`;
        setEditorContentUpload(content);
      }
    }
  };

  useEffect(() => {
    if (transcript.length > 2) {
      resetTranscript()
    }
  }, [transcript])

  useEffect(() => {

    console.log("CAAAAAAAAAAAAA    transcript, tractismodelopan")
    console.log(trackIsModelOpen, "ismodelOpen")
    console.log(isRecording, "isRecording");
    if (!trackIsModelOpen) {
      handleTranscriptChange();
      console.log(isModalOpen, "ismodelOpen")
    }
  }, [transcript, trackIsModelOpen]);



  ////////////////    MIC FUNCTIONALITY   /////////////////////////////////////


  // ----------------------------------

  const showRightSideOnce = () => {
    setShowRightSide(true);
    setShowSummarizer(true)


  };
  const showRightTranslator = () => {
    setShowRightSide(true);
    setShowTranslationEditor(true)
    handleTranslate()
  };

  const showRightSummarizer = () => {
    setShowRightSide(true);
    setShowSummarizer(false)

    handleSummeriseClick()
    handleLinkClick()
  }

  const toggleSummarizer = () => {
    setShowSummarizer(!showSummarizer);
  };


  const handleLefEditorChange = (newEditorState) => {
    selectText(newEditorState);

    if (!selectedViewoption) {

      let previous_Content = editorState.getCurrentContent().getPlainText();
      let current_Content = editorState.getCurrentContent().getPlainText();

      const wordCount = current_Content.trim().split(/\s+/).length;

      const isHighlighted = editorState.getSelection().isCollapsed();
      const currentContent = editorState.getCurrentContent();
      const currentSelection = editorState.getSelection();


      const isContentEmpty = editorState.getCurrentContent().getPlainText().trim() === "";
      setShowHintIcon(!isContentEmpty);


      if (!isHighlighted) {

        // Get the currently highlighted text
        const start = currentSelection.getStartOffset();
        const end = currentSelection.getEndOffset();
        const selectedText = currentContent.getBlockForKey(currentSelection.getStartKey()).getText().slice(start, end);

        console.log("inside highlight", selectedText)


        // Set the highlighted text as contentValue
        setContentValue(selectedText);
      } else {

        // No text is highlighted, set entire content as contentValue
        const contentText = currentContent.getPlainText();
        console.log("inside else", contentText)
        setContentValue(contentText);

      }
      // const newContentState = ContentState.createFromText(input);
      // const newEditorState = EditorState.createWithContent(newContentState);
      // setEditorState(newEditorState);

      // Check content change
      if (previous_Content !== current_Content) {
        setRightEditorText({ ...rightEditorText, isClear: true });

      }

      // Check word count
      if (wordCount <= maxWords) {
        if (previous_Content === "" || current_Content === "" || previous_Content === " " || current_Content === " ") {
          setTotalWords(0);
        } else {
          setTotalWords(wordCount);
        }
      }

      setEditorState(newEditorState);

    }


  };


  const handleTextEditor = (data, wordsCount, characterCount) => {

    const textData = `{\"blocks\":[{\"key\":\"9ukr0\",\"text\":\"${editorContent}\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}}],\"entityMap\":{}}`
    setCircularLoader(false)

    console.log("data___", data)
    const socketData = {
      documentName: docName,
      documentId: docId ? docId : newDocId,
      onlyDocumentContent: data,
      // onlyDocumentContentText: textData,
      formattedDocumentContent: data,
      wordsCount,
      characterCount,
      folder: folderId ? folderId : null,
      tags: tags
      //imageUrl: image,
    };
    if (clickState === "blog") {
      autoSaveBlogContent(socketData)
    } else {
      autoSaveContent(socketData)
    }



  };


  const autoSaveContent = async (data) => {

    try {
      // if (JSON.parse(data.onlyDocumentContent).blocks[0].text === "") {
      if (data.onlyDocumentContent == "" || data.documentName == "" || data.documentId == "") {
        return;
      }
      setLoading(true)
      const response = await documentService.autoSaveDocument(data);
      if (response.status === 200) {
        console.log('Auto save successful:', response);
        //toast.success("Document Saved");
        setIsSaving(true);
        setDocSaved(true);
        setCircularLoader(true)
        setMountedFromUpload(false)
      }
    } catch (error) {
      console.error('Error occurred during auto save:', error);
    } finally {
      setIsSaving(false);
      // if(sentenceResult.length>0){setLoading(false)}
      setLoading(false)
    }
  };

  const autoSaveContent22 = async (data) => {
    setLoading(true)

    try {
      // if (JSON.parse(data.onlyDocumentContent).blocks[0].text === "") {
      
      const response = await documentService.autoSaveDocument(data);
      if (response.status === 200) {
        console.log('Auto save successful:', response);
        //toast.success("Document Saved");
        setIsSaving(true);
        setDocSaved(true);
        setCircularLoader(true)
        setMountedFromUpload(false)
      }
    } catch (error) {
      console.error('Error occurred during auto save:', error);
    } finally {
      setIsSaving(false);
      setLoading(false)
    }
  };

  const autoSaveBlogContent = async (data) => {

    try {
      // if (JSON.parse(data.onlyDocumentContent).blocks[0].text === "") {
      if (data.onlyDocumentContent == "" || data.documentName == "" || data.documentId == "") {
        return;
      }
      console.log(data, "dataa from autosave blog");
      const response = await blogService.autoSaveBlog(data);
      if (response.status === 200) {
        console.log('Auto save bloggg successful:', response);
        //toast.success("Document Saved");
        setIsSaving(true);
        setBlogSaved(true);
        setCircularLoader(true)
        setMountedFromUpload(false)
      }
    } catch (error) {
      console.error('Error occurred during auto save:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const autoSaveBlogContent22 = async (data) => {

    try {
      // if (JSON.parse(data.onlyDocumentContent).blocks[0].text === "") {
      console.log(data, "dataa from autosave blog");
      const response = await blogService.autoSaveBlog(data);
      if (response.status === 200) {
        console.log('Auto save bloggg successful:', response);
        //toast.success("Document Saved");
        setIsSaving(true);
        setBlogSaved(true);
        setCircularLoader(true)
        setMountedFromUpload(false)
      }
    } catch (error) {
      console.error('Error occurred during auto save:', error);
    } finally {
      setIsSaving(false);
    }
  };


  const iconButtonStyle = {
    width: '36px',
    height: '36px',
    borderRadius: '0'
  };
  const iconButtonBorderStyle = {
    width: '36px',
    height: '36px',
    borderRadius: '0',
    borderLeft: '2px solid #ccc'
  };
  const disabledStyles = {
    opacity: 0.5,
    pointerEvents: "none",
    cursor: "not-allowed",
  };
  const handleReplace = () => {
    // Update the editorState with the translated text
    const newContentState = ContentState.createFromText(translatedText);
    const newEditorState = EditorState.createWithContent(newContentState);

    // Update the main editor state
    setEditorState(newEditorState);
  };



  const handleChildStateChange = (newChildState) => {
    // Handle the child state change in the parent component
    console.log("props received from translator: ", newChildState);
    const replaceText = editor.current;

    setEditorState(EditorState.createEmpty())
    setEditorContent("")

    try {
      if (newChildState) {
        if (typeof newChildState === 'string') {
          // If newChildState is already a plain text string
          // setEditorState(EditorState.createWithContent(ContentState.createFromText(newChildState)));
          setEditorContent(newChildState)
          replaceText.setEditorValue(newChildState);

        } else if (newChildState.getCurrentContent && typeof newChildState.getCurrentContent === 'function') {
          // If newChildState is an instance of EditorState
          // setEditorState(newChildState);
          setEditorContent(newChildState)
          replaceText.setEditorValue(newChildState);

        } else {
          console.error("Invalid newChildState type:", newChildState);
        }
      } else {
        // Handle the case when newChildState is null or undefined
        setEditorState(EditorState.createEmpty());
        setEditorContent("")
      }
    } catch (error) {
      console.error("Error handling child state change:", error);
    }
  };

  // select text for ai review
  // function selectText(newEditorState) {
  //   // setEditorState(EditorState.createEmpty())
  //   const selectionState = newEditorState.getSelection();
  //   // console.log("selectionState_",selectionState.getCurrentContent().getPlainText());
  //   if (!selectionState.isCollapsed()) {
  //     const anchorKey = selectionState.getAnchorKey();
  //     const currentContent = newEditorState.getCurrentContent();
  //     const currentContentBlock = currentContent.getBlockForKey(anchorKey);
  //     const start = selectionState.getStartOffset();
  //     const end = selectionState.getEndOffset();
  //     const selectedText = currentContentBlock.getText().slice(start, end);
  //     let wordCount = selectedText.trim().split(/\s+/).length;
  //     console.log('Selected Text:', selectedText);
  //     console.log('Selected Text word count:', wordCount);
  //     // if (wordCount < 50) {
  //     //   toast.warn(`Select atleast 50 words`);
  //     //   return;
  //     // }
  //     // if(wordCount == 0){
  //     //   setSelectedWordCount(0)
  //     // }else{
  //     //   setSelectedWordCount(wordCount)
  //     // }
  //     // console.log(wordCount,"countt");

  //     setSelectedSentence(selectedText)
  //     setSelectedWordCount(wordCount)
  //     // if (sourceCount !== 0) {
  //     //   setIsUniqueButton(true)
  //     // }
  //   }
  // }

  const handleBlur = () => {
    if (!selectedViewoption) {
      setSelectedSentence('');
      setSelectedWordCount(0);
    }
  };

  // const selectText = (newEditorState) => {
  //   const selectionState = newEditorState.getSelection();

  //   if (!selectionState.isCollapsed()) {
  //     const anchorKey = selectionState.getAnchorKey();
  //     const currentContent = newEditorState.getCurrentContent();
  //     const currentContentBlock = currentContent.getBlockForKey(anchorKey);
  //     const start = selectionState.getStartOffset();
  //     const end = selectionState.getEndOffset();
  //     const selectedText = currentContentBlock.getText().slice(start, end).trim();
  //     const wordCount = selectedText.split(/\s+/).filter((word) => word !== '').length;

  //     setSelectedSentence(selectedText);
  //     setSelectedWordCount(wordCount);
  //   }
  // };  /// working partially

  const selectText = (newEditorState) => {

    if (editor.current) {
      console.log("selectedText_", editor.current.selection)
      const selectedText = editor.current.selection?.sel.toString();
      const wordCount = selectedText?.split(/\s+/)?.filter((word) => word !== '').length;

      setSelectedSentence(selectedText);
      setSelectedWordCount(wordCount);
      console.log("selectedText", selectedText)
    }
    return

    const selectionState = newEditorState.getSelection();

    if (!selectionState.isCollapsed()) {
      const anchorKey = selectionState.getAnchorKey();
      const currentContent = newEditorState.getCurrentContent();
      const currentContentBlock = currentContent.getBlockForKey(anchorKey);
      const start = selectionState.getStartOffset();
      const end = selectionState.getEndOffset();
      const selectedText = currentContentBlock.getText().slice(start, end).trim();
      const wordCount = selectedText.split(/\s+/).filter((word) => word !== '').length;

      setSelectedSentence(selectedText);
      setSelectedWordCount(wordCount);
    } else {
      // Handle the case when the cursor is at the end of the text or paragraph
      const anchorKey = selectionState.getAnchorKey();
      const currentContent = newEditorState.getCurrentContent();
      const currentContentBlock = currentContent.getBlockForKey(anchorKey);
      const blockLength = currentContentBlock.getLength();

      if (selectionState.getStartOffset() === blockLength) {
        // Cursor is at the end of the block, consider it as a blank space
        setSelectedSentence('');
        setSelectedWordCount(0);
      }
    }
  };  // workingggg

  // const passNametoCoNewProject = (name) => {
  //   console.log(name, "name in parent ")
  //   if (name) {
  //     console.log((editorContent + name))
  //     // setLeftEditorText(editorContent + name)
  //     const newContentState = ContentState.createFromText(editorContent + name);
  //     const newEditorState = EditorState.createWithContent(newContentState);
  //     console.log(newEditorState,"newEditorState");
  //     setEditorState(newEditorState);
  //     // const newContentState = Modifier.insertText(
  //     //   editorState.getCurrentContent(),
  //     //   editorState.getSelection(),
  //     //   name
  //     // );
  //     // const newEditorState = EditorState.push(
  //     //   editorState,
  //     //   newContentState,
  //     //   "insert-AuthorName"
  //     // );

  //     // setEditorContent(newEditorState);

  //   }
  //   else {
  //     console.log('name not found')
  //   }
  // }

  const passNametoCoNewProject = (name) => {
    if (name) {
      // Concatenate the name with existing editor content
      const updatedContent = editorContent + ` - ${name}`;
      console.log(updatedContent, "updatedContent")
      // setEditorContent(updatedContent)
      setEditorContentUpload(updatedContent)

    } else {
      console.log('name not found');
    }
  };








  const handleInsertClick = (intext, id) => {
    // Get the current editor state
    const currentEditorState = editorState;

    let intextCitation = ` (${intext})`

    // Get the selection state
    const selection = currentEditorState.getSelection();

    // Create a ContentState with the inserted text
    const contentStateWithInsertion = Modifier.insertText(
      currentEditorState.getCurrentContent(),
      selection,
      intextCitation
    );

    // Create a new EditorState with the updated ContentState
    const newEditorState = EditorState.push(
      currentEditorState,
      contentStateWithInsertion,
      'insert-characters'
    );

    // Set the new EditorState
    setEditorState(newEditorState);
  };


  useEffect(() => {
    console.log("CAdocname", docName, docApiCalled, blogApiCalled, docId)
    // Check if the API has been called once and docName is not empty
    if (clickState === "blog") {
      if (docName !== "Untitled Blog" && docName !== "") {
        console.log("CAAAAAAAAAAAAA  inside if", docName)
        if (!docApiCalled && !docId && !blogApiCalled) {
          fetchData();
        } else {
          console.log("fetch else", docName)
          const [wordsCount, characterCount] = strCount(editorHtmlContent);
          handleTextEditor(editorHtmlContent, wordsCount, characterCount);

        }
      }
    }
    if (clickState === "document") {
      if (docName !== "Untitled Document" && docName !== "") {
        console.log("CAAAAAAAAAAAAA  inside if", docName)
        if (!docApiCalled && !docId && !blogApiCalled) {
          fetchData();
        } else {
          console.log("fetch else", docName)
          const [wordsCount, characterCount] = strCount(editorHtmlContent);
          handleTextEditor(editorHtmlContent, wordsCount, characterCount);

        }
      }
    }

  }, [docName, clickState]);

  const fetchData = async () => {
    try {
      console.log("CAAAAAAAAAAAAA  inside function")

      const data = { documentName: `${docName}`, documentType: clickState === "blog" ? "blog" : "document" };
      console.log(data, "dattaaa")
      if (clickState === "blog") {
        console.log(data, "data inside grammer check")
        const documentId = await blogService.saveBlogs(data);
        // Set apiCalled to true to prevent further API calls
        setNewDocId(documentId)
        setBlogApiCalled(true);
        handleTagGenerator(documentId, "blogs")
        setCircularLoader(true)
      } else {
        const documentId = await documentService.saveDocs(data);
        // Set apiCalled to true to prevent further API calls
        setNewDocId(documentId)
        setDocApiCalled(true);
        handleTagGenerator(documentId, "document")
        setCircularLoader(true)
      }
    } catch (error) {
      // Handle error
      console.error("Error occurred:", error);
    }
  };




  useEffect(() => {
    console.log("CAAAAAAAAAAAAA    editorState, docName")
    let typingTimer;
    setIsSuggestion(true)
    // Clear the previous typing timer
    clearTimeout(typingTimer);
    // Set a new timer to determine when the user has stopped typing
    typingTimer = setTimeout(handleStoppedTyping, 2000); // Adjust the duration as needed (e.g., 2000ms = 2 seconds)

    // Cleanup function
    return () => {
      clearTimeout(typingTimer); // Clear the timer on component unmount or re-render
    };
  }, [editorContent, docName, newDocId]);

  // Function to execute after user stops typing
  const handleStoppedTyping = () => {
    setIsTyping(false);
    // setLoading(true)

    const currentInputText = editorHtmlContent
    console.log("Parachanges_", currentInputText, isSuggestion);

    if (currentInputText === "" || !isTyping || !isSuggestion) {
      return;
    } else {
      setIsSuggestion(false)
      setDocContent(currentInputText)
      console.log("doc__", currentInputText)
      const [wordsCount, characterCount] = strCount(currentInputText);
      // let convertRaw = convertToRaw(editorContent);

      handleTextEditor(currentInputText, wordsCount, characterCount);
      setDocData(editorContent)
      setDocWords(wordsCount)
      setDocCharacters(characterCount)
      // var sentencesArray = editorContent.split('.');
      // let filtersentence = sentencesArray.filter((item) => { return item !== "" })
      // for (let i = 0; i < filtersentence.length; i++) {
      //   let firstSentence = filtersentence.slice(i, i + 2);
      //   console.log("firstSentence_", firstSentence.length)
      //   let mergesentence = (firstSentence.length > 1) ? firstSentence[0] + '.' + firstSentence[1] + '.' : firstSentence[0] + '.' + "This is the end of the content"
      //   finalArray.push(mergesentence)
      // }
      // console.log("finalArray_", finalArray, isSuggestion);
      // handleCheckGrammarFluency(editorContent)
      // finalArray.map((item, i) => {
      //   console.log("itemfinalarray_", i, item)
      //   checkGrammarWords(item, i)
      // })

    }
  };

  const grammarCheckClick = () => {
    handleCheckGrammarFluency(editorContent)
    const [wordsCount, characterCount] = strCount(editorHtmlContent);
      setDocData(editorContent)
      setDocWords(wordsCount)
      setDocCharacters(characterCount)
      var sentencesArray = editorContent.split('.');
      let filtersentence = sentencesArray.filter((item) => { return item !== "" })
      for (let i = 0; i < filtersentence.length; i++) {
        let firstSentence = filtersentence.slice(i, i + 2);
        console.log("firstSentence_", firstSentence.length)
        let mergesentence = (firstSentence.length > 1) ? firstSentence[0] + '.' + firstSentence[1] + '.' : firstSentence[0] + '.' + "This is the end of the content"
        finalArray.push(mergesentence)
      }
    console.log(finalArray,"kokoo")
    finalArray.map((item, i) => {
      checkGrammarWords(item, i)
    })
  }

  useEffect(()=>{
    if(sentenceResult?.length > 0){
      setLoading(false)
    }
  },[sentenceResult])

  const handleTagGenerator = async (id, type) => {
    let payload = {
      documentId: id,
      documentType: type,
    }
    const response = await documentService.generateAutoTag(payload);
    console.log("tagresponse_", response)
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
    console.log(isModalOpen, "ismodelopen");
    setTrackIsModalOpen(false)
    console.log(trackIsModelOpen, "trackIsModelopen");
  }

  const handleNewproject = (value) => {
    if (value === true) {
      setEditorContent("")
      setEditorState(EditorState.createEmpty())
      setIsModalOpen(false)
      setShowTranslateModel(false);
      setShowSummariser1(false);
      setShowParaphraser1(false)
    }
  }
  // const editorStyle = {
  //   transition: 'width 0.3s',
  //   width: trackIsModelOpen && rightModalOpen || lowerModelOpen ? '64%' : '90%',
  //   // width: trackIsModelOpen  ? '60%' : '90%',
  //   maxWidth: '90%',

  // };
  const editorStyle = {
    transition: 'width 0.3s',
    maxWidth: '100%',
    width: !mobileView ? (!selectedEditOption
      ? (!selectedEditOption ? '120%' : '100%')
      : (trackIsModelOpen && rightModalOpen || lowerModelOpen ? sentenceResult.length > 0 ? '100%' : '65%' : '100%')) : '100%',
    // height: !selectedEditOption ? "500px" : "100%",
    minHeight: '320px'
  };

  // const handleInsert= () => {
  // if(dummyMail.coldEmail !=="") {
  // // Assuming you are using Draft.js for the editor
  // const contentState =ContentState.createFromText(dummyMail.coldEmail);
  // const newEditorState =EditorState.createWithContent(contentState);
  // setEditorState(newEditorState);
  // }};

  const handleBackButton = () => {

    if (selectedEditOption || sharedDocument) {
      if (isCoAuthor) {
        history.push('/coauthor');
      } else {
        history.goBack();
      }
    }
    dispatch(resetTemplateName(""));
    dispatch(openLeftNavbar())
    handleMenuItemClick('Editor View')

  };

  const handleRemoveHighlight = () => {

    setRemoveHighlight(true)
  }

  const submitForReview = async () => {
    swal({
      title: 'Review Document',
      text: 'Submit your Document for Review ?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then(async (reviewDoc) => {
      console.log("DocID_", docId)
      if (reviewDoc) {
        let payload = {
          userId: userID,
          id: docId
        }
        try {
          const response = await reviewDocument(
            payload
          );
          if (response.status !== 200) {
            toast.error(response.message);
          } else {
            toast.info(response.message);
            setClickSubmitForReview(true)
          }
          // Perform any additional actions after submission
        } catch (error) {
          console.error('Error submitting for review:', error);
          toast.error('An error occurred while submitting for review');
        }
      }
    });
  };

  useEffect(() => {
    console.log(clickSubmitForReview, "clickSubmitForReview");
  }, [clickSubmitForReview]);
  const handleTipsAndUpdatesClick = (event) => {
    setAnchorElForHint(event.currentTarget);
    console.log("inside");
  };


  //hint
  const handleRewriteClick = async (text) => {
    try {
      const response = await myDocumentsNewProjectServices.getRewriteText(text);
      console.log(response.status);
      if (response.status === 200) {
        console.log("RESPONSEEEEEEEEEEEEE", response);
        setRewrittentext(response.rewrittenText.output[0]);
        setRewriteCard(true);
      } else {
        console.error("Error fetching rewritten text:", response.message);
      }
    } catch (error) {
      console.error("Error calling backend API:", error.message);
    }
  };

  const handleStatisticalDataClick = async (text) => {
    try {
      const response = await myDocumentsNewProjectServices.getStatisticalData(
        text
      );
      console.log(response.status);
      if (response.status === 200) {
        console.log("RESPONSEEEEEEEEEEEEE", response);
        setStatisticalData(response.statisticalData.output[0]);
        setStatisticalFactCard(true);
      } else {
        console.error("Error fetching rewritten text:", response.message);
      }
    } catch (error) {
      console.error("Error calling backend API:", error.message);
    }
  };

  const handleExplainClick = async (text) => {
    try {
      const response = await myDocumentsNewProjectServices.getExplanation(text);
      console.log(response.status);
      if (response.status === 200) {
        console.log("RESPONSEEEEEEEEEEEEE", response);
        setExplanationText(response.Explaination.output[0]);
        setExplainCard(true);
      } else {
        console.error("Error fetching rewritten text:", response.message);
      }
    } catch (error) {
      console.error("Error calling backend API:", error.message);
    }
  };
  const handleHintClick = (option) => {
    setSelectedOption(option);
    if (option === "rewrite") {
      handleRewriteClick(contentValue);
    }
    if (option === "statisticalFact") {
      handleStatisticalDataClick(contentValue);
    }
    if (option === "explain") {
      handleExplainClick(contentValue);
    }
    handleCloseMenu();
  };

  const handleCloseMenu = () => {
    setAnchorElForHint(null);
  };

  const handleCloseRewriteCard = () => {
    setRewriteCard(false);
  };

  const handleCloseStatisticalFactCard = () => {
    setStatisticalFactCard(false);
  };

  const handleCloseExplainCard = () => {
    setExplainCard(false);
  };
  const [previousContent, setPreviousContent] = useState("");

  useEffect(()=>{
    if(editorHtmlContent !== ""){
    const [wordsCount, characterCount] = strCount(editorHtmlContent);

    const socketData = {
      documentName: docName,
      documentId: docId ? docId : newDocId,
      onlyDocumentContent: editor.current.value,
      // onlyDocumentContentText: texteditorHtmlContent,
      formattedDocumentContent: editor.current.value,
      wordsCount,
      characterCount,
      folder: folderId ? folderId : null,
      tags: tags
      //imageUrl: image,
    };
   
      if (clickState === "blog") {
          autoSaveBlogContent22(socketData);
      } else {
          autoSaveContent22(socketData);
      }
  

  }

  },[imgInEditor])

  const handleChangeEditor = () => {
    if (editor.current) {
      setIsTyping(true);
      // setCircularLoader(false);
      selectText();
      const htmlContent = editor.current.value; // Access the HTML content
      console.log("htmlContent:", htmlContent);

      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlContent, 'text/html');

      // Count the number of <img> tags
      const imgTags = doc.getElementsByTagName('img');
      const imgCount = imgTags.length;

     
        setImgInEditor(imgCount)

      let wordCount = 0;
      let textContent = '';

      if (htmlContent.trim() !== '') {
        // Replace line breaks with newline characters
        const processedHtmlContent = htmlContent.replace(/<br\s*\/?>/gi, '\n').replace(/<div\s*\/?>/gi, '\n');

        // Count words
        const tempElement = document.createElement('div');
        tempElement.innerHTML = processedHtmlContent;
        textContent = tempElement.innerText.trim();
        wordCount = textContent.split(/\s+/).length;
      }

      if (wordCount > 2500) {
        // Undo the latest change to prevent new content addition
        editor.current.execCommand('undo'); // Undo the last action
        toast.warning("File content limit exceeded. Truncated content to 2500 words.");
      } else {
        // Update word count and editor content normally if limit not exceeded
        setTotalWords(wordCount);
        setEditorHtmlContent(htmlContent);
        setEditorContent(textContent);
      }
    }
  };




  // const handleChangeEditor = () => {
  //   if (editor.current) {
  //     setIsTyping(true)
  //     selectText()
  //     console.log("editor_", editor.current?.value);
  //     const htmlContent = editor.current.value; // Access the HTML content
  //     const tempElement = document.createElement('div');
  //     tempElement.innerHTML = htmlContent;
  //     // Extract text content
  //     const textContent = tempElement.textContent || tempElement.innerText;
  //     console.log("textContent", textContent);
  //     let wordCount
  //     if (textContent !== "") {
  //       wordCount = textContent.trim().split(/\s+/).length;
  //       setTotalWords(wordCount)
  //     } else {
  //       setTotalWords(0)
  //     }
  //     // const pContent = editor.current?.value.match(/<p>(.*?)<\/p>/)[1]; // Extract text in brackets
  //     // console.log("pContent__", pContent);
  //     setEditorContent(textContent)
  //     setEditorHtmlContent(htmlContent)
  //   }
  // }


  const checkGrammarWords = async (inputtext, index) => {
    setLoading(true)
    console.log("index_", index);
    let data = {
      text: inputtext
    }
    let indexValid = 0
    let grammarResponse = await checkGrammar(data)
    if (grammarResponse?.data == "" || grammarResponse?.data == null) {
      console.log("grammarResponse_", grammarResponse)
      setLoading(false)
      // setIsNull(1)
      // toast.warn("failed to check grammar")
      return
    }

    let responseOutput = grammarResponse?.data[0]
    // let responseOutput = "\n{\n  \"replacements\": [\n    {\n      \"value\": \"the\",\n      \"phrase\": \"The the\",\n      \"start_position\": 8,\n      \"corrective_text\": \"The\",\n      \"type_of_error\": \"grammatical\",\n      \"justification\": \"Removing redundant use of article 'the'.\"\n    },\n    {\n      \"value\": \"Sun\",\n      \"phrase\": \"sUn\",\n      \"start_position\": 22,\n      \"corrective_text\": \"Sun\",\n      \"type_of_error\": \"spelling\",\n      \"justification\": \"Correct spelling of 'Sun'.\"\n    },\n    {\n      \"value\": \"a\",\n      \"phrase\": \"A beautiful\",\n      \"start_position\": 43,\n      \"corrective_text\": \"a beautiful\",\n      \"type_of_error\": \"grammatical\",\n      \"justification\": \"Adding article 'a' before the adjective 'beautiful'.\"\n    }]}"
    // console.log("responseOutput_", responseOutput);
    let finaloutput = responseOutput.message.tool_calls[0].function.arguments
    console.log("trychangedJSon_", finaloutput, index)
    // if(index == indexValid){
    grammarCheckArray.push(finaloutput)
    console.log("length_", finalArray?.length, grammarCheckArray?.length)
    if (grammarCheckArray?.length === finalArray?.length) {
      console.log("changedJSon_", grammarCheckArray)
      const selection = window.getSelection();
      selection.removeAllRanges();
      setSentenceResult(grammarCheckArray)
    }
    // setGrammarCheckArray(finaloutput)
    // }

  }



  const handleCheckGrammarFluency = async (inputtext) => {
    let data = {
      text: inputtext
    }
    let checkGrammarFluencyResponse = await checkGrammarFluency(data)
    console.log("checkGrammarFluencyResponse_", checkGrammarFluencyResponse)
    setGrammarFluency(checkGrammarFluencyResponse)
  }

  //------- start modals ------

  const handleGoals = () => {
    setIsGoalsOpen(true)
  }

  const closeGoalsModal = () => {
    setIsGoalsOpen(false)
  }

  const handleAIassistant = () => {
    setIsAIOpen(true)
  }
  const closeAIAssistant = () => {
    setIsAIOpen(false)
  }
  const handleAudience = (e) => {
    console.log("handleAudience", e.target.id, audience)
    let audID = e.target.id
    let audienceButton
    setAudience(audID)
    // setAudience((preValue)=>({...preValue,id: !preValue[id]}))
    // setAudience({ ...audience, [audID]: !audience[audID] })
  }

  const handleDomain = (e) => {
    console.log("handleDomain", e.target.id, audience)
    let domainID = e.target.id
    setDomain(domainID)
  }

  const handleSetGoals = () => {
    console.log("handleSetGoals", audience, domain, showGoalsCheck)

    closeGoalsModal()
  }

  const handleShowGoalsCheck = () => {
    console.log("handleShowGoalsCheck")
    setShowGoalsCheck(!showGoalsCheck)
  }

  const handleFormality = (e) => {
    console.log("handleFormality", e.target.id, formality)
    let objID = e.target.id
    // setFormality({ ...formality, [objID]: !formality[objID] })
    setFormality(objID)
  }

  const handleTone = (e) => {
    console.log("handleTone", e.target.id, tone)
    let objID = e.target.id
    // setTone({ ...tone, [objID]: !tone[objID] })
    setTone(objID)
  }

  const handleProfession = (e) => {
    console.log("handleProfession", e)
    let value = e.target.value
    setProfession(value)
  }

  const updateAIAssistant = () => {
    console.log("updateAIAssistant", formality, tone, profession)
    closeAIAssistant()
  }
  // ------- end modals --------

  //for tags 
  const removeTag = (i) => {
    const newTags = [...tags];
    newTags.splice(i, 1);

    setTags(newTags);
    const data = {
      documentName: docName,
      documentId: docId ? docId : newDocId,
      onlyDocumentContent: editorContent,
      // onlyDocumentContentText: textData,
      formattedDocumentContent: editorContent,
      totalWords,
      folder: folderId ? folderId : null,
      tags: newTags
    };
    if (clickState === "blog") {
      autoSaveBlogContent(data)
    } else {
      autoSaveContent(data)
    }
  };

  const inputKeyDown = (e) => {
    console.log("inside input key down");
    const val = e.target.value.toLowerCase();
    if (e.key === "Enter" && val) {
      if (tags.find((tag) => tag.toLowerCase() === val.toLowerCase())) {
        return;
      }
      console.log(tags, "tags before");
      setTags([...tags, val]);
      console.log(tags, "tagsss")
      setIsTagsEnter(true)
      const data = {
        documentName: docName,
        documentId: docId ? docId : newDocId,
        onlyDocumentContent: editorContent,
        // onlyDocumentContentText: textData,
        formattedDocumentContent: editorContent,
        totalWords,
        folder: folderId ? folderId : null,
        tags: [...tags, val]
      };
      if (clickState === "blog") {
        autoSaveBlogContent(data)
      } else {
        autoSaveContent(data)
      }
      console.log(data, "dataaa");
    } else if (e.key === "Backspace" && !val) {
      removeTag(tags.length - 1);
    }
  };

  const focus = () => {
    if (!docName) {

    }
  };

  const handleAIReview = () => {
    setshowAireviewContent(true)
    setTrackIsModalOpen(true);
    dispatch(openRightModalAndCloseLeftNavbar());
  }

  const handleCloseModal = () => {
    setshowAireviewContent(false)
    dispatch(mobileView ? closeRightModal() : closeRightModalAndOpenLeftNavbar());
    setTrackIsModalOpen(false);
  }
  console.log(sharedDocument, "sharedDocument");
  return (
    <>

      {/* Coauthor calling */}
      {(selectedEditOption && isCoAuthor) && !mobileView &&
        <CoauthorModal trial={trial} userId={userId} state={editorContent} editorState={editorState} setEditorState={setEditorState} setState={setEditorContent} count={totalWords} onChange={handleChangeEditor} selectedEditorText={selectedSentence}
          wordCount={selectedWordCount} passNametoCoNewProject={passNametoCoNewProject} selectText={selectText}
          handleInsertClick={handleInsertClick}
          onModalOpen={() => setIsModalOpen(true)} onModalClose={handleModalClose} setTrackIsModalOpen={setTrackIsModalOpen}/>
      }

      {(rightModalOpen && (isGrammarCheck || showAireviewContent)) &&
        <div className="Serive_div" style={{ width: mobileView ? '65%' : '468px', overflow: "scroll", zIndex: '1000', height:'100vh', position: "absolute", top: "70px", right: "0px", backgroundColor: "white", border: '1px solid  grey', padding: "none" }}>
          <div style={{ display: 'flex', width: '100%', justifyContent: "space-between", alignItems: "center", border: "1px solid grey ", marginTop: "0px" }}>
            <div style={{ display: 'flex', marginBottom: "0px" }}>
              <div style={{ border: "1px solid grey", borderTop: 'none', borderBottom: 'none' }}>
                <IconButton
                  disableTouchRipple
                  style={{ backgroundColor: showAireviewContent ? 'transparent' : 'transparent', borderRadius: "0px", border: "none", outline: "none", display: 'flex', alignItems: "center" }}
                  onClick={() => handleAIReview()}>
                  <div style={{ color: 'green', display: 'flex', alignItems: "center" }}>
                    <img
                      src={aireviewicon}
                      alt="AI Review"
                      style={{ width: '28px' }}
                    />
                    {showAireviewContent &&
                      <h6 style={{ color: 'green', marginLeft: '2px', marginBottom: "0px" }}>AI Review</h6>
                    }
                    {
                      showGoals &&
                      <h6 style={{ color: 'green', marginLeft: '2px', marginBottom: "0px" }}>Goals</h6>
                    }
                  </div>
                </IconButton>
              </div>
            </div>
            <div onClick={handleCloseModal}>
              <CloseIcon style={{ cursor: "pointer" }} />
            </div>
          </div>
          {(showAireviewContent || isGrammarCheck) && <CoAuthor_Aireviewcomponent mobileView={mobileView} editorState={editorContent}
            count={totalWords} isGrammarCheck={isGrammarCheck}
            onChange={handleChangeEditor} selectedText={selectedSentence}
            selectedWordCount={selectedWordCount} selectText={selectText} userId = {userId} trial = {trial}/>}
        </div>
      }

      <CoAuthorLowerModel
        mobileView = {mobileView}
        showTranslateModel={showTranslateModel}
        handleCloseTranslate={handleCloseTranslate}

        editorState={editorContent}
        setEditorState={setEditorContent}

        translator={showTranslator1}
        setTranslator={setShowTranslator1}

        summariser={showSummariser1}
        setSummariser={setShowSummariser1}

        paraphraser={showParaphraser1}
        setParaphraser={setShowParaphraser1}

        callGrammerCheck={callGrammerCheck}
        setCallGrammerCheck={setCallGrammerCheck}

        callSummarise={callSummarise}
        setCallSummarise={setCallSummarise}

        onChildStateChange={handleChildStateChange}

        firstTime={firstTime}
        setFirstTime={setFirstTime}

        righteditorContent={secondaryeditorStateSummarizer}
        handleDeleteinSummarizer={handleDeleteInSummarizer}
        sharedState={sharedState}
        setTrackIsModalOpen={setTrackIsModalOpen}
      />
      {/* } */}

      <Loader loading={loading} />

      <div>
        {!isGrammarCheck && !trial && !mobileView &&
          <Button onClick={handleBackButton} variant="contained" color="primary" startIcon={<KeyboardBackspaceIcon />} style={{ marginRight: '7px', whiteSpace: 'nowrap', marginBottom: viewOnlyAccess ? "20px" : "opx" }}>
            Back
          </Button>
        }

{isGrammarCheck && trial &&
      <div className="col-10 row" style={{marginLeft:'10px', marginTop:'40px'}}>
        <HeadingB text="Grammar Check"></HeadingB>
      </div>
}

{isCoAuthor && trial &&
      <div className="col-10 row" style={{marginLeft:'10px', marginTop:'40px'}}>
        <HeadingB text="Co-Author"></HeadingB>
      </div>
}

        {!trial &&
          
          <Tags tags={tags} viewOnlyAccess={viewOnlyAccess} removeTags={removeTag} modalOpen={trackIsModelOpen} content={docName} istagsEnter={istagsEnter} setIsTagsEnter={setIsTagsEnter} onFocus={focus} onKeyDown={inputKeyDown} />
        }

      </div>
      {/* {selectedEditOption && */}

      <>
  

        <div className='d-flex' style={{marginLeft: trial ? '25px' : '0px'}}>
        <div 
  className={
    mobileView 
    ? 'col-md-12' 
    : sentenceResult?.length > 0 
      ? trackIsModelOpen 
        ? 'col-md-5' 
        : 'col-md-7' 
      : isCoAuthor || rightModalOpen || lowerModelOpen 
        ? 'col-md-11 p-0' 
        : 'col-md-10 p-0'
  } 
  style={{ paddingLeft: '0px', paddingRight: mobileView ? '0px' : '' }}
>
            <div style={{ ...editorStyle, minHeight: '90px' }}>
              <CoAutherTopMenubar mobileView={mobileView} grammarCheck = {grammarCheckClick} trial ={trial} handleMenuItemClick={handleMenuItemClick} handleDownload={handleDownloadFormain} editorHtmlContent={editorHtmlContent} setEditorContentUpload={setEditorContentUpload} removeHighlight={removeHighlight} setRemoveHighlight={setRemoveHighlight} circularLoader={circularLoader} setSharedState={setSharedState} setDocName={setDocName} docName={docName} setEditorState={setEditorContent} editorState={editorContent} onChange={handleLefEditorChange} newProject={handleNewproject} setTotalWords={setTotalWords} handleWordCountExceed={handleWordCountExceed} setInputKey={setInputKey} setMaxCountDisplayed={setMaxCountDisplayed} docId={docId} newDocId={newDocId} />
            </div>

          
          

            {/* Rich Text Editor start */}
            <div style={{ width: "100%", display: "flex", flexWrap: "wrap", gap: "2%", alignItems: "center" }}>
              {sharedDocument && !viewOnlyAccess && docId &&
                <div style={{
                  width: "auto", height: "43px", position: 'absolute', top: '86px',
                  right: !selectedEditOption
                    ? (!selectedEditOption ? '10px' : '5px')
                    : (trackIsModelOpen && rightModalOpen || lowerModelOpen ? sentenceResult.length > 0 ? '5px' : '34%' : '5px')
                }}>
                  <Button variant="outlined" style={{ marginRight: '15px' }} color="primary" onClick={submitForReview} disabled={clickSubmitForReview} >Submit for Review</Button>
                </div>}

              {docId && !sharedDocument &&
                <div style={{
                  marginTop: "20px", width: "97px", height: "43px", position: 'absolute', top: '68px',
                  right: !mobileView ? (!selectedEditOption
                    ? (!selectedEditOption ? '10px' : '5px')
                    : (trackIsModelOpen && rightModalOpen || lowerModelOpen ? sentenceResult.length > 0 ? '5px' : '34%' : '5px')) : '-15px'
                }}>
                  <Button
                    variant='outlined'
                    color='primary'
                    style={{width:"25px",marginLeft:"5px", fontSize: '10px', marginRight: '5px', backgroundColor: "white", padding: "8px" }}
                    onClick={handleEditDropdownOpen}
                  >
                    {selectedEditOption ? (
                      <>
                        <EditIcon style={{ fontSize: "15px", marginRight: '5px' }} /> Edit
                      </>
                    ) : (
                      <>
                        <VisibilityIcon style={{ fontSize: "15px", marginRight: '5px' }} /> View
                      </>
                    )}
                    <KeyboardArrowDownIcon style={{ fontSize: "15px", marginLeft: "4px" }} />
                  </Button>
                  {/* <Button onClick={handleInsert}>Insert</Button> */}
                  {/* Dropdown Menu */}
                  <Menu
                    anchorEl={editDropdown}
                    open={Boolean(editDropdown)}
                    onClose={handleEditDropdownClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    style={{ padding: "0px" }}
                  >
                    {/* Editor View Option */}
                    <MenuItem onClick={() => handleMenuItemClick("Editor View")}
                      style={{ backgroundColor: selectedEditOption === "Editor View" ? "#F3F4F9" : "transparent" }}>
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                        <ListItemIcon style={{ width: "15%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                          <EditIcon />
                        </ListItemIcon>
                        <div style={{ display: "flex", flexDirection: "column", width: "90%" }}>
                          <ListItemText primary="Editor View" style={{ fontWeight: "bolder", color: selectedEditOption === "Editor View" ? "#3f51b5" : "black" }} />
                          <ListItemText primary="Edit the project directly" style={{ color: "gray", fontSize: "14px" }} />
                        </div>
                        {selectedEditOption === "Editor View" && (
                          <ListItemIcon style={{ width: "15%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <CheckIcon />
                          </ListItemIcon>
                        )}
                      </div>
                    </MenuItem>


                    {/* Viewing Option */}
                    <MenuItem onClick={() => handleMenuItemClick("Viewing")}
                      style={{ backgroundColor: selectedEditOption === "Viewing" ? "#F3F4F9" : "transparent" }}>
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                        <ListItemIcon style={{ width: "15%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                          <RemoveRedEyeIcon />
                        </ListItemIcon>
                        <div style={{ display: "flex", flexDirection: "column", width: "90%" }}>
                          <ListItemText primary="Viewing" style={{ fontWeight: "bold", color: selectedEditOption === "Viewing" ? "#3f51b5" : "black" }} />
                          <ListItemText primary="Read or print the project" />
                        </div>
                        {selectedEditOption === "Viewing" && (
                          <ListItemIcon style={{ width: "15%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <CheckIcon />
                          </ListItemIcon>
                        )}
                      </div>
                    </MenuItem>
                  </Menu>
                </div>

              }
            </div>
            {/* <div style={{ ...editorStyle, overflowX: 'auto', position: "relative", boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.3)', padding: '20px 50px 20px 20px', borderRadius: '8px 8px 0px 0px', background: 'white', overflowY: 'auto' }}> */}
            {/* <Editor
                editorState={editorState}
                customStyleMap={styleMap()}
                onChange={handleLefEditorChange}
                handleBeforeInput={handleBeforeInput}
                placeholder="Please enter your text."
                readOnly={selectedViewoption}
                plugins={undoredoPlugin}
                onBlur={handleBlur}
                autoCapitalize='words' trackIsModelOpentrackIsModelOpen
                blockRendererFn={blockRenderer}
              //onFocus={handleRemoveHighlight}   SELECT ALL FUNCTIONALITY
              /> */}
            <div style={{ ...editorStyle }}>
              <JoditEditorComp editorHeight={350} onChange={handleChangeEditor} editorContent={editorContent} editor={editor} isEdit={selectedEditOption} setEditorContentUpload = {setEditorContentUpload} />
            </div>
            {/* <JoditEditor
                ref={editor}
                value={content}
                config={config}

                // onBlur={onBlur}
                // onBlur={(event) => handleUpdate(event)}
                // onBlur={newContent => setContent(newContent.target.value)}
                // onChange={content => handleChange(content)}
              /> */}

            {showHintIcon && (

              <IconButton
                onClick={handleTipsAndUpdatesClick}
                style={{ position: "absolute", top: "4%", right: "1%", zIndex: "4" }}
              >
                <TipsAndUpdatesIcon />
              </IconButton>
            )}
            <Menu
              anchorEl={anchorElForoHint}
              open={Boolean(anchorElForoHint)}
              onClose={handleCloseMenu}

            >
              <MenuItem
                onClick={() => handleHintClick("rewrite")}
                sx={{
                  fontSize: "14px",
                  textAlign: "center",
                  marginBottom: "8px",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div>
                    <strong>Rewrite</strong>
                  </div>
                  <div>
                    <p style={{ fontSize: "12px", margin: "0" }}>
                      Rewrite the sentence
                    </p>
                  </div>
                </div>
              </MenuItem>
              <MenuItem
                onClick={() => handleHintClick("statisticalFact")}
                sx={{
                  fontSize: "14px",
                  textAlign: "center",
                  marginBottom: "8px",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div>
                    <strong>Statistical Fact</strong>
                  </div>
                  <div>
                    <p style={{ fontSize: "12px", margin: "0" }}>
                      Add Quantitative data
                    </p>
                  </div>
                </div>
              </MenuItem>
              <MenuItem
                onClick={() => handleHintClick("explain")}
                sx={{
                  fontSize: "14px",
                  textAlign: "center",
                  marginBottom: "8px",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div>
                    <strong>Explain</strong>
                  </div>
                  <div>
                    <p style={{ fontSize: "12px", margin: "0" }}>
                      Add a detailed explanation
                    </p>
                  </div>
                </div>
              </MenuItem>
            </Menu>

            {/* </div> */}
            {rewriteCard && (
              <RewriteCard
                handleCloseRewriteCard={handleCloseRewriteCard}
                rewrittenText={rewrittenText}
                contentEditorState={editorState}
                setContentEditorState={setEditorState}
                contentValue={contentValue}
              />
            )}
            {statisticalFactCard && (
              <StatisticalFactCard
                handleCloseStatisticalFactCard={handleCloseStatisticalFactCard}
                statisticalData={statisticalData}
                contentEditorState={editorState}
                setContentEditorState={setEditorState}
              />
            )}
            {explainCard && (
              <ExplainCard
                handleCloseExplainCard={handleCloseExplainCard}
                explanationText={explanationText}
                contentEditorState={editorState}
                setContentEditorState={setEditorState}
              />
            )}
            {selectedEditOption &&
              <>

                <div className='d-flex' style={{ justifyContent: mobileView ? 'center' : 'space-between', flexWrap: "wrap", alignItems: 'center', gap: '5px', ...editorStyle, boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.3), 0 0 0 white', padding: '15px', borderRadius: '0px 0px 8px 8px', background: 'white', minHeight: '90px', maxHeight: '98px' }}>
                  <div className='px-2' style={{display: mobileView ? 'none' : ''}}>
                    <h6 style={{ color: '#1976D2', fontSize: '13px' }}>
                      <FiberManualRecordIcon fontSize='small' />
                      <b>  {totalWords} | 2500 words </b>
                    </h6>
                  </div>

                  {!trial &&

            
                  <div
                  // style={{position:"relative", right:"9%"}}
                  >
                    {/* <Button1
                      variant='outlined'
                      style={{ color: "black", fontSize: '12px', marginRight: '10px', borderRadius: '10px', borderColor: 'black', textTransform: 'capitalize', boxShadow: '3px 3px 8px rgba(0, 0, 0, 0.2)' }}
                      component="label" htmlFor="coAutherFileInput"
                    >
                      <UploadIcon /> Upload
                      <VisuallyHiddenInput
                        id="coAutherFileInput"
                        type="file"
                        key={inputKey}
                        onChange={handleFileChange}  // Directly call the function here
                      />

                    </Button1>  */}


                    <Button variant='outlined' style={{ fontSize: '12px', marginRight: '10px', borderRadius: '10px', color: '#1976D2', borderColor: '#1976D2', textTransform: 'capitalize', boxShadow: '3px 3px 8px rgba(0, 0, 0, 0.2)' }} onClick={handleParaphraseClick}>
                      {/* <MenuBookIcon style={{ marginRight: '3px' }} />  */}
                      <img
                        src={paraphrase_logo}
                        alt="Paraphrase"
                        style={{ width: '21px' }}
                      />
                      Paraphrase
                    </Button>
                    <Button variant='outlined' style={{ fontSize: '12px', marginRight: '10px', borderRadius: '10px', color: '#1976D2', borderColor: '#1976D2', textTransform: 'capitalize', boxShadow: '3px 3px 8px rgba(0, 0, 0, 0.2)' }} onClick={handleSummariserClick}>
                      {/* <ArticleIcon /> */}
                      {/* <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18px"
                          height="18px" fill="currentColor"
                          viewBox="0 0 14 14"
                          style={{ marginRight: '3px' }}
                        >
                          <path d="M8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783" />
                        </svg> */}
                      <FaBookOpen
                        style={{ marginRight: '3px', width: '18px', height: '18px' }}
                      />
                      Summarize
                    </Button>
                  </div>
}
                  <div className='buttons-div' style={{width: mobileView ? '100%' : '', display:'flex', justifyContent:'space-between', alignItems:'center'}} >                    

                    <div className='px-2' style={{display: !mobileView ? 'none' : ''}}>
                    <h6 style={{ color: '#1976D2', fontSize: '10px' }}>
                      <FiberManualRecordIcon fontSize='small' />
                      <b>  {totalWords} words </b>
                    </h6>
                  </div>

                  <div>

                    <CustomTooltip title="Delete" >
                      <IconButton title={"Delete"} style={{ fontSize: '8px', marginLeft: '2px', color: 'red', borderColor: 'white', width: mobileView ? '1px' : '' }} onClick={handleDeleteFormain}><DeleteIcon /></IconButton>
                    </CustomTooltip>


                    <CustomTooltip title="Upload" >
                      <IconButton
                        title={"Upload"} style={{ fontSize: '8px', marginLeft: '2px', color: 'indigo', borderColor: 'white', width: mobileView ? '1px' : '' }}
                        component="label" htmlFor="coAutherFileInput" onClick={() => handleFileChange()}
                      >
                        <UploadIcon />
                        {/* <VisuallyHiddenInput
                          id="coAutherFileInput"
                          type="file"
                          key={inputKey}
                          onClick={handleFileChange} // Directly call the function here
                        /> */}
                      </IconButton>
                    </CustomTooltip>

                    {!trial && docId &&

                    <CustomTooltip title="Download" >
                      <IconButton title={"Download"} style={{ fontSize: '8px', marginLeft: '2px', color: 'green', borderColor: 'white', width: mobileView ? '1px' : '' }} onClick={() => { openDownloadModal() }}><DownloadIcon /></IconButton>
                    </CustomTooltip>
}

                    <CustomTooltip title="Copy" >
                      <IconButton title={"Copy"} style={{ fontSize: '8px', marginLeft: '2px', color: 'blue', borderColor: 'white', width: mobileView ? '1px' : '' }} onClick={handleCopyFormain}><ContentCopyIcon /></IconButton>
                    </CustomTooltip>
                    {!trial &&
                    <CustomTooltip title="Translate" >
                      <IconButton title='Translate' style={{ fontSize: '8px', marginLeft: '2px', color: 'orange', borderColor: 'white', width: mobileView ? '1px' : '' }} onClick={handleTranslateClick}><TranslateIcon /></IconButton>
                    </CustomTooltip>
                    }
                    <CustomTooltip title="Mic" >
                      <IconButton title={"Mic"} style={{ fontSize: '8px', marginLeft: '2px', color: isRecording ? 'red' : 'grey', borderColor: 'white', width: mobileView ? '1px' : '' }} onClick={handleMicClick}><MicIcon /></IconButton>
                    </CustomTooltip>
                    {/* <IconButton title={"Remove"} style={{ fontSize: '8px', marginLeft: '2px', color: 'grey', borderColor: 'white' }}><RemoveIcon /></IconButton> */}

                    </div>
                  </div>





                </div>
                <DownloadOptionModal show={isDownloadModalOpen} onHide={closeDownloadModal} id={docId} />

                {/* <div><h6 style={{ marginTop: '15px' }}>
              <FiberManualRecordIcon fontSize='small' />1000 words limit per search |
              <b> Total words: {totalWords}</b>
            </h6></div> */}
              </>
            }
          </div>
          

          

         



          {sentenceResult?.length > 0 && !mobileView &&
            <div className='col-md-3 ' style={{ marginTop: '87px' }}>
              {/* {isFeatureButton &&  */}
              <>
                <div
                  // className=" pt-3"
                  style={{
                    fontFamily: 'Poppins, sans-serif',
                    fontWeight: 600,
                    color: "#1A1A1A",
                    fontSize: "18px",
                    marginBottom: '10px',
                    backgroundColor: 'white',
                    height: '45px',
                    padding: '7px',
                    borderRadius: '7px',
                    // display: 'flex',
                    // justifyContent: 'space-between'

                  }}
                >
                  <div>Suggestions</div>
                  <div style={{display:'flex', justifyContent:'flex-end', marginTop:'18px' , padding:'0px 5px'}}>
                  <div>
                    <button className="btn btn-primary" style={{ borderRadius: '15px', marginRight:'10px' }}
                      onClick={() => { setIgnoreAllClicked(true) }}
                    >Ignore All</button>
                  </div>

                  <div>
                    <button className="btn btn-primary" style={{ borderRadius: '15px' }}
                      onClick={() => { setAcceptAllClicked(true) }}
                    >Accept All</button>
                  </div>
                  </div>

                </div>

                <SuggestionComp autoSaveContent={autoSaveContent} docName = {docName} docId={docId} newDocId={newDocId} folderId={folderId} tags={tags} ignoreAllClicked={ignoreAllClicked} setIgnoreAllClicked={setIgnoreAllClicked} acceptAllClicked={acceptAllClicked} setAcceptAllClicked={setAcceptAllClicked} setEditorContentUpload={setEditorContentUpload} editorHtmlContent={editorHtmlContent} changedSentence={sentenceResult} setSentenceResult={setSentenceResult} editorState={editorContent} setIsSuggestion={setIsSuggestion} setEditorState={setEditorContent} editor={editor} setIsType={setIsType} ignoredSuggestions={ignoredSuggestions} />
                {/* <WordComparer string1={state.paraText} sentence2={"Despite having studied extensively for the exam, their performance was not as good as she had hoped."} /> */}
              </>
            </div>
          }


          {/* Mobile View */}

{sentenceResult?.length > 0 && mobileView &&
            <div className='mobile-gc' style={{ marginTop: '75px', backgroundColor:'white', height:'70vh', width:'60%', position: "fixed",right: "5px",border: '1px solid  grey',zIndex: '998', overflowY:'auto', overflowX:'hidden' }}>
              {/* {isFeatureButton &&  */}
              <>
                <div
                  // className=" pt-3"
                  style={{
                    fontFamily: 'Poppins, sans-serif',
                    fontWeight: 600,
                    color: "#1A1A1A",
                    fontSize: "18px",
                    marginBottom: '10px',
                    backgroundColor: 'white',
                    height: '45px',
                    padding: '7px',
                    borderRadius: '7px',
                    // display: 'flex',
                    // justifyContent: 'space-between'

                  }}
                >
                  <div style={{ display:'flex', justifyContent:'space-between', alignItems:'baseline'}}><p style={{fontSize:'15px'}}>Suggestions</p>{ !isCoAuthor && <p style={{fontSize:'10px',  cursor: "pointer"}} onClick={()=>setShowAssistant(true)}>Show Assistant<span style={{ fontSize: '13px' }}> &lt;&lt;</span></p>}</div>
                  { showAssistant && !isCoAuthor && !trackIsModelOpen && mobileView && <div className='mobile-assistant' style={{ top: '5px', backgroundColor:'white', height:'65vh', width:'85%', position: "absolute",right: "5px",border: '1px solid  grey',zIndex: '999' }}>
            <GrammarCheckAssistant
              handleGoals={handleGoals}
              handleAIassistant={handleAIassistant}
              grammarFluency={grammarFluency}
              handleAIReview={handleAIReview}
              trial = {trial}
              userId = {userId}
              mobileView={mobileView}
              setShowAssistant = {setShowAssistant}
            />
          </div>
          }
                  <div style={{display:'flex', justifyContent:'flex-end', marginTop:'18px' , padding:'0px 5px'}}>
                  <div>
                    <button className="btn btn-primary" style={{ borderRadius: '15px', marginRight:'10px', fontSize:'10px' }}
                      onClick={() => { setIgnoreAllClicked(true) }}
                    >Ignore All</button>
                  </div>

                  <div>
                    <button className="btn btn-primary" style={{ borderRadius: '15px', fontSize:'10px' }}
                      onClick={() => { setAcceptAllClicked(true) }}
                    >Accept All</button>
                  </div>
                  </div>

                </div>

                <SuggestionComp mobileView={mobileView} autoSaveContent={autoSaveContent} docName = {docName} docId={docId} newDocId={newDocId} folderId={folderId} tags={tags} ignoreAllClicked={ignoreAllClicked} setIgnoreAllClicked={setIgnoreAllClicked} acceptAllClicked={acceptAllClicked} setAcceptAllClicked={setAcceptAllClicked} setEditorContentUpload={setEditorContentUpload} editorHtmlContent={editorHtmlContent} changedSentence={sentenceResult} setSentenceResult={setSentenceResult} editorState={editorContent} setIsSuggestion={setIsSuggestion} setEditorState={setEditorContent} editor={editor} setIsType={setIsType} ignoredSuggestions={ignoredSuggestions} />
                {/* <WordComparer string1={state.paraText} sentence2={"Despite having studied extensively for the exam, their performance was not as good as she had hoped."} /> */}
              </>
            </div>
          }
           {/* Mobile View */}


          {!isCoAuthor && !trackIsModelOpen && !mobileView && <div className="col-md-2 " style={{ marginTop: '87px' }}>
            <GrammarCheckAssistant
              handleGoals={handleGoals}
              handleAIassistant={handleAIassistant}
              grammarFluency={grammarFluency}
              handleAIReview={handleAIReview}
              trial = {trial}
              userId = {userId}
              mobileView={mobileView}
            />
          </div>
          }

        </div>

        {(selectedEditOption && isCoAuthor) && mobileView &&

<div className='col-md-12'>
<CoauthorModal trial={trial} userId={userId} state={editorContent} editorState={editorState} setEditorState={setEditorState} setState={setEditorContent} count={totalWords} onChange={handleChangeEditor} selectedEditorText={selectedSentence}
  wordCount={selectedWordCount} passNametoCoNewProject={passNametoCoNewProject} selectText={selectText}
  handleInsertClick={handleInsertClick}
  onModalOpen={() => setIsModalOpen(true)} onModalClose={handleModalClose} setTrackIsModalOpen={setTrackIsModalOpen} mobileView={mobileView}/>
   </div>
}
      </>
      {/* Rich Text Editor end */}
      {/* Goals Modal start */}
      <Modal
        isOpen={isGoalsOpen}
        toggle={() => setIsGoalsOpen(!isGoalsOpen)}
        style={{
          position: "absolute",
          left: "43%",
          top: "25%",
        }}
      >
        <ModalHeader
          toggle={() => setIsGoalsOpen(!isGoalsOpen)}
        // className="text-center"
        >
          <p
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: 600,
              color: "#303030",
              fontSize: "1rem",
            }}
          >Set Goals</p>
          <p
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: 400,
              color: "#6C6C6C",
              fontSize: "0.9rem",
            }}
          >Convey the content using Set Goals customization</p>
        </ModalHeader>
        <ModalBody>
          <div className="">
            <p
              style={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: 600,
                color: "#303030",
                fontSize: "1rem",
              }}
            >Audience</p>
            {goalsAudienceButton.map((item, i) => {
              return <TagButtonComp
                id={item}
                name={item}
                className="ml-2"
                onClickTagButton={handleAudience}
                selectedButton={audience}
              />
            })}
          </div>
          <div className="mt-3">
            <p
              style={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: 600,
                color: "#303030",
                fontSize: "1rem",
              }}
            >Domain</p>
            {goalsDomainButton.map((item, i) => {
              return <TagButtonComp
                id={item}
                name={item}
                className="ml-2"
                onClickTagButton={handleDomain}
              />
            })}
          </div>
          <div className="mt-4">
            <CheckboxMui
              label="Show goals when I start a new Document"
              isChecked={showGoalsCheck}
              onChange={(e) => handleShowGoalsCheck(e)}
            />
          </div>
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-primary"
              onClick={handleSetGoals}
            >
              Done
            </button>
          </div>
        </ModalBody>
      </Modal>

      {/* AI Assistant Modal start */}
      <Modal
        isOpen={isAIOpen}
        toggle={() => setIsAIOpen(!isAIOpen)}
        style={{
          position: "absolute",
          left: "43%",
          top: "25%",
        }}
      >
        <ModalHeader
          toggle={() => setIsAIOpen(!isAIOpen)}
        // className="text-center"
        >
          <p
            style={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: 600,
              color: "#303030",
              fontSize: "1rem",
            }}
          >AI Assistant</p>

        </ModalHeader>
        <ModalBody>
          <div className="">
            <p
              style={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: 600,
                color: "#303030",
                fontSize: "1rem",
              }}
            >Formality</p>

            <TagButtonComp
              id="neutral"
              name="Neutral"
              className="ml-2"
              onClickTagButton={handleFormality}
            />

            <TagButtonComp
              id="casual"
              name="Casual"
              className="ml-2"
              onClickTagButton={handleFormality}
            />

            <TagButtonComp
              id="formal"
              name="Formal"
              className="ml-2"
              onClickTagButton={handleFormality}
            />

          </div>
          <div className="mt-3">
            <p
              style={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: 600,
                color: "#303030",
                fontSize: "1rem",
              }}
            >Tone</p>
            <div>
              <TagButtonComp
                id="personable"
                name="Personable"
                className="ml-2"
                onClickTagButton={handleTone}
              />

              <TagButtonComp
                id="confident"
                name="Confident"
                className="ml-2"
                onClickTagButton={handleTone}
              />

              <TagButtonComp
                id="empathetic"
                name="Empathetic"
                className="ml-2"
                onClickTagButton={handleTone}
              />
            </div>
            <div className="mt-2">
              <TagButtonComp
                id="engaging"
                name="Engaging"
                className="ml-2"
                onClickTagButton={handleTone}
              />

              <TagButtonComp
                id="witty"
                name="Witty"
                className="ml-2"
                onClickTagButton={handleTone}
              />

              <TagButtonComp
                id="direct"
                name="Direct"
                className="ml-2"
                onClickTagButton={handleTone}
              />

            </div>
          </div>
          <div className="mt-4">
            {/* <p
                            style={{
                                fontFamily: "Poppins, sans-serif",
                                fontWeight: 600,
                                color: "#303030",
                                fontSize: "1rem",
                            }}
                        >Your Profession</p> */}

            <SelectDropdownMui
              optionsList={professionList}
              selectLabel={"Your Profession"}
              onChange={handleProfession}
              value={profession}
            />

          </div>
          <div className="mt-3 d-flex justify-content-end">
            <button
              className="btn btn-primary"
              onClick={updateAIAssistant}
            >
              Update
            </button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default CoAuthorNewProject;
