
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import FormatAlignLeftIcon from "@material-ui/icons/FormatAlignLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MenuItem from "@material-ui/core/MenuItem";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Badge from "@material-ui/core/Badge";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Documents from "@material-ui/icons/Description";
import PlaylistAddCheckRoundedIcon from '@mui/icons-material/PlaylistAddCheckRounded';
import Dashboard from "@material-ui/icons/Equalizer";
import Team from "@material-ui/icons/SupervisorAccount";
import Alerts from "@material-ui/icons/Forum";
import Reports from "@material-ui/icons/LibraryBooks";
import { NavLink } from "react-router-dom";
import MoreIcon from "@material-ui/icons/MoreVert";
import avatar from "../static/img_avatar.png";
import NotificationCard from "./Cards/NotificationCard";
import { getProfile } from "../services/userService";
import logo from "../static/Group 570.png";
import { useHistory } from "react-router-dom";
import PostAddIcon from "@material-ui/icons/PostAdd";
import NotificationServices from "../services/notficationService";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import "./Navbar.css";
import "./SideNavBar.css";
import GenericThemeSwitch from "./GenericThemeSwitch";
import isEmptyObject from "../utils/isEmptyObject";
import { connect, useDispatch, useSelector } from "react-redux";
import { setCurrentUser } from "../redux/user/user.actions";
import BookIcon from "@material-ui/icons/Book";
import FormatQuoteIcon from "@material-ui/icons/FormatQuote";
import ChromeReaderModeOutlinedIcon from "@material-ui/icons/ChromeReaderModeOutlined";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";
import ImportContactsRoundedIcon from '@mui/icons-material/ImportContactsRounded';
import TranslateRoundedIcon from '@mui/icons-material/TranslateRounded';
import ModeEditOutlineRoundedIcon from '@mui/icons-material/ModeEditOutlineRounded';
import { getHomeCMS } from "../services/adminServices";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import { withRouter } from "react-router-dom";
import $ from "jquery";
import Tooltip from "@material-ui/core/Tooltip";
import PetLogo from "../static/signup_login/PetLogo.png"
import PetPenLogo from '../static/PetPenLogo.png'
import Poetlogopen from '../static/POET_Logo_Pen.png'
import Poetlogo from '../static/POET_Logo_Mid.png'
import { imgUrl } from "../services/config.json"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { matchPath } from "react-router";
import { closeLeftNavbar, closeRightModalAndOpenLeftNavbar, openLeftNavbar, openRightModalAndCloseLeftNavbar } from "../redux/handleSideNavBarAndModel/handleStatus.action";
import HomeIcon from '@mui/icons-material/Home';
import GroupsIcon from '@mui/icons-material/Groups';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { FaBookOpen } from "react-icons/fa";
import { setBlogClick, setDocumentClick } from "../redux/manageClick/manageClick.action";
import GridViewIcon from '@mui/icons-material/GridView';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import PlagiarismOutlinedIcon from '@mui/icons-material/PlagiarismOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import { markAllRead } from "../services/notficationService";
import  coauthor_logo from "../static/Co Author_logo.png"
import  dashboard_logo from "../static/Dashboard_logo.png"
import  document_logo from "../static/Document_logo.png"
import  grammarcheck_logo from "../static/Grammar check_logo.png"
import  paraphrase_logo from "../static/Paraphrase_logo.png"
import  plagiarism_logo from "../static/Plagiarism_logo.png"
import  reports_logo from "../static/Reports_logo.png"
import  teams_logo from "../static/Teams_logo.png"
import  translate_logo from "../static/Translate_logo.png"

import  coauthor_logo1 from "../static/Co Author 1.png"
import  dashboard_logo1 from "../static/Dashboard 1.png"
import  document_logo1 from "../static/Document 1.png"
import  grammarcheck_logo1 from "../static/Grammar check 1.png"
import  paraphrase_logo1 from "../static/Paraphrase 1.png"
import  plagiarism_logo1 from "../static/Plagiarism 1.png"
import  reports_logo1 from "../static/Reports 1.png"
import  teams_logo1 from "../static/Teams 1.png"
import  translate_logo1 from "../static/Translate 1.png"
import  FAQIcon from "../static/FAQIcon.png"


const drawerWidth = 240;
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'blue',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);
const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: 10,
  },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60,
  },
  grow: {
    flexGrow: 1,
  },
  root: {
    display: "flex",
  },
  appBar: {
    backgroundColor: '#ccced1 !important',
    boxShadow: 'none',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(99% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 38,
    [theme.breakpoints.down("sm")]: {
      marginRight: 0, // Applied for small screens and below
    },
  },
  hide: {
    display: "none",
  },
  drawer: {
    // border: "2px solid black",
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    position: "relative", // Default positioning
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    zIndex: theme.zIndex.drawer, // Default zIndex
    [theme.breakpoints.down("sm")]: {
      position: "fixed", // Overlay the content
      height: "100%", // Full height
      top: 0,
      left: 0,
      zIndex: theme.zIndex.drawer + 1, // Higher zIndex for small screens
      transform: "translateX(-100%)", // Initially hidden on small screens
      transition: theme.transitions.create("transform", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down("sm")]: {
      transform: "translateX(0)", // Show when open on small screens
    },
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  toolbarB: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  active: {
    backgroundColor: theme.palette.action.selected,
  },
  iconButton: {
    '&:hover': {
      backgroundColor: '#B4D9FF',
    },
  },

}));

function NewHeader(props) {
  const classes = useStyles();
  const [AnchorEl, setAnchorEl] = React.useState(false);
  const [profilePic, setProfilePic] = useState("");
  const [username, setusername] = useState("");
  const [notifications, setnotifications] = useState([]);
  const [alerts, setalerts] = useState([]);
  const theme = useTheme();
  const [open, setOpen] = React.useState();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [cms, setCms] = useState({});
  const [userData, setuserData] = useState({});
  const [bottomText, setBottomText] = useState("")
  const [selectedMenu, setSelectedMenu] = useState('Professional Editing Tool');
  const userRole = sessionStorage.getItem("Role")
  const [orgName, setOrgName] = useState('')
  const location = useLocation()
  console.log(username, "username");
  const dispatch = useDispatch();
  const { rightModalOpen, leftNavbarOpen } = useSelector(state => state.handleModelAndSideBavBar);

  const [popoverOpen, setPopoverOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  console.log(windowWidth, "windowWidth")
  const mobileView = windowWidth <= 600;

  const togglePopover = () => {
    setPopoverOpen(!popoverOpen);
  };

  const formatDate = () => {
    const options = { month: 'short', day: 'numeric', year: 'numeric' };
    const date = new Date();
    const month = date.toLocaleDateString('en-US', { month: 'short' });
    const day = date.toLocaleDateString('en-US', { day: 'numeric' });
    const year = date.toLocaleDateString('en-US', { year: 'numeric' });

    return `${month} ${day} | ${year}`;
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const currentUser = await getProfile();
        setOrgName(currentUser.data.organizationName)
      } catch (error) {
        console.error("Failed to fetch profile:", error);
      }
    };
    fetchProfile();
  }, []);


  useEffect(() => {
    const currentPath = window.location.pathname;
    console.log("currentpath", currentPath)

    const capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    };

    if (currentPath.includes('/viewmydocuments/')) {
      setSelectedMenu('Documents');
      return;
    }

    switch (currentPath) {
      case '/paraphrase':
        setSelectedMenu('Paraphraser');
        break;
      case '/dashboard':
        const capitalizedUsername = capitalizeFirstLetter(username);
        setSelectedMenu(`Welcome ${capitalizedUsername}`);
        setBottomText(userRole==='Organization' ? `${orgName}` : `${formatDate()}`);
        break;
      case '/texteditor':
        setSelectedMenu('New Document');
        break;
      case '/mydocuments':
        setSelectedMenu('Documents');
        break;
      case '/myteam':
        setSelectedMenu('Team');
        break;
      case '/docTags':
        setSelectedMenu('Tags');
        break;
      case '/citation':
        setSelectedMenu('Citation Generator');
        break;
      case '/plagiarism':
        setSelectedMenu('Plagiarism');
        break;
      case '/coauthor':
        setSelectedMenu('Co-Author');
        break;
      case '/Summarizer':
        setSelectedMenu('Summarizer');
        break;
      case '/translator':
        setSelectedMenu('Translator');
        break;
      case '/reports':
        setSelectedMenu('Reports');
        break;
      case '/faq':
        setSelectedMenu('FAQ');
        break;
      case '/alerts':
        setSelectedMenu('Alerts');
        break;
      case '/grammarcheck':
        setSelectedMenu('Grammar Check');
        break;
      case '/blogpage':
        setSelectedMenu('Blogs');
        break;
      case '/accountsettings':
        setSelectedMenu('Account Settings');
        break;
      case '/pricing':
        setSelectedMenu('Subcriptions');
        break;
      case '/invoices':
        setSelectedMenu('Invoice');
        break;
      case '/contactUs':
        setSelectedMenu('Contact Us');
        break;
      case '/chat':
        setSelectedMenu('Chat Messages');
        break;
      case '/coauthor-newproject':
        setSelectedMenu('Co-Author');
        break;
      case '/mynewdocuments':
        setSelectedMenu('New Document');
        break;
      default:
        setSelectedMenu('Professional Editing Tool');
    }
  }, [username,orgName]);

  const history = useHistory()
  const markAllNotificationsRead = async () =>{
    try{
    if(notifications.notificationsCount < 1){
      return
    }
    
      let details =  await markAllRead()
      if(details.status===200){
        getNotifications()
        console.log("All notifications marked as read")
      }else{
        console.log("Failed to mark all notifications as read")
      }
      
     
    }catch (error) {
      console.error('Error marking all notifications as read:', error.message);
    }
    
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getCms = async () => {
    const { currentUser, setCurrentUser } = props;
    let cms = await getHomeCMS();
    setCms(cms.data);
  };
  // const getUser = async () => {
  //   const { currentUser, setCurrentUser } = props;
  //   if (!JSON.stringify(currentUser)) {
  //     let user = (user = await getProfile());
  //     console.log("storing in redux");
  //     console.log(user.sidenavOption)
  //     user = { ...user.data };
  //     // setOpen(user.sidenavOption)
  //     setCurrentUser(user);
  //   }
  // }

  useEffect(() => {
    console.log(rightModalOpen, leftNavbarOpen, "rightModalOpen, leftNavbarOpen");
    async function getUser() {
      const { currentUser, setCurrentUser } = props;
      if (!JSON.stringify(currentUser)) {
        let user = (user = await getProfile());
        console.log("storing in redux");
        console.log(user.sidenavOption);
        user = { ...user.data };
        setuserData(user);
        setCurrentUser(user);
      }
    }
    getUser();
    getNotifications();
    getAlerts();
    displyPic();
    getCms();
  }, [rightModalOpen, leftNavbarOpen]);

  

  useEffect(() => {
    const { currentUser } = props;
    setOpen(currentUser.sidenavOption);
  }, [props]);

  const notfication_popover = (
    <Popover id="popover-basic" style={{ zIndex: "9999", minWidth:'400px' }}>
      <Popover.Content
        style={{
          overflowY: "auto",
          maxHeight: "40vh",
          position: "relative",
          minWidth: "400px",
          
        }}
      >
        <div 
        style={{
          position: "sticky", 
          top: -7, 
          backgroundColor: "white", 
          zIndex: 1,
          paddingTop:'5px',
          display:'flex',
          justifyContent:'space-between',
        }}>
        <div>
          <h6><b>Notification</b></h6>
        </div>
        <div>
          <p style={{color:'blue', fontSize:'10px', position:'relative', bottom:'-4px', cursor: "pointer" }} onClick={() => {
                markAllNotificationsRead();
                togglePopover();
              }}>Mark all as read</p>
        </div>
        </div>
        <hr />
        <NotificationCard data={notifications} />
      </Popover.Content>
    </Popover>
  );

  const handleDrawerOpen = () => {
    console.log("drawer open");
    setOpen(true);
    dispatch(closeRightModalAndOpenLeftNavbar());
    // dispatch(openLeftNavbar())
  };
  const handleDrawerClose = () => {
    console.log("drawer closed");
    setOpen(false);
    dispatch(closeLeftNavbar());   //////// changing open
    // dispatch(openRightModalAndCloseLeftNavbar());   
  };
  const menuId = "primary-search-account-menu";
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    // handleMobileMenuClose();
  };

  const getAlerts = async () => {
    let alerts = await NotificationServices.getAlerts();
    setalerts(alerts);
    // console.log(alerts);
  };

  const getNotifications = async () => {
    let notifications = await NotificationServices.getnotifications();
    setnotifications(notifications);
    // console.log(notifications);
  };

  const handleBlogClick = () => {
    dispatch(setBlogClick());
  };

  const handleDocumentClick = () => {
    dispatch(setDocumentClick());
  };

  const openContactUs = async () => {
    props.history.push({
      pathname: `/contactUs`,
    });
  };

  const displyPic = async () => {
    let user;
    const { currentUser, setCurrentUser } = props;
    if (currentUser && !isEmptyObject(currentUser)) {
      user = { ...currentUser };
      user["status"] = 200;
    } else {
      user = user = await getProfile();
      console.log("currentUser", user);
      user = user.data;
      setCurrentUser(user);
    }
    if (user.status === 200) {
      const { display_picture, username } = user;
      setProfilePic(display_picture);
      setusername(username);
    }
  };

  const mobileMenuId = "primary-search-account-menu-mobile";

  const dropdown_popover = (
    <Popover id="popover-basic" style={{ zIndex: "9999" }}>
      <Popover.Content>
        <NavLink
          to={{ pathname: "/accountsettings" }}
          style={{ color: "black" }}
        >
          <MenuItem
            onClick={handleMenuClose}
            className={isUrlMatch("/accountsettings") && "background-grey"}
          >
            <Typography>Account Settings</Typography>
          </MenuItem>
        </NavLink>
        {props.currentUser && props.currentUser.accountType === "Personal" && (
          <NavLink
            to={{ pathname: "/pricing" }}
            style={{ color: "black", paddingTop: "2px" }}
          >
            <MenuItem
              onClick={handleMenuClose}
              className={isUrlMatch("/pricing") && "background-grey"}
            >
              Subscriptions
            </MenuItem>
          </NavLink>
        )}
        {props.currentUser && props.currentUser.accountType === "Personal" && (
          <NavLink to={{ pathname: "/invoices" }} style={{ color: "black" }}>
            <MenuItem
              onClick={handleMenuClose}
              className={isUrlMatch("/invoices") && "background-grey"}
            >
              Invoices
            </MenuItem>
          </NavLink>
        )}

        <NavLink to={{ pathname: "/contactUs" }} style={{ color: "black" }}>
          <MenuItem onClick={handleMenuClose}>Support</MenuItem>
        </NavLink>
        <NavLink to={{ pathname: "/logout" }} style={{ color: "black" }}>
          <MenuItem onClick={handleMenuClose}>Log Out</MenuItem>
        </NavLink>
      </Popover.Content>
    </Popover>
  );

  function isUrlMatch(path) {
    return matchPath(location.pathname, {
      path,
      exact: true,
      strict: false,
    })
  }

  const getThemeSwitch = () => {
    if (window.location.pathname.match("/accountsettings")) {
      return null;
    }
    return <GenericThemeSwitch />;
  };


  const handleMenuSelection = (menuName) => {
    setSelectedMenu(menuName);
  };

  useEffect(() => {
    console.log("test_", selectedMenu);
    handleMenuSelection(selectedMenu)
    const container = document.querySelector('.MuiDrawer-paper');
    if (selectedMenu == "Summarizer" || selectedMenu == "Translator" || selectedMenu == "Reports") {
      container.scrollTop = 220;
    } else {
      container.scrollTop = 0;
    }
  }, [selectedMenu])

  return (
    <div className={classes.root}>
      <CssBaseline />
      {getThemeSwitch()}
      <AppBar
        style={{ backgroundColor: 'grey' }}

        id="appBar"
        position="fixed"
        className={clsx(classes.appBar, {
          // [classes.appBarShift]: open, 
          [classes.appBarShift]: leftNavbarOpen,

        })}
      >
        <Toolbar>
          <LightTooltip title="Click to Expand">
            <IconButton
              style={{ color: "#4d4f5c" }}
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                // [classes.hide]: open,
                [classes.hide]: leftNavbarOpen,
              })}
              id="normalsidenav"
            >
              {/* <MenuIcon /> */}
              <img
                alt="logo"
                className="img-fluid lcss"
                src={Poetlogopen}
                style={{
                  width: "40px",
                  height: "40px",
                  objectFit: "contain",
                }}
              ></img>
            </IconButton>
          </LightTooltip>

          {/* <Typography
            className="main-title"
            variant="h6"
            noWrap
            style={{ color: "#4d4f5c" }}
          >
            <p>
              <text
                style={{
                  fontFamily: "ProximaNova-Semibold",
                  lineHeight: "1.18",
                  fontWeight: 600,
                  fontSize: "22px",
                  color: "#3d83fa",
                  // backgroundColor: "#99ccff"
                }}
              >
                PROFESSIONAL
              </text>{" "}
              <small
                style={{
                  fontFamily: "ProximaNova-Light",
                  lineHeight: "1.18",
                  fontSize: "22px",
                  fontWeight: 300,
                  color: "#3d83fa",
                }}
              >
                EDITING TOOL
              </small>
            </p>
          </Typography> */}
            {mobileView  && !leftNavbarOpen ? (
               <p>
               {selectedMenu !== 'Professional Editing Tool' ? (
                 <>
                   <text
                     style={{
                       fontFamily: "ProximaNova-Semibold",
                      //  lineHeight: "1.18",
                       fontWeight: 600,
                       fontSize: "18px",
                       color: "#3d83fa",
                       marginBottom: "0px"
                     }}
                   >
                     {selectedMenu === 'Chat Messages' ?
                       <>
                         <ArrowBackIcon sx={{ verticalAlign: 'middle', cursor: 'pointer' }} onClick={() => props.history.goBack()} /> {selectedMenu}
                       </>
                       :
                       selectedMenu
                     }
                   </text>
                 </>
               ) : (
                 <>
                   <text
                     style={{
                       fontFamily: "ProximaNova-Semibold",
                       lineHeight: "1.18",
                       fontWeight: 600,
                       fontSize: "18px",
                       color: "#3d83fa",
                       marginRight: "5px"
                     }}
                   >
                     PROFESSIONAL
                   </text>
                   <small
                     style={{
                       fontFamily: "ProximaNova-Light",
                       lineHeight: "1.18",
                       fontSize: "18px",
                       fontWeight: 300,
                       color: "#3d83fa",
                     }}
                   >
                     EDITING TOOL
                   </small>
                 </>
               )}
             </p>
            ) : (
          <Typography
            className="main-title"
            variant="h6"
            noWrap
            style={{ color: "#4d4f5c", marginLeft: "33px" }}
          >
            <p>
              {selectedMenu !== 'Professional Editing Tool' ? (
                <>
                  <text
                    style={{
                      fontFamily: "ProximaNova-Semibold",
                      lineHeight: "1.18",
                      fontWeight: 600,
                      fontSize: "22px",
                      color: "#3d83fa",
                    }}
                  >
                    {selectedMenu === 'Chat Messages' ?
                      <>
                        <ArrowBackIcon sx={{ verticalAlign: 'middle', cursor: 'pointer' }} onClick={() => props.history.goBack()} /> {selectedMenu}
                      </>
                      :
                      selectedMenu
                    }
                  </text><br />
                  {bottomText && (
                    <small
                      style={{
                        fontFamily: "ProximaNova-Light",
                        lineHeight: "1.18",
                        fontSize: "14px",
                        fontWeight: 300,
                        color: "#3d83fa",
                      }}
                    >
                      {bottomText}
                    </small>
                  )}
                </>
              ) : (
                <>
                  <text
                    style={{
                      fontFamily: "ProximaNova-Semibold",
                      lineHeight: "1.18",
                      fontWeight: 600,
                      fontSize: "22px",
                      color: "#3d83fa",
                      marginRight: "5px"
                    }}
                  >
                    PROFESSIONAL
                  </text>
                  <small
                    style={{
                      fontFamily: "ProximaNova-Light",
                      lineHeight: "1.18",
                      fontSize: "22px",
                      fontWeight: 300,
                      color: "#3d83fa",
                    }}
                  >
                    EDITING TOOL
                  </small>
                </>
              )}
            </p>
          </Typography>
          )}

          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <div style={{ marginTop: "6%" }}>
              {/* <IconButton
                style={{ color: "#4d4f5c" }}
                aria-label="show 17 new notifications"
                color="inherit"
                onClick={openContactUs}
              >
                <ContactMailIcon />
              </IconButton> */}
              <OverlayTrigger
                trigger="click"
                placement="bottom"
                overlay={notfication_popover}
                rootClose={true}
                show={popoverOpen}
              >
                <IconButton
                  classes={{ root: classes.iconButton }}
                  style={{ color: "#1976D2" }}
                  aria-label="show 17 new notifications"
                  color="inherit"
                  onClick={togglePopover}
                  disabled={notifications.notificationsCount < 1}
                >
                  <Badge
                    badgeContent={
                      notifications.notificationsCount > 0
                        ? notifications.notificationsCount
                        : null
                    }
                    color="secondary"
                  >
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
              </OverlayTrigger>
            </div>
            {/*  */}
            <div>
              <Grid container justify="center" alignItems="center">
                <OverlayTrigger
                  trigger="click"
                  placement="bottom"
                  overlay={dropdown_popover}
                  rootClose={true}
                >
                  <LightTooltip title={username} onClick={handleProfileMenuOpen}>
                    <Avatar
                      aria-controls={menuId}
                      aria-haspopup="true"
                      alt={username}
                      src={profilePic ? profilePic : avatar}
                      id="profilePic"
                      className={`${classes.avatar} profilePic point`}
                      color="inherit"
                    />
                  </LightTooltip>
                </OverlayTrigger>
              </Grid>
            </div>
          </div>

          <div className={classes.sectionMobile}>
            <OverlayTrigger
              trigger="click"
              placement="bottom"
              overlay={notfication_popover}
              rootClose={true}
            >
              <IconButton
                classes={{ root: classes.iconButton }}
                style={{ color: "#1976D2" }}
                aria-label="show 17 new notifications"
                color="inherit"
                disabled={notifications.notificationsCount < 1}
              >
                <Badge
                  badgeContent={
                    notifications.notificationsCount > 0
                      ? notifications.notificationsCount
                      : null
                  }
                  color="secondary"
                >
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            </OverlayTrigger>

            <OverlayTrigger
              trigger="click"
              placement="bottom"
              overlay={dropdown_popover}
              rootClose={true}
            >
              {/* <IconButton
                style={{ color: "#4d4f5c" }}
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                color="inherit"
              >
                <MoreIcon />
              </IconButton> */}
              <Avatar
                aria-label="account of current user"
                aria-controls={menuId}
                onClick={handleProfileMenuOpen}
                aria-haspopup="true"
                alt={username}
                src={profilePic ? profilePic : avatar}
                id="profilePic"
                className={`${classes.avatar} point`}
                data-toggle="tooltip"
                data-placement="bottom"
                title={username}
                color="inherit"
              />
            </OverlayTrigger>
          </div>
        </Toolbar>
        {/* <div id="mobilesidenav">
          <MobileSidenav />
        </div> */}
      </AppBar>
      {/* {renderMobileMenu} */}
      {/* {renderMenu} */}
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          // [classes.drawerOpen]: open,
          // [classes.drawerClose]: !open,
          [classes.drawerOpen]: leftNavbarOpen,
          [classes.drawerClose]: !leftNavbarOpen,
        })}
        classes={{
          paper: clsx({
            // [classes.drawerOpen]: open,
            // [classes.drawerClose]: !open,
            [classes.drawerOpen]: leftNavbarOpen,
            [classes.drawerClose]: !leftNavbarOpen,
          }),
        }}
        // open={open}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        id="normalsidenav"
      >
        <div
          className={classes.toolbarB}
          style={{
            backgroundColor: "#1976D2",
            borderTopRightRadius: '50px',
            border: "1px solid black"
            // borderStartEndRadius: "2rem",

          }}
        // id="sidebar"
        >
          <IconButton  onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <MenuIcon style={{ color: "#fff", fontSize: '25px' }} />
            ) : (
              <MenuIcon style={{ color: "#fff", fontSize: '25px' }} />
            )}
          </IconButton>
          <img
            alt="logo"
            className="img-fluid lcss"
            src={Poetlogo}
            // src={
            //   cms && cms.Navbar_logo
            //     ? cms.Navbar_logo.Image
            //     : `${imgUrl}/pet-website-static-images/Group+570.png`
            // }
            style={{
              width: "70%",
              height: "70%",
              objectFit: "contain",
              marginRight: '40px'
            }}
          // src={logo}
          ></img>
        </div>
        <Divider />

        <List className="drawerMenu pointer" id="menuList">
          <NavLink
            className="sidenavbar-textlink"
            // onClick={() =>  handleSideNavBarMatch("dashboard")}
            to={{ pathname: "/dashboard" }}
          >
            <ListItem
              className={
                isUrlMatch("/dashboard") ? "background-red" : "hvr-pulse"
              }
            >
              <ListItemIcon   //HomeIcon
                className="drawerMenu"
                style={{ color: "#FAA802" }}
              >
                <LightTooltip title={open ? "" : "Dashboard"}>
                <img
                    src={isUrlMatch("/dashboard") ? dashboard_logo1 : dashboard_logo}
                    alt="Dashboard"
                    className={isUrlMatch("/dashboard") ? "iconCol" : "hvr-pulse"}
                    style={{width:'21px'}}
                />
                </LightTooltip >
              </ListItemIcon>
              <Typography>Dashboard</Typography>
            </ListItem>
          </NavLink>

          {/* <NavLink
            className="sidenavbar-textlink"
            to={{ pathname: "/texteditor" }}
          >
            <ListItem
              className={
                isUrlMatch("/texteditor") ? "background-red" : "hvr-pulse"
              }
            >
              <ListItemIcon
                className="drawerMenu"

                // style={{ color: "#499557" }}
                style={{ color: "black", border: '1px' }}
              >
                <LightTooltip title="New Document">
                  <PostAddIcon
                    className={
                      isUrlMatch("/texteditor") ? "iconCol" : "hvr-pulse"
                    }
                  />
                </LightTooltip>
              </ListItemIcon>

              <Typography>New Document</Typography>
            </ListItem>
          </NavLink> */}

          <NavLink
            className="sidenavbar-textlink"
            to={{ pathname: "/mydocuments" }}
            onClick={handleDocumentClick}
          >
            <ListItem
              className={
                isUrlMatch("/mydocuments") || isUrlMatch("/documentpreview/:id") || isUrlMatch("/viewmydocuments/:id") ? "background-red" : "hvr-pulse"
              }
            >
              <ListItemIcon
                className="drawerMenu"

                style={{ color: "#53EDF3" }}
              >
                <LightTooltip title={open ? "" :"Documents"}>
                <img
                    src={isUrlMatch("/mydocuments") || isUrlMatch("/documentpreview/:id") || isUrlMatch("/viewmydocuments/:id") ? document_logo1 : document_logo}
                    alt="Document"
                    className={isUrlMatch("/mydocuments") || isUrlMatch("/documentpreview/:id") || isUrlMatch("/viewmydocuments/:id") ? "iconCol" : "hvr-pulse"}
                    style={{width:'21px'}}
                />
              
                </LightTooltip>
              </ListItemIcon>

              <Typography>Documents</Typography>
            </ListItem>
          </NavLink>

          <NavLink
            className="sidenavbar-textlink"
            to={{ pathname: "/grammarcheck" }}
            onClick={handleDocumentClick}
          >
            <ListItem
              className={
                isUrlMatch("/grammarcheck") ? "background-red" : "hvr-pulse"
              }
            >
              <ListItemIcon
                className="drawerMenu"

                style={{ color: "#00FA21" }}
              >
                <LightTooltip title={open ? "" : "Grammar Check"}>
                <img
                    src={isUrlMatch("/grammarcheck") ? grammarcheck_logo1 : grammarcheck_logo}
                    alt="Grammar Check"
                    className={isUrlMatch("/grammarcheck") ? "iconCol" : "hvr-pulse"}
                    style={{width:'21px'}}
                />
                </LightTooltip>
              </ListItemIcon>

              <Typography>Grammar Check</Typography>
            </ListItem>
          </NavLink>

          <NavLink className="sidenavbar-textlink" to={{ pathname: "/myteam" }}>
            <ListItem
              className={isUrlMatch("/myteam") ? "background-red" : "hvr-pulse"}
            >
              <ListItemIcon
                className="drawerMenu"

                style={{ color: "red" }}
              >
                <LightTooltip title={open ? "" :"Team"}>
                <img
                    src={isUrlMatch("/myteam") ? teams_logo1 : teams_logo}
                    alt="Team"
                    className={isUrlMatch("/myteam") ? "iconCol" : "hvr-pulse"}
                    style={{width:'21px'}}
                />
                </LightTooltip>
              </ListItemIcon>

              <Typography>Team</Typography>
            </ListItem>
          </NavLink>

          

          <NavLink
            className="sidenavbar-textlink"
            to={{ pathname: "/citation" }}
          >
            <ListItem
              className={
                isUrlMatch("/citation") ? "background-red" : "hvr-pulse"
              }
            >
              <ListItemIcon
                className="drawerMenu"

                style={{ color: "#ff1694" }}
              >
                <LightTooltip title={open ? "" :"Citation Generator"}>
                  <FormatQuoteIcon
                    className={isUrlMatch("/citation") ? "iconCol" : "hvr-pulse"}
                  />
                </LightTooltip>
              </ListItemIcon>

              <Typography>Citation Generator</Typography>
            </ListItem>
          </NavLink>

          <NavLink
            className="sidenavbar-textlink"
            to={{ pathname: "/paraphrase" }}

          >
            <ListItem
              className={
                isUrlMatch("/paraphrase") ? "background-red" : "hvr-pulse"
              }
              onClick={() => handleMenuSelection('Paraphrasing')}
            >
              <ListItemIcon
                className="drawerMenu"
                style={{ color: "yellow", height: "25px", width: "25px" }}
              >
                <LightTooltip title={open ? "" :"Paraphraser"}>
                  {/* <ChromeReaderModeOutlinedIcon
                    className={
                      isUrlMatch("/paraphrase") ? "iconCol" : "hvr-pulse"
                    }
                  /> */}
                  <img
                    src={isUrlMatch("/paraphrase") ? paraphrase_logo1 : paraphrase_logo}
                    alt="Paraphraser"
                    className={isUrlMatch("/paraphrase") ? "iconCol" : "hvr-pulse"}
                    style={{width:'21px'}}
                />
                </LightTooltip>
              </ListItemIcon>

              <Typography>Paraphraser</Typography>
            </ListItem>
          </NavLink>


          <NavLink
            className="sidenavbar-textlink"
            to={{ pathname: "/plagiarism" }}
          >
            <ListItem
              className={
                isUrlMatch("/plagiarism") ? "background-red" : "hvr-pulse"
              }
            >
              <ListItemIcon
                className="drawerMenu"
                style={{ color: "#F3034B", height: "25px", width: "25px" }}
              >
                <LightTooltip title={open ? "" :"Plagiarism"}>
                <img
                    src={isUrlMatch("/plagiarism") ? plagiarism_logo1 : plagiarism_logo}
                    alt="Plagiarism"
                    className={isUrlMatch("/plagiarism") ? "iconCol" : "hvr-pulse"}
                    style={{width:'21px'}}
                />
                  
                </LightTooltip>
              </ListItemIcon>

              <Typography>Plagiarism</Typography>
            </ListItem>
          </NavLink>

          <NavLink
            className="sidenavbar-textlink"
            to={{ pathname: "/coauthor" }}
            onClick={handleDocumentClick}
          >
            <ListItem
              className={
                isUrlMatch("/coauthor") || isUrlMatch("/coauthor-newproject") ? "background-red" : "hvr-pulse"
              }
            >
              <ListItemIcon
                className="drawerMenu"
                style={{ color: "#4CE4BE", height: "25px", width: "25px" }}
              >
                <LightTooltip title={open ? "" :"Co-Author"}>
                  {/* <ModeEditOutlineRoundedIcon
                    className={
                      isUrlMatch("/coauthor") || isUrlMatch("/coauthor-newproject") ? "iconCol" : "hvr-pulse"
                    }
                  /> */}
                  <img
                    src={isUrlMatch("/coauthor") || isUrlMatch("/coauthor-newproject") ? coauthor_logo1 : coauthor_logo}
                    alt="Co-Author"
                    className={isUrlMatch("/coauthor") || isUrlMatch("/coauthor-newproject") ? "iconCol" : "hvr-pulse"}
                    style={{width:'21px'}}
                />
                  
                </LightTooltip>
              </ListItemIcon>

              <Typography>Co-Author</Typography>
            </ListItem>
          </NavLink>

          <NavLink
            className="sidenavbar-textlink"
            to={{ pathname: "/Summarizer" }}
          >
            <ListItem
              className={
                isUrlMatch("/Summarizer") ? "background-red" : "hvr-pulse"
              }
            >
              <LightTooltip title={open ? "" :"Summarizer"}>
                <ListItemIcon
                  className="drawerMenu"
                  style={{ color: "black", height: "25px", width: "25px", marginTop: '5px' }}
                >

                  {/* <svg
                    className={
                      isUrlMatch("/Summarizer")
                        ? "bi bi-book-fill iconCol"
                        : "bi bi-book-fill hvr-pulse"
                    }
                    xmlns="http://www.w3.org/2000/svg"
                    width="18px"
                    height="18px" fill="currentColor"
                    viewBox="0 0 14 14"
                  >
                    <path d="M8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783" />
                  </svg> */}

                  <FaBookOpen
                    className={
                      isUrlMatch("/Summarizer")
                        ? "bi bi-book-fill iconCol"
                        : "bi bi-book-fill hvr-pulse"
                    }
                  />

                </ListItemIcon>
              </LightTooltip>
              <Typography>Summarizer</Typography>
            </ListItem>
          </NavLink>


          <NavLink
            className="sidenavbar-textlink"
            to={{ pathname: "/translator" }}
          >
            <ListItem
              className={
                isUrlMatch("/translator") ? "background-red" : "hvr-pulse"
              }
            >
              <ListItemIcon
                className="drawerMenu"
                style={{ color: "orange", height: "25px", width: "25px" }}
              >
                <LightTooltip title={open ? "" :"Translator"}>
                  <TranslateRoundedIcon
                    className={
                      isUrlMatch("/translator") ? "iconCol" : "hvr-pulse"
                    }
                  />
                </LightTooltip>
              </ListItemIcon>
              <Typography>Translator</Typography>
            </ListItem>
          </NavLink>
          <NavLink
            className="sidenavbar-textlink"
            to={{ pathname: "/reports" }}
          >
            <ListItem
              className={
                isUrlMatch("/reports") ? "background-red" : "hvr-pulse"
              }
            >
              <ListItemIcon
                className="drawerMenu"
                style={{ color: "#FFF017" }}
              >
                <LightTooltip title={open ? "" :"Reports"}>
                <img
                    src={isUrlMatch("/reports") ? reports_logo1 : reports_logo}
                    alt="Reports"
                    className={isUrlMatch("/reports") ? "iconCol" : "hvr-pulse"}
                    style={{width:'21px'}}
                />
                </LightTooltip>
              </ListItemIcon>
              <Typography>Reports</Typography>
            </ListItem>
          </NavLink>

          <NavLink
            className="sidenavbar-textlink"
            to={{ pathname: "/docTags" }}
          >
            <ListItem
              className={
                isUrlMatch("/docTags") ? "background-red" : "hvr-pulse"
              }
            >
              <ListItemIcon
                className="drawerMenu"
                style={{ color: "#FF5733" }}
              >
                <LightTooltip title={open ? "" :"Tags"}>
                  <LocalOfferIcon
                    className={isUrlMatch("/docTags") ? "iconCol" : "hvr-pulse"}
                  />
                </LightTooltip>
              </ListItemIcon>
              <Typography>Tags</Typography>
            </ListItem>
          </NavLink>

          <NavLink
            className="sidenavbar-textlink"
            to={{ pathname: "/blogpage" }}
            onClick={handleBlogClick}
          >
            <ListItem
              className={
                isUrlMatch("/blogpage") || isUrlMatch("/blogpreview/:id") ? "background-red" : "hvr-pulse"
              }
            >
              <ListItemIcon
                className="drawerMenu"
                style={{ color: "#FFF017" }}
              >
                <LightTooltip title={open ? "" :"Blogs"}>
                  <BookIcon
                    className={isUrlMatch("/blogpage") || isUrlMatch("/blogpreview/:id") ? "iconCol" : "hvr-pulse"}
                  />
                </LightTooltip>
              </ListItemIcon>

              <Typography>Blogs</Typography>
            </ListItem>
          </NavLink>
          <NavLink
            className="sidenavbar-textlink"
            to={{ pathname: "/faq" }}
          >
            <ListItem
              className={
                isUrlMatch("/faq") ? "background-red" : "hvr-pulse"
              }
            >
              <ListItemIcon
                className="drawerMenu"
                style={{ color: "orange", height: "25px", width: "25px" }}
              >
                <LightTooltip title={open ? "" :"FAQ"}>
                <img
                    src={isUrlMatch("/faq") ? FAQIcon : FAQIcon}
                    alt="FAQ"
                    className={isUrlMatch("/faq") ? "iconCol" : "hvr-pulse"}
                    style={{width:'21px'}}
                />
                </LightTooltip>
              </ListItemIcon>
              <Typography>FAQ</Typography>
            </ListItem>
          </NavLink>
          {/* {props.currentUser && props.currentUser.accountType === "Personal" && (
            <NavLink
              className="sidenavbar-textlink"
              to={{ pathname: "/alerts" }}
            >
              <ListItem
                className={
                  isUrlMatch("/alerts") ? "background-red" : "hvr-pulse"
                }
              >
                <ListItemIcon
                  className="drawerMenu"
                  style={{ color: "white" }}
                >
                  <LightTooltip title="Alerts">
                    <Badge
                      badgeContent={
                        alerts.alertCount === 0 ? "0" : alerts.alertCount
                      }
                      color="secondary"
                    >
                      <Alerts
                        className={
                          isUrlMatch("/alerts") ? "iconCol" : "hvr-pulse"
                        }
                      />
                    </Badge>
                  </LightTooltip>
                </ListItemIcon>
                <Typography>Alerts</Typography>
              </ListItem>
            </NavLink>
          )} */}
        </List>

        <Divider />
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
      </main>
    </div>
  );
}
const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  isAuth: state.user.isAuth,
});
const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NewHeader));

