const getHtml = obj => {
    let html = `
    <!DOCTYPE html>
    <meta charset="utf-8">
    <style>
        .containermain{
            margin: 0px 10%;
        }
    
        body{
            font-family: Arial, Helvetica, sans-serif;
        }
        .clearfix {
          overflow: auto;
        }
        .center{
          text-align: center;
        }
        .right{
          float: right;
        }
        .left{
          float: left;
        }
        .m-0{
          margin: 0px;
        }
        .add{
          font-size: 10px;
          margin-bottom: 5px;
        }
        table {
          border-collapse: collapse;
          width: 100%;
        }
        
        td {
          text-align: center;
          padding: 8px;
        }
        th {
          text-align: center;
          padding: 8px;
          background-color: #1976d2;
        }
        tr:nth-child(even) {
          background-color: #dddddd;
        }
        .top{
          margin-top: 50px;
        }
        .leftm{
          margin-left: 15%;
          
        }
        
        .topm{
          margin-top: -140px;
        }
        
        .topmargin{
            margin-top: -3%;
        }
        
        .topmar{
            margin-top: -3%;
        }
  
        .topmc{
          margin-top: -8%;
        }
      
        .botmc{
          margin-bottom: 10px;
        }
        
        .topmargin{
            margin-top: -3%;
        }
        
        .topmar{
            margin-top: -3%;
        }
        .head{
          font-size: 12px;
        }
        .addadd{
          font-size: 15px;
          margin-right: 5%;
          margin-bottom: 2%;
        }
        .dot{
          height: 10px;
          width: 10px;
          background-color: #f14c4c;
          border-radius: 50%;
          display: inline-block;
          margin-right: 1%;
        }
        .dotone{
          height: 15px;
          width: 15px;
          background-color: #27beb8;
          border-radius: 50%;
          display: inline-block;
          margin-right: 1%;
        }
        .dottwo{
          height: 15px;
          width: 15px;
          background-color: #ff9500;
          border-radius: 50%;
          display: inline-block;
          margin-right: 1%;
        }
        .dotthree{
          height: 15px;
          width: 15px;
          background-color: #3ba500;
          border-radius: 50%;
          display: inline-block;
          margin-right: 1%;
        }
        .dotfour{
          height: 15px;
          width: 15px;
          background-color: #0870e7;
          border-radius: 50%;
          display: inline-block;
          margin-right: 1%;
        }
        .dotfive{
          height: 10px;
          width: 10px;
          background-color: #a3a0fb;
          border-radius: 50%;
          display: inline-block;
          margin-right: 1%;
        }
        .inline{
          display: inline-block;
        }
        progress[value] {
          -webkit-appearance: none;
          appearance: none;
          width: 250px;
          height: 15px;
        }
        progress[value]::-webkit-progress-bar {
          background-color: #eee;
          border-radius: 5px;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
        }
        progress[value]::-webkit-progress-value {
            background-color: #0870e7;
            border-radius: 5px; 
            background-size: 35px 20px, 100% 100%, 100% 100%;
        }
        #pro1::-webkit-progress-value{
          background-color: #33A5FF;
        }
        #pro2::-webkit-progress-value{
          background-color: grey;
        }
        #pro3::-webkit-progress-value{
          background-color: #FFD700;
        }
        #pro4::-webkit-progress-value{
          background-color: cyan;
        }
        </style>
    <body>
    
    <div class="containermain">

      <div class="clearfix">
        <div class="center">
          <h4 class="center">Document Analyzer Report</h4>
          <h4 class="center">${obj.document.documentName}</h4>
        </div>
      </div>

      <div class="clearfix">
        <div class="center">
          <h5 class="top">Word Frequency</h5>
          <div>
            <table>
              <tr>
                <th class="head">Word</th>
                <th class="head">Frequency</th>
              </tr>
              
              ${ Object.keys(obj.wordFrequency)
                .slice(0, 5)
                .map((word) => (
                  '<tr>'+
                    '<td class="add">'+ word +'</td>'+
                    '<td class="add">' + obj.wordFrequency[word] + '</td>'+
                  '</tr>'
                ))}
      
            </table>
          </div>
        </div>
      </div>
    
      <div class="clearfix">
      

          ${ obj.sentence_limit && obj.sentence_limit.length ? obj.sentence_limit.slice(1, obj.sentence_limit.length).map((limit, index) => (
            `<div class="center">
            <h5 class="top">Highlight Sentence</h5>
            </div>
            <div class="">
              <h6>Sentences which contains ${limit} words : <b>${obj.actual_Limit[index+1]}</b></h6>
              <progress 
                id=${obj.color[index % 4]}
                value=${Math.round((obj.actual_Limit[index+1] / obj.total_sentences) * 100)} 
                max="100" 
              > ${Math.round((obj.actual_Limit[index+1] / obj.total_sentences) * 100)}% 
              </progress>
            </div>`
          )) : ''}

      </div>
  

      <div class="clearfix">
      <div class="center">
        <h5>Perspective Words</h5>
        <p class="addadd">
            <span class="dottwo"></span>First Person Words: <b>${ obj.perspectiveWords.first } &nbsp;&nbsp; </b><br/>
            <span class="dotthree"></span>Second Person Words: <b>${ obj.perspectiveWords.second } &nbsp;&nbsp; </b><br/>
            <span class="dotfour"></span>Third Person Words: <b>${ obj.perspectiveWords.third } &nbsp;&nbsp; </b><br/>
        </p>
      </div>
    </div>

    </div>

    </body>
    </html>
        `;
    return html;
  };
  
  module.exports = {
    getHtml
  };

  //       <div class="">
  //         <h6>Sentences which contains 5 words : <b>${obj.sentenceCounter.five}</b></h6>
  //         <progress 
  //           value=${obj.sentenceCounter.percentfive} 
  //           max="100" 
  //         > ${obj.sentenceCounter.percentfive}% 
  //         </progress>
  //       </div>
  //       <div class="">
  //        <h6>Sentences which contains 10 words : <b>${obj.sentenceCounter.ten}</b></h6>
  //        <progress 
  //          id="pro2"
  //          value=${obj.sentenceCounter.percentten} 
  //          max="100" 
  //        > ${obj.sentenceCounter.percentten}% 
  //        </progress>
  //       </div>
  //       <div class="">
  //         <h6>Sentences which contains 20 words : <b>${obj.sentenceCounter.twenty}</b></h6>
  //         <progress 
  //           id="pro3"
  //           value=${obj.sentenceCounter.percenttwenty} 
  //           max="100" 
  //         > ${obj.sentenceCounter.percenttwenty}% 
  //         </progress>
  //       </div>
  //       <div class="">
  //         <h6>Sentences which contains 30 words : <b>${obj.sentenceCounter.thirty}</b></h6>
  //         <progress 
  //           id="pro4"
  //           value=${obj.sentenceCounter.percentthirty} 
  //           max="100" 
  //         > ${obj.sentenceCounter.percentthirty}% 
  //         </progress>
  //       </div>
  