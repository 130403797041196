import http from "./httpService";
import { apiUrl, herokuUrl } from "./config.json";

let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = `${apiUrl}/subAdmin`;
} else {
  apiEndpoint = `${herokuUrl}/subAdmin`;
}

export async function getPendingOrgBlogs(input) {
    const data = await http.post(
    `${apiEndpoint}/getPendingOrgBlogs`,{userId:input.userId,orgId: input.orgId}
  );
  return data;
}

export async function getAllOrgBlogsSubAdmin(userId, orgId) {
  const data = await http.post(
    `${apiEndpoint}/getAllOrgBlogsSubAdmin`,{userId,orgId}
  );
  return data;
}

export async function getAllOrgDocs(id) {
  const data = await http.post(
    `${apiEndpoint}/getAllOrgDocs`,{id}
  );
  return data;
}

export async function getSharedDocsOfOrgUsers(id) {
  const data = await http.post(`${apiEndpoint}/getSharedDocsOfOrganizationUsers`,{id});
  return data;
}

export async function acceptPendingBlogBySubAdmin(blog) {
  const data = await http.post(`${apiEndpoint}/acceptPendingBlogBySubAdmin`, blog);
  return data;
}

export async function rejectPendingBlogBySubAdmin(blog) {
  const data = await http.post(`${apiEndpoint}/rejectPendingBlogBySubAdmin`, blog);
  return data;
}

export async function getBlogByIdSubAdmin(blogId,subadminId,orgId) {
  const data = await http.post(`${apiEndpoint}/getBlogByIdSubAdmin`, {
    blogId,subadminId, orgId
  });
  return data;
}

export async function publishBlogSubAdmin(blogId,subadminId,orgId) {
  const data = await http.post(`${apiEndpoint}/publishBlogSubAdmin`, {
    blogId,subadminId, orgId
  });
  return data;
}

export async function unpublishBlogSubAdmin(blogId,subadminId,orgId) {
  const data = await http.post(`${apiEndpoint}/unpublishBlogSubAdmin`, {
    blogId,subadminId, orgId
  });
  return data;
}

export async function searchPendingBlogsSubAdmin(userId, orgId, search) {
  const data = await http.post(`${apiEndpoint}/searchPendingBlogsSubAdmin`,{
    userId, orgId, search
  });
  return data;
}

export async function searchBlogSubAdmin(userId, orgId, search) {
  const data = await http.post(`${apiEndpoint}/searchBlogSubAdmin`,{
    userId, orgId, search
  });
  return data;
}

export default {
    getPendingOrgBlogs,
    getAllOrgBlogsSubAdmin,
    getAllOrgDocs,
    getSharedDocsOfOrgUsers,
    acceptPendingBlogBySubAdmin,
    rejectPendingBlogBySubAdmin,
    getBlogByIdSubAdmin,
    publishBlogSubAdmin,
    unpublishBlogSubAdmin,
    searchPendingBlogsSubAdmin,
    searchBlogSubAdmin
}  