import React, { useState, useEffect, useRef } from "react";
import BreadCrumb from "../Cards/BreadCrumbs";
import { EditorState, Modifier, CompositeDecorator, convertToRaw, convertFromRaw, KeyBindingUtil, ContentState, ContentBlock, RichUtils, SelectionState } from "draft-js";
import Editor, { createEditorStateWithText, composeDecorators } from "draft-js-plugins-editor";
import countSyllables from "syllable";
import readability from "readability-meter";
import { toast, ToastContainer } from "react-toastify";
import style from "./paraphrase.module.css";
import { PetAssistanceBtn, SideBtn, RightBottomBtn, Statistics, Feedback, } from "./ParaphraseComponent";
import { checkCompression, checkExpansion, checkPhrasing, paraphrase } from "../../services/englishCheckers";
import { Loader } from "../../utils/loader";
import { connect } from "react-redux";
import HeadingB from "../Cards/HeadingB";
import { Typography } from "@material-ui/core";
import { MdDelete } from "react-icons/md";
import { Card } from "react-bootstrap";
import Button from '@mui/material/Button';
import { useSelector } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import adminServices from "../../services/adminServices";
import InputBoxMui from "../ReusableComponents/InputBoxMui";
import FeatureButton from "../ReusableComponents/Button";
import { Circle } from "@mui/icons-material";
import { AiOutlineCopy } from "react-icons/ai";
import DownloadSharpIcon from '@mui/icons-material/DownloadSharp';
import UndoSharpIcon from '@mui/icons-material/UndoSharp';
import RedoSharpIcon from '@mui/icons-material/RedoSharp';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import UploadIcon from '@mui/icons-material/Upload';

import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import createCounterPlugin from "draft-js-counter-plugin";
import createUndoPlugin from 'draft-js-undo-plugin';
import 'draft-js/dist/Draft.css';
import 'draft-js-undo-plugin/lib/plugin.css';
import SpeechRecognition from 'react-speech-recognition';
import styled from "styled-components";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import JoditEditorComp from "../ReusableComponents/JoditEditorComp";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});


const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'blue',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const { hasCommandModifier } = KeyBindingUtil;
const counterPlugin = createCounterPlugin();
const undoRedoPlugin = createUndoPlugin();

const { CharCounter, WordCounter, LineCounter, CustomCounter } = counterPlugin;
const { UndoButton, RedoButton } = undoRedoPlugin
const plugins = [counterPlugin];
const undoredoPlugin = [undoRedoPlugin]



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function NewParaphrase({ features, trial=false }) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [secondaryeditorState, setSecondaryEditorState] = useState(EditorState.createEmpty());
  const [P_wordCount, P_setWordCount] = useState(0);
  const userDetails = useSelector((user) => user)
  const userID = userDetails.user.currentUser._id
  const [characters, setCharacters] = useState({ max: 1500, now: 0 });
  const [opCharacters, setOpCharacters] = useState({ max: 1500, now: 0 });
  const [paraText, setParaText] = useState('')


  const [sentenceSuggestion, setSentenceSuggestion] = useState([]);
  const [leftEditorText, setLeftEditorText] = useState('');
  const [rightEditorText, setRightEditorText] = useState({ text: '', status: '', isClear: false });
  const [statistics, setStatistics] = useState({});
  let [keyCode, setKeyCode] = useState(null)
  const [currentNavId, setCurrentNavId] = useState(0);
  const [preText, setPreText] = useState('');
  const [updatedTextResponse, setUpdatedTextResponse] = useState({
    suggestWords: null,
    text: null,
    leftEditorText: null,
  });
  const [loading, setLoading] = useState(false);
  const [column, setColumn] = useState({
    column: "col-md-6 p-0",
    feedback: false,
    statistics: false,
  });
  const [plagarizedContent, setPlagarizedContent] = useState("");
  const [tabValue, setTabValue] = React.useState(0);
  const [reactTour, setReactTour] = React.useState(false);
  const [dontShow, setDontShow] = useState(localStorage.getItem('dontShow') || false);
  const [tourSteps, setTourSteps] = React.useState([
    {
      selector: '.reactour-1',
      content: 'Write your content here',
      position: 'right',
    },
    {
      selector: '.reactour-2',
      content: 'On left side it displays the character limit of the content & on right a button to paraphrase the content',
      position: 'right',
    },
    {
      selector: '.reactour-3',
      content: 'Paraphrased content will displayed on right editor',
      position: 'left',
    },
    {
      selector: '.reactour-4',
      content: 'Utility bar displaying the sentence navigation, plagiarism checker, copy response content to clipboard and statistics bar',
      position: 'left',
    },
    {
      selector: '.reactour-5',
      content: 'Click on this tab to check paraphrased content for your existing document',
      position: 'bottom',
    }
  ]);
  const [currentParaphraseText, setCurrentParaphraseText] = useState();
  const [isParaphrase, setIsParaphrase] = useState(false);
  const [outputSentence, setOutputSentence] = useState("");
  const [promptText, setPromptText] = useState("")
  const [isCopy, setIsCopy] = useState(false);
  const [isCustomOpen, setIsCustomOpen] = useState(false)
  const [customText, setCustomText] = useState("Custom")
  const [customTextValue, setCustomTextValue] = useState("")
  const [isCustomSuggestion, setIsCustomSuggestion] = useState(true)
  const [suggestionText, setSuggestionText] = useState("Custom")
  const [inputarray, setInputArray] = useState([])
  const [outputarray, setOutputArray] = useState([])
  const [history, setHistory] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [P_File, setP_File] = useState("File");
  const [selectedTone, setSelectedTone] = useState("standard")
  const [selectDropdownTone, setSelectDropdownTone] = useState("select")
  const [editorContent, setEditorContent] = useState('')
  const [editorContentTemp, setEditorContentTemp] = useState('')
  
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
 
  // Update windowWidth on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
 
    window.addEventListener('resize', handleResize);
    // if(window.innerWidth <768){
    //   setSidebarOpen(!isSidebarOpen)
    // }
 
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // const fileInputRef = useRef(null);
  const [inputKey, setInputKey] = useState(0);


  let wordLimit = 1500


  // const VisuallyHiddenInput = styled('input')({
  //   clip: 'rect(0 0 0 0)',
  //   clipPath: 'inset(50%)',
  //   height: 1,
  //   overflow: 'hidden',
  //   position: 'absolute',
  //   bottom: 0,
  //   left: 0,
  //   whiteSpace: 'nowrap',
  //   width: 1,
  // });

  const paraeditor = useRef(null);

  const getParaphraseLimit = async () => {
    let data = await adminServices.getParaphraseLimit();
    setCharacters({ max: data.paraphrase_limit, now: 0 })
    console.log(characters)
  };

  const calculateWordCount = () => {
    const words = editorState.getCurrentContent().getPlainText('').split(/\s+/);
    const filteredWords = words.filter((word) => word !== ''); // Remove empty strings
    P_setWordCount(filteredWords.length);
  };


  const handleSpan = props => {
    // console.log("handleSpan==>", props);
    const { start, end } = props;
    console.log('props', props);
    let className = 'select-highlight';
    return (<span {...props} className={className}> {props.children} </span>);
  };

  const handleStrategy = (contentBlock, callback, contentState) => {
    console.log('currentNavId', currentNavId)
    sentenceSuggestion.forEach((obj) => {
      const id = obj.id;
      if (id === currentNavId) {
        console.log('obj start end', currentNavId, obj.start, obj.end)
        callback(obj.start, obj.end)
      }
    });
  }

  const generateDecorator = () => {
    console.info('generateDecorator_called');
    return new CompositeDecorator([
      {
        strategy: handleStrategy,
        component: handleSpan
      }
    ]);
  };

  const decoratorInit = () => {
    const editorStateWithDec = EditorState.set(editorState, { decorator: generateDecorator() });
    setEditorState(editorStateWithDec);

    const editorSecondaryStateWithDec = EditorState.set(secondaryeditorState, { decorator: generateDecorator() });
    setSecondaryEditorState(editorSecondaryStateWithDec);
  }

  const handleChangeText = (text) => {
    if (!text) return
    console.log("before text", text);
    text = text.replace(/['"]+/g, '');
    console.log("after text", text);
    const editorState = EditorState.createEmpty();
    const newContentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      text
    );
    const updatedEditor = EditorState.push(editorState, newContentState, 'insert-characters')
    setEditorState(EditorState.set(updatedEditor, { decorator: generateDecorator() }));
  }

  const onHandleClear = () => {
    console.log('init onHandleClear');
    setOutputSentence("")

    setEditorState(EditorState.createEmpty());
    setRightEditorText({ ...rightEditorText, isClear: true });
    leftSuggestion([]);
    setCurrentNavId(0);
    setIsParaphrase(false);
    if (plagarizedContent) {

      colChange("statistics");
    }
    setPlagarizedContent("");
    setSecondaryEditorState(EditorState.createEmpty())
  }

  const handleTabChange = (event, newValue) => {
    console.log('handleTabChange');
    setTabValue(newValue)
  }

  const onLeftHandleNavigation = (currentId, text) => {
    console.log('init onLeftHandleNavigation', currentId);
    handleChangeText(text);
    setCurrentNavId(currentId)
  }

  useEffect(() => {
    getParaphraseLimit();

  }, []);

  useEffect(() => {
    decoratorInit();

  }, [currentNavId]);



  const onLeftEditorChange = (event) => {
    let previous_Content = editorState.getCurrentContent().getPlainText();
    let current_Content = event.getCurrentContent().getPlainText();

    const contentState = event.getCurrentContent();
    const rawText = convertToRaw(contentState).blocks
        .map((block) => block.text.trim())
        .join(' ')
        .trim();

    if (previous_Content !== current_Content) {
        setIsParaphrase(false);
        setRightEditorText({ ...rightEditorText, isClear: true });
        leftSuggestion([]);
        setCurrentNavId(0);
    }

    const selectionState = event.getSelection();
    if (!selectionState.isCollapsed()) {
        const selectedText = event.getCurrentContent().getBlockForKey(selectionState.getStartKey())
            .getText()
            .slice(selectionState.getStartOffset(), selectionState.getEndOffset());

        const selectedWords = selectedText.toLowerCase().split(/\s+/);
        const uniqueSelectedWords = [...new Set(selectedWords)];

        console.log("Highlighted words:", uniqueSelectedWords);

        const secondaryEditorContentState = secondaryeditorState.getCurrentContent();
        
        const secondaryEditorRawText = convertToRaw(secondaryEditorContentState).blocks
            .map((block) => block.text.trim())
            .join(' ')
            .trim();

        const sentences = secondaryEditorRawText.split(/[.!?]/);

        let maxCount = 0;
        let highlightedSentence = null;
        const wordCountMap = {};

        sentences.forEach(sentence => {
            const sentenceWords = sentence.toLowerCase().split(/\s+/);
            uniqueSelectedWords.forEach(word => {
                if (sentenceWords.includes(word)) {
                    if (!wordCountMap[sentence]) {
                        wordCountMap[sentence] = 1;
                    } else {
                        wordCountMap[sentence]++;
                    }
                }
            });
        });

        for (const [sentence, count] of Object.entries(wordCountMap)) {
            if (count > maxCount) {
                maxCount = count;
                highlightedSentence = sentence;
            }
        }


        console.log("Highlighted sentence:", highlightedSentence);
        console.log("jodit editor", editorContent)
        
        let newContent = editorContentTemp;

        
        if (highlightedSentence !== null) {
          const regex = new RegExp(`(${highlightedSentence.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')})`, 'g');
          newContent = newContent.replace(regex, `<span style="background-color: yellow;">${highlightedSentence}</span>`);
          
        }

        console.log(newContent, "new jodit content");
        setEditorContent(newContent)

    }

    setEditorState(event);
    let length = current_Content.length;
    if (characters.max < length)
        length = characters.max;
    setCharacters({ ...characters, now: length });
};








  const myKeyBindingFn = (e) => {
    // if you paste thecommand this if conditon will invoke
    if (e.keyCode === 86 /* `V` key */ && hasCommandModifier(e)) {
      keyCode = e.keyCode;
    }
  }

  const removeString = (event) => {
    console.log('Remove String function called');
    let maxLength = characters.max - 1;
    const contentState = event.getCurrentContent();
    let convertRaw = convertToRaw(contentState);
    console.log('convertRaw', convertRaw);
    const blocks = convertRaw.blocks;

    let end = 0;
    const updatedBlock = blocks.map((block) => {
      const blockText = block.text;
      const blockTextLen = blockText.length;

      if (maxLength < 0) {
        toast(`2.You are reached maximum character limit ${characters.max}`);
        end = 0;
      }
      else {
        if (blockTextLen < maxLength) {
          maxLength = maxLength - blockTextLen;
          console.log('maxLength', maxLength, 'blockTextLen', blockTextLen);
          end = blockTextLen;
        }
        else {
          end = Math.abs(maxLength);
          maxLength = -1;
          toast(`3.You are reached maximum character limit ${characters.max}`);
        }
      }
      console.log('endLen', end)
      const text = blockText.slice(0, end);
      block.text = text;
      return block;
    });

    convertRaw.blocks = updatedBlock;
    console.log('updatedRaw', convertRaw);
    let newBLocks = convertRaw.blocks;
    let slicedString = ``;
    newBLocks.map(block => {
      slicedString += block.text;
      return block;
    });
    slicedString = slicedString.slice(0, characters.max);
    handleChangeText(slicedString);
    // const updatedContentState = convertFromRaw(convertRaw);
    // const updatedEditorState = EditorState.createWithContent(updatedContentState);
    // setEditorState(updatedEditorState)
  }

  // const _handleBeforeInput = () => {


  //  let curr_State= editorState.getCurrentContent().getPlainText().trim().split(/\s+/).length
  //   if (curr_State >= maxWords) {
  //     toast.warn("Word limit exceeded",{
  //         position: toast.POSITION.TOP_RIGHT
  //      }  );
  //     return 'handled';

  // }
  // return 'not-handled';

  // }

  const sentenceOutput = () => {
    let inputText = editorState.getCurrentContent().getPlainText()

    let splitinputtext = inputText.split(" ")
    let splitOutputtext = outputSentence.split(" ")

    console.log("split_", splitOutputtext, splitinputtext)
    setInputArray(splitinputtext)
    setOutputArray(splitOutputtext)

    // splitinputtext.forEach(function (a) {
    //   splitOutputtext  = splitOutputtext.split(RegExp("\\b" + a + "\\b", "i")).join("<div>" + a + "</div>");
    // });

    // console.log("splitOutputtext_",splitOutputtext)


    const differences = splitinputtext.map((word, index) => {
      if (word !== splitOutputtext[index]) {
        return (<span key={index} style={{ color: 'orange' }}>{splitOutputtext[index]}</span>);
      }
      return (<span key={index}>{splitOutputtext[index]}</span>);
    });

    return differences;
  }

  const GrammerCheckForParaphrase = async (type) => {
    setLoading(true)
    let array = []
    console.log(type, "typeeee")
    console.log("editorState_", editorState, editorState.getCurrentContent().getPlainText(), leftEditorText, rightEditorText);
    // let data = {
    //   text: `Rephrase the text to enhance clarity, conciseness, and engagement ${type !== undefined ? `in the mode of a ${type}` : ""} retaining the original input format '${editorState.getCurrentContent().getPlainText()}'`
    // }
    let data = {}
    if (type !== undefined) {
      if (type == "Like a story" || type == "Like a poem" || type == "Like a letter" || type == "Like a sales executive" || type == "Like a news" || type == "Like a Doctor" || type == "Like a CEO") {
        data = {
          text: editorState.getCurrentContent().getPlainText(),
          tone: "custom",
          customTone: type,
          userId: userID,
          isCoAuthor: false
        }
      } else {
        data = {
          text: editorState.getCurrentContent().getPlainText(),
          tone: type,
          userId: userID,
          isCoAuthor: false
        }
      }
    } else {
      data = {
        text: editorState.getCurrentContent().getPlainText(),
        tone: "defaultPrompt",
        userId: userID,
        isCoAuthor: false
      }
    }

    console.log(data, "textttt");
    let paraphraseResponse = await checkPhrasing(data)

    if (paraphraseResponse.data !== "") {
      // let responseOutput = paraphraseResponse?.data?.output[0]
      if (paraphraseResponse.data.output[0].startsWith('\n')) {
        paraphraseResponse.data.output[0] = paraphraseResponse.data.output[0].substring(1); 
    }
      const summaryText = paraphraseResponse.data.output.join('\n');
      const summaryText2 = summaryText.replace(/\n/g, "<br>");
      setEditorContent(`<p>${summaryText2}</p>`)
      setEditorContentTemp(`<p>${summaryText2}</p>`)
      console.log(summaryText, "summary");
      let wordLength = summaryText.split(" ")
      let responseOutput = ContentState.createFromText(summaryText);
      // let wordLength = responseOutput.split(" ")
      console.log("paraphraseResponse_", responseOutput, wordLength.length);
      setOpCharacters({ now: wordLength.length })

      const newEditorState = EditorState.createWithContent(responseOutput);
      setSecondaryEditorState(newEditorState);

      setOutputSentence(responseOutput)
      setIsParaphrase(true)
      setLoading(false)
      // let resResult = {
      //     changedWords: responseOutput,
      //     heading: "Word Expansion"
      // }

    }
    setLoading(false)
  }

  useEffect(() => {
    const currentEditorContent = secondaryeditorState.getCurrentContent();
    const currentEditorText = currentEditorContent.getPlainText();
    setParaText(currentEditorText);

  }, [secondaryeditorState])

  const checkWordsCompression = async (e) => {
    setLoading(true)
    let data = {
      text: editorState.getCurrentContent().getPlainText(),
    }
    let wordCompressionResponse = await checkCompression(data)
    if (wordCompressionResponse.data !== "") {
      if (wordCompressionResponse.data.output[0].startsWith('\n')) {
        wordCompressionResponse.data.output[0] = wordCompressionResponse.data.output[0].substring(1); 
    }
      // let responseOutput = wordCompressionResponse?.data?.output[0]
      const summaryText = wordCompressionResponse.data.output.join('\n');
      setEditorContent(`<p>${summaryText}</p>`)
      setEditorContentTemp(`<p>${summaryText}</p>`)
      const newContentState = ContentState.createFromText(summaryText);
      // let resResult = {
      //     changedWords: responseOutput,
      //     heading: "Word Compression"
      // }
      // setOutputSentence(responseOutput)
      // const contentState = ContentState.createFromText(responseOutput);
      const newEditorState = EditorState.createWithContent(newContentState);
      setSecondaryEditorState(newEditorState);
      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  const checkWordsExpansion = async (e) => {
    setLoading(true)
    let array = []
    let data = {
      text: editorState.getCurrentContent().getPlainText()
    }
    let wordExpansionResponse = await checkExpansion(data)
    if (wordExpansionResponse.data !== "") {
      if (wordExpansionResponse.data.output[0].startsWith('\n')) {
        wordExpansionResponse.data.output[0] = wordExpansionResponse.data.output[0].substring(1); 
    }
      // let responseOutput = wordExpansionResponse?.data?.output[0]
      const summaryText = wordExpansionResponse.data.output.join('\n');
      setEditorContent(`<p>${summaryText}</p>`)
      setEditorContentTemp(`<p>${summaryText}</p>`)
      const newContentState = ContentState.createFromText(summaryText);
      // let resResult = {
      //     changedWords: responseOutput,
      //     heading: "Word Expansion"
      // }
      // const contentState = ContentState.createFromText(responseOutput);
      const newEditorState = EditorState.createWithContent(newContentState);
      setSecondaryEditorState(newEditorState);
      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  const handleFutureButton = (type) => {
    console.log(editorState.getCurrentContent().getPlainText(), "editorState.getCurrentContent().getPlainText()");
    if (editorState.getCurrentContent().getPlainText() === "") {
      toast.warning("Please input some text!")
      return
    }
    if (editorState.getCurrentContent().getPlainText('').split(/\s+/).length > 1500) {
      toast.warn("Words limit exceeded")
      return;
    }
    console.log("param", type)
    if (type == "shorten") {
      console.log("compression")
      checkWordsCompression(type)
      setCustomText("Custom")
    } else if (type == "expand") {
      console.log("checkWordsExpansion")
      checkWordsExpansion()
      setCustomText("Custom")
    } else if (type == "custom") {
      console.log("checkCustom")
      setIsCustomOpen(true)
    }
    else {
      GrammerCheckForParaphrase(type)
      console.log("func called");
      setCustomText("Custom")
    }
  }


  const onPetAssistant = () => {
    if (editorState.getCurrentContent().getPlainText().trim().split(/\s+/).length > 1500) {
      toast.warn("Words limit exceeded");
      return
    }
    GrammerCheckForParaphrase('standard')


    // const current_content = editorState.getCurrentContent();
    // const text = current_content.getPlainText();
    // console.log("input_text===>", text);
    // if (text.length < 10) {
    //   toast('Please Enter at least one sentence');
    //   return;
    // }
    // let isTextChanged = false;
    // let isPara = true;
    // if (preText !== text) {
    //   isTextChanged = true;
    //   setPreText(text);
    // }
    // setIsParaphrase(isPara);
    // setRightEditorText({ text, status: !rightEditorText.status, isTextChanged, isClear: false });

    console.log('leftEditorText', leftEditorText);
  }

  const statisticsFn = async () => {
    console.log("init statisticsFn");
    const editorRight = currentParaphraseText;
    const editorLeft = rightEditorText.text;

    if (!editorRight || !editorLeft) {
      let statistic = {
        sentance: 0,
        words: 0,
        avgWords: 0,
        percentChange: 0,
        syllables: 0,
        readability: 0,
      };
      console.log("statistic", statistic);
      setStatistics(statistic);
      return;
    }

    const sentance = editorRight.split(/\.+/);
    if (!sentance[sentance.length - 1]) {
      sentance.pop();
    }
    const words = editorRight.split(/\s+/);
    const avgWords = ((sentance.length / words.length) * 100).toFixed(2);
    const count = words.reduce((count, word, i) => {
      word = word.replace(/[^a-zA-Z ]/g, "");
      const pattern = `\\b(${word})\\b`;
      const regex = new RegExp(pattern, 'g');;
      if (!regex.test(editorLeft)) count++;
      return count;
    }, 0);
    const percentChange = ((count / words.length) * 100).toFixed(2);
    let syllables = countSyllables(editorRight);
    const grade = await readability.ease(editorRight);
    const score = grade.score.toFixed(2);

    let statistic = {
      sentance: sentance.length,
      words: words.length,
      avgWords,
      percentChange,
      syllables,
      readability: score,
    };
    console.log("statistic", statistic);
    setStatistics(statistic);
  };

  const colChange = (status) => {
    console.log(status); // feedback, statistics
    let feedback = false;
    let statistics = false;
    let mycol = "col-md-4";
    if (status === "feedback") {
      feedback = !column.feedback;
      if (!feedback) mycol = "col-md-6";
    } else if (status === "statistics") {
      statistics = !column.statistics;
      if (!statistics) mycol = "col-md-6";
      else statisticsFn();
    }
    setColumn({ column: mycol, feedback, statistics });
  };

  const assignplagarizedContent = (htmlContent) => {
    setPlagarizedContent(htmlContent);
  }

  const leftSuggestion = (suggestion) => {
    console.log('suggestion', suggestion);
    setSentenceSuggestion(suggestion);
  }
  const ParaContent = (data) => {
    let text = data.replace(
      /^\s*[\r\n]/gm,
      ""
    );
    const length = text.length;
    console.log(data)
    // const max = characters.max;
    // characterLimit(max, length);
    setLeftEditorText(text.replace(/^\s*[\r\n]/gm, ''));
    sessionStorage.setItem("paraphraseText", text);
    setCharacters({ ...characters, now: length });
    setRightEditorText({ text: "", status: "", isClear: false });
    if (length > characters.max) {
      text = text.slice(0, characters.max);
      handleChangeText(text);
    } else {
      handleChangeText(text);
    }
    // if (max >= length) {
    //   setLeftEditorText(text);
    //   sessionStorage.setItem("paraphraseText", text);
    //   setCharacters({ ...characters, now: length });
    // } else {
    //   toast.error("Character limit exceeded.");
    // }
    setTabValue(0);
  };

  const handlePastedText = (text) => {
    // const {editorState} = this.state;

    console.log("before text", text);
    text = text.replace(/['"]+/g, '');
    console.log("after text", text);
    const blockMap = ContentState.createFromText(text.trim()).blockMap;
    const newState = Modifier.replaceWithFragment(editorState.getCurrentContent(), editorState.getSelection(), blockMap);
    setEditorState(EditorState.push(editorState, newState, 'insert-fragment'));
    return true;
  }




  const handleDontShow = (e) => {
    const dontShow = e.target.checked;
    if (dontShow) {
      localStorage.setItem("dontShow", true);
      sessionStorage.setItem("dontShow", true);
    } else {
      localStorage.setItem("dontShow", false);
      sessionStorage.setItem("dontShow", true);
    }
    setDontShow(dontShow);
  };


  const onHandleDelete = () => {
    // console.log(e.target.value);
    // const value = e.target.value
    // if (value === "true") {
    setEditorState(EditorState.createEmpty());
    setSecondaryEditorState(EditorState.createEmpty());
    setOutputSentence("")
    setRightEditorText({ ...rightEditorText, isClear: true });
    leftSuggestion([]);
    setCurrentNavId(0);
    setIsParaphrase(false);
    if (plagarizedContent) {
      colChange("statistics");
    }
    setPlagarizedContent("");


  }

  const handleDelete = () => {
    setSecondaryEditorState(EditorState.createEmpty());
    setEditorContent('<p></p>')

  }

  const handlecopy = () => {
    // console.log('editorRight', editorRight);
    if (!outputSentence) {
      toast.warn(`You don't have text, to copy`);
      return;
    }
    navigator.clipboard.writeText(paraText).then(
      function () {
        console.log("Async: Copying to clipboard was successful!");
        if (outputSentence) toast.info("Your text Copied!");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  }

  const handleDownload = () => {
    // const contentState = outputSentence;
    // const rawText = outputSentence
    // const textToDownload = rawText.blocks.map(block => block.text).join('\n');
    if (!outputSentence) {
      toast.warn(`You don't have text, to download`);
      return;
    }
    const blob = new Blob([secondaryeditorState.getCurrentContent().getPlainText()], { type: 'text/plain' });

    const anchor = document.createElement('a');
    anchor.href = URL.createObjectURL(blob);
    anchor.download = 'text_content.doc';
    anchor.click();
    URL.revokeObjectURL(anchor.href);
  };

  const handleClose = () => {
    setIsCustomOpen(false)
    setCustomText(customText)
  }

  const handleCustomSelection = (type) => {
    // setCustomTextValue(`Like a${type}`)
    console.log(editorState.getCurrentContent().getPlainText(), "editorState.getCurrentContent().getPlainText()");
    if (editorState.getCurrentContent().getPlainText() === "") {
      toast.warning("Please input some text!")
      return
    }
    let splitCustomText
    let suggestionType = type
    splitCustomText = suggestionType.split(' ')
    suggestionType = splitCustomText[2]
    console.log("splitCustomText", suggestionType)
    GrammerCheckForParaphrase(type)
    setCustomText(type)
    setIsCustomOpen(false)
    setIsCustomSuggestion(true)
    setSuggestionText("Custom")
    setCustomTextValue("")
  }

  const handleCustomClick = () => {
    console.log("customText_", customTextValue)
    setIsCustomSuggestion(false)
    setSuggestionText(customTextValue)
    // let splitCustomText = customTextValue.split(' ')
    // GrammerCheckForParaphrase(splitCustomText[2])
    // setIsCustomOpen(false)
  }

  const handleSuggestionChange = (e) => {
    console.log("Suggestion Text", e.target.value)
    let value = e.target.value
    //  value = value.replace(/[0-9]' `@#$%^&*()_+!:"}{|<>,.}]/g,'')
    console.log("value_", value)
    setCustomTextValue(value)

  }

  // useEffect(() => {
  //   setOutputSentence(history[currentIndex]);
  // }, [currentIndex, history]);

  const handleOuputChange = (newEditorState) => {
    console.log("opChange_", newEditorState)
    // let value = e.target.value
    // setHistory([...history.slice(0, currentIndex + 1), value]);
    // setCurrentIndex(currentIndex + 1);
    setSecondaryEditorState(newEditorState)
  }

  const handleUndo = () => {
    console.log("handleUndo_")
    // if (currentIndex > 0) {
    //   setCurrentIndex(currentIndex - 1);
    //   setOutputSentence(history[currentIndex - 1]);
    // }
  };

  const handleRedo = () => {
    console.log("handleRedo_")

    if (currentIndex < history.length - 1) {
      setCurrentIndex(currentIndex + 1);
      setOutputSentence(history[currentIndex + 1]);
    }
  };

  const countWords = (editorState) => {
    const contentState = editorState.getCurrentContent();
    const rawText = convertToRaw(contentState).blocks
      .map((block) => block.text.trim())
      .join(' ')
      .trim();

    return rawText.length > 0 ? rawText.split(/\s+/).length : 0;
  };










  const wordCountEditor1 = countWords(editorState);
  const wordCountEditor2 = countWords(secondaryeditorState);
  const handleKeyCommand = (command, state) => {

    const newState = RichUtils.handleKeyCommand(state, command);

    // if(newState.getCurrentContent().getPlainText().trim().split(/\s+/).length <= maxWords){
    if (newState) {

      onLeftEditorChange(newState);

      return 'handled';
    }
    return 'not-handled';



  };



  const handleUpload1 = () => {
    if (editorState.getCurrentContent().getPlainText().split(/[ -]+/).length > 1500) {
      toast.warn(`Words limit exceeded`)

      setP_File("")
      return
    }
    setP_File("File")
  }

  const handleFileChange = (e) => {
    console.log("File selected", e.target.files); // Check if this is logged

    const file = e.target.files[0];

    if (!file.name.endsWith('.txt')) {
      toast.warn("Only .txt files are supported");
      return;
    }

    if (file) {
      if (file.type === "application/pdf") {
        // If the file is a PDF, show a toast message and return
        toast.warn("PDF format is not supported.");
        return;
      }
      const reader = new FileReader();

      reader.onload = (event) => {
        const content = event.target.result;
        const text = content.toString();

        let words = text.split(/[ -]+/).length;
        if (words > 1500) {
          toast.warn("Words limit exceeded");
          return;
        }
        console.log("text_", text)

        const contentState = ContentState.createFromText(text);
        const newEditorState = EditorState.createWithContent(contentState);
        setEditorState(EditorState.createEmpty());
        setInputKey((prevKey) => prevKey + 1);
        setEditorState(newEditorState);
      };

      reader.readAsText(file);
    }
  };
  console.log(customText, "customText");

const handleChangeEditor = (newContent) => {
  setEditorContent(newContent);
}


  return (
    <div style={{width:windowWidth <= 1100 ? "100%" :"100%", paddingTop: trial ? '5%' : '',marginLeft:windowWidth <= 1100 ? "" : "-30px"}} >
      <Loader loading={loading} />


      <Modal
        isOpen={isCustomOpen}
        // toggle={() => setIsCustomOpen(true)}
        style={{
          // position: "absolute",
          // left: "43%",
          top: "33%",
        }}
      >
        <ModalHeader toggle={handleClose}>
          <div className="d-flex px-2 justify-content-between">
            <div
              style={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: 600,
                color: "#303030",
                fontSize: "1rem",
              }}
            >Make my text sound...</div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="d-flex justify-content-between">
            <p
              style={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: 600,
                color: "#303030",
                fontSize: "1rem",
              }}
              className="col-md-10"
            >
              <InputBoxMui
                fullWidth
                placeholder={"E.g., “like a News”"}
                value={customTextValue}
                onChange={e => handleSuggestionChange(e)}

              />
            </p>
            <div className="ml-2">
              <button
                className="btn btn-primary"
                onClick={handleCustomClick}
                disabled={customTextValue !== "" ? false : true}
              >
                Done
              </button>
            </div>
          </div>
          <div className="px-2">
            <div
              style={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: 600,
                color: "#525252",
                fontSize: "14px",
              }}
            >Suggestions:</div>
            <div className="d-flex justify-content-evenly mt-2">
              <FeatureButton text={"Like a Story"} style={{ width: "120px" }} size={"md"} onClick={() => handleCustomSelection("Like a story")} />
              <FeatureButton text={"Like a Poem"} divClassName={"ml-3"} style={{ width: "120px" }} size={"md"} onClick={() => handleCustomSelection("Like a poem")} />
            </div>
            <div className="d-flex justify-content-evenly mt-2">
              <FeatureButton text={"Like a Ceo"} style={{ width: "120px" }} size={"md"} onClick={() => handleCustomSelection("Like a CEO")} />
              <FeatureButton text={"Like a Doctor"} divClassName={"ml-3"} style={{ width: "120px" }} size={"md"} onClick={() => handleCustomSelection("Like a Doctor")} />
            </div>
            <div className="d-flex justify-content-evenly mt-2">
              <FeatureButton text={"Like a News"} style={{ width: "120px" }} size={"md"} onClick={() => handleCustomSelection("Like a news")} />
              <FeatureButton text={"Like a Sales Executive"} divClassName={"ml-3"} style={{ width: windowWidth < 400 ? '130px' : "160px" }} size={"md"} onClick={() => handleCustomSelection("Like a sales executive")} />
            </div>
            <div className="d-flex justify-content-evenly mt-2">
              <FeatureButton text={"Like a Letter"} style={{ width: "120px" }} size={"md"} onClick={() => handleCustomSelection("Like a letter")} />
              <FeatureButton text={suggestionText} disable={isCustomSuggestion} divClassName={"ml-3"} style={{ width: "120px" }} size={"md"} onClick={() => handleCustomSelection(suggestionText)} />
            </div>
          </div>
        </ModalBody>
      </Modal>

      
      {/* tones  */}
      <div className="mt-2 col-md-12" style={{width:windowWidth <= 1100 ? "100%" : "100%",boxShadow: "2px 2px 5px -2px #000000", backgroundColor: "white", paddingLeft: "5px"}}>
        <div style={{ width:windowWidth <= 1100 ? "100%" : "100%", padding: "8px 0px 0px 0px", display: "flex", justifyContent: "space-around", alignItems: "center", borderBottom: "4px solid gray", flexWrap: "wrap" }}>
          <div style={{ width:windowWidth <= 1100 ? "100%" : "7%", fontWeight: "bold", fontSize: "20px", paddingBottom: "8px", marginLeft: "10px" }}>Tones</div>
          <div style={{width:windowWidth <= 1100 ? "100%" : "70%",display:"flex",flexWrap:windowWidth <= 1100 ? "wrap" : "",justifyContent:windowWidth <= 1100 ? "start" : "space-between",columnGap:windowWidth <= 1100 ? "2.5%" : "",rowGap:windowWidth <= 1100 ? "8px" : "",paddingLeft:"5%",marginBottom:windowWidth <= 1000 ? "15px" : ""}}>
          <button disable={editorState.getCurrentContent().getPlainText() === ""} style={{ width:customText !== "Custom" ? "10%" : "30%",fontSize:windowWidth <= 400 ? "10px" : "", borderBottom: selectedTone == "standard" ? "4px solid dodgerblue" : "", borderRadius:windowWidth <= 1100 ? "5px" : "0px", outline: "none",border:windowWidth <= 1100 ? "1px solid #0069d9" : "" }} size={"sm"} onClick={() => { handleFutureButton("standard"); setSelectedTone("standard"); }} >Standard</button>
          <button disable={editorState.getCurrentContent().getPlainText() === ""} style={{ width: customText !== "Custom" ? "10%" : "30%",fontSize:windowWidth <= 400 ? "10px" : "", borderBottom: selectedTone == "formal" ? "4px solid dodgerblue" : "", borderRadius:windowWidth <= 1100 ? "5px" : "0px", outline: "none",border:windowWidth <= 1100 ? "1px solid #0069d9" : "" }} size={"sm"} onClick={() => { handleFutureButton("formal"); setSelectedTone("formal"); }} >Formal</button>
          <button disable={editorState.getCurrentContent().getPlainText() == ""} style={{ width: customText !== "Custom" ? "10%" : "30%",fontSize:windowWidth <= 400 ? "10px" : "", borderBottom: selectedTone == "casual" ? "4px solid dodgerblue" : "", borderRadius:windowWidth <= 1100 ? "5px" : "0px", outline: "none",border:windowWidth <= 1100 ? "1px solid #0069d9" : "" }} size={"sm"} onClick={() => { handleFutureButton("casual"); setSelectedTone("casual"); }}>Casual</button>
          <button disable={editorState.getCurrentContent().getPlainText() == ""} style={{ width: customText !== "Custom" ? "10%" : "30%",fontSize:windowWidth <= 400 ? "10px" : "", borderBottom: selectedTone == "positive" ? "4px solid dodgerblue" : "", borderRadius:windowWidth <= 1100 ? "5px" : "0px", outline: "none",border:windowWidth <= 1100 ? "1px solid #0069d9" : "" }} size={"sm"} onClick={() => { handleFutureButton("positive"); setSelectedTone("positive"); }} >Positive</button>
          <button disable={editorState.getCurrentContent().getPlainText() == ""} style={{ width: customText !== "Custom" ? "10%" : "30%",fontSize:windowWidth <= 400 ? "10px" : "", borderBottom: selectedTone == "confident" ? "4px solid dodgerblue" : "", borderRadius:windowWidth <= 1100 ? "5px" : "0px", outline: "none",border:windowWidth <= 1100 ? "1px solid #0069d9" : "" }} size={"sm"} onClick={() => { handleFutureButton("confident"); setSelectedTone("confident"); }}>Confident</button>
          <button disable={editorState.getCurrentContent().getPlainText() == ""} style={{ width: customText !== "Custom" ? "10%" : "30%",fontSize:windowWidth <= 400 ? "10px" : "", borderBottom: selectedTone == "friendly" ? "4px solid dodgerblue" : "", borderRadius:windowWidth <= 1100 ? "5px" : "0px", outline: "none",border:windowWidth <= 1100 ? "1px solid #0069d9" : "" }} size={"sm"} onClick={() => { handleFutureButton("friendly"); setSelectedTone("friendly"); }} >Friendly</button>
          <button disable={editorState.getCurrentContent().getPlainText() == ""} style={{ width: customText !== "Custom" ? "10%" : "30%",fontSize:windowWidth <= 400 ? "10px" : "", borderBottom: selectedTone == "simple" ? "4px solid dodgerblue" : "", borderRadius:windowWidth <= 1100 ? "5px" : "0px", outline: "none",border:windowWidth <= 1100 ? "1px solid #0069d9" : "" }} size={"sm"} onClick={() => { handleFutureButton("simple"); setSelectedTone("simple"); }} >Simple</button>
          </div>
         <div style={{width:windowWidth <= 1100 ? "100%" : "16%",display:"flex",justifyContent:windowWidth <= 1100 ? "space-around" : "space-between",marginBottom:windowWidth <= 1100 ? "10px" : ""}}>
         <FormControl style={{ width: customText !== "Custom" ? "90px" : "110px", border: "1px solid dodgerblue", borderRadius: "7px" }}>
            <Select
              value={selectDropdownTone && selectedTone === "shorten" || selectDropdownTone && selectedTone === "expand" ? selectDropdownTone : "select"}
              onChange={(event) => {
                const selectedValue = event.target.value;
                handleFutureButton(selectedValue);
                setSelectedTone(selectedValue);
                setSelectDropdownTone(selectedValue)
              }}
              style={{ height: "32px" }}
            >
              <MenuItem value={"select"}>Select</MenuItem>
              <MenuItem value={"shorten"}>Shorten</MenuItem>
              <MenuItem value={"expand"}>Expand</MenuItem>
            </Select>
          </FormControl>

          <button disable={editorState.getCurrentContent().getPlainText() == ""} style={{ width: customText !== "Custom" ? "140px" : "110px", border: "1px solid dodgerblue", borderRadius: "7px", outline: "none", fontSize: "14px", color: "dodgerblue", padding: "8px 8px", marginRight: "10px" }} onClick={() => { handleFutureButton("custom"); setSelectedTone("custom"); }} >{customText}</button>

         </div>
          {/* <button  disable={editorState.getCurrentContent().getPlainText() == "" } style={{ width: customText !== "Custom" ? "80px" : "110px", borderBottom: selectedTone == "shorten" ?  "4px solid dodgerblue" : "" , borderRadius: "0px", outline: "none" }} size={"sm"} onClick={() => {handleFutureButton("shorten"); setSelectedTone("shorten");}} >Shorten</button> */}
    
        </div>
      </div>

      {/* <div value={tabValue} index={0}> */}
      <div className="col-md-12 d-flex p-0 justify-content-between" style={{width:windowWidth <= 1100 ? "100%" : "100%",display:"flex", flexDirection:windowWidth <= 1100 ? "column" : "row"}}>
        <div  style={{width:windowWidth <= 1100 ? "100%" : "50%", borderRadius: "8px 0px 0px 8px"}}>
          {characters.now > 0 ? (<>
            {dontShow ? (<LightTooltip title="Delete"><button
              className={style.trash}
              onClick={onHandleClear}
              style={{ position: "absolute", top: "12px", right: "2px", color: "red" }}
            >

              <MdDelete
                data-placement="top"
                // title="Clear editor"
                size={"24px"}
              />

            </button> </LightTooltip>) : (<LightTooltip title="Delete"><button
              className={style.trash}
              style={{ position: "absolute", top: "12px", right: "2px", color: "red" }}
            >

              <MdDelete
                data-toggle="modal"
                //  data-target="#myModal"
                data-placement="top"
                // title="Clear editor"
                size={"24px"} color="red"
                onClick={onHandleDelete}
              />

            </button></LightTooltip>)}
          </>) : ("")}
          <div style={{ background: "white", boxShadow: "2px 2px 5px -2px #000000", borderRadius: '8px 0px 0px 8px' }} className="pb-3">
            <div className="editor" style={{
              paddingRight: "48px",
              minHeight: "55vh",
              maxHeight: "55vh",
              overflow: "auto",
              // borderTopLeftRadius: "8px !important",
              // borderTopRightRadius: "8px !important",
              // paddingBottom: "100px",
              // boxShadow: "2px 2px 0px -2px #000000",
            }}
            >
              <Editor
                onChange={(e) => onLeftEditorChange(e)}
                // handleBeforeInput={_handleBeforeInput}
                keyBindingFn={myKeyBindingFn}
                editorState={editorState}
                plugins={plugins}
                autoCapitalize="sentences"
                handlePastedText={handlePastedText}
                placeholder="Please enter the text"
                handleKeyCommand={handleKeyCommand}
              />
            </div>
            <div className={`text-e mx-3 d-flex mt-0 justify-content-between bottomeditor ${style.moveup}`} style={{ boxShadow: "2px 2px 5px -2px #000000", height: "52px" }}>
              <div>
{windowWidth <= 1100 && ( <>
  <p className="ft_sm mt-1 d-flex">
                  <Circle style={{ height: "7px", width: "7px", marginTop: "8px", color: "#3295F7" }} />
                  <>
                  <div className="ml-2" style={{ fontSize: "10px", fontWeight: "400px", color: "#3295F7" }}><b>{wordCountEditor1} Words </b></div>
                  </> 
                </p>
               
</> )}
{windowWidth >= 1100 && ( <>
  <p className="ft_sm mt-1 d-flex">
                  <Circle style={{ height: "12px", width: "13px", marginTop: "5px", color: "#3295F7" }} />
                  <>
                  <div className="ml-2" style={{ fontSize: "16px", fontWeight: "400px", color: "#3295F7" }}><b>{wordCountEditor1} Words | 1500 Words </b></div>
                  </> 
                </p>
</>)}
               
              </div>

              <div className="d-flex">
                <div className="d-flex align-items-center">
                 {windowWidth <= 1100 ? <>
                  <Button
                    component="label" variant="outlined" style={{fontSize:"7px", width:'75px'}} onChange={handleFileChange} className={style.PmainBtn1} startIcon={<CloudUploadIcon />}>
                    Upload file
                    <input key={inputKey} style={{ display: "none" }} type={P_File} />
                  </Button>
                 </> : <>
                 <Button
                    component="label" variant="outlined" onChange={handleFileChange} className={style.PmainBtn1} startIcon={<CloudUploadIcon />}>
                    Upload file
                    <input key={inputKey} style={{ display: "none" }} type={P_File} />
                  </Button>
                 </>}
                </div>
                <Button
                  variant="contained"
                  className="ml-2"
                  style={{ borderRadius: "8px", fontSize: windowWidth <= 400 ? '8px' : ''  }}
                  onClick={onPetAssistant}
                  disabled={editorState !== "" ? false : true}
                >
                  {!isParaphrase ? "Paraphrase " : "Re-Phrase"}
                </Button>
              </div>
            </div>
          </div>

        </div>

        <div style={{width:windowWidth <= 1100 ? "100%" : "50%", borderLeft: "4px solid gray",marginTop:windowWidth <= 1100 ? "10px" : "" }}>
          <div style={{ background: "white", boxShadow: "2px 2px 5px -2px #000000", borderRadius: "0px 8px 8px 0px" }} className="pb-3">
            <div className="editor" style={{
              paddingRight: "0%",
              minHeight: "55vh",
              maxHeight: "55vh",
              overflow: 'auto'
              
              // borderTopLeftRadius: "8px !important",
              // borderTopRightRadius: "8px !important",

            }}
            >
              {/* <Editor
                editorState={secondaryeditorState}
                onChange={handleOuputChange}
                customStyleMap={customStyleMap}
                readOnly={secondaryeditorState.getCurrentContent().getPlainText() !== "" ? false : true}
              /> */}

            <JoditEditorComp editorHeight={375} onChange={handleChangeEditor} editorContent={editorContent} editor={paraeditor} isEdit={false} isMain={false}/>
            </div>
            <div className={`text-e mx-3 d-flex mt-0 justify-content-between bottomeditor ${style.moveup}`} style={{ boxShadow: "2px 2px 5px -2px #000000", height: "52px" }}>
              <div>
                <p className="ft_sm mt-1 d-flex">
                  <Circle style={{ height: "12px", width: "13px", marginTop: "5px", color: "#3295F7" }} />
                  <div className="ml-2" style={{ fontSize:windowWidth <500 ? '11px' : "16px", fontWeight: "400px", color: "#3295F7" }}><b>{wordCountEditor2} Words</b></div>
                </p >
              </div >
              <div>

                <button className={`${style.btn_trns} pt-1 px-2`}
                  onClick={handleDownload}
                >
                  <LightTooltip title="Download">
                    <DownloadSharpIcon
                      size={"20px"}
                      style={{ color: "green" }}

                      data-toggle="tooltip"
                      data-placement="top"
                    />
                  </LightTooltip>
                </button>
                <LightTooltip title="Copy">
                  <button className={`${style.btn_trns}`}
                    onClick={handlecopy}>
                    <ContentCopyIcon
                      size={"20px"}
                      // data-toggle="tooltip"
                      // data-placement="top"
                      // title={isCopy ? "Copied" : "Copy to clipboard"}
                      style={{ color: "blue" }}
                    />
                  </button>
                </LightTooltip>
                <LightTooltip title="Delete">
                  <button className={`${style.btn_trns}`}
                    onClick={handleDelete}>
                    <DeleteIcon
                      size={"20px"}
                      // data-toggle="tooltip"
                      // data-placement="top"
                      // title={isCopy ? "Copied" : "Copy to clipboard"}
                      style={{ color: "red" }}
                    />
                  </button>
                </LightTooltip>
              </div>
            </div >
          </div>


        </div >

        {/* <div className="col-md-4">
            <ReactCSSTransitionGroup
              transitionName="example"
              transitionAppear={true}
              transitionEnter={true}
              transitionLeave={true}
            >
              {column.statistics && (
                <Statistics statistics={statistics} colChange={colChange} plagarizedContent={assignplagarizedContent} />
              )}
              {column.feedback && <Feedback colChange={colChange} />}
            </ReactCSSTransitionGroup>
          </div> */}
      </div >
      {/* </div > */}

      {/* <TabPanel value={tabValue} index={1}>
        <ParaphraseDocs isPara={true} ParaContent={ParaContent} />
      </TabPanel> */}


      {/*  clear editor modal */}
      <div>


        <div class="modal fade" id="myModal" role="dialog">
          <div class="modal-dialog modal-dialog-centered ">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
              </div>
              <div class="modal-body">
                <h4 style={{ textAlign: "center" }}>Can we clear you editor</h4>
              </div>
              <div class="modal-footer">
                <input type="checkbox" id="defaultUnchecked" checked={dontShow} onChange={(e) => handleDontShow(e)} />
                <label style={{ marginRight: "45%", marginTop: "1%" }} for="defaultUnchecked"> Don't ask me again</label><br />
                <button type="button" class="btn btn-success" value="true" data-dismiss="modal">Continue</button>
                {/* <button type="button" class="btn btn-danger" value="false" data-dismiss="modal" onClick={(e)=>onHandleDelete(e)}>No</button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  features: state.features.features,
});

export default connect(mapStateToProps)(NewParaphrase);
