
import React from 'react';
import { useState, useEffect } from "react";
import { Editor, EditorState, ContentState } from 'draft-js';
import FeatureButton from '../ReusableComponents/Button';
import Button from '@mui/material/Button';
import { getSummariserPrompt, updatePromptforSummariser } from '../../services/promptsGeneratorServices';
import Loader from '../../utils/loader';
import { toast } from "react-toastify";
import { getParaphrasePrompt, updateParaphrasePrompt } from '../../services/promptsGeneratorServices';

const PromptsGenerator_Summariser = () => {

  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [loading, setLoading] = useState(false);
  const [featureName, setFeatureName] = useState("Summarizer")
  const [length, setLength] = useState("");
  const [paraphraseCalled, setParaphraseCalled] = useState(false)

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
 
  // Update windowWidth on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
 
    window.addEventListener('resize', handleResize);
 
    return () => window.removeEventListener('resize', handleResize);
  }, []);
 
  const mobileView = windowWidth < 770

  const handleChange = (newEditorState) => {
    setEditorState(newEditorState)
  }

  const handleParaphrasePrompt = async (type, customTone) => {
    setParaphraseCalled(true)
    try {
      const data = {
        featureName: "improve-phrasing",
        tone: type,
        customTone: customTone
      };
      console.log("featureName ", data.featureName, "tone  ", data.tone, "customTone  ", data.customTone)
      setLoading(true)
      const result = await getParaphrasePrompt(data);
      if (result.prompt_information !== "") {
        console.log("Responce from ParaPrompt API  ", result)
        console.log("Responce from ParaPrompt API  ", result.data)
        const contentState = ContentState.createFromText(result.prompt_information);
        const newEditorState = EditorState.createWithContent(contentState);
        setEditorState(newEditorState);
      }
    } catch (error) {
      console.error('Error calling paraphrasePrompt:', error);
    }
    setLoading(false)
  };


  const updatePraphrasePrompts = async () => {

    const text = editorState.getCurrentContent().getPlainText()
    if (text === "") {
      toast.warn("Nothing to save");
      return;
    }
    try {
      const data = {
        promptInformation: text,
        featureName: "improve-phrasing",
        tone: "defaultPrompt",
      };

      console.log("promptsInformation   ", data.promptInformation);
      console.log("featureName   ", data.featureName)
      console.log("Tone", data.tone)

      setLoading(true);
      const result = await updateParaphrasePrompt(data);
      if (result.prompt_information !== "") {
        console.log("Response from ParaPrompt API", result);
        console.log("Response from ParaPrompt API", result.data);


        toast.success("Data saved successfully")
      }
    } catch (error) {
      console.error('Error calling paraphrasePrompt:', error);
    }


    setLoading(false);
  };

  const handleSummarisePrompt = async (length1) => {
    setParaphraseCalled(false)
    try {
      const data = {
        featureName: featureName,
        length: length1
      };
      setLength(data.length)
      // console.log("featureName ",data.featureName ,"search  ", data.search, "aireview  ", data.aireview)
      console.log("length   ", data.length)
      setLoading(true)
      const result = await getSummariserPrompt(data);
      if (result.prompt_information !== "") {
        console.log("Responce from ParaPrompt API  ", result)
        console.log("Responce from ParaPrompt API  ", result.data)
        const contentState = ContentState.createFromText(result.prompt_information);
        const newEditorState = EditorState.createWithContent(contentState);
        setEditorState(newEditorState);
      }
    } catch (error) {
      console.error('Error in saving SummarisePrompt:', error);
    }
    setLoading(false)
  };


  const updateSummarisePrompt = async () => {
    const text = editorState.getCurrentContent().getPlainText()
    try {
      const data = {
        promptInformation: text,
        length: length,
        featureName: featureName

      };
      console.log("featureName ", data.featureName, "text  ", data.promptInformation, "search  ", data.search, "aireview  ", data.aireview)
      setLoading(true)
      const result = await updatePromptforSummariser(data);
      if (result.prompt_information !== "") {
        console.log("Responce from ParaPrompt API  ", result)
        console.log("Responce from ParaPrompt API  ", result.data)
        toast.success("Data saved successfully")
      }
    } catch (error) {
      console.error('Error in upating SummarisePrompt:', error);
    }
    setLoading(false)
  };

  const handleClick = async () => {
    if (paraphraseCalled) {
      await updatePraphrasePrompts();
    } else {
      await updateSummarisePrompt();
    }
  };

  return (
    <div>              
      <Loader loading={loading} />

      <div style={{ backgroundColor: "white", minHeight: "9vh", width: "100%", display: "flex", alignItems: "center", marginTop: "20px", boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', borderRadius: '8px', padding:'5px 0px' }}>
        <Button style={{ height: "60%", marginLeft: "10px" }} variant="contained" onClick={() => handleSummarisePrompt("defaultPrompt")}>Generate</Button>

        <div style={{ height: "100%", width: "92%", marginLeft: "5%", display: "flex", flexWrap:'wrap', flexDirection: "row", alignItems: "center" }}>
          <FeatureButton divClassName={"px-1"} text={"Paraphrase"} style={{ width: mobileView ? '75px' : "95px", margin: '5px', fontSize: mobileView ? '10px' : '' }} size={"sm"} onClick={() => handleParaphrasePrompt("defaultPrompt")} />
          <FeatureButton divClassName={"px-1"} text={"0%"} style={{ width: mobileView ? '65px' : "75px", margin: '5px', fontSize: mobileView ? '10px' : '' }} size={"sm"} onClick={() => handleSummarisePrompt("0")} />
          <FeatureButton divClassName={"px-1"} text={"20%"} style={{ width: mobileView ? '65px' : "75px", margin: '5px', fontSize: mobileView ? '10px' : '' }} size={"sm"} onClick={() => handleSummarisePrompt("20")} />
          <FeatureButton divClassName={"px-1"} text={"40%"} style={{ width: mobileView ? '65px' : "75px", margin: '5px', fontSize: mobileView ? '10px' : '' }} size={"sm"} onClick={() => handleSummarisePrompt("40")} />
          <FeatureButton divClassName={"px-1"} text={"60%"} style={{ width: mobileView ? '65px' : "75px", margin: '5px', fontSize: mobileView ? '10px' : '' }} size={"sm"} onClick={() => handleSummarisePrompt("60")} />
          <FeatureButton divClassName={"px-1"} text={"80%"} style={{ width: mobileView ? '65px' : "75px", margin: '5px', fontSize: mobileView ? '10px' : '' }} size={"sm"} onClick={() => handleSummarisePrompt("80")} />
          <FeatureButton divClassName={"px-1"} text={"100%"} style={{ width: mobileView ? '65px' : "75px", margin: '5px', fontSize: mobileView ? '10px' : '' }} size={"sm"} onClick={() => handleSummarisePrompt("100")} />
          <FeatureButton divClassName={"px-1"} text={"Keyword"} style={{ width: mobileView ? '65px' : "75px", margin: '5px', fontSize: mobileView ? '10px' : '' }} size={"sm"} onClick={() => handleSummarisePrompt("keyword")} />
          <FeatureButton divClassName={"px-1"} text={"Keyword Identifier"} style={{ width: mobileView ? '125px' : "145px", margin: '5px', fontSize: mobileView ? '10px' : '' }} size={"sm"} onClick={() => handleSummarisePrompt("keywordIdentifier")} />
        </div>

      </div>


      <div style={{ backgroundColor: "white", display: "flex", flexDirection: "column", height: "50vh", width: "100%", padding: "20px", boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', borderRadius: '8px', marginTop: "20px" }}>
        <div style={{ flex: "100%", display: "flex", flexDirection: "column", width: "100%", overflowY: "auto" }}>
          <Editor
            editorState={editorState}
            // placeholder="Summariser"
            onChange={handleChange}
            style={{ flex: 1 }}
          />
        </div>
        <div style={{ flex: "15%", width: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
          <Button variant="contained" onClick={handleClick}>Save</Button>
        </div>
      </div>
    </div>
  );
}

export default PromptsGenerator_Summariser;