import React from 'react'

import uploadImageServices from '../../services/uploadImageServices';

export default function ImageUpload({insertImage, triggerLoading}) {

    const _handleFileUploadChange = async e => {
      e.preventDefault();
      let file = e.target.files[0];
      console.log(file)
      if (!file) return;
      triggerLoading(true);
    






    const uploadConfig = await uploadImageServices.UploadImageToS3(file);
    const { data:{displayUrl,key} } = uploadConfig;
    insertImage(displayUrl,key);
    
    


  }

      
  return (
    <div className="point RichEditor-controls" data-toggle="tooltip" title="upload your image">
      <label for="imageadd" className="m-0 point">
        <input
          type="file"
          id="imageadd"
          name="imageadd"
          accept="image/*"
          onChange={e => _handleFileUploadChange(e)}
          style={{ display: "none" }}
        />
        <i className="fa fa-image RichEditor-styleButton"  style={{color:"#1976D2",fontSize:"12px"}} aria-hidden="true"></i>
      </label>

    </div>
  )
}
