import http from "./httpService";
import { apiUrl, herokuUrl } from "./config.json";
let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = `${apiUrl}/notes`;
} else {
  apiEndpoint = `${herokuUrl}/notes`;
}



export async function saveNotes(email,text){
    console.log(email,text,"text from service");
  const response = await http.post(`${apiEndpoint}/saveNotes`, {email,text} );
  return response;
}

export async function getNotes(email){
  console.log(email);
const response = await http.post(`${apiEndpoint}/getNotes`, email );
return response;
}



export default {
    saveNotes,
    getNotes
}