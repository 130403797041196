

import React from 'react';
import { useState, useEffect } from "react";
import { Editor, EditorState, ContentState } from 'draft-js';
import FeatureButton from '../ReusableComponents/Button';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import { getCoauthorPrompt, updateCoauthorPrompt } from '../../services/promptsGeneratorServices';
import Loader from '../../utils/loader';
import { toast } from "react-toastify";
import { getParaphrasePrompt, updateParaphrasePrompt } from '../../services/promptsGeneratorServices';
import { getPlagiarismPrompt, updatePlagiarismPrompt } from '../../services/promptsGeneratorServices';
import { getSummariserPrompt, updatePromptforSummariser } from '../../services/promptsGeneratorServices';

import { getCitationPrompt, updateCitationPrompt } from '../../services/promptsGeneratorServices';
import { getTranslatorPrompt, updatePromptforTranslator } from '../../services/promptsGeneratorServices';
import { MenuItem, Select, Paper } from '@material-ui/core';


const PromptsGenerator_CoAuthor = () => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [menuAnchor, setMenuAnchor] = useState(null);
    const [activeButton, setActiveButton] = useState("");
    const [loading, setLoading] = useState(false)
    //const [featureName , setFeatureName] = useState("")
    const [search, setSearch] = useState('');
    const [aireview, setAireview] = useState('');
    const [paraphraseCalled, setParaphraseCalled] = useState(false)
    const [plagiarismCalled, setPlagiarismCalled] = useState(false)
    const [summarizerCalled, setSummarizerCalled] = useState(false)
    const [translatorCalled, setTranslatorCalled] = useState(false)
    const [citationCalled, setCitationCalled] = useState(false)
    const [coAuthorCalled, setCoAuthorCalled] = useState(false)




    // useEffect(() => {
    //     if (activeButton === "Paraphrase") {
    //         handleParaphrasePrompt()
    //     }
    //     if (activeButton === "Plagiarism") {
    //         handlePlagiarismPrompt()
    //     }

    //     if (activeButton === "Summarizer") {
    //         handleSummarisePrompt()
    //     }
    //     if (activeButton === "Citation") {
    //         handleCitationPrompt()
    //     }

    //     if(activeButton === "Translator"){
    //         generatePrompt()
    //     }

    //     // if(activeButton === "Translato"){
    //     //    // handleParaphrasePrompt()
    //     // }
    // }, [activeButton])




    const handleSummarisePrompt = async () => {
        // setParaphraseCalled(false)
        try {
            const data = {
                featureName: "Summarizer",
                length: "defaultPrompt"
            };
            // setLength(data.length)

            // console.log("featureName ",data.featureName ,"search  ", data.search, "aireview  ", data.aireview)
            console.log("length   ", data.length)

            setLoading(true)
            const result = await getSummariserPrompt(data);
            if (result.prompt_information !== "") {
                console.log("Responce from ParaPrompt API  ", result)
                console.log("Responce from ParaPrompt API  ", result.data)
                const contentState = ContentState.createFromText(result.prompt_information);
                const newEditorState = EditorState.createWithContent(contentState);
                setEditorState(newEditorState);
            }


        } catch (error) {
            console.error('Error calling paraphrasePrompt:', error);
        }

        setParaphraseCalled(false)
        setPlagiarismCalled(false)
        setSummarizerCalled(true)
        setTranslatorCalled(false)
        setCitationCalled(false)
        setCoAuthorCalled(false)

        setLoading(false)
    };

    const updateSummarisePrompt = async () => {
        const text = editorState.getCurrentContent().getPlainText()
        try {
            const data = {
                promptInformation: text,
                length: "defaultPrompt",
                featureName: "Summarizer"

            };

            console.log("featureName ", data.featureName, "text  ", data.promptInformation, "search  ", data.search, "aireview  ", data.aireview)


            setLoading(true)
            const result = await updatePromptforSummariser(data);
            if (result.prompt_information !== "") {
                console.log("Responce from ParaPrompt API  ", result)
                console.log("Responce from ParaPrompt API  ", result.data)
                toast.success("Data saved successfully")

            }


        } catch (error) {
            console.error('Error calling paraphrasePrompt:', error);
        }


        setLoading(false)
    };

    const handlePlagiarismPrompt = async () => {

        try {
            const data = {
                featureName: "Plagiarismdetails"


            };



            setLoading(true)
            const result = await getPlagiarismPrompt(data);
            if (result.prompt_information !== "") {

                const contentState = ContentState.createFromText(result.prompt_information);
                const newEditorState = EditorState.createWithContent(contentState);
                setEditorState(newEditorState);
            }


        } catch (error) {
            console.error('Error calling paraphrasePrompt:', error);
        }

        setParaphraseCalled(false)
        setCoAuthorCalled(false)
        setPlagiarismCalled(true)
        setSummarizerCalled(false)
        setTranslatorCalled(false)
        setCitationCalled(false)
        setLoading(false)
    };

    const updatePlagiarismPrompts = async () => {

        const text = editorState.getCurrentContent().getPlainText()
        if (text === "") {
            toast.warn("Nothing to save");
            return;
        }


        try {
            const data = {
                prompt_information: text,
                featureName: "Plagiarismdetails",

            };

            setLoading(true);
            const result = await updatePlagiarismPrompt(data);
            if (result.prompt_information !== "") {
                console.log("Response from ParaPrompt API", result);
                console.log("Response from ParaPrompt API", result.data);


                toast.success("Data saved successfully")
            }
        } catch (error) {
            console.error('Error calling paraphrasePrompt:', error);
        }


        setLoading(false);
    };

    const handleParaphrasePrompt = async (type, customTone) => {
        setParaphraseCalled(true)
        try {
            const data = {
                featureName: "improve-phrasing",
                tone: "defaultPrompt",
                customTone: customTone
            };

            console.log("featureName ", data.featureName, "tone  ", data.tone, "customTone  ", data.customTone)


            setLoading(true)
            const result = await getParaphrasePrompt(data);
            if (result.prompt_information !== "") {
                console.log("Responce from ParaPrompt API  ", result)
                console.log("Responce from ParaPrompt API  ", result.data)
                const contentState = ContentState.createFromText(result.prompt_information);
                const newEditorState = EditorState.createWithContent(contentState);
                setEditorState(newEditorState);
            }


        } catch (error) {
            console.error('Error calling paraphrasePrompt:', error);
        }

        setParaphraseCalled(true)
        setCoAuthorCalled(false)
        setPlagiarismCalled(false)
        setSummarizerCalled(false)
        setTranslatorCalled(false)
        setCitationCalled(false)
        setLoading(false)
    };

    const updatePraphrasePrompts = async () => {

        const text = editorState.getCurrentContent().getPlainText()
        if (text === "") {
            toast.warn("Nothing to save");
            return;
        }


        try {
            const data = {
                promptInformation: text,
                featureName: "improve-phrasing",
                tone: "defaultPrompt",
            };

            console.log("promptsInformation   ", data.promptInformation);
            console.log("featureName   ", data.featureName)
            console.log("Tone", data.tone)

            setLoading(true);
            const result = await updateParaphrasePrompt(data);
            if (result.prompt_information !== "") {
                console.log("Response from ParaPrompt API", result);
                console.log("Response from ParaPrompt API", result.data);


                toast.success("Data saved successfully")
            }
        } catch (error) {
            console.error('Error calling paraphrasePrompt:', error);
        }


        setLoading(false);
    };

    const handleCitationPrompt = async (type) => {

        try {
            const data = {
                featureName: "Citation",
                type: "defaultPrompt"

            };



            setLoading(true)
            const result = await getCitationPrompt(data);
            if (result.prompt_information !== "") {

                const contentState = ContentState.createFromText(result.prompt_information);
                const newEditorState = EditorState.createWithContent(contentState);
                setEditorState(newEditorState);
            }


        } catch (error) {
            console.error('Error calling paraphrasePrompt:', error);
        }

        setParaphraseCalled(false)
        setCoAuthorCalled(false)
        setPlagiarismCalled(false)
        setSummarizerCalled(false)
        setTranslatorCalled(false)
        setCitationCalled(true)



        setLoading(false)
    };

    const updateCitationPrompts = async () => {

        const text = editorState.getCurrentContent().getPlainText()
        if (text === "") {
            toast.warn("Nothing to save");
            return;
        }


        try {
            const data = {
                promptInformation: text,
                featureName: "Citation",
                type: "defaultPrompt"
            };
            console.log("Citation ", data.promptInformation, "  ",)
            setLoading(true);
            const result = await updateCitationPrompt(data);
            if (result.prompt_information !== "") {
                console.log("Response from ParaPrompt API", result);
                console.log("Response from ParaPrompt API", result.data);


                toast.success("Data saved successfully")
            }
        } catch (error) {
            console.error('Error calling paraphrasePrompt:', error);
        }


        setLoading(false);
    };

    const handleButtonClick = (buttonName) => {
        setActiveButton(buttonName);
        setMenuAnchor(null);
    };

    const handleMenuOptionClick = (option) => {
        setActiveButton(option);
        setMenuAnchor(null);
    };
    const handleDropdownChange = (event) => {
        setActiveButton(event.target.value);
        setEditorState(EditorState.createEmpty());
    }
    const renderButtonsBasedOnActiveButton = () => {

        if (activeButton === "Search") {
            return (
                <>
                    <FeatureButton divClassName={"px-1"} text={"Search"} style={{ width: "75px" }} size={"sm"} onClick={() => handleCoAuthorPrompt("improve-phrasing", "searchPrompt", undefined)} />
                    <FeatureButton divClassName={"px-1"} text={"Ask a Question"} style={{ width: "150px" }} size={"sm"} onClick={() => handleCoAuthorPrompt("improve-phrasing", "askAQuestion", undefined)} />
                </>
            );
        } else if (activeButton === "AI Review") {
            return (
                <>
                    <FeatureButton divClassName={"px-1"} text={"Get general advice"} style={{ width: "170px" }} size={"sm"} onClick={() => handleCoAuthorPrompt("improve-phrasing", undefined, "generalAdvice")} />
                    <FeatureButton divClassName={"px-1"} text={"Increase clarity & readability"} style={{ width: "220px" }} size={"sm"} onClick={() => handleCoAuthorPrompt("improve-phrasing", undefined, "clarityAndReadability")} />
                    <FeatureButton divClassName={"px-1"} text={"Ensure content is complete"} style={{ width: "230px" }} size={"sm"} onClick={() => handleCoAuthorPrompt("improve-phrasing", undefined, "ensureContentIsComplete")} />
                    <FeatureButton divClassName={"px-1"} text={"Custom Feedback"} style={{ width: "150px" }} size={"sm"} onClick={() => handleCoAuthorPrompt("improve-phrasing", undefined, "customFeedback")} />
                </>
            );

        }


    }
    const handleChange = (newEditorState) => {
        setEditorState(newEditorState)
    }

    const handleMenuClick = (event) => {
        setMenuAnchor(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setMenuAnchor(null);
    };

    const handleOptionSelect = (option) => {
        setActiveButton(option);
        handleCloseMenu();
    };

    const renderMenuOptions = (options) => {
        // return (
        //     <div style={{ padding: '10px' }}>
        //         {options.map((option) => (
        //             <div
        //                 key={option}
        //                 onClick={() => handleMenuOptionClick(option)}
        //                 style={{
        //                     cursor: 'pointer',
        //                     padding: '8px',
        //                     borderBottom: '1px solid #ddd',
        //                 }}
        //             >
        //                 {option}
        //             </div>
        //         ))}
        //     </div>
        // );

        return options.map((option, index) => (
            <MenuItem key={index} onClick={() => handleOptionSelect(option)}>
                {option}
            </MenuItem>
        ));
    };





    const handleCoAuthorPrompt = async (featureName1, search1, aireview1) => {
        console.log("COAUTHOR CALLED")
        console.log("search1:", search1);
        console.log("aireview1:", aireview1);
        setSearch(search1);
        setAireview(aireview1)
        try {
            const data = {
                featureName: featureName1,
                search: search1,
                aireview: aireview1
            };

            // console.log("featureName ",data.featureName ,"search  ", data.search, "aireview  ", data.aireview)
            console.log("featureName  ", featureName1, "searchCO  ", search, "aireviewCO  ", aireview)

            setLoading(true)
            const result = await getCoauthorPrompt(data);
            if (result.prompt_information !== "") {
                console.log("Responce from ParaPrompt API  ", result)
                console.log("Responce from ParaPrompt API  ", result.data)
                const contentState = ContentState.createFromText(result.prompt_information);
                const newEditorState = EditorState.createWithContent(contentState);
                setEditorState(newEditorState);
            }


        } catch (error) {
            console.error('Error calling paraphrasePrompt:', error);
        }

        setCoAuthorCalled(true)
        setParaphraseCalled(false)

        setPlagiarismCalled(false)
        setSummarizerCalled(false)
        setTranslatorCalled(false)
        setCitationCalled(false)

        setLoading(false)
    };







    const updateTheCoAuthorPrompt = async (featureName, search, aireview) => {
        const text = editorState.getCurrentContent().getPlainText()
        // alert(featureName, "  ", search, " ", aireview)

        try {
            const data = {
                featureName: featureName,
                promptInformation: text,
                search: search,
                aireview: aireview
            };

            console.log("featureName ", data.featureName, "text  ", data.promptInformation, "search  ", data.search, "aireview  ", data.aireview)

            setLoading(true)
            const result = await updateCoauthorPrompt(data);
            if (result.prompt_information !== "") {
                console.log("Responce from ParaPrompt API  ", result)
                console.log("Responce from ParaPrompt API  ", result.data)
                toast.success("Data saved successfully")

            }


        } catch (error) {
            console.error('Error calling paraphrasePrompt:', error);
        }


        setLoading(false)
    };












    //       const updateCoAutherPrompts = async () => {

    //        const text = editorState.getCurrentContent().getPlainText()
    //     if(text === ""){
    //       toast.warn("Nothing to save");
    //       return;
    //     }


    //         try {
    //           const data = {
    //             promptInformation : text,
    //             featureName: featureName, 
    //             tone: tone,
    //           };

    //           console.log("promptsInformation   ", data.promptInformation);
    //           console.log("featureName   ",    data.featureName)
    //           console.log("Tone", data.tone)

    //           setLoading(true);
    //           const result = await updateCoauthorPrompt(data);
    //           if (result.prompt_information !== "") {
    //             console.log("Response from ParaPrompt API", result);
    //             console.log("Response from ParaPrompt API", result.data);


    //             toast.success("Data saved successfully")
    //           }
    //         } catch (error) {
    //           console.error('Error calling paraphrasePrompt:', error);
    //         }


    //         setLoading(false);
    //       };



    const handleClick = async () => {
        const text = editorState.getCurrentContent().getPlainText()
        if(text === ""){
          toast.warn("Nothing to save");
          return;
        }
        if (paraphraseCalled) {
            await updatePraphrasePrompts();
        }

        else if (plagiarismCalled) {
            await updatePlagiarismPrompts()

        }

        else if (summarizerCalled) {
            await updateSummarisePrompt()

        }


        else if (translatorCalled) {
            // await updateSummarisePrompt()
            await savePrompt()

        }

        else if (citationCalled) {
            await updateCitationPrompts()
        }


        else if (coAuthorCalled) {
            await updateTheCoAuthorPrompt("improve-phrasing", search, aireview);

        }
    };
    const generatePrompt = async () => {
        try {
            let data = {}
            data = { featureName: "Translator" }


            //   let data = {}

            //   if (!selectedProfession && !selectedIndustry) {
            //     data = { featureName: "Translator" }
            //    } 


            //else if (selectedIndustry && !selectedProfession) {
            //     data = {
            //       featureName: "Translator",
            //       industry: selectedIndustry
            //     }
            //   } else if (!selectedIndustry && selectedProfession) {
            //     data = {
            //       featureName: "Translator",
            //       profession: selectedProfession
            //     }
            //   } else if (selectedIndustry && selectedProfession) {
            //     data = {
            //       featureName: "Translator",
            //       industry: selectedIndustry,
            //       profession: selectedProfession,
            //     }
            //   }
            const response = await getTranslatorPrompt(data);

            if (response.status == 200) {
                console.log('Data from API:', response.data);
                // return response.data;
                let responseOutput = response?.prompt_information
                const contentState = ContentState.createFromText(responseOutput);
                const newEditorState = EditorState.createWithContent(contentState);
                setEditorState(newEditorState);
            } else {
                console.error('Unexpected response format:', response);
                throw new Error('Unexpected response format');
            }
        } catch (error) {
            console.error('Error fetching data from API:', error);
            throw error;
        }
        setCoAuthorCalled(false)
        setParaphraseCalled(false)

        setPlagiarismCalled(false)
        setSummarizerCalled(false)
        setTranslatorCalled(true)
        setCitationCalled(false)
    }

    const savePrompt = async () => {

        try {
            const text = editorState.getCurrentContent().getPlainText()
            if (text === "") {
                toast.warn("Nothing to save");
                return;
            }
            let data = {}
            data = {
                featureName: "Translator",
                promptInformation: text
            }

            //   let data = {}

            //   if (!selectedProfession && !selectedIndustry) {
            //     data = {
            //       featureName: "Translator",
            //       promptInformation: text
            //     }
            //   }
            // else if (selectedIndustry && !selectedProfession) {
            //     data = {
            //       featureName: "Translator",
            //       industry: selectedIndustry,
            //       promptInformation: text
            //     }
            //   } else if (!selectedIndustry && selectedProfession) {
            //     data = {
            //       featureName: "Translator",
            //       profession: selectedProfession,
            //       promptInformation: text
            //     }
            //   } else if (selectedIndustry && selectedProfession) {
            //     data = {
            //       featureName: "Translator",
            //      // industry: selectedIndustry,
            //       profession: selectedProfession,
            //       promptInformation: text
            //     }

            setLoading(true)
            const response = await updatePromptforTranslator(data);

            if (response.status == 200) {
                console.log('Data from API:', response.data);
                // return response.data;
                // let responseOutput = response?.prompt_information
                // const contentState = ContentState.createFromText(responseOutput);
                // const newEditorState = EditorState.createWithContent(contentState);
                // setEditorState(newEditorState);

                toast.success('Data saved successfully.');
            } else {
                console.error('Unexpected response format:', response);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.error('Error fetching data from API:', error);
        }
        setLoading(false);

    }



    return (
        <div>
            <Loader loading={loading} />

            {/* <div style={{ backgroundColor: "white", height: "9vh", width: "100%", display: "flex", alignItems: "center", marginTop: "20px", boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', borderRadius: '8px', }}>
                <Button onClick={handleMenuClick} style={{ height: "60%", marginLeft: "10px" }} variant="contained">Generate</Button>
                <Popover
                    open={Boolean(menuAnchor)}
                    anchorEl={menuAnchor}
                    onClose={handleCloseMenu}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    {renderMenuOptions(["Search", "AI Review"])}
                    {renderMenuOptions(["Search", "AI Review", "Plagiarism", "Summarizer", "Translator", "Paraphrase", "Citation"])}

                </Popover>
                <div style={{ height: "100%", width: "92%", marginLeft: "10%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                    {renderButtonsBasedOnActiveButton()}
                </div>
            </div> */}

            <div style={{ backgroundColor: "white", height: "9vh", width: "100%", display: "flex", alignItems: "center", marginTop: "20px", boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', borderRadius: '8px', }}>
            <Select
                value={activeButton}
                displayEmpty
                onChange={handleDropdownChange}
                style={{ height: "60%", marginLeft: "10px" }}
                MenuProps={{
                    PaperProps: {
                        style: {
                            marginTop: '40px',
                        },
                    },
                }}
            >
                <MenuItem value="" >Select</MenuItem>
                <MenuItem value="Search">Search</MenuItem>
                <MenuItem value="AI Review">AI Review</MenuItem>
            </Select>
                <div style={{ height: "100%", width: "92%", marginLeft: "10%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                    {renderButtonsBasedOnActiveButton()}
                </div>
            </div>


            <div style={{ backgroundColor: "white", display: "flex", flexDirection: "column", height: "50vh", width: "100%", padding: "20px", boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', borderRadius: '8px', marginTop: "20px" }}>
                <div style={{ flex: "100%", display: "flex", flexDirection: "column", width: "100%", overflowY: "auto" }}>
                    <Editor
                        editorState={editorState}
                        // placeholder="coAuthor"
                        onChange={handleChange}
                        style={{ flex: 1 }}
                    />
                </div>
                <div style={{ flex: "15%", width: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                    <Button variant="contained" onClick={handleClick} >Save</Button>
                </div>
            </div>





        </div>
    );
}

export default PromptsGenerator_CoAuthor;