import React, { useEffect, useState } from 'react'
import ReactApexChart from "react-apexcharts";

function ApexChartsUserFn({ cmp1, cmp2, bar1, bar2, line, linen, lineDataColor, title, monthList }) {

    const [options, setOptions] = useState({}); 
    const [series, setSeries] = useState([]); 

    useEffect(() => {
        _initial();
    }, [bar1, bar2, line, linen, lineDataColor]);

    const _initial = () => {
        setSeries(
            [{
                name: cmp1,
                type: 'column',
                data: bar1
            },
            {
                name: cmp2,
                type: 'column',
                data: bar2
            },{
                name: linen,
                type: 'line',
                data: line
            }]
        );

        setOptions({
            chart: {
              height: 350,
              type: 'line',
              stacked: false
            },
           
            dataLabels: {
              enabled: false
            },
            colors: ['#4285f4','#20c997', lineDataColor],
            stroke: {
              width: [1, 1, 3],
            },
            xaxis: {
              categories: ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"],
            },
            yaxis: [
              {
                axisTicks: {
                  show: true,
                },
                axisBorder: {
                  show: true,
                  color: '#008FFB'
                },
              
               
                tooltip: {
                  enabled: true
                }
              },          
            ],
            tooltip: {
              fixed: {
                enabled: true,
                position: 'topLeft',
                offsetY: 30,
                offsetX: 60
              },
            },
            legend: {
              horizontalAlign: 'left',
              offsetX: 40,
              showForNullSeries: false
            }
          });
    }

    return (
        <div className="app">
            <div className="row">
                <div className="col-12 apexCharts">
                    <div id="chart">
                        <ReactApexChart options={options} series={series} type="line" height={400}  style={{ backgroundColor:"white"}} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ApexChartsUserFn
