import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { Card, CardText, Progress } from "reactstrap";
// import "./NumberWidget.css";

const NumberWidget = ({
  title,
  subtitle,
  number,
  color,
  colorpb,
  count,
  progress: { value, label },
  ...restProps
}) => {
  return (
    <React.Fragment>
      {/* NumWidget 1 */}

      <div className="col-sm-4">
        <Card body {...restProps} className="card setShadow" style={{borderRadius: "10px"}}>
          <div className="d-flex justify-content-between">
            <CardText tag="div">
              {/* <Typography className="mb-1" color={color} component="h4">{title}</Typography> */}
              <h4 className="mb-2" style={{ color: color }}>{title}</h4>
            </CardText>
            {/* <CardTitle className={`text-${color}`}>100</CardTitle> */}
          </div>
          <Progress
            value={count}
            color={colorpb}
            style={{ height: "8px" }}
            max={500}
          />
          <CardText tag="div" className="d-flex justify-content-between">
            <Typography className="text-left large mt-2 mb-0">
              <span>{count}</span>
            </Typography>
          </CardText>
        </Card>
      </div>
    </React.Fragment>
  );
};

NumberWidget.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  number: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "danger",
    "light",
    "dark",
  ]),
  progress: PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  }),
};

NumberWidget.defaultProps = {
  title: "",
  subtitle: "",
  number: 0,
  color: "primary",
  progress: {
    value: 0,
    label: "",
  },
};

export default NumberWidget;
