import React, { useEffect, useState } from "react";
import ReplyAllOutlinedIcon from '@mui/icons-material/ReplyAllOutlined';
 
const ImageCard = ({
    bgColor,
    txtColor,
    altImg,
    imgSrc,
    cardName,
    number,
    totalUsers,
    additionalData,
    applyFilter
}) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
 
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
 
    const mobileView = windowWidth <= 600;
 
    return (
        <div
            style={{
                backgroundColor: bgColor,
                borderRadius: "8px",
                cursor: 'pointer',
                height: windowWidth?'50%':'9rem',
                minWidth: windowWidth?'50%':'13rem',
                maxWidth: '17rem',
                margin: '0px 2px',
                display: 'flex',
                alignItems: 'center',
            }}
        >
            {applyFilter ? (
                <ReplyAllOutlinedIcon
                    style={{
                        width: "5rem",
                        height: "6rem",
                        color: txtColor,
                        marginRight: "10px",
                    }}
                />
            ) : (
                <img
                    style={{
                        width:windowWidth < 768 ? "3rem" : "5rem",
                        height:windowWidth < 768 ? "3rem" : "6rem",
                        marginRight: "10px",
                    }}
                    alt={altImg}
                    src={imgSrc}
                />
            )}
            <div style={{ textAlign: "center", flex: 1 }}>
                <p
                    style={{
                        color: txtColor ? txtColor : '#ffffff',
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: "500",
                        fontSize: windowWidth < 768 ? "0.7rem" : "1rem",
                        margin: "2px",
                    }}
                >
                    {cardName}
                </p>
                <p
                    style={{
                        color: txtColor ? txtColor : '#ffffff',
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: "600",
                        fontSize:windowWidth < 768 ? "1rem" : "2.3rem",
                        margin: 0,
                        marginTop: '5px',
                    }}
                >
                    {number}
                </p>
                {additionalData && (
                    <p
                        style={{
                            color: txtColor ? txtColor : '#ffffff',
                            fontFamily: "Poppins, sans-serif",
                            fontWeight: "500",
                            fontSize: "0.7rem",
                            margin: 0,
                            marginTop: '5px',
                        }}
                    >
                        Total Users {totalUsers}
                    </p>
                )}
            </div>
        </div>
    );
};
 
export default ImageCard;