import React, { Component } from "react";
import DocumentPreview from "../documentPreview";
import NetworkDetector from "../HigherOrderComponents/NetworkDetector";
import SideNavBar from "../SideNavBar";
import AdminNavBar from "../AdminSideNavBar";
import authService from "../../services/authService";
import SubAdminSideBar from "../SubAdminSideBar";

class DocumentPreviewPage extends Component {
  state = {};

  render() {
    const { detail, isReviewing } = this.props.history.location.state;
    const user = authService.getCurrentUser();
    console.log(user,"holla operator")
    if (user.isAdmin) {
    
      return (
        <AdminNavBar>
        <DocumentPreview data={detail} />
      </AdminNavBar>
      );
    } else if(user.isSubAdmin){
      return(
      <SubAdminSideBar>
          <DocumentPreview data={detail} />
        </SubAdminSideBar>
      );
    }else {
      return (
        <SideNavBar>
          <DocumentPreview data={detail} />
        </SideNavBar>
      );
    }
  }
}

export default NetworkDetector(DocumentPreviewPage);
