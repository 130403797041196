import React from 'react';
import { useState, useEffect } from "react";
import { Editor, EditorState, ContentState, Modifier } from 'draft-js';
import FeatureButton from '../ReusableComponents/Button';
import Button from '@mui/material/Button';
import SelectDropdownMui from '../ReusableComponents/SelectDropdownMui';
import { getTranslatorPrompt, updatePromptforTranslator } from '../../services/promptsGeneratorServices';
import Loader from "../../utils/loader";
import { toast, ToastContainer } from "react-toastify";


const PromptsGenerator_Translator = () => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [loading, setLoading] = useState(false);
  const [industryList, setIndustryList] = useState([
    {
      value: "",
      name: "Select",
    },
    {
      value: "IT",
      name: "IT",
    },
    {
      value: "Healthcare",
      name: "Healthcare",
    },
    {
      value: "Finance",
      name: "Finance",
    },
    {
      value: "Agriculture",
      name: "Agriculture",
    },
    {
      value: "Education",
      name: "Education",
    },
    {
      value: "Retail",
      name: "Retail",
    },
    {
      value: "Telecommunication",
      name: "Telecommunication",
    },
  ]);
  const [selectedIndustry, setSelectedIndustry] = useState("");
  const [professionList, setProfessionList] = useState([
    {
      value: "",
      name: "Select",
    },
    {
      value: "Content Writer",
      name: "Content Writer",
    },
    {
      value: "Business Owner",
      name: "Business Owner",
    },
    {
      value: "HR Professional",
      name: "HR Professional",
    },
    {
      value: "Marketing",
      name: "Marketing",
    },
    {
      value: "Product Manager",
      name: "Product Manager",
    },
    {
      value: "Medical Professional",
      name: "Medical Professional",
    },
    {
      value: "Legal Professional",
      name: "Legal Professional",
    },
  ]);
  const [selectedProfession, setSelectedProfession] = useState("");
  const [detectLang, setDetectLang] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
 
  // Update windowWidth on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
 
    window.addEventListener('resize', handleResize);
 
    return () => window.removeEventListener('resize', handleResize);
  }, []);
 
  const mobileView = windowWidth < 770

  const handleChange = (newEditorState) => {
    setEditorState(newEditorState)
  }

  const generatePrompt = async (detectLanguage) => {
    try {
      let data = {}
      if (detectLanguage === "detectLanguage") {
        setDetectLang(true)
        data = {
          featureName: "Translator",
          detectLanguage: detectLanguage
        };
      } else if (!selectedProfession && !selectedIndustry) {
        setDetectLang(false)
        data = { featureName: "Translator" }
      } else if (selectedIndustry && !selectedProfession) {
        setDetectLang(false)
        data = {
          featureName: "Translator",
          industry: selectedIndustry
        }
      } else if (!selectedIndustry && selectedProfession) {
        setDetectLang(false)
        data = {
          featureName: "Translator",
          profession: selectedProfession
        }
      } else if (selectedIndustry && selectedProfession) {
        setDetectLang(false)
        data = {
          featureName: "Translator",
          industry: selectedIndustry,
          profession: selectedProfession,
        }
      }
      const response = await getTranslatorPrompt(data);

      if (response.status == 200) {
        console.log('Data from API:', response.data);
        // return response.data;
        let responseOutput = response?.prompt_information
        const contentState = ContentState.createFromText(responseOutput);
        const newEditorState = EditorState.createWithContent(contentState);
        setEditorState(newEditorState);
      } else {
        console.error('Unexpected response format:', response);
        throw new Error('Unexpected response format');
      }
    } catch (error) {
      console.error('Error fetching data from API:', error);
      throw error;
    }
  }

  const savePrompt = async () => {

    try {
      const text = editorState.getCurrentContent().getPlainText()
      if (text === "") {
        toast.warn("Nothing to save");
        return;
      }

      let data = {}

      if (detectLang == true) {
        data = {
          featureName: "Translator",
          detectLanguage: "detectLanguage",
          promptInformation: text
        };
      } else if (!selectedProfession && !selectedIndustry) {
        data = {
          featureName: "Translator",
          promptInformation: text
        }
      } else if (selectedIndustry && !selectedProfession) {
        data = {
          featureName: "Translator",
          industry: selectedIndustry,
          promptInformation: text
        }
      } else if (!selectedIndustry && selectedProfession) {
        data = {
          featureName: "Translator",
          profession: selectedProfession,
          promptInformation: text
        }
      } else if (selectedIndustry && selectedProfession) {
        data = {
          featureName: "Translator",
          industry: selectedIndustry,
          profession: selectedProfession,
          promptInformation: text
        }
      }
      setLoading(true)
      const response = await updatePromptforTranslator(data);

      if (response.status == 200) {
        console.log('Data from API:', response.data);
        // return response.data;
        // let responseOutput = response?.prompt_information
        // const contentState = ContentState.createFromText(responseOutput);
        // const newEditorState = EditorState.createWithContent(contentState);
        // setEditorState(newEditorState);

        toast.success('Data saved successfully.');
      } else {
        console.error('Unexpected response format:', response);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data from API:', error);
    }
    setLoading(false);

  }



  return (
    <div>
      <div style={{ backgroundColor: "white", minHeight: "9vh", width: "100%", display: "flex", alignItems: "center", marginTop: "20px", boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', borderRadius: '8px', }}>
        <Loader loading={loading} />
        <Button style={{ height: "60%", marginLeft: "10px" }} variant="contained" onClick={() => generatePrompt()} >Generate</Button>

        <div style={{ height: "100%", width: "92%", marginLeft: "5%", display: "flex", flexWrap:'wrap', flexDirection: "row", alignItems: "center" }}>
          <FeatureButton divClassName={"px-1"} text={"Detect Language"} style={{ width: "150px", margin:'5px'}} size={"sm"} onClick={() => generatePrompt("detectLanguage")} />
          {/* <FeatureButton divClassName={"px-1"} text={"Translate"} style={{ width: "75px" }} size={"sm"} /> */}

          <div className="ml-2 bg-white" style={{ width: "150px", color: "blue", margin:'5px' }}
          >
            <SelectDropdownMui
              optionsList={professionList}
              selectLabel={"Select Profession"}
              onChange={(e) => setSelectedProfession(e.target.value)}
              value={selectedProfession}
              
            // onClick={()=>generatePrompt()}
            />
          </div>
          <div className="ml-2 bg-white" style={{ width: "150px", margin:'5px' }}>
            <SelectDropdownMui
              optionsList={industryList}
              selectLabel={"Select Industry"}
              onChange={(e) => setSelectedIndustry(e.target.value)}
              value={selectedIndustry}
              
            />
          </div>


        </div>

      </div>

      <div style={{ backgroundColor: "white", display: "flex", flexDirection: "column", height: "50vh", width: "100%", padding: "20px", boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', borderRadius: '8px', marginTop: "20px" }}>
        <div style={{ flex: "100%", display: "flex", flexDirection: "column", width: "100%", overflowY: "auto" }}>
          <Editor
            editorState={editorState}
            // placeholder="translator"
            onChange={handleChange}
            style={{ flex: 1 }}

          />
        </div>
        <div style={{ flex: "15%", width: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
          <Button variant="contained" onClick={() => savePrompt()} >Save</Button>
        </div>

      </div>
    </div>
  );
}

export default PromptsGenerator_Translator;

