import React, {useEffect, useState} from "react";
import dateFn from "date-fn";
import { getnotifications } from "../../services/notficationService";

const RemainderComp = (data) => {
    const [isOpen, setIsOpen] = useState(true)
    const [notifications, setNotifications] = useState([]);

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    const list = ["Brainstorming", "Meet 1", "Conference", "Raildon", "Meet 2"]

useEffect(()=>{
    getNotifications(data)
},[data])

    const getNotifications = (data) => {
        // let notifications = await getnotifications();
        console.log("notifications",data)
        setNotifications(data.data);
        // console.log(notifications);
      };

    return (
        <div
            style={{
                backgroundColor: "#ffffff",
                borderRadius: "8px",
                boxShadow: "2px 2px 5px -2px #000000",
                padding: "9px",
                // height: isOpen ? "auto" : "3rem",
                height: isOpen ? "10rem" : "3rem",
                transition: "height 0.3s"
            }}
        >
            <div
                style={{
                    color: "#393939",
                    fontFamily: 'Poppins, sans-serif',
                    fontWeight: 600,
                    fontSize: "0.8rem",
                    cursor: "pointer"
                }}
                onClick={toggle}
            >
               {isOpen ? "Hide Reminders <<" : "Show Reminders >>"}
            </div>
            <div
                style={{
                    display: isOpen ? "block" : "none",
                    // height: "80vh",
                    height: "8rem",
                    overflowY: "auto",
                    padding: "3px",
                }}
            >
                <ul
                    style={{
                        listStyle: "none"
                    }}
                >
                    {
                       notifications.length > 0 && notifications.map((obj) => {
                            return <li>
                                <div
                                    className="mt-2"
                                    style={{
                                        backgroundColor: "#ffffff",
                                        borderRadius: "8px",
                                        boxShadow: "2px 2px 5px -2px #000000",
                                        padding: "7px", fontSize:"0.8rem",
                                    }}
                                >
                                    <p>{obj.message}</p>
                                    {/* <p>Sept 17 | 10:00 to 11:00 AM </p> */}
                                    {/* <p>dateFn.date(obj.date)</p> */}
                                    {/* <div
                                    className="justify-content-end"
                                    >
                                    <button className="btn btn-primary">Join now</button>
                                    </div> */}
                                </div>
                            </li>
                        })
                    }
                </ul>
            </div>
            {/* <div 
            style={{margin: "0 auto"}}
            >
                <button className="btn btn-outline-primary">View More</button>
            </div> */}

        </div>
    )
}

export default RemainderComp