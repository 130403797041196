import React, { useContext, useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import ContactCard from '../ContactCard/ContactCard';
import CreateGroupCard from '../ContactCard/CreateGroupCard';
import { ChatContext } from '../../../contexts/ChatContext';
import { Box, TextField } from '@mui/material';
import ChatServices from '../../../services/ChatServices';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const CreateGroup = ({ contacts , openDialog, setOpenDialog, fetchUsersChat }) => {


    const [selectedContacts, setSelectedContacts] = useState([]);


    const [groupName , setGroupName] = useState('');
  
  const onSelect = (contact, isSelected) => {
    if (isSelected) {
      setSelectedContacts(prev => [...prev, contact.userId]); // Add contact if it's selected
    } else {
      setSelectedContacts(prev => prev.filter(id => id !== contact.userId)); // Remove contact if it's deselected
    }
  };


    console.log(selectedContacts);


    const handleSubmit = async () => {
      if (selectedContacts.length > 0) {
      try {
        const response = await ChatServices.createGroup(groupName, selectedContacts);
        if (response.status === 200) {
          handleClose();
        } else {
          console.error("Error Opening Chat:", response.message);
        }
      } catch (error) {
        console.error("Error calling backend API:", error.message);
      }
    }
    else{
        console.log("No contacts selected");
    }
      };

      const handleClose = () => {
        setOpenDialog(false);
        fetchUsersChat();
        setSelectedContacts([])
      };

  return (
    <>
    <Dialog
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      fullWidth
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{"New Group"}</DialogTitle>
      <DialogContent>
        <Box p={1}>
          <TextField onChange={(e)=>setGroupName(e.target.value)} fullWidth variant='outlined' size='small' placeholder='Enter Group Name'  />
        </Box>
      <div className="contacts__list">
      {contacts.map((contact, index) => (
          <CreateGroupCard key={contact._id} contact={contact} index={index} onSelect={onSelect} />
        ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>CANCEL</Button>
        <Button onClick={handleSubmit}>CREATE</Button>
      </DialogActions>
    </Dialog>
  </>
  )
}

export default CreateGroup;