import React,{useRef} from 'react';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import { Button } from '@mui/material';
import { useState, useEffect } from "react";
import Loader from '../../../utils/loader';
import { checkPlagiarism } from '../../../services/plagiarismServices';
import { toast, ToastContainer } from "react-toastify";
import 'react-circular-progressbar/dist/styles.css';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Card, CardContent, Typography } from '@mui/material';
import _ from 'lodash';
import { ContentState, Editor, EditorState, Modifier, getDefaultKeyBinding, KeyBindingUtil, RichUtils, SelectionState, convertToRaw, CompositeDecorator } from 'draft-js';
import { Modal } from 'react-bootstrap';
import SuggestionCard from '../../Plagiarism/SuggestionCard';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import JoditEditorComp from '../../ReusableComponents/JoditEditorComp';


const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'blue',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);




const CoAuther_PlagiarismChecker_Text = (props) => {
    const [check_plag, setCheckPlag] = useState(false)
    const [loading, setLoading] = useState(false);
    const [plagiarozedProgress, setPlagiarozedProgress] = useState(0);
    const [uniqueProgress, setUniqueProgress] = useState(0);
    const [sourceCount, setSourceCount] = useState(0)
    const [uniqueWordsPercentage, setUniqueWordsPercentage] = useState(`0%`);
    const [progressStatus, setProgressStatus] = useState('Plagiarized');
    const [sentenceCount, setSentenceCount] = useState(0);
    const [paraCount, setParaCount] = useState(0);
    const [timeDisplay, setTimeDisplay] = useState("0 sec");
    const [uniqueWordCount, setUniqueWordCount] = useState("0");
    const [outputArray, setOutputArray] = useState([])
    const [showSuggestion, setShowSuggestion] = useState(false);

    // const [showOverlay, setShowOverlay] = useState(false);

    const plageditor = useRef(null);


    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
 
    // Update windowWidth on resize
    useEffect(() => {
      const handleResize = () => setWindowWidth(window.innerWidth);
   
      window.addEventListener('resize', handleResize);
      // if(window.innerWidth <768){
      //   setSidebarOpen(!isSidebarOpen)
      // }
   
      return () => window.removeEventListener('resize', handleResize);
    }, []);


    const applyHighlight = (obj) => {
        const contentState = props.editorState.getCurrentContent();
        let newContentState = contentState;
        // if(outputArray.length == 0 || outputArray==undefined){
        //   alert("No responce in outputArr")

        // }
        // if(highlightSentence.length == 0 || highlightSentence==undefined){
        //   alert("No responce in sentenceArr")
        // }


        obj.forEach((detail) => {
            const { sentenceDetails } = detail;
            let start = 0;

            while (start < contentState.getPlainText().length) {
                const startIndex = contentState.getPlainText().indexOf(sentenceDetails, start);

                if (startIndex === -1) {
                    break;
                }

                const endIndex = startIndex + sentenceDetails.length;

                // Apply inline style to the selection
                const selection = SelectionState.createEmpty(
                    contentState.getBlockMap().first().getKey()
                )
                    .set("anchorOffset", startIndex)
                    .set("focusOffset", endIndex);

                newContentState = Modifier.applyInlineStyle(
                    newContentState,
                    selection,
                    "HIGHLIGHT"
                );

                // Update the start index to avoid an infinite loop
                start = endIndex;
            }
        });

        props.setEditorState(
            EditorState.push(props.editorState, newContentState, "apply-inline-style")
        );
    };

    // Define the custom style map
    const customStyleMap = {
        HIGHLIGHT: {
            backgroundColor: "#FF7F7F",
            color: "white",
        },
    };








    const calculateSpeakTime = (countstats, isTable = false) => {
        // const contentState = props.editorState.getCurrentContent();
        // const rawText = convertToRaw(contentState);
        // const text = rawText.blocks.map((block) => block.text).join(" ");

        const text = props.editorState

        if (text == "") {
            setTimeDisplay(`0 sec`)
        }

        // Estimate speaking speed (words per minute)
        const wordsPerMinute = 150; // Adjust as needed

        // Calculate the number of words in the text
        const wordCount = text.split(/\s+/).length;

        // Calculate the time it would take to speak in minutes
        const speakingTimeMinutes = wordCount / wordsPerMinute;

        // Convert minutes to minutes and seconds
        const minutes = Math.floor(speakingTimeMinutes);
        const seconds = Math.round((speakingTimeMinutes % 1) * 60);
        let finalTime = ""
        // Set the time display based on conditions
        if (speakingTimeMinutes < 1) {
            setTimeDisplay(`${seconds} sec`);
            finalTime = `${seconds} sec`

        } else {
            setTimeDisplay(`${minutes} min ${seconds} sec`);
            // addScanHistory(countstats,`${seconds} sec`)
            finalTime = `${minutes} min ${seconds} sec`

        }
        console.log("countstats_", countstats)

    };









    const countTextStats = (isTable) => {
        // let inputText = props.editorState.getCurrentContent().getPlainText()

        let inputText = props.editorState
        // Count paragraphs
        const paragraphs = inputText?.split('\n').filter((line) => line.trim() !== '').length;

        // Count sentences (a basic example)
        const sentences = inputText?.split(/[.!?]/).filter((sentence) => sentence.trim() !== '').length;

        // Count unique words
        const words = inputText
            .toLowerCase()
            .split(/\W+/)
            .filter((word) => word.trim() !== '');
        const uniqueWords = [...new Set(words)].length;/////////////////////////////////////////////////////////////////

        setParaCount(paragraphs);
        setSentenceCount(sentences);
        setUniqueWordCount(uniqueWords);
        let counts = {
            "paragraph": paragraphs,
            "sentence": sentences,
            "uniqueWords": uniqueWords
        }
        calculateSpeakTime(counts, isTable)

    };



    const uniqueWordsPercentageCalculation = () => {
        // const currentContent = props.editorState.getCurrentContent();
        // const rawContentState = convertToRaw(currentContent);
        // const text = rawContentState.blocks.map((block) => block.text).join(" ");
        const text = props.editorState
        const words = text.match(/\b\w+\b/g) || []; // Regular expression to match words

        const uniqueWords = new Set(words);
        const percentage = (uniqueWords.size / words.length) * 100;

        setUniqueWordsPercentage(`${percentage.toFixed(0)}%`);
    };



    const handleCheckPlagiarism = async (outputSentence = "", isTable) => {

        setCheckPlag(true);

        console.log("props from coAuther", props.editorState)

        //console.log("checkSentence_", outputSentence);
        // let sentence = props.editorState.getCurrentContent().getPlainText();
        let sentence = props.editorState
       
        setLoading(true)
         setSourceCount(0)
        countTextStats(isTable)
        uniqueWordsPercentageCalculation()
        // getParaDetails()
        let data = {
            text: sentence
        }
        let userId = sessionStorage.getItem("userId")
    let isCoAuthor = true
        let plagiarismResponse = await checkPlagiarism(data,userId,isCoAuthor)
        console.log("plagiarismResponse_", plagiarismResponse.data)
        if (plagiarismResponse.status == 400) {
            setLoading(false)
            toast.warn(plagiarismResponse.message)
            return
        }
        if (plagiarismResponse?.data?.details?.plagPercent !== 0) {
            setUniqueProgress(plagiarismResponse.data.uniquePercent)
            setPlagiarozedProgress(plagiarismResponse.data.plagPercent)
            let plagDetails = plagiarismResponse.data.details
            let sourceDetails = plagiarismResponse.data.sources
            let sentenceArray = []

            let filterPlagDetails = _.filter(plagDetails, (item, i) => item.unique == "false")

            //console.log("plagDetails_", plagDetails, sourceDetails.length, filterPlagDetails)
            setSourceCount(sourceDetails.length)
            filterPlagDetails.map((obj) => {
                //console.log("obj_", obj);
                let outObject = {
                    sentenceDetails: obj.query,
                    url: obj?.display?.url
                }
                sentenceArray.push(outObject)
            })
            console.log("current content_yyyyyyyyyyyyyyyyyyyyyyyyyyyyy pankaj", sentenceArray.sentenceDetails)
            //console.log("current_Content yyyyyyyy", sentenceArray)
            //  setHighlightSentence(sentenceArray)
            setOutputArray(sentenceArray)


            //  console.log(outputArray, "outputArrrrrrrrrrrrrrrrrrrr")
            //    console.log(outputArray.sentenceDetails, "sentenceDetailsssssss")
            // console.log(outputArray[0].sentenceDetails, "index00000000000000000000000000000")
            setLoading(false)
            //  console.log(highlightSentence, "Hightlightedddddddddd")
            // applyHighlight(sentenceArray)
            
            props.setEditorState(props.editorState)

        }
    }











    // const handleModel = (value)=>{
    //     props.check(value)
    // }


    return (
<>
        <div className="blank_container"
            style={{
                margin: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center", /* Center vertically */
                height: "70%",
                flexDirection: "column",
                position: "relative"
            }}
        >
            <div className="head_blank"
                style={{
                    textAlign: "center",
                    position: "absolute",
                    top: "10%",
                    width: "100%"
                }} >
                {/* <h2> <InsertDriveFileOutlinedIcon style={{ height: "30px", width: "30px" }} /> {" "}Paraphraser takes</h2> */}
            </div>

            <div className='blank_para' style={{
                textAlign: "center",
                color: "grey",
                position: "absolute",
                top: "30%",
                width: "100%",
            }}><p><TipsAndUpdatesOutlinedIcon />{" "} Tip: Finish writing before checking for plagiarism</p></div>

            <div
                style={{
                    position: "absolute", top: "40%", height: "30%", width: "80%",
                    color: "black", textAlign: "center", backgroundColor: "#ADD8E6", display: "flex", alignItems: "center", justifyContent: "center",marginTop:windowWidth < 768 ? "30px" : "",
                    // margint:windowWidth < 768 ? "auto" : ""
                }} 
            >
                <p><b>Do you want to continue?</b></p>
            </div>

            <div className='blank_btn'
                style={{
                    display: "flex",
                    justifyContent: "center",
                    position: "absolute",
                    top:props.mobileView ? "80%" : "75%",
                }}>
                <Button onClick={handleCheckPlagiarism} variant="contained" aria-label="outlined primary button group">Check Plagiarism</Button>
            </div>

           
        </div>

       
             {check_plag && (
                !props.mobileView ?
            <div

                style={{
                    position: "fixed",
                    top: "62px",
                    left: "0px",
                    width: "100%",
                    height: "100%",
                 backgroundColor: "rgba(0, 0, 0, 0.7)", // semi-transparent black
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex:"99999999" // Ensure the overlay is on top
                }}
            >
                <Loader loading={loading} />

                {/* You can add loading spinners or other content here */}
                <div style={{ height: "98.5%", width: "90%", padding: "40px", backgroundColor: "white" }}>


                    <button style={{ position: "absolute", top: "2%", left: "88%" }} onClick={() => setCheckPlag(false)}> <LightTooltip title="Close"><CloseIcon style={{ backgroundColor: "transparent" }} /></LightTooltip></button>
                    <Button variant="contained" aria-label="outlined primary button group" style={{ position: "absolute", top: "2%", left: "79%" }} onClick={handleCheckPlagiarism}>Check Again</Button>



                    <div style={{ display: "flex", flexDirection: "row", marginLeft: "11px", marginTop: "10px" }}>
                        <Card className="col-md-2 col-sm-3">
                            <CardContent className="">
                                {/* <SemiCircleProgressBar percentage={uniqueProgress}>
            <div className="">
            {uniqueProgress}%
            </div>
            </SemiCircleProgressBar> */}
                                <div style={{ width: 100, height: 100, textAlign: "center" }}>
                                    <CircularProgressbar
                                        value={plagiarozedProgress}
                                        // text={`${plagiarozedProgress}%`}
                                        counterClockwise={true}
                                        circleRatio={0.75}
                                        styles={buildStyles({
                                            rotation: 0.37,
                                            strokeLinecap: "butt",
                                            pathColor: 'red',
                                            trailColor: '#F6B6B6',
                                            textColor: 'red'
                                        })}
                                    />
                                </div>
                                <p className="progressText" style={{ color: "red" }}>{plagiarozedProgress}% {progressStatus}</p>
                            </CardContent>
                        </Card>
                        <Card className="col-md-2 col-sm-3  mx-1">
                            <CardContent className="">
                                <div style={{ width: 100, height: 100, textAlign: "center" }}>
                                    <CircularProgressbar
                                        value={uniqueProgress}
                                        // text={`${uniqueProgress}%`}
                                        // counterClockwise={true}
                                        circleRatio={0.75}
                                        styles={buildStyles({
                                            rotation: 0.63,
                                            strokeLinecap: "butt",
                                            pathColor: '#32BC7A',
                                            trailColor: '#7FF1BA',
                                            textColor: '#32BC7A'
                                        })}
                                    />
                                </div>
                                <p className="progressText ml-3" style={{ color: "#32BC7A" }}>{uniqueProgress}% Unique</p>
                            </CardContent>
                        </Card>
                        <Card className="col-md-5 col-sm-6">
                            <CardContent>
                                <div>
                                    <div>
                                        <p className="countCardheader">Extra Word Count Statistics</p>
                                    </div>
                                    <div className="countCardValues d-flex justify-content-between">
                                        <div className="d-flex mt-3">
                                            <div className="d-flex flex-column" style={{ fontWeight: 600 }}>
                                                <p>Sentences</p>
                                                <p>Unique Word(s)</p>
                                            </div>
                                            <div className="d-flex flex-column ml-2">
                                                <p>{sentenceCount}</p>
                                                {/* <p>{sen}</p> */}
                                                {/* <p>{uniqueWordCount + " " + "(" + uniqueWordPercent + ")"}</p> */}
                                                <p>{uniqueWordsPercentage}</p>
                                            </div>
                                        </div>
                                        <div className="d-flex mt-3">
                                            <div className="d-flex flex-column" style={{ fontWeight: 600 }}>
                                                <p>Paragraph(s)</p>
                                                <p>Speak Time</p>
                                            </div>
                                            <div className="d-flex flex-column ml-2">
                                                <p>{paraCount}</p>
                                                {/* <p>1</p> */}
                                                <p>{timeDisplay}</p>
                                                {/* <p>{speakTime}</p> */}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </CardContent>
                        </Card>

                        <div style={{
                            height: "151px",
                            width: "285px",

                            marginLeft: "5px",
                            borderRadius: "5px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            boxShadow: "0px 0px 10px 2px rgba(0, 0, 0, 0.2)"


                        }}>

                            <div className="P_div1" style={{ display: "flex", flexDirection: "column", width: "100%", justifyContent: "space-between" }}>
                                <div className="P_div1" style={{ flex: 0.25, display: "flex", alignItems: "center", borderBottom: "1px solid grey" }}>
                                    <div style={{ marginLeft: "10px", width: "25px", height: "25px", borderRadius: "50%", backgroundColor: "red" }}>
                                        {/* Content for the 1st row (25% height) */}
                                    </div>
                                    <div style={{ marginLeft: "8px", width: "100%", display: "flex", justifyContent: "space-between" }}>
                                        <div>Identical</div>
                                        <span style={{ alignSelf: "flex-end" }}>{plagiarozedProgress}%</span>
                                    </div>                                    </div>
                                <div className="P_div1" style={{ flex: 0.25, display: "flex", alignItems: "center", borderBottom: "1px solid grey" }}>
                                    <div style={{ marginLeft: "10px", width: "25px", height: "25px", borderRadius: "50%", backgroundColor: "pink" }}>
                                        {/* Content for the 2nd row (25% height) */}
                                    </div>
                                    <div style={{ marginLeft: "8px", width: "100%", display: "flex", justifyContent: "space-between" }}>
                                        <div>Minor Changes</div>
                                        <span className="" style={{ alignSelf: "flex-end" }}>{/*{uniqueWordsPercentage}*/}0%</span>
                                    </div>
                                </div>
                                <div className="P_div1" style={{ flex: 0.25, display: "flex", alignItems: "center", borderBottom: "1px solid grey" }}>
                                    <div style={{ marginLeft: "10px", width: "25px", height: "25px", borderRadius: "50%", backgroundColor: "Orange" }}>
                                        {/* Content for the 3rd row (25% height) */}
                                    </div>
                                    <div style={{ marginLeft: "8px", width: "100%", display: "flex", justifyContent: "space-between" }}>
                                        <div>Paraphrased</div>
                                        <span className="" style={{ alignSelf: "flex-end" }}>0%</span>
                                    </div>                                    </div>
                                <div className="P_div1" style={{ flex: 0.25, display: "flex", alignItems: "center" }}>
                                    <div style={{ marginLeft: "10px", width: "25px", height: "25px", borderRadius: "50%", backgroundColor: "blue" }}>
                                        {/* Content for the 4th row (25% height) */}
                                    </div>
                                    <div style={{ marginLeft: "8px", width: "100%", display: "flex", justifyContent: "space-between" }}>
                                        <div>Omitted Words</div>
                                        <span className="" style={{ alignSelf: "flex-end" }}>0%</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div style={{ height: "65%", width: "100%", margin: "10px", display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                        <div style={{ boxShadow: "0px 0px 10px 2px rgba(0, 0, 0, 0.2)", height: "100%", width: "70%", marginRight: "10px" }}>
                            <div style={{ height: "10%", width: "100%", display: "flex", alignItems: "center", borderBottom: "1px solid #000", }}>
                                <span style={{ marginLeft: "20px" }}>SUBMITTED TEXT</span>
                                <div style={{width:"80%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent:"end" }}>
                                    <div style={{ marginRight: "15px" }}><ArrowDownwardIcon /></div>
                                    <div style={{ marginRight: "15px" }}>  <ArrowUpwardIcon /></div>
                                    <div style={{ marginRight: "15px", fontWeight: "bolder", fontSize: "20px" }}>T</div>
                                </div>
                            </div>
                            <div style={{ padding: "10px" }}>

                                {/* <Editor
                                    editorState={props.editorState}
                                    readOnly={true}
                                    customStyleMap={customStyleMap}

                                /> */}
                                <JoditEditorComp editorHeight={350} editor={plageditor} editorContent={props.editorState} isEdit={false} isMain={false}/>
                            </div>


                        </div>


                        <div style={{ height: "100%", width: "29%", marginRight: "20px", boxShadow: "0px 0px 10px 2px rgba(0, 0, 0, 0.2)" }}>
                            {sourceCount !== 0 ? (

                                <SuggestionCard sentenceArray={outputArray} sourceCount={sourceCount} />

                            ) : (<>
                                <div style={{ height: "10%", width: "100%", display: "flex", alignItems: "center", borderBottom: "1px solid #000", }}><span style={{ marginLeft: "20px" }}>RESULT</span> </div>

                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "90%" }} ><h3>Plagiarism Free</h3></div>
                            </>
                            )}

                        </div>
                    </div>


                    {/* <div className="col-md-4">
                        <SuggestionCard sentenceArray={outputArray} sourceCount={sourceCount} />

                    </div> */}




                </div>
            </div >
            :

            <div

                style={{
                    position: "fixed",
                    top: "62px",
                    left: "0px",
                    width: "100%",
                    height: "100%",
                 backgroundColor: "rgba(0, 0, 0, 0.7)", // semi-transparent black
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex:"99999999" // Ensure the overlay is on top
                }}
            >
                <Loader loading={loading} />

                {/* You can add loading spinners or other content here */}
                <div style={{ height: "98.5%", width: "90%", padding: "40px 20px", backgroundColor: "white", overflowX:'hidden', overflowY:'auto', position:'relative'}}>


                    <button style={{ position: "absolute", top: "1%", right: "1%" }} onClick={() => setCheckPlag(false)}> <LightTooltip title="Close"><CloseIcon style={{ backgroundColor: "transparent" }} /></LightTooltip></button>
                    <Button variant="contained" aria-label="outlined primary button group" style={{ fontSize:'10px',position: "absolute", top: "1%", right: "20%" }} onClick={handleCheckPlagiarism}>Check Again</Button>



                    <div style={{ display: "flex", flexDirection: "column", marginLeft: "11px", marginTop: "10px" }}>
                        <div className='row first-row' style={{display:'flex', flexDirection: "row"}}>
                            <div style={{display:'flex', flexDirection: "row", width:'100%' }}>
                        <Card className="col-md-3">
                            <CardContent className="">
                                {/* <SemiCircleProgressBar percentage={uniqueProgress}>
            <div className="">
            {uniqueProgress}%
            </div>
            </SemiCircleProgressBar> */}
                                <div style={{ width: 100, height: 100, textAlign: "center" }}>
                                    <CircularProgressbar
                                        value={plagiarozedProgress}
                                        // text={`${plagiarozedProgress}%`}
                                        counterClockwise={true}
                                        circleRatio={0.75}
                                        styles={buildStyles({
                                            rotation: 0.37,
                                            strokeLinecap: "butt",
                                            pathColor: 'red',
                                            trailColor: '#F6B6B6',
                                            textColor: 'red'
                                        })}
                                    />
                                </div>
                                <p className="progressText" style={{ color: "red" }}>{plagiarozedProgress}% {progressStatus}</p>
                            </CardContent>
                        </Card>
                        <Card className="col-md-3">
                            <CardContent className="">
                                <div style={{ width: 100, height: 100, textAlign: "center" }}>
                                    <CircularProgressbar
                                        value={uniqueProgress}
                                        // text={`${uniqueProgress}%`}
                                        // counterClockwise={true}
                                        circleRatio={0.75}
                                        styles={buildStyles({
                                            rotation: 0.63,
                                            strokeLinecap: "butt",
                                            pathColor: '#32BC7A',
                                            trailColor: '#7FF1BA',
                                            textColor: '#32BC7A'
                                        })}
                                    />
                                </div>
                                <p className="progressText ml-3" style={{ color: "#32BC7A" }}>{uniqueProgress}% Unique</p>
                            </CardContent>
                        </Card>

                        </div>

                        <Card className="col-md-6">
                            <CardContent>
                                <div>
                                    <div>
                                        <p className="countCardheader">Extra Word Count Statistics</p>
                                    </div>
                                    <div className="countCardValues d-flex justify-content-between">
                                        <div className="d-flex mt-3">
                                            <div className="d-flex flex-column" style={{ fontWeight: 600 }}>
                                                <p>Sentences</p>
                                                <p>Unique Word(s)</p>
                                            </div>
                                            <div className="d-flex flex-column ml-2">
                                                <p>{sentenceCount}</p>
                                                {/* <p>{sen}</p> */}
                                                {/* <p>{uniqueWordCount + " " + "(" + uniqueWordPercent + ")"}</p> */}
                                                <p>{uniqueWordsPercentage}</p>
                                            </div>
                                        </div>
                                        <div className="d-flex mt-3">
                                            <div className="d-flex flex-column" style={{ fontWeight: 600 }}>
                                                <p>Paragraph(s)</p>
                                                <p>Speak Time</p>
                                            </div>
                                            <div className="d-flex flex-column ml-2">
                                                <p>{paraCount}</p>
                                                {/* <p>1</p> */}
                                                <p>{timeDisplay}</p>
                                                {/* <p>{speakTime}</p> */}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </CardContent>
                        </Card>

                        </div>

                        <div style={{
                            height: "151px",
                            width: "100%",

                           
                            borderRadius: "5px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            boxShadow: "0px 0px 10px 2px rgba(0, 0, 0, 0.2)",
                            padding:'15px'



                        }}>

                            <div className="P_div1" style={{ display: "flex", flexDirection: "column", width: "100%", justifyContent: "space-between" }}>
                                <div className="P_div1" style={{ flex: 0.25, display: "flex", alignItems: "center", borderBottom: "1px solid grey" }}>
                                    <div style={{ marginLeft: "10px", width: "25px", height: "25px", borderRadius: "50%", backgroundColor: "red" }}>
                                        {/* Content for the 1st row (25% height) */}
                                    </div>
                                    <div style={{ marginLeft: "8px", width: "100%", display: "flex", justifyContent: "space-between" }}>
                                        <div>Identical</div>
                                        <span style={{ alignSelf: "flex-end" }}>{plagiarozedProgress}%</span>
                                    </div>                                    </div>
                                <div className="P_div1" style={{ flex: 0.25, display: "flex", alignItems: "center", borderBottom: "1px solid grey" }}>
                                    <div style={{ marginLeft: "10px", width: "25px", height: "25px", borderRadius: "50%", backgroundColor: "pink" }}>
                                        {/* Content for the 2nd row (25% height) */}
                                    </div>
                                    <div style={{ marginLeft: "8px", width: "100%", display: "flex", justifyContent: "space-between" }}>
                                        <div>Minor Changes</div>
                                        <span className="" style={{ alignSelf: "flex-end" }}>{/*{uniqueWordsPercentage}*/}0%</span>
                                    </div>
                                </div>
                                <div className="P_div1" style={{ flex: 0.25, display: "flex", alignItems: "center", borderBottom: "1px solid grey" }}>
                                    <div style={{ marginLeft: "10px", width: "25px", height: "25px", borderRadius: "50%", backgroundColor: "Orange" }}>
                                        {/* Content for the 3rd row (25% height) */}
                                    </div>
                                    <div style={{ marginLeft: "8px", width: "100%", display: "flex", justifyContent: "space-between" }}>
                                        <div>Paraphrased</div>
                                        <span className="" style={{ alignSelf: "flex-end" }}>0%</span>
                                    </div>                                    </div>
                                <div className="P_div1" style={{ flex: 0.25, display: "flex", alignItems: "center" }}>
                                    <div style={{ marginLeft: "10px", width: "25px", height: "25px", borderRadius: "50%", backgroundColor: "blue" }}>
                                        {/* Content for the 4th row (25% height) */}
                                    </div>
                                    <div style={{ marginLeft: "8px", width: "100%", display: "flex", justifyContent: "space-between" }}>
                                        <div>Omitted Words</div>
                                        <span className="" style={{ alignSelf: "flex-end" }}>0%</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div style={{ height: "65%", width: "100%", margin: "10px", display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                        <div style={{ boxShadow: "0px 0px 10px 2px rgba(0, 0, 0, 0.2)", height: "90%", width: "97%", marginRight: "10px" }}>
                            <div style={{ height: "10%", width: "100%", display: "flex", alignItems: "center", borderBottom: "1px solid #000", }}>
                                <span style={{ marginLeft: "20px", fontSize:'11px' }}>SUBMITTED TEXT</span>
                                <div style={{width:"80%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent:"end" }}>
                                    <div style={{ marginRight: "15px" }}><ArrowDownwardIcon /></div>
                                    <div style={{ marginRight: "15px" }}>  <ArrowUpwardIcon /></div>
                                    <div style={{ marginRight: "15px", fontWeight: "bolder", fontSize: "20px" }}>T</div>
                                </div>
                            </div>
                            <div style={{ padding: "10px" }}>

                                {/* <Editor
                                    editorState={props.editorState}
                                    readOnly={true}
                                    customStyleMap={customStyleMap}

                                /> */}
                                <JoditEditorComp editorHeight={250} editor={plageditor} editorContent={props.editorState} isEdit={false} isMain={false}/>
                            </div>


                        </div>


                        <div style={{ height: "100%", width: "97%", marginRight: "20px", boxShadow: "0px 0px 10px 2px rgba(0, 0, 0, 0.2)" }}>
                            {sourceCount !== 0 ? (

                                <SuggestionCard sentenceArray={outputArray} sourceCount={sourceCount} mobileView = {props.mobileView} />

                            ) : (<>
                                <div style={{ height: "10%", width: "100%", display: "flex", alignItems: "center", borderBottom: "1px solid #000", }}><span style={{ marginLeft: "20px" }}>RESULT</span> </div>

                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "90%" }} ><h3>Plagiarism Free</h3></div>
                            </>
                            )}

                        </div>
                    </div>


                    {/* <div className="col-md-4">
                        <SuggestionCard sentenceArray={outputArray} sourceCount={sourceCount} />

                    </div> */}




                </div>
            </div >


)}


</>
    );
}

export default CoAuther_PlagiarismChecker_Text;
