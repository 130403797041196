import React, { useState, Component, useEffect } from "react";
import NetworkDetector from "../HigherOrderComponents/NetworkDetector";
//import Citation from "../Forms/Citation";
import CoAuthorNewProject from '../Forms/CoAuthorNewProject'
import SideNavBar from "../SideNavBar";
import { withRouter } from "react-router-dom";

const CoAuthorNewProjectPage = (props) => {
  console.log(props.location.state);

  return (
    <SideNavBar>
      {/* <Citation value={props.location.state} /> */}
      <CoAuthorNewProject />
    </SideNavBar>
  );
};

export default withRouter(NetworkDetector(CoAuthorNewProjectPage));
