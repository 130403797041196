import React from 'react';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';

import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
export const option_ = {
  inlineStyles: {
    red: {
      style: {
        color: "red"
      }
    },
    handwritten: {
      style: {
        fontFamily: "hand-writing"
      }
    },
    sans_serif: {
      style: {
        fontFamily: "Arial, Helvetica, sans-serif"
      }
    },
    serif: {
      style: {
        fontFamily: "Times New Roman, Times, serif"
      }
    },
    slab_serif: {
      style: {
        fontFamily: "Josefin-Slab"
      }
    },
    monospace: {
      style: {
        fontFamily: "Courier New, Courier, monospace"
      }
    },
    arial: {
      style: {
        fontFamily: "Arial, Helvetica, sans-serif",
      },
    },
    courier_new: {
      style: {
        fontFamily: "Courier New, Courier, monospace",
      },
    },
    georgia: {
      style: {
        fontFamily: "Georgia, serif",
      },
    },
    lucida_sans: {
      style: {
        fontFamily: "Lucida Sans, Lucida, sans-serif",
      },
    },
    times_new_roman: {
      style: {
        fontFamily: "Times New Roman, Times, serif",
      },
    },
    trebuchetms: {
      style: {
        fontFamily: "Trebuchet MS, Helvetica, sans-serif",
      },
    },
    verdana: {
      style: {
        fontFamily: "Verdana, Geneva, sans-serif",
      },
    },
    helvetica: {
      style: {
        fontFamily: "Helvetica, Arial, sans-serif",
      },
    },roboto_regular:{
      style:{
        fontFamily: "Roboto, sans-serif",
      }
    },
    black: {
      style: {
        color: "#343a40"
      }
    },




    
    blue: {
      style: {
        color: "blue"
      }
    },
    indigo: {
      style: {
        color: "indigo"
      }
    },
    purple: {
      style: {
        color: "purple"
      }
    },
    pink: {
      style: {
        color: "pink"
      }
    },
    orange: {
      style: {
        color: "orange"
      }
    },
    green: {
      style: {
        color: "green"
      }
    },
    yellow: {
      style: {
        color: "yellow"
      }
    },
    fontSize01: {
      style: {
        fontSize: 1
      }
    },
    fontSize02: {
      style: {
        fontSize: 2
      }
    },
    fontSize03: {
      style: {
        fontSize: 3
      }
    },
    fontSize04: {
      style: {
        fontSize: 4
      }
    },
    fontSize05: {
      style: {
        fontSize: 5
      }
    },
    fontSize06: {
      style: {
        fontSize: 6
      }
    },
    fontSize07: {
      style: {
        fontSize: 7
      }
    },
    fontSize08: {
      style: {
        fontSize: 8
      }
    },
    fontSize09: {
      style: {
        fontSize: 9
      }
    },
    fontSize10: {
      style: {
        fontSize: 10
      }
    },
    fontSize11: {
      style: {
        fontSize: 11
      }
    },
    fontSize12: {
      style: {
        fontSize: 12
      }
    },
    fontSize13: {
      style: {
        fontSize: 13
      }
    },
    fontSize14: {
      style: {
        fontSize: 14
      }
    },
    fontSize15: {
      style: {
        fontSize: 15
      }
    },
    fontSize16: {
      style: {
        fontSize: 16
      }
    },
    fontSize17: {
      style: {
        fontSize: 17
      }
    },
    fontSize18: {
      style: {
        fontSize: 18
      }
    },
    fontSize19: {
      style: {
        fontSize: 19
      }
    },
    fontSize20: {
      style: {
        fontSize: 20
      }
    },
    fontSize21: {
      style: {
        fontSize: 21
      }
    },
    fontSize22: {
      style: {
        fontSize: 22
      }
    },
    fontSize23: {
      style: {
        fontSize: 23
      }
    },
    fontSize24: {
      style: {
        fontSize: 24
      }
    },
    fontSize25: {
      style: {
        fontSize: 25
      }
    },
    fontSize26: {
      style: {
        fontSize: 26
      }
    },
    fontSize27: {
      style: {
        fontSize: 27
      }
    },
    fontSize28: {
      style: {
        fontSize: 28
      }
    },
    fontSize29: {
      style: {
        fontSize: 29
      }
    },
    fontSize30: {
      style: {
        fontSize: 30
      }
    },
    fontSize31: {
      style: {
        fontSize: 31
      }
    },
    "fontSize32": {
      style: {
        fontSize: 32
      }
    },
    fontSize33: {
      style: {
        fontSize: 33
      }
    },
    fontSize34: {
      style: {
        fontSize: 34
      }
    },
    fontSize35: {
      style: {
        fontSize: 35
      }
    },
    fontSize36: {
      style: {
        fontSize: 36
      }
    },
    fontSize37: {
      style: {
        fontSize: 37
      }
    },
    fontSize38: {
      style: {
        fontSize: 38
      }
    },
    fontSize39: {
      style: {
        fontSize: 39
      }
    },
    fontSize40: {
      style: {
        fontSize: 40
      }
    },
    fontSize41: {
      style: {
        fontSize: 41
      }
    },
    fontSize42: {
      style: {
        fontSize: 42
      }
    },
    fontSize43: {
      style: {
        fontSize: 43
      }
    },
    fontSize44: {
      style: {
        fontSize: 44
      }
    },
    fontSize45: {
      style: {
        fontSize: 45
      }
    },
    fontSize46: {
      style: {
        fontSize: 46
      }
    },
    fontSize47: {
      style: {
        fontSize: 47
      }
    },
    fontSize48: {
      style: {
        fontSize: 48
      }
    },
    fontSize49: {
      style: {
        fontSize: 49
      }
    },
    fontSize50: {
      style: {
        fontSize: 50
      }
    },
    fontSize51: {
      style: {
        fontSize: 51
      }
    },
    fontSize52: {
      style: {
        fontSize: 52
      }
    },
    fontSize53: {
      style: {
        fontSize: 53
      }
    },
    fontSize54: {
      style: {
        fontSize: 54
      }
    },
    fontSize55: {
      style: {
        fontSize: 55
      }
    },
    fontSize56: {
      style: {
        fontSize: 56
      }
    },
    fontSize57: {
      style: {
        fontSize: 57
      }
    },
    fontSize58: {
      style: {
        fontSize: 58
      }
    },
    fontSize59: {
      style: {
        fontSize: 59
      }
    },
    fontSize60: {
      style: {
        fontSize: 60
      }
    },
    fontSize61: {
      style: {
        fontSize: 61
      }
    },
    fontSize62: {
      style: {
        fontSize: 62
      }
    },
    fontSize63: {
      style: {
        fontSize: 63
      }
    },
    fontSize64: {
      style: {
        fontSize: 64
      }
    },
    fontSize65: {
      style: {
        fontSize: 65
      }
    },
    fontSize66: {
      style: {
        fontSize: 66
      }
    },
    fontSize67: {
      style: {
        fontSize: 67
      }
    },
    fontSize68: {
      style: {
        fontSize: 68
      }
    },
    fontSize69: {
      style: {
        fontSize: 69
      }
    },
    fontSize70: {
      style: {
        fontSize: 70
      }
    },
    fontSize71: {
      style: {
        fontSize: 71
      }
    },
    fontSize72: {
      style: {
        fontSize: 72
      }
    },
    fontSize73: {
      style: {
        fontSize: 73
      }
    },
    fontSize74: {
      style: {
        fontSize: 74
      }
    },
    fontSize75: {
      style: {
        fontSize: 75
      }
    },
    fontSize76: {
      style: {
        fontSize: 76
      }
    },
    fontSize77: {
      style: {
        fontSize: 77
      }
    },
    fontSize78: {
      style: {
        fontSize: 78
      }
    },
    fontSize79: {
      style: {
        fontSize: 79
      }
    },
    fontSize80: {
      style: {
        fontSize: 80
      }
    },
    fontSize81: {
      style: {
        fontSize: 81
      }
    },
    fontSize82: {
      style: {
        fontSize: 82
      }
    },
    fontSize83: {
      style: {
        fontSize: 83
      }
    },
    fontSize84: {
      style: {
        fontSize: 84
      }
    },
    fontSize85: {
      style: {
        fontSize: 85
      }
    },
    fontSize86: {
      style: {
        fontSize: 86
      }
    },
    fontSize87: {
      style: {
        fontSize: 87
      }
    },
    fontSize88: {
      style: {
        fontSize: 88
      }
    },
    fontSize89: {
      style: {
        fontSize: 89
      }
    },
    fontSize90: {
      style: {
        fontSize: 90
      }
    },
    fontSize91: {
      style: {
        fontSize: 91
      }
    },
    fontSize92: {
      style: {
        fontSize: 92
      }
    },
    fontSize93: {
      style: {
        fontSize: 93
      }
    },
    fontSize94: {
      style: {
        fontSize: 94
      }
    },
    fontSize95: {
      style: {
        fontSize: 95
      }
    },
    fontSize96: {
      style: {
        fontSize: 96
      }
    },
    fontSize97: {
      style: {
        fontSize: 97
      }
    },
    fontSize98: {
      style: {
        fontSize: 98
      }
    },
    fontSize99: {
      style: {
        fontSize: 99
      }
    },
    
    SUPERSCRIPT: {
      style: {
        fontSize: "0.6em",
        verticalAlign: "super"
      }
    },
    SUBSCRIPT: {
      style: {
        fontSize: "0.6em",
        verticalAlign: "sub"
      }
    },
    citations:{
      style:{
        // fontSize: 28
      textDecorationLine: "underline",
      textDecorationStyle: "wavy",
      textDecorationColor: "#7D8FFD"
      }
    },
    alignLeft: {
      style: {
        display: "flex",
        textAlign:"left",
        justifyContent: "left",
      },
    },
    alignCenter: {
      style: {
        display: "flex",
        justifyContent: "center",
        textAlign:"center",
      },
    },
    alignRight: {
      style: {
        display: "flex", 
        textAlign:"right",
        justifyContent: "right",
      },
    },
    clearStyle:{
      style:{
        fontWeight: 'normal',
        fontStyle: 'normal',
        textDecoration: 'none',
        color: 'inherit', // Reset color to inherit from the parent
        fontFamily: 'inherit', // Reset font family to inherit from the parent
        fontSize: 'inherit', // Reset font size to inherit from the parent
        textAlign: 'inherit', // Reset text alignment to inherit from the parent
        lineHeight: 'inherit',
      }
    }
   
  }
};
 
export const styleMap = () => {
  const inlineStyles = option_.inlineStyles;
  const styleMap = {};
  for (const key in inlineStyles) {
    const element = inlineStyles[key];
    styleMap[key] = element.style;
  }
  styleMap.HIGHLIGHT = {
    backgroundColor: "#FF7F7F",
    color: "black",
  };
  styleMap.CURRENT_HIGHLIGHT = {
    backgroundColor: "yellow",
    color: "black", 
};

  return styleMap;
};
 
export const FONT_TYPES = [{
  label: "handwritten",
  style: "handwritten"
},
{
  label: "sans_serif",
  style: "sans_serif"
},
{
  label: "serif",
  style: "serif"
},
{
  label: "slab_serif",
  style: "slab_serif"
},
{
  label: "monospace",
  style: "monospace"
},
{
  label:"arial",
  style:"arial"
},
{
  label:"courier_new",
  style:"courier_new"
},
{
  label:"georgia",
  style:"georgia"
},

{
  label:"lucida_sans",
  style:"lucida_sans"
},
{
  label:"times_new_roman",
  style:"times_new_roman"
},
{
  label:"trebuchetms",
  style:"trebuchetms"
},
{
  label:"verdana",
  style:"verdana"
},
{
  label:"helvetica",
  style:"helvetica"
},
{
  label:"roboto_regular",
  style:"roboto_regular"
}

];
 
export const FONT_COLOR = [{
    label: "Black",
    style: "black"
  },
  {
    label: "Red",
    style: "red"
  },
  {
    label: "Blue",
    style: "blue"
  },
  {
    label: "Indigo",
    style: "indigo"
  },
  {
    label: "Purple",
    style: "purple"
  },
  {
    label: "Pink",
    style: "pink"
  },
  {
    label: "Orange",
    style: "orange"
  },
  {
    label: "Green",
    style: "green"
  },
  {
    label: "Yellow",
    style: "yellow"
  }
];
 
export const FONT_SIZE = [
  { label: '01', style:  "fontSize01"  },
  { label: '02', style:  "fontSize02"  },
  { label: '03', style: "fontSize03"  },
  { label: '04', style:  "fontSize04"  },
  { label: '05', style:  "fontSize05" },
  { label: '06', style: "fontSize06"},
  { label: '07', style: "fontSize07" },
  { label: '08', style:  "fontSize08" },
  { label: '09', style:  "fontSize09" },
  { label: '10', style:  "fontSize10"  },
  { label: '11', style:  "fontSize11"  },
  { label: '12', style:  "fontSize12"  },
  { label: '13', style:  "fontSize13"  },
  { label: '14', style:  "fontSize14"  },
  { label: '15', style:  "fontSize15"  },
  { label: '16', style:  "fontSize16" },
  { label: '17', style:  "fontSize17"  },
  { label: '18', style:  "fontSize18"  },
  { label: '19', style:  "fontSize19"  },
  { label: '20', style:  "fontSize20"  },
  { label: '21', style: "fontSize21"  },
  { label: '22', style:  "fontSize22" } ,
  { label: '23', style:  "fontSize23" } ,
  { label: '24', style:  "fontSize24" } ,
  { label: '25', style:  "fontSize25" } ,
  { label: '26', style: "fontSize26" } ,
  { label: '27', style:  "fontSize27" } ,
  { label: '28', style:  "fontSize28" },
  { label: '29', style:  "fontSize29" } ,
  { label: '30', style:  "fontSize30" } ,
  { label: '31', style:  "fontSize31" } ,
  { label: '32', style:  "fontSize32" } ,
  { label: '33', style:  "fontSize33" } ,
  { label: '34', style:  "fontSize34" } ,
  { label: '35', style:  "fontSize35" } ,
  { label: '36', style:  "fontSize36" } ,
  { label: '37', style:  "fontSize37" } ,
  { label: '38', style:  "fontSize38" } ,
  { label: '39', style:  "fontSize39" } ,
  { label: '40', style:  "fontSize40" } ,
  { label: '41', style:  "fontSize41" } ,
  { label: '42', style:  "fontSize42" } ,
  { label: '43', style:  "fontSize43" } ,
  { label: '44', style:  "fontSize44" } ,
  { label: '45', style:  "fontSize45" } ,
  { label: '46', style:  "fontSize46" } ,
  { label: '47', style:  "fontSize47" } ,
  { label: '48', style:  "fontSize48" } ,
  { label: '49', style:  "fontSize49" } ,
  { label: '50', style:  "fontSize50" } ,
  { label: '51', style: "fontSize51" } ,
  { label: '52', style:  "fontSize52" } ,
  { label: '53', style:  "fontSize53" } ,
  { label: '54', style:  "fontSize54" } ,
  { label: '55', style:  "fontSize55" } ,
  { label: '56', style:  "fontSize56" } ,
  { label: '57', style:  "fontSize57" } ,
  { label: '58', style:  "fontSize58" },
  { label: '59', style:  "fontSize59" } ,
  { label: '60', style:  "fontSize60" } ,
  { label: '61', style:  "fontSize61" } ,
  { label: '62', style:  "fontSize62" } ,
  { label: '63', style:  "fontSize63" } ,
  { label: '64', style:  "fontSize64" } ,
  { label: '65', style:  "fontSize65" } ,
  { label: '66', style: "fontSize66" } ,
  { label: '67', style:  "fontSize67" } ,
  { label: '68', style:  "fontSize68" } ,
  { label: '69', style:  "fontSize69" } ,
  { label: '70', style:  "fontSize70" } ,
  { label: '71', style:  "fontSize71" } ,
  { label: '72', style:  "fontSize72" } ,
  { label: '73', style:  "fontSize73" } ,
  { label: '74', style:  "fontSize74" } ,
  { label: '75', style:  "fontSize75" } ,
  { label: '76', style:  "fontSize76" } ,
  { label: '77', style:  "fontSize77" } ,
  { label: '78', style:  "fontSize78" } ,
  { label: '79', style:  "fontSize79" } ,
  { label: '80', style:  "fontSize80" } ,
  { label: '81', style:  "fontSize81" } ,
  { label: '82', style:  "fontSize82" } ,
  { label: '83', style: "fontSize83" } ,
  { label: '84', style:  "fontSize84" } ,
  { label: '85', style:  "fontSize85" },
  { label: '86', style:  "fontSize86" } ,
  { label: '87', style:  "fontSize87" } ,
  { label: '88', style:  "fontSize88" } ,
  { label: '89', style:  "fontSize89" } ,
  { label: '90', style:  "fontSize90" } ,
  { label: '91', style:  "fontSize91" } ,
  { label: '92', style:  "fontSize92" } ,
  { label: '93', style:  "fontSize93" } ,
  { label: '94', style:  "fontSize94" } ,
  { label: '95', style:  "fontSize95" } ,
  { label: '96', style:  "fontSize96" } ,
  { label: '97', style:  "fontSize97" } ,
  { label: '98', style:  "fontSize98" } ,
  { label: '99', style:  "fontSize99" } 
];

 
export const Heading = [{
  label:'Heading 1',
  style:"fontSize24"
},
{
  label:'Heading 2',
  style:"fontSize20"
},
{
  label:'Heading 3',
  style:"fontSize18"
},
{
  label:'Heading 4',
  style:"fontSize16"
},
{
  label:'Heading 5',
  style:"fontSize14"
},
{
  label:'Heading 6',
  style:"fontSize12"
}
];
 
export const BLOCK_TYPES = [{
    label: "Normal Text",
    style: "Normal Text"
  },
  {
    label: "H1",
    style: "header-one"
  },
  {
    label: "H2",
    style: "header-two"
  },
  {
    label: "H3",
    style: "header-three"
  },
  {
    label: "H4",
    style: "header-four"
  },
  {
    label: "H5",
    style: "header-five"
  },
  {
    label: "H6",
    style: "header-six"
  }
];
 
export const Text_Align=[
  {
    label:<FormatAlignLeftIcon style={{fontSize:"14px"}}/>,
    style:"alignLeft"
  },
  {
    label:<FormatAlignCenterIcon  style={{fontSize:"14px"}}/>,
    style:"alignCenter"
  },
  {
    label:<FormatAlignRightIcon  style={{fontSize:"14px"}}/>,
    style:"alignRight"
  }
];
 
export const Special_Characters=[
    {
      label:'←',
      name:'LEFTWARDS SIMPLE ARROW',
      shortName:'U+2190',
      type:'Arrow'
    },
    {
      label:'→',
      name:'RIGHTWARDS SIMPLE ARROW',
      shortName:'U+2192',
      type:'Arrow'
    },
    {
      label:'↑',
      name:'UPWARDS SIMPLE ARROW',
      shortName:'U+2191',
      type:'Arrow'
    },
    {
      label:'↓',
      name:'DOWNWARDS SIMPLE ARROW',
      shortName:'U+2193',
      type:'Arrow'
    },
    {
      label:'⇐',
      name:'DOWNWARDS SIMPLE ARROW',
      shortName:'U+2193',
      type:'Arrow'
    },
    {
      label:'⇒',
      name:'DOWNWARDS SIMPLE ARROW',
      shortName:'U+2193',
      type:'Arrow'
    },
    {
      label:'⇑',
      name:'DOWNWARDS SIMPLE ARROW',
      shortName:'U+2193',
      type:'Arrow'
    },
    {
      label:'⇓',
      name:'DOWNWARDS SIMPLE ARROW',
      shortName:'U+2193',
      type:'Arrow'
    },
    {
      label:'⇠',
      name:'DOWNWARDS SIMPLE ARROW',
      shortName:'U+2193',
      type:'Arrow'
    },
    {
      label:'⇡',
      name:'DOWNWARDS SIMPLE ARROW',
      shortName:'U+2193',
      type:'Arrow'
    },
    {
      label:'⇣',
      name:'DOWNWARDS SIMPLE ARROW',
      shortName:'U+2193',
      type:'Arrow'
    },
    {
      label:'⇤',
      name:'DOWNWARDS SIMPLE ARROW',
      shortName:'U+2193',
      type:'Arrow'
    },
    {
      label:'⇥',
      name:'DOWNWARDS SIMPLE ARROW',
      shortName:'U+2193',
      type:'Arrow'
    },
 
    {
      label:'⤒',
      name:'DOWNWARDS SIMPLE ARROW',
      shortName:'U+2193',
      type:'Arrow'
    },
    {
      label:'⤓',
      name:'DOWNWARDS SIMPLE ARROW',
      shortName:'U+2193',
      type:'Arrow'
    },
    {
      label:'↨',
      name:'DOWNWARDS SIMPLE ARROW',
      shortName:'U+2193',
      type:'Arrow'
    },
    {
      label:'⤒',
      name:'DOWNWARDS SIMPLE ARROW',
      shortName:'U+2193',
      type:'Arrow'
    },
    {
      label:'🔙',
      name:'DOWNWARDS SIMPLE ARROW',
      shortName:'U+2193',
      type:'Arrow'
    },
    {
      label:'🔚',
      name:'DOWNWARDS SIMPLE ARROW',
      shortName:'U+2193',
      type:'Arrow'
    },
    {
      label:'🔛',
      name:'DOWNWARDS SIMPLE ARROW',
      shortName:'U+2193',
      type:'Arrow'
    },
    {
      label:'🔜',
      name:'DOWNWARDS SIMPLE ARROW',
      shortName:'U+2193',
      type:'Arrow'
    },
    {
      label:'🔝',
      name:'DOWNWARDS SIMPLE ARROW',
      shortName:'U+2193',
      type:'Arrow'
    },
   
//Currency
    {
      label:'$',
      name:'DOLLAR SIGN',
      shortName:'U+24',
      type:'Arrow'
    },
    {
      label:'€',
      name:'EURO SIGN',
      shortName:'U+20AC',
      type:'Arrow'
    },
    {
      label:'¥',
      name:'YEN SIGN',
      shortName:'U+A5',
      type:'Arrow'
    },
    {
      label:'£',
      name:'POUND SIGN',
      shortName:'U+A3',
      type:'Arrow'
    },
    {
      label:'¢',
      name:'POUND SIGN',
      shortName:'U+A3',
      type:'Arrow'
    },
    {
      label:'₠',
      name:'POUND SIGN',
      shortName:'U+A3',
      type:'Arrow'
    },
    {
      label:'₡',
      name:'POUND SIGN',
      shortName:'U+A3',
      type:'Arrow'
    },
    {
      label:'₢',
      name:'POUND SIGN',
      shortName:'U+A3',
      type:'Arrow'
    },
    {
      label:'₣',
      name:'POUND SIGN',
      shortName:'U+A3',
      type:'Arrow'
    },
    {
      label:'₤',
      name:'POUND SIGN',
      shortName:'U+A3',
      type:'Arrow'
    },
    {
      label:'¤',
      name:'POUND SIGN',
      shortName:'U+A3',
      type:'Arrow'
    },
    {
      label:'₿',
      name:'POUND SIGN',
      shortName:'U+A3',
      type:'Arrow'
    },
    {
      label:'₥',
      name:'POUND SIGN',
      shortName:'U+A3',
      type:'Arrow'
    },
    {
      label:'₧',
      name:'POUND SIGN',
      shortName:'U+A3',
      type:'Arrow'
    },
    {
      label:'₨',
      name:'POUND SIGN',
      shortName:'U+A3',
      type:'Arrow'
    },
    {
      label:'₩',
      name:'POUND SIGN',
      shortName:'U+A3',
      type:'Arrow'
    },
    {
      label:'₪',
      name:'POUND SIGN',
      shortName:'U+A3',
      type:'Arrow'
    },
    {
      label:'₫',
      name:'POUND SIGN',
      shortName:'U+A3',
      type:'Arrow'
    },
    {
      label:'₭',
      name:'POUND SIGN',
      shortName:'U+A3',
      type:'Arrow'
    },
    {
      label:'₮',
      name:'POUND SIGN',
      shortName:'U+A3',
      type:'Arrow'
    },
    {
      label:'₯',
      name:'POUND SIGN',
      shortName:'U+A3',
      type:'Arrow'
    },
    {
      label:'₰',
      name:'POUND SIGN',
      shortName:'U+A3',
      type:'Arrow'
    },
    {
      label:'₱',
      name:'POUND SIGN',
      shortName:'U+A3',
      type:'Arrow'
    },
    {
      label:'₲',
      name:'POUND SIGN',
      shortName:'U+A3',
      type:'Arrow'
    },
    {
      label:'₳',
      name:'POUND SIGN',
      shortName:'U+A3',
      type:'Arrow'
    },
    {
      label:'₴',
      name:'POUND SIGN',
      shortName:'U+A3',
      type:'Arrow'
    },
    {
      label:'₵',
      name:'POUND SIGN',
      shortName:'U+A3',
      type:'Arrow'
    },
    {
      label:'₶',
      name:'POUND SIGN',
      shortName:'U+A3',
      type:'Arrow'
    },
    {
      label:'₷',
      name:'POUND SIGN',
      shortName:'U+A3',
      type:'Arrow'
    },
    {
      label:'₸',
      name:'POUND SIGN',
      shortName:'U+A3',
      type:'Arrow'
    },
    {
      label:'₹',
      name:'POUND SIGN',
      shortName:'U+A3',
      type:'Arrow'
    },
    {
      label:'₺',
      name:'POUND SIGN',
      shortName:'U+A3',
      type:'Arrow'
    },
    {
      label:'₻',
      name:'POUND SIGN',
      shortName:'U+A3',
      type:'Arrow'
    },
    {
      label:'₼',
      name:'POUND SIGN',
      shortName:'U+A3',
      type:'Arrow'
    },
    {
      label:'₽',
      name:'POUND SIGN',
      shortName:'U+A3',
      type:'Arrow'
    },
 
 
    //
    {
      label:'‹',
      name:'SINGLE LEFT-POINTING ANGLE QUOTATION MARK',
      shortName:'U+2039',
      type:'Arrow'
    },
    {
      label:'›',
      name:'SINGLE RIGHT-POINTING ANGLE QUOTATION MARK',
      shortName:'U+203A',
      type:'Arrow'
    },
    {
      label:'«',
      name:'LEFT-POINTING DOUBLE ANGLE QUOTATION MARK',
      shortName:'U+AB',
      type:'Arrow'
    },
    {
      label:'»',
      name:'RIGHT-POINTING DOUBLE ANGLE QUOTATION MARK',
      shortName:'U+bB',
      type:'Arrow'
    },
    {
      label:'‘',
      name:'SINGLE LEFT-POINTING ANGLE QUOTATION MARK',
      shortName:'U+2039',
      type:'Arrow'
    },
    {
      label:'’',
      name:'SINGLE RIGHT-POINTING ANGLE QUOTATION MARK',
      shortName:'U+203A',
      type:'Arrow'
    },
    {
      label:'“',
      name:'LEFT-POINTING DOUBLE ANGLE QUOTATION MARK',
      shortName:'U+AB',
      type:'Arrow'
    },
    {
      label:'”',
      name:'RIGHT-POINTING DOUBLE ANGLE QUOTATION MARK',
      shortName:'U+bB',
      type:'Arrow'
    },
    {
      label:'‚',
      name:'SINGLE LEFT-POINTING ANGLE QUOTATION MARK',
      shortName:'U+2039',
      type:'Arrow'
    },
    {
      label:'„',
      name:'SINGLE RIGHT-POINTING ANGLE QUOTATION MARK',
      shortName:'U+203A',
      type:'Arrow'
    },
    {
      label:'¡',
      name:'LEFT-POINTING DOUBLE ANGLE QUOTATION MARK',
      shortName:'U+AB',
      type:'Arrow'
    },
    {
      label:'¿',
      name:'RIGHT-POINTING DOUBLE ANGLE QUOTATION MARK',
      shortName:'U+bB',
      type:'Arrow'
    },
    {
      label:'‥',
      name:'LEFT-POINTING DOUBLE ANGLE QUOTATION MARK',
      shortName:'U+AB',
      type:'Arrow'
    },
    {
      label:'…',
      name:'RIGHT-POINTING DOUBLE ANGLE QUOTATION MARK',
      shortName:'U+bB',
      type:'Arrow'
    },
    {
      label:'‡',
      name:'LEFT-POINTING DOUBLE ANGLE QUOTATION MARK',
      shortName:'U+AB',
      type:'Arrow'
    },
    {
      label:'‰',
      name:'RIGHT-POINTING DOUBLE ANGLE QUOTATION MARK',
      shortName:'U+bB',
      type:'Arrow'
    },
    {
      label:'‱',
      name:'LEFT-POINTING DOUBLE ANGLE QUOTATION MARK',
      shortName:'U+AB',
      type:'Arrow'
    },
    {
      label:'‼',
      name:'RIGHT-POINTING DOUBLE ANGLE QUOTATION MARK',
      shortName:'U+bB',
      type:'Arrow'
    },
    {
      label:'⁈',
      name:'LEFT-POINTING DOUBLE ANGLE QUOTATION MARK',
      shortName:'U+AB',
      type:'Arrow'
    },
    {
      label:'⁉',
      name:'RIGHT-POINTING DOUBLE ANGLE QUOTATION MARK',
      shortName:'U+bB',
      type:'Arrow'
    },
    {
      label:'⁉',
      name:'RIGHT-POINTING DOUBLE ANGLE QUOTATION MARK',
      shortName:'U+bB',
      type:'Arrow'
    },
    {
      label:'⁇',
      name:'RIGHT-POINTING DOUBLE ANGLE QUOTATION MARK',
      shortName:'U+bB',
      type:'Arrow'
    },
    {
      label:'©',
      name:'RIGHT-POINTING DOUBLE ANGLE QUOTATION MARK',
      shortName:'U+bB',
      type:'Arrow'
    },
    {
      label:'®',
      name:'RIGHT-POINTING DOUBLE ANGLE QUOTATION MARK',
      shortName:'U+bB',
      type:'Arrow'
    },
    {
      label:'™',
      name:'RIGHT-POINTING DOUBLE ANGLE QUOTATION MARK',
      shortName:'U+bB',
      type:'Arrow'
    },
    {
      label:'§',
      name:'RIGHT-POINTING DOUBLE ANGLE QUOTATION MARK',
      shortName:'U+bB',
      type:'Arrow'
    },
    {
      label:'¶',
      name:'RIGHT-POINTING DOUBLE ANGLE QUOTATION MARK',
      shortName:'U+bB',
      type:'Arrow'
    },
    {
      label:'⁋',
      name:'RIGHT-POINTING DOUBLE ANGLE QUOTATION MARK',
      shortName:'U+bB',
      type:'Arrow'
    },
 
    //
    {
      label:'<',
      name:'LESS-THAN SIGN',
      shortName:'U+3C',
      type:'Arrow'
    },
    {
      label:'>',
      name:'GREATER-THAN SIGN',
      shortName:'U+3E',
      type:'Arrow'
    },
    {
      label:'≤',
      name:'LESS-THAN OR EQUAL TO',
      shortName:'U+2264',
      type:'Arrow'
    },
    {
      label:'≥',
      name:'GREATER-THAN OR EQUAL TO',
      shortName:'U+2265',
      type:'Arrow'
    },
    {
      label:'–',
      name:'LESS-THAN SIGN',
      shortName:'U+3C',
      type:'Arrow'
    },
    {
      label:'—',
      name:'GREATER-THAN SIGN',
      shortName:'U+3E',
      type:'Arrow'
    },
    {
      label:'¯',
      name:'LESS-THAN OR EQUAL TO',
      shortName:'U+2264',
      type:'Arrow'
    },
    {
      label:'‾',
      name:'GREATER-THAN OR EQUAL TO',
      shortName:'U+2265',
      type:'Arrow'
    },
    {
      label:'°',
      name:'LESS-THAN SIGN',
      shortName:'U+3C',
      type:'Arrow'
    },
    {
      label:'−',
      name:'GREATER-THAN SIGN',
      shortName:'U+3E',
      type:'Arrow'
    },
    {
      label:'±',
      name:'LESS-THAN OR EQUAL TO',
      shortName:'U+2264',
      type:'Arrow'
    },
    {
      label:'÷',
      name:'GREATER-THAN OR EQUAL TO',
      shortName:'U+2265',
      type:'Arrow'
    },
    {
      label:'⁄',
      name:'LESS-THAN SIGN',
      shortName:'U+3C',
      type:'Arrow'
    },
    {
      label:'×',
      name:'GREATER-THAN SIGN',
      shortName:'U+3E',
      type:'Arrow'
    },
    {
      label:'ƒ',
      name:'LESS-THAN OR EQUAL TO',
      shortName:'U+2264',
      type:'Arrow'
    },
    {
      label:'∑',
      name:'GREATER-THAN OR EQUAL TO',
      shortName:'U+2265',
      type:'Arrow'
    },
    {
      label:'∞',
      name:'LESS-THAN SIGN',
      shortName:'U+3C',
      type:'Arrow'
    },
    {
      label:'√',
      name:'GREATER-THAN SIGN',
      shortName:'U+3E',
      type:'Arrow'
    },
    {
      label:'∼',
      name:'LESS-THAN OR EQUAL TO',
      shortName:'U+2264',
      type:'Arrow'
    },
    {
      label:'≅',
      name:'GREATER-THAN OR EQUAL TO',
      shortName:'U+2265',
      type:'Arrow'
    },
    {
      label:'≈',
      name:'LESS-THAN SIGN',
      shortName:'U+3C',
      type:'Arrow'
    },
    {
      label:'≠',
      name:'GREATER-THAN SIGN',
      shortName:'U+3E',
      type:'Arrow'
    },
    {
      label:'≡',
      name:'LESS-THAN OR EQUAL TO',
      shortName:'U+2264',
      type:'Arrow'
    },
    {
      label:'∈',
      name:'GREATER-THAN OR EQUAL TO',
      shortName:'U+2265',
      type:'Arrow'
    },
    {
      label:'∉',
      name:'LESS-THAN SIGN',
      shortName:'U+3C',
      type:'Arrow'
    },
    {
      label:'∋',
      name:'GREATER-THAN SIGN',
      shortName:'U+3E',
      type:'Arrow'
    },
    {
      label:'∏',
      name:'LESS-THAN OR EQUAL TO',
      shortName:'U+2264',
      type:'Arrow'
    },
    {
      label:'∧',
      name:'GREATER-THAN OR EQUAL TO',
      shortName:'U+2265',
      type:'Arrow'
    },
    {
      label:'∨',
      name:'GREATER-THAN OR EQUAL TO',
      shortName:'U+2265',
      type:'Arrow'
    },
    {
      label:'¬',
      name:'GREATER-THAN OR EQUAL TO',
      shortName:'U+2265',
      type:'Arrow'
    },
    {
      label:'∩',
      name:'GREATER-THAN OR EQUAL TO',
      shortName:'U+2265',
      type:'Arrow'
    },
    {
      label:'∂',
      name:'GREATER-THAN OR EQUAL TO',
      shortName:'U+2265',
      type:'Arrow'
    },
    {
      label:'∀',
      name:'GREATER-THAN OR EQUAL TO',
      shortName:'U+2265',
      type:'Arrow'
    },
    {
      label:'∃',
      name:'GREATER-THAN OR EQUAL TO',
      shortName:'U+2265',
      type:'Arrow'
    },
    {
      label:'∅',
      name:'GREATER-THAN OR EQUAL TO',
      shortName:'U+2265',
      type:'Arrow'
    },
    {
      label:'∇',
      name:'GREATER-THAN OR EQUAL TO',
      shortName:'U+2265',
      type:'Arrow'
    },
    {
      label:'∗',
      name:'GREATER-THAN OR EQUAL TO',
      shortName:'U+2265',
      type:'Arrow'
    },
    {
      label:'∝',
      name:'GREATER-THAN OR EQUAL TO',
      shortName:'U+2265',
      type:'Arrow'
    },
    {
      label:'∠',
      name:'GREATER-THAN OR EQUAL TO',
      shortName:'U+2265',
      type:'Arrow'
    },
    {
      label:'¼',
      name:'GREATER-THAN OR EQUAL TO',
      shortName:'U+2265',
      type:'Arrow'
    },
    {
      label:'½',
      name:'GREATER-THAN OR EQUAL TO',
      shortName:'U+2265',
      type:'Arrow'
    },
    {
      label:'¾',
      name:'GREATER-THAN OR EQUAL TO',
      shortName:'U+2265',
      type:'Arrow'
    },
 
    //
    {
      label:'Ā',
      name:'LATIN CAPITAL LETTER A WITH MACRON',
      shortName:'U+100',
      type:'Arrow'
    },
    {
      label:'ā',
      name:'LATIN SMALL LETTER A WITH MACRON',
      shortName:'U+101',
      type:'Arrow'
    },
    {
      label:'Ă',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+102',
      type:'Arrow'
    },
    {
      label:'ă',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'Ą',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'ą',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'Ć',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'ć',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'Ĉ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'ĉ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'Ċ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'ċ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'Č',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'č',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'Ď',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'ď',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'Đ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'đ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'Ē',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'ē',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'Ĕ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'ĕ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'Ė',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'ė',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'Ę',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'ę',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'Ě',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'ě',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'Ĝ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'ĝ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'Ğ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'ğ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'Ġ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'ġ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'Ģ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'ģ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'Ĥ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'ĥ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'Ħ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'ħ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'Ĩ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'ĩ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'Ī',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'ī',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'Ĭ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'ĭ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'Į',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'į',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'İ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'ı',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'Ĳ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'ĳ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    }, {
      label:'Ĵ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'Ķ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
   
    {
      label:'ķ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'Ĺ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'ĺ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'Ļ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'ļ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'Ľ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'ľ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'Ŀ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'ŀ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'Ł',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'ł',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'Ń',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'ń',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'Ņ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'ņ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'Ň',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'ň',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'ŉ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'Ŋ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'ŋ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
 
    {
      label:'Ō',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'ō',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'Ŏ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'ŏ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'Ő',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'ő',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'Œ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'œ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'Ŕ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'ŕ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'Ŗ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
 
    {
      label:'ŗ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    }, {
      label:'Ř',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'ř',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'Ś',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'ś',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'Ŝ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'ŝ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
 
    {
      label:'Ş',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'ş',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
 
    {
      label:'Š',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
 
    {
      label:'š',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
 
 
      {
      label:'Ţ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
 
    {
      label:'ţ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
 
    {
      label:'Ť',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'ť',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'Ŧ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'ŧ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'Ũ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'ũ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'Ū',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'ū',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'Ŭ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'ŭ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'Ů',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'ů',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'Ű',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'ű',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'Ų',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'ų',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'Ŵ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'ŵ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'Ŷ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'ŷ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'Ÿ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'Ź',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'ź',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'Ż',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'ż',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'Ž',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'ž',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
    {
      label:'ſ',
      name:'LATIN CAPITAL LETTER A WITH BREVE',
      shortName:'U+103',
      type:'Arrow'
    },
 
 
];
 
export const Format_Style=[
  {
    label:'clear',
    style:'clearStyle'
  }
]
 
export const Table_List = [
  {rows:0 ,  columns: 0 },
  { rows: 1, columns: 1 },
  { rows: 1, columns: 2 },
  { rows: 1, columns: 3 },
  { rows: 1, columns: 4 },
  { rows: 1, columns: 5 },
  { rows: 2, columns: 1 },
  { rows: 2, columns: 2 },
  { rows: 2, columns: 3 },
  { rows: 2, columns: 4 },
  { rows: 2, columns: 5 },
  { rows: 3, columns: 1 },
  { rows: 3, columns: 2 },
  { rows: 3, columns: 3 },
  { rows: 3, columns: 4 },
  { rows: 3, columns: 5 },
  { rows: 4, columns: 1 },
  { rows: 4, columns: 2 },
  { rows: 4, columns: 3 },
  { rows: 4, columns: 4 },
  { rows: 4, columns: 5 },
  { rows: 5, columns: 1 },
  { rows: 5, columns: 2 },
  { rows: 5, columns: 3 },
  { rows: 5, columns: 4 },
  { rows: 5, columns: 5 }
]
 
export const Add_List = [
  {
    label: "Monospace",
    style: "CODE",
    font: <i className="fa fa-code" aria-hidden="true"></i>
  },
  {
    label: "Code block",
    style: "CODEBLOCK",
    font: <IntegrationInstructionsIcon/>
  },
  {
    label: "Horizontal Line",
    style: "HORIZONTALLINE",
    font: <HorizontalRuleIcon/>
  } ,

  {
    label: "Block Quote",
    style: "BLOCKQUOTE",
    font: <FormatQuoteIcon/>
  }
  // {
  //   label: "To-do List",
  //   style: "TODOLIST",
  //   font: <PlaylistAddIcon/>
  // }
]
 
 
export const errorList = [{
  label: "passive",
  content: "Passive voice: Use active voice"
},
{
  label: "error",
  content: "Correctness: Spelling"
}];
 
export const grammerColor= (GrammarChecker) => {
  console.log('GrammarChecker', GrammarChecker)
 return [
    {
      secition: "Correctness",
      color: "#ff0000",
      list: ["GRAMMAR", "CASING", "CONFUSED_WORDS", "SEMANTIC", "TYPOS", "passive", "MISC", "effectivevocabulary", "NONSTANDARD_PHRASES", "REDUNDANCY"],
      status: true,
      eligible: true
    },
    {
      secition: "Check",
      color: "#3282b8",
      list: ["error"],
      status: false,
      eligible: GrammarChecker
    },
    {
      secition: "Sentence Structure",
      color: "cyan",
      list: ["weaken", "hard", "complex", "COLLOCATIONS", 'whitespace'],
      status: false,
      eligible: GrammarChecker
    },
    {
      secition: "Improper Formating",
      color: "green",
      list: ["space", "TYPOGRAPHY", "PUNCTUATION"],
      status: false,
      eligible: GrammarChecker
    },
    {
      secition: "Inappropriate Colloquialisms",
      color: "orange",
      list: ["STYLE"],
      status: false,
      eligible: GrammarChecker
    },
    {
      secition: "Overused Words",
      color: "purple",
      list: ["REPETITION", "overuse"],
      status: false,
      eligible: GrammarChecker
    },
    {
      secition: "Qualifiers",
      color: "blue",
      list: ["qualifier"],
      status: false,
      eligible: GrammarChecker
    }
  ];
}
 
export const headingList = {
  CASING: {
    head: "CASING",
    body: "Capitalization of first word"
  },
  TYPOS: {
    head: "TYPOS",
    body: "TYPOS body"
  },
  error: {
    head: "error",
    body: "Mispelled words"
  },
  weaken: {
    head: "weaken",
    body: "Words which can be eliminated without changing the interpretation."
  },
  passive: {
    head: "passive",
    body: "Action and object is been emphasized more rather than the subject."
  },
  overuse: {
    head: "overuse",
    body: "Words are being repeated making their meaning obfuscated. "
  },
  space: {
    head: "space",
    body: "wide blank spaces"
  },
 
  qualifier: {
    head: "qualifier",
    body: "word that limits or enhances another words meaning, while affecting the certainty and specificity of a statement."
  },
  effectivevocabulary: {
    head: "effectivevocabulary",
    body: "words which are more efficacious making the statements more constructive"
  },
  hard: {
    head: "hard",
    body: ""
  },
  complex: {
    head: "complex",
    body: "Sentence consisting of an independent clause plus a dependent clause."
  },
  COLLOCATIONS: {
    head: "COLLOCATIONS",
    body: "Collocation is a series of words or terms that co-occur more often than would be expected by chance."
  },
  CONFUSED_WORDS: {
    head: "CONFUSED_WORDS",
    body: "Words which sound the same or similar yet have different meaning."
  },
  GRAMMAR: {
    head: "GRAMMAR",
    body: " Set of structural rules governing the composition of clauses, phrases and words in a natural language."
  },
  MISC: {
    head: "MISC",
    body: "Odd bunch of words — words you might not expect to go together"
  },
 
  NONSTANDARD_PHRASES: {
    head: "NONSTANDARD_PHRASES",
    body: "Commonly used but are not necessarily correct"
  },
  PUNCTUATION: {
    head: "PUNCTUATION",
    body: "The marks, such as full stop, comma, and brackets, used in writing to separate sentences and their elements and to clarify meaning"
  },
  REDUNDANCY: {
    head: "  REDUDANCY",
    body: "Information is been expressed more than once"
  },
 
  REPETITION: {
    head: "REPETITION",
    body: "Often repeated words."
  },
  SEMANTIC: {
    head: "SEMANTIC",
    body: ""
  },
  STYLE: {
    head: "STYLE",
    body: ""
  },
  TYPOGRAPHY: {
    head: "TYPOGRAPHY",
    body: "Improper formatting of the setence."
  },
  whitespace: {
    head: "BLANK SPACE",
    body: "Improper spacing between the Words."
  }
};
 
 
// the value return only selected by true
export const filterList = (grammerList, suggesions) => {
  let totList = [];
  grammerList.filter(obj => {
    if (obj.status) totList = totList.concat(obj.list);
  });
  let filterSuggesion = {};
  for (const key in suggesions) {
    let suggestList = suggesions[key];
    let filt = suggestList.filter(obj => {
      if (totList.indexOf(obj.pgm) >= 0) {
        obj.anchorKey = key; // anchorKey = 2j6hs
        obj.span_id = `${key}_${obj.offset}_${obj.offset + obj.length}` // id = "2j6hs_14_18"
        return obj;
      }
    });
    filterSuggesion[key] = filt;
  }
  return filterSuggesion;
};
 
export const strCount = (str) => {
  const wordArray = str.match(/\S+/g); // matches words according to whitespace
  const wordsCount = wordArray ? wordArray.length : 0;
  const characterCount = str.length;
 
  return [wordsCount, characterCount]
}