import React, { useState, useEffect } from "react";
import Tour from 'reactour'
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';


export default function ReactTour({ steps, isOpen, closeTour }) {

    const [currentstep, setCurrentstep] = useState(0);
    return (
        <>
            <Tour
                steps={steps}
                isOpen={isOpen}
                onRequestClose={closeTour}
                startAt={0}
                closeTour
                getCurrentStep={curr => setCurrentstep(curr + 1)}
                prevButton={<ArrowBackOutlinedIcon style={{ color: `${currentstep == 0 ? "grey" : "dodgerblue"}` }} />}
                nextButton={<ArrowForwardOutlinedIcon style={{ color: `${currentstep == steps.length ? "grey" : "dodgerblue"}` }} />}
            />
        </>
    )
}
