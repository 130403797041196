

export function getTextFromEditorState(onlyDocumentContent, sliceIt) {
    console.log("onlyDocumentContent_", onlyDocumentContent, sliceIt)
    const processedHtmlContent = onlyDocumentContent.replace(/<br\s*\/?>/gi, '\n').replace(/<div\s*\/?>/gi, '\n');
    // Count words
    const tempElement = document.createElement('div');
    tempElement.innerHTML = processedHtmlContent;
    const textContent = tempElement.innerText;

    // const storedState = JSON.parse(onlyDocumentContent);
    // const contentState = convertFromRaw(storedState);
    // const text = contentState.getPlainText();
    if (sliceIt) {
        return textContent.slice(0, 120);
    }
    return textContent;
}