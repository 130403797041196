// reducers.js
const initialState = {
    clickState : ""
};

const handleClickReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_BLOG':
            return {
                ...state,
                clickState: "blog"
            };
        case 'SET_DOCUMENT':
            return {
                ...state,
                clickState: "document"
            };
        default:
            return state;
    }
};

export default handleClickReducer;
