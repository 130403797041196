import React, { useEffect, useState } from "react";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import { makeStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import { convertFromRaw } from "draft-js";
import { withRouter } from "react-router-dom";
import lastModified from "../../utils/lastModfied";
import { getTextFromEditorState } from "../../utils/getTextFromEditorState";
import "./DocumentCard.css";
import RestoreFromTrashIcon from "@material-ui/icons/RestoreFromTrash";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import Tooltip from "@material-ui/core/Tooltip";
import {withStyles} from "@material-ui/core/styles";

import Avatar from "@material-ui/core/Avatar";
import dateFn from "date-fn";
import CardHeader from "@material-ui/core/CardHeader";
import "../../css/hover-min.css";
import { getProfile } from "../../services/userService";
//import { withRouter } from "react-router-dom";
// state = {
//   documentName: ""
// };


const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'blue',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const useStyles = makeStyles({
  card: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});

const DocumentCard = ({
  data,
  editDocs,
  shareDocs,
  deleteDocs,
  showDocs,
  isEdit,
  download,
  isAdmin,
  restoreDocs,
  permanentDelete,
  hide,
  handleTeamCollab,
}) => {
  console.log(isAdmin);
  const classes = useStyles();
  console.log(data.documentName);
  let title = data.documentName.slice(0, 14);
  title = data.documentName.length === title.length ? title : `${title}...`;
  let content = getTextFromEditorState(data.onlyDocumentContent,true);
  console.log(content,"hellovvvv");
  const mdate = new Date(data.date);
  const today = new Date();
  let [doctitle, setdoctitle] = useState("");

  const setDownload = async (name, id) => {
    await setdoctitle(name);
    console.log(doctitle);
  };

  const newCont = (data) => {
    let newContent = data + " ";
    let length = 100 - data.length;
    for (let i = 0; i < length / 2; i++) {
      newContent = newContent + " \xa0";
    }
    return newContent;
  };

  return (
    <div key={data._id} className="DocumentCardHover">
      <Card 
        id="DocCard" 
        // className={classes.card} 
        style={{ height: "100%" }}
      >
        <CardHeader
          // avatar={<Avatar aria-label="recipe" src={data.ownerDP}></Avatar>}
          title={
            <h6
              component={"span"}
              gutterBottom
              style={{ fontWeight: "900", overflowWrap: "break-word", fontSize:'20px', color:'#1265FF' }}
            >
              {title}
            </h6>
          }
          subheader={
            isEdit ? (
              <small style={{ fontSize: "10px" }}>
                <b>{lastModified(today, new Date(mdate))}</b>
              </small>
            ) : (
              <small style={{ fontSize: "10px" }}>
                <b>{lastModified(today, mdate)}</b>
              </small>
            )
          }
          action={
            <div className="p-2">
              {!isAdmin &&
              data.activeDoc === true &&
              data.pendingDocuments?.length > 0 ? (
                <LightTooltip title="Collaboration">
                  <i
                    className="fa fa-asterisk blurb-ripple-out point"
                    style={{ fontSize: "11px", color: "#00e673" }}
                    // data-toggle="tooltip"
                    // title="Collaboration"
                    onClick={() => handleTeamCollab(data)}
                    aria-hidden="true"
                  ></i>
                </LightTooltip>
              ) : (
                ""
              )}
            </div>
          }
        ></CardHeader>
        <CardActionArea onClick={() => showDocs(data)}>
          <CardContent style={{ height: 80 }}>
            {/* <h6
              component={"span"}
              gutterBottom
              style={{ fontWeight: "900", overflowWrap: "break-word" }}
            >
              {title}
            </h6> */}
            {/* overflow-wrap  wordWrap   \xa0  newCont*/}
            <small
              variant="body2"
              color="textSecondary"
              component="p"
              style={{ overflowWrap: "break-word", fontSize: "13px" }}
            >
              {newCont(content)}
            </small>
            {/* <p
              variant="body2"
              color="textSecondary"
              component="p"
              style={{ overflowWrap: "break-word" }}
            >
              {newCont(content)}
            </p> */}
            {/* </Link> */}
            <div
              className="MuiCardActions-root "
              style={{ display: "none" }}
            ></div>
          </CardContent>
        </CardActionArea>

        <CardActions>
          {/* <small>last modified: {mdate}</small> */}
          {/* {isEdit ? (
            <small style={{ fontSize: "10px" }}>
              {lastModified(today, new Date(mdate))}
            </small>
          ) : (
            <small style={{ fontSize: "10px" }}>
              {lastModified(today, mdate)}
            </small>
          )} */}

          <i
            style={{
              color: "orange",
            }}
            className="fa fa-star ml-3"
            aria-hidden="true"
          ></i>
          <p className="m-1">{data.ratings}</p>
          {/* <small>last modified:{dateFn.date(mdate, 110)}</small> */}
          {isEdit ? (
            <div className="ml-auto text-primary ft_lg">
              <div>
                {!isAdmin && (
                  <React.Fragment>
                    {!data.readOnly && (
                      <LightTooltip title="Edit">
                        <i
                          className="fa fa-pencil-square-o mr-3 point"
                          onClick={editDocs}
                          style={{ color: 'blue' }}
                          // id="popmodal"
                          // data-toggle="tooltip"
                          // title="Edit"
                          aria-hidden="true"
                        ></i>
                      </LightTooltip>
                    )}

                    <LightTooltip title="Share">
                      <i
                        className="fa fa-share-square-o mr-3 point"
                        onClick={shareDocs}
                        style={{ color: '#00e6e6' }}
                        // data-toggle="tooltip"
                        // title="Share"
                        aria-hidden="true"
                      ></i>
                    </LightTooltip>

                    <LightTooltip title="Download">
                      <i
                        className="fa fa-download mr-3 point"
                        onClick={download}
                        style={{ color: 'green' }}
                        aria-hidden="true"
                        data-toggle="modal"
                        data-target="#myModal"
                      ></i>
                    </LightTooltip>
                  </React.Fragment>
                )}

                <LightTooltip title="Delete">
                  <i
                    className="fa fa-trash mr-3 point"
                    style={{ color: 'red' }}
                    // data-toggle="tooltip"
                    // title="Delete!"
                    onClick={() => deleteDocs(data)}
                    aria-hidden="true"
                  ></i>
                </LightTooltip>
              </div>
            </div>
          ) : hide ? (
            ""
          ) : (
            <div className="ml-auto text-primary ft_lg">
              <LightTooltip title="Restore">
                <RestoreFromTrashIcon
                  className="point mr-3"
                  style={{ fontSize: "25px", color:'#00e673' }}
                  // data-toggle="tooltip"
                  // title="Restore"
                  onClick={() => restoreDocs(data)}
                />
              </LightTooltip>

              <LightTooltip title="Permanent Delete">
                <DeleteIcon
                  className="point mr-3"
                  // data-toggle="tooltip"
                  // title="Permanent Delete"
                  style={{ fontSize: "25px", color:'red' }}
                  onClick={() => permanentDelete(data)}
                />
              </LightTooltip>
            </div>
          )}
        </CardActions>
      </Card>
    </div>
  );
};

export default withRouter(DocumentCard);
