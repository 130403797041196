import React, { Component, useState } from "react";
import BreadCrumb from "../Cards/BreadCrumbs";
import HeadingB from "../Cards/HeadingB";
import Swal from "sweetalert2";
import Statistics from "./Statistics";
import { PlagiarismDocs } from "./PlagiarismDocs";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Button, Card } from "react-bootstrap";
// import Button from "@material-ui/core/Button";
import TextareaAutosize from "react-textarea-autosize";
import style from "./plagiarism.module.css";
import { useContext } from "react";
import { PlagiarismContext } from "../../contexts/PlagiarismContext";
import { useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import { checkPlagiarism } from "../../services/plagiarismServices";
import { toast } from "react-toastify";
import Loader from "../../utils/loader";
import { PetAssistanceBtn } from "../Paraphrase/ParaphraseComponent";
import PlagButton from "./PlagButton";
import adminServices from "../../services/adminServices";
import ReactTour from "../ReactTour";
import $ from "jquery";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  card: {
    height: 720,
    overflowY: "auto",
  },
}));

export function Plagiarism(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { plagCheckContent, addPlagResponse, addPlagCheckContent } = useContext(
    PlagiarismContext
  );
  const [value, setValue] = React.useState(0);
  const [searchVal, setsearchVal] = React.useState("");
  const [editorText, setEditorText] = useState("");
  const [plagarizedContent, setPlagarizedContent] = useState("");
  const [plagarizedResponse, setPlagarizedResponse] = useState("");
  const [statdetails, setstatDetails] = useState("");
  const [disable, setDisable] = useState(false);
  const [characters, setCharacters] = useState({ max: 1500, now: 0 });
  const [buttonDisable, setButtonDisable] = useState(false);
  const [reactTour, setReactTour] = useState(false);
  const [plagStats, setPlagStats] = useState(false);
  const [dontShow, setDontShowForPlagiarism] = useState(sessionStorage.getItem('dontShowPlag') || false);

  const [sentanceNav, setSentanceNav] = useState({
    total: 0,
    current: 0,
    selected: "",
    index: -1,
  });
  const [column, setColumn] = useState({
    column: "col-md-8",
    statistics: false,
  });
  useEffect(() => {
    getCharacterLimit();
  }, []);

  useEffect(() => {
    if (plagCheckContent) {
      let trimmedPlagCheckContent = plagCheckContent.replace(
        /^\s*[\r\n]/gm,
        ""
      ); // Removing the Empty Lines
      setEditorText(trimmedPlagCheckContent);
      sessionStorage.setItem("plagiarismText", trimmedPlagCheckContent);
      characterLimit(characters.max, trimmedPlagCheckContent.length);
      setCharacters({ ...characters, now: trimmedPlagCheckContent.length });
      if (value > 0) {
        setValue(0);
      } else {
        setValue(1);
      }
      setDisable(false);
    }
  }, [plagCheckContent]);
  useEffect(() => {
    console.log(characters.max);
    console.log(characters.now);
  }, [characters]);

  useEffect(() => {
    let persistedPlag = sessionStorage.getItem("plagiarismText");
    let persistedStatistics = sessionStorage.getItem("plagiarismStatistics");
    let persistedHtmlContent = sessionStorage.getItem("htmlContent");
    let persistedResponse = sessionStorage.getItem("plagiarizedResponse");
    if (persistedPlag) {
      let length = persistedPlag.replace(/^\s*[\r\n]/gm, "").length;
      console.log("onUseEffect===>", characters.now);
      setCharacters({ ...characters, now: length });
      characterLimit(characters.max, persistedPlag.length);
      setEditorText(persistedPlag.replace(/^\s*[\r\n]/gm, ""));
    }
    if (persistedStatistics) {
      setstatDetails(JSON.parse(persistedStatistics));
      setPlagStats(true)
    }
    if (persistedHtmlContent) {
      let htmlContent = ReactHtmlParser(persistedHtmlContent);
      setPlagarizedContent(htmlContent);
    }
    if (persistedResponse) {
      setPlagarizedResponse(JSON.parse(persistedResponse));
    }
  }, []);
  const steps = [
    {
      selector: ".reactour-1",
      content: "Write your content here",
      position: "bottom",
    },
    {
      selector: ".reactour-2",
      content:
        "On left side it shows the maximum character limit and on right is the delete button to clear the editor ",
      position: "bottom",
    },
    {
      selector: ".reactour-3",
      content:
        "Click on this tab to check plagiarism for your existing document",
      position: "bottom",
    },
  ];
  const steps1 = [
    {
      selector: ".reactour-1",
      content: "Write your content here",
      position: "bottom",
    },
    {
      selector: ".reactour-2",
      content:
        "On left side it shows the maximum character limit and on right is the delete button to clear the editor ",
      position: "bottom",
    },
    {
      selector: ".reactour-3",
      content: "Click to check plagiarized content",
      position: "bottom",
    },
    {
      selector: ".reactour-4",
      content:
        "Click on this tab to check plagiarism for your existing document",
      position: "bottom",
    },
  ];

  const getCharacterLimit = async () => {
    let {
      status,
      plagiarism_limit,
      message,
    } = await adminServices.getPlagiarismLimit();
    if (status !== 200) {
      console.log(plagiarism_limit);
      setCharacters({ ...characters, max: 2500 });
    } else {
      let persistedPlag = sessionStorage.getItem("plagiarismText");
      if (persistedPlag) {
        setCharacters({ now: persistedPlag.length, max: plagiarism_limit });
      } else {
        setCharacters({ ...characters, max: plagiarism_limit });
      }
    }
  };






  const scrollToDiv = async () => {

    $('html, body').animate({
      scrollTop: $("#content").offset().top
    }, 2000);


  };

  const getStyledContent = async () => {
    setDisable(true);
    let data = editorText || plagCheckContent;
    if (!data) {
      toast.info("Please Select Some Document or Type Some Content");
      return;
    }
    setLoading(true);
    let response;
    data = data.substring(0, characters.max);
    let userId = sessionStorage.getItem("userId")
    let isCoAuthor = false
    if (data) {
      response = await checkPlagiarism(data,userId,isCoAuthor);
      if (response.status !== 200) {
      } else {
        response = response.response;
        response = JSON.parse(response);
        console.log(response);
        sessionStorage.setItem(
          "plagiarismStatistics",
          JSON.stringify(response)
        );
        setstatDetails(response);
        const { sources, details } = response;
        let plagiarizedLine = ``;
        details.map((line, index) => {
          if (line.unique !== "true") {
            plagiarizedLine += `<p><text style="text-decoration: none; border-bottom: 3px solid red;font-size:large">${line.query}</text></p>`;
          } else if (line.paraphrase === "true") {
            plagiarizedLine += `<p><text style="text-decoration: underline yellow solid;font-size:large">${line.query}</text></p>`;
          }
        });
        sessionStorage.setItem("htmlContent", plagiarizedLine);
        let htmlContent = ReactHtmlParser(plagiarizedLine);
        setPlagarizedContent(htmlContent);
        console.log(plagarizedContent);
        sessionStorage.setItem(
          "plagiarizedResponse",
          JSON.stringify(response.sources)
        );
        setPlagarizedResponse(response.sources);
        setPlagStats(true);
        setEditorText(editorText.substring(0, characters.max))
        setCharacters({ ...characters, now: characters.max });
        setLoading(false);
        console.log(plagarizedResponse);
        scrollToDiv();
      }
    }
  };

  const handleChange = (event, newValue) => {
    setLoading(true);
    console.log(newValue);
    addPlagCheckContent("");
    setPlagarizedContent("");
    setPlagarizedResponse("");
    setEditorText("");
    setValue(newValue);
    setDisable(false);
    setLoading(false);
  };

  const callback = (key) => {
    console.log(key);
  };

  const characterLimit = (maxLength, charaLength) => {
    console.log(maxLength, charaLength);
    // if (maxLength >= charaLength) {
    //   setButtonDisable(false);
    // } else {
    //   setButtonDisable(true);
    // }
  };

  const onEditorChange = (event) => {
    setLoading(true);
    const text = event.target.value;
    console.log("onEditorChange", text);
    const length = text.length;
    const max = characters.max;
    characterLimit(max, length);
    console.log(text.substring(0, max));
    setEditorText(text);
    sessionStorage.setItem("plagiarismText", text);
    setDisable(false);
    setCharacters({ ...characters, now: length });
    // if (max >= length) {
    // } else {
    //   console.log("you reached maximum");
    // }
    setLoading(false);
  };

  const onHandleClear = () => {
    console.log("init onHandleClear");
    setEditorText("");
    setCharacters({ ...characters, now: 0 });
    setButtonDisable(false);
    setPlagarizedResponse('');
    sessionStorage.removeItem("plagiarismText");
    sessionStorage.removeItem("plagiarizedResponse");
    sessionStorage.removeItem("htmlContent");
    sessionStorage.removeItem('plagiarismStatistics');
    setSentanceNav({ total: 0, current: 0, selected: "", index: -1 });
  };
  const handleDontShow = (e) => {
    const dontShow = e.target.checked;
    if (dontShow) {
      localStorage.setItem("dontShowPlag", true);
      sessionStorage.setItem("dontShowPlag", true);
    } else {
      localStorage.setItem("dontShowPlag", false);
      sessionStorage.setItem("dontShowPlag", false);
    }
    setDontShowForPlagiarism(dontShow);
  };

  return (
    <div>
      <Loader loading={loading} />

      <BreadCrumb l1="Home" l2="Plagiarism" dashboardlink="dashboard" />

      <div className="row">
        <div className="col-md-2">
          <HeadingB text="Plagiarism" />
        </div>
        {/* <div className="col-md-4">
          <SearchBox placeholder="&#xF002; Search document" />
        </div>
        <div className="col-md-6"></div> */}
        <div className="col-md-9"></div>
        <div className="col-md-1">
          <i
            className="fa fa-question-circle mt-2"
            aria-hidden="true"
            data-toggle="tooltip"
            title="Need Help ?"
            style={{
              cursor: "pointer",
              color: "dodgerblue",
              fontSize: "15px",
            }}
            onClick={() => setReactTour(true)}
          ></i>
        </div>
      </div>
      <br />

      <div className={classes.root}>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            aria-label="simple tabs example"
          >
            <Tab label="Editor" {...a11yProps(0)} />
            {plagarizedContent || editorText ? (
              <Tab
                label="My Documents"
                {...a11yProps(1)}
                className="reactour-4"
              />
            ) : (
                <Tab
                  label="My Documents"
                  {...a11yProps(1)}
                  className="reactour-3"
                />
              )}
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          {buttonDisable === true ? (
            <div className="row mb-3">
              <div className="col-md-9"></div>
              <div className="col-md-3" style={{ color: "red" }}>
                Character limit exceeds!
              </div>
            </div>
          ) : (
              ""
            )}

          <div className="row">
            <div
              className={
                plagarizedResponse && plagStats ? "col-md-8" : "col-md-12"
              }
            >
              {/* {plagarizedContent ? (
                <React.Fragment>{plagarizedContent}</React.Fragment>
              ) : ( */}
              <>
                <TextareaAutosize
                  onChange={onEditorChange}
                  id="plagiarismEditor"
                  value={editorText}
                  className={`${style.editable} reactour-1`}
                  placeholder="Write your content..."
                  spellCheck={false}
                // minRows={20}
                // maxRows={20}
                // style={{
                //   textAlign: "justify",
                //   fontSize: "19px",
                //   width: "100%",
                //   marginBottom: "3%"
                //   // height: "50vh"
                // }}
                />
                <span className="reactour-2">
                  <PlagButton
                    dontShow={dontShow}
                    onHandleClear={onHandleClear}
                    characters={characters}
                    plagStats={() => setPlagStats(!plagStats)}
                  />
                </span>
              </>
              {/* )} */}
            </div>
            {plagarizedResponse && plagStats && (
              <div className="col-md-4">
                <Statistics
                  source={plagarizedResponse}
                  data={statdetails}
                  // clearResult={() => setPlagarizedResponse("")}
                  clearResult={() => setPlagStats(!plagStats)}
                />
              </div>
            )}
          </div>
          <br />

          {plagarizedContent || editorText ? (
            <div className="row">
              <div
                className={
                  plagarizedResponse && plagStats
                    ? "col-md-8 text-center"
                    : "col-md-12 text-center"
                }
              >
                <button
                  className="btn btn-primary reactour-3"
                  id="button"
                  onClick={getStyledContent}
                  disabled={buttonDisable}
                  style={
                    plagarizedResponse && plagStats
                      ? { marginTop: "-30%" }
                      : { marginTop: "-5%" }
                  }
                >
                  CHECK PLAGIARISM
                </button>
              </div>
            </div>
          ) : null}

          <br />

          {plagarizedResponse && plagStats ? (
            <div
              className="container "
              id="content"
              style={{ marginTop: "-2%", display: `${plagarizedResponse.length > 0 ? "block" : "none"}` }}
            >
              <Card
                className="text-center"
                style={{ boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.4)" }}
              >
                <Card.Header>
                  <h3>Plagiarized Content</h3>
                </Card.Header>
                <Card.Body>
                  <Card.Text>{plagarizedContent}</Card.Text>
                </Card.Body>
              </Card>
            </div>
          ) : null}
        </TabPanel>

        <TabPanel value={value} index={1}>
          <PlagiarismDocs />
        </TabPanel>
      </div>
      <ReactTour
        steps={plagarizedContent || editorText ? steps1 : steps}
        isOpen={reactTour}
        closeTour={() => setReactTour(false)}
      />
      <div class="modal fade" id="myModal" role="dialog">
        <div class="modal-dialog modal-dialog-centered ">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body">
              <h4 style={{ textAlign: "center" }}>Can we clear you editor</h4>
            </div>
            <div class="modal-footer">
              <input type="checkbox" id="defaultUnchecked" checked={dontShow} onChange={(e) => handleDontShow(e)} />
              <label style={{ marginRight: "45%", marginTop: "1%" }} for="defaultUnchecked"> Don't ask me again</label><br />
              <button type="button" class="btn btn-success" value="true" data-dismiss="modal" onClick={(e) => onHandleClear(e)}>Continue</button>
              {/* <button type="button" class="btn btn-danger" value="false" data-dismiss="modal" onClick={(e)=>onHandleDelete(e)}>No</button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
