// reducers.js
const initialState = {
    leftNavbarOpen: true,
    rightModalOpen: false,
    lowerModelOpen: false
};

const handleModelAndSideBavBarReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CLOSE_RIGHT_MODAL_AND_OPEN_LEFT_NAVBAR':
            return {
                ...state,
                rightModalOpen: false,
                leftNavbarOpen: true,
                lowerModelOpen: false
            };
        case 'OPEN_RIGHT_MODAL_AND_CLOSE_LEFT_NAVBAR':
            return {
                ...state,
                rightModalOpen: true,
                leftNavbarOpen: false,
                lowerModelOpen: false
            };
        case 'CLOSE_LOWER_MODAL_AND_OPEN_LEFT_NAVBAR':
            return {
                ...state,
                rightModalOpen: false,
                leftNavbarOpen: true,
                lowerModelOpen: false
            };
        case 'OPEN_LOWER_MODAL_AND_CLOSE_LEFT_NAVBAR':
            return {
                ...state,
                rightModalOpen: false,
                leftNavbarOpen: false,
                lowerModelOpen: true
            };
        case 'OPEN_LEFT_NAVBAR':
            return {
                ...state,
                leftNavbarOpen: true
            };
        case 'CLOSE_LEFT_NAVBAR':
            return {
                ...state,
                leftNavbarOpen: false
            };
        case 'OPEN_RIGHT_MODAL':
            return {
                ...state,
                rightModalOpen: true
            };
        case 'CLOSE_RIGHT_MODAL':
            return {
                ...state,
                rightModalOpen: false
            };
        case 'CLOSE_LOWER_MODAL':
            return {
                ...state,
                lowerModelOpen: false
            };
        default:
            return state;
    }
};

export default handleModelAndSideBavBarReducer;
