import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography } from '@mui/material';

function SuggestionCard({ sentenceArray, sourceCount, mobileView }) {
    return (
        <div style={{width:mobileView < 768 ? "90vw" : ""}}>
            {sentenceArray.length > 0 && <Card className="sentencewiseCard">
                <CardContent>
                    <div>
                        <p style={{ fontSize: mobileView ? '13' : "16px", fontWeight: "600", color: "#353535", textAlign: "center" }}>
                            Sentence Wise
                        </p>
                        <hr />
                        <Card>
                            <div className="pt-1 px-2" style={{fontSize: mobileView ? '12px' : "14px", fontWeight: "400", textAlign: "left", minHeight: mobileView ? '25px' : "30px", background: "#CFE7FF" }}>
                                {sentenceArray.length > 1 ? sentenceArray.length+` matches `:sentenceArray.length+` match `}from {sourceCount > 1 ? sourceCount+` sources`:sourceCount+` source`} 
                            </div>
                        </Card>
                        {/* <Card>
                            {sentenceArray[0]}
                        </Card> */}
                        {sentenceArray.map((sentence) => (
                        //     console.log("sentence_", sentence.sentenceDetails);
                        // let wordCount = sentence.sentenceDetails.split(/[ -]+/)
                        // console.log("wordCount_", wordCount.length)
                        <Card className="mt-2" >
                            <CardContent style={{fontSize: mobileView ? '13px' : "16px", fontWeight: "600", color: "#353535", }}>
                                {sentence.sentenceDetails.split(/[ -]+/).length} Similar words
                            </CardContent>
                            <CardContent style={{fontSize: mobileView ? '11px' : "14px", fontWeight: "600",color: "#777777"}}>
                               "{sentence.sentenceDetails}"
                            </CardContent>
                            <CardContent className="pt-0">
                                <a href={sentence.url}  target="_blank" style={{fontSize: mobileView ? '11px' :  "14px", fontWeight: "600",color: "#1976D2"}}>{sentence.url}</a>
                            </CardContent>
                        </Card>
                        ))}
                    </div>
                </CardContent>
            </Card>}
        </div>
    )
}
export default SuggestionCard;
