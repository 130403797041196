import React from "react";

import ProgressBar from "@ramonak/react-progress-bar";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

const ProgressBarComp = ({ mobileView,progressLabel, checkCircleColor, completed, bgColor }) => {
    return (


        // <ProgressBar
        //     // className="pBCompWrapper"
        //     barContainerClassName="pBCompContainer"
        //     completed={60}
        //     maxCompleted={100}
        //     height="7px"
        //     borderRadius="50px"
        //     isLabelVisible={false}
        // />;

        <>
            <div className="d-flex justify-content-between">
                <span style={{ color: "#606060", fontSize: mobileView ? '13px' : '' }}>{progressLabel}</span>
                {/* </><CheckCircleRoundedIcon style={{ color: checkCircleColor, float: "right" }}  /> */}
                <span style={{color:bgColor, fontSize: mobileView ? '13px' : ''}}>{completed}%</span>
            </div>
            <ProgressBar
                completed={completed}
                maxCompleted={100}
                bgColor={bgColor}
                baseBgColor="#E1E1E0"
                height="5px"
                borderRadius="50px"
                isLabelVisible={false}
            // margin="10px auto"
            // width="100%"
            // labelAlignment="left"
            // labelColor="#e80909"
            // customLabel=""
            />
        </>
    )
};

export default ProgressBarComp