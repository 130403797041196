import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { CardActionArea } from "@material-ui/core";
import { Badge, Box } from "@mui/material";
import { styled } from '@mui/material/styles';

import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'blue',
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}))(Tooltip);
// import "./teamcard.css";


const styles = muiBaseTheme => ({
  card: {
    maxWidth: 300,
    margin: "auto",
    transition: "0.3s",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)"
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  media: {
    paddingTop: "50.25%"
  },
  content: {
    textAlign: "center",
    padding: muiBaseTheme.spacing.unit * 2,
    // eslint-disable-next-line no-dupe-keys
    textAlign: 'center',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'column',
  },
  divider: {
    margin: `${muiBaseTheme.spacing.unit * 2}px 0`
  },
  heading: {
    fontWeight: "bold"
  },
  subheading: {
    lineHeight: 1.1,
  },
  avatar: {
    backgroundColor: "#80d4ff",
    color: "black",
    display: "inline-block",
    border: "2px solid white",
    "&:not(:first-of-type)": {
      marginLeft: -muiBaseTheme.spacing.unit
    }
  }
});


const colors = [
  "#bff603", "yellow", "#80d4ff", "orange",
  "#ff8b94", "#d3ffce", "#9bb7d4", "#ffdb58",
  "#f1a7fe", "#ff6961", "#ffb347", "#add8e6",
  "#a0bfe6", "#89cff0", "#4f86f7", "#67e6ec",
  "#7fffd4", "#77dd77", "#b19cd9", "#ff9999"
];


function App({ classes, teamdata: detail, grpData, deleteMember, openChat }) {

  const letterToColorIndex = (letter) => {
    // Convert letter to lowercase to ensure consistency
    const lowercaseLetter = letter.toLowerCase();
    // Get ASCII code of the lowercase letter
    const charCode = lowercaseLetter.charCodeAt(0) - 97; // ASCII code of 'a' is 97
    // Wrap the charCode to ensure it stays within the bounds of the colors array
    return charCode % colors.length;
  };

  const colorIndex = letterToColorIndex(detail.name.charAt(0));

  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: detail.status === 'Online' ? '#44b700' : '#d3d6db',
      color: detail.status === 'Online' ? '#44b700' : '#d3d6db',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        // position: 'absolute',
        // top: 0,
        // left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: detail.status === 'Online' ? 'ripple 1.2s infinite ease-in-out' : '',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));


  return (
    <div className="col-md-3 res-Team-card teamcard DocumentCardHover" >


      <Card className={classes.card} style={{ height: 200, marginBottom: "15px", border: "1px solid blue", borderRadius: "10px" }} id="tapp">
        <CardContent className={classes.content} style={{ height: 150 }}>
          <StyledBadge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            variant="dot"
            className="teamDPs"
          // style={{ border: "1px solid red" }}
          >
            <Avatar
              style={{
                backgroundColor: `${colors[colorIndex]}`,
                color: "black",
                width: 54, height: 54,
                border: "4px solid white",
                boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.2)"
              }}
            >
              <h5
                style={{ marginTop: "4px" }}
                data-toggle="tooltip"
                title={detail.name}
              >
                {detail.name.charAt(0).toUpperCase()}
              </h5>
            </Avatar>
          </StyledBadge>
          <Typography
            className={"MuiTypography--heading"}
            variant={"h6"}
            gutterBottom
            id="teamMemName"
          >
            {detail.name}
          </Typography>
          <Typography
            className={"MuiTypography--subheading"}
            variant={"caption"}
            id='teamPhone'
          >
            {detail.mobile || "xxxxxxxxxxx"}
          </Typography>
          {/* <br /> */}
          <Typography
            className={"MuiTypography--subheading"}
            id='teamMail'
            variant={"caption"}
          >
            {detail.email}
          </Typography>
          <br />
          {/* <Divider className={classes.divider} light /> */}
          {/* <i
            className="fa fa-trash  "
            style={{ color: "#007bff", textAlign: "center"}}
            aria-hidden="true"
            data-toggle="tooltip"
            data-placement="right"
            title="Remove Member"
            onClick={deleteMember}
            data-dismiss="modal"
          ></i> */}
        </CardContent>
        <CardActionArea style={{ borderTop: "1px solid #D3D3D3" }}>
          {/* <Box p={1} display='flex' flexDirection='column' gap={1} justifyContent='space-between'>
          {grpData.length > 0
          ? grpData.slice(0, 5).map((group, i) =>
          group.members.map(member => (
          <>
          {member === detail.id && (
          <Avatar
          className={classes.avatar}
          style={{
          backgroundColor: `${
          color[grpData.indexOf(group) % 4]
          }`,
          marginTop: "4%"
          }}
          >
          <h5
          style={{ marginTop: "9px" }}
          data-toggle="tooltip"
          title={group.name}
          >
          {group.name.charAt(0).toUpperCase()}
          </h5>
          </Avatar>
          )}
          </>
          ))
          )
          : 
          <div></div>
          } */}

          <Box display='flex' flexDirection='row'  alignContent='center' alignItems='center'>
            <div style={{ borderRight: "1px solid #D3D3D3 ", width: "50%" }}>
              <IconButton aria-label="ChatBox" onClick={openChat}>
                <LightTooltip title="ChatBox">
                  <Badge badgeContent={detail.unreadCount} color="secondary">
                    <ChatBubbleOutlineOutlinedIcon
                      className="fa fa-commenting"
                      style={{ color: "dodgerblue", fontSize: "19px" }}
                      aria-hidden="true"
                      data-placement="right"
                      data-dismiss="modal"
                    ></ChatBubbleOutlineOutlinedIcon>
                  </Badge>
                </LightTooltip>
              </IconButton>
            </div>
            <div style={{  width: "50%" }}>
              <IconButton aria-label="settings">
                <LightTooltip title="Remove Member">
                  <svg
                    style={{ color: "red", fontSize: "19px" }}
                    aria-hidden="true"
                    // data-toggle="tooltip"
                    data-placement="right"
                    // title="Remove Member"
                    onClick={deleteMember}
                    data-dismiss="modal"
                    xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                    <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                  </svg>
                </LightTooltip>
              </IconButton>
            </div>
          </Box>

          {/* </Box> */}
        </CardActionArea>
      </Card>
    </div>
  );
}

export default withStyles(styles)(App);
// {/* {member.length > 0 ? (
//                         <Avatar style={{ backgroundColor: "#007bff" }}>
//                           <AddCircleOutlineIcon />
//                         </Avatar>
//                       ) : (
//                         ""
//                       )} */}
// <Avatar style={{ backgroundColor: "#007bff" }}>
//   <AddCircleOutlineIcon />
// </Avatar>
