import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import GrammarReport from "./GrammarReport";
import { useSelector } from "react-redux";

import Tooltip from "@material-ui/core/Tooltip";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'blue',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid #e8e8e8"
  },
  indicator: {
    backgroundColor: "#1890ff"
  }
})(Tabs);

const AntTab = withStyles(theme => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1
    },
    "&$selected": {
      color: "#1890ff",
      border: "none",
      fontWeight: theme.typography.fontWeightMedium
    },
    "&:focus": {
      color: "#40a9ff"
    }
  },
  selected: {}
}))(props => <Tab disableRipple {...props} />);

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  padding: {
    padding: theme.spacing(3)
  },
  demo1: {
    backgroundColor: theme.palette.background.paper
  },
  demo2: {
    backgroundColor: "#2e1534"
  }
}));

export default function CustomizedTabs({ handleChangeTab, openEditor, search, onChangeSearch, searchBlog }) {
  // const {  switchTab } = this.props
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const userDetails = useSelector((user) => user)
  console.log("userDetails", userDetails)
  const isSubAdmin = userDetails.user.currentUser.isSubAdmin

  const handleChange = async (event, newValue) => {
    event.preventDefault();
    console.log(newValue);
    setValue(newValue);
    handleChangeTab(newValue);
  };

  return (
    <div className={classes.root}>
      <div className={`${classes.demo1} row`}
        style={{
          // display: "flex", 
          // justifyContent: "space-between", 
          // alignItems: "center",
          width: "99%", 
          padding: "7px",
          borderRadius: "8px",
          boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
          marginLeft: "3px"
        }}>
        <div class="col-sm-12 col-md-6 mb-3">
          <AntTabs
            value={value}
            onChange={handleChange}
            aria-label="ant example"
            variant="scrollable"
          >
            <AntTab label="Manage Blogs"></AntTab>
            <AntTab label={isSubAdmin ? "Organization Blogs" : "My Blogs"}></AntTab>
            {!isSubAdmin ? (
              <AntTab label="Write Blog" onClick={openEditor} />
            ) : null}
          </AntTabs>
        </div>
        <div class="col-sm-12 col-md-6 mb-3 mt-2" >
          <div class="search-container d-flex justify-content-between align-items-center" style={{width: "100%", border:"1px solid gray", borderRadius: "5px", paddingRight: "5px"}}>
            <input
              type="text"
              name="searchQuery"
              value={search}
              onChange={onChangeSearch}
              className="form-control"
              placeholder="search blog"
              aria-label="User's Blog"
              aria-describedby="To Search the User Blog"
              style={{ width: "100%", border: "none", outline: "none", boxShadow: "none", appearance: "none", fontSize: "14px" }}
            />
              <LightTooltip title="Search">
            <i className="fa fa-search" style={{ color: "dodgerblue", cursor: "pointer" }} onClick={searchBlog}></i>
            </LightTooltip>
          </div>
        </div>
      </div>
    </div>
  );
}
