import http from "./httpService";
import { apiUrl, herokuUrl } from "./config.json";
let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = `${apiUrl}/dashboard`;
} else {
  apiEndpoint = `${herokuUrl}/dashboard`;
}

export async function getYearList() {
  let data = await http.get(`${apiEndpoint}/getYearList`);
  return data;
}

export async function getTeamMembersDetails(id) {
  let data = await http.post(`${apiEndpoint}/getTeamMembersDetails`,{
    id
  });
  return data;
}

export async function getOrganisationData(id) {
  let data = await http.post(`${apiEndpoint}/getOrganisationData`,{
    id
  });
  return data;
}

export async function getSuperAdminDashCards(id) {
  let data = await http.post(`${apiEndpoint}/getSuperAdminDashCards`, {
    id
  });
  return data;
}

export async function getUserPlanBreakdown(id) {
  let data = await http.post(`${apiEndpoint}/getUserPlanBreakdown`, {
    id
  });
  return data;
}

export async function getUserCountryCodeBreakdown(id) {
  let data = await http.post(`${apiEndpoint}/getUserCountryCodeBreakdown`, {
    id,
  });
  return data;
}

export async function getOrgUserPlanBreakdown(id, organizationId) {
  let data = await http.post(`${apiEndpoint}/getOrgUserPlanBreakdown`, {
    id,organizationId
  });
  return data;
}

export async function getDashboardDetails(ok) {
  let detail = { year: ok }
  const data = await http.post(`${apiEndpoint}/getUserDashBoardDetails`,detail);
  
  return data;
}

export async function getOrgUsersForBarChart(id,year) {
  let data = await http.post(`${apiEndpoint}/getOrgUsersForBarChart`, {
    id,year
  });
  return data;
}

export async function getDetailsSubAdminDash(email) {
  let data = await http.post(`${apiEndpoint}/dashboardDetails`, {
    email,
  });
  return data;
}








export async function getDetailsSubAdminBarChart(email) {
  let data = await http.post(`${apiEndpoint}/subAdminBarChartDetails`, {
    email,
  });
  return data;
}

export async function getDetailsSubAdminPieChart(email) {
  let data = await http.post(`${apiEndpoint}/dashBoardPieChartDetails`, {
    email,
  });
  return data;
}

export async function getIndividualDashCards(id) {
  let data = await http.post(`${apiEndpoint}/getIndividualDashCards`, {
    id,
  });
  return data;
}

export async function getIndividualUserPiechart(id,ownerId) {
  let data = await http.post(`${apiEndpoint}/getIndividualUserPiechart`, {
    id,ownerId
  });
  return data;
}

export async function getIndividualBarChart(userId,year) {
  let data = await http.post(`${apiEndpoint}/getIndividualBarChart`, {
    userId,year
  });
  return data;
}
export async function getDocsStatusBarChart(userId) {
  let data = await http.post(`${apiEndpoint}/getDocsStatusBarChart`, {
    userId
  });
  return data;
}

export async function getWidgetDetails(year) {
  const data = await http.post(`${apiEndpoint}/getWidgetDetails`, { year });
  return data;
}

export async function getMonthWiseRatings(yr) {
  let detail = { year: yr }
  const data = await http.post(`${apiEndpoint}/getMonthwiseRatingsDetail`,detail);
  console.log(data);
  return data;
}

export async function getMonthWiseTeamDetails(year) {
  let detail = { year: year }
  const data = await http.post(`${apiEndpoint}/getMonthWiseTeamDetails`,detail);
  console.log(data);
  return data;
}

export async function getRatedDocumentMonthwise(yr) {
  let detail = { year: yr }
  const data = await http.post(`${apiEndpoint}/getRatedDocumentMonthwise`,detail);
  console.log(data);
  return data;
}

export default {
  getYearList,
  getTeamMembersDetails,
  getOrganisationData,
  getSuperAdminDashCards,
  getUserPlanBreakdown,
  getUserCountryCodeBreakdown,
  getOrgUserPlanBreakdown,
  getDashboardDetails,
  getOrgUsersForBarChart,
  getDetailsSubAdminDash,
  getDetailsSubAdminBarChart,
  getDetailsSubAdminPieChart,
  getIndividualDashCards,
  getIndividualUserPiechart,
  getIndividualBarChart,
  getDocsStatusBarChart,
  getWidgetDetails,
  getMonthWiseRatings,
  getMonthWiseTeamDetails,
  getRatedDocumentMonthwise
};
