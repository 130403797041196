import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link, NavLink } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Tooltip from "@material-ui/core/Tooltip";
import { ThemeProvider, createTheme, makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import TipsAndUpdatesRoundedIcon from '@mui/icons-material/TipsAndUpdatesRounded';
import TranslateRoundedIcon from '@mui/icons-material/TranslateRounded';

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'blue',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip);

const theme = createTheme({
    palette: {
        primary: {
          main: '#ffffff',
        },
        text: {
          primary: '#ffffff',
        },
      },
})

export default function MenuAccordionMui() {

    function isUrlMatch(url) {
        if (window.location.pathname.match(url)) {
            return true;
        }
        return false;
    }

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <>
        <ThemeProvider theme={theme}>
            <Accordion expanded={expanded === 'panel1'} 
            onChange={handleChange('panel1')}
            sx={{backgroundColor: "#1976D2 !important", color: "#ffffff"}}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                        Features
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>

                    <Link
                        // className="sidenavbar-textlink"
                        to={{ pathname: "/promptsGenerator" }}
                    >
                        <ListItem
                            className={
                                isUrlMatch("/promptsGenerator") ? "background-red2" : "hvr-pulse"
                            }
                        >
                            <ListItemIcon
                                className="drawerMenu"
                                data-toggle="tooltip"
                                data-placement="right"
                                title=""
                            >
                                <LightTooltip title="Promptfs Generator">
                                
                                    <TipsAndUpdatesRoundedIcon
                                        className={isUrlMatch("/promptsGenerator") ? "iconCol" : ""}
                                        style={{color: "yellow"}}
                                    />
                                </LightTooltip>
                            </ListItemIcon>
                            <Typography 
                            sx={{color: isUrlMatch("/promptsGenerator") ? "#1976D2" : "white"}}
                            // sx={{color: "white"}}
                            >
                                Prompts Generator
                            </Typography>
                        </ListItem>
                    </Link>
                </AccordionDetails>
                {/* <AccordionDetails>

                    <Link
                        // className="sidenavbar-textlink"
                        to={{ pathname: "/adminBlogs" }}
                    >
                        <ListItem
                            // className={
                            //     isUrlMatch("/adminBlogs") ? "background-red" : "hvr-pulse"
                            // }
                        >
                            <ListItemIcon
                                className="drawerMenu"
                                data-toggle="tooltip"
                                data-placement="right"
                                title=""
                            >
                                <LightTooltip title="Translator">
                                
                                    <TranslateRoundedIcon
                                        className={isUrlMatch("/adminBlogs") ? "iconCol" : ""}
                                        style={{color: "white"}}
                                    />
                                </LightTooltip>
                            </ListItemIcon>

                            <Typography sx={{color: "white"}}>
                                Translator
                            </Typography>
                        </ListItem>
                    </Link>
                </AccordionDetails> */}
            </Accordion>
            </ThemeProvider>
        </>
    );
}
