import React from "react";
import AdminNavBar from "../AdminSideNavBar";
import SideNavBar from "../SideNavBar";
import { withRouter } from "react-router-dom";
import NetworkDetector from "../HigherOrderComponents/NetworkDetector";
import authService from "../../services/authService";
import GrammarCheck from "../GrammarCheck";
import CoAuthorNewProject from "../Forms/CoAuthorNewProject";

const GrammarCheckPage = (props) => { 
  const user = authService.getCurrentUser();
  let userData = {};
  let docId;
  if (props.location.state) {
    userData = props.location.state.userData;
    docId  = props.location.state.blogId
  }
  console.log(docId,"blog id from grammercheck");
  if (!user.isAdmin) {
    return (
      <SideNavBar>
        {/* <GrammarCheck /> */}
        <CoAuthorNewProject data={userData} isGrammarCheck={true} isCoAuthor={false} docId={docId} />
      </SideNavBar>
    );
  } else {
    return (
      <AdminNavBar>
        <CoAuthorNewProject data={userData} />
      </AdminNavBar>
    );
  }
};

export default NetworkDetector(withRouter(GrammarCheckPage));
