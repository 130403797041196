import React, { useState, useEffect } from "react";
import Loader from "../../utils/loader";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import _ from 'lodash'
import { Card } from "@mui/material";
import Button from '@mui/material/Button';
import FeatureButton from "../ReusableComponents/Button";
// import { Modal } from "react-bootstrap";

//import CheckboxMui from "./ReusableComponents/CheckBoxMui";
//import InputBoxMui from "./ReusableComponents/InputBoxMui";
import { Editor, EditorState } from "draft-js";
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Popover from '@mui/material/Popover';
import PromptsGenerator_Translator from "./PromptsGenerator_Translator";
import PromptsGenerator_Summariser from "./PromptsGenerator_Summariser";
import PromptsGenerator_Plagiarism from "./PromptsGenerator_Plagiarism";
import PromptsGenerator_Paraphrase from "./PromptsGenerator_Paraphrase";
import PromptsGenerator_GrammerCheck from "./PromptsGenerator_GrammerCheck";
import PromptsGenerator_CoAuthor from "./PromptsGenerator_CoAuthor";
import PromptsGenerator_Citation from "./PromptsGenerator_Citation";
import PromptsGenerator_Hint from "./PromptsGenerator_Hint";

const PromptsGenerator = () => {

    const [loading, setLoading] = useState(false);
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [activeButton, setActiveButton] = useState("Paraphrasing");
    const [buttonList, setButtonList] = useState(["Paraphrasing", "Translator", "Summarizer", "Co-Author",  "Citation", "Grammar Check", "Hint"])

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
 
  // Update windowWidth on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
 
    window.addEventListener('resize', handleResize);
 
    return () => window.removeEventListener('resize', handleResize);
  }, []);
 
  const mobileView = windowWidth < 770


    const handleButtonClick = (buttonName) => {
        setActiveButton(buttonName);
    };



    const handleChange = (newEditorState) => {
        setEditorState(newEditorState)
    }
    return (

        <div className="docCont">
            <Loader loading={loading} />
            <ToastContainer />
            <div style={{ position: "absolute", height: "85%", width: mobileView ? '90%' : "75%" }}>

               { !mobileView ? <nav style={{ backgroundColor: 'white', height: "10%", padding: '10px', color: "black", boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', borderRadius: '8px', display: 'flex', alignItems: "center" }}>
                    <ul style={{ listStyleType: 'none', padding: 0, display: "flex", alignItems: "center" }}>
                        {buttonList.map((buttonName) => (
                            <li
                                key={buttonName}
                                style={{
                                    marginRight: '10px',
                                    borderBottom: activeButton === buttonName ? '2px solid skyblue' : 'none',

                                }}
                            >
                                <div
                                    onClick={() => handleButtonClick(buttonName)}
                                    style={{
                                        textDecoration: 'none',
                                        color: activeButton === buttonName ? "skyblue" : "black",
                                        padding: '8px 8px',
                                        borderRadius: '5px',
                                        transition: 'background-color 0.3s ease',
                                    }}
                                >
                                    {buttonName}
                                </div>
                            </li>
                        ))}
                    </ul>
                </nav>

                :

                <nav style={{ backgroundColor: 'white', height: "10%", padding: '10px', color: "black", boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', borderRadius: '8px', display: 'flex', alignItems: "center" }}>
    <select
        value={activeButton}
        onChange={(e) => handleButtonClick(e.target.value)}
        style={{
            width:'200px',
            padding: '8px 12px',
            borderRadius: '5px',
            border: '1px solid #ccc',
            backgroundColor: 'white',
            color: 'black',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
            outline: 'none',
            appearance: 'none',
            backgroundImage: 'url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjN2I4M2JlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij48cGF0aCBkPSJNNC41IDZMOC44IDEwLjVoM0wxMS4xIDZ6Ii8+PC9zdmc+)',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right 10px center',
            backgroundSize: '12px',
        }}
    >
        {buttonList.map((buttonName) => (
            <option
                key={buttonName}
                value={buttonName}
                style={{
                    padding: '10px',
                    color: activeButton === buttonName ? "skyblue" : "black",
                }}
            >
                {buttonName}
            </option>
        ))}
    </select>
</nav>


    }



                {
  activeButton === "Paraphrasing" && <PromptsGenerator_Paraphrase />
}             
               
 {
  activeButton === "Translator" && <PromptsGenerator_Translator />
}


{
  activeButton === "Summarizer" && <PromptsGenerator_Summariser />
}
{
  activeButton === "Co-Author" && <PromptsGenerator_CoAuthor />
}

{
  activeButton === "Plagiarism" && <PromptsGenerator_Plagiarism />
}

{
  activeButton === "Citation" && <PromptsGenerator_Citation />
}

{
  activeButton === "Grammar Check" && <PromptsGenerator_GrammerCheck />
}
{
  activeButton === "Hint" && <PromptsGenerator_Hint />
}




            


            </div>
        </div>
    );
}

export default PromptsGenerator;
