const initialState = {
    loginClickState : ""
};

const handleLoginClickReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_SOCIAL_MEDIA_LOGIN':
            return {
                ...state,
                loginClickState: "socialMedia"
            };
        case 'SET_MANUALLY_LOGIN':
            return {
                ...state,
                loginClickState: "manually"
            };
        default:
            return state;
    }
};

export default handleLoginClickReducer;
