import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import GrammarReport from "./GrammarReport";
import ReactTour from "./ReactTour";
import Tooltip from "@material-ui/core/Tooltip";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'blue',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);
const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: "#1890ff",
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&$selected": {
      color: "#1890ff",
      border: "none",
      fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#40a9ff",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
  demo2: {
    backgroundColor: "#2e1534",
  },
}));

export default function CustomizedTabs({ handleChangeTab, openEditor, filterOnChange, searchValue, searchBlogName }) {
  // const {  switchTab } = this.props
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [reactTour, setReactTour] = useState(false);

  const steps = [
    {
      selector: ".reactour-1",
      content: "Click to view your blogs.",
      position: "bottom",
    }
  ];

  const stepsone = [
    {
      selector: ".reactour-1",
      content: "Click to view your blogs.",
      position: "bottom",
    },
    {
      selector: ".reactour-2",
      content: "Click to write a blog.",
      position: "bottom",
    },
  ];

  const handleChange = async (event, newValue) => {
    event.preventDefault();
    console.log(newValue);
    setValue(newValue);
    handleChangeTab(newValue);
  };


  return (
    <div className={classes.root} >
      <div className={`${classes.demo1} row`} style={{marginLeft: "10px", width: "98%"}}>
        <div class="col-sm-12 col-md-6 mb-3">
          <AntTabs
            value={value}
            onChange={handleChange}
            aria-label="ant example"
            variant="scrollable"
            style={{ width: "90%", outline: "none" }}
          >
            <AntTab label="Latest Posts" onChange={() => handleChange("0")}></AntTab>
            <AntTab label="Your Posts" onChange={() => handleChange("1")} className="reactour-1"></AntTab>
            <AntTab label="Write Blog" onClick={openEditor} className="reactour-2"></AntTab>

          </AntTabs>
        </div>
        <div class="col-sm-12 col-md-6 mb-3 mt-2" style={{display: "flex", justifyContent: "space-between"}} >
          <div style={{ width: "90%"}}>
            <div class="search-container d-flex justify-content-between align-items-center" style={{width: "100%", border: "1px solid gray", borderRadius: "5px", paddingRight: "5px" }}>
              <input
                type="text"
                name="searchQuery"
                value={searchValue}
                onChange={filterOnChange}
                className="form-control"
                placeholder="search blog"
                aria-label="User's Blog"
                aria-describedby="To Search the User Blog"
                style={{ border: "none", outline: "none", boxShadow: "none", appearance: "none", fontSize: "14px" }}
              />
              <LightTooltip title="Search">
              <i className="fa fa-search" style={{ color: "dodgerblue", cursor: "pointer" }} onClick={searchBlogName}></i>
              </LightTooltip>
            </div>
          </div>
          <div style={{width: "8%"}}>
          <LightTooltip title="Need Help?">
            <i
              className="fa fa-question-circle mt-3"
              aria-hidden="true"
              style={{
                cursor: "pointer",
                color: "dodgerblue",
                fontSize: "15px",
                float: "right",
                marginLeft: "15px"
              }}
              onClick={() => setReactTour(true)}
            ></i>
          </LightTooltip>
          </div>
        </div>

      </div>
      <ReactTour
        steps={value === 1 ? stepsone : steps}
        isOpen={reactTour}
        closeTour={() => setReactTour(false)}
      />
    </div>
  );
}
