import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import Loader from "../utils/loader";
import userService from "../services/userService";
import { toast, ToastContainer } from "react-toastify";
import $ from "jquery";
import Swal from "sweetalert2";
import { withRouter } from "react-router-dom";
import "./settings.css";
import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/dist/style.css";
import ThemeSwitch from "./ThemeSwitch";
import { Link } from "react-router-dom";
import authService from "../services/authService";
import TimezonePicker from "react-bootstrap-timezone-picker";
import "react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css";
import isEmptyObject from "../utils/isEmptyObject";
import BreadCrumb from "./Cards/BreadCrumbs";
import adminServices from "../services/adminServices";
import "./hover-min.css";
import InputBase from "@material-ui/core/InputBase";
import FormControl from "@material-ui/core/FormControl";
import { withStyles } from "@material-ui/core/styles";
import {
  addDictionary,
  getmywords,
  deletemywords,
} from "../services/englishCheckers";
import { connect, useSelector } from "react-redux";
import { setCurrentUser } from "../redux/user/user.actions";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import uploadImageServices from "../services/uploadImageServices";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { getProducts, addProduct, deleteProduct } from '../services/productService';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import PreferencesAccordionMui from "./ReusableComponents/PreferenceAccordionMui";
import RadioButtonMui from "./ReusableComponents/RadioButtonMui";
import ImageAvatarsMui from "./ReusableComponents/ImageAvatarsMui";
import CloudUploadRoundedIcon from '@mui/icons-material/CloudUploadRounded';
import { Button } from "@mui/material";
import CryptoJS from 'crypto-js';
import { TwoFactorComp } from "./ReusableComponents/TwoFactorComp";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";



const inputLabel = {
  fontSize: "15px",
  color: "#707070",
  lineHeight: "1.33",
  width: "90%",
};

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.common.white,
    border: "none",
    // fontSize: 20,
    textAlign: "left",
    width: "auto",
    fontWeight: "bold",
    // padding: "8px 10px",
    marginTop: "-7px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ["Roboto"].join(","),
    "&:focus": {
      // boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      // borderColor: theme.palette.primary.main,
      borderBottom: "2px solid #1976d2",
    },
  },
}))(InputBase);

const SettingsNew = ({ props }) => {
  const userDetails = useSelector((user) => user)
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const fileInputRef = useRef(null);
  const [error, setError] = useState({});
  const [npassword, setNPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [display, setDisplay] = useState(false);
  const [readonly, setReadonly] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [visibility, setVisibility] = useState("hidden");
  const [isAdmin, setIsAdmin] = useState(false);
  const [isUser, setIsUser] = useState(false);
  const [isSubAdmin, setIsSubAdmin] = useState(false);
  const [dashLink, setDashLink] = useState("/dashboard");
  const [isDark, setIsDark] = useState("false");
  const [errors, setErrors] = useState("");
  const [cerrors, setCErrors] = useState("");
  const [disable, setDisable] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [addWord, setAddWord] = useState("");
  const [Dictionary, setDictionary] = useState([]);
  const [characterLimit, setCharacterLimit] = useState(0);
  const [plagiarismLimit, setPlagiarismLimit] = useState(0);
  const [paraphraseLimit, setParaphraseLimit] = useState(0);
  const [expiryDate, setExpiryDate] = useState("");
  const [key, setKey] = useState("");
  const [manageUser, setManageUser] = useState(false);
  const [sentenceHighlighter, setSentenceHighlighter] = useState("");
  const [products, setProducts] = useState([]);
  const [addProduct, setAddProduct] = useState("");
  const [productModal, setProductModal] = useState(false);
  const [radioSelection, setRadioSelection] = useState(1);
  const history = useHistory()
  const [isDeleted, setIsDeleted] = useState(false);
  const [radioOptions, setRadioOptions] = useState([
    {
      label: "American English",
      value: 1,
      status: true
    },
    {
      label: "Canadian English",
      value: 2,
      status: false
    },
    {
      label: "British English",
      value: 3,
      status: false
    }
  ]);
  const [is2Factor, setIs2Factor] = useState(true);
  const [is2FEmailChecked, setIs2FEmailChecked] = useState(false);
  const [is2FMobileChecked, setIs2FMobileChecked] = useState(true);
  const [is2FactorApproved, setIs2FactorApproved] = useState(false);
  const [is2FReqApprovedMsg, setIs2FReqApprovedMsg] = useState("");
  const [isRequestSent, setIsRequestSent] = useState(false)
  // const [accountType, setAccountType] = useState(localStorage.getItem("accountType"));
  const [accountType, setAccountType] = useState(userDetails.user.currentUser.accountType);
  // const [role, setRole] = useState(localStorage.getItem("Role"));
  const [role, setRole] = useState(userDetails.user.currentUser.Role);
  const [organizationId, setOrganizationId] = useState(userDetails.user.currentUser.organizationId);
  const [currentUserEmail, setCurrentUserEmail] = useState(userDetails.user.currentUser.email);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [fontSize, setFontSize] = useState(20)

  const data2F = { is2Factor, is2FEmailChecked, is2FactorApproved, is2FReqApprovedMsg, isRequestSent, email, isSubAdmin }


  useEffect(() => {
    console.log("props", props, userDetails)
    return () => {

    };
  }, []);

  const onRadioChange = (e) => {
    console.log('radio_checked', e.target.value);
    const value = e.target.value;

    const updatedStatusList = radioOptions.map((item) => ({
      ...item,
      status: item.value === value,
    }));

    setRadioSelection(value);
    setRadioOptions(updatedStatusList);
  };

  const handleChangeSidenavBar = (event) => {
    const newData = { ...data, [event.target.name]: event.target.checked };
    console.log(newData);
    console.log(newData.sidenavOption);

    setCurrentUser(newData);
    setData(newData);
  };

  const handleToggle = (value) => {
    const newData = { ...data, [value]: data[value] ? !data[value] : true };
    setCurrentUser(newData);
    setData(newData);
  };

  const handleChange = ({ target: { value, name } }) => {
    const newData = { ...data, [name]: value };
    console.log(newData);
    setCurrentUser(newData);
    setData(newData);
    setUsername(newData.username)
    
  };


  const handleEdit = () => {
    setReadonly(false); // Set readonly to false to make the input editable
  };

  
  const onChangeCPassword = (e) => {
    const cpass = e.target.value;
    setCPassword(cpass)
    const npass = data.npassword; // Assuming you have 'npassword' in your data state
    setData({ ...data, cpassword: cpass });

    // if (cpass.length > 0 && npass.length > 0) {
      if (cpass.length > 0 && npassword.length > 0) {
      if (errors.length > 1) {
        setDisable(true);
      } else {
        if (cpass !== npassword) {
          setDisable(true);
          setCErrors("Password & Confirm Password Field Should Match");
        } else {
          setErrors("");
          setCErrors("");
          setDisable(false);
        }
      }
    } else {
      setErrors("");
      setCErrors("");
      setDisable(true);
    }
  };

  const userProfileData = async () => {
    let params = props.currentUser.email;
    try {
      let userData = await userService.getUserProfile(params);
      console.log("data", userData);
    } catch (error) {
      console.error("Error fetching user profile data:", error);
    }
  };

  const onChangeNPassword = (e) => {
    const npass = e.target.value;
    const cpass = cpassword; // Assuming you have 'cpassword' in your state
    setNPassword(npass);

    if (npass.length > 0) {
      if (npass.length <= 7) {
        setDisable(true);
        setErrors("Password must contain minimum 8 Characters");
      } else {
        setErrors("");
        const upperCaseLetters = /[A-Z]/g;
        const numbers = /[0-9]/g;

        if (!npass.match(upperCaseLetters)) {
          setDisable(true);
          setErrors("Password must contain at least 1 Capital letter");
        } else {
          setErrors("");
          if (!npass.match(numbers)) {
            setDisable(true);
            setErrors("Password must contain at least 1 number");
          } else {
            setErrors("");
          }
        }
      }
    } else {
      setErrors("");
    }

    if (cpass.length > 7 && npass.length > 7 && errors.length < 1 && npass === cpass) {
      setErrors("");
      setCPassword("");
      setDisable(false);
    }
  };

  const onClickResetPassword = async () => {
    const hashedPassword = CryptoJS.SHA256(npassword).toString();
    const hashConfirmPassword = CryptoJS.SHA256(cpassword).toString();
    let user = {
      email: data.email,
      password: hashedPassword,
      cpassword: hashConfirmPassword,
    };
    console.log("onClickResetPassword", user)
    const result = await authService.resetPassword(user);
    if (result.status === "good") {
      toast.success(result.message);
    } else {
      toast.error(result.message);
    }
    setNPassword("");
    setCPassword("");
  };

  const onChangePhone = (e) => {
    const newData = { ...data, phone: e };
    setCurrentUser(newData);
    setData(newData);
  };

  const onChangeTimezone = (e) => {
    const newData = { ...data, timezone: e };
    setCurrentUser(newData);
    setData(newData);
  };

  const getProfileData = async () => {
    // console.log("props",props)
    // let params = props.currentUser.email;
    let params = userDetails.user.currentUser.email
    try {
      let res = await userService.getUserProfile(params);
      // let userData = { ...user.data };
      let userData = res.data.user
      let otp = res.data.OTP
      console.log("profileData", userData, res);
      setCurrentUser(userData);
      setData(userData);
      setIsAdmin(userData.isAdmin);
      setIsSubAdmin(userData.isSubAdmin);
      setLoading(false);
      setImagePreviewUrl("dummy");
      setIsUser(!userData.isAdmin);
      //setReadonly(false);
      setExpiryDate(userData.planID ? userData.planID.expireDate : "");
      setIs2Factor(userData.is2Factor);
      setIs2FEmailChecked(userData.is2FEmailChecked);
      setIsRequestSent(userData.isRequestSent)
      setIs2FactorApproved(otp?.isApproved ? true : false);
      setIs2FReqApprovedMsg(otp?.message ? otp.message : "");
      setUsername(userData.username);
      setEmail(userData.email);

      if (userData.isAdmin) {
        getCharacterLimit();
        getParaphraseLimit();
        getPlagiarismLimit();
        getSentenceLimit();
      }

    } catch (error) {
      console.error("Error fetching user profile data:", error);
    }
  };

  const handle2FactorSwitch = () => {
    setIs2Factor(!is2Factor);
    setIs2FMobileChecked(!is2FMobileChecked);
    setIs2FEmailChecked(is2Factor === false && false);
  };

  const handleEmailCheckBox = () => {
    setIs2FEmailChecked(!is2FEmailChecked);
  };

  const handleDeleteOrganizationUser = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Your Request will be sent to Admin for Approval !!",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Send Delete Request!",
      cancelButtonText: "No, keep it",
    }).then(async (result) => {
      if (result.value === true) {
        try {
          let data = {
            email: currentUserEmail,
            typeOfRequest: "Delete"
          };
          let response = await adminServices.updateUserRequest(data);

          if (response.status === 200) {
            toast.info(response.message);
            toast.info("Delete Request has been sent to Organization Admin");
          } else {
            toast.error(response.message);
          }
        } catch (error) {
          console.error("Error sending delete request:", error);
        }
      } else {
        Swal.fire("Cancelled", "Your Account is safe :)", "error");
      }
    });
  };

  const handleDeleteAccount = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "It will be permanently deleted!!",
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then(async (result) => {
      if (result.value === true) {
        try {
          let response = await userService.deleteAccount();
          if (response.status === 200) {
            toast.success(response.message);
            setIsDeleted(true);
          } else {
            toast.error(response.message);
          }
          Swal.fire("Deleted!", "Your Account has been deleted.", "success");
        } catch (error) {
          console.error("Error deleting account:", error);
        }
      } else {
        Swal.fire("Cancelled", "Your Account is safe :)", "error");
      }
    });
  };

  useEffect(() => {
    if (isDeleted) {
      history.push({
        pathname: `/`,
      });
    }
  }, [isDeleted, history]);

  // useEffect(() => {
  //   getProfileData();
  // }, [props.currentUser]);

  useEffect(() => {
    getProfileData();
  }, []);

  //   useEffect(() => {
  //     const fetchData = async () => {
  //       let { currentUser, initialData, initialManageUser } = props
  //       console.log("componenet", currentUser, initialData, initialManageUser,userDetails);
  //       let {isSubAdmin} = userDetails.user.currentUser
  //       let params = currentUser.email;

  //       // if (initialManageUser) {
  //       //   setDashLink("/admindashboard");
  //       //   setIsAdmin(true);
  //       //   setReadonly(true);
  //       //   setData(initialData);
  //       //   setLoading(false);
  //       //   setIsUser(false);
  //       //   setManageUser(initialManageUser);
  //       //   getProfileData(params);
  //       // } 
  //        if (currentUser.isSubAdmin === true) {
  //         console.log(currentUser);
  //         setDashLink("/subAdminDashboard");
  //         getProfileData(params);
  //       } 
  //       else if (currentUser && Object.keys(currentUser).length !== 0) {
  //         setDashLink(currentUser.isAdmin ? "/admindashboard" : "/dashboard");
  //         setIsAdmin(currentUser.isAdmin);
  //         setData(currentUser);
  //         setIsUser(!currentUser.isAdmin);
  //         setIsSubAdmin(currentUser.isSubAdmin);
  //         setReadonly(false);
  //         setLoading(false);
  //         setExpiryDate(currentUser.planID ? currentUser.planID.expireDate : "");

  //         if (currentUser.isAdmin) {
  //           getCharacterLimit();
  //           getParaphraseLimit();
  //           getPlagiarismLimit();
  //           getSentenceLimit();
  //           Blog_Products();
  //         } else {
  //           getDictionary();
  //           getProfileData(params);
  //         }
  //       }
  //     };

  //     fetchData();
  //   // }, [props.currentUser, props.initialData, props.initialManageUser]);
  // }, []);

  useEffect(() => {
    const openFileInput = () => {
      $("#fileup").trigger("click");
    };

    $("#open").click(openFileInput);

    return () => {
      $("#open").off("click", openFileInput);
    };
  }, []);


  const handleImageChange = async (e) => {
    e.preventDefault();
    setLoading(true);

    let file = e.target.files[0];
    let key = document.getElementById("UploadprofilePic")?.getAttribute("name") || null;

    try {
      const uploadConfig = await uploadImageServices.UploadImageToS3(file, key);
      let user = { ...data };
      user.display_picture = uploadConfig.data.displayUrl;
      user.key = uploadConfig.data.key;
      setCurrentUser(user);

      setData(user);
      setImagePreviewUrl(uploadConfig.data.displayUrl);
      setLoading(false);
      updateProfile();
    } catch (error) {
      console.error('Error uploading image:', error);
      setLoading(false);
    }
  };

  const uploadPic = () => {
    const { isUser, manageUser } = props;

    if (isUser || (!manageUser && !isUser)) {
      fileInputRef.current.click();
    }
  };

  const updateProfile = async () => {
    //const { setCurrentUser, currentUser } = props;
    const updatedData = { ...data, is2Factor, is2FMobileChecked, is2FEmailChecked };

    console.log("profileData", updatedData);

    setLoading(true);

    try {
      if (updatedData.username === "") {
        setLoading(false);
        toast.error("Profile Update failed");
      } else {
        const user = await userService.updateProfile(updatedData);

        if (user.status !== 200) {
          setLoading(false);
          toast.error(user.message);
        } else {
          toast.success(user.message);
          setCurrentUser(updatedData);
          //console.log(currentUser);
          setData(updatedData);
          setLoading(false);
          setReadonly(true)
        }
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      setLoading(false);
    }
  };

  const handleActivateUser = async () => {
    try {
      const user = await adminServices.activateUser({ email: data.email });

      if (user.status !== 200) {
        toast.error(user.message);
      } else {
        toast.info(user.message);
        getManagedUserProfile(user.data);
      }
    } catch (error) {
      console.error('Error activating user:', error);
    }
  };

  const handleDeactivateUser = async () => {
    try {
      const user = await adminServices.freezeuser({ email: data.email });

      if (user.status !== 200) {
        toast.error(user.message);
      } else {
        toast.info(user.message);
        getManagedUserProfile(user.data);
      }
    } catch (error) {
      console.error('Error deactivating user:', error);
    }
  };

  const getManagedUserProfile = (userData) => {
    setData(userData);
  };

  const addWordDictonary = async () => {
    try {
      let data = await addDictionary(addWord);
      if (data.status !== 200) {
        toast.error('Failed to add the word');
      } else {
        getDictionary();
      }
      setAddWord('');
    } catch (error) {
      console.error('Error adding word to dictionary:', error);
    }
  };

  const getDictionary = async () => {
    try {
      let data = await getmywords();
      if (data.status === 200) {
        setDictionary(data.words);
      }
    } catch (error) {
      console.error('Error getting dictionary:', error);
    }
  };

  const removeWord = async (word) => {
    try {
      let data = await deletemywords(word);
      if (data.status !== 200) {
        toast.error('Failed to delete the word');
      } else {
        getDictionary();
      }
    } catch (error) {
      console.error('Error removing word:', error);
    }
  };

  const Blog_Products = async () => {
    try {
      let { data } = await getProducts();
      setProducts(data.products);
    } catch (error) {
      console.error('Error getting products:', error);
    }
  };

  const addNewProduct = async () => {
    try {
      let product = addProduct.trim().toLowerCase().replace(/ /g, '_');
      let data = await addProduct(product);
      if (data.status !== 200) {
        toast.error('Failed to add product');
      } else {
        Blog_Products();
      }
      setAddProduct('');
    } catch (error) {
      console.error('Error adding new product:', error);
    }
  };

  const removeProduct = async (product) => {
    try {
      let data = await deleteProduct(product);
      if (data.status !== 200) {
        toast.error('Failed to delete the product');
      } else {
        Blog_Products();
      }
    } catch (error) {
      console.error('Error removing product:', error);
    }
  };

  const getCharacterLimit = async () => {
    try {
      let data = await adminServices.getCharacterLimit();
      setCharacterLimit(data.character_limit);
    } catch (error) {
      console.error('Error getting character limit:', error);
    }
  };

  const setCharacterLimitValue = async (limit) => {
    try {
      let data = await adminServices.setCharacterLimit(limit);
      if (data.status === 200) {
        toast.success(data.message);
        getCharacterLimit();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error('Error setting character limit:', error);
    }
  };

  const getSentenceLimit = async () => {
    try {
      let data = await adminServices.getSentenceLimit();
      setSentenceHighlighter(data.sentence_limit);
    } catch (error) {
      console.error('Error getting sentence limit:', error);
    }
  };

  const setSentenceLimitValue = async () => {
    try {
      let limit = sentenceHighlighter.trim();
      if (limit[limit.length - 1] === ',') {
        limit = limit.slice(0, -1);
      }
      setSentenceHighlighter(limit);
      let data = await adminServices.setSentenceLimit(limit);
      if (data.status === 200) {
        toast.success(data.message);
        getSentenceLimit();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error('Error setting sentence limit:', error);
    }
  };

  const getPlagiarismLimit = async () => {
    try {
      let data = await adminServices.getPlagiarismLimit();
      setPlagiarismLimit(data.plagiarism_limit);
    } catch (error) {
      console.error('Error getting plagiarism limit:', error);
    }
  };

  const setPlagiarismLimitValue = async (limit) => {
    try {
      let data = await adminServices.setPlagiarismLimit(limit);
      if (data.status === 200) {
        toast.success(data.message);
        getPlagiarismLimit();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error('Error setting plagiarism limit:', error);
    }
  };

  const getParaphraseLimit = async () => {
    try {
      let data = await adminServices.getParaphraseLimit();
      setParaphraseLimit(data.paraphrase_limit);
    } catch (error) {
      console.error('Error getting paraphrase limit:', error);
    }
  };

  const setParaphraseLimitValue = async (limit) => {
    try {
      let data = await adminServices.setParaphraseLimit(limit);
      if (data.status === 200) {
        toast.success(data.message);
        getParaphraseLimit();
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      console.error('Error setting paraphrase limit:', error);
    }
  };

  const handleLimit = (e) => {
    setFontSize(e.target.value);
  };

  const biggify = () => {
    if (fontSize < 20) {
      setFontSize((prevSize) => prevSize + 2);
    } else {
      toast.warn("You have reached the maximum font limit");
    }
  };

  const smallify = () => {
    if (fontSize > 8) {
      setFontSize((prevSize) => prevSize - 2);
    } else {
      toast.warn("You have reached the minimum font limit");
    }
  };

  const fontReset = () => {
    setFontSize(12);
  };

  return (
    <div>
      <Loader loading={loading} />
      {/* <div className="">
        
        {isSubAdmin ? (
          <BreadCrumb
            l1="Dashboard"
            l2="Account Settings"
            dashboardlink={dashLink}
          />
        ) : manageUser ? (
          <BreadCrumb
            l1="Dashboard"
            l2="Manage Users"
            l3="Account Settings"
            dashboardlink={dashLink}
            documentLink="/manageuser"
          />
        ) : (
          <BreadCrumb
            l1="Home"
            l2="Account Settings"
            dashboardlink={dashLink}
          />
        )}

        <h4 className="ft_xl" style={{ margin: "0" }}>
          Account Settings
        </h4>
      </div> */}
      {/* <br /> */}
      <div className="bg-white rounded shadow">
        <br />
        {/* <form> */}
        <div className="row mt-2">
          <div className="col-md-2 firstcol">
            <div className="text-center">
              <div className="hvrpic"
                name={data?.key}
              >
                <ImageAvatarsMui
                  id="UploadprofilePic"
                  alt={data?.username}
                  src={data?.display_picture}
                />

                {isUser || !manageUser ? (
                  <div className="middleIcon">
                    <Button
                      // id="fileup"
                      // variant="contained"
                      // className="uploadBtn"
                      component="span"
                      ref={fileInputRef}
                      onClick={uploadPic}
                    >
                      <CloudUploadRoundedIcon />
                    </Button>
                  </div>

                ) : (
                  ""
                )}
              </div>
              <br />

              <input
                className="fileInput"
                type="file"
                id="fileup"
                // ref={fileInputRef}
                accept="image/*"
                onChange={(e) => handleImageChange(e)}
                style={{ display: "none" }}
                readonly={readonly}
              />
            </div>
          </div>

          <div className="hvrInput col-md-8"
            style={{ textAlign: "left" }}
          >
            <div>
              <FormControl>
                <BootstrapInput
                  className="ft_xl"
                  // style={{textAlign: "left"}}
                  // defaultValue={data.username}
                  value={username}
                  id="bootstrap-input"
                  onChange={handleChange}
                  name="username"
                  readOnly={readonly}
                  autoComplete="off"
                />
              </FormControl>
              {readonly ? (
                <div className="hvrpencil" onClick={handleEdit}>
                  <i
                    className="fa fa-pencil point "
                    aria-hidden="true"
                  ></i>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="form-group f1">
              <p className="sub-headings">{email}</p>

            </div>
          </div>
        </div>
        <br />

        <hr style={{ width: "95%", margin: "15px auto" }} />

        {/* display pic and name */}
        <div className="row mt-3">
          <div className="col-md-12 firstcol">

            <p className="h6-headings">Email Verified <CheckCircleRoundedIcon />  </p>
            <p className="sub-headings">{email}</p>
          </div>
        </div>
        <hr style={{ width: "95%", margin: "15px auto" }} />
        <div className="row mt-2">
          <div
            className="col-md-12 firstcol"
          >

            <div>
              <p className="h6-headings">Subscription Plan</p>
              <p className="sub-headings"><i
                className="fa fa-check  icon_color mr-2"
                aria-hidden="true"
              ></i> {data?.planName || "free Trial"}</p>
              <p className="sub-headings2">Expiry :{" "}
                {expiryDate
                  ? new Date(expiryDate)
                    .toString()
                    .slice(0, 15)
                  : "No Expiry Date"}</p>
            </div>

            <div className="mt-2">
              <Link to={{ pathname: "/pricing" }}>
                <button
                  style={{
                    border: "1px solid #1976D2",
                    borderRadius: "8px",
                    boxShadow: "3px 3px 7px -2px #000000",
                    backgroundColor: "#ffffff",
                    color: "#1976D2",
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "500",
                    fontSize: "0.9rem",
                    padding: "7px",
                    width: "150px"
                  }}
                >
                  Upgrade Plans
                </button>
              </Link>
            </div>

          </div>
        </div>
        <hr style={{ width: "95%", margin: "15px auto" }} />
        {/* <div className="row mt-2">
          <div className="col-md-11 firstcol">
            <PreferencesAccordionMui
              // is2Factor={is2Factor}
              // handle2FactorSwitch={handle2FactorSwitch}
              // is2FEmailChecked={is2FEmailChecked}
              // handleEmailCheckBox={handleEmailCheckBox}
              renderComponent={() => <RadioButtonMui
                optionsList={radioOptions}
                selectedValue={radioSelection}
                handleChange={(event) => onRadioChange(event)}
              />}
            />
            {
              (role == "Organization" || isSubAdmin == true) &&
              <TwoFactorComp
                data={is2FReqApprovedMsg !== "" ? data2F : data2F}
                getProfile={getProfileData}
              />
            }

          </div>
        </div> */}

        {!manageUser && isAdmin ? (
          <div>
            <div className="row mt-3">
              <h4 className="ft_lg firstcol">Character Limit</h4>
            </div>
            <hr />
            <br />

            <div className="row firstcol">
              <div className="col-lg-3 col-sm-6">
                <div className="form-group pl-2">
                  <div>
                    <label className="ft_sm "><b>Document: </b></label>
                  </div>
                  <br />

                  {/* <TextField
                    id="standard-basic"
                    // label={`Current limit ${character_limit}`}
                    label={`Current limit ${character_limit === undefined
                      ? 0
                      : character_limit
                      }`}
                    type="number"
                    name="character_limit"
                    value={character_limit}
                    onChange={handleLimit}
                  /> */}

                  {/* <button
                    className="btn btn-primary mt-3 ml-2"
                    onClick={() =>
                      setCharacterLimit(character_limit)
                    }
                  >
                    Set Limit
                  </button> */}
                </div>
              </div>

              <div className="col-lg-3 col-sm-6">
                <div className="form-group pl-2">
                  <div>
                    <label className="ft_sm "><b>Paraphrase: </b></label>
                  </div>
                  <br />

                  {/* <TextField
                    id="standard-basic"
                    // label={`Current limit ${character_limit}`}
                    label={`Current limit ${paraphrase_limit === undefined
                      ? 0
                      : paraphrase_limit
                      }`}
                    type="number"
                    name="paraphrase_limit"
                    value={paraphrase_limit}
                    onChange={handleLimit}
                  /> */}

                  {/* <button
                    className="btn btn-primary mt-3 ml-2"
                    onClick={() =>
                      setParaphraseLimitValue(paraphrase_limit)
                    }
                  >
                    Set Limit
                  </button> */}
                </div>
              </div>

              <div className="col-lg-3 col-sm-6">
                <div className="form-group pl-2">
                  <div>
                    <label className="ft_sm "><b>Plagiarism: </b></label>
                  </div>
                  <br />

                  {/* <TextField
                    id="standard-basic"
                    // label={`Current limit ${character_limit}`}
                    label={`Current limit ${plagiarism_limit === undefined
                      ? 0
                      : plagiarism_limit
                      }`}
                    type="number"
                    name="plagiarism_limit"
                    value={plagiarism_limit}
                    onChange={handleLimit}
                  /> */}

                  {/* <button
                    className="btn btn-primary mt-3 ml-2"
                    onClick={() =>
                      setPlagiarismLimitValue(plagiarism_limit)
                    }
                  >
                    Set Limit
                  </button> */}
                </div>
              </div>

              <div className="col-lg-3 col-sm-6">
                <div className="form-group pl-2">
                  <div>
                    <label className="ft_sm "><b>Sentence Highlighter: </b></label>
                  </div>
                  <br />

                  {/* <TextField
                    id="standard-basic"
                    // label={`Current limit ${character_limit}`}
                    label={`${sentence_highlighter === undefined
                      ? 0
                      : sentence_highlighter
                      }`}
                    type="text"
                    name="sentence_highlighter"
                    value={sentence_highlighter}
                    onChange={(e) => setState({ sentence_highlighter: e.target.value })}
                  /> */}

                  {/* <button
                    className="btn btn-primary mt-3 ml-2"
                    onClick={setSentenceLimit}
                  >
                    Set Limit
                  </button> */}
                </div>
              </div>

            </div>
          </div>
        ) : (
          ""
        )}

        {!manageUser && isAdmin ? (
          <div className="mb-5">
            <div className="row mt-5">
              <h4 className="ft_lg firstcol">Blog Settings</h4>
            </div>
            <hr />
            <br />
            <div className="row firstcol">
              <div className="d-inline-block ft_md point font-weight-bold del">
                <button className="btn btn-primary" onClick={() => setProductModal(true)}>
                  Add Blog Products
                </button>
              </div>
            </div>
          </div>
        ) : ("")}

        {isUser ? (
          <div className="row firstcol">
            <div className="">
              {" "}

              <div className="d-inline-block ft_md point font-weight-bold del  mt-3">
                <button
                  data-toggle="modal"
                  data-target="#ChangePassModal"
                  // className="accountSettingsBtn"
                  style={{
                    borderStyle: "none",
                    borderRadius: "8px",
                    boxShadow: "2px 2px 5px -2px #000000",
                    backgroundColor: "#ffffff",
                    color: "#1976D2",
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "500",
                    fontSize: "0.9rem",
                    padding: "7px",
                    width: "150px",
                  }}
                >
                  Change Password
                </button>
              </div>
              <p className="ft_xs mt-2">
                Upgrade your security check, by changing your password.
              </p>

              {isUser &&
                <div>
                  <div className="d-inline-block ft_md point font-weight-bold del">
                    <button
                      // className={!isUser ? "AdminVisible" : "accountSettingsBtn"}
                      style={{
                        borderStyle: "none",
                        borderRadius: "8px",
                        boxShadow: "2px 2px 5px -2px #000000",
                        backgroundColor: "#ffffff",
                        color: "#1976D2",
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: "500",
                        fontSize: "0.9rem",
                        padding: "7px",
                        width: "150px",
                      }}
                      onClick={role == "Organization" ? handleDeleteOrganizationUser : handleDeleteAccount}
                    >
                      Delete Account {isUser}
                    </button>
                  </div>
                  <p className="ft_xs mt-2">
                    This account is no longer available, and all your saved data
                    will be permanently deleted.
                  </p>
                </div>
              }
            </div>

            <div className="col-sm-6  mt-4">
              <div>

              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        <br />
        <hr style={{ width: "95%", margin: "15px auto", color: "#444444", height: "1px" }} />
        <br />
        <br />
        <div className="">
          {!manageUser || isUser ? (
            <div className="text-center">
              <Link
                to={{
                  pathname: isAdmin
                    ? "/admindashboard"
                    : isSubAdmin
                      ? "/subAdminManageUser"
                      : "/dashboard",
                }}
              >
                <button
                  className="btn btn-outline-primary mr-3 mb-4"
                  type="button"
                  id="backBtn"
                  style={{
                    width: "20%",
                  }}
                >
                  Back
                </button>
              </Link>

              <button
                className="btn btn-primary mb-4"
                type="submit"
                id="backBtn"
                onClick={updateProfile}
                style={{
                  width: "20%",
                }}
              >
                Save
              </button>
            </div>
          ) : (
            <div className="text-center">
              <button
                type="button"
                id="backBtn"
                className="btn btn-outline-primary mr-3 mb-4"
                style={{
                  width: "20%",
                }}
                disabled={!data.freeze ? true : false}
                onClick={handleActivateUser}
              >
                Activate
              </button>
              <button
                type="button"
                id="backBtn"
                className="btn btn-primary mb-4"
                style={{
                  width: "20%",
                }}
                disabled={data.freeze ? true : false}
                onClick={handleDeactivateUser}
              >
                Deactivate
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Modal */}
      <Modal
        show={modalIsOpen}
        onHide={() => setModalIsOpen(false)}
        centered
        id="shareModal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Personal Dictionary
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="container-fluid">
            <div className="input-group mb-3">
              <input
                type="text"
                name="addWord"
                value={addWord}
                className="form-control"
                placeholder="Add a new word"
                aria-label="Add a new word"
                onChange={(e) => setAddWord(e.target.value)}
                aria-describedby="basic-addon2"
              />
              <div className="input-group-append">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={addWordDictonary}
                >
                  <i className="fa fa-plus ft_sm"></i> ADD
                </button>
              </div>
            </div>
            <small>
              These words will no longer be flagged as misspellings.
            </small>
            <hr id="dictHr" />
            <div>
              {Dictionary.length > 0 &&
                Dictionary.map((word) => {
                  return (
                    <div
                      key={word}
                      className="d-flex justify-content-between"
                    >
                      <p className="ft_sm">
                        <i
                          className="fa fa-book text-primary mr-2"
                          aria-hidden="true"
                        ></i>
                        {word}
                      </p>

                      <p
                        className="point ft_sm"
                        onClick={() => removeWord(word)}
                      >
                        <i
                          className="fa fa-trash point mr-2 text-primary"
                          aria-hidden="true"
                        ></i>
                      </p>
                    </div>
                  );
                })}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Modal */}

      {/* Product Modal */}
      <Modal
        show={productModal}
        onHide={() => setProductModal(false)}
        centered
        id="shareModal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Blog Products
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="container-fluid">
            <div className="input-group mb-3">
              <input
                type="text"
                name="addWord"
                value={addProduct}
                className="form-control"
                placeholder="Add a new product"
                aria-label="Add a new product"
                onChange={(e) => setAddWord(e.target.value)}
                aria-describedby="basic-addon2"
              />
              <div className="input-group-append">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={addNewProduct}
                >
                  <i className="fa fa-plus ft_sm"></i> ADD
                </button>
              </div>
            </div>
            <hr id="dictHr" />
            <div>
              {products.length > 0 &&
                products.map((product) => {
                  return (
                    <div
                      key={product}
                      className="d-flex justify-content-between"
                    >
                      <p className="ft_sm">
                        <i
                          className="fa fa-product-hunt text-primary mr-2"
                          aria-hidden="true"
                        ></i>
                        {product}
                      </p>

                      <p
                        className="point ft_sm"
                        onClick={() => removeProduct(product)}
                      >
                        <i
                          className="fa fa-trash point mr-2 text-primary"
                          aria-hidden="true"
                        ></i>
                      </p>
                    </div>
                  );
                })}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Product Modal */}

      {/* ........ */}

      <div class="modal fade" id="ChangePassModal">
        <div
          class="modal-dialog modal-dialog-centered"
          style={{ width: "360px" }}
        >
          <div class="modal-content">
            {/* <!-- Modal Header --> */}
            <div class="modal-header">
              <div className="modal-title h4">Change Password</div>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>

            {/* <!-- Modal body --> */}
            <div class="modal-body">
              <div className="row ">
                <label style={{ marginLeft: "35%", marginTop: "3%" }}>
                  New Password
                </label>
              </div>
              <div className="row">
                <input
                  type="password"
                  className="form-control"
                  name="npassword"

                  value={npassword}
                  onChange={(e) => onChangeNPassword(e)}
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  style={{
                    width: "50%",
                    marginLeft: "25%",
                  }}
                />
              </div>
              <p
                className="p-1 text-center"
                style={{ color: "red", fontSize: "10px" }}
              >
                {errors}
                <br />
                {cerrors}
              </p>
              <div className="row">
                <label style={{ marginLeft: "30%", marginTop: "3%" }}>
                  Confirm New Password
                </label>
              </div>
              <div className="row">
                <input
                  type="password"
                  className="form-control"
                  name="cpassword"
                  value={cpassword}
                  onChange={(e) => onChangeCPassword(e)}
                  disabled={npassword == "" ? true : errors !== "" ? true : false}
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  style={{
                    width: "50%",
                    marginLeft: "25%",
                  }}
                />
              </div>
              <div className="row" style={{ marginTop: "8%" }}>
                <div className="col-md-12 text-center">
                  <button
                    type="button"
                    className="btn btn-primary"
                    autoComplete="current-password"
                    onClick={onClickResetPassword}
                    style={{
                      // color: "white",
                      // backgroundColor: "#1976d2",
                      width: "50%",
                    }}
                    disabled={disable}
                    data-dismiss="modal"
                  >
                    UPDATE PASSWORD
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ................ */}
    </div >
  );
}

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsNew); 
