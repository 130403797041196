import React, { useState } from "react";
// import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
// import MenuItem from "@material-ui/core/MenuItem";
// import Select from "@material-ui/core/Select";
import "semantic-ui-css/semantic.min.css";
// import { Dropdown } from "semantic-ui-react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const consoleVal = e => {
  console.log(e[0].value);
};
const options = [
  {
    text: "Newsletter",
    value: "newsletter",
    icon: "sticky note outline"
  },
  { text: "Alerts", value: "alert", icon: "bell outline" }
];

const DropDown = ({ onChange }) => {
  const classes = useStyles();
  const [value, setValue] = useState("");

  return (
    <div>
      <select
        class="browser-default custom-select dropdown-primary"
        value={value}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
      >
        <option value="" selected>
          Choose your option
        </option>
        <option value="newsletter" id="soflow-color">
          Newsletter
        </option>
        <option value="alert" id="soflow-color">
          Alert
        </option>
      </select>
    </div>
  );
};

export default DropDown;
