import React, { useEffect, useState } from "react";
import BlogCard from "./Cards/BlogCard";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Loader from "../utils/loader";
import { withRouter } from "react-router-dom";
import { Modal, Button } from "antd";
import page0 from "./../../src/static/Group 745.png";
import page1 from "./../../src/static/Group 746.png";
import {
  getAllBlogs,
  getBlogTags,
  getAllUsersBlogs,
  searchBlogByName,
  getBlogById,
  publishMyBlogs,
  unpublishMyBlogs,
  getBlogByTags,
  ShareableLink,
  deletePermanentlyBlog

} from "../services/blogServices";
import { getProfile } from "../services/userService";
import PostTabs from "./PostTabs";
import { toast, ToastContainer } from "react-toastify";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import _ from "lodash";
import "./Blog.css";
import HeadingB from "./Cards/HeadingB";
import NoBlog from "../static/noBlog.png";
import swal from "sweetalert";
import { camelCase, paramCase } from "change-case";
import InputBoxMui from "./ReusableComponents/InputBoxMui";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-prevent-tab-${index}`,
    "aria-controls": `scrollable-prevent-tabpanel-${index}`,
  };
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

function Blog({ history }) {
  const classes = useStyles();
  const [values, setValue] = React.useState(0);
  const [blogs, setBlogs] = useState([]);
  const [blogTags, setblogTags] = useState([]);
  const [filterBlog, setfilterBlog] = useState([]);
  const [loading, setLoading] = useState(true);
  const [value, setsearchvalue] = useState("");
  const [tabValue, setSwitchTabValue] = useState("0");
  const [yourPost, setYourPost] = useState(false);
  const [page, setPage] = useState(0);
  const [blogValue, setblogValue] = useState("all");
  const [LastPage, setLastPage] = useState(0);
  const [noMyBlogs, setnoMyBlogs] = useState(false);
  const [noYRBlogs, setnoYRBlogs] = useState(false);
  const [selectedTag, setSelectedTag] = useState("")

  const showCard = 5;
  let pages = 0;

  const steps = [
    {
      selector: ".reactour-3",
      content: "Click to get sharable link of this blog.",
      position: "bottom",
    },
  ];

  useEffect(() => {
    // getBlogs();
    getAllBlog(blogValue);
  }, [page, blogValue]);

  // handle publish

  const handlePublish = async (data) => {
    swal({
      title: "Send Blog for Admin Approval",
      text:
        "Are you sure ? You want to send the blog for Admin approval in-order to publish this blog",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willPublish) => {
      if (willPublish) {
        let blog = await getBlogById(data._id);
        console.log(blog);
        if (blog.status === 200) {
          if (blog.data.activeDoc === true) {
            let responseBlog = await publishMyBlogs(data._id);
            toast.success("Blog sent for approval!");
            console.log(responseBlog);
          }
          getAllBlog("my");
        }
      }
    });
  };

  // handle unpublish

  const handleUnpublish = async (data) => {
    swal({
      title: "Unpublish Blog",
      text: "Are you sure ? You want to unpublish this blog",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willunPublish) => {
      if (willunPublish) {
        let blog = await getBlogById(data._id);
        console.log(data);

        if (blog.status === 200) {
          if (blog.data.activeDoc === true) {
            let unpublishBlog = await unpublishMyBlogs(data._id);
            toast.success("Blog unpublished!");
            console.log(unpublishBlog);
          }
          getAllBlog("my");
        } else {
          toast.error("Sommething went wrong");
        }
      }
    });
  };

  // Get All Users Blogs
  const getAllBlog = async (value) => {
    setSelectedTag("Latest Blogs")
    console.log(showCard, page, value, "in valuee");
    setLoading(true);
    const res = await getAllUsersBlogs(showCard, page, value);
    console.log("res from getAllUsersBlogs", res);

    if (res.status === 200) {
      if (res.data.length == 0) {
        if (value === "my") {
          // const textWithoutHTML = res.data?.onlyDocumentData?.replace(/<[^>]*>/g, '');
          // const textWithNewlines = textWithoutHTML?.replace(/<br\s*\/?>/gi, '\n');
          // const formattedText = {
          //   blocks: [
          //     {
          //       key: "9ukr0",
          //       text: textWithNewlines,
          //       type: "unstyled",
          //       depth: 0,
          //       inlineStyleRanges: [],
          //       entityRanges: [],
          //       data: {}
          //     }
          //   ],
          //   entityMap: {}
          // };

          // const jsonText = JSON.stringify(formattedText);
          // console.log(jsonText,"jsonText");

          // res.data.onlyDocumentContent = jsonText;
          setnoMyBlogs(true);
          setLoading(false);
        } else if (value === "all") {
          setnoYRBlogs(true);
          setLoading(false);
        }
      } else {
        setBlogs(res.data);
        setfilterBlog(res.data);
        setLastPage(Math.ceil(res.pages - 1));
        setLoading(false);
        setnoMyBlogs(false);
      }
    } else {
      setLoading(false);
      toast.error(res.message);

      console.log("Oops something went worng");
    }
    getAllTags();
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log(values, "values");
  };
  console.log(values, "values2");
  const openTextEditor = () => {
    setLoading(true);
    history.push({
      pathname: `/grammarcheck`,
      data: { documentType: "blog" },
    });
    setLoading(false);
  };

  ///get all my blogs
  // const getBlogs = async () => {
  //   setLoading(true);
  //   let blogs = await getAllBlogs();
  //   console.log("blogs", blogs);
  //   if (blogs.status === 200) {
  //     setBlogs(blogs.data);
  //     setfilterBlog(blogs.data);
  //     setYourPost(true);
  //   } else {
  //     setLoading(false);
  //   }
  //   getAllTags();
  // };

  const getAllTags = async () => {
    let allTags = await getBlogTags();
    console.log("blogs", allTags);
    let tags = allTags.data;
    let freqMap = [];
    setLoading(true);

    tags.map((tag) => {
      tag = tag.toLowerCase();
      if (!freqMap[tag]) {
        freqMap[tag] = 0;
      }
      freqMap[tag] += 1;
    });
    console.log(freqMap);
    const object = Object.entries(freqMap).map(([key, val]) => ({
      name: key,
      index: val,
    }));
    console.log(object);
    const list = object
      .sort((a, b) => a.index - b.index)
      .map((exemple, index, array) => exemple.name)
      .reverse()
      .slice(0, 7);
    if (list) {
      let initial = ["Latest Blogs"];
      var merge = _.concat(initial, list);
      setblogTags(merge);
      // let blogs = await getAllBlogs();
      // let filterBlog = blogs.data.filter((ok) => {
      //   for (let i = 0; i < ok.tags.length; i++) {
      //     if (ok.tags[i] === list[0]) {
      //       return ok;
      //     }
      //   }
      // });
      // setfilterBlog(filterBlog);
    }
    setLoading(false);
    console.log(blogTags, "blogTags");
  };

  const searchBlog = async (tag, index) => {
    if (index !== 0) {

      console.log(index, tag);
      setSelectedTag(tag)
      setLoading(true);
      setfilterBlog([]);
      let blog = blogs;
      let alltags = blogTags;
      // let filterBlog = blog.filter((ok) => {
      //   for (let i = 0; i < ok.tags.length; i++) {
      //     if (ok.tags[i] === tag) {
      //       return ok;
      //     }
      //   }
      // });
      let filterBlog = await getBlogByTags(tag);
      setfilterBlog(filterBlog.data);
      // console.log(filterBlog);
      setLoading(false);
    } else {
      getAllBlog("all");
    }
  };

  const viewBlog = (blog) => {
    console.log(blogValue);
    history.push({
      pathname: `/blogpreview/${blog._id}`,
      state: { detail: blog, blogType: blogValue, blogTags: blog.tags },
      data: { documentType: "blog" },
    });
  };

  const filterOnChange = async (e) => {
    if (e.target.value === "") {
      setPage(0);
      setfilterBlog(blogs);
    }
    setsearchvalue(e.target.value);
  };

  const searchBlogName = async () => {
    setLoading(true);
    console.log(value);
    if (value === "") {
      setLoading(false);
      toast.error("Please enter value to search!");
    } else {
      let result = await searchBlogByName(value, blogValue);
      console.log(result);
      setLoading(false);
      setfilterBlog(result);
    }
  };
  console.log(blogValue, "blogValue");
  console.log(selectedTag, "selectedTag");
  const switchTab = (value) => {
    console.log(value, "inside value");
    setSwitchTabValue(value);
    if (value == "0") {
      setblogValue("all");
      setPage(0);
      setYourPost(false);
    } else if (value == "1") {
      setblogValue("my");
      setPage(0);
      setYourPost(true);
      setValue(0);
    }
  };
  console.log(tabValue, "tabValue");
  const [isShareModal, setIsShareModal] = useState(false)
  const [shareLink, setShareLink] = useState("")

  const handleShareModal = () => {
    setIsShareModal(false)
  }

  const handleCopy = () => {
    console.log("shareLink", shareLink)
    let link = shareLink
    if (link == "") {
      toast.warn(`You don't have text, to copy`);
      return;
    }
    navigator.clipboard.writeText(link).then(
      function () {
        console.log("Async: Copying to clipboard was successful!");
        if (link) toast.info("Link is Copied!");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  }

  const shareableLink = async (data) => {
    console.log("shareableLink", data)
    let link = await ShareableLink(data._id, paramCase(data.documentName));

    const el = document.createElement("textarea");
    el.value = link;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    console.log(el.value);
    setShareLink(el.value)
    setIsShareModal(true)

    // window.open(link, "_blank");
  };

  const permanentDeleteBlog = async (blog) => {
    swal({
      title: "Permanent Deletion",
      text: "Do you want to permanently delete this blog ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (permanentDelete) => {
      if (permanentDelete) {
        setLoading(true);
        let data = {
          _id: blog._id,
        };
        let res = await deletePermanentlyBlog(data);
        if (res.status !== 200) {
          setLoading(false);
          toast.error("Blog Deletion Failed !");
        }
        else {
          toast.success("Blog deleted permanently!");
          setLoading(false)
          getAllBlog("my");
        }

      } else {
        swal("Your Blog is safe!");
      }
    });
  };

  console.log(blogTags.length,"blogTags");
  return (
    <>
      <ToastContainer />
      <Loader loading={loading} />
      <div style={{ backgroundColor: "white",boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px", width: "99%", borderRadius: "15px", padding: "5px", marginBottom : "14px" }}>
        <div
          style={{
            width: "100%",
            borderRadius: "7px",
            borderBottom: noMyBlogs || tabValue == "1" ? "none" : "3px solid gray"
          }}>
          <PostTabs handleChangeTab={switchTab} openEditor={openTextEditor} filterOnChange={filterOnChange} searchValue={value} searchBlogName={searchBlogName} />
        </div>
        {tabValue == "0" && (
          <div
            style={{
              // marginTop: "10px",
              width: "100%",
            }}
          >
            {/* <AppBar position="static"> */}
            <Box sx={{
              maxWidth: { xs: 320, sm: 480, md: 1150 },
              // bgcolor: 'background.paper'
              bgcolor: 'white',
              // border: "1px solid black"
            }}>

              <Tabs
                value={values}
                onChange={handleChange}
                indicatorColor="white"
                textColor="primary"
                aria-label="scrollable force tabs example"
                scrollButton
                variant="scrollable"
                // style={{border: "1px solid blue"}}
              >

                {blogTags.map((tag, index) => (
                  <Tab
                    label={tag}
                    key={index}
                    {...a11yProps(index)}
                    // disabled={tabDisable}
                    onClick={() => searchBlog(tag, index)}
                    sx={{
                      height: 5, // Minimum height for responsiveness
                    }}
                    style={{
                      backgroundColor: selectedTag === tag ? "#AFDBF5" : "white",
                      border: "1px solid dodgerblue",
                      color: selectedTag === tag ? "white" : "dodgerblue",
                      margin: "7px",
                      outline: "none",
                      borderRadius: "7px",
                      padding: "0px"
                    }}
                  />
                ))}
              </Tabs>
            </Box>
            {/* </AppBar> */}
          </div>

        )
        // : (
        //   <div className="row" style={{ marginBottom: "2%" }}></div>
        // )
      }
      </div>
      {/* {blogs.length > 0 ? ( */}

      {tabValue === "0" && noYRBlogs ? (
        <div className="text-center mt-4">
          <img
            className=" mt-5"
            style={{ height: "150px" }}
            src={NoBlog}
            alt="404"
          ></img>
          <HeadingB className="alertHead" text="No Blog Published Yet..!" />
        </div>
      ) : tabValue == "1" && noMyBlogs ? (
        <div className="text-center mt-4">
          <img
            className=" mt-5"
            style={{ height: "150px" }}
            src={NoBlog}
            alt="404"
          ></img>
          <HeadingB className="alertHead" text="No Blogs Found..!" />
        </div>
      ) : (
        <>
          <hr
            style={{ marginLeft: "2%", marginRight: "6%", marginTop: "-1%" }}
          />
          {filterBlog?.map((blog, index) => (
            console.log(blog, "blog"),
            <BlogCard
              key={index}
              data={blog}
              showBlog={() => viewBlog(blog)}
              mypost={yourPost}
              blogunpub={() => handleUnpublish(blog)}
              blogPub={() => handlePublish(blog)}
              shareableLink={shareableLink}
              search={searchBlog}
              permanentDelete={() => permanentDeleteBlog(blog)}
            />
          ))}
          <hr />

          <div className="row">
            {page > 0 && (
              <div className="col-md-6" style={{ padding: "2%" }}>
                <div
                  className="row point"
                  style={{ padding: "2%" }}
                  onClick={() => setPage(page - 1)}
                >
                  <img src={page0} style={{ height: "50px", width: "50px" }} />{" "}
                  <h6 style={{ paddingTop: "17px" }}>Latest Posts</h6>
                </div>
              </div>
            )}
            {page >= 0 && page < LastPage && (
              <div className="col-md-6 ml-auto" style={{ padding: "2%" }}>
                <div
                  className="point"
                  style={{ padding: "2%", float: "right" }}
                  onClick={() => setPage(page + 1)}
                >
                  <span style={{ paddingTop: "17px" }}>Older Posts</span>{" "}
                  <img
                    src={page1}
                    alt="older post"
                    style={{ height: "50px", width: "50px" }}
                  />
                </div>
              </div>
            )}
          </div>
        </>
      )}


      <Modal
        title="Share link"
        // style={{ width: "65%" }}
        width={"55%"}
        open={isShareModal}
        // onOk={handleShareModal}
        onCancel={handleShareModal}
        footer={null}
      // footer={[
      //   <div className="d-flex justify-content-end gap-1">
      //     <Button className="btn btn-secondary btn-sm" type="secondary"
      //       onClick={handleShareModal}
      //     >
      //       Cancel
      //     </Button>,
      //   </div>
      // ]}
      >

        <div className="row"
        >
          <div className="col-md-10"
          >
            <InputBoxMui
              fullWidth
              value={shareLink}
              disabled={true}
            />
          </div>
          <div className="col-md-2">
            <Button
              className="btn btn-secondary btn-sm"
              style={{
                // border: "none",
                // background: "transparent",
                // minWidth: '20px',
                // padding: '4px',
                // color: 'black'
              }}
              onClick={handleCopy}
            >
              Copy
            </Button>
          </div>
        </div>
      </Modal>

    </>
  );
}
export default withRouter(Blog);
