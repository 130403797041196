import {
  apiUrl,
  herokuUrl
} from "./config.json";
import httpService from "./httpService.js";
let apiEndpoint = "";
let apiEndpoint2="";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = `${apiUrl}/citations`;
  apiEndpoint2 = `${apiUrl}/detectors`;
} else {
  apiEndpoint = `${herokuUrl}/citations`;
  apiEndpoint2 = `${apiUrl}/detectors`;
}


export async function insertCites() {
  const data = await httpService.post(`${apiEndpoint}/insertCite`);
  console.log(data)
  return data;
}
/////////

////////Duplication service////////////////////
export async function Duplicate(id){
  const data = await httpService.post(`${apiEndpoint}/duplicateCitation`,{ citationId: id })
  return data
}
export async function GetOnecitation(id){
  const data = await httpService.post(`${apiEndpoint}/getoneCitationDetails`,{ citationId: id })
  return data
}
/////////////////////////////////Duplication end//////////////

export async function getCheckCitationWebpage(text, citationStyle, sourceitem,userId,isCoAuthor) {
  const allowedTextStyles = [
    "AMA 10th edition",
    "American Chemical Society",
    "APA 11th edition",
    "APA 6th edition",
    "APA 7th edition",
    "Chicago author"
];

const finalTextStyle = allowedTextStyles.includes(citationStyle) ? citationStyle : "AMA 10th edition";
  const data = await httpService.post(`${apiEndpoint2}/checkCitationsWebpage`, {
    text,
    citationStyle: finalTextStyle,
    sourceItem: sourceitem,
    userId,isCoAuthor,
  });
  return data;
}

export async function getCheckCitationJournalArticle(text, citationStyle, sourceitem,userId,isCoAuthor) {
  const allowedTextStyles = [
    "AMA 10th edition",
    "American Chemical Society",
    "APA 11th edition",
    "APA 6th edition",
    "APA 7th edition",
    "Chicago author"
];

const finalTextStyle = allowedTextStyles.includes(citationStyle) ? citationStyle : "AMA 10th edition";
  const data = await httpService.post(`${apiEndpoint2}/checkCitationsJournalArticle`, {
    text,
    citationStyle: finalTextStyle,
    sourceItem: sourceitem,
    userId,isCoAuthor
  });
  return data;
}

export async function getCheckCitationBook(text, citationStyle, sourceitem,userId,isCoAuthor) {
  const allowedTextStyles = [
    "AMA 10th edition",
    "American Chemical Society",
    "APA 11th edition",
    "APA 6th edition",
    "APA 7th edition",
    "Chicago author"
];

const finalTextStyle = allowedTextStyles.includes(citationStyle) ? citationStyle : "AMA 10th edition";
  const data = await httpService.post(`${apiEndpoint2}/checkCitationsBook`, {
    text,
    citationStyle: finalTextStyle,
    sourceItem: sourceitem,
    userId,isCoAuthor
  });
  return data;
}

export async function getCitationDetails(data) {
  const response = await httpService.post(`${apiEndpoint}/getCitationDetails`,{data});
  return response;
}

export async function getAddCitation(formData,citationStyle, selectedValue) {
    let sourceType = ""
    if(selectedValue === 0){sourceType="Webpage"}
          else if (selectedValue === 1){sourceType="Journal Article"}
          else if (selectedValue === 2){sourceType="Book"}
          else{sourceType = selectedValue}


  const data = await httpService.post(`${apiEndpoint}/addCitation`, {
    title: formData.title,
    description: formData.description,
    contributors: formData.contributors,
    websiteName: formData.websiteName,
    url: formData.url,
    email:formData.email,
    publicationDate: formData.publicationDate,
    accessDate: formData.accessDate,
    textStyle: citationStyle,
    sourceType: sourceType,
    doi: formData.doi,
    journalName: formData.journalName,
    edition: formData.edition,
    publisher: formData.publisher,
    articleNumber: formData.articleNumber,
    volumeNumber: formData.volumeNumber,
    issueNumber: formData.issueNumber,
    page: formData.page,
    libraryDatabase: formData.libraryDatabase,
    ISBN: formData.ISBN,
    ISSN: formData.ISSN
   
  });
  return data;
}

export async function getAddCitation2(websiteData, email) {
  
  const data = await httpService.post(`${apiEndpoint}/addCitation`, {
    title: websiteData.title,
    description: websiteData.description,
    websiteName: websiteData.websiteName,
    contributors:websiteData.contributors,
    publicationDate: websiteData.publicationDate,
    fullCitation: websiteData.fullCitation,
    textStyle: websiteData.citationStyle,
    url: websiteData.url,
    sourceType: websiteData.sourceType, 
    email: email,
    inTextCitation: websiteData.inTextCitation,
    doi: websiteData.doi,
    journalName: websiteData.journalName,
    edition: websiteData.edition,
    publisher: websiteData.publisher

  });
  return data;
}

export async function deleteCitation(id) {
  const response = await httpService.post(`${apiEndpoint}/deleteCitation2`, {
    id,
  });
  return response;
}

export async function updateCitationDetails(citationId, updatedDetails) {
  const data = await httpService.post(`${apiEndpoint}/updateCitationDetails`, {
    citationId,
    updatedDetails
  });
  return data;
}


export async function getCitationStyleChange(title,year,authorFirstname,authorLastname,sourceType, textStyle) {
  const postData = {title,year,authorFirstname,authorLastname,sourceType,textStyle };
  const data = await httpService.post(`${apiEndpoint2}/getCitationStyleChange`,postData);
  return data;
}


export async function deleteSelectedCitations(ids) {
  const response = await httpService.post(`${apiEndpoint}/deleteSelectedCitations`, {
    ids,
  });
  return response;
}
////////


export async function getStyle(styleDetails) {
  const data = await httpService.post(`${apiEndpoint}/getStyle`, {
    "data": styleDetails
  });
  return data;
}

export async function savecitations(styleDetails, id) {
  const data = await httpService.post(`${apiEndpoint}/savecitations`, {
    "data": styleDetails,
    projectId: id
  });
  return data;
}

export async function getMyCitations(projectId) {
  const data = await httpService.get(`${apiEndpoint}/getCitationsByUser/${projectId}`);
  return data;
}
export async function changeCitationStyle(data) {
  const response = await httpService.post(`${apiEndpoint}/changeStyle`, data);
  return response;
}

export async function updateCitations(data) {
  const response = await httpService.post(`${apiEndpoint}/updateCitations`, data);
  return response;
}



export async function deleteMyCitations(value, projectId) {
  console.log(value)
  const response = await httpService.post(`${apiEndpoint}/deleteCitations`, {
    id: value,
    projectId: projectId
  });
  return response;
}

export async function createProject(data) {
  const response = await httpService.post(`${apiEndpoint}/createProject`, data);
  return response;
}

export async function getAllProjects() {
  const response = await httpService.get(`${apiEndpoint}/getAllProjects`);
  return response;
}

export default {
  Duplicate,
  GetOnecitation,
  deleteSelectedCitations,
  updateCitationDetails,
  getCitationStyleChange,
  deleteCitation,
  getAddCitation2,
  getCitationDetails,
  getAddCitation,
  getCheckCitationWebpage,
  getCheckCitationJournalArticle,
  getCheckCitationBook,
  getStyle,
  savecitations,
  insertCites,
  getMyCitations,
  changeCitationStyle,
  updateCitations,
  deleteMyCitations,
  createProject,
  getAllProjects,
  

}