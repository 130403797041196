
export const openLeftNavbar = () => {
    return {
        type: 'OPEN_LEFT_NAVBAR'
    };
};

export const closeLeftNavbar = () => {
    return {
        type: 'CLOSE_LEFT_NAVBAR'
    };
};

export const openRightModal = () => {
    return {
        type: 'OPEN_RIGHT_MODAL'
    };
};

export const closeRightModal = () => {
    return {
        type: 'CLOSE_RIGHT_MODAL'
    };
};

export const closeLowerModal = () => {
    return {
        type: 'CLOSE_LOWER_MODAL'
    };
};

export const closeRightModalAndOpenLeftNavbar = () => {
    return {
        type: 'CLOSE_RIGHT_MODAL_AND_OPEN_LEFT_NAVBAR'
    };
};

export const openRightModalAndCloseLeftNavbar = () => {
    return {
        type: 'OPEN_RIGHT_MODAL_AND_CLOSE_LEFT_NAVBAR'
    };
};

export const closeLowerModalAndOpenLeftNavbar = () => {
    return {
        type: 'CLOSE_LOWER_MODAL_AND_OPEN_LEFT_NAVBAR'
    };
};

export const openLowerModalAndCloseLeftNavbar = () => {
    return {
        type: 'OPEN_LOWER_MODAL_AND_CLOSE_LEFT_NAVBAR'
    };
};