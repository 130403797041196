import * as React from 'react';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
// import StyledInputBox from './Reusable_Components/StyledInputBox.jsx'


const theme = createTheme({
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiInputBase-input': {
                        color: '#646464',
                        border: 'none',
                        // boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
                        // transition: 'box-shadow 0.3s',
                        fontFamily: 'Montserrat, sans-serif',
                        fontWeight: '500',
                        // fontSize: '14px',
                        fontSize: '0.9rem',
                        '&:focus': {
                            color: '#646464',
                            outline: 'none',
                            boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.2)',
                        },
                    },
                    '& .MuiInputLabel-root': {
                        color: '#646464',
                        fontFamily: 'Montserrat, sans-serif',
                        fontWeight: "500",
                        // fontSize: '14px',
                        fontSize: '0.9rem',
                        "&.Mui-focused": {
                            color: "#6E6E6E",
                        },
                        // '&:focus': {
                        //     color: '#646464',
                        // },
                    },
                    '& .MuiInputBase-multiline': {
                        // boxShadow: "none"
                        // minHeight: '10px', // Set a minimum height for multiline
                    },

                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    // borderColor: '#646464', // Change the outline border color
                    // borderWidth: '1px', // Add a border width
                    '&:focus': {
                        // borderColor: '#646464',
                    },
                },
                input: {
                    '&:focus': {
                        boxShadow: "none !important"
                    }
                },
                inputSingle: {
                    // border: 'none', // Remove the inner border for single-line input
                    // borderRadius: '8px', // Add border radius
                    // padding: '10px', // Add padding
                },
                inputMultiline: {
                    border: 'none', // Remove the inner border for multiline input
                    // borderRadius: '8px', // Add border radius
                    // padding: '10px', // Add padding
                    // padding: "none"
                    boxShadow: "none"
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
                    transition: 'box-shadow 0.3s',
                }
            }
        }
    },
});

const InputBoxMui = (
    {
        id, value, name, onChange, label, width, type,
        multiline, rows, onBlur, className, variant,
        disabled, required, error, helperText,
        maxWidth, fullWidth, placeholder, defaultValue,
        InputProps,autoComplete,onSelect
    }
) => {
    const handleKeyDown = (event) => {
        let element = event.target.nodeName
        if (event.key === 'Enter' && element !== "TEXTAREA") {
            console.log("handleKeyDown", event.key,event)
            event.preventDefault()
        }
    }

    const inputClasses = multiline ? "inputMultiline" : "inputSingle"

    return (
        <ThemeProvider theme={theme}>
            <Box
                component="form"
                sx={{
                    m: 1,
                    width: `${width}`,
                    maxWidth: `${maxWidth}`,
                    margin: "unset",
                    background: "white",
                }
                }
                noValidate
                autoComplete="off"
            >
                <TextField
                    className={className}
                    id={id}
                    name={name}
                    value={value}
                    onChange={onChange}
                    onKeyDown={handleKeyDown}
                    onBlur={onBlur}
                    label={label}
                    type={type}
                    multiline={multiline}
                    rows={rows}
                    disabled={disabled}
                    required={required}
                    autoComplete={autoComplete}
                    onSelect={onSelect}
                    // error={error}
                    // helperText={helperText}
                    size="small"
                    // variant="outlined"
                    // variant={variant}
                    fullWidth={fullWidth}
                    placeholder={placeholder}
                    // defaultValue={defaultValue}
                    InputProps={{ classes: { inputSingle: inputClasses } }}
                />
            </Box>
        </ThemeProvider>
    );
}

export default InputBoxMui