
export const setSocialMediaClick = () => {
    console.log("inside SOCIAL LOGIN")
    return {
        type: 'SET_SOCIAL_MEDIA_LOGIN'
    };
};

export const setManuallyClick = () => {
    console.log("inside MANUALLY LOGIN")
    return {
        type: 'SET_MANUALLY_LOGIN'
    };
};
