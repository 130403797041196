const getHtml = obj => {
  let html = `<!DOCTYPE html>
  <meta charset="utf-8">
  <script src="https://d3js.org/d3.v4.js"></script>  
  <style>
      .containermain{
          margin: 0px 10%;
      }
  
      body{
          font-family: Arial, Helvetica, sans-serif;
      }
      .clearfix {
        overflow: auto;
      }
      .center{
        text-align: center;
      }
      .margin-new{
        margin-top: 35%;
      }
      .right{
        float: right;
      }
      .left{
        float: left;
      }
      .m-0{
        margin: 0px;
      }
      .add{
        font-size: 10px;
        margin-bottom: 5px;
      }
      table {
        border-collapse: collapse;
        width: 100%;
      }
      
      td {
        text-align: center;
        padding: 8px;
      }
      th {
        text-align: center;
        padding: 8px;
        background-color: #1976d2;
      }
      tr:nth-child(even) {
        background-color: #dddddd;
      }
      .top{
        margin-top: 60px;
      }
      .leftm{
        margin-left: 38%;
		
      }
	  
      .topm{
        margin-top: -140px;
      }
	  
	  .topmargin{
		  margin-top: -3%;
	  }
	  
	  .topmar{
		  margin-top: -3%;
	  }

      .topmc{
        margin-top: -8%;
      }
    
      .botmc{
        margin-bottom: 10px;
      }
      
	  .topmargin{
		  margin-top: -3%;
	  }
	  
	  .topmar{
		  margin-top: -3%;
	  }
      .head{
        font-size: 12px;
      }
      .addadd{
        font-size: 10px;
        margin-right: 5%;
		margin-bottom: 2%;
      }
      .dot{
        height: 10px;
        width: 10px;
        background-color: #f14c4c;
        border-radius: 50%;
        display: inline-block;
        margin-right: 1%;
      }
      .dotone{
        height: 10px;
        width: 10px;
        background-color: #27beb8;
        border-radius: 50%;
        display: inline-block;
        margin-right: 1%;
      }
      .dottwo{
        height: 10px;
        width: 10px;
        background-color: #ff9500;
        border-radius: 50%;
        display: inline-block;
        margin-right: 1%;
      }
      .dotthree{
        height: 10px;
        width: 10px;
        background-color: #3ba500;
        border-radius: 50%;
        display: inline-block;
        margin-right: 1%;
      }
      .dotfour{
        height: 10px;
        width: 10px;
        background-color: #0870e7;
        border-radius: 50%;
        display: inline-block;
        margin-right: 1%;
      }
      .dotfive{
        height: 10px;
        width: 10px;
        background-color: #a3a0fb;
        border-radius: 50%;
        display: inline-block;
        margin-right: 1%;
      }
      </style>
  <body>
  
  <div class="containermain">
    <div class="clearfix">
      <div class="center">
        <h5 class="center">${obj.document.documentName}</h5>
      </div>
    </div>
   
    <hr/>
      
	
	
 
    <div class="clearfix margin-new">
      <div class="center">
        
      </div>
    </div>
      <div class="clearfix topm">
        <div class="left">
          <h6>Word Count</h6>
          <p class="add">
            <b>Characters</b>   ${obj.score.characterCount} <br/>
            <b>Words</b>   ${obj.score.wordLength} <br/>
            <b>Sentences</b>   ${obj.score.sentenceLength} 
          </p>
        </div>
        <div class="right">
          <div class="top">
            <p class="add">
              <b>Reading Time:</b> ${obj.score.readingTime}
            </p>
            <p class="add">
              <b>Speaking Time:</b> ${obj.score.speakingTime}
            </p>
          </div>
        </div>
      </div>
    <br/>
    <hr/>
    <br/>
    <div class="clearfix">
      <div class="left">
        <h6>Readability</h6>
        <p class="add m-0">Your text is likely to be understood by a reader who 
          has at least a ${obj.score.schoolLevel} grade education (age 12) and 
          should be easy for most adults to read</p>
      </div>
      <div class="right">
      </div>
    </div>
    <br/>
    <hr/>
    ${ obj.score.errorName.length < 1 ? '' : (
      '<div class="clearfix">'+
        '<div class="left">'+
          '<h6 class="center">Grammar </h6>'+
        '</div>'+
        '<table>'+
          '<tr>'+
            '<th class="head">Error Name</th>'+
            '<th class="head">Error Count</th>'+
          '</tr>'
          ) }
  
          ${ obj.score.errorName.map((item, index) => (
              '<tr><td class="add">'+ item +'</td>'+
              '<td class="add">' + obj.score.errorCount[index] + '</td></tr>'
          )) }
  
          ${ obj.score.errorName.length < 1 ? '' : (
        '</table>'+
      '</div>'
    ) }

  </div>

  <script>

    // *******************************Doughnut 1***********************************
    // set the dimensions and margins of the graph
    var width = 150
        height = 150
        margin = 30
    // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
    var radius = Math.min(width, height) / 2 - margin
    // append the svg object to the div called 'my_chart'
    var svg = d3.select("#my_chart")
      .append("svg")
        .attr("width", width)
        .attr("height", height)
      .append("g")
        .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");
    // Create dummy data
    var data = {a: ${obj.score.plagScore}, b: ${obj.score.unplagscore}}
    // set the color scale
    var color = d3.scaleOrdinal()
      .domain(data)
      .range(["#f14c4c", "#E5E7E9"])
    // Compute the position of each group on the pie:
    var pie = d3.pie()
      .value(function(d) {return d.value; })
    var data_ready = pie(d3.entries(data))
    // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
    svg
      .selectAll('whatever')
      .data(data_ready)
      .enter()
      .append('path')
      .attr('d', d3.arc()
        .innerRadius(70)         // This is the size of the donut hole
        .outerRadius(radius)
      )
      .attr('fill', function(d){ return(color(d.data.key)) })
      .style("opacity", 1)
  // *******************************Doughnut 2***********************************
      // set the dimensions and margins of the graph
      var widthTwo = 150
          heightTwo = 150
          marginTwo = 30
  
      // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
      var radiusTwo = Math.min(widthTwo, heightTwo) / 2 - marginTwo
  
      // append the svg object to the div called 'second_chart'
      var svgTwo = d3.select("#second_chart")
        .append("svg")
          .attr("widthTwo", widthTwo)
          .attr("heightTwo", heightTwo)
          .attr("align","center")
        .append("g")
          .attr("transform", "translate(" + widthTwo / 2 + "," + heightTwo / 2 + ")");
  
      // Create dummy data
      
      var data = {
        a: ${obj.score.totalError || 0}, 
        b: ${obj.score.userAction.Accept || 0}, 
        c: ${obj.score.userAction.Reject || 0}, 
        d: ${obj.score.noOfWords || 0}
      }
  
      // set the color scale
      var color = d3.scaleOrdinal()
        .domain(data)
        .range(["#27beb8", "#ff9500", "#3ba500", "#0870e7"])
  
      // Compute the position of each group on the pie:
      var pie = d3.pie()
        .value(function(d) {return d.value; })
      var data_ready = pie(d3.entries(data))
  
      // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
      svgTwo
        .selectAll('whatever')
        .data(data_ready)
        .enter()
        .append('path')
        .attr('d', d3.arc()
          .innerRadius(70)         // This is the size of the donut hole
          .outerRadius(radiusTwo)
        )
        .attr('fill', function(d){ return(color(d.data.key)) })
        .style("opacity", 1)
  // *******************************Doughnut 3***********************************
        // set the dimensions and margins of the graph
        var widthThree = 150
            heightThree = 150
            marginThree = 30
    
        // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
        var radiusThree = Math.min(widthThree, heightThree) / 2 - marginThree
    
        // append the svg object to the div called 'third_chart'
        var svgThree = d3.select("#third_chart")
          .append("svg")
            .attr("widthThree", widthThree)
            .attr("heightThree", heightThree)
          .append("g")
            .attr("transform", "translate(" + widthThree / 2 + "," + heightThree / 2 + ")");
    
        // Create dummy data
        var data = {a: ${obj.score.performance}, b: ${100 - obj.score.performance}}
    
        // set the color scale
        var color = d3.scaleOrdinal()
          .domain(data)
          .range(["#a3a0fb", "#E5E7E9"])
    
        // Compute the position of each group on the pie:
        var pie = d3.pie()
          .value(function(d) {return d.value; })
        var data_ready = pie(d3.entries(data))
    
        // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
        svgThree
          .selectAll('whatever')
          .data(data_ready)
          .enter()
          .append('path')
          .attr('d', d3.arc()
            .innerRadius(70)         // This is the size of the donut hole
            .outerRadius(radiusThree)
          )
          .attr('fill', function(d){ return(color(d.data.key)) })
          .style("opacity", 1)

  // ****************************************************************

</script>
  </body>
  </html>
      `;
  return html;
};

module.exports = {
  getHtml
};
