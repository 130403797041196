import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import SelectDropdownMui from "./SelectDropdownMui";
import DatePickerMui from "./DatePickerMui";
import { useSelector } from "react-redux";

const ApexChartsComp = (props) => {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const { leftNavbarOpen } = useSelector(state => state.handleModelAndSideBavBar);

    // Update windowWidth on resize
    useEffect(() => {
      const handleResize = () => setWindowWidth(window.innerWidth);
   
      window.addEventListener('resize', handleResize);
      // if(window.innerWidth <768){
      //   setSidebarOpen(!isSidebarOpen)
      // }
   
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    console.log("ApexChartsCompProps", props)
    const generateColors = (length) => {
        const colors = [];
        for (let i = 0; i < length; i++) {
            colors.push(getRandomColor());
        }
        return colors;
    };

    const getRandomColor = () => {
        return "#" + Math.floor(Math.random() * 16777215).toString(16);
    };

    const options = {
        chart: {
            height: 350,
            type: "line",
            stacked: false,
        },
        fill: {
            colors: ["#678ffc", "#34eb8f", "#ffb200"],
        },
        colors: ["#678ffc", "#34eb8f", "#ffb200"],
        // colors: generateColors(props.xAxisList.length),
        xaxis: {
            categories: props.xAxisList.map(label => label.length > 8 ? label.substring(0, 8) + "..." : label),
        },
        yaxis: [
            {
                axisBorder: {
                    show: true,
                    color: "#008FFB",
                },
                // labels: {
                //     formatter: function (value) {
                //         return Math.round(value);
                //     },
                // },
                forceNiceScale: true,
            },
        ],
        dataLabels: {
            enabled: false
        },
        tooltip: {
            fixed: {
                enabled: true,
                position: "topLeft",
                offsetY: 30,
                offsetX: 60,
            },
        },
    };

    const series = [
        {
            name: props.cmp1,
            type: "column",
            data: props.bar1,
        },
        {
            name: props.cmp2,
            type: "column",
            data: props.bar2,
        },
        {
            name: props.cmp3,
            type: "line",
            data: props?.linedata,
        },
    ];

    return (
        <div className="app"
            style={{
                backgroundColor: "#ffffff",
                borderRadius: "8px",
                padding: "2px",
                height:windowWidth < 768 ? "25rem" : "20rem",
                // width:leftNavbarOpen ? "74vw" : "50vw"
            }}
        >
            <div className="row mt-2">
                <div className="col-md-7">
                    <h6 className='p-2'
                        style={{
                            color: "#171717",
                            fontFamily: "Poppins, sans-serif",
                            fontWeight: 600,
                            fontSize: "0.9rem"

                        }}
                    >{props.chartHeading}</h6>
                </div>
                <div className="col-md-5 d-flex justify-content-end pr-4">
                    {props.isSelectDropdownShown &&
                        // <SelectDropdownMui
                        //     optionsList={props.barChartSelectOptions}
                        //     onChange={props.barChartHandleChange}
                        //     value={props.barChartValue}
                        //     selectLabel={props.barChartSelectLabel}
                        //     disabled={props.selectDropdownDisabled}
                        // />

                        <DatePickerMui
                            onChangeYear={props.onChangeBarChartYear}
                        />
                    }
                </div>
            </div>
            {props.bar1.length > 0 ?
                <div className="row">
                    <div className="col-md-12 apexCharts"
                    >
                        <div className="container-fluid"
                            style={{ overflowX: "scroll", overflowY: "hidden" }}
                        >
                            <div
                                style={{ pointerEvents: "none" }}
                            >
                                <Chart
                                    options={options}
                                    series={series}
                                    type="bar"
                                    id="charts"
                                    // height={200}
                                    height={"185vh"}
                                    // height={"175rem"}
                                    style={{ backgroundColor: "white" }}
                                />

                                {props.bar1.length > 0 && props.cmp2 == "" &&
                                    <p
                                        style={{ textAlign: "center", fontWeight: "bold", paddingBottom: "5px" }}
                                    >{props.cmp1}</p>}
                            </div>
                        </div>

                    </div>
                </div>
                :
                <div
                    className="p-3"
                    style={{ fontWeight: "bold" }}>
                    No data available
                </div>
            }
        </div>
    );
};

export default ApexChartsComp;
