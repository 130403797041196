import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DefaultBlogImage from "../../static/defaultBlogThree.jpg";
import StarRatings from "react-star-ratings";
import "./BlogCard.css";
import { getTextFromEditorState } from "../../utils/getTextFromEditorState";
import { withStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import { DownloadBlogOptionModal } from "../MyDocumentsCardsModal";

import {
  camelCase,
  capitalCase,
  constantCase,
  dotCase,
  headerCase,
  noCase,
  paramCase,
  pascalCase,
  pathCase,
  sentenceCase,
  snakeCase,
} from "change-case";
import { FacebookIcon, FacebookShareButton } from "react-share";
import { ShareableLink } from "../../services/blogServices";
import Tooltip from "@material-ui/core/Tooltip";
import { imgUrl } from "../../services/config.json";
import { connect, useSelector } from "react-redux";
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import dateFn from "date-fn";
import Avatar from "@material-ui/core/Avatar";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'blue',
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}))(Tooltip);

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    height: 215,
    marginTop: 20,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
});

const CustomTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'blue',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);



function getTextFromEditorStateNew(onlyDocumentContent, sliceIt) {
  console.log("onlyDocumentContent_", onlyDocumentContent, sliceIt)
  const processedHtmlContent = onlyDocumentContent.replace(/<br\s*\/?>/gi, '\n').replace(/<div\s*\/?>/gi, '\n');
  // Count words
  const tempElement = document.createElement('div');
  tempElement.innerHTML = processedHtmlContent;
  const textContent = tempElement.innerText;
  if (sliceIt) {
    return textContent.slice(0, 250);
  }
  return textContent;
}

export default function BlogCard({
  data,
  showBlog,
  search,
  mypost,
  blogPub,
  blogunpub,
  shareableLink,
  isAdmin,
  dialogBox,
  permanentDelete,
  approveBlog,
}) {
  let moreContent
  const classes = useStyles();
  const userDetails = useSelector((user) => user)
  console.log("blogcarddata", data);
  let content = getTextFromEditorStateNew(data.onlyDocumentContent, true);
  console.log(content, "content");
  let link = ShareableLink(data._id, paramCase(data.documentName));
  const mdate = new Date(data.date);
  let blogDate = new Date(mdate).toString().split(" ").splice(1, 3).join(" ");
  console.log("userDetails__", userDetails)
  let currentUser = userDetails.user.currentUser.username
  const history = useHistory();
  
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false)
  // let blogDate =
  //   new Date(mdate).getDate() +
  //   "/" +
  //   parseInt(new Date(mdate).getMonth() + 1) +
  //   "/" +
  //   new Date(mdate).getFullYear();

  // console.log(blogDate);
  useEffect(() => {
    console.log(data, "data5");
  }, [])
  const newCont = (data) => {
    console.log(data, "data5");
    let newContent = data + "...";
    let length = 100 - data.length;
    for (let i = 0; i < length / 2; i++) {
      newContent = newContent + " \xa0";
    }
    return newContent;
  };



  const handleDownload = (data) => {
    // console.log("handleDownload", data)
    // let docName = data.documentName
    // let docContent = data.onlyDocumentContent
    // let docText = docContent
    // let finalContent = `${docName} \n\n ${docText}`
    // const blob = new Blob([finalContent], { type: 'text/plain' });
    // const anchor = document.createElement('a');
    // anchor.href = URL.createObjectURL(blob);
    // // anchor.download = 'text_content.doc';
    // anchor.download = `${docName}.doc`
    // anchor.click();
    // URL.revokeObjectURL(anchor.href);
    setIsDownloadModalOpen(true)
  }

  const closeDownloadModal = () => {
    setIsDownloadModalOpen(false)
  }

  const windowWidth = window.innerWidth;
  const isSmallScreen = windowWidth <= 768;

  const viewAllTags = () => {
    history.push("/docTags");
  }

  return (
    <div className="mt-2">
      <div className="row d-flex justify-content-between" style={{ padding: "0px 24px 0px 14px", marginTop: "14px" }}>

        <div className="d-flex align-items-center justify-content-between p-2  col-sm-12 bg-light" style={{ border: "1px solid gray", borderRadius: "10px" }}>
          <div className="col-md-5 d-flex gap-3 align-items-center">
            <h2 className="mb-0">{data.documentName}</h2>
            <div className="ml-3 mb-0">
              <StarRatings
                rating={data.ratings}
                starRatedColor="orange"
                starDimension="13px"
                borderRadius="2px"
                starSpacing="0px"
                numberOfStars={5}
                name="rating"

              />
              <small style={{ marginTop: "15px" }}> {data.totalRatings} User Ratings</small>
            </div>
          </div>
          <div className="col-md-3 justify-content-end d-flex align-items-end">
            <div className="d-flex align-items-center ">
              <Avatar
                // aria-controls={menuId}
                aria-haspopup="true"
                // alt={username}
                alt={
                  data.ownerName
                    ? data.ownerName
                    : data.editor?.username

                }
                // src={profilePic ? profilePic : avatar}
                src={
                  data?.ownerDP
                    ? data?.ownerDP
                    : data.editor?.display_picture

                }
                id="profilePic"
                // className={`${classes.avatar} profilePic point`}
                color="inherit"
              />
              <p className="ml-3 mb-0">
                {data.ownerName
                  ? data.ownerName
                  : data.editor?.username
                }
                <br />
                <span className="ft_xs text-e">{dateFn.date(data.date, 110)}</span>
              </p>
            </div>
          </div>

        </div>

        <div className="col-sm-12  p-3 bg-light" style={{ border: "1px solid gray", borderRadius: "10px" }}>

          <div className="row w-full ">
            <div className="col-md-8 pr-0 " >
              <div style={{ fontSize: "15px", marginTop: "8px" }}>{newCont(content)}
                <span
                  onClick={() => showBlog(data)}
                  style={{ color: "#3b81f2", cursor: "pointer", marginLeft: "5px" }}
                >
                  Continue Reading
                </span>
              </div>
            </div>
            <div className="col-md-4 d-flex flex-column justify-content-between mt-3 mt-md-0" >
              <div className=" d-flex justify-content-between" >

                {mypost === true ? (
                  <div className="col-6 col-md-6" style={{ maxWidth: "50%", overflow: "hidden", }}>
                    {data.pendingPublish === false && data.isPublished === false && (
                      <button
                        type="button"
                        className="btn btn-primary "
                        style={{
                          fontSize: "15px", padding: "4px 6px", borderRadius: "5px"
                        }}
                        onClick={() => blogPub(data)}
                      >
                        Publish
                      </button>
                    )}

                    {data.pendingPublish === true && data.isPublished === false && (
                      <div className="mt-1 mb-1">
                        <small style={{ color: "red", backgroundColor: "#FFCCCB", borderRadius: "7px", padding: "7px 9px" }}>
                          <b>Waiting For Approval</b>
                        </small>

                      </div>
                    )}

                    {(data.isPublished === true && currentUser == data?.editor?.username) ? (
                      <>
                        <button
                          type="button"
                          className="btn btn-primary "
                          style={{
                            fontSize: "15px", padding: "4px 6px", borderRadius: "5px"
                          }}
                          onClick={() => blogunpub(data)}
                        >
                          Unpublish
                        </button>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
                <br />

                <div className="col-6 col-md-6" style={{ gap: "2px", maxWidth: "50%", overflow: "hidden" }}>
                {data.tags &&
                  data.tags.length > 0 &&
                  data.tags.slice(0, 6).map((tag, index) => (
                    <React.Fragment key={tag}>
                      <button
                        type="button"
                        className=" ft_xs mr-2 py-1 px-2 mb-1"
                        onClick={() => search(tag)}
                        style={{
                          backgroundColor: "white",
                          borderRadius: "5px",
                          color: "#007bff",
                          border: "1px solid #007bff"
                        }}
                      >
                        {capitalCase(tag)}
                      </button>
                      {index === 5 && data.tags.length > 6 && (
                        <span
                          className="ft_xs mr-2 mb-1"
                          onClick={() => viewAllTags()} // Add your function to handle "View all" click event
                          style={{
                            color: "#1687FF",
                            cursor: "pointer"
                          }}
                        >
                          View all
                        </span>
                      )}
                    </React.Fragment>
                  ))}
                </div>


                {isAdmin && !mypost ? (
                  <div className="col-6 col-md-6 d-flex justify-content-center  flex-column " style={{ maxWidth: "50%", overflow: "hidden" }}>
                    <CustomTooltip title="Approve Blog">
                      <div
                        type="button"
                        onClick={() => approveBlog(data)}
                        style={{ width: "105px", color: "white", background: "transparent", border: "none", padding: "9px 12px", backgroundColor: "limegreen", borderRadius: "7px", display: "flex", justifyContent: "space-between", marginBottom: "5px", alignItems: "center" }}
                      >
                        Accept
                        <CheckIcon className="fa fa-check point" style={{ color: "white", marginLeft: "10px" }} aria-hidden="true" />
                      </div>
                    </CustomTooltip>

                    <CustomTooltip title="Reject Blog">
                      <div
                        type="button"
                        onClick={() => dialogBox(true, data)}
                        style={{ width: "105px", color: "white", background: "transparent", border: "none", padding: "9px 12px", backgroundColor: "#FF0000", borderRadius: "7px", display: "flex", justifyContent: "space-between", alignItems: "center" }}
                      >
                        Reject
                        <CloseOutlinedIcon className="fa fa-times point" style={{ color: "white", marginLeft: "10px" }} aria-hidden="true" />
                      </div>
                    </CustomTooltip>
                  </div>

                ) : (
                  ""
                )}
              </div>

              <div  style={{ textAlign: "right", margin: "5px", display: "flex", justifyContent: "flex-end", paddingLeft: "0px", paddingRight: "0px" }}>
                <div className="col-6 d-flex justify-content-between pl-0 pr-0">
                  <button
                    className="btn btn-primary ml-3 "
                    style={{ fontSize: "15px",  padding: "4px 6px", borderRadius: "5px" }}
                    onClick={() => handleDownload(data)}
                  >
                    Download
                  </button>


                  {(mypost === true && currentUser == data?.editor?.username) ? (
                    <LightTooltip title="Permanent Delete">
                      <DeleteIcon
                        className="point"
                        style={{ fontSize: "25px", color: "red", marginTop:'3px' }}
                        onClick={() => permanentDelete(data)}
                      />
                    </LightTooltip>
                  ) : (
                    ""
                  )}
                </div>
                <DownloadBlogOptionModal
              show={isDownloadModalOpen}
              onHide={()=>closeDownloadModal()}
              id={data._id}
              />
              </div>
            </div>


          </div>


        </div>


      </div>
    </div>
  );
}
