import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import FormatAlignLeftIcon from "@material-ui/icons/FormatAlignLeft";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Dashboard from "@material-ui/icons/Equalizer";
import Review from "@material-ui/icons/Chat";
import { Link, NavLink } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import avatar from "../static/img_avatar.png";
import NotificationCard from "./Cards/NotificationCard";
import logo from "../static/Group 570.png";
import { useHistory } from "react-router-dom";
import authService from "../services/authService";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
// import PaymentIcon from "@material-ui/icons/Payment";
import ReceiptIcon from "@material-ui/icons/Receipt";
import { getProfile } from "../services/userService";
import "./Navbar.css";
import "./AdminSideNavBar.css";
import GenericThemeSwitch from "./GenericThemeSwitch";
import isEmptyObject from "../utils/isEmptyObject";
import { connect, useSelector } from "react-redux";
import { setCurrentUser } from "../redux/user/user.actions";
import BookIcon from "@material-ui/icons/Book";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import { getHomeCMS } from "../services/adminServices";
import Tooltip from "@material-ui/core/Tooltip";
import PetLogo from "../static/POET_Logo_Mid.png"
import { imgUrl } from "../services/config.json"
import MenuDropDownMui from "./ReusableComponents/AdminSideNavDropDown";
import MenuAccordionMui from "./ReusableComponents/AdminMenuAccordion";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { matchPath } from "react-router";
import PetPenLogo from '../static/POET_Logo_Pen.png'
import HomeIcon from '@mui/icons-material/Home';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";


const drawerWidth = 250;


const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'blue',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);
const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: 10,
  },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60,
  },
  grow: {
    flexGrow: 1,
  },
  root: {
    display: "flex",
  },
  appBar: {
    boxShadow: 'none',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 38,
    [theme.breakpoints.down("sm")]: {
      marginRight: 0, // Applied for small screens and below
    },
  },
  hide: {
    display: "none",
  },
  drawer: {
    // border: "2px solid black",
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    position: "relative", // Default positioning
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    zIndex: theme.zIndex.drawer, // Default zIndex
    [theme.breakpoints.down("sm")]: {
      position: "fixed", // Overlay the content
      height: "100%", // Full height
      top: 0,
      left: 0,
      zIndex: theme.zIndex.drawer + 1, // Higher zIndex for small screens
      transform: "translateX(-100%)", // Initially hidden on small screens
      transition: theme.transitions.create("transform", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down("sm")]: {
      transform: "translateX(0)", // Show when open on small screens
    },
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function AdminHeader(props) {
  const classes = useStyles();
  let history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const [profilePic, setProfilePic] = useState("");
  const [emailId, setemailId] = useState("");
  const [user, setUser] = useState("");
  const theme = useTheme();
  const [open, setOpen] = React.useState();
  const [isAdmin, setAdmin] = React.useState(false);
  const isMenuOpen = Boolean(anchorEl);
  const [cms, setCms] = useState({});
  const { rightModalOpen, leftNavbarOpen } = useSelector(state => state.handleModelAndSideBavBar);

  const [bottomText, setBottomText] = useState("")
  const [selectedMenu, setSelectedMenu] = useState('Professional Editing Tool');
  console.log(user, "username");

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  console.log(windowWidth, "windowWidth")
  const mobileView = windowWidth <= 600;


  const formatDate = () => {
    const options = { month: 'short', day: 'numeric', year: 'numeric' };
    const date = new Date();
    const month = date.toLocaleDateString('en-US', { month: 'short' });
    const day = date.toLocaleDateString('en-US', { day: 'numeric' });
    const year = date.toLocaleDateString('en-US', { year: 'numeric' });

    return `${month} ${day} | ${year}`;
  };


  useEffect(() => {
    const currentPath = window.location.pathname;

    const capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    };

    switch (currentPath) {
      case '/accountsettings':
        setSelectedMenu('Account Settings');
        break;
      case '/admindashboard':
        const capitalizedUsername = capitalizeFirstLetter(user);
        setSelectedMenu(`Welcome ${capitalizedUsername}`);
        setBottomText(`${formatDate()}`);
        break;
      case '/manageuser':
        setSelectedMenu('Manage Users');
        break;
      case '/manageorganizations':
        setSelectedMenu('Manage Organizations');
        break;
      case '/pricing':
        setSelectedMenu('Manage Subscription');
        break;
      case '/manageinvoices':
        setSelectedMenu('Manage Invoices');
        break;
      case '/adminBlogs':
        setSelectedMenu('Manage Blogs');
        break;
      case '/costumHome':
        setSelectedMenu('Customise Landing');
        break;
      case '/managereviews':
        setSelectedMenu("Manage Reviews");
        break;
      case '/promptsGenerator':
        setSelectedMenu('Prompts Generator')
        break;
      default:
        setSelectedMenu('Professional Editing Tool');
    }
  }, [user]);



  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const menuId = "primary-search-account-menu";
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  let location = useLocation()
  console.log("location", location)


  const handleMenuClose = () => {
    setAnchorEl(null);
    // handleMobileMenuClose();
  };
  function handleNavigation(route) {
    history.push(`/${route}`);
  }

  const getCms = async () => {
    let cms = await getHomeCMS();
    setCms(cms.data);
    console.log(cms);
  };

  useEffect(() => {
    const user = authService.getCurrentUser();
    if (user) {
      if (user.isAdmin === true) {
        setAdmin(true);
      }
    }
    displyPic();
    getCms();
  }, []);

  useEffect(() => {
    const { currentUser } = props;
    setOpen(currentUser.sidenavOption);
  }, [props]);

  const displyPic = async () => {
    let user;
    const { currentUser, setCurrentUser } = props;
    if (currentUser && !isEmptyObject(currentUser)) {
      user = { ...currentUser };
    } else {
      user = user = await getProfile();
      console.log("currentUser", user);
      user = user.data;
      setCurrentUser(user);
    }
    console.log("users", user);
    const { display_picture } = user;
    setProfilePic(display_picture);
    setemailId(user.email);
    setUser(user.username);
  };

  const mobileMenuId = "primary-search-account-menu-mobile";

  const dropdown_popover = (
    <Popover id="popover-basic" style={{ zIndex: "9999" }}>
      <Popover.Content>
        <NavLink
          to={{ pathname: "/accountsettings" }}
          style={{ color: "black" }}
        >
          <MenuItem onClick={handleMenuClose}>Account Settings</MenuItem>
        </NavLink>

        <NavLink to={{ pathname: "/logout" }} style={{ color: "black" }}>
          <MenuItem onClick={handleMenuClose}>Log Out</MenuItem>
        </NavLink>
      </Popover.Content>
    </Popover>
  );
  const getThemeSwitch = () => {
    if (window.location.pathname.match("/accountsettings")) {
      return null;
    }
    return <GenericThemeSwitch />;
  };

  // function isUrlMatch(url) {
  //   if (window.location.pathname.match(url)) {
  //     return true;
  //   }
  //   return false;
  // }

  function isUrlMatch(path) {
    return matchPath(location.pathname, {
      path,
      exact: true,
      strict: false,
    })
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <ToastContainer />
      {getThemeSwitch()}
      <AppBar
        id="appBar"
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            style={{ color: "#4d4f5c" }}
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            {/* <MenuIcon /> */}
            <img
              alt="logo"
              className="img-fluid lcss"
              src={PetPenLogo}
              style={{
                width: "40px",
                height: "40px",
                objectFit: "contain",
              }}
            ></img>
          </IconButton>
          {/* <Typography variant="h6" noWrap style={{ color: "#4d4f5c" }}>
            <p>
              PROFESSIONAL <small>EDITING TOOL</small>
            </p>
          </Typography> */}
          <Typography
            className="main-title"
            variant="h6"
            noWrap
            style={{ color: "#4d4f5c" }}
          >
             {mobileView  && !leftNavbarOpen ? (
               <p>
               {selectedMenu !== 'Professional Editing Tool' ? (
                 <>
                   <text
                     style={{
                       fontFamily: "ProximaNova-Semibold",
                      //  lineHeight: "1.18",
                       fontWeight: 600,
                       fontSize: "18px",
                       color: "#3d83fa",
                       marginBottom: "0px"
                     }}
                   >
                     {selectedMenu === 'Chat Messages' ?
                       <>
                         <ArrowBackIcon sx={{ verticalAlign: 'middle', cursor: 'pointer' }} onClick={() => props.history.goBack()} /> {selectedMenu}
                       </>
                       :
                       selectedMenu
                     }
                   </text>
                 </>
               ) : (
                 <>
                   <text
                     style={{
                       fontFamily: "ProximaNova-Semibold",
                       lineHeight: "1.18",
                       fontWeight: 600,
                       fontSize: "22px",
                       color: "#3d83fa",
                       marginRight: "5px"
                     }}
                   >
                     PROFESSIONAL
                   </text>
                   <small
                     style={{
                       fontFamily: "ProximaNova-Light",
                       lineHeight: "1.18",
                       fontSize: "22px",
                       fontWeight: 300,
                       color: "#3d83fa",
                     }}
                   >
                     EDITING TOOL
                   </small>
                 </>
               )}
             </p>
            ) : (
          <Typography
            className="main-title"
            variant="h6"
            noWrap
            style={{ color: "#4d4f5c", marginLeft: "33px" }}
          >
            <p>
              {selectedMenu !== 'Professional Editing Tool' ? (
                <>
                  <text
                    style={{
                      fontFamily: "ProximaNova-Semibold",
                      lineHeight: "1.18",
                      fontWeight: 600,
                      fontSize: "22px",
                      color: "#3d83fa",
                    }}
                  >
                    {selectedMenu === 'Chat Messages' ?
                      <>
                        <ArrowBackIcon sx={{ verticalAlign: 'middle', cursor: 'pointer' }} onClick={() => props.history.goBack()} /> {selectedMenu}
                      </>
                      :
                      selectedMenu
                    }
                  </text><br />
                  {bottomText && (
                    <small
                      style={{
                        fontFamily: "ProximaNova-Light",
                        lineHeight: "1.18",
                        fontSize: "14px",
                        fontWeight: 300,
                        color: "#3d83fa",
                      }}
                    >
                      {bottomText}
                    </small>
                  )}
                </>
              ) : (
                <>
                  <text
                    style={{
                      fontFamily: "ProximaNova-Semibold",
                      lineHeight: "1.18",
                      fontWeight: 600,
                      fontSize: "22px",
                      color: "#3d83fa",
                      marginRight: "5px"
                    }}
                  >
                    PROFESSIONAL
                  </text>
                  <small
                    style={{
                      fontFamily: "ProximaNova-Light",
                      lineHeight: "1.18",
                      fontSize: "22px",
                      fontWeight: 300,
                      color: "#3d83fa",
                    }}
                  >
                    EDITING TOOL
                  </small>
                </>
              )}
            </p>
          </Typography>
          )}
          </Typography>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <div>
              <Grid container justify="center" alignItems="center">
                <OverlayTrigger
                  trigger="click"
                  placement="bottom"
                  overlay={dropdown_popover}
                  rootClose={true}
                >
                  <LightTooltip title={user} onClick={handleProfileMenuOpen}>
                    <Avatar
                      aria-label="account of current user"
                      aria-controls={menuId}
                      aria-haspopup="true"
                      alt={user}
                      src={profilePic ? profilePic : avatar}
                      id="profilePic"
                      className={`${classes.avatar} profilePic point`}
                      color="inherit"
                    />
                  </LightTooltip>
                </OverlayTrigger>
              </Grid>
            </div>
          </div>
          <div className={classes.sectionMobile}>
            <OverlayTrigger
              trigger="click"
              placement="bottom"
              overlay={dropdown_popover}
              rootClose={true}
            >
              {/* <MoreIcon /> */}
              <Avatar
                aria-label="account of current user"
                aria-controls={menuId}
                onClick={handleProfileMenuOpen}
                aria-haspopup="true"
                alt={user}
                src={profilePic ? profilePic : avatar}
                id="profilePic"
                className={`${classes.avatar} point`}
                data-toggle="tooltip"
                data-placement="bottom"
                title={emailId}
                color="inherit"
              />
            </OverlayTrigger>
          </div>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        open={open}
      >
        <div className={classes.toolbar} style={{ backgroundColor: "#1976D2", borderTopRightRadius: '50px' }}>
          <IconButton className="ml-4" onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <MenuIcon style={{ color: "#fff", fontSize: '25px' }} />
            ) : (
              <MenuIcon style={{ color: "#fff", fontSize: '25px' }} />
            )}
          </IconButton>
          <img
            alt="logo"
            src={PetLogo}
            // src={
            //   cms && cms.Navbar_logo
            //     ? cms.Navbar_logo.Image
            //     : `${imgUrl}/pet-website-static-images/Group+570.png`
            // }
            className="img-fluid lcss"
            style={{
              width: "70%",
              height: "70%",
              objectFit: "contain",
              marginRight: '40px'
            }}
          ></img>

        </div>
        <Divider />
        {/* list starts */}
        <List className="drawerMenu pointer" id="menuList">
          {/* Link 1 */}
          <NavLink
            className="sidenavbar-textlink"
            to={{ pathname: "/admindashboard" }}
          >
            <ListItem
              className={
                isUrlMatch("/admindashboard") ? "background-red" : "hvr-pulse"
              }
            // style={{ marginTop: "15%" }}
            >
              <ListItemIcon
                className="drawerMenu"
                style={{ color: "red" }}
              >
                <LightTooltip title={open ? "" :"Dashboard"}>
                  <HomeIcon
                    className={
                      isUrlMatch("/admindashboard") ? "iconCol" : "hvr-pulse"
                    }
                  />
                </LightTooltip>
              </ListItemIcon>
              <Typography>Dashboard</Typography>
            </ListItem>
          </NavLink>
          {/* Link 2 */}
          <Link
            className="sidenavbar-textlink"
            to={{ pathname: "/manageuser" }}
          >
            <ListItem
              className={
                isUrlMatch("/manageuser") ? "background-red" : "hvr-pulse"
              }
            >
              <ListItemIcon
                className="drawerMenu"
              >
                <LightTooltip title={open ? "" :"Manage Users"}>
                  {/* <PeopleAltIcon
                    className={
                      isUrlMatch("/manageuser") ? "iconCol" : "hvr-pulse"
                    }
                  /> */}
                  <svg
                    className={
                      isUrlMatch("/manageuser")
                        ? "bi bi-person-fill-check iconCol"
                        : "bi bi-person-fill-check hvr-pulse"
                    }
                    xmlns="http://www.w3.org/2000/svg"
                    width="22px"
                    height="22px" fill="currentColor"
                    viewBox="0 0 18 18"
                    color="black"
                  >
                    <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m1.679-4.493-1.335 2.226a.75.75 0 0 1-1.174.144l-.774-.773a.5.5 0 0 1 .708-.708l.547.548 1.17-1.951a.5.5 0 1 1 .858.514M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                    <path d="M2 13c0 1 1 1 1 1h5.256A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1 1.544-3.393Q8.844 9.002 8 9c-5 0-6 3-6 4" />
                  </svg>
                </LightTooltip>
              </ListItemIcon>
              <Typography>Manage Users</Typography>
            </ListItem>
          </Link>

          <Link
            className="sidenavbar-textlink"
            to={{ pathname: "/manageorganizations" }}
          >
            <ListItem
              className={
                isUrlMatch("/manageorganizations") ? "background-red" : "hvr-pulse"
              }
            >
              <ListItemIcon
                className="drawerMenu"
              >
                <LightTooltip title={open ? "" :"Manage Organizations"}>
                  <BusinessRoundedIcon
                    className={
                      isUrlMatch("/manageorganizations") ? "iconCol" : "hvr-pulse"
                    }
                  />
                </LightTooltip>
              </ListItemIcon>
              <Typography>Manage Organizations</Typography>
            </ListItem>
          </Link>
          {/* Link 3 */}
          <Link className="sidenavbar-textlink" to={{ pathname: "/pricing" }}>
            <ListItem
              className={
                isUrlMatch("/pricing") ? "background-red" : "hvr-pulse"
              }
            >
              <ListItemIcon
                className="drawerMenu"
              >
                <LightTooltip title={open ? "" :"Manage Subscription"}>
                  <CurrencyExchangeIcon
                    className={isUrlMatch("/pricing") ? "iconCol" : "hvr-pulse"}
                    style={{ color: "orange" }}
                  />
                </LightTooltip>
              </ListItemIcon>
              <Typography>Manage Subscription</Typography>
            </ListItem>
          </Link>
          {/* Link 4 */}
          <Link
            className="sidenavbar-textlink"
            to={{ pathname: "/manageinvoices" }}
          >
            <ListItem
              className={
                isUrlMatch("/manageinvoices") ? "background-red" : "hvr-pulse"
              }
            >
              <ListItemIcon
                className="drawerMenu"
              >
                <LightTooltip title={open ? "" :"Manage Invoices"}>
                  <ReceiptIcon
                    className={
                      isUrlMatch("/manageinvoices") ? "iconCol" : "hvr-pulse"
                    }
                    style={{ color: "white" }}
                  />
                </LightTooltip>
              </ListItemIcon>
              <Typography>Manage Invoices</Typography>
            </ListItem>
          </Link>
          {/* Link 5 */}
          {/* <Link className="sidenavbar-textlink" to={{ pathname: "/" }}>
            <ListItem>
              <ListItemIcon className="drawerMenu">
                <AnnouncementIcon />
              </ListItemIcon>
              Alerts
            </ListItem>
          </Link> */}
          <Link
            className="sidenavbar-textlink"
            to={{ pathname: "/managereviews" }}
          >
            <ListItem
              className={
                isUrlMatch("/managereviews") ? "background-red" : "hvr-pulse"
              }
            >
              <ListItemIcon
                className="drawerMenu"
              >
                <LightTooltip title={open ? "" :"Manage Reviews"}>
                  <Review
                    className={
                      isUrlMatch("/managereviews") ? "iconCol" : "hvr-pulse"
                    }
                    style={{ color: "yellow" }}
                  />
                </LightTooltip>
              </ListItemIcon>
              <Typography>Manage Reviews</Typography>
            </ListItem>
          </Link>
          <Link
            className="sidenavbar-textlink"
            to={{ pathname: "/adminBlogs" }}
          >
            <ListItem
              className={
                isUrlMatch("/adminBlogs") || isUrlMatch("/blogpreview/:id") ? "background-red" : "hvr-pulse"
              }
            >
              <ListItemIcon
                className="drawerMenu"
              >
                <LightTooltip title={open ? "" :"Manage Blogs"}>
                  <BookIcon
                    className={
                      isUrlMatch("/adminBlogs") || isUrlMatch("/blogpreview/:id") ? "iconCol" : "hvr-pulse"
                    }
                    style={{ color: "black" }}
                  />
                </LightTooltip>
              </ListItemIcon>
              <Typography>Manage Blogs</Typography>
            </ListItem>
          </Link>
          <Link
            className="sidenavbar-textlink"
            to={{ pathname: "/costumHome" }}
          >
            <ListItem
              className={
                isUrlMatch("/costumHome") ? "background-red" : "hvr-pulse"
              }
            >
              <ListItemIcon
                className="drawerMenu"
                data-toggle="tooltip"
                data-placement="right"
                title=""
              >
                <LightTooltip title={open ? "" :"Customize Landing Page"}>
                  <AccountBalanceIcon
                    className={isUrlMatch("/costumHome") ? "iconCol" : ""}
                    style={{ color: "orange" }}
                  />
                </LightTooltip>
              </ListItemIcon>
              <Typography>
                Customize Landing <br /> Page
              </Typography>
            </ListItem>
          </Link>

          {/* <Link
            className="sidenavbar-textlink"
            // to={{ pathname: "/costumHome" }}
          > */}
          {/* <ListItem
              className={
                isUrlMatch("/costumHome") ? "background-red" : "hvr-pulse"
              }
              onClick={handleClickMenu}
            >
              <ListItemIcon
                className="drawerMenu"
                data-toggle="tooltip"
                data-placement="right"
                title=""
              >
                <LightTooltip title="Features">
                  <AccountBalanceIcon
                    className={isUrlMatch("/costumHome") ? "iconCol" : ""}
                  />
                </LightTooltip>
              </ListItemIcon>
              <Typography>
                Features
              </Typography>
            </ListItem> */}

          <MenuAccordionMui />
          {/* <MenuDropDownMui/> */}

          {/* <Menu
              anchorEl={anchorElMenu}
              open={Boolean(anchorElMenu)}
              onClose={handleCloseMenu}
            >
              <MenuItem onClick={handleCloseMenu}>
                <Link>
                  Feature 1
                </Link>
              </MenuItem>
            </Menu> */}

          {/* </Link> */}

          {/* <Link className="sidenavbar-textlink" to={{ pathname: "/newhome" }}>
            <ListItem className={isUrlMatch("/newhome") && "background-red"}>
              <ListItemIcon
                className="drawerMenu"
                data-toggle="tooltip"
                data-placement="right"
                title="Costumize Landing Page"
              >
                <AccountBalanceIcon
                  className={isUrlMatch("/newhome") ? "iconCol" : ""}
                />
              </ListItemIcon>
              home
            </ListItem>
          </Link> */}
        </List>
        {/* list over */}
        <Divider />
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
      </main>
    </div>
  );
}
const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});
const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminHeader);
