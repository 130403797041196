import { apiUrl, herokuUrl } from "./config.json";
import httpService from "./httpService.js";
let apiEndpoint = "";
if (process.env.NODE_ENV !== "production") {
  apiEndpoint = `${apiUrl}/detectors`;
} else {
  apiEndpoint = `${herokuUrl}/detectors`;
}

export async function spellCheck(words) {
  const data = await httpService.post(`${apiEndpoint}/spellCheck`, words);
  return data;
}

export async function overusedWords(sentences) {
  const data = await httpService.post(`${apiEndpoint}/overuse`, sentences);
  return data;
}

export async function sentimentAnalyzer(text) {
  let Sentiment = require("sentiment");
  const Sad = require("../static/Sad.png");
  const Happy = require("../static/Happy.png");
  const Normal = require("../static/Normal.png");
  const VeryHappy = require("../static/VeryHappy.png");
  let emoji = {};
  let sentiment = new Sentiment();
  let result = sentiment.analyze(text);
  
  if (result.score >= 20) {
    emoji = {
      emojiStatus: "Joyful & Delighted",
      emojiImg: VeryHappy
    };
  } else if (result.score >= 10) {
    emoji = {
      emojiStatus: "Happy",
      emojiImg: Happy
    };
  } else if (result.score > 0) {
    emoji = {
      emojiStatus: "Normal",
      emojiImg: Normal
    };
  } else if (result.score === 0) {
    emoji = {
      emojiStatus: "Normal",
      emojiImg: Normal
    };
  } else {
    emoji = {
      emojiStatus: "Sad",
      emojiImg: Sad
    };
  }
  return emoji;
}

export async function effectiveVocabularyAndQualifier() {
  const data = await httpService.get(`${apiEndpoint}/effectivevocabulary`);
  return data;
}

export async function addDictionary(words) {
  let detail = { word: words }
  const data = await httpService.post(`${apiEndpoint}/addDictionary`, detail);
  return data;
}

export async function getmywords() {
  const data = await httpService.get(`${apiEndpoint}/getmywords`);
  return data;
}

export async function deletemywords(word) {
  const data = await httpService.post(`${apiEndpoint}/deletemywords`,{word});
  return data;
}

export async function grammerCheck(sentences,anchorKey){
  const response = await httpService.post(`${apiEndpoint}/grammarcheck`,{sentences,anchorKey});
  return response;
}

export async function paraphrase(content,id,times){
  const response = await httpService.post(`${apiEndpoint}/paraphrase1`, { content, id, iteration: times });
  return response;
}

export async function getSentences(content) {
  const response = await httpService.post(`${apiEndpoint}/getSentences`, { content });
  return response;
}

export async function checkGrammerCheckForParaphrase(data) {
  const response = await httpService.post(`${apiEndpoint}/checkGrammerCheckForParaphrase`, { paraphraseSentences: data });
  return response;
}

export async function checkRepetitiveWords(data){
  const response = await httpService.post(`${apiEndpoint}/checkRepetitiveWords`,  data );
  return response;
}

export async function checkCapitalization(data){
  const response = await httpService.post(`${apiEndpoint}/checkCapitalization`,  data );
  return response;
}

export async function checkPunctuation(data){
  const response = await httpService.post(`${apiEndpoint}/checkPunctuation`,  data );
  return response;
}

export async function checkGrammar(data){
  const response = await httpService.post(`${apiEndpoint}/checkGrammar`,  data );
  return response;
}

export async function checkWordConfusion(data){
  const response = await httpService.post(`${apiEndpoint}/checkWordConfusion`,  data );
  return response;
}

export async function checkPhrasing(data){
  const cleanedText = data.text.replace(/.*\t.*/g, '').trim();
  data.text = cleanedText
  const response = await httpService.post(`${apiEndpoint}/checkParaphrasing`,  data );
  return response;
}

export async function checkCompression(data){
  const response = await httpService.post(`${apiEndpoint}/checkCompression`,  data );
  return response;
}

export async function checkEfficiency(data){
  const response = await httpService.post(`${apiEndpoint}/checkEfficiency`,  data );
  return response;
}

export async function checkExpansion(data){
  const response = await httpService.post(`${apiEndpoint}/checkExpansion`,  data );
  return response;
}

export async function checkWordChoice(data){
  const response = await httpService.post(`${apiEndpoint}/checkWordChoice`,  data );
  return response;
}

export async function checkIntricateText(data){
  const response = await httpService.post(`${apiEndpoint}/checkIntricateText`,  data );
  return response;
}

export async function checkMonotonousSentence(data){
  const response = await httpService.post(`${apiEndpoint}/checkMonotonousSentence`,  data );
  return response;
}

export async function checkSummarizer(data){
  const response = await httpService.post(`${apiEndpoint}/checkSummarizer`,  data );
  return response;
}

export async function checkGrammarFluency(data){
  const response = await httpService.post(`${apiEndpoint}/Correct_Fluency_Engagement_Delivery`,  data );
  return response;
}




export default {
  spellCheck,
  overusedWords,
  sentimentAnalyzer,
  effectiveVocabularyAndQualifier,
  addDictionary,
  getmywords,
  deletemywords,
  grammerCheck,
  paraphrase,
  getSentences,
  checkGrammerCheckForParaphrase,
  checkRepetitiveWords,
  checkCapitalization,
  checkPunctuation,
  checkGrammar,
  checkWordConfusion,
  checkPhrasing,
  checkCompression,
  checkEfficiency,
  checkExpansion,
  checkWordChoice,
  checkIntricateText,
  checkMonotonousSentence,
  checkSummarizer,
  checkGrammarFluency
};
