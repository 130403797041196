import React from "react";
import FAQ from "../FAQ";
import SideNavBar from "../SideNavBar";
const FAQPage = props => {
 
  return (
    <SideNavBar>
      <FAQ />
    </SideNavBar>
  );
};
export default FAQPage;