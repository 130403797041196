import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Paper from '@mui/material/Paper';

const theme = createTheme();

function LanguageDropdown({
  selectLabel, id, name, value, onChange, optionsList,
  margin, disabled, customColor
}) {

  console.log("optionslist", optionsList)

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ m: 1, background: "white", margin: { margin } }}>
        <FormControl sx={{ m: 1, margin: "unset" }} fullWidth size="small">
          <InputLabel id="demo-select-small-label">{selectLabel}</InputLabel>
          <Select
            labelId="demo-select-small-label"
            name={name}
            id={id}
            value={value}
            label={selectLabel}
            onChange={(e) => onChange(e)}
            disabled={disabled}
            renderValue={(selected) => {
              const selectedOption = optionsList.find(option => option.value === selected);
              return selectedOption ? (
                <>
                  {selectedOption.name}
                  &nbsp;{selectedOption.nativeWord}
                </>
              ) : "";
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  color: '#42a5f5',
                },
              },
            }}
          >
            {optionsList.length > 0 &&
              optionsList.map((obj) => {
                let { value, name } = obj;
                return (
                  <MenuItem key={value} value={value}>
                    {name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Box>
    </ThemeProvider>
  );
}

export default LanguageDropdown;
