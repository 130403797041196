import React from 'react';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
    root: {
      '& .MuiChartsLegend-mark': {
        borderRadius: '50%',
      },
    },
  });

const data = [
    { id: 0, value: 10, label: 'series A eries A eries A' },
    { id: 1, value: 15, label: 'series B' },
    { id: 2, value: 20, label: 'series C' },
];

const size = {
    width: 400,
    height: 200,
  };

export default function PieChartMui() {
    const classes = useStyles()
    return (
        <div
        className={classes.root}
            style={{
                backgroundColor: "#ffffff",
                borderRadius: "8px",
                boxShadow: "2px 2px 5px -2px #000000",
                padding: "2px"
            }}
        >
            <h6 className='p-2'
            style={{
                color: "#171717",
                fontFamily: "Poppins, sans-serif",
                fontWeight: 600,
                fontSize: "0.9rem"

            }}
            >User Plans Breakdown</h6>
            <PieChart
                series={[
                    {
                        // arcLabel: (item) => `${item.label} (${item.value})`,
                        arcLabel: (item) => `(${item.value})`,
                        arcLabelMinAngle: 45,
                        data,
                        highlightScope: { faded: 'global', highlighted: 'item' },
                        faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                    },
                ]}
                sx={{
                    [`& .${pieArcLabelClasses.root}`]: {
                        fill: 'white',
                        fontWeight: 'bold',
                        fontSize: "0.8rem"
                    },
                }}
                // {...size}
                height={200}
                width={450}
            />
        </div>
    );
}