import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DefaultBlogImage from "../../static/Blogging-pana.jpg";
import "./BlogCard.css";
import { getTextFromEditorState } from "../../utils/getTextFromEditorState";
import {
    camelCase,
    capitalCase,
    constantCase,
    dotCase,
    headerCase,
    noCase,
    paramCase,
    pascalCase,
    pathCase,
    sentenceCase,
    snakeCase,
} from "change-case";
import { FacebookIcon, FacebookShareButton } from 'react-share'
import { ShareableLink } from "../../services/blogServices";
import Tooltip from "@material-ui/core/Tooltip";
import { imgUrl } from "../../services/config.json";
import { useSelector } from "react-redux";
import DeleteIcon from '@mui/icons-material/Delete';
import { withStyles } from '@material-ui/core/styles';

import dateFn from "date-fn";
import Avatar from "@material-ui/core/Avatar";

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'blue',
        boxShadow: theme.shadows[1],
        fontSize: 12,
    },
}))(Tooltip);

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        height: 215,
        marginTop: 20,
    },
    bullet: {
        display: "inline-block",
        margin: "0 2px",
        transform: "scale(0.8)",
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    media: {
        height: 0,
        paddingTop: "56.25%", // 16:9
    },
});

function getTextFromEditorStateNew(onlyDocumentContent, sliceIt) {
    console.log("onlyDocumentContent_", onlyDocumentContent, sliceIt)
    const processedHtmlContent = onlyDocumentContent.replace(/<br\s*\/?>/gi, '\n').replace(/<div\s*\/?>/gi, '\n');
    // Count words
    const tempElement = document.createElement('div');
    tempElement.innerHTML = processedHtmlContent;
    const textContent = tempElement.innerText;
    if (sliceIt) {
        return textContent.slice(0, 250);
    }
    return textContent;
}

export default function SEOBlogCard({
    data,
    showBlog,
    permanentDelete,
    search
}) {
    const classes = useStyles();
    console.log("search", search)
    console.log(data, "dataaa");
    const userDetails = useSelector((user) => user)
    console.log("userDetails", userDetails)
    const isSubAdmin = userDetails.user.currentUser.isSubAdmin
    let content = getTextFromEditorStateNew(data.onlyDocumentContent, true);
    let link = ShareableLink(data._id, paramCase(data.documentName));
    console.log(data.Date);
    const mdate = new Date(data.date || data.Date);
    let blogDate = new Date(mdate).toString().split(" ").splice(1, 3).join(" ");
    const newCont = (data) => {
        let newContent = data + "...";
        let length = 100 - data.length;
        for (let i = 0; i < length / 2; i++) {
            newContent = newContent + " \xa0";
        }
        return newContent;
    };
    return (
        <div className=" mt-3 mr-4">
            <div className="row d-flex justify-content-between ">
                <div className="col-sm-2  d-flex">
                    {/* {data.imageUrl ? (
                        <img
                            src={
                                `${imgUrl}/` +
                                data.imageUrl[0]
                            }
                            alt="BlogImage"
                            style={{ width: "100%", height: "140px" }}
                        />
                    ) : ( */}
                    <img
                        src={DefaultBlogImage}
                        alt="BlogImage"
                        style={{ width: "100%", height: "100%", objectFit: 'cover' }}
                    />
                    {/* )} */}
                </div>

                <div className="col-sm-10 mt-3 mt-sm-0" style={{paddingLeft: "0px", paddingRight: "0px"}}>

                    <div className="col-12 d-flex align-items-center justify-content-between py-2 bg-light" style={{ borderRadius: "10px", border: "1px solid gray" }}>
                        <div className="col-md-5 d-flex gap-3 align-items-center">
                            <h2 className="mb-0">{data.documentName}</h2>
                            {/* <div className="ml-3 mb-0">
                                <StarRatings
                                    rating={data.ratings}
                                    starRatedColor="orange"
                                    starDimension="13px"
                                    borderRadius="2px"
                                    starSpacing="0px"
                                    numberOfStars={5}
                                    name="rating"

                                />
                                <small style={{ marginTop: "15px" }}> {data.totalRatings} User Ratings</small>
                            </div> */}
                        </div>
                        <div className="col-md-3 justify-content-end d-flex align-items-end">
                            <div className="d-flex align-items-center ">
                                <Avatar
                                    // aria-controls={menuId}
                                    aria-haspopup="true"
                                    // alt={username}
                                    alt={
                                        data.ownerName
                                            ? data.ownerName
                                            : data.editor?.username

                                    }
                                    // src={profilePic ? profilePic : avatar}
                                    src={
                                        data?.ownerDP
                                            ? data?.ownerDP
                                            : data.editor?.display_picture

                                    }
                                    id="profilePic"
                                    // className={`${classes.avatar} profilePic point`}
                                    color="inherit"
                                />
                                <p className="ml-3 mb-0">
                                    {data.ownerName
                                        ? data.ownerName
                                        : data.editor?.username
                                    }
                                    <br />
                                    <span className="ft_xs text-e">{dateFn.date(data.date, 110)}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div style={{ borderRadius: "10px", border: "1px solid gray" }} className="p-3  bg-light col-12">
                        <div className="row">
                            <div className="col-md-9 ">
                                {/* <div className="d-flex mb-2">
                                    <h3><b>{data.documentName}</b></h3>
                                </div>

                                <p
                                    className="row headtwo mb-1"
                                    style={{ marginLeft: "0%", fontSize: "18px" }}
                                >
                                    {data.editor ? data?.editor?.username : "PET User"}
                                    <small style={{ marginLeft: "10px" }}>{blogDate}</small>
                                </p> */}

                                <div style={{ fontSize: "15px", marginTop: "10px" }}>{newCont(content)}
                                    <span
                                        onClick={() => showBlog(data)}
                                        style={{ color: "#3b81f2", cursor: "pointer", marginLeft: "5px" }}
                                    >
                                        Continue Reading
                                    </span>
                                </div>
                            </div>
                            <div className="col-md-3 d-flex flex-md-column justify-content-between align-items-end mt-3 mt-md-0" >
                                <div >
                                    {data.tags ?
                                        (data.tags.slice(0, 3).map((tag) => (
                                            <button
                                                key={tag}
                                                type="button"
                                                className=" ft_xs mr-2 py-1 px-2 mb-1"
                                                onClick={() => search(tag)}
                                                style={{
                                                    backgroundColor: "white",
                                                    borderRadius: "5px",
                                                    color: "#007bff",
                                                    border: "1px solid #007bff"
                                                }}
                                            >
                                                {capitalCase(tag)}
                                            </button>
                                        ))) : ("")
                                    }
                                </div>
                                <div className=" mt-2 mt-sm-0 d-flex align-items-end justify-content-end" >
                                    <LightTooltip title="Permanent Delete">
                                        <DeleteIcon
                                            className="point ml-2"
                                            style={{ fontSize: "25px", color: "red" }}
                                            onClick={() => permanentDelete(data)}
                                        />
                                    </LightTooltip>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>


            </div>
        </div>
    );
}
